.#{$css-namespace}loyalty {
    $root: &;

    position: relative;
    padding: $space-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.15;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $color-white;
    overflow: hidden;
    height: 100%;
    margin: 0 auto;
    background-position: bottom;

    @include min-screen($screen__m) {
        border-radius: 0.3rem;
        margin-right: 0;
    }

    // Inside modal
    &_login {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $space-2;

        p {
            margin: 0;
        }
    }

    .#{$css-namespace}title[data-ui-title-level='3'] {
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        display: block;

        @include min-screen($screen__m) {
            height: fit-content;
            font-weight: $font-weight-semi-bold;
        }

        &.#{$css-namespace}title--stars::before {
            display: block;
            content: '';
            position: absolute;
            top: $space-2;
            left: 2rem;
            width: 7rem;
            height: 6rem;
            background-image: url('../Descours_UserAccount/images/dashboard/stars.svg');
            background-repeat: no-repeat;
            z-index: -1;

            @include min-screen($screen__m) {
                top: -1rem;
            }
        }

        span {
            font-size: 3.2rem;

            @include min-screen($screen__m) {
                font-size: 3.5rem;
            }
        }
    }

    .#{$css-namespace}loyalty_subtitle {
        margin-top: $space-2;
        margin-bottom: $space-2;
        font-size: $font-size-default !important;

        @include min-screen($screen__m) {
            margin-bottom: $space-3;
        }

        &--light {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            column-gap: 1rem;
            row-gap: 0.5rem;
            margin-top: 0;
            margin-bottom: $space-3;
            color: $color-black;
            font-weight: $font-weight-regular !important;

            @include min-screen($screen__m) {
                margin-top: $space-1;
                margin-bottom: 1rem;
            }
        }

        .#{$css-namespace}link {
            color: $color-secondary !important;
        }
    }

    &_message {
        color: $color-secondary;
        max-width: 36.8rem;
        margin: $space-6 0 $space-8 0;

        @include min-screen($screen__m) {
            margin: 0;
        }

        &,
        &_text,
        &_balance {
            font-family: $font-secondary;
        }

        &_text,
        &_balance {
            font-weight: $font-weight__bold;
        }

        &_icon {
            margin-bottom: $indent__base;
            font-size: $font-size-xxxl;
        }

        &_text {
            line-height: 2rem;
            color: $color-main-dark;
            font-size: $font-size-default;
            margin-top: $space-3;

            @include min-screen($screen__m) {
                margin-top: $space-2;
            }
        }

        &_balance {
            line-height: 1.1;
            color: $color-secondary;
            font-size: $font-size-xxxl;
        }
    }

    &_list {
        display: flex;
        flex-direction: column;
        row-gap: $space-3;
        line-height: 2rem;
        list-style: none;
        text-align: left;
        margin: 0;
        padding: 0;

        @include min-screen($screen__m) {
            padding-left: $space-4;
            padding-right: $space-4;
            row-gap: 1rem;
        }

        &_entry {
            display: flex;
            align-items: center;
            margin: 0;

            &::before {
                content: '';
                margin-right: 2rem;
                min-width: 7rem;

                @include min-screen($screen__m) {
                    min-width: 4.3rem;
                }
            }

            span {
                font-size: $font-size-default;
                line-height: 2rem;
            }

            &--piggy-bank,
            &--gift {
                &::before {
                    content: '';
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }

            &--piggy-bank {
                &::before {
                    background-image: url('../Descours_UserAccount/images/dashboard/piggy-bank.svg');
                    transform: scale(-1, 1) rotate(12deg);
                    height: 6.6rem;
                    position: relative;
                    left: -1rem;

                    @include min-screen($screen__m) {
                        height: 4.5rem;
                    }
                }
            }

            &--gift {
                &::before {
                    background-image: url('../Descours_UserAccount/images/dashboard/gift.svg');
                    transform: rotate(12deg);
                    height: 6.3rem;
                    left: -0.5rem;
                    position: relative;

                    @include min-screen($screen__m) {
                        height: 4.5rem;
                    }
                }
            }
        }
    }

    &_points {
        margin: $space-5 0 0;
        line-height: 1.15;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: $space-3;

        @include min-screen($screen__m) {
            padding: $space-1 0;
            gap: $space-2;
        }

        &_expired {
            margin-top: $space-3;

            @include min-screen($screen__m) {
                margin-top: 1rem;
            }

            .#{$css-namespace}loyaltyprogram_pending & {
                display: none;
            }
        }

        &_booster {
            margin-top: 1.2rem;
            margin-bottom: $indent__s;
            background: $color-secondary;
            padding: $indent__s $indent__base;
            border-radius: 1.6rem;
            color: $color-white;

            .#{$css-namespace}link {
                &,
                &:hover,
                &:focus {
                    color: $color-white;
                    text-decoration: underline;
                }

                &:visited {
                    color: $color-white;
                }
            }

            @include min-screen($screen__m) {
                margin-top: $indent__base;
            }

            .#{$css-namespace}loyaltyprogram_pending & {
                background-color: $color-disabled;
                cursor: not-allowed;

                .#{$css-namespace}link {
                    pointer-events: none;
                }
            }
        }

        &_info {
            margin-top: 0;

            small {
                font-size: $font-size-xs;
            }
        }

        &_expired,
        &_booster,
        &_info {
            margin: 0;
        }
    }

    &_button {
        margin-top: 4.5rem;

        @include min-screen($screen__m) {
            margin-top: $space-2;
        }

        .#{$css-namespace}btn {
            padding-left: $indent__l;
            padding-right: $indent__l;
        }
    }

    #{$root}_content + .#{$css-namespace}loyalty_button {
        margin-top: $space-3;

        @include min-screen($screen__m) {
            margin-top: 1rem;
        }
    }

    &_more {
        margin-top: $space-1 / 2;
        padding-bottom: $space-1 / 2;
        text-align: center;
        font-size: $font-size-s;
        color: $color-secondary;

        @include min-screen($screen__m) {
            margin-top: $space-2;
        }
    }

    strong {
        font-weight: $font-weight__bold;
    }

    &--vertical_center {
        #{$root}_content {
            z-index: 1;

            @include min-screen($screen__m) {
                margin: auto;
            }
        }
    }

    // For validated only
    &[data-step='validated'] {
        @include min-screen($screen__l) {
            background-image: url('../Descours_UserAccount/images/dashboard/loyalty-program-subscribe_lg.svg'),
                url('../Descours_UserAccount/images/dashboard/gift-with-cloud.svg'),
                url('../Descours_UserAccount/images/dashboard/piggy-bank.svg');

            background-position:
                bottom,
                top left,
                top right;
            background-size: contain, contain, 15%;
        }

        .#{$css-namespace}loyalty_button {
            margin-top: $space-7;

            @include min-screen($screen__m) {
                margin-top: $space-1;
            }
        }
    }
}

.block-dashboard-loyalty {
    flex: 1;

    @include min-screen($screen__m) {
        margin: 0 0 0 1rem;
    }
}
