$section-padding: $space-2;

.descours_events-catalogevent-index {
    .page-wrapper {
        .page-main {
            .columns {
                margin-bottom: 5rem;
            }
        }
    }
}

.#{$css-namespace}catalog-event-page {
    margin-bottom: $offset-l;

    @include min-screen($screen__m) {
        margin-bottom: $space-2;
    }

    &_wrapper {
        padding: 0 $section-padding;

        @include min-screen($screen__m) {
            margin-top: $space-3;
            padding: 0;
        }

        @include min-screen($screen__l) {
            padding: 0 $section-padding;
        }

        @include max-screen($screen__m) {
            .page-title-wrapper {
                margin-bottom: 1rem;
            }
        }

        a[href] {
            color: $color-secondary;

            &:hover {
                color: $color-primary;
            }
        }
    }

    &_images {
        max-height: 16rem;
        overflow: hidden;
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        picture {
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    &_subtitle {
        margin-top: $offset;
    }

    &_description {
        margin-top: $offset;

        @include min-screen($screen__m) {
            max-width: 60%;
        }
    }

    .#{$css-namespace}plp_title {
        margin: 3rem 0 $space-2;
        text-align: left;
        display: block;
    }

    &_subtitle,
    &_description {
        &:last-child {
            margin-bottom: 3rem;
        }
    }

    .#{$css-namespace}list {
        margin-top: 3rem !important;
    }

    &_links {
        text-align: center;
        margin: 0;
        padding: 0;

        @include max-screen($screen__m) {
            display: flex !important;
            flex-direction: column !important; //override data-ui-list-type direction
            margin: auto;
        }

        @include min-screen($screen__m) {
            white-space: nowrap;
        }

        &_wrapper {
            padding: $offset-l $offset-xxxl !important; // override dc__list padding
            margin: 3rem 0 0 !important; //override data-ui-list-type margins
            background: $color-main-light;

            @include max-screen($screen__m) {
                padding: $offset-l 1rem !important; // override dc__list padding;
            }

            @include min-screen($screen__m) {
                padding: $offset-m #{$space-1 / 2} !important; // override dc__list padding
            }
        }
    }

    &_link {
        &-item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $color-secondary;
            margin-bottom: 0 !important;

            @include min-screen($screen__m) {
                height: 3rem;
                padding: 0 2.25rem 0 2rem !important;

                & + & {
                    border-left: 0.1rem solid $color-grey;
                }
            }

            @include max-screen($screen__m) {
                justify-content: flex-start;

                & + & {
                    margin-top: 2rem;
                }
            }

            .#{$css-namespace}link {
                // Todo : refacto
                text-decoration: none;

                span {
                    text-decoration: underline;
                }
            }

            .dcfonts-icons {
                margin-right: $offset-xs;
                font-size: $font-size-l;
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    &_video-iframe {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0.1rem solid $color-white;
    }

    &_modal-button {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        color: $color-secondary;

        span {
            text-decoration: underline;
        }
    }

    &_goc-title,
    &_seo-title {
        display: block;
        color: $color-primary;
        font-size: $font-size-m;
        font-weight: $font-weight-bold;
        margin-bottom: 1.5rem;
    }

    &_footer {
        padding: 0 $offset-l;

        div + div {
            margin-top: 4.5rem;
        }

        @include min-screen($screen__m) {
            padding: 0 $offset-xxl;
        }

        a[href] {
            color: $color-secondary;

            &:hover {
                color: $color-primary;
            }
        }
    }
}

.modal-popup.modal-video-frame {
    //really override magento modal style

    @include max-screen($screen__m) {
        bottom: auto;
    }

    .modal-header {
        background: transparent !important;

        .action-close {
            padding: 0;
        }
    }

    .modal-footer {
        display: none;
    }

    .modal-inner-wrap {
        background: transparent !important;
        border-radius: 0;
        box-shadow: none !important;
    }

    .modal-content {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding: 56.25% 0 0 0 !important;
        background: $color-white;
    }
}
