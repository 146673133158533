$slide-width: 22.7rem !default;

// TODO: Replace these IDs by a generic selector once the designs of all sliders are consistent.
#cross-sell,
#linked-articles {
    .#{$css-namespace}product-carousel__top {
        @include min-screen($screen__m) {
            padding-right: $space-5;
        }
    }
}

.#{$css-namespace}product-carousel {
    &__top#carrousel__top-events {
        display: none;
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        margin: 0;
        text-align: center;
        white-space: normal;
        height: 0;
        width: 100%;

        .slick {
            &-slide {
                width: $slide-width;
                display: flex;
                flex-direction: column;
                height: auto;
                margin: 0 #{$space-3 / 2};

                > div {
                    height: 100%;
                }
            }
        }

        .#{$css-namespace}product_slot.#{$css-namespace}product_slot,
        .#{$css-namespace}product_slot__container {
            height: 100%;
        }

        .#{$css-namespace}product_slot__top-part {
            flex: 1;
        }
    }

    &__slick-arrows-container {
        @include min-screen($screen__m) {
            margin-top: 0;
        }

        #category-top-sales & {
            margin-left: auto;
        }

        &:empty {
            display: none;
        }
    }

    .product_grid {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        margin: $indent__l 0 $indent__s;
        text-align: center;
        white-space: normal;
        height: 0;

        .product_grid__item {
            min-width: 25rem;

            .product-image-wrapper {
                width: 20rem;
                height: 20rem;
            }
        }

        &__item,
        .product_grid__item {
            .product-image-wrapper {
                display: block;
                max-width: 100%;
                margin: 0 auto;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            h4 {
                margin-bottom: 0;
            }

            .no-price {
                padding: $indent__s;
            }

            .dc-panier {
                display: none;
            }
        }

        &__btn_container .action.primary {
            margin: $indent__s;
        }

        &__price_container {
            margin-bottom: $indent__s;
            margin-top: $indent__s;
        }

        .actions {
            padding-left: 0;
        }

        .not-salable {
            font-weight: $font-weight-bold;
            color: $color-sub-secondary;
            padding: $indent__s;
            flex: 1 0 auto;
        }

        .add-to-cart {
            padding-top: $indent__base;
            margin-bottom: $indent__s;
            flex: 1 0 auto;
        }
    }
}

.image-control {
    margin: $indent__base auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40rem;

    img {
        width: 6.4rem;
        height: 6.4rem;
    }
}
