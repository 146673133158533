.checkout__products {
    &-wrapper {
        &:not(:last-child) {
            margin-bottom: 1rem;

            @include max-screen($screen-sm) {
                margin-bottom: 2rem;
            }
        }
    }

    .#{$css-namespace}order-items_table {
        table-layout: auto;

        .has-quantity-promo {
            td {
                vertical-align: top;
            }

            .#{$css-namespace}item-table_row-availability {
                .small {
                    padding-top: 2rem;
                }
            }

            .#{$css-namespace}item-table_row-qty {
                height: 16rem !important;

                .quantity-picker-container {
                    .#{$css-namespace}form-quantity-picker_input-wrapper {
                        padding-top: 1rem;
                    }

                    .#{$css-namespace}form-quantity-picker_update-link {
                        position: relative;
                    }
                }
            }
        }
    }

    .#{$css-namespace}products-section_products-table {
        cursor: default;

        .#{$css-namespace}order-items {
            .#{$css-namespace}item-table_header-row {
                > th {
                    &:first-child {
                        width: 34%;
                    }

                    &.price {
                        width: 42%;
                    }

                    &.qty {
                        width: 24%;
                    }
                }
            }

            .#{$css-namespace}item-table_row {
                &::after {
                    content: '';
                    clear: both;
                    display: table;
                }

                .#{$css-namespace}item-table_row {
                    &-item {
                        @include min-screen($screen-sm) {
                            padding-left: 2rem;
                        }

                        &-container {
                            padding: 0;

                            @include max-screen($screen-sm) {
                                display: block;
                            }

                            @include min-screen($screen-sm) {
                                min-width: 26rem;
                            }

                            @include min-screen($screen__l) {
                                min-width: 46.5rem;
                            }
                        }

                        .#{$css-namespace}item-table_row {
                            &-picto {
                                @include min-screen($screen-sm) {
                                    margin-right: 1.5rem;
                                    flex: 0 0 6rem;
                                }
                            }

                            &-details {
                                @include min-screen($screen-sm) {
                                    padding: 0;
                                    flex: 1;
                                }

                                @include min-screen($screen__l) {
                                    max-width: 23rem;
                                }
                            }
                        }
                    }

                    &-qty {
                        text-align: center;

                        @include min-screen($screen__l) {
                            max-width: 15.5rem;
                        }

                        @include min-screen($screen-sm) {
                            min-height: 9rem;
                        }

                        @include max-screen($screen-sm) {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            height: 4rem;
                            margin-bottom: 2rem;
                            font-weight: $font-weight__regular;

                            &::before {
                                content: attr(data-qty-label);
                                margin-right: $space-1 / 2;
                            }
                        }
                    }

                    &-price {
                        text-align: left;

                        @include min-screen($screen__l) {
                            width: 14.6rem;
                            padding-left: 2rem;
                        }

                        @include max-screen($screen-sm) {
                            position: static;
                        }

                        .#{$css-namespace}item-table_row-item_subtotal {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.checkout-shipping-method {
    .#{$css-namespace}order-items {
        border: 0.1rem solid $color-primary;
        border-radius: 0.3rem;

        &_table {
            position: relative;
            z-index: 1;
        }
    }

    .#{$css-namespace}item-table {
        &_row + .#{$css-namespace}item-table_row {
            border-top: 0.1rem solid $color-grey-argent !important;
        }

        &_row {
            border-width: 0;
            background-color: $color-white;
            min-height: auto;

            @include min-screen($screen__s) {
                min-height: 9rem !important;
            }

            &:first-child {
                border-top-width: 0;
            }

            &-qty {
                margin-top: $space-1;

                @include max-screen($screen__s) {
                    width: 40% !important; // overrided by wishlist

                    .#{$css-namespace}quantity-readonly {
                        width: $quantity-picker-width;
                        max-width: 100%;
                        display: inline-block;
                        text-align: center;
                    }
                }
            }

            &-qty,
            &-price {
                float: none !important;
                display: inline-block;
                vertical-align: top;
            }

            &-price {
                width: calc(60% - 0.1rem);
                text-align: right;

                @include max-screen($screen__s) {
                    padding-right: $space-1 !important; // overrided by wishlist
                }
            }

            &-details {
                text-align: left;
            }

            &-item-container {
                width: 100%;
                margin-bottom: $space-3;
            }

            &-item_subtotal {
                white-space: nowrap;
            }

            &-price_ctn {
                margin-left: auto;
                margin-right: 0;
            }
        }

        @include max-screen($screen-sm) {
            &_row-item-container {
                display: flex;
                text-align: center;
            }
        }

        @include min-screen($screen__s) {
            &_row-item {
                &,
                &-container {
                    padding: 0 !important;
                }

                &-container {
                    width: calc(70% - #{$quantity-picker-width});
                    margin-bottom: 0;
                }
            }

            &_row-qty,
            &_row-price {
                display: table-cell;
                vertical-align: middle;
                width: $quantity-picker-width;
            }

            &_row-price {
                padding: 0 2rem 0 0 !important;
                width: 30%;
            }

            &_row-qty {
                height: auto !important;
            }

            &_row-picto img {
                max-height: calc(100% - 1rem) !important; // checkout cropping border
            }

            &_row-details {
                padding-left: 0;
            }
        }
    }
}
