.#{$css-namespace}list-dl {
    display: flex;
    flex-direction: column;
    margin: 0; // reset native rule

    dt {
        margin-bottom: $offset-xs;
        font-size: var(--font-size-default);
    }

    dd {
        margin: 0; // reset native rule
        font-size: var(--font-size-default);

        + dd {
            margin-top: $offset-xs;
        }
    }

    // specific : inline label + inline values
    &[data-ui-list-type='inline'] {
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-auto-flow: column;
        grid-column-gap: $offset-s;

        dt {
            grid-column: 1;
            margin: 0 $offset-s 0 0;
            white-space: nowrap;
        }

        dd {
            margin: 0;
        }
    }

    // specific : inline label + block values
    &[data-ui-list-type='inline-dt'] {
        display: grid;
        grid-template-columns: min-content auto;
        grid-column-gap: $offset-xs;

        dt {
            grid-column: 1;
            margin-bottom: 0;
            white-space: nowrap;
        }

        dd {
            grid-column: 2;
            margin: 0;

            + dd {
                margin: $base-step 0 0;
            }
        }
    }

    // specific : block label + inline values
    &[data-ui-list-type='inline-dd'] {
        flex-direction: row;
        flex-wrap: wrap;

        dt {
            width: 100%;
        }

        dd {
            margin-bottom: $offset-s;

            + dd {
                margin: 0 0 $offset-s $offset-m;
            }
        }
    }
}
