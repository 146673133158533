.product-details-description {
    .product-name {
        margin: 0;
        @include lib-font-size(22);
        font-weight: $font-weight__bold;
        word-break: break-all;
        color: $color-gray-light7;

        @supports (hyphens: auto) {
            hyphens: auto;
            word-break: normal;
        }
    }

    .product-brand {
        margin-top: $indent__s;

        a {
            margin: 0;
            @include lib-font-size(14);
            color: $color-gray20;
        }
    }

    .product-sku {
        margin-top: $indent__s;

        dl {
            display: flex;
            margin: 0;
            @include lib-font-size(12);
            color: $subAltPrimaryColor;

            dt {
                flex: 0 0 auto;
                margin: 0;
                font-weight: $font-weight__bold;
            }

            dd {
                flex: 0 0 auto;
                margin: 0 0 0 $indent__xs;
            }
        }
    }
}
