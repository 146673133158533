.#{$css-namespace}price-levels {
    margin-bottom: $space-2;
    width: 100%;

    &_title {
        margin-bottom: 1rem;
        font-weight: $font-weight-semi-bold;
        color: $color-main-dark;
    }

    &_grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        color: $color-grey-dark;

        &_cell {
            font-size: $font-size-xs;
            line-height: $font-size-default;
            padding: $space-1;

            &:nth-child(n + 3) {
                border-top: 0.1rem solid $color-blue-light;
            }

            &--right {
                text-align: right;
            }
        }
    }

    &_qty {
        font-size: $font-size-default;
    }

    &_price {
        font-size: $font-size-s;
    }

    &_message {
        margin-top: 0.6rem;
        padding: 1rem;
        background: $color-red-light;
        color: $color-main-dark;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;
        border-radius: 0.3rem;
        position: relative;

        &_text {
            margin-top: 0.3rem;
            line-height: 1.1rem;
        }

        &--with-arrow {
            &::before {
                content: '';
                position: absolute;
                left: 6.3rem;
                top: 0;
                transform-origin: center;
                transform: rotate(45deg) translate(-50%);
                width: 0.75rem;
                height: 0.75rem;
                background: $color-red-light;
                @include max-screen($screen__m) {
                    left: 7.5rem;
                }
            }
        }

        strong {
            font-weight: $font-weight-medium;
            color: $color-red;
        }

        ~ .#{$css-namespace}availability {
            margin-top: 0.7rem;
        }

        &_price {
            color: $color-red-promo;
            font-weight: $font-weight-bold;
        }
    }

    &_chip {
        padding: 0.2rem 0.7rem;
        color: $color-white;
        background: $color-red;
        border-radius: 0.2rem;
        max-width: 12rem;
        display: flex;
        justify-content: center;
        white-space: nowrap;

        &_text {
            font-size: $font-size-xxs;
            line-height: 0.8rem;
            font-weight: $font-weight-medium;
            color: $color-white;
        }

        &:hover,
        &:visited {
            color: $color-white;
        }

        .dcfonts-icons {
            margin-left: 0.65rem;
            font-size: $font-size-xs;
        }
    }
}
