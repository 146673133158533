.contact-index-index {
    .column.main {
        padding: 0 0.5rem;

        @include min-screen($screen__l) {
            padding: 0;
        }
    }
}

.page-wrapper {
    background-color: $color-blue-background-1;
}

.#{$css-namespace}contact-page {
    @include max-screen($screen__l) {
        overflow-x: hidden;
    }

    &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13rem;
        color: $color-white;
        background-color: #aaaaaa; // TODO: Use global var
        @include lib-background-image('/background-hero-contact.png', center, no-repeat, transparent, false);
        background-size: cover;

        @include min-screen($screen__l) {
            height: 20rem;
        }

        &-wrapper {
            width: 100%;
            padding: 0 4rem;

            @include min-screen($screen__l) {
                width: 100rem;
                padding: 0;
            }
        }

        &-subtitle {
            margin: 0;
        }
    }

    &_title,
    &_subtitle {
        margin: 0;
    }

    &_title {
        font-size: $font-size-xxl;
        font-weight: $font-weight-bold;
        font-family: $font-secondary;
    }

    &_subtitle {
        margin-bottom: 3rem;
        font-size: $font-size-xl;
        font-family: $font-secondary;
        font-weight: $font-weight-bold;
        color: $color-primary;

        @include min-screen($screen__l) {
            margin-bottom: 2rem;
        }

        &--black {
            color: $color-black;
        }

        &:not(:first-of-type) {
            margin-top: 3rem;
        }
    }

    &_header-subtitle {
        font-size: $font-size-m;
        font-weight: $font-weight-regular;
        font-family: $font-secondary;

        @include min-screen($screen__l) {
            font-size: $font-size-xxl;
            font-weight: $font-weight-bold;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: auto;
        width: 100%;
        transform: translateY(-2rem);

        @include min-screen($screen__l) {
            flex-direction: row;
            width: 100rem;
        }
    }

    &_map {
        width: 17.8rem;
        height: 18rem;
        margin-bottom: 3.3rem;
    }

    &_form {
        width: 100%;

        @include min-screen($screen__l) {
            width: 59rem;
        }

        .#{$css-namespace}form-field-wrapper--phone {
            position: relative;
            display: flex;

            .#{$css-namespace}tooltip-container {
                position: absolute;
                right: -3rem;
                left: 15.8rem;
                top: 2.2rem;
                transform: translateY(-50%);
                z-index: 1;

                .active.#{$css-namespace}tooltip--light > span {
                    @include max-screen($screen__m) {
                        left: -14rem;
                    }
                }
            }

            .dcfonts-icons--question-circle {
                margin-left: 0.6rem;
                font-size: 1.6rem;
            }
        }

        &-submit-button {
            width: 100%;
            justify-content: center;
            margin-top: 3rem;

            @include min-screen($screen__m) {
                width: auto;
                min-width: 28rem;
            }
        }
    }

    .#{$css-namespace}textarea_field::after {
        background: none; // override app/design/frontend/Descours_v2/Descours/styles/toolkit/common/01_components/forms/textarea/_module.scss:100
    }

    &_sidebar {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;

        @include min-screen($screen__l) {
            flex: 1;
            max-width: 38.7rem;
        }
    }

    &_item {
        padding: 2rem 1.5rem;
        border-radius: 0.3rem;
        background-color: $color-white;
        box-shadow: var(--box-shadow);

        @include min-screen($screen__l) {
            padding: 3rem 3rem;
        }
    }

    &--chevron {
        @include lib-background-image('svg/chevron-left.svg', center center, no-repeat, transparent);

        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
    }

    &_common-questions {
        padding: 2rem 1.5rem 0.5rem;
        border-radius: 0.3rem;
        background-color: $color-white;
        box-shadow: var(--box-shadow);

        @include min-screen($screen__l) {
            padding: 3rem 3rem 0.5rem;
        }

        .#{$css-namespace}contact-page {
            &_subtitle {
                margin-bottom: 1rem;

                @include max-screen($screen__l) {
                    margin-bottom: 0;
                }
            }
        }

        &--list {
            > ul {
                @include lib-list-reset-styles();
                position: relative;

                &.#{$css-namespace}loader {
                    min-height: 16rem;
                }
            }

            li {
                display: flex;
                align-items: center;
                margin: 0;

                + li {
                    border-top: 0.1rem solid $color-gray83;
                }

                a {
                    position: relative;
                    display: inline-block;
                    flex: 1;
                    padding-top: 1.95rem;
                    padding-bottom: 1.75rem;
                    padding-right: $space-4;
                    transition:
                        padding-left 0.3s,
                        padding-right 0.3s;

                    @include lib-hover-focus {
                        padding-left: 1rem;
                        padding-right: 2.2rem;

                        &::before {
                            opacity: 1;
                            transition: opacity 0.3s 0.1s; // TODO : Uniformise using var(--transition-normal)
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        display: block;
                        width: 0.5rem;
                        background-color: $color-secondary;
                        opacity: 0;
                        transition: opacity 0.3s; // TODO : Uniformise using var(--transition-normal)
                    }

                    &::after {
                        font-family: $font-icons;
                        content: $caret-right;
                        color: $color-contact-faq-arrow;
                        height: 1.6rem;
                        display: inline;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        translate: 0 -50%;
                    }
                }
            }
        }
    }

    &_stores {
        .#{$css-namespace}contact-page_subtitle--black {
            line-height: 1.75;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 2.2rem;
        }

        .#{$css-namespace}btn {
            // TODO : Refacto needed using var ?
            min-width: 25.7rem;
        }
    }

    fieldset {
        margin-bottom: 2.7rem;
    }

    .select-container {
        display: flex;
        flex-direction: column;

        select.#{$css-namespace}select--custom {
            display: block !important;
            pointer-events: none;
            height: 4rem;
            border-color: $color-grey-dove;
            appearance: none;

            &[style='display: none;'] {
                position: absolute;
                opacity: 0;
            }
        }

        div.mage-error {
            order: 9;
        }
    }

    .#{$css-namespace}select--custom {
        @include min-screen($screen__l) {
            width: 45rem !important;
        }
    }

    .nice-select {
        span {
            color: $color-gray43 !important;
            opacity: 1 !important;
        }

        &::after {
            border-bottom-color: $color-primary;
            border-right-color: $color-primary;
        }

        ~ label {
            background-color: $color-white;
            margin-left: 0.1rem;
            width: calc(100% - 3rem);
            color: $color-grey-dove;

            @include min-screen($screen__l) {
                width: 40rem;
            }
        }

        &.open {
            ~ label {
                width: auto;
            }
        }

        &.--hasValue {
            .current {
                color: $color-black !important;
            }
        }

        .list {
            cursor: default;

            .option {
                cursor: pointer;
            }

            .option:not([data-value='']) {
                padding-left: 4rem !important;
            }

            .option[data-value=''] {
                display: block !important;
                font-weight: $font-weight-bold;
                pointer-events: none;
                cursor: default;

                &:first-child:not(.disabled) {
                    display: none !important;
                }
            }

            .option[data-value='placeholder'] {
                display: none;
            }
        }
    }

    &_field-is-customer {
        .#{$css-namespace}form-field-wrapper {
            display: flex;
            align-items: center;
            gap: 1.5rem;
        }

        span {
            display: inline-flex;
            gap: 0.5rem;
        }

        input[type='radio'] {
            margin: 0;
        }
    }

    &_field-customer-id {
        display: none;

        &.active {
            display: block;
        }
    }

    .#{$css-namespace}form-field-wrapper {
        position: relative;
    }

    .#{$css-namespace}select--custom,
    .#{$css-namespace}input-text,
    .#{$css-namespace}textarea_text {
        @include max-screen($screen__l) {
            width: 100% !important;
        }
    }

    .#{$css-namespace}input-text {
        width: 38.7rem;

        &#customerId {
            width: 25rem !important;
        }

        &#phonenumber {
            width: 15rem !important;
        }

        &#shop:not(.mage-error) ~ .mage-error.custom {
            display: none;
        }

        &--blue {
            border-color: $color-input-autocomplete-search-border !important;
        }
    }

    &_search-field {
        .control {
            width: 100%;

            @include min-screen($screen__l) {
                width: fit-content;
            }
        }

        .#{$css-namespace}input-text {
            padding-right: 5.5rem !important;
            color: $color-secondary;
            font-weight: $font-weight-bold;
        }

        &-submit-button {
            height: 4rem;
            width: 4rem;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: $color-input-autocomplete-search-btn;
            border: 0;
            border-top-right-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;

            .dcfonts-icons {
                font-size: 2.4rem;
            }
        }

        &-remove-button {
            position: absolute;
            right: 4rem;
            top: 0.3rem;
            background: none;
            border: none;

            &:hover,
            &:focus {
                background: none;
                border: none;
            }

            .dcfonts-icons--cross {
                color: $color-primary;
            }
        }
    }
}
