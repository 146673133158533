@font-face {
  font-family: "dcfonts-icons";
  src: url("../fonts/webFonts/dcfonts-icons.woff2?c9ef2ccad823f610a8eb30bb9240f580") format("woff2");
  font-display: swap;
}

.dcfonts-icons {
  font-family: 'dcfonts-icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.dcfonts-icons, .dc-icons {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.dc-icons {
  width: 1em;
  height: 1em;
  position: relative;
  fill: currentColor;
}

// variable : contenu
$add-pdf: "\f101";
$add-wishlist: "\f102";
$arrow-left: "\f103";
$arrow-right: "\f104";
$arrows-left-right: "\f105";
$assistance: "\f106";
$attachement: "\f107";
$bars: "\f108";
$box-search: "\f109";
$box-stopwatch: "\f10a";
$box-v2: "\f10b";
$box: "\f10c";
$calendar-alt: "\f10d";
$calendar-mark: "\f10e";
$calendar-money: "\f10f";
$calendar: "\f110";
$caret-down: "\f111";
$caret-left: "\f112";
$caret-right: "\f113";
$caret-up: "\f114";
$cart-reorder: "\f115";
$cart-v2: "\f116";
$cart: "\f117";
$catalog-2: "\f118";
$catalog: "\f119";
$check-circle-arrow: "\f11a";
$check-circle: "\f11b";
$check-line: "\f11c";
$check: "\f11d";
$clock-circle: "\f11e";
$clock-fast: "\f11f";
$close-circle-2: "\f120";
$close-line: "\f121";
$commande-rapide-v2: "\f122";
$commande-rapide: "\f123";
$comments-question: "\f124";
$commercial-contact: "\f125";
$compte-client-v2: "\f126";
$compte-client: "\f127";
$copy: "\f128";
$cross: "\f129";
$customer-service: "\f12a";
$discount-percent-circle: "\f12b";
$download: "\f12c";
$edit-2: "\f12d";
$edit-default: "\f12e";
$edit-square-2: "\f12f";
$edit-square: "\f130";
$edit: "\f131";
$error-warning: "\f132";
$eye-slash: "\f133";
$eye-v2: "\f134";
$eye: "\f135";
$fidelity: "\f136";
$file-add: "\f137";
$file-download: "\f138";
$file-list: "\f139";
$file-pdf: "\f13a";
$filters: "\f13b";
$flashlight: "\f13c";
$handshake-2: "\f13d";
$handshake: "\f13e";
$heart-full: "\f13f";
$heart-plus: "\f140";
$home-v2: "\f141";
$home: "\f142";
$icon-list-v2: "\f143";
$icon-list: "\f144";
$info-2: "\f145";
$info: "\f146";
$information-circle: "\f147";
$information: "\f148";
$invoice: "\f149";
$location-check: "\f14a";
$location-map: "\f14b";
$location: "\f14c";
$lock-2: "\f14d";
$lock: "\f14e";
$logo-minify: "\f14f";
$logout: "\f150";
$magasin-2: "\f151";
$magasin-v2: "\f152";
$magasin: "\f153";
$magn-glass-plus: "\f154";
$mail: "\f155";
$map-france: "\f156";
$megaphone: "\f157";
$minus: "\f158";
$missing-product: "\f159";
$new-window: "\f15a";
$organization: "\f15b";
$paper-clip: "\f15c";
$parameters: "\f15d";
$people: "\f15e";
$phone-arrow: "\f15f";
$phone-call: "\f160";
$phone-circle: "\f161";
$phone: "\f162";
$pick-up-half-day-v2: "\f163";
$pick-up-half-day: "\f164";
$planet: "\f165";
$play-circle: "\f166";
$plus-circle: "\f167";
$plus-square: "\f168";
$plus: "\f169";
$print: "\f16a";
$product-page-list-display: "\f16b";
$product-page-table-display: "\f16c";
$question-circle: "\f16d";
$retrait-2h-v2: "\f16e";
$retrait-2h: "\f16f";
$save: "\f170";
$search-bar-v2: "\f171";
$search-bar: "\f172";
$search: "\f173";
$send-email: "\f174";
$share_v2: "\f175";
$share: "\f176";
$shop: "\f177";
$shopping-cart: "\f178";
$squares: "\f179";
$star-shine-full: "\f17a";
$star-shine: "\f17b";
$star: "\f17c";
$store: "\f17d";
$tape-mesure: "\f17e";
$target: "\f17f";
$tarification: "\f180";
$technician: "\f181";
$trash: "\f182";
$trophy: "\f183";
$truck-2: "\f184";
$truck-old: "\f185";
$truck: "\f186";
$two-hours: "\f187";
$upload: "\f188";
$user-status: "\f189";
$user: "\f18a";
$users: "\f18b";
$warning-triangle: "\f18c";
$warning: "\f18d";
$wishlist: "\f18e";
$wishlist2: "\f18f";

.dcfonts-icons {
    &--add-pdf:before {
        content : "\f101";
    }
    &--add-wishlist:before {
        content : "\f102";
    }
    &--arrow-left:before {
        content : "\f103";
    }
    &--arrow-right:before {
        content : "\f104";
    }
    &--arrows-left-right:before {
        content : "\f105";
    }
    &--assistance:before {
        content : "\f106";
    }
    &--attachement:before {
        content : "\f107";
    }
    &--bars:before {
        content : "\f108";
    }
    &--box-search:before {
        content : "\f109";
    }
    &--box-stopwatch:before {
        content : "\f10a";
    }
    &--box-v2:before {
        content : "\f10b";
    }
    &--box:before {
        content : "\f10c";
    }
    &--calendar-alt:before {
        content : "\f10d";
    }
    &--calendar-mark:before {
        content : "\f10e";
    }
    &--calendar-money:before {
        content : "\f10f";
    }
    &--calendar:before {
        content : "\f110";
    }
    &--caret-down:before {
        content : "\f111";
    }
    &--caret-left:before {
        content : "\f112";
    }
    &--caret-right:before {
        content : "\f113";
    }
    &--caret-up:before {
        content : "\f114";
    }
    &--cart-reorder:before {
        content : "\f115";
    }
    &--cart-v2:before {
        content : "\f116";
    }
    &--cart:before {
        content : "\f117";
    }
    &--catalog-2:before {
        content : "\f118";
    }
    &--catalog:before {
        content : "\f119";
    }
    &--check-circle-arrow:before {
        content : "\f11a";
    }
    &--check-circle:before {
        content : "\f11b";
    }
    &--check-line:before {
        content : "\f11c";
    }
    &--check:before {
        content : "\f11d";
    }
    &--clock-circle:before {
        content : "\f11e";
    }
    &--clock-fast:before {
        content : "\f11f";
    }
    &--close-circle-2:before {
        content : "\f120";
    }
    &--close-line:before {
        content : "\f121";
    }
    &--commande-rapide-v2:before {
        content : "\f122";
    }
    &--commande-rapide:before {
        content : "\f123";
    }
    &--comments-question:before {
        content : "\f124";
    }
    &--commercial-contact:before {
        content : "\f125";
    }
    &--compte-client-v2:before {
        content : "\f126";
    }
    &--compte-client:before {
        content : "\f127";
    }
    &--copy:before {
        content : "\f128";
    }
    &--cross:before {
        content : "\f129";
    }
    &--customer-service:before {
        content : "\f12a";
    }
    &--discount-percent-circle:before {
        content : "\f12b";
    }
    &--download:before {
        content : "\f12c";
    }
    &--edit-2:before {
        content : "\f12d";
    }
    &--edit-default:before {
        content : "\f12e";
    }
    &--edit-square-2:before {
        content : "\f12f";
    }
    &--edit-square:before {
        content : "\f130";
    }
    &--edit:before {
        content : "\f131";
    }
    &--error-warning:before {
        content : "\f132";
    }
    &--eye-slash:before {
        content : "\f133";
    }
    &--eye-v2:before {
        content : "\f134";
    }
    &--eye:before {
        content : "\f135";
    }
    &--fidelity:before {
        content : "\f136";
    }
    &--file-add:before {
        content : "\f137";
    }
    &--file-download:before {
        content : "\f138";
    }
    &--file-list:before {
        content : "\f139";
    }
    &--file-pdf:before {
        content : "\f13a";
    }
    &--filters:before {
        content : "\f13b";
    }
    &--flashlight:before {
        content : "\f13c";
    }
    &--handshake-2:before {
        content : "\f13d";
    }
    &--handshake:before {
        content : "\f13e";
    }
    &--heart-full:before {
        content : "\f13f";
    }
    &--heart-plus:before {
        content : "\f140";
    }
    &--home-v2:before {
        content : "\f141";
    }
    &--home:before {
        content : "\f142";
    }
    &--icon-list-v2:before {
        content : "\f143";
    }
    &--icon-list:before {
        content : "\f144";
    }
    &--info-2:before {
        content : "\f145";
    }
    &--info:before {
        content : "\f146";
    }
    &--information-circle:before {
        content : "\f147";
    }
    &--information:before {
        content : "\f148";
    }
    &--invoice:before {
        content : "\f149";
    }
    &--location-check:before {
        content : "\f14a";
    }
    &--location-map:before {
        content : "\f14b";
    }
    &--location:before {
        content : "\f14c";
    }
    &--lock-2:before {
        content : "\f14d";
    }
    &--lock:before {
        content : "\f14e";
    }
    &--logo-minify:before {
        content : "\f14f";
    }
    &--logout:before {
        content : "\f150";
    }
    &--magasin-2:before {
        content : "\f151";
    }
    &--magasin-v2:before {
        content : "\f152";
    }
    &--magasin:before {
        content : "\f153";
    }
    &--magn-glass-plus:before {
        content : "\f154";
    }
    &--mail:before {
        content : "\f155";
    }
    &--map-france:before {
        content : "\f156";
    }
    &--megaphone:before {
        content : "\f157";
    }
    &--minus:before {
        content : "\f158";
    }
    &--missing-product:before {
        content : "\f159";
    }
    &--new-window:before {
        content : "\f15a";
    }
    &--organization:before {
        content : "\f15b";
    }
    &--paper-clip:before {
        content : "\f15c";
    }
    &--parameters:before {
        content : "\f15d";
    }
    &--people:before {
        content : "\f15e";
    }
    &--phone-arrow:before {
        content : "\f15f";
    }
    &--phone-call:before {
        content : "\f160";
    }
    &--phone-circle:before {
        content : "\f161";
    }
    &--phone:before {
        content : "\f162";
    }
    &--pick-up-half-day-v2:before {
        content : "\f163";
    }
    &--pick-up-half-day:before {
        content : "\f164";
    }
    &--planet:before {
        content : "\f165";
    }
    &--play-circle:before {
        content : "\f166";
    }
    &--plus-circle:before {
        content : "\f167";
    }
    &--plus-square:before {
        content : "\f168";
    }
    &--plus:before {
        content : "\f169";
    }
    &--print:before {
        content : "\f16a";
    }
    &--product-page-list-display:before {
        content : "\f16b";
    }
    &--product-page-table-display:before {
        content : "\f16c";
    }
    &--question-circle:before {
        content : "\f16d";
    }
    &--retrait-2h-v2:before {
        content : "\f16e";
    }
    &--retrait-2h:before {
        content : "\f16f";
    }
    &--save:before {
        content : "\f170";
    }
    &--search-bar-v2:before {
        content : "\f171";
    }
    &--search-bar:before {
        content : "\f172";
    }
    &--search:before {
        content : "\f173";
    }
    &--send-email:before {
        content : "\f174";
    }
    &--share_v2:before {
        content : "\f175";
    }
    &--share:before {
        content : "\f176";
    }
    &--shop:before {
        content : "\f177";
    }
    &--shopping-cart:before {
        content : "\f178";
    }
    &--squares:before {
        content : "\f179";
    }
    &--star-shine-full:before {
        content : "\f17a";
    }
    &--star-shine:before {
        content : "\f17b";
    }
    &--star:before {
        content : "\f17c";
    }
    &--store:before {
        content : "\f17d";
    }
    &--tape-mesure:before {
        content : "\f17e";
    }
    &--target:before {
        content : "\f17f";
    }
    &--tarification:before {
        content : "\f180";
    }
    &--technician:before {
        content : "\f181";
    }
    &--trash:before {
        content : "\f182";
    }
    &--trophy:before {
        content : "\f183";
    }
    &--truck-2:before {
        content : "\f184";
    }
    &--truck-old:before {
        content : "\f185";
    }
    &--truck:before {
        content : "\f186";
    }
    &--two-hours:before {
        content : "\f187";
    }
    &--upload:before {
        content : "\f188";
    }
    &--user-status:before {
        content : "\f189";
    }
    &--user:before {
        content : "\f18a";
    }
    &--users:before {
        content : "\f18b";
    }
    &--warning-triangle:before {
        content : "\f18c";
    }
    &--warning:before {
        content : "\f18d";
    }
    &--wishlist:before {
        content : "\f18e";
    }
    &--wishlist2:before {
        content : "\f18f";
    }
}
