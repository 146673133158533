.customer {
    &-account {
        display: flex;
        flex-direction: column;
    }

    &-dropdown-wrap {
        &.active {
            @include min-screen($screen__m) {
                position: relative;
            }

            .customer-submenu {
                display: flex !important;
            }
        }
    }

    &-submenu {
        position: absolute;
        top: calc(100% - #{$search-bar-height});
        width: 33rem;
        height: auto;
        max-height: 80vh;
        overflow-y: auto;
        background-color: $defaultColor;
        box-shadow: var(--box-shadow);
        border-radius: $space-1;
        z-index: $dropdown-index;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        font-size: $font-size-s;
        padding: $space-2;
        flex-direction: column;
        gap: $space-1;
        @extend %scrollbars !optional;
        scrollbar-width: thin;
        scrollbar-color: $color-secondary transparent;

        @include min-screen($screen__m) {
            top: calc(100% + #{$space-1});
            left: auto;
            right: $shift-right;
            transform: none;
        }

        .--scrolled & {
            @include max-screen($screen__m) {
                top: calc(100% + #{$space-1});
            }
        }

        &--account {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: $space-1;
            text-align: center;
        }

        &--name {
            font-size: $font-size-l;
            font-weight: $font-weight-bold;
        }

        &--email {
            font-size: $font-size-s;
        }

        &--shortcuts {
            padding: 0 $space-2;
            gap: $space-1;
            display: flex;
            flex-wrap: wrap;
        }

        &--shortcut {
            flex: 1 0 45%;
            margin: 0;
        }

        &--link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: $space-1;
            color: $color-dark-grey !important; // Important because a:visited on the body.scss
            height: 100%;
            padding: $space-2;

            i {
                font-size: 2.4rem;

                &.-hasQuotation {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        top: -0.2rem;
                        right: -$space-1;
                        background-color: $color-secondary;
                        width: 0.8rem;
                        height: 0.8rem;
                        border-radius: 100%;
                    }
                }
            }

            span {
                text-align: center;
                margin-top: auto;
                margin-bottom: auto;
                font-weight: $font-weight-semi-bold;
            }
        }

        &--shops {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: $space-1;
        }

        &--shop {
            margin: 0;
        }

        &--adding {
            padding: $space-2 $space-3;

            i {
                font-size: $font-size-xs;
                color: $color-primary;
            }
        }

        &--letter {
            width: 2.8rem;
            height: 2.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background-color: $color-disabled;
            color: $color-white;
            font-weight: $font-weight-semi-bold;
            text-transform: uppercase;
            position: relative;
            transition: background-color var(--transition-normal);

            i {
                position: absolute;
                bottom: -0.6rem;
                right: -0.6rem;
                background-color: $color-white;
                border-radius: 100%;
                border: 0.15rem solid $color-secondary;
                padding: $space-1 / 2;
                font-size: $font-size-xxs;
                color: $color-secondary;
                display: none;
            }

            &.-connected {
                background-color: $color-secondary;

                i {
                    display: flex;
                }
            }
        }

        &--data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: start;
            flex: 1;
        }

        &--agency {
            font-weight: $font-weight-regular;
        }

        &--switch,
        &--adding {
            display: flex;
            gap: $space-2;
            width: 100%;
            border: 0;
            font-weight: $font-weight-semi-bold;
            align-items: center;
        }

        &--shortcuts,
        &--shops {
            list-style-type: none;
            margin: 0;
        }

        &--switch {
            font-size: $font-size-s;
            padding: $space-1 $space-3;
            text-align: left;

            &:not(:disabled) {
                &:hover,
                &:focus-within {
                    .customer-submenu--letter {
                        background-color: $color-secondary;
                    }
                }
            }
        }

        &--link,
        &--switch,
        &--adding {
            background-color: $color-grey-background;
            border-radius: $space-1;
            transition: background-color var(--transition-normal);

            &:hover,
            &:focus-within {
                background-color: rgba($color-secondary, 0.1);
                border: 0;
            }
        }

        .account-edit-link {
            text-decoration: underline;
        }

        .my-account-referent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: $indent__base;
        }

        .referent-phone {
            color: $color-blue1;
            font-weight: $font-weight__regular;
            font-size: $font-size-s;
        }
    }
}

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;

    .header.content {
        .logo {
            width: 13rem;
            height: 2.5rem;
        }
    }

    .#{$css-namespace}account-links {
        display: flex;
        align-items: flex-end;
    }

    .panel.wrapper {
        .store-locator-link,
        .wishlist-link,
        .quick-order-link,
        .quotation-link {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $color-gray-light7;
            height: 6.5rem;
            box-sizing: border-box;
            border: 0.5rem solid $defaultColor;
            padding: 0 $indent__xs;

            &:hover {
                border-bottom: 0.5rem solid $color-secondary;
            }
        }

        .active {
            .store-locator-link,
            .wishlist-link,
            .quick-order-link,
            .quotation-link {
                border-bottom: 0.5rem solid $color-secondary;
            }
        }

        .header.store {
            .ui-dialog {
                top: 50%;
            }
        }

        .header-icon-wrapper {
            &-quotation {
                display: none;

                @include min-screen($screen__l) {
                    display: block;
                    position: relative;
                }
            }
        }
    }

    .header {
        .#{$css-namespace}btn-menu {
            @include min-screen($screen__l) {
                display: none;
            }

            background: none;
            border: none;
            left: 0;
            top: 0;
            padding: 1rem;
            max-height: 4.5rem;

            .#{$css-namespace}icon-menu {
                width: 2.4rem;
                height: 2.4rem;
                color: $color-primary;
                font-size: 2.4rem;
            }
        }
    }
}

@include min-screen($screen__xs) {
    .page-wrapper {
        .page-header {
            .header.content {
                .logo {
                    width: 17.5rem;
                    height: 3.2rem;
                    padding-left: $indent__s;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .page-wrapper {
        .page-header {
            .#{$css-namespace}panel-container {
                margin-left: auto;
                min-height: 3.2rem;
                color: $color-gray-light7;
                height: 6.5rem;
            }

            .panel.wrapper {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                max-width: 132.5rem;
                margin: 0 auto;

                .counter.qty {
                    top: 0;
                    right: -1rem;
                }

                .customer-dropdown-wrap {
                    position: relative;
                }

                > .header-icon-wrapper {
                    display: flex;
                    align-items: center;
                    height: 6.5rem;
                    position: relative;
                }

                .header-icon-wrapper {
                    .header-icon-text {
                        margin-top: 0.3rem;
                        line-height: 1.25;
                    }
                }

                .wishlist-link {
                    display: inline-flex;
                    flex-direction: column;
                    text-decoration: none;

                    .text {
                        font-size: $font-size-s;
                        color: $color-sub-secondary;
                        text-decoration: none;
                        font-weight: $font-weight-medium;
                    }
                }

                .link.quick-order,
                .link.quotation {
                    .quick-order-link,
                    .quotation-link {
                        display: inline-flex;
                        flex-direction: column;

                        .icon-quick-order {
                            width: 2.3rem;
                            height: 2.2rem;
                            margin: 0;
                            fill: $color-sub-secondary;
                        }

                        .text {
                            font-size: $font-size-s;
                            font-weight: $font-weight-medium;
                            color: $color-sub-secondary;
                            text-decoration: none;
                        }
                    }
                }

                .#{$css-namespace}link-logout-wrapper {
                    .#{$css-namespace}link-logout {
                        display: none;

                        &.isLogged {
                            display: inline-flex;
                        }
                    }
                }

                .#{$css-namespace}link-logout {
                    .#{$css-namespace}icon {
                        width: 1.3rem;
                        height: 1.5rem;
                    }
                }
            }

            .header.content {
                .#{$css-namespace}account-link-wrapper {
                    display: none;
                }
            }
        }
    }
}

@include screen($screen__l, $layout__max-width) {
    .page-wrapper {
        .page-header {
            padding: 0 $indent__m;
        }
    }
}

@include max-screen($screen__l) {
    .page-wrapper {
        .page-header {
            .header.content {
                max-height: 8.7rem;

                .panel.wrapper {
                    display: flex;

                    .customer-submenu {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                        top: calc(
                            var(--header-height) - 4rem
                        ); // 4rem is the height of .#{$css-namespace}searchbar_input
                        box-shadow: 0 0.5rem 0.5rem 0 $color-gray60; // TODO: Use box-shadow var
                    }
                }

                .logo {
                    img {
                        height: 4.5rem;
                        width: auto;
                    }
                }

                .wishlist-link {
                    display: none;
                }

                .header.store {
                    .agency-submenu {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                        top: 4.5rem;
                        text-align: left;
                        box-shadow: 0 0.5rem 0.5rem 0 $color-gray60; // TODO: Use box-shadow var
                    }

                    .store-locator-link {
                        .store-informations {
                            display: none;
                        }
                    }
                }
            }

            .#{$css-namespace}panel-container {
                order: 2;
                margin: 0 0.5rem 0 auto;
            }

            .panel.wrapper {
                .store-locator-link,
                .wishlist-link,
                .quick-order-link,
                .quotation-link {
                    max-height: 4.5rem;
                    margin-left: 0;
                }

                .header.store {
                    display: flex;
                    align-items: flex-end;
                }
            }

            .header.store {
                .store-locator-link {
                    .store-informations {
                        display: none;
                    }

                    .none {
                        display: none;
                    }
                }
            }
        }
    }
}
