$facet-horizontal-spacing: $space-2 !default;
$facet-vertical-spacing: $space-2 !default;
$facet-item-spacing: $space-1 !default;
$facet-icon-angle-size: 1rem !default;
$facet-icon-angle-offset: 0.2rem !default;
$facet-step-offset-x-cat3: $facet-horizontal-spacing + $facet-item-spacing + $facet-item-spacing; // cat2 horizontal offset + item spacing + angle icon padding
$facet-step-offset-x-cat4: $facet-horizontal-spacing + $facet-item-spacing + $facet-icon-angle-offset; // cat3 horizontal offset
$facet-checkbox-size: 1.6rem !default;

.facet {
    &__filter {
        display: none;
        padding: $facet-item-spacing $facet-horizontal-spacing $facet-vertical-spacing;
        flex-direction: column;
        gap: $facet-item-spacing;

        @include min-screen($screen__l) {
            display: flex;
        }
    }

    &__block {
        margin-top: $space-1;

        @include min-screen($screen__l) {
            margin: 0;
        }
    }

    &__header {
        display: none;
        align-items: center;
        justify-content: center;
        font-size: $font-size-l;
        padding: $facet-vertical-spacing $facet-horizontal-spacing;
        margin: 0;
        background-color: $color-primary;
        color: $defaultColor;
        font-weight: $font-weight__semibold;
        position: relative;

        @include min-screen($screen__l) {
            display: flex;
            justify-content: flex-start;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        cursor: pointer;
        line-height: $line-height-default;
        font-weight: $font-weight-bold;
        padding: $space-1 0;
        user-select: none;

        &--active-filter {
            display: none;

            @include min-screen($screen__l) {
                display: block;
                padding-top: $facet-vertical-spacing;
            }
        }
    }

    &__list {
        display: none;
        position: relative;
        padding: $facet-item-spacing;
        margin-bottom: 0;
        flex-direction: column;

        &:last-child {
            padding-bottom: 0;

            &.facet__list--opened.no-gap:has(> li.facet__item--current-categ) {
                padding-bottom: $facet-vertical-spacing;
            }
        }

        @include min-screen($screen__l) {
            border-top: none;
        }

        &--opened {
            display: flex;

            li {
                p {
                    margin-bottom: 0;
                    line-height: 1;
                }
            }
        }

        &:not(.no-gap) {
            gap: $facet-vertical-spacing;
        }

        &__active {
            align-items: flex-start;
            flex-wrap: wrap;
            flex-direction: row;
            gap: $facet-item-spacing;
            padding: 0;
        }

        .cat3 {
            + li {
                &::before {
                    content: '';
                    width: $facet-icon-angle-size;
                    height: $facet-icon-angle-size;
                    border-left: 0.1rem solid $color-primary;
                    border-bottom: 0.1rem solid $color-primary;
                    left: $space-2;
                    top: $facet-icon-angle-offset * 3; // Temporary position before icon size standardization with the same padding (0.2rem)
                    position: absolute;
                }
            }

            > a,
            ~ li a {
                padding-left: $facet-item-spacing;
            }

            ~ li {
                margin-left: $facet-step-offset-x-cat4;
                padding-left: $facet-horizontal-spacing;
            }
        }
    }

    &__cross {
        margin-left: $facet-item-spacing;
        cursor: pointer;

        &--white {
            color: $color-white;
        }

        &--hide-cross-desktop {
            @include min-screen($screen__l) {
                background-image: none;
                width: auto;
                height: auto;
                background-color: inherit;
            }
        }

        &--right {
            position: absolute;
            right: 2rem;
        }

        &--no-margin {
            margin: 0;
        }

        &--show-desktop {
            @include min-screen($screen__l) {
                display: block;
            }
        }
    }

    &__category {
        list-style: none;

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }

        &__name {
            font-weight: $font-weight-medium;
        }

        &__icon {
            font-size: $font-size-m;
            color: $color-secondary;
            margin-left: $facet-item-spacing;
            line-height: 1;

            &.opened .dcfonts-icons--plus::before {
                content: $minus;
            }
        }

        &-list {
            margin-top: $facet-vertical-spacing;
            padding: 0;
        }
    }

    &__item {
        list-style-type: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        margin-bottom: 0;

        &--inline {
            border: 0.1rem solid $color-primary;
            border-radius: 2.6rem;
            padding: $space-1 2rem $space-1 $space-3;
            color: $color-primary;
            font-weight: $font-weight__regular;
            display: inline-flex;
            gap: $space-1;
            cursor: default;

            .facet__cross {
                font-size: $font-size-s;
                margin: 0;
            }
        }

        &--hidden {
            display: none;
        }

        &--categ {
            margin: 0 0 0 $facet-step-offset-x-cat3;
            padding: 0 $facet-item-spacing;
            color: $color-primary;
            line-height: 1;
            position: relative;

            @include min-screen($screen__l) {
                border-bottom: none;
            }
        }

        &--current {
            color: $color-sub-secondary;
            font-weight: $font-weight__bold;
        }

        &--parent-categ {
            font-size: $font-size-default;
            line-height: 1.428;
            font-weight: $font-weight-regular;
            margin: 0;
            padding: $facet-item-spacing;

            &.search {
                padding: $facet-item-spacing 0;
            }

            a {
                display: flex;
                align-items: center;

                > .#{$css-namespace}icon {
                    font-size: $font-size-m;
                    line-height: 1;
                    color: $color-primary;
                    margin-right: $facet-item-spacing;
                }

                > .categ-title {
                    align-self: flex-end;
                }
            }
        }

        &--remove-all {
            width: 100%;
        }

        &.cat3 {
            margin: 0 0 0 $facet-step-offset-x-cat3;
            padding: 0 $space-2 0 $facet-icon-angle-size;
            color: $color-primary;
            font-weight: $font-weight-bold;
            line-height: $line-height-default;
            position: relative;

            a {
                margin: unset;
            }

            :before {
                content: '';
                width: $facet-icon-angle-size;
                height: $facet-icon-angle-size;
                border-left: 0.1rem solid $color-primary;
                border-bottom: 0.1rem solid $color-primary;
                left: 0;
                top: $facet-icon-angle-offset;
                position: absolute;
            }

            ~ .facet__show-less-categ,
            ~ .facet__show-more-categ {
                margin-left: $facet-step-offset-x-cat4 + $facet-item-spacing + $facet-item-spacing +
                    $facet-icon-angle-offset; // similar offset items as cat3 parent categ
            }
        }

        a:visited {
            color: inherit;
        }
    }

    &__checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: $facet-checkbox-size;
        width: $facet-checkbox-size;
        top: 0;
        left: 0;
    }

    &__number {
        background-color: transparent;
        font-size: $font-size-default;
        margin-left: $space-1 / 2;
        display: inline-flex;
        font-weight: $font-weight__light;

        &--default {
            color: inherit;
            background-color: inherit;
            font-size: inherit;
        }

        &__show-more {
            margin-left: 5.5rem;
        }

        &__show-less {
            margin-left: 5.5rem;
        }

        &--hide-desktop {
            @include min-screen($screen__l) {
                display: none;
            }
        }

        &--round {
            justify-content: center;
            align-items: center;
            color: $color-primary;
            background-color: $color-background-filters;
            font-size: $font-size__s;
            font-style: normal;
            font-weight: $font-weight__heavier;
            letter-spacing: 0;
            text-align: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
        }

        &--smaller {
            width: 2.2rem;
            height: 2.2rem;
        }
    }

    &__text {
        color: $color-gray-light7;

        &--only-desktop {
            display: none;
            @include min-screen($screen__l) {
                display: inline-block;
            }
        }

        &--underline {
            text-decoration: underline;
            color: $color-secondary;
            font-size: $font-size__s;
            font-weight: $font-weight__heavier;
            margin-top: $space-2;
        }
    }

    &__buttons {
        margin-bottom: $facet-vertical-spacing;
        gap: $facet-vertical-spacing;
        text-align: center;
        justify-content: space-between;
        display: flex;

        @include min-screen($screen__l) {
            display: block;
            text-align: left;
        }

        &--hide-desktop {
            @include min-screen($screen__l) {
                display: none;
            }
        }
    }

    &__label_checkbox {
        pointer-events: none;
        display: inline-flex;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .facet__number {
            font-size: inherit;
            display: inline;
        }

        @include min-screen($screen__l) {
            @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                max-width: 21rem;
            }
        }

        input:checked ~ .facet__checkmark {
            background-color: $color-secondary;

            &::after {
                display: block;
            }
        }

        &--checked {
            color: $color-gray-light7;
        }
    }

    &__checkmark {
        position: absolute;
        border: 0.1rem solid $color-disabled-light;
        top: 0;
        left: 0;
        height: $facet-checkbox-size;
        width: $facet-checkbox-size;
        border-radius: 0.3rem;

        @include lib-hover-focus {
            background-color: $color-gray80;
        }

        &:checked {
            background-color: $color-sub-secondary;
            border: none;
        }

        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 0.5rem;
            top: 0.2rem;
            width: 0.4rem;
            height: 0.8rem;
            border: solid $color-white;
            border-width: 0 0.2rem 0.2rem 0;
            transform: rotate(45deg);
        }
    }

    &__checkmark_name {
        margin-left: $facet-checkbox-size + $facet-item-spacing;
    }

    &__active {
        padding: 0 $facet-horizontal-spacing;
        display: none;

        &:has(.facet__item--inline) {
            display: block;
        }
    }

    &__show-less,
    &__show-more {
        color: $color-secondary;
        line-height: $line-height-default;
        font-weight: $font-weight-medium;
        font-size: $font-size-s;
        text-decoration: underline;
    }

    &--hide-desktop {
        @include min-screen($screen__l) {
            display: none;
        }
    }

    &__container {
        position: relative;
        overflow: hidden;
        background-color: $color-white;
        display: none;

        @include min-screen($screen__l) {
            display: block;
        }

        &--opened {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            background-color: $color-white;
            left: 0;
            right: 0;
            z-index: 151; // To be superior to header__wrapper z-index
            overflow-y: scroll;
            padding-bottom: $space-10;
        }
    }

    &__footer {
        align-items: center;
        display: none;
        position: fixed;
        z-index: 25;
        left: 0;
        right: 0;
        bottom: -0.1rem;
        width: 100%;
        height: 8rem;
        background-color: $color-white;
        box-shadow: 0 1.8rem 1.5rem 1.5rem $color-gray60; // TODO: Use box-shadow var
    }

    &__button {
        margin: 1rem;
        width: 50%;
        text-align: center;
        height: 5rem;

        &.action {
            font-size: $font-size__s;
            font-weight: $font-weight__heavier;
            text-transform: none;
            color: $color-white;
        }

        &.primary {
            background: $color-primary;
            border-color: $color-primary;

            @include lib-hover-focus {
                background-color: $color-white;
                border-color: $color-primary;
                color: $color-primary;
            }
        }

        &--white {
            background-color: $color-white;
        }
    }

    &__icon {
        font-size: $font-size-xl;

        &::before {
            content: $plus;
            color: $color-secondary;
            font-size: inherit;
        }

        @include min-screen($screen__l) {
            font-size: $font-size-m;
        }

        &--less::before {
            content: $minus;
        }

        &--left {
            margin-left: auto;
        }
    }

    &__categories {
        display: none;
        flex-direction: column;

        @include min-screen($screen__l) {
            display: block;
            @include lib-css-box-shadow($params: false, $default: true);

            @include lib-hover-focus {
                @include lib-css-box-shadow-hover($params: false, $default: true);
            }
        }

        .facet__filter {
            gap: $space-1;
        }
    }

    &__img_categ {
        width: 3rem;
        height: 3rem;
        margin-right: $space-2;
    }

    &__link_categ {
        display: flex;
        align-items: center;
        margin-left: $facet-icon-angle-size;
        padding: 0.5rem 0;
        width: 100%;
        color: $color-primary;

        &--no-border {
            border: none;
        }
    }

    &__show-more-categ,
    &__show-less-categ {
        margin: $facet-item-spacing 0 $facet-item-spacing $facet-horizontal-spacing;
        padding: 0 $facet-horizontal-spacing;
        color: $color-secondary;
        text-decoration: underline;
        font-size: $font-size__s;
        font-weight: $font-weight__heavier;
    }

    &__btn_filter {
        color: $defaultColor;
        border: none;
        cursor: pointer;
        display: inline-block;
        font-weight: $font-weight-semi-bold;
        padding: 1rem $space-3; // Cause 0.8*2 egal to 1 in Figma on odd days
        font-size: $font-size-default;
        line-height: 1;
        box-sizing: border-box;
        vertical-align: middle;
        text-transform: none;
        position: relative;
        width: calc(50% - #{$space-1});
        white-space: nowrap;

        @include lib-hover-focus {
            border-color: transparent; // prevent .action.primary to change border-color
        }

        @include min-screen($screen__l) {
            font-size: $font-size-l;
            padding: $facet-vertical-spacing $facet-horizontal-spacing;
        }

        &__label {
            position: relative;
            display: inline-block;
            max-height: $font-size__l;
        }

        &.facet__btn_filter {
            @include lib-hover-focus {
                border: 0;
                background-color: $color-blue-very-dark;
                color: $color-white;
            }
        }
    }

    &__name_categ {
        line-height: 1.428;
    }

    ~ .facet {
        @include min-screen($screen__l) {
            margin-top: $space-3;
        }
    }
}

.sidebar {
    .filter-products-categories {
        background-color: $color-primary;
    }

    .title-search,
    .filter-products {
        background-color: $color-secondary;
        display: flex;
        justify-content: center;
        gap: $facet-item-spacing;

        @include min-screen($screen__l) {
            gap: $space-2;
            justify-content: flex-start;
        }

        .dcfonts-icons {
            font-size: $font-size-default;
        }

        .facet__cross {
            font-size: $font-size-l;
        }
    }

    .remove-filter {
        background-color: $color-secondary;
        border-color: $color-primary;
    }

    .facet__btn_filter,
    .remove-filter {
        border-radius: 0.3rem;
    }
}
