// @TODO: This file will be replaced by order/module

.#{$css-namespace}sales-order-view {
    .block-order-summary,
    .block-order-addresses {
        font-size: 0;
    }

    .box.box-order-summary,
    .box.box-shipping-address {
        float: none !important;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
    }

    .block-order-addresses {
        @include lib-font-size(16);
    }

    .block-summary-actions,
    .box.box-billing-address {
        display: inline-block !important;
        width: 50% !important;
        box-sizing: border-box;
        padding: 0 0 0 $indent__s !important;

        .actions {
            padding: 0;
        }
    }

    .box.box-billing-address {
        @include lib-font-size(16);
    }

    .block-title {
        margin: $indent__m 0;
        border-bottom: 0;
        padding-bottom: 0;
        font-weight: $font-weight-regular;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .box-order-summary,
    .block-order-addresses,
    .box-billing-address {
        .box {
            .box-container {
                background: $color-gray-darken0;
                padding: $indent__s;
            }

            .order-box-title {
                @include lib-font-size(16);
                margin-bottom: $indent__s;

                > span {
                    &:not(.shipping-mode-content) {
                        font-weight: $font-weight__bold;
                        display: block;
                    }
                }
            }
        }
    }

    .box-orders {
        float: left;
        width: 45% !important;
        padding: 1rem;
        margin: 0;

        @include lib-shadow-block();

        .box-container,
        .order-totals {
            background: none !important;
            padding: 0 !important;
        }

        &:nth-child(2n) {
            float: right;
        }
    }

    .box-order-summary {
        .box-orders {
            width: 90.5% !important;
        }
    }

    .block-order-summary,
    .block-order-addresses {
        .box-orders {
            padding: 1rem !important;
            min-height: 15.5rem;
        }
    }
}

.block-summary-actions {
    button {
        display: block;
        text-align: center;
        margin-bottom: $indent__s;
        width: 100%;
    }
}

.billing-address-details {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        margin: 0;
    }
}

@include max-screen($screen__m) {
    .#{$css-namespace}sales-order-view {
        .block-summary-actions,
        .box.box-billing-address,
        .box.box-shipping-address {
            width: 100% !important;
            padding: 0;
        }
    }
}

@include max-screen($screen__l) {
    .box.box-order-summary {
        width: 100% !important;
    }
    .block-summary-actions,
    .box.box-billing-address {
        .actions {
            padding: 0;
        }
    }

    .order-items {
        .cart {
            &.item {
                padding-left: 0 !important;
            }
        }
    }
}

@include min-screen($screen__m) {
    .#{$css-namespace}sales-order-view {
        .block-order-addresses {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: space-between;

            > .box {
                flex: 0 1 auto;
            }
        }

        .box.box-order-summary,
        .box.box-shipping-address {
            width: calc(48% - 2.2rem) !important;
        }
    }
}

@include min-screen($screen__l) {
    .#{$css-namespace}sales-order-view {
        .box.box-order-summary,
        .box.box-shipping-address {
            width: 50%;
            padding: 0 $indent__s 0 0;
        }

        .cart.table-wrapper {
            .items {
                thead,
                tbody {
                    .col {
                        &.qty,
                        &.price {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .#{$css-namespace}sales-order-view {
        .#{$css-namespace}order-summary {
            //order-details-items
            .product-options-reference {
                display: block;
            }

            .cart.table-wrapper .items .cart.item .item-info {
                .product-item-photo-picto {
                    min-width: 8.1rem;
                    position: relative;
                    padding-right: 1rem;
                }

                .col.qty,
                .col.subtotal {
                    line-height: 1;
                    width: 50%;
                }
            }
        }

        .block-order-addresses {
            .box-orders {
                padding: 1rem;
            }
        }

        .box-order-summary {
            .box-orders {
                width: 94% !important;
            }
        }
    }
}
