.#{$css-namespace}checkout-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    min-height: 12.6rem;
    background-color: $color-white;
    box-shadow: 0.2rem 0.4rem 0.6rem 0 transparentize($color-black, 0.95); // TODO: Use box-shadow var
    max-width: $layout-checkout__max-width;
    margin: 0 auto;

    @include min-screen($screen__l) {
        flex-direction: row;
        padding: 0;
        min-height: initial;
        height: 6.5rem;
        box-shadow: none;

        &::before {
            content: '';
            width: var(--app-width);
            position: absolute;
            left: 50%;
            top: 0;
            height: 100%;
            background: $color-white;
            z-index: 0;
            transform: translateX(-50%);
        }
    }

    &_logo {
        margin-bottom: 2rem;
        content: url('../images/logo.svg');
        height: 2.4rem;
        width: auto;

        @include min-screen($screen__l) {
            position: absolute;
            left: 0;
            height: 3.2rem;
            margin-bottom: 0;
        }
    }

    .#{$css-namespace}progress-bar {
        margin: 0;
        width: 100%;

        @include min-screen($screen__l) {
            margin-bottom: 0.5rem;
            width: 55%;
        }

        &_container {
            @include min-screen($screen__l) {
                max-width: initial;
                padding: 0;
            }
        }
    }
}
