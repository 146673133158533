@supports (display: grid) {
    // default grid rules (mobile-first conception) | 4 cols
    [data-ui-grid='fixed'] {
        display: grid;
        grid-gap: $grid-min-gap;
        grid-template-columns: repeat($grid-columns-min, 1fr);

        /** Fixed grid = fixed columns **/
        > [data-ui-grid='col'] {
            grid-column: auto / span $grid-columns-min;

            // columns rules
            @include makeCol(1, $grid-columns-max, $grid-columns-min); // col *
            @include makeCol(1, $grid-columns-max, $grid-columns-min, 'xs'); // col xs
        }

        // 8 cols
        @media screen and (min-width: $screen-sm) {
            grid-gap: $grid-gap;
            grid-template-columns: repeat($grid-columns-medium, 1fr);

            > [data-ui-grid='col'] {
                grid-column: auto / span $grid-columns-medium;

                // columns rules
                @include makeCol(1, $grid-columns-max, $grid-columns-medium); // col *
                @include makeCol(1, $grid-columns-max, $grid-columns-medium, 'xs'); // col xs
                @include makeCol(1, $grid-columns-max, $grid-columns-medium, 'sm'); // col sm
            }
        }

        // 12 cols
        @media screen and (min-width: $screen-sm-2) {
            grid-gap: $grid-max-gap;
            grid-template-columns: repeat($grid-columns-max, 1fr);

            > [data-ui-grid='col'] {
                grid-column: auto / span $grid-columns-max;
                // columns rules
                @include makeCol(1, $grid-columns-max, $grid-columns-max); // col *
                @include makeCol(1, $grid-columns-max, $grid-columns-max, 'xs'); // col xs
                @include makeCol(1, $grid-columns-max, $grid-columns-max, 'sm'); // col sm
            }
        }

        // 12 cols
        @media screen and (min-width: $screen-md) {
            > [data-ui-grid='col'] {
                @include makeCol(1, $grid-columns-max, $grid-columns-max, 'md'); // col md
            }
        }

        // 12 cols
        @media screen and (min-width: $screen-lg) {
            > [data-ui-grid='col'] {
                @include makeCol(1, $grid-columns-max, $grid-columns-max, 'lg'); // col lg
            }
        }

        // 12 cols
        @media screen and (min-width: $screen-xl) {
            > [data-ui-grid='col'] {
                @include makeCol(1, $grid-columns-max, $grid-columns-max, 'xl'); // col xl
            }
        }
    }

    /** Fluid grid = column auto distribution **/
    [data-ui-grid='fluid'] {
        display: grid;
        grid-gap: $grid-min-gap;
        grid-template-columns: repeat($grid-columns-min, 1fr);

        // grid 4 cols
        @include makeGrid(1, $grid-columns-max, $grid-columns-min);

        // grid 8 cols
        @media screen and (min-width: $screen-sm) {
            grid-gap: $grid-gap;
            @include makeGrid(1, $grid-columns-max, $grid-columns-medium);
        }

        // 12 cols
        @media screen and (min-width: $screen-sm-2) {
            grid-gap: $grid-max-gap;
            @include makeGrid(1, $grid-columns-max, $grid-columns-max);
        }

        // For a column flow with 2 rows
        &[data-ui-flow='rows'] {
            @media screen and (min-width: $screen-sm) {
                grid-auto-flow: column;
                grid-template-rows: repeat(2, auto);
                grid-row-gap: 0;

                @media screen and (max-width: $screen-sm-2) {
                    grid-template-rows: repeat(4, auto);
                    grid-template-columns: repeat(2, 1fr);
                    grid-row-gap: $space-2;
                }
                @media screen and (min-width: $screen-sm-2) {
                    grid-template-rows: repeat(2, auto);
                    grid-row-gap: 0;
                }
            }
        }
    }
}
