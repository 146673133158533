//
//  Dropdowns
//  _____________________________________________

//
//  Simple dropdown
//  ---------------------------------------------

@mixin lib-dropdown(
  $_toggle-selector                           : ".action.toggle",
  $_options-selector                          : "ul.dropdown",

  $_dropdown-actions-padding                  : $dropdown-actions__padding,
  $_dropdown-list-min-width                   : $dropdown-list__min-width,
  $_dropdown-list-width                       : $dropdown-list__width,
  $_dropdown-list-height                      : $dropdown-list__height,
  $_dropdown-list-margin-top                  : $dropdown-list__margin-top,
  $_dropdown-list-position-top                : $dropdown-list__position-top,
  $_dropdown-list-position-bottom             : $dropdown-list__position-bottom,
  $_dropdown-list-position-left               : $dropdown-list__position-left,
  $_dropdown-list-position-right              : $dropdown-list__position-right,
  $_dropdown-list-background                  : $dropdown-list__background,
  $_dropdown-list-border                      : $dropdown-list__border,
  $_dropdown-list-pointer                     : $dropdown-list__pointer,
  $_dropdown-list-pointer-border              : $dropdown-list-pointer__border,
  $_dropdown-list-pointer-position            : $dropdown-list-pointer__position,
  $_dropdown-list-pointer-position-top        : $dropdown-list-pointer__position-top,
  $_dropdown-list-pointer-position-left-right : $dropdown-list-pointer__position-left-right,
  $_dropdown-list-item-border                 : $dropdown-list-item__border,
  $_dropdown-list-item-padding                : $dropdown-list-item__padding,
  $_dropdown-list-item-margin                 : $dropdown-list-item__margin,
  $_dropdown-list-item-hover                  : $dropdown-list-item__hover,
  $_dropdown-list-shadow                      : $dropdown-list__shadow,
  $_dropdown-list-z-index                     : $dropdown-list__z-index,

  $_dropdown-toggle-icon-content              : $dropdown-toggle-icon__content,
  $_dropdown-toggle-active-icon-content       : $dropdown-toggle-icon__active__content,

  $_icon-font                                 : $dropdown-toggle-icon__font,
  $_icon-font-size                            : $dropdown-toggle-icon__font-size,
  $_icon-font-line-height                     : $dropdown-toggle-icon__font-line-height,
  $_icon-font-color                           : $dropdown-toggle-icon__font-color,
  $_icon-font-color-hover                     : $dropdown-toggle-icon__font-color-hover,
  $_icon-font-color-active                    : $dropdown-toggle-icon__font-color-active,
  $_icon-font-margin                          : $dropdown-toggle-icon__font-margin,
  $_icon-font-position                        : $dropdown-toggle-icon__position,
  $_icon-font-vertical-align                  : $dropdown-toggle-icon__font-vertical-align,
  $_icon-font-text-hide                       : $dropdown-toggle-icon__text-hide
) {
  @include lib-clearfix();
  display: inline-block;
  position: relative;

  #{$_toggle-selector} {
    @include lib-css(padding, $_dropdown-actions-padding);
    cursor: pointer;

    @include _lib-dropdown-icon(
        $_dropdown-toggle-icon-content,
        $_dropdown-toggle-active-icon-content,
        $_icon-font,
        $_icon-font-size,
        $_icon-font-line-height,
        $_icon-font-color,
        $_icon-font-color-hover,
        $_icon-font-color-active,
        $_icon-font-margin,
        $_icon-font-vertical-align,
        $_icon-font-position,
        $_icon-font-text-hide
    );
  }

  @include _lib-dropdown-styles(
      $_options-selector,
      $_dropdown-list-min-width,
      $_dropdown-list-width,
      $_dropdown-list-height,
      $_dropdown-list-margin-top,
      $_dropdown-list-background,
      $_dropdown-list-border,
      $_dropdown-list-z-index,
      $_dropdown-list-shadow,
      $_dropdown-list-item-padding,
      $_dropdown-list-item-margin,
      $_dropdown-list-item-border,
      $_dropdown-list-item-hover,
      $_dropdown-list-position-top,
      $_dropdown-list-position-bottom,
      $_dropdown-list-position-left,
      $_dropdown-list-position-right,
      $_dropdown-list-pointer,
      $_dropdown-list-pointer-border,
      $_dropdown-list-pointer-position,
      $_dropdown-list-pointer-position-top,
      $_dropdown-list-pointer-position-left-right
  );
}

//
//  Split dropdown
//  ---------------------------------------------

@mixin lib-dropdown-split(
  $_toggle-selector                                 : ".action.toggle",
  $_options-selector                                : "ul.dropdown",
  $_button-selector                                 : ".action.split",

  $_dropdown-split-actions-padding                  : $dropdown-split-actions__padding,
  $_dropdown-split-button-actions-padding           : $dropdown-split-button__actions__padding,
  $_dropdown-split-toggle-actions-padding           : $dropdown-split-toggle__actions__padding,
  $_dropdown-split-toggle-position                  : $dropdown-split-toggle__position,
  $_dropdown-split-list-min-width                   : $dropdown-split-list__min-width,
  $_dropdown-split-list-width                       : $dropdown-split-list__width,
  $_dropdown-split-list-height                      : $dropdown-split-list__height,
  $_dropdown-split-list-margin-top                  : $dropdown-split-list__margin-top,
  $_dropdown-split-list-position-top                : $dropdown-split-list__position-top,
  $_dropdown-split-list-position-bottom             : $dropdown-split-list__position-bottom,
  $_dropdown-split-list-position-left               : $dropdown-split-list__position-left,
  $_dropdown-split-list-position-right              : $dropdown-split-list__position-right,
  $_dropdown-split-list-background                  : $dropdown-split-list__background,
  $_dropdown-split-list-border                      : $dropdown-split-list__border,
  $_dropdown-split-list-pointer                     : $dropdown-split-list__pointer,
  $_dropdown-split-list-pointer-border              : $dropdown-split-list__pointer-border,
  $_dropdown-split-list-pointer-position            : $dropdown-split-list__pointer-position,
  $_dropdown-split-list-pointer-position-top        : $dropdown-split-list__pointer-position-top,
  $_dropdown-split-list-pointer-position-left-right : $dropdown-split-list__pointer-position-left-right,
  $_dropdown-split-list-item-border                 : $dropdown-split-list__item-border,
  $_dropdown-split-list-item-padding                : $dropdown-split-list__item-padding,
  $_dropdown-split-list-item-margin                 : $dropdown-split-list__item-margin,
  $_dropdown-split-list-item-hover                  : $dropdown-split-list__item-hover,
  $_dropdown-split-button-border-radius-fix         : $dropdown-split-button__border-radius-fix,
  $_dropdown-split-list-shadow                      : $dropdown-split-list__shadow,
  $_dropdown-split-list-z-index                     : $dropdown-split-list__z-index,

  $_dropdown-split-toggle-icon-content              : $dropdown-split-toggle-icon__content,
  $_dropdown-split-toggle-active-icon-content       : $dropdown-split-toggle-icon__active__content,

  $_icon-font                                       : $dropdown-split-toggle-icon__font,
  $_icon-font-size                                  : $dropdown-split-toggle-icon__font-size,
  $_icon-font-line-height                           : $dropdown-split-toggle-icon__font-line-height,
  $_icon-font-color                                 : $dropdown-split-toggle-icon__font-color,
  $_icon-font-color-hover                           : $dropdown-split-toggle-icon__font-color-hover,
  $_icon-font-color-active                          : $dropdown-split-toggle-icon__font-color-active,
  $_icon-font-margin                                : $dropdown-split-toggle-icon__font-margin,
  $_icon-font-vertical-align                        : $dropdown-split-toggle-icon__font-vertical-align,
  $_icon-font-position                              : $dropdown-split-toggle-icon__position,
  $_icon-font-text-hide                             : $dropdown-split-toggle-icon__text-hide
) {
  @include lib-clearfix();
  display: inline-block;
  position: relative;

  @include _lib-dropdown-split-toggle-position(
      $_button-selector,
      $_toggle-selector,
      $_dropdown-split-toggle-position,
      $_dropdown-split-button-border-radius-fix
  );

  #{$_button-selector} {
    @include lib-css(padding, $_dropdown-split-button-actions-padding);
  }

  #{$_toggle-selector} {
    @include lib-css(padding, $_dropdown-split-toggle-actions-padding);
    @include _lib-dropdown-split-icon(
        $_dropdown-split-toggle-icon-content,
        $_dropdown-split-toggle-active-icon-content,
        $_icon-font,
        $_icon-font-size,
        $_icon-font-line-height,
        $_icon-font-color,
        $_icon-font-color-hover,
        $_icon-font-color-active,
        $_icon-font-margin,
        $_icon-font-vertical-align,
        $_icon-font-position,
        $_icon-font-text-hide
    );
  }

  @include _lib-dropdown-styles(
    $_options-selector: $_options-selector,
    $_dropdown-list-min-width: $_dropdown-split-list-min-width,
    $_dropdown-list-width: $_dropdown-split-list-width,
    $_dropdown-list-height: $_dropdown-split-list-height,
    $_dropdown-list-margin-top: $_dropdown-split-list-margin-top,
    $_dropdown-list-background: $_dropdown-split-list-background,
    $_dropdown-list-border: $_dropdown-split-list-border,
    $_dropdown-list-z-index: $_dropdown-split-list-z-index,
    $_dropdown-list-shadow: $_dropdown-split-list-shadow,
    $_dropdown-list-item-padding: $_dropdown-split-list-item-padding,
    $_dropdown-list-item-margin: $_dropdown-split-list-item-margin,
    $_dropdown-list-item-border: $_dropdown-split-list-item-border,
    $_dropdown-list-item-hover: $_dropdown-split-list-item-hover,
    $_dropdown-list-position-top: $_dropdown-split-list-position-top,
    $_dropdown-list-position-bottom: $_dropdown-split-list-position-bottom,
    $_dropdown-list-position-left: $_dropdown-split-list-position-left,
    $_dropdown-list-position-right: $_dropdown-split-list-position-right,
    $_dropdown-list-pointer: $_dropdown-split-list-pointer,
    $_dropdown-list-pointer-border: $_dropdown-split-list-pointer-border,
    $_dropdown-list-pointer-position: $_dropdown-split-list-pointer-position,
    $_dropdown-list-pointer-position-top: $_dropdown-split-list-pointer-position-top,
    $_dropdown-list-pointer-position-left-right: $_dropdown-split-list-pointer-position-left-right
  );
}

//
//  Internal use mixins
//  ---------------------------------------------

@mixin _lib-dropdown-styles(
  $_options-selector,
  $_dropdown-list-min-width,
  $_dropdown-list-width,
  $_dropdown-list-height,
  $_dropdown-list-margin-top,
  $_dropdown-list-background,
  $_dropdown-list-border,
  $_dropdown-list-z-index,
  $_dropdown-list-shadow,
  $_dropdown-list-item-padding,
  $_dropdown-list-item-margin,
  $_dropdown-list-item-border,
  $_dropdown-list-item-hover,
  $_dropdown-list-position-top,
  $_dropdown-list-position-bottom,
  $_dropdown-list-position-left,
  $_dropdown-list-position-right,
  $_dropdown-list-pointer,
  $_dropdown-list-pointer-border,
  $_dropdown-list-pointer-position,
  $_dropdown-list-pointer-position-top,
  $_dropdown-list-pointer-position-left-right
) {
  #{$_options-selector} {
    @include lib-list-reset-styles();
    @include lib-css(background, $_dropdown-list-background);
    @include lib-css(border, $_dropdown-list-border);
    @include lib-css(height, $_dropdown-list-height);
    @include lib-css(margin-top, $_dropdown-list-margin-top);
    @include lib-css(min-width, $_dropdown-list-min-width);
    @include lib-css(width, $_dropdown-list-width);
    @include lib-css(z-index, $_dropdown-list-z-index);
    box-sizing: border-box;
    display: none;
    position: absolute;

    @include _lib-dropdown-list-position(
        $_dropdown-list-position-top,
        $_dropdown-list-position-bottom,
        $_dropdown-list-position-left,
        $_dropdown-list-position-right
    );

    @include _lib-dropdown-list-shadow($_dropdown-list-shadow);

    li {
      @include lib-css(margin, $_dropdown-list-item-margin);
      @include lib-css(padding, $_dropdown-list-item-padding);

      @include _lib-dropdown-list-item-border(
          $_dropdown-list-item-border
      );

      &:hover {
        @include lib-css(background, $_dropdown-list-item-hover);
        cursor: pointer;
      }
    }

    @include _lib-dropdown-list-pointer(
        $_dropdown-list-background,
        $_dropdown-list-pointer,
        $_dropdown-list-pointer-border,
        $_dropdown-list-pointer-position,
        $_dropdown-list-pointer-position-top,
        $_dropdown-list-pointer-position-left-right
    );


  }

  &.active {
    overflow: visible;

    #{$_options-selector} {
      display: block;
    }
  }
}

//
//  Dropdown list position
//  ---------------------------------------------

@mixin _lib-dropdown-list-position(
  $_dropdown-list-position-top,
  $_dropdown-list-position-bottom,
  $_dropdown-list-position-left,
  $_dropdown-list-position-right
) {
  @if $_dropdown-list-position-top != false and $_dropdown-list-position-top != auto {
    top: $_dropdown-list-position-top;
  }
  @if $_dropdown-list-position-bottom != false and $_dropdown-list-position-bottom != auto {
    bottom: $_dropdown-list-position-bottom;
  }
  @if $_dropdown-list-position-left != false and $_dropdown-list-position-left != auto {
    left: $_dropdown-list-position-left;
  }
  @if $_dropdown-list-position-right != false and $_dropdown-list-position-right != auto {
    right: $_dropdown-list-position-right;
  }
}


//
//  Simple dropdown icon
//  ---------------------------------------------

@mixin _lib-dropdown-icon(
  $_dropdown-toggle-icon-content,
  $_dropdown-toggle-active-icon-content,
  $_icon-font,
  $_icon-font-size,
  $_icon-font-line-height,
  $_icon-font-color,
  $_icon-font-color-hover,
  $_icon-font-color-active,
  $_icon-font-margin,
  $_icon-font-vertical-align,
  $_icon-font-position,
  $_icon-font-text-hide
) {
  @include lib-icon-font(
    $_icon-font-content: $_dropdown-toggle-icon-content,
    $_icon-font: $_icon-font,
    $_icon-font-size: $_icon-font-size,
    $_icon-font-line-height: $_icon-font-line-height,
    $_icon-font-color: $_icon-font-color,
    $_icon-font-color-hover: $_icon-font-color-hover,
    $_icon-font-color-active: $_icon-font-color-active,
    $_icon-font-margin: $_icon-font-margin,
    $_icon-font-vertical-align: $_icon-font-vertical-align,
    $_icon-font-position: $_icon-font-position,
    $_icon-font-text-hide: $_icon-font-text-hide
  );

  &.active {
    @include lib-icon-font(
      $_icon-font-content: $_dropdown-toggle-active-icon-content,
      $_icon-font: $_icon-font,
      $_icon-font-size: $_icon-font-size,
      $_icon-font-line-height: $_icon-font-line-height,
      $_icon-font-color: $_icon-font-color,
      $_icon-font-color-hover: $_icon-font-color-hover,
      $_icon-font-color-active: $_icon-font-color-active,
      $_icon-font-margin: $_icon-font-margin,
      $_icon-font-vertical-align: $_icon-font-vertical-align,
      $_icon-font-position: $_icon-font-position,
      $_icon-font-text-hide: $_icon-font-text-hide
    );
  }
}

//
//  Split dropdowns
//  ---------------------------------------------

//  Split dropdown icon
@mixin _lib-dropdown-split-icon(
  $_dropdown-toggle-icon-content,
  $_dropdown-toggle-active-icon-content,
  $_icon-font,
  $_icon-font-size,
  $_icon-font-line-height,
  $_icon-font-color,
  $_icon-font-color-hover,
  $_icon-font-color-active,
  $_icon-font-margin,
  $_icon-font-vertical-align,
  $_icon-font-position,
  $_icon-font-text-hide
) {
  @include lib-button-icon(
    $_icon-font-content: $_dropdown-toggle-icon-content,
    $_icon-font: $_icon-font,
    $_icon-font-size: $_icon-font-size,
    $_icon-font-line-height: $_icon-font-line-height,
    $_icon-font-color: $_icon-font-color,
    $_icon-font-color-hover: $_icon-font-color-hover,
    $_icon-font-color-active: $_icon-font-color-active,
    $_icon-font-margin: $_icon-font-margin,
    $_icon-font-vertical-align: $_icon-font-vertical-align,
    $_icon-font-position: $_icon-font-position,
    $_icon-font-text-hide: $_icon-font-text-hide
  );

  &.active {
    @include lib-button-icon(
      $_icon-font-content: $_dropdown-toggle-active-icon-content,
      $_icon-font: $_icon-font,
      $_icon-font-size: $_icon-font-size,
      $_icon-font-line-height: $_icon-font-line-height,
      $_icon-font-color: $_icon-font-color,
      $_icon-font-color-hover: $_icon-font-color-hover,
      $_icon-font-color-active: $_icon-font-color-active,
      $_icon-font-margin: $_icon-font-margin,
      $_icon-font-vertical-align: $_icon-font-vertical-align,
      $_icon-font-position: $_icon-font-position,
      $_icon-font-text-hide: $_icon-font-text-hide
    );
  }
}

@mixin _lib-dropdown-split-toggle-position(
  $_button-selector,
  $_toggle-selector,
  $_dropdown-split-toggle-position,
  $_dropdown-split-button-border-radius-fix
) {
  @if $_dropdown-split-toggle-position == left {
    #{$_button-selector} {
      float: right;
      margin: 0;
    }
    #{$_toggle-selector} {
      float: left;
      margin: 0;
    }
    @if $_dropdown-split-button-border-radius-fix {
      button#{$_button-selector} {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      button + #{$_toggle-selector} {
        border-bottom-right-radius: 0;
        border-right: 0;
        border-top-right-radius: 0;
      }
    }
  } @else if $_dropdown-split-toggle-position == right {
    #{$_button-selector} {
      float: left;
      margin: 0;
    }
    #{$_toggle-selector} {
      float: right;
      margin: 0;
    }
    @if $_dropdown-split-button-border-radius-fix {
      button#{$_button-selector} {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      button + #{$_toggle-selector} {
        border-bottom-left-radius: 0;
        border-left: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

//
//  Dropdown show pointer
//  ---------------------------------------------

@mixin _lib-dropdown-list-pointer(
  $_dropdown-list-background,
  $_dropdown-list-pointer,
  $_dropdown-list-pointer-border,
  $_dropdown-list-pointer-position,
  $_dropdown-list-pointer-position-top,
  $_dropdown-list-pointer-position-left-right
) {
  @if $_dropdown-list-pointer {
    @include _lib-dropdown-pointer(
        $_dropdown-list-background,
        $_dropdown-list-pointer-border,
        $_dropdown-list-pointer-position,
        $_dropdown-list-pointer-position-top,
        $_dropdown-list-pointer-position-left-right
    );
  }
}

@mixin _lib-dropdown-pointer(
  $_dropdown-list-background,
  $_dropdown-list-pointer-border,
  $_dropdown-list-pointer-position,
  $_dropdown-list-pointer-position-top,
  $_dropdown-list-pointer-position-left-right
) {
  @if $_dropdown-list-pointer-position == left {
    @include _lib-dropdown-pointer-default(
        $_dropdown-list-background,
        $_dropdown-list-pointer-border
    );

    &::before {
      @include lib-css(left, $_dropdown-list-pointer-position-left-right);
      @include lib-css(top, $_dropdown-list-pointer-position-top);
    }

    &::after {
      @include lib-css(left, $_dropdown-list-pointer-position-left-right - 1);
      @include lib-css(top, $_dropdown-list-pointer-position-top - 2);
    }
  } @else if $_dropdown-list-pointer-position == right {
    @include _lib-dropdown-pointer-default(
        $_dropdown-list-background,
        $_dropdown-list-pointer-border
    );

    &::before {
      @include lib-css(right, $_dropdown-list-pointer-position-left-right);
      @include lib-css(top, $_dropdown-list-pointer-position-top);
    }

    &::after {
      @include lib-css(right, $_dropdown-list-pointer-position-left-right - 1);
      @include lib-css(top, $_dropdown-list-pointer-position-top - 2);
    }
  }
}

@mixin _lib-dropdown-pointer-default(
  $_dropdown-list-background,
  $_dropdown-list-pointer-border
) {
  &::before,
  &::after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }

  &::before {
    border: 0.6rem solid;
    @include lib-css(border-color, transparent transparent $_dropdown-list-background transparent);
    z-index: 99;
  }

  &::after {
    border: 0.7rem solid;
    @include lib-css(border-color, transparent transparent $_dropdown-list-pointer-border transparent);
    z-index: 98;
  }
}

//  Dropdown list item border != 0
@mixin _lib-dropdown-list-item-border($_dropdown-list-item-border) {
  @if $_dropdown-list-item-border != false and $_dropdown-list-item-border != 0 and $_dropdown-list-item-border != '' {
    @include lib-css(border-top, $_dropdown-list-item-border);
    &:first-child {
      border: none;
    }
  }
}

//  Dropdown list shadow
@mixin _lib-dropdown-list-shadow($_dropdown-list-shadow) {
  @include lib-css(box-shadow, $_dropdown-list-shadow);
}
