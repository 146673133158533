$recap-order-image-width: 6rem;
$recap-order-image-text-gap: $space-2;
$recap-order-detail-width: calc(100% - (#{$recap-order-image-width} + #{$recap-order-image-text-gap}));

.#{$css-namespace}checkout-recap-order {
    background: $color-white;
    box-shadow: var(--box-shadow);

    &__title {
        &,
        &:hover,
        &:focus {
            background: none;
            border: none;
        }

        & ~ & {
            border-top: 0.1rem solid $color-border-grey-light;
        }

        &__text {
            color: $color-dark-grey;
            font-weight: $font-weight-bold;

            &__counter {
                font-weight: $font-weight-regular;
            }
        }

        &__icon {
            color: $color-main-dark;
        }
    }

    &__content {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;

        &:not(.hidden) + .#{$css-namespace}checkout-recap-order__title {
            margin-top: $space-2;
        }

        &.hidden {
            display: none !important; //required as default class can be overridden
        }

        &:last-child {
            padding-bottom: $space-2;
        }

        &__elt {
            width: 100%;
            gap: $recap-order-image-text-gap;

            &__img {
                min-width: $recap-order-image-width;
                flex: 0;
            }

            &__data {
                width: 100%;
                max-width: $recap-order-detail-width;

                &__content {
                    width: 100%;
                }

                &__name {
                    text-transform: $brand-text-case;
                    font-weight: $brand-text-weight;
                    color: $brand-text-color;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin-bottom: $space-1 / 2;
                    line-height: 1.43;

                    .product-name {
                        text-transform: none;
                        font-weight: $font-weight-regular;
                        color: $color-main-dark;
                    }
                }

                &__reference {
                    font-size: $reference-text-size;
                    line-height: $reference-text-line-height;
                }

                &__details {
                    width: 100%;

                    &__quantity {
                        color: $color-primary;
                        background-color: $color-grey-background;
                        min-width: 4rem;
                        line-height: 1.429;
                    }

                    &__price {
                        gap: $space-1 / 2;
                        font-weight: $font-weight-regular;

                        &__old {
                            text-decoration: line-through;
                            line-height: 1.172;
                        }

                        &__final {
                            color: $text-price-color;
                            font-size: $font-size-l;
                            font-weight: $font-weight-black;
                            line-height: 1.167;

                            .has_promo & {
                                color: $color-red-promo;
                            }
                        }

                        &__unit {
                            font-size: $text-price-unit-size;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
}
