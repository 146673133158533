.capaddress__suggest-wrapper {
    overflow: auto;
    max-height: 32rem;
    border: 0.1rem solid $color-disabled-light;
    box-shadow: 0 0.4rem 1rem 0 rgba($color-black, 0.12); // TODO: Use box-shadow var

    &:empty,
    &[aria-hidden='true'] {
        display: none;
    }
}

.capaddress__suggest-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        margin: 0;

        button {
            display: block;
            width: 100%;
            padding: $space-1 $space-2;
            border: none;
            font-weight: $font-weight-regular;
            text-align: left;
            background: white;

            &:focus,
            &:hover {
                background-color: $color-secondary;
                color: $color-white;
            }
        }
    }
}
