.most-popular-categories-container {
    background: $clearColor;
}

.most-popular-categories {
    &-section {
        position: relative;
    }

    &-list {
        padding: 0;
        margin: 0;
        font-size: 0;
    }
}

.most-popular-category-url {
    display: block;
    cursor: pointer;
    padding: $indent__m 0;
    transition: box-shadow 0.2s ease-in-out; // TODO : Uniformise using var(--transition-normal)
}

li.most-popular-category {
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    @include lib-font-size(16);
    vertical-align: top;
    border: 0.1rem solid transparent;

    span {
        display: block;

        &:not(.most-popular-category-img) {
            font-weight: $font-weight__bold;
            width: 50%;
            margin: $indent__s auto 0;
        }
    }
}

@include max-screen($screen__l) {
    .all-categories-url {
        display: none;
    }
    .most-popular-categories {
        &-section {
            flex-flow: row wrap;
            justify-content: center;
            padding: $indent__m 0;
        }

        &-list {
            display: flex;
            flex-flow: row wrap;
            padding: 0 1.5rem;
        }
    }
    li.most-popular-category {
        width: calc(100% / 2);
        display: inline-flex;

        > .most-popular-category-url {
            border: 0.1rem solid $color-gray-light6;
            border-radius: 0.5rem;
            flex: 1;

            span:not(.most-popular-category-img) {
                width: 70%;
            }
        }

        &:nth-child(even) {
            > .most-popular-category-url {
                margin-left: 7.5rem;
            }
        }

        &:nth-child(odd) {
            > .most-popular-category-url {
                margin-right: 0.5rem;
            }
        }
    }
}

@include min-screen($screen__l) {
    .most-popular-categories-container {
        padding: $indent__l 0;
    }

    .most-popular-categories {
        &-section {
            background-color: $color-white;
        }

        &-list {
            display: flex;
        }
    }
    li.most-popular-category {
        width: calc(100% / 6);
        flex: 1;
        margin: 0;
        display: inline-flex;
    }
    .most-popular-category-url {
        flex: 1;
        border: 0.1rem solid transparent;

        &:hover {
            border-radius: 0.4rem;
            border: 0.1rem solid $color-blue3;
            color: $color-blue1;
            @include lib-css-box-shadow-hover($params: false, $default: true);
        }
    }
}

@include screen(#{$screen__l - 1}, #{$screen__l + 1}) {
    .home-current-offers-container,
    .home-best-sellers-container {
        padding: $indent__l;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: $screen__l) and (max-device-width: $screen__l) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .home-current-offers-container,
    .home-best-sellers-container {
        padding: $indent__l;
    }
    .product_grid__item {
        padding: 1.5rem;
    }
    .product_grid__img_container {
        height: 10rem;
    }
}
