// common style rules for quotation modals
.quotation-create-index,
.useraccount-quickorder-index {
    .#{$css-namespace}textarea {
        &_error {
            margin-top: $space-1;
            color: $color-red10;
        }

        &_counter {
            flex: 0 0 5rem;
        }

        &_counter_container {
            display: flex;
        }

        &_counter_content {
            width: 100%;

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    .#{$css-namespace}copy-paste_mentions {
        margin-bottom: 2.7rem;
    }
}

.#{$css-namespace}modal.#{$css-namespace}modal_specific-item-detail {
    [data-js-specific-item-details='text-modal'] {
        padding: 2rem;
        background: $color-grey-background;
        width: 100%;
        word-break: break-word;
    }
}

.#{$css-namespace}modal_specific-item-import {
    .modal-inner-wrap {
        .modal-content {
            .#{$css-namespace}specific-item_wrapper {
                text-align: left;

                .#{$css-namespace}specific-item_text {
                    font-size: $font-size-default;
                    font-weight: $font-weight-light;
                    margin-bottom: 1rem;
                    line-height: 1.9rem;
                }

                .#{$css-namespace}specific-item_text_mandatory {
                    font-size: $font-size-s;
                    font-weight: $font-weight-regular;
                    font-style: italic;
                    line-height: 1.7rem;
                }

                .#{$css-namespace}textarea {
                    // TODO : define in toolkit
                    margin-top: 2.62rem;

                    .#{$css-namespace}textarea_text {
                        border-width: 0.1rem;

                        &.mage-error {
                            // todo : uniformise mage error in _forms
                            border-color: $deleteColor;
                        }

            + div.mage-error {
              position: absolute;
              bottom: -2.7rem;
              left: 0;
              right: $space-5;
            }
          }

                    .#{$css-namespace}textarea_label {
                        font-weight: $font-weight__regular;
                        line-height: 1.9rem;

                        @include max-screen($screen__m) {
                            right: $space-1;
                            font-size: 1.15rem;
                            width: fit-content;
                        }
                    }
                }
            }

            .#{$css-namespace}specific-item {
                &_form {
                    max-width: 45rem;
                }

                &_upload-wrapper {
                    margin: 0 0.3rem; // Added margin to fix box-shadow overflow visibility
                    border-radius: 0.3rem;
                    box-shadow: var(--box-shadow);
                    display: flex;
                    transition: background 150ms; // TODO : Uniformise using var(--transition-normal)

                    &.is-dragenter {
                        background: $color-drag-hover;
                    }

                    .label {
                        position: relative;
                        flex: 1;
                        pointer-events: auto;
                    }

                    [type='file'] {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        cursor: pointer;
                    }

                    .#{$css-namespace}specific-item_file-field {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        padding: 1.437rem 1.615rem 1.422rem 1.541rem;
                        height: 6.3rem;

                        &_inner {
                            text-align: left;
                        }

                        &_title {
                            font-weight: $font-weight__bold;
                            margin-top: 0;
                            margin-bottom: 0.514rem;
                            line-height: 1.6rem;
                        }

                        &_text {
                            font-size: $font-size-xs-l;
                            color: $color-text-lighter;
                        }

                        .#{$css-namespace}icon,
                        .dcfonts-icons {
                            align-self: center;
                            color: $color-secondary;
                            font-size: 2.4rem;
                        }
                    }
                }

                &_uploaded {
                    &-files {
                        &_file {
                            display: flex;
                            margin-top: 2rem;
                            line-height: 1.9rem;
                            cursor: default;
                            overflow: hidden;
                            align-items: flex-start;
                            text-align: left;

                            &.hidden {
                                display: none;
                            }

                            .dcfonts-icons {
                                color: $color-secondary;
                                font-size: 1.9rem;
                                margin-right: 1rem;
                                cursor: pointer;
                            }
                        }
                    }

                    &-error {
                        display: flex;

                        &.hidden {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.#{$css-namespace}modal_quotation {
    &_validate-quotation-chorus {
        .form-chorus {
            @include max-screen($screen__m) {
                width: calc(100% - 2.2rem);
            }

            .#{$css-namespace}input-text,
            .#{$css-namespace}select--custom {
                border-color: $color-grey-dove;
            }

            .fieldset {
                &:not(._error) {
                    .control {
                        .#{$css-namespace}input-text {
                            border-color: $color-grey-dove;
                        }
                    }
                }
            }
        }

        .modal-validate-block-chorus {
            text-align: left;
            max-width: 43.6rem;
            margin: 0 auto 4.4rem;

            .mandatory {
                margin-top: 1.5rem;
                margin-bottom: -0.5rem;
                font-weight: $font-weight-regular;
                font-size: $font-size-s;
                font-style: italic;
                line-height: 2.2rem;
            }

            .#{$css-namespace}form-field-wrapper {
                margin-top: 2.7rem;

                .control {
                    width: 100%;

                    .#{$css-namespace}input-text {
                        width: 100%;
                    }

                    .dcfonts-icons {
                        margin-right: 0;
                    }
                }
            }
        }

        .fieldset .control .#{$css-namespace}input-text ~ label,
        .fieldset .control .input-text ~ label {
            color: $color-grey-dove;
            font-size: $font-size-default;
            display: flex;
        }

        .#{$css-namespace}select--custom.nice-select {
            i {
                pointer-events: none;
            }

            .list {
                .option[data-value=''] {
                    display: block;
                }
            }

            ~ label {
                color: $color-grey-dove;
                font-size: $font-size-default;
            }
        }

        label,
        .label {
            &.required::after {
                content: '*';
            }
        }

        .field-error {
            display: none;
        }

        .chorus_legal_commitment_fieldset {
            &._error {
                .input-text {
                    border-color: $color-error;
                }

                [data-error-required].field-error {
                    display: block;
                }
            }
        }

        [data-js-service-code-select] {
            &._error {
                .#{$css-namespace}select--custom {
                    border-color: $color-error;
                }

                .field-error:not(.field-error--disabled) {
                    display: block;
                }
            }
        }

        .hide-other-errors {
            &.visible {
                display: block;

                ~ .field-error {
                    display: none;
                }
            }
        }

        .#{$css-namespace}tooltip {
            position: absolute;
            right: -2.2rem;
            top: 2rem;
            transform: translateY(-50%);

            > span {
                max-width: 29rem !important;
                right: -1.8rem;
                left: auto;

                &::after {
                    right: 1.5rem;
                    left: auto;
                    bottom: -0.1rem;
                }
            }
        }

        .modal-footer {
            max-width: 43.6rem;
        }
    }
}
