$modal-gap: $space-2;

.modal-popup.#{$css-namespace}modal-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none;

    @include max-screen($screen__m) {
        .-modal--small-width & {
            align-items: flex-end;
        }
    }

    %modal-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: $space-2;
        justify-content: flex-end;

        @include min-screen($screen__m) {
            flex-direction: row;
        }

        .-modal--no-horizontal-padding & {
            padding: 0 $space-3;
        }

        .#{$css-namespace}btn {
            justify-content: center;
            min-width: 15rem; // TODO : Refacto needed using var ?

            @include max-screen($screen__m) {
                width: 100%;
            }
        }

        &:empty,
        &:not(:has(.#{$css-namespace}btn)) {
            display: none;
        }
    }

    .modal {
        &-inner-wrap {
            width: 100%;
            height: 100vh;
            height: 100dvh;
            padding: 0 $space-3 $space-4;
            gap: $modal-gap;
            margin: 0 auto;

            .-modal--no-horizontal-padding & {
                padding: 0 0 $space-4;
            }

            @include max-screen($screen__m) {
                .-modal--small-width & {
                    height: auto;
                    max-height: 100%;
                }
            }

            @include min-screen($screen__m) {
                max-width: var(--modal-medium-width);
                height: auto;
                max-height: calc(100vh - 5rem);
                max-height: calc(100dvh - 5rem);

                .-modal--large-width & {
                    max-width: var(--modal-large-width);
                }

                .-modal--small-width & {
                    max-width: var(--modal-small-width);
                }

                .-modal--medium-width & {
                    max-width: var(--modal-medium-width);
                }
            }
        }

        &-header {
            padding: 0;
            background: none;
            display: flex;
            flex-direction: column;
            gap: $space-2;

            .-modal--no-horizontal-padding & {
                padding: 0 $space-3;
            }

            .action-close {
                padding: $space-1;
                font-weight: $font-weight__bold;
                background-color: $color-sub-secondary;
                transform: none;
                border: none;
                order: -1;
                align-self: flex-end;
                line-height: 1;
                margin-right: -$space-3;
                height: 4rem;
                width: 4rem;

                &:hover {
                    color: transparentize($color-white, 0.2);
                }

                &::before {
                    font-family: $font-icons;
                    content: $cross;
                    font-size: $font-size-m;
                    color: $color-white;
                    text-align: center;
                }

                > span {
                    display: none;
                }
            }
        }

        &-content .#{$css-namespace}title,
        &-title {
            color: $color-secondary;
            margin-bottom: 0;
            font-size: $font-size-xxl;
            font-weight: $font-weight-semi-bold;
            display: flex;
            flex-wrap: wrap;
            gap: $space-1;
        }

        &-subtitle {
            color: $color-black;
            font-size: $font-size-xl;
            font-weight: $font-weight-semi-bold;
            text-align: left;
            width: 100%;

            + .modal-subtitle {
                font-weight: $font-weight-regular;
                color: $color-black;
            }
        }

        &-content {
            overflow: hidden auto;
            @extend %scrollbars !optional;
            scrollbar-width: thin;
            scrollbar-color: $color-secondary transparent;

            > div {
                .-modal--no-horizontal-padding & {
                    padding: 0 $space-3;
                }
            }

            .custom-form {
                display: flex;
                flex-direction: column;
                gap: $modal-gap;
                padding-top: $space-1; // Prevent overflow-y:auto (wishlist edit for example)
            }

            // For NiceSelect and BOB (modal-issue)
            .#{$css-namespace}modal-formInput {
                position: relative;
            }

            .#{$css-namespace}card-with-fieldset {
                border-color: $color-primary;
            }

            .#{$css-namespace}textarea {
                max-width: 100%;
            }
        }

        &-content,
        &-footer {
            padding: 0;
        }

        &-footer {
            @extend %modal-footer;
        }
    }
}

.modals-overlay {
    position: fixed;
    inset: 0;
    background-color: $color-black;
    opacity: 0.7;
}

.modal-footer-in-body {
    // Used in submit form submit
    @extend %modal-footer;
    margin-top: $modal-gap;
}
