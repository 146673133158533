.#{$css-namespace}loyalty-prospect {
    display: none;
    border-top: 0.3rem solid $color-secondary;
    border-bottom: 0.3rem solid $color-secondary;
    height: 100%;
    flex-direction: column;

    @include min-screen($screen__m) {
        display: flex;
    }

    &,
    &-banner {
        background: $color-white;
        border-radius: 0.3rem;
        box-shadow: var(--box-shadow-light);
    }

    &_title {
        font-family: $font-secondary;
        font-size: $font-size-xl;
        line-height: 1.25;
        font-weight: $font-weight-semi-bold;
        align-items: center;
        color: $color-primary;

        &.--banner {
            span {
                @include min-screen($screen__l) {
                    margin-left: $space-1;
                }
            }
        }

        br {
            @include min-screen($screen__l) {
                display: none;
            }
        }

        span {
            color: $color-secondary;
        }
    }

    &_list {
        &_item {
            display: flex;
            gap: $space-1;
            align-items: center;
            margin: 0;
            line-height: 1.43;

            .dcfonts-icons {
                font-size: 2.4rem;
                color: $color-secondary;
            }
        }
    }
}

.#{$css-namespace}loyalty-prospect-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    justify-content: center;
    margin: $space-3 0 $space-4;

    @include min-screen($screen__l) {
        flex-direction: row;
        gap: $space-2;
        justify-content: flex-start;
    }

    &::after {
        content: '';
        background-image: url('../Descours_UserAccount/images/dashboard/loyalty-prospect-stars.svg');
        display: block;
        width: 6.1rem;
        height: 5.6rem;
        position: absolute;
        bottom: 2.8rem;
        right: 2.8rem;

        @include min-screen($screen__l) {
            top: 1.3rem;
            right: 1.7rem;
            transform: rotateZ(-170.92°);
        }
    }

    &-wrapper {
        padding: 0 $space-2;

        @include min-screen($screen__l) {
            padding: 0;
        }
    }

    &_image {
        min-height: 16.2rem;
        background-repeat: no-repeat;
        align-self: flex-start;
        width: 100%;
        background-size: 85%;

        @include min-screen($screen__l) {
            width: 19rem;
            background-size: auto;
        }

        &[data-store-name='PROLIANS'] {
            background-image: url('../Descours_UserAccount/images/dashboard/loyalty-prospect-gift-PROLIANS.svg');
        }

        &[data-store-name='HYDRALIANS'] {
            background-image: url('../Descours_UserAccount/images/dashboard/loyalty-prospect-gift-HYDRALIANS.svg');
        }

        [data-step='pending'] & {
            background-image: url('../Descours_UserAccount/images/dashboard/loyalty-prospect-pending.svg');
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        gap: $space-2;
        flex: 1;
        padding: $space-4;
    }

    &_amount {
        font-family: $font-secondary;
        font-size: $font-size-xxxl;
        color: $color-primary;
        font-weight: $font-weight-bold;
        line-height: 1.1;
    }

    &_description {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        gap: $space-2;

        @include min-screen($screen__l) {
            flex-direction: row;
        }
    }

    &_text {
        display: flex;
        flex-direction: column;
        gap: $space-1 / 2;
        line-height: 1.43;

        &-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $space-3;
            flex: 1;

            @include min-screen($screen__l) {
                flex-direction: row;
                gap: $space-2;
            }
        }
    }

    &_actions {
        position: relative;

        @include min-screen($screen__l) {
            margin-left: auto;
        }

        .#{$css-namespace}loyalty_button {
            margin-top: 0;
            white-space: nowrap;

            .#{$css-namespace}btn {
                padding: $space-1 $space-3;
            }
        }
    }
}
