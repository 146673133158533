.quote-validation-main-block {
    background: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0.5rem 0 0.5rem;
    padding: 3rem 1.5rem;
    border-radius: 0.3rem;
    box-shadow: var(--box-shadow);

    @include min-screen($screen__l) {
        padding: 3rem;
        margin: 0 20rem 0 24rem;
    }

    .quote-validation-title-container {
        text-align: center;

        @include min-screen($screen__l) {
            margin-left: 2rem;
            margin-right: 2rem;
        }

        .main-title {
            font-size: $font-size-xxl;
            line-height: 3rem;
            margin-bottom: 3rem;

            @include min-screen($screen__l) {
                font-size: $font-size-xxxl;
                line-height: 5rem;
                padding-right: 5.5rem;
                padding-left: 4rem;
            }
        }

        .sub-title {
            font-size: $font-size-xl;
            line-height: 2.5rem;
            font-weight: $font-weight__semibold;

            @include min-screen($screen__l) {
                padding-left: 5rem;
                padding-right: 5rem;
            }
        }
    }

    .quote-validation-text-container {
        margin: 3rem 0 3rem 0;
        padding: 0;

        @include min-screen($screen__l) {
            padding-left: 15.7rem;
            padding-right: 15.7rem;
        }

        li {
            display: flex;
            line-height: 1.9rem;
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            .dcfonts-icons {
                color: $color-secondary;
                margin-right: 1rem;
                padding-bottom: 2rem;
            }
        }

        .account-link {
            color: $color-secondary;
            text-decoration: underline;
        }
    }

    .#{$css-namespace}btn {
        display: flex;
        justify-content: center;
        width: 100%;

        @include min-screen($screen__l) {
            width: 40%;
        }
    }
}

.quote-validation-contact-block {
    background-color: $color-white;
    display: flex;
    justify-content: center;
    padding: 3rem 2.6rem 3rem 2.967rem;
    border-radius: 0.3rem;
    box-shadow: var(--box-shadow);
    margin: 3rem 0.5rem 0 0.5rem;

    @include min-screen($screen__l) {
        margin: 3rem 20rem 0 24rem;
        padding: 2rem 0 2.5rem;
    }

    .#{$css-namespace}title {
        margin-bottom: 1rem;
        font-size: $font-size-xl;
        padding-left: 2rem;
        line-height: 2.5rem;

        @include min-screen($screen__l) {
            padding: 0;
            margin-bottom: 0.5rem;
            line-height: 3rem;
        }
    }

    .#{$css-namespace}p {
        @include max-screen($screen__l) {
            margin-left: -1.5rem;
        }
    }

    .dcfonts-icons {
        color: $color-primary;
        margin-right: 2rem;
        font-size: 2.8rem;

        @include max-screen($screen__l) {
            align-self: flex-start;
            margin-top: 1rem;
            margin-right: 0;
        }
    }

    .phone-number {
        color: $color-primary;
        font-weight: $font-weight-bold;
    }
}
