$step-number-size: 2.4rem !default;
$step-number-border-size: 0.1rem !default;
$step-number-line-height: $step-number-size - $step-number-border-size * 2 !default;

.#{$css-namespace}step {
    margin-top: 0;
    margin-bottom: $space-4;
    height: min-content;

    &_wrapper,
    &_title {
        height: auto;
    }

    &_wrapper {
        display: flex;
        align-items: baseline;
        gap: $space-1;

        &::before {
            content: counter(i);
            counter-increment: i;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $step-number-size;
            height: $step-number-size;
            background: $color-white;
            color: $disabledTextColor;
            font-weight: $font-weight-bold;
            border-radius: 50%;
            border: solid $step-number-border-size $disabledTextColor;
            font-size: $font-size-s;
            line-height: $step-number-line-height;
            flex: 1 0 auto;
            max-width: $step-number-size;
        }
    }

    &_title {
        height: min-content;
        font-size: $font-size__base;
        font-weight: $font-weight-bold;
        line-height: 1.95;
        margin: 0;
    }
}

.contains-steps {
    counter-reset: i;
}
