$dropdown-index: 25 !default;
$shift-right: -3.5rem !default;

.page-wrapper {
    .header__wrapper {
        .minicart-wrapper-container {
            display: flex;
            position: relative;
            height: 100%;

            @include min-screen($screen__l) {
                display: flex;
                align-items: center;
                min-height: 4.2rem; // Visually force 2 lines
            }

            @include max-screen($screen__l) {
                order: 4;
            }

            .minicart-price {
                display: flex;
                flex-direction: column;
                font-size: $font-size-s;
            }
        }

        .minicart-dropdown {
            position: absolute;
            right: $shift-right;
            top: calc(100% + #{$space-1});
            z-index: $dropdown-index + 1;
            width: 48.3rem;
            border-radius: 0.8rem;
            box-shadow: var(--box-shadow);
            background: $color-white;

            @include max-screen($screen__m) {
                display: none !important;
            }

            .block-minicart {
                padding: $space-2 $space-3 $space-2 $space-2;
            }

            &_title {
                display: flex;
                align-items: center;
                margin-bottom: $space-1;
                color: $color-primary;
                gap: $space-1;
                font-weight: $font-weight-bold;
                line-height: 2rem;

                .#{$css-namespace}badge {
                    color: $color-white;
                    background-color: $color-secondary;
                    border: none;
                    height: 1.8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.2rem $space-1;
                    font-size: $font-size-xs;
                    line-height: 1;
                }
            }

            &_content {
                background-color: $color-white;
                margin-right: -$space-1;

                &.with-scrollbar {
                    margin-right: 0;

                    .minicart-items {
                        padding-right: 2.2rem;
                    }

                    .minicart-dropdown_bottom {
                        padding-right: $space-2;
                    }
                }
            }

            &_bottom {
                padding: $space-1 0 0;
                display: flex;
                flex-direction: column;
                gap: $space-1;

                .action {
                    width: 100%;
                    justify-content: center;
                    height: 4.8rem;
                    font-weight: $font-weight-semi-bold;
                }
            }

            &_price-detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: 2rem;

                .price {
                    color: $color-primary;
                    font-weight: $font-weight-bold;

                    &.price-discount {
                        .price {
                            color: $color-red-promo;
                        }
                    }
                }
            }

            &_subtotal {
                color: $color-primary;
                line-height: 2.6rem;

                .label {
                    font-family: $font-secondary;
                    font-size: $font-size-m;
                    font-weight: $font-weight-bold;
                }

                .price {
                    font-size: 2.2rem;
                    font-weight: $font-weight-black;
                }
            }
        }

        .minicart-items {
            padding: 0;
            margin: 0;
            list-style: none;

            &-wrapper {
                overflow-y: auto;
                max-height: 22.5rem;
                @extend %scrollbars !optional;

                &--no-overflow {
                    overflow: visible;
                }
            }

            .product-item {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                padding-bottom: $space-1;
                border-bottom: 0.1rem solid $color-grey-argent;

                &:last-of-type:not(:first-child) {
                    .tooltip-text {
                        top: auto;
                        bottom: calc(100% + 1.2rem);

                        &::before {
                            top: 100%;
                        }
                    }
                }

                + .product-item {
                    padding: $space-1 0;
                }

                .product-image-wrapper {
                    margin-right: $space-2;
                    width: 5.8rem;
                    height: 5.8rem;
                    display: flex;

                    img {
                        max-height: 100%;
                    }
                }

                &-details {
                    width: 18.8rem;
                    margin-right: $space-2;
                    position: relative;

                    .tooltip-text {
                        top: 100%;
                        box-shadow: 0 0.5rem 0.3rem 0 rgba($color-black, 0.12); // TODO: Use box-shadow var
                        width: 25rem;
                    }
                }

                &-title {
                    line-height: 1.8rem;
                    height: 3.6rem;
                    font-size: $font-size-s;
                    font-weight: $font-weight-regular;

                    .#{$css-namespace}product_slot__name {
                        line-height: inherit;
                        font-weight: inherit;
                        font-size: inherit;
                        color: $text-price-color;
                    }
                }

                &-brand {
                    color: $brand-text-color;
                    font-weight: $brand-text-weight;
                    text-transform: $brand-text-case;
                }

                &-reference {
                    display: block;
                    font-size: $font-size-xs;
                    line-height: 1.4rem;
                    font-weight: $font-weight-regular;
                    margin: 0;
                }

                &-quantity {
                    input {
                        width: 4.1rem;
                        height: 4rem;
                        border-radius: 0.3rem;
                        border-color: $color-grey-background;
                        background-color: $color-grey-background;
                        font-size: $font-size-default;
                        color: $color-primary;
                        font-weight: $font-weight-bold;
                        padding: 0.5rem;
                        text-align: center;
                        cursor: default;
                    }
                }

                &-pricing {
                    margin-left: auto;
                    text-align: right;

                    .#{$css-namespace}item-table_row-item_subtotal {
                        font-size: $font-size-default;
                        font-weight: $font-weight-bold;
                        line-height: 2rem;
                    }

                    .#{$css-namespace}product_promo {
                        margin: 0;

                        &__old_price {
                            font-size: $font-size-xs;
                            line-height: 1.4rem;
                        }
                    }
                }
            }
        }
    }
}
