form.companySearchForm { // TODO: Refacto in _forms.scss
    .search-by-zip {
        padding-top: 0;
        display: none;
    }

    &[data-show-search='true'] .search-by-zip {
        display: block;
        margin-top: 2rem;
    }
}
