.lg-backdrop {
    display: none;
}

.lg-outer {
    background-color: rgba($color-black, 0.7);

    @include min-screen($screen__l) {
        height: auto;
        width: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: $space-23 solid transparent;
    }

    > *,
    .lg-thumb-outer, {
        background-color: $color-white;
    }

    .lg-thumb {
        padding: $space-2 0;
        display: flex;
        margin: 0 auto;
        justify-content: center;
    }

    .lg-thumb-item {
        max-width: 6.8rem;
        max-height: 6.8rem;
        padding: $space-1;
        margin-bottom: 0;
        border: 0.1rem solid $color-blue-light;

        &:hover,
        &.active {
            border-color: $color-primary;
        }

        img {
            width: fit-content;
            height: fit-content;
            max-height: 100%;
        }
    }
}

.lg-toolbar {
    .lg-close {
        background-color: $color-primary;
        color: $color-white;
        font-size: $font-size-l;

        &::after {
            font-family: $font-icons;
            content: $cross;
        }
    }
}

.lg-prev,
.lg-next {
    font-size: $font-size-xxl;

    &,
    &:hover,
    &:focus {
        appearance: none;
        background: transparent;
        border: none;
    }

    &:focus-visible {
        outline: 0.1rem solid $color-blue-light;
    }

    &::after,
    &::before {
        font-family: $font-icons;
        color: $color-primary;
    }
}

.lg-prev {
    left: $space-3;

    &::after {
        content: $caret-left;
    }
}

.lg-next {
    right: $space-3;

    &::before {
        content: $caret-right;
    }
}
