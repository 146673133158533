.#{$css-namespace}textarea {
    position: relative;
    max-width: 45rem;

    &.--error {
        .#{$css-namespace}textarea_error {
            display: block;
        }
    }

    &_field {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 0.1rem solid $color-grey-dove;
        border-radius: 0.3rem;
        padding: 1.8rem 1.8rem 1.8rem 1.4rem;

        &:has(.#{$css-namespace}textarea_text:focus),
        &:has(.#{$css-namespace}textarea_text:not([value=''])),
        &:has(.#{$css-namespace}textarea_text:not([value])),
        &:has(.#{$css-namespace}textarea_text:not(:empty)) {
            border-color: $color-primary;

            .#{$css-namespace}textarea_label {
                left: 0.7rem;
                top: -1rem;
                padding: 0 0.7rem;
                color: $color-primary;
                background-color: $color-white;
                font-size: $font-size-s;
            }
        }
    }

    &_text {
        padding: 0;
        font-size: $font-size-default;
        min-height: 16.2rem;
        width: 100%;
        resize: none;
        color: $color-main-dark;
        border: none;
        @extend %scrollbars;
    }

    &_label,
    &_counter {
        font-size: $font-size-s;
        transition: font-size 0.25s; // TODO : Uniformise using var(--transition-normal)
    }

    &_label {
        position: absolute;
        left: 1.4rem;
        top: 1.8rem;
        z-index: 1;
        font-size: $font-size-default;
        transition: all 0.25s; // TODO : Uniformise using var(--transition-normal)
        border-radius: 0.3rem;
        color: $color-grey-dove;
        pointer-events: none;
    }

    &_counter {
        display: inline-block;
        text-align: right;
        width: 100%;
        margin-top: 0.5rem;
    }

    &_error {
        color: $color-error;
        font-size: $font-size-s;
        margin-top: 0.5rem;
        display: none;
    }

    .mage-error ~ div.mage-error:not(:empty) { // Prevent css-has-no-support::after to hide error
        z-index: 2;
    }
}

// As Firefox and iOS < 14.4 for example can't support :has()
// selector, we keep the previous version (default scrollbar)
// We couldn't use @supports selector() as it is not compilable
.CSS-no-has-support {
    .#{$css-namespace}textarea {
        &_field {
            border: none;
            padding: 0;

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0.1rem;
                top: 0.1rem;
                height: 0.8rem;
                background: $color-white;
                right: 1.8rem;
                border-radius: 0.3rem;
            }

            &::after {
                top: auto;
                bottom: 0.1rem;
            }
        }

        &_text {
            padding: 1.8rem 1.8rem 1.8rem 1.4rem;
            border: 0.1rem solid $color-grey-dove;
            min-height: 20rem;

            &:focus,
            &:not([value='']),
            &:not([value]),
            &:not(:empty) {
                border: 0.1rem solid $color-primary;

                ~ label {
                    left: 0.7rem;
                    top: -1rem;
                    padding: 0 0.7rem;
                    color: $color-primary;
                    background-color: $color-white;
                    font-size: $font-size-s;
                }
            }
        }
    }
}

// END of specific has code
