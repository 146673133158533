#shops_address_list {
    ul {
        list-style-type: none;
        padding-left: 0;
    }

    .btn_select {
        width: 50%;
        text-align: right;
    }

    .info {
        width: 50%;
    }

    .item_seleted {
        border-color: $mainSecondaryColor;
    }

    .agency-selected {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        text-align: center;
        max-width: 20rem;
        float: right;

        &::after {
            content: '✓'; // TODO: Use check icon
            color: $color-white;
            background-color: $mainSecondaryColor;
            width: 2.5rem;
            height: 2.5rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            margin-left: 0.5rem;
        }
    }
}
