.#{$css-namespace}add_cart_slot--v2 {
    $root: '.#{$css-namespace}add_cart_slot';

    #{$root}__header {
        justify-content: flex-start;
    }

    &[data-delivery-mode='online'] {
        #{$root}__header_icon {
            font-size: 2.4rem;
        }
    }

    &[data-delivery-mode='pickup'] {
        #{$root}__header_icon {
            font-size: 2rem;
            padding: 0.2rem;

            &.dcfonts-icons--pick-up-half-day {
                font-size: 1.75rem;
                padding: 0;
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    &[data-status='unavailable'] {
        #{$root}__header_icon,
        #{$root}__header_title {
            color: $color-main-dark;
        }
    }

    #{$root}__content {
        display: flex;
        flex-direction: column;
        gap: $space-1;

        #{$root}__form {
            display: flex;
            align-items: center;
            gap: $space-1;

            #{$root}__add_button {
                padding: $space-1 $space-3;
                max-width: none;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                gap: $space-2;
                margin: 0;

                > .dcfonts-icons {
                    font-size: 2rem;
                }

                span {
                    font-size: $font-size-default;
                }

                &:disabled {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        .#{$css-namespace}availability {
            + .#{$css-namespace}availability__low-stock {
                margin-top: -$space-1;
            }

            &__text {
                font-weight: $font-weight-bold;
                line-height: 1;
            }

            &__qty,
            &__shop {
                font-weight: $font-weight-regular;
            }

            &__label {
                text-transform: uppercase;
            }
        }

        .#{$css-namespace}round_light {
            width: 0.9rem;
            height: 0.9rem;

            &__text {
                margin-left: 0.6rem;
                font-size: $font-size__xxs;
            }
        }
    }

    #{$root}__quantity_container {
        display: flex;
        @include max-screen($screen__m) {
            width: 65%;
        }
    }
}
