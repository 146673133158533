.account.customer-account-edit,
.account.useraccount-account-preference,
.account.customer-address-index,
.account.customer-address-form {
    .column.main {
        margin: 0;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }
}

.#{$css-namespace}customer-account {
    $root: &;

    display: flex;
    flex-direction: column;
    row-gap: 2.8rem;
    margin-top: 2rem;

    @include min-screen($screen__l) {
        margin-top: 0;
    }

    &_title {
        display: inline-block;
        margin-bottom: 2rem;
        font-family: $font-secondary;
        font-weight: $font-weight-bold;
        font-size: $font-size-xl;
        line-height: 1.25;
        color: $color-primary;
    }

    &_wrapper {
        @include lib-css-wrapper();
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 0 !important;

        @include max-screen($screen__s) {
            padding: 1.5rem 1.5rem 2rem;
        }

        &:has(.#{$css-namespace}customer-account_toolbar) {
            padding: 2.2rem 2rem 2rem;
        }
    }

    &_form-wrapper {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
        row-gap: 2rem;

        @include min-screen($screen__m) {
            flex-direction: row;
        }

        @include min-screen($screen__l) {
            column-gap: 2rem;
        }

        #{$root}_wrapper {
            flex: 1;
        }
    }

    .form-edit-account,
    .form-address-edit {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0;

        fieldset {
            margin-bottom: 2.7rem;

            &.info {
                margin-bottom: 0;
            }
        }
    }

    &_toolbar {
        display: flex;
        gap: 1.05rem;
        flex-direction: column;
        width: 100%;

        @include min-screen($screen__m) {
            flex-direction: row;
        }

        .#{$css-namespace}btn_reset-password {
            @include max-screen($screen__m) {
                margin-top: 2rem;
            }
        }
    }

    &_addresses {
        margin-bottom: 0;
        background-color: $color-white;
        padding: 1.5rem 1.5rem 2rem;

        @include min-screen($screen__m) {
            padding: 2rem 3rem;
        }

        &-list {
            @include min-screen($screen__m) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 3rem;
                grid-row-gap: 2rem;
            }
        }

        .#{$css-namespace}customer-account_toolbar {
            margin-top: 3rem;

            .action {
                font-weight: $font-weight-medium;
                max-width: 37.8rem;
                width: 100%;
                min-width: auto;
            }
        }

        &--edit {
            max-width: 49.1rem;

            .zip-city {
                display: flex;
                column-gap: 1rem;

                .field {
                    margin-left: 0;
                    margin-right: 0;

                    &.zip {
                        width: 36.195%; // with that precision we are pixel perfect on desktop and mobile :)
                    }

                    &.city {
                        flex: 1;
                    }
                }
            }

            .#{$css-namespace}customer-account_toolbar {
                margin: 1rem 0;

                .#{$css-namespace}btn_cancel {
                    min-width: auto;
                    width: 100%;

                    @include min-screen($screen__m) {
                        max-width: 13.8rem;
                    }
                }

                .#{$css-namespace}btn_validate {
                    width: 100%;
                    justify-content: center;
                    min-width: auto;
                    flex: 1;
                }
            }
        }
    }

    &_address {
        padding: 2rem;
        background: $color-grey-background;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;

        & + & {
            @include max-screen($screen__m) {
                margin-top: 2rem;
            }
        }

        &-title {
            color: $color-primary;
            font-weight: $font-weight-bold;
            margin-bottom: 0.5rem;
            display: inline-block;
        }

        &-detail {
            color: $color-black;
            line-height: 1.9rem;
        }

        &-actions {
            display: flex;
            flex-direction: column;
            margin-top: auto;
        }

        &-action {
            margin-top: 1.5rem;
            margin-right: auto;

            &.#{$css-namespace}link {
                text-decoration: none;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                color: $color-secondary;

                span {
                    text-decoration: none;
                }

                .dcfonts-icons {
                    font-size: 1.7rem;
                    width: auto;
                    height: auto;
                    margin-top: -0.2rem;
                    margin-right: 0.5rem;
                }

                @include lib-hover-focus {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .#{$css-namespace}btn {
        &_cancel,
        &_validate,
        &_reset-password {
            // TODO : Refacto needed using w-full
            justify-content: center;
            width: 100%;

            @include min-screen($screen__m) {
                width: auto;
            }
        }

        &_cancel {
            min-width: 25rem;
        }

        &_validate {
            min-width: 30.9rem;
        }

        &_reset-password {
            margin-left: auto;

            &.#{$css-namespace}btn {
                // TODO : Refacto needed
                color: $color-white !important;
                background-color: $color-main-dark !important;
                border-color: transparent !important;
            }
        }
    }

    .#{$css-namespace}input {
        &_phone-number {
            width: 16rem;
        }
    }

    .tooltip {
        top: 50%;
        right: 0.5rem;
        z-index: 10;
        transform: translateY(-50%);

        &-text {
            margin-top: 0;
        }
    }
}
