$confirm-connecting-steps-circle-size: 3.5rem !default;

.accountopeningrequest-success {
    &__congrats {
        .icon-checkmark {
            content: $icon-checkmark;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 4.3rem;
            height: 4.3rem;
            @include lib-font-size(22);
            border-radius: 100%;
            background: $color-green1;
            color: $clearColor;
        }

        h2 {
            @include lib-font-size(22);

            font-weight: $font-weight__regular;

            display: inline-block;
            width: calc(100% - 6rem);
            vertical-align: middle;
            margin-left: 1rem;

            strong {
                font-weight: $font-weight__bold;
            }
        }
    }

    &__summary {
        border: 0.2rem solid $color-blue4;
        position: relative;
        overflow: hidden;
    }
}

.accountopeningrequest-summary {
    @include lib-typography(
        $_color: $mainFontColor,
        $_font-family: $subFont,
        $_font-size: 1.6rem,
        $_font-style: false,
        $_font-weight: $font-weight__regular,
        $_line-height: normal
    );

    &__side {
        p {
            @include lib-font-size(18);

            margin: 0;

            strong {
                display: block;

                color: $color-blue3;
            }
        }

        .actions {
            margin: $indent__m auto;
            padding: 0;

            .action.primary {
                height: auto;
                text-align: center;
                background: $color-blue1;
            }
        }
    }

    &__user {
        margin-top: #{$indent__xl + 5};
        padding-top: #{$indent__xl + 5};

        border-top: 0.2rem dotted $color-gray52;

        .accountopeningrequest-user--company {
            h3 {
                font-weight: $font-weight__bold;

                margin-top: 0;
            }

            .list {
                line-height: 2.2rem;

                padding: 0;

                list-style-type: none;

                .item {
                    margin: 0;

                    &-title {
                        @include lib-font-size(14);

                        display: inline-block;

                        width: 10rem;

                        color: $color-gray52;
                        vertical-align: top;
                    }
                }
            }
        }
    }
}

.agency__phone-confirm {
    display: flex;
    align-items: center;
    margin: $indent__s 0 0;
    @include lib-font-size(24);
    font-weight: $font-weight__bold;
    color: $color-blue3;

    .icon-telephone {
        flex: 0 0 auto;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: $indent__s;
    }
}

.accountopeningrequest-summary {
    &__user {
        margin-top: #{$indent__xl + 5};
        padding-top: #{$indent__xl + 5};

        border-top: 0.2rem dotted $color-gray52;

        h2 {
            @include lib-font-size(22);

            font-weight: $font-weight__bold;

            margin-top: 0;
        }
    }
}

@include max-screen($screen__l) {
    #accountopeningrequest-success {
        padding: 0 $indent__l;
    }

    .accountopeningrequest-success {
        &__summary {
            padding: $space-2;
        }
    }

    .accountopeningrequest-summary {
        @include lib-typography(
            $_color: $mainFontColor,
            $_font-family: $subFont,
            $_font-size: 1.6rem,
            $_font-style: false,
            $_font-weight: $font-weight__regular,
            $_line-height: normal
        );

        &__side {
            p {
                @include lib-font-size(16);

                margin: 0;

                strong {
                    display: block;

                    color: $color-blue3;
                }
            }
        }
    }

    .accountopeningrequest-agency {
        &__confirm {
            padding: 0;
            margin: 0 auto;
            list-style: none;
            width: 25rem;

            .agency {
                &__name-confirm {
                    @include lib-font-size(18);

                    font-weight: $font-weight__bold;

                    margin-bottom: 0;

                    color: $color-blue3;
                }

                &__address-confirm {
                    @include lib-font-size(16);
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    #accountopeningrequest-success {
        width: 114rem;
        margin: auto;
    }

    .accountopeningrequest-success {
        &__congrats {
            width: 66rem;
            margin: 0 auto $indent__l;

            text-align: center;
        }

        &__summary {
            display: flex;
            flex-flow: row wrap;

            padding: $indent__xl;
        }
    }
    .accountopeningrequest-summary {
        &__side {
            flex: 1;

            p {
                @include lib-font-size(18);

                margin: 0;
                padding-right: $indent__xl;

                strong {
                    display: block;

                    color: $color-blue3;
                }
            }

            .actions {
                margin: $indent__s 0 0;
            }
        }

        &__agency {
            position: relative;

            display: flex;
            align-items: center;
            flex: 1;

            border-left: 2x solid $color-gray-darken1;

            &::before {
                position: absolute;
                top: 50%;
                left: -0.9rem;

                display: block;

                width: 1.5rem;
                height: 1.5rem;

                content: '';
                transform: translateY(-50%) rotate(-45deg);

                border-right: 0.2rem solid $color-gray-darken1;
                border-bottom: 0.2rem solid $color-gray-darken1;
                background: $clearColor;
            }
        }

        &__agency {
            position: relative;

            display: flex;
            align-items: center;
            flex: 1;

            border-left: 0.1rem solid $color-gray-darken1;

            &::before {
                position: absolute;
                top: 50%;
                left: -0.9rem;

                display: block;

                width: 1.5rem;
                height: 1.5rem;

                content: '';
                transform: translateY(-50%) rotate(-45deg);

                border-right: 0.2rem solid #e2e2e2; //TODO: use $color-gray-darken1 var instead of hexa
                border-bottom: 0.2rem solid #e2e2e2; //TODO: use $color-gray-darken1 var instead of hexa
                background: $color-white;
            }
        }
    }

    .accountopeningrequest-user--company {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 1;

        > div {
            flex: 1;

            padding-left: $indent__l;

            &:first-child {
                padding-left: 0;

                .list {
                    border-right: 0.1rem dotted $color-gray52;
                }
            }
        }
    }

    .accountopeningrequest-agency {
        &__confirm {
            padding: 0 0 0 #{$indent__xl + 15};
            margin: 0;
            max-width: 35rem;
            list-style: none;

            .agency {
                &__name-confirm {
                    @include lib-font-size(22);

                    font-weight: $font-weight__bold;

                    margin-bottom: 0;

                    color: $color-blue3;
                }

                &__address-confirm {
                    @include lib-font-size(18);
                }
            }
        }
    }
    .accountopeningrequest-success {
        &__summary {
            &::before {
                content: '';
                display: block;
                width: 40rem;
                height: 40rem;
                position: absolute;
                right: -21.5rem;
                background: $color-blue3;
                transform: rotate(-45deg);
                top: -20rem;
                z-index: 12;
            }

            &::after {
                content: '';
                display: block;
                width: 25rem;
                height: 25rem;
                position: absolute;
                right: -12.5rem;
                background: $altBackgroundColor;
                transform: rotate(-45deg);
                top: 17rem;
                z-index: 13;
            }
        }
    }
}

.accountopeningrequest-success-page {
    margin-top: 4rem;

    .#{$css-namespace}title[data-ui-title-level='3'] {
        font-size: $font-size-xl;
    }
}

.accountopeningrequest-confirm-index .quotation-success-header {
    margin-left: -2rem;
    margin-right: -2rem;
}
