.#{$css-namespace}copy-paste {
    @include max-screen($screen__m) {
        display: flex;
        flex-direction: column;
    }

    .#{$css-namespace}modal-title {
        margin-left: 3.5rem;
        @include max-screen($screen__m) {
            padding: 0 7rem 2rem;
            display: flex;
            margin: 0;
        }
    }

    &_actions {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &_mentions {
        margin-bottom: 2rem;
        font-weight: $font-weight-bold;

        .dcfonts-icons {
            color: $color-secondary;
            font-size: 2.2rem;
            margin-right: 1rem;
            display: inline-block;
            vertical-align: middle;

            @include max-screen($screen__l) {
                font-size: $font-size-s;
            }
        }
    }
}
