.#{$css-namespace}discriminant {
    $root: &;

    display: flex;
    flex-direction: column;
    flex: 1;

    @include min-screen($screen__l) {
        margin-right: 2.7rem;
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__header {
        margin-bottom: $indent__base;
        display: flex;
        align-items: center;

        @include min-screen($screen__l) {
            align-items: flex-end;
        }

        #{$root}__parameter_button {
            width: 2.8rem;
            height: 2.8rem;

            &.list-display,
            &.table-display {
                padding: 0;
                border: none;
                display: initial;
            }

            &.list-display {
                margin-right: 1rem;
            }

            &.table-display {
                color: $color-gray-light2;
                z-index: 3;

                span {
                    color: $color-grey-dark;

                    @include max-screen($screen__l) {
                        display: none;
                    }
                }
            }

            .dcfonts-icons {
                font-size: 2.8rem;
            }

            span {
                border-color: $color-secondary;

                &::after {
                    border-color: $color-secondary;
                }
            }
        }

        &_title {
            font-family: $font-secondary;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            font-size: $font-size-default;
            margin-right: 1.5rem;

            @include min-screen($screen__l) {
                font-size: $font-size-m;
                line-height: 2.2rem;
            }
        }

        &__parameter_button {
            margin-right: 1rem;
        }
    }

    &__parameter_container {
        display: flex;
        flex-direction: column;
    }

    &__parameter_list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 $indent__base;
    }

    &__parameter_item {
        margin: 0 0.5rem 0.5rem 0;
    }

    &__parameter_label,
    &__parameter_button {
        font-size: $font-size-default;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-grey-dark;
    }

    &__parameter_label_container {
        margin-bottom: 1rem;

        @include min-screen($screen__l) {
            margin-bottom: 1.5rem;
        }
    }

    &__parameter_label_unit {
        font-size: $font-size-default;
        font-weight: $font-weight-regular;
        text-transform: none;
        color: $color-gray43;
    }

    &__parameter_button {
        border: 0.1rem solid $color-grey-dark;
        border-radius: 0.3rem;
        padding: $space-1;
        color: $color-grey-dark;
        background-color: $color-white;
        line-height: 1;
        height: auto;
        text-transform: none;

        &.inactive {
            opacity: 0.5;
            color: $color-disabled-light;
            border-color: $color-disabled;
        }

        &:empty {
            visibility: hidden; // avoid empty visible square when loading
        }

        &:not(.table-display) {
            &.selected,
            &:focus {
                color: $color-white;
                background-color: $color-grey-dark;
                cursor: default;
                box-shadow: 0 0 0.4rem 0 transparentize($color-black, 0.7); // TODO: Use box-shadow var
            }

            &.selected {
                &:hover {
                    border-color: $color-grey-dark;
                }
            }
        }

        &.no-selectionable {
            color: $color-disabled-light;
            border-color: $color-disabled;
            font-weight: $font-weight-regular;
        }

        @include min-screen($screen__l) {
            &:hover:not(.selected):not(:focus) {
                background-color: $color-blue-light;
                color: $color-grey-dark;
                border-color: $color-grey-dark;
                font-weight: $font-weight-bold;

                &.table-display {
                    background-color: transparentize($color-blue-light, 0.5);
                }
            }
        }
    }

    &__product_info {
        padding-bottom: $indent__base;

        #{$root}__info_text {
            line-height: 1.9rem;

            &--key,
            &--hightlight {
                font-weight: $font-weight-medium;
            }

            &--highlight {
                text-transform: uppercase;
                color: $color-primary;
                font-weight: $font-weight-bold;
            }

            &--separator {
                font-weight: $font-weight-light;

                &:empty {
                    display: none;
                }
            }
        }
    }
}
