// Variables
$campton-font-path: '#{$path-fonts}/Campton' !default;

// regular version (default)
@font-face {
    font-family: 'Campton';
    src: url('#{$campton-font-path}/Campton-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// bold version
@font-face {
    font-family: 'Campton';
    src: url('#{$campton-font-path}/Campton-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// semibold version
@font-face {
    font-family: 'Campton';
    src: url('#{$campton-font-path}/Campton-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
