@mixin lib-css-border($borderWidth, $borderStyle, $borderColor) {
    border: $borderWidth $borderStyle $borderColor;
}

@mixin lib-css-border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

@mixin lib-css-border-less() {
    border: 0;
}
