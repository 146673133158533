.#{$css-namespace}pending_orders_requests_requests_table {
    .#{$css-namespace}orders_table_item {
        &_reference-webshop,
        &_reference-order {
            @include min-screen($screen__l) {
                width: 26.5rem !important;
            }
        }

        &_reference-order {
            @include min-screen($screen__xl) {
                width: 37.2rem !important;
            }
        }

        &_date,
        &_price-vat-exclusive {
            @include min-screen($screen__l) {
                width: 12.3rem !important;
            }
        }

        &_price-vat-exclusive {
            .#{$css-namespace}orders_table_item_text {
                display: flex;
                align-items: flex-end;
            }

            .price {
                line-height: 1.9rem;
            }

            .tax-excl {
                font-size: $font-size-s;
                margin-left: 0.3rem;
            }
        }
    }
}
