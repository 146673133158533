@include max-screen($screen__s) {
    .cartouche {
        &.locate {
            p {
                &:first-of-type {
                    display: none;
                }
            }
        }
    }
}

.cartouche {
    &.locate {
        .cartouche- {
            &image {
                @include lib-background-image(
                    'thumbnail_carte_france_footer_2.png',
                    0 0,
                    no-repeat,
                    transparent,
                    false
                );
            }
        }
    }
}
