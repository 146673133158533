.useraccount-multiuser-agency {
    &.account .#{$css-namespace}header-simplify .#{$css-namespace}progress-bar--acount {
        display: none;
    }

    .page-main {
        padding-top: 0;
    }

    .page.messages {
        margin-bottom: 0;
    }

    .actions-toolbar {
        display: flex;
        align-items: center;

        .#{$css-namespace}link-back {
            margin: 0;
        }
    }

    .columns {
        display: block;
        margin-top: 0 !important; //overwritting margin-top of account class from theme v2

        .column.main {
            padding: 0 $space-3;
            box-shadow: none;
            background-color: transparent;
        }
    }
}

.#{$css-namespace}choose-agency {
    max-width: 60rem;
    margin: auto;

    h2 {
        margin-top: 0 !important; //overwritting h2 margin-top of account class from theme v2
    }

    li {
        margin: 0;
    }

    ul {
        margin-bottom: 0;
    }

    .#{$css-namespace}title-section,
    .#{$css-namespace}p {
        text-align: center;
    }

    .#{$css-namespace}p {
        margin: 2.8rem 0 3rem;

        @include min-screen($screen__l) {
            margin: 3.3rem 6rem 3rem;
        }
    }

    &--search {
        display: flex;
        justify-content: center;
        margin: auto;

        &-container {
            width: 100%;
            position: relative;

            .#{$css-namespace}filter_agency {
                height: 4rem;
                padding: 1rem;
                border-radius: 0.4rem 0 0 0.4rem;
                border: 0.1rem solid $color-primary;
                border-right: none;
                color: $color-main-dark;
                font-size: 1.3rem;
                font-family: $font-default;

                &::placeholder {
                    color: $color-main-dark;
                }
            }

            .dcfonts-icons--cross {
                position: absolute;
                right: 1rem;
                top: 1.3rem;
                font-size: 1.3rem;
                color: $color-secondary;
            }
        }

        &-submit {
            display: flex;
            align-items: center;
            padding: $space-1;
            background-color: $color-form-search-submit-background;
            border-radius: 0 0.4rem 0.4rem 0;
            border-color: transparent;
            height: 4rem;

            &:hover {
                background-color: $color-form-search-submit-background-darken;
                border-color: transparent;
            }

            .#{$css-namespace}icon {
                color: $color-main-light;
            }

            span {
                display: none;
            }
        }
    }

    &_list-category {
        padding: 0;
        list-style: none;
        margin-top: 4rem;
    }

    &_category-title-container {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        @include min-screen($screen__l) {
            align-items: baseline;
        }

        .dcfonts-icons {
            color: $color-primary;
            font-size: 2.2rem;
            margin-right: 1rem;
            margin-left: 0.5rem;

            @include min-screen($screen__l) {
                margin-right: 2rem;
            }
        }
    }

    &_distribution-network {
        text-transform: uppercase;
        display: flex;

        @include min-screen($screen__l) {
            display: inline;
        }
    }

    &_tile {
        position: relative;
        border: 0.1rem solid $color-disabled-light;
        border-radius: 0.3rem;
        padding: 1.5rem 2rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        flex-direction: column;
        gap: $space-2;

        &:not(&--connected):hover {
            cursor: pointer;
            border-color: $color-secondary;
            @include lib-css(box-shadow, $shadow);
        }

        &--disabled {
            pointer-events: none;
        }
    }

    &_data {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include min-screen($screen__l) {
            width: auto;
        }

        span {
            line-height: 1.5;
        }

        &-title {
            color: $color-primary;
        }
    }

    &_btn-container {
        width: 100%;

        .#{$css-namespace}btn {
            // TODO : Refacto needed
            display: flex;
            justify-content: center;
            width: 100%;

            &--disabled {
                // TODO : Refacto needed using disabled
                pointer-events: none;
                border-color: $color-disabled-light;
                background-color: $color-white;
                color: $color-disabled-text;
                font-weight: $font-weight-medium;

                &::after {
                    cursor: not-allowed;
                }
            }

            @include min-screen($screen__l) {
                display: block;
                text-align: center;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
            }
        }

        .#{$css-namespace}logged-in {
            color: $color-success-secondary;
            background-color: $color-success-background;
            display: flex;
            padding: 0.3rem 1rem;
            min-width: 11rem;
            font-weight: $font-weight-medium;
            border-radius: 3rem;
            width: fit-content;

            .dcfonts-icons--check-circle {
                margin-right: $space-1;
            }
        }
    }

    ul:last-of-type {
        div:last-of-type {
            margin-bottom: 0;
        }
    }

    .#{$css-namespace}title.no-result {
        text-align: center;
        color: $color-secondary;
        padding: 0 2rem;
        margin-bottom: 9.5rem;
        margin-top: 4rem !important; //overwritting h2 margin-top of account class from theme v2
        font-size: $font-size-xl;

        @include min-screen($screen__l) {
            margin-bottom: 7.8rem;
            padding: 0 $space-10;
            font-size: $font-size-xxl;
        }
    }

    .no-display {
        display: none;
    }
}
