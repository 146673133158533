.accountopeningrequest-form-specialities,
.accountopeningrequest-form-activities {
    &:empty {
        padding: 0;
    }

    &:not([data-hasrequired])::after {
        display: none;
    }

    p {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }

    .h-flex {
        align-items: stretch;

        [class*='col-'] {
            align-items: center;

            > * {
                width: 100%;
                align-self: stretch;

                &.control,
                .control {
                    height: 100%;
                    width: 100%;

                    .input-speciality {
                        ~ label {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .activities-container {
        p {
            margin-top: 0;
        }

        [data-ui-radio-group-skin='primary'] input[type='radio'] {
            + label {
                text-transform: uppercase;
                border: solid 0.1rem $color-blue-background-1;
                max-width: 100%;

                span.text {
                    word-break: break-word;
                    overflow-wrap: break-word;
                }
            }

            &:checked + label {
                border-color: $color-primary;
            }
        }
    }
}

.accountopeningrequest-form-activities > p {
    margin-top: 0;
}
