.#{$css-namespace}dashboard_quicklinks {
    padding: 0 2rem;
    margin-top: $space-3;
    margin-bottom: 3rem;
    width: 100%;

    @include min-screen($screen__l) {
        padding: 0;
    }

    &_blocks {
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            flex-direction: row;
        }
    }

    &_block {
        width: 100%;
        height: 10.6rem;
        margin-top: 1.5rem;
        border-radius: 1.8rem;
        color: $color-white;
        font-size: $font-size-l;
        line-height: 2.2rem;
        font-weight: $font-weight__bold;
        display: block;
        cursor: pointer;

        &:hover,
        &:visited {
            color: $color-white;
        }

        @include min-screen($screen__l) {
            margin-right: 2rem;
            margin-top: 2rem;

            &:last-child {
                margin-right: 0;
            }
        }

        &-bluedark {
            background-color: #004894; // TODO: Use global var
        }

        &-bluelight {
            background-color: $color-secondary;
        }

        &-blueprimary {
            background-color: $color-primary;
        }

        &-orange {
            background-color: #f66d48; // TODO: Use global var
        }

        &-yellow {
            background-color: #ebb100; // TODO: Use global var
        }
    }

    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        padding: 0 4.5rem;
        align-items: center;
        justify-content: center;

        @include min-screen($screen__l) {
            padding: 0 2.5rem;
        }

        p {
            width: 100%;
            text-align: center;
        }

        .dcfonts-icons {
            font-size: $font-size-l;
            margin-right: $space-1;
        }
    }
}
