.field.qty {
    > .control {
        display: inline-flex;
        align-items: center;

        > button {
            flex: 0 0 auto;
            width: 3rem;
            height: 3.4rem;
            padding: $indent__xs;
            border: 0.1rem solid $border-color__base;
            background-color: $color-white;

            &.qty-minus {
                border-right-width: 0;
            }

            &.qty-plus {
                border-left-width: 0;
            }
        }

        > .input-text {
            flex: 0 0 auto;
            width: 3.4rem;
            height: 3.4rem;
            padding: $indent__xs 0.2rem;
            border-radius: 0;
            text-align: center;
        }
    }
}
