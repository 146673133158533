$section-table-padding-tiny: 1.5rem !default;
$section-table-padding: $section-table-padding-tiny 2.8rem !default;
$section-table-border-width: 0.1rem !default;
$section-table-border-color: #e7e7ed !default;
$section-table-border-color-alt: $color-grey-argent !default;
$section-table-border: solid $section-table-border-width $section-table-border-color !default;
$section-table-border-alt: solid $section-table-border-width $section-table-border-color-alt !default;
$section-table-fixed-width-md: 132.5rem - 0.2rem !default;
$section-table-fixed-width-sm: 80rem !default;
$section-table-fixed-width-xs: 50rem !default;

$data-bkg-table-colors: (
    'blue-extra-light': (
        'default': $color-tables-blue-extra-light,
    ),
    'blue-dark': (
        'default': $color-tables-blue-dark,
    ),
    'blue-primary': (
        'default': $color-primary,
    ),
    'blue-secondary': (
        'default': $color-secondary,
        '60': rgba($color-secondary, 0.6),
        '65': rgba($color-secondary, 0.65),
        '70': rgba($color-secondary, 0.7),
        '75': rgba($color-secondary, 0.75),
        '80': rgba($color-secondary, 0.8),
        '85': rgba($color-secondary, 0.85),
        '90': rgba($color-secondary, 0.9),
    ),
);

.#{$css-namespace}section_table {
    $root: &;

    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 0.4rem;
    overflow: hidden;
    border: $section-table-border;

    img {
        max-width: 100%;
        width: auto;
    }

    .bordered {
        @each $direction in (bottom, top, left, right) {
            &-#{$direction} {
                border-#{$direction}: $section-table-border-alt;
            }
        }
    }

    &-flex {
        display: flex;
        flex-wrap: wrap;
        background-color: $color-white;

        #{$root}-fixed-width & {
            min-width: $section-table-fixed-width-md;

            @include max-screen($screen__m) {
                min-width: $section-table-fixed-width-sm;
            }

            @include max-screen($screen__s) {
                min-width: $section-table-fixed-width-xs;
            }
        }
    }

    &-thead,
    &-tbody > .row-flex {
        display: flex;
        align-content: stretch;
        flex-wrap: wrap;
        flex: 1;

        &.text-center > div,
        .text-center {
            justify-content: center;
            text-align: center;
        }

        > div {
            padding: $section-table-padding;
            border-bottom: $section-table-border;
            border-right: $section-table-border;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            max-width: 100%;

            > :last-child {
                margin-bottom: 0;
            }

            &.cell-blue {
                border-left: transparent;
                border-right: transparent;

                &:not([class*='bordered']) {
                    border-color: transparent;
                }

                &.divided {
                    border-top: none;
                    border-bottom: none;
                    padding: 0;
                    align-items: center;

                    > * {
                        height: 100%;
                        font-weight: $font-weight-bold;
                        padding: 2rem 1.2rem;
                    }

                    > span {
                        flex: 0 0 50%;
                        max-width: 50%;
                        @extend .bordered-right;
                    }

                    > strong {
                        display: flex;
                        flex-wrap: wrap;
                        flex: 1;
                        @extend .bordered-bottom;

                        > * {
                            align-self: center;
                        }
                    }

                    &.first {
                        align-items: flex-start;

                        > strong {
                            align-content: flex-start;
                        }
                    }

                    &.last > span {
                        @extend .bordered-bottom;
                        height: 100%;
                    }
                }
            }

            &.space-between {
                justify-content: space-between;
                gap: 1rem;

                .line {
                    flex: 1;
                    margin-left: 0.1rem;
                    margin-right: 0.1rem;
                    height: 0.1rem;
                    background-color: $color-black;

                    @include max-screen($screen__m) {
                        display: none;
                    }
                }
            }

            ol,
            ul {
                padding-left: 2rem;
                margin-top: 0.5rem;
                margin-bottom: 1rem;

                li {
                    margin-bottom: 0.25rem;
                }
            }

            ul {
                padding-left: 1.5rem;
            }

            .table-figcaption-big {
                font-family: $font-secondary;
                text-transform: uppercase;
                line-height: 1;
                font-size: 1.7rem;
            }

            @include max-screen($screen__m) {
                min-width: 8rem;
            }

            // Cols width
            .col-2 & {
                flex: 0 0 50%;
                max-width: 50%;
            }

            .col-3 & {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }

            .col-4 & {
                flex: 0 0 25%;
                max-width: 25%;
            }

            .col-5 & {
                flex: 0 0 20%;
                max-width: 20%;
            }

            .col-6 & {
                flex: 0 0 16.6667%;
                max-width: 16.6667%;
            }

            .col-7 & {
                flex: 0 0 14.2857%;
                max-width: 14.2857%;
                padding-left: $section-table-padding-tiny;
                padding-right: $section-table-padding-tiny;
            }

            .col-8 & {
                flex: 0 0 12.5%;
                max-width: 12.5%;
                padding-left: $section-table-padding-tiny;
                padding-right: $section-table-padding-tiny;
            }

            .col-9 & {
                flex: 0 0 11.111111111%;
                max-width: 11.111111111%;
                padding-left: $section-table-padding-tiny;
                padding-right: $section-table-padding-tiny;
            }

            // alignments flex
            .align-start & {
                align-items: flex-start;

                > * {
                    align-self: flex-start;
                }
            }

            .align-end & {
                align-items: flex-end;

                > * {
                    align-self: flex-end;
                }
            }
        }

        &.borderless > div {
            &.bordered-right {
                border-bottom-color: transparent;
            }

            &:not(.bordered-right) {
                border-color: transparent;
            }
        }
    }

    &-cells-blue,
    &-thead {
        > td,
        > div {
            color: $color-white;
            background-color: $color-secondary;
        }

        > div {
            line-height: 2.2rem;
            font-weight: $font-weight-bold;
            border-bottom-color: transparent;
            padding-top: 1.4rem;
            padding-bottom: 1.4rem;
        }
    }

    .cell {
        &-blue {
            color: $color-white;
            background-color: $color-secondary;
            border-color: transparent;

            &-dark {
                background: $color-primary;
                color: $color-white;
            }
        }

        &-grey {
            background: $color-gray97;
            border-color: $color-white;
        }

        &-dark {
            background: $color-dark-grey;
            color: $color-grey-background;
        }
    }

    .dcfonts-icons {
        &--check {
            color: $color-success-secondary;
        }

        &--cross {
            color: #d9344a; // TODO : Use a global var
        }

        &:not(.-inline):not(:only-child) {
            margin-bottom: 1rem;
        }
    }

    &-tbody {
        width: 100%;

        > .row-flex {
            > div#{$root}-cells-blue:last-child,
            &:last-child > div:not(#{$css-namespace}section_table-cells-blue) {
                border-bottom: none;
            }

            > div:last-child {
                border-right: none;
            }
        }
    }

    &-thead > div,
    &-tbody > .row-flex > div#{$root}-cells-blue {
        border-color: transparent;
    }

    &-thead.bordered > div:not(:first-child) {
        border-left: $section-table-border-alt;
    }

    &-fixed-width {
        overflow-x: auto !important;
    }

    // colors
    [data-color='white'] {
        color: $color-white;
    }

    [data-bkg-color] > div {
        border-color: $section-table-border-color-alt;
    }

    @each $background-color-name, $background-color-values in $data-bkg-table-colors {
        $background-color-selector: "[data-bkg-color='#{$background-color-name}']";

        #{$background-color-selector} {
            background-color: map-get($background-color-values, 'default');
        }

        @each $opacity, $value in $background-color-values {
            $opacity-selector: '[data-bkg-color="#{$background-color-name}-#{$opacity}"]';

            #{$opacity-selector} {
                background-color: $value;

                .bordered-right {
                    border-color: $section-table-border-color-alt;
                }

                .borderless & {
                    border-bottom-color: transparent;

                    @each $direction in (bottom, top, left) {
                        &:not(.bordered-#{$direction}) {
                            border-#{$direction}-color: $value;
                        }
                    }
                }
            }
        }
    }
}

.tk__container
    .#{$css-namespace}section_table.#{$css-namespace}section_table-fixed-width
    .#{$css-namespace}section_table-flex {
    @include min-screen($screen__m) {
        min-width: 120.5rem - 0.2rem;
    }
}
