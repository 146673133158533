$card-with-fieldset-margin-top: 0.5rem; // from frontend/Descours_v2/Prolians/fr_FR/Descours/styles/toolkit/common/01_components/cards/card/_card-with-fieldset.scss top position

@import 'modal/change-store-modal';

.favorite-shop-container {
    margin: 0 auto -1rem;
    padding: 3rem 1rem;
    max-width: var(--container-narrow-width);
    border-radius: 0.3rem;
    background-color: $color-white;
    box-shadow: var(--box-shadow-light);
    display: flex;
    flex-direction: column;
    gap: $space-2;

    @include min-screen($screen__m) {
        padding: 3rem 3.5rem 4rem;
    }

    .agency-card {
        position: relative;
    }

    #selectShopForm {
        display: flex;
        flex-direction: column;
        gap: $space-2;
    }

    #selectShopForm {
        display: flex;
        flex-direction: column;
        gap: $space-2;
    }
}

.customer-come-back{
    &:has(~ div > .favorite-shop-container),
    &:has(~ .favorite-shop-container) {
        margin-left: auto;
        margin-right: auto;
        width: #{'min(var(--container-narrow-width), 100%)'};
    }
}
