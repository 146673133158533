//
//  Tables
//  _____________________________________________

@mixin lib-table(
    $_table-width: $table__width,
    $_cell-padding-vertical: $table-cell__padding-vertical,
    $_cell-padding-horizontal: $table-cell__padding-horizontal,
    $_table-margin-bottom: $table__margin-bottom
) {
    @include lib-css(margin-bottom, $_table-margin-bottom);
    @include lib-css(width, $_table-width);
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;

    th {
        text-align: left;
    }

    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                vertical-align: top;
            }
        }
    }

    > thead > tr {
        > th,
        > td {
            vertical-align: bottom;
        }
    }

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                @include lib-css(padding, $_cell-padding-vertical $_cell-padding-horizontal);
            }
        }
    }
}

@mixin lib-table-typography(
    $_table-td-font-size: $table-td__font-size,
    $_table-td-color: $table-td__color,
    $_table-td-font-family: $table-td__font-family,
    $_table-td-font-weight: $table-td__font-weight,
    $_table-td-line-height: $table-td__line-height,
    $_table-td-font-style: $table-td__font-style,
    $_table-th-font-size: $table-th__font-size,
    $_table-th-color: $table-th__color,
    $_table-th-font-family: $table-th__font-family,
    $_table-th-font-weight: $table-th__font-weight,
    $_table-th-line-height: $table-th__line-height,
    $_table-th-font-style: $table-th__font-style
) {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > td {
                @include lib-typography(
                    $_font-size: $_table-td-font-size,
                    $_color: $_table-td-color,
                    $_font-family: $_table-td-font-family,
                    $_font-weight: $_table-td-font-weight,
                    $_line-height: $_table-td-line-height,
                    $_font-style: $_table-td-font-style
                );
            }

            > th {
                @include lib-typography(
                    $_font-size: $_table-th-font-size,
                    $_color: $_table-th-color,
                    $_font-family: $_table-th-font-family,
                    $_font-weight: $_table-th-font-weight,
                    $_line-height: $_table-th-line-height,
                    $_font-style: $_table-th-font-style
                );
            }
        }
    }
}

@mixin lib-table-background-color(
    $_table-background-color: $table__background-color,
    $_table-head-background-color: $table-head__background-color,
    $_table-foot-background-color: $table-foot__background-color,
    $_table-td-background-color: $table-td__background-color,
    $_table-body-th-background-color: $table-body-th__background-color
) {
    @include lib-css(background, $_table-background-color);

    > thead {
        @include lib-css(background, $_table-head-background-color);
    }

    > tfoot {
        @include lib-css(background, $_table-foot-background-color);
    }

    > tbody > tr > td {
        @include lib-css(background, $_table-td-background-color);
    }

    > tbody > tr > th {
        @include lib-css(background, $_table-body-th-background-color);
    }
}

@mixin lib-table-resize(
    $_td-padding-top: $table-cell__padding-vertical / 2,
    $_td-padding-right: $table-cell__padding-horizontal / 2,
    $_td-padding-bottom: $_td-padding-top,
    $_td-padding-left: $_td-padding-right,
    $_th-padding-top: $_td-padding-top,
    $_th-padding-right: $_td-padding-right,
    $_th-padding-bottom: $_td-padding-top,
    $_th-padding-left: $_td-padding-right
) {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > td {
                @include lib-css(padding, $_td-padding-top $_td-padding-right $_td-padding-bottom $_td-padding-left);
            }

            > th {
                @include lib-css(padding, $_th-padding-top $_th-padding-right $_th-padding-bottom $_th-padding-left);
            }
        }
    }
}

@mixin lib-table-bordered(
    $_table_type: normal,
    $_table_border-width: $table__border-width,
    $_table_border-style: $table__border-style,
    $_table_border-color: $table__border-color
) {
    @if $_table_type == normal {
        @include lib-css(border, $_table_border-width $_table_border-style $_table_border-color);
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    @include lib-css(border, $_table_border-width $_table_border-style $_table_border-color);
                }
            }
        }
    }
    @if $_table_type == horizontal {
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    @include lib-css(border-top, $_table_border-width $_table_border-style $_table_border-color);
                }
            }
        }

        > caption + thead,
        > colgroup + thead,
        > thead:first-child {
            > tr:first-child {
                > th,
                > td {
                    border-top: 0;
                }
            }
        }

        > tbody + tbody {
            @include lib-css(border-top, $_table_border-width $_table_border-style $_table_border-color);
        }
    }

    @if $_table_type == horizontal_body {
        > tbody {
            > tr {
                > th,
                > td {
                    @include lib-css(border-top, $_table_border-width $_table_border-style $_table_border-color);
                }
            }
        }

        > tbody + tbody {
            @include lib-css(border-top, $_table_border-width $_table_border-style $_table_border-color);
        }
    }

    @if $_table_type == vertical {
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    @include lib-css(border-left, $_table_border-width $_table_border-style $_table_border-color);

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }
    }

    @if $_table_type == light {
        border: none;

        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    border: none;
                }
            }
        }

        > thead {
            > tr {
                > th,
                > td {
                    @include lib-css(border-bottom, $_table_border-width $_table_border-style $_table_border-color);
                }
            }
        }
    }

    @if $_table_type == clear {
        border: none;

        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    border: none;
                }
            }
        }
    }
}

@mixin lib-table-striped(
    $_stripped-background-color: $table-cell-stripped__background-color,
    $_stripped-color: $table-cell-stripped__color,
    $_stripped-direction: horizontal,
    $_stripped-highlight: odd
) {
    @if $_stripped-highlight == odd and $_stripped-direction == horizontal {
        > tbody > tr:nth-child(odd) {
            > td,
            > th {
                @include lib-css(background, $_stripped-background-color);
                @include lib-css(color, $_stripped-color);
            }
        }
    }

    @if $_stripped-highlight == even and $_stripped-direction == horizontal {
        > tbody > tr:nth-child(even) {
            > td,
            > th {
                @include lib-css(background, $_stripped-background-color);
                @include lib-css(color, $_stripped-color);
            }
        }
    }

    @if $_stripped-highlight == odd and $_stripped-direction == vertical {
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th:nth-child(odd),
                > td:nth-child(odd) {
                    @include lib-css(background, $_stripped-background-color);
                    @include lib-css(color, $_stripped-color);
                }
            }
        }
    }

    @if $_stripped-highlight == even and $_stripped-direction == vertical {
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th:nth-child(even),
                > td:nth-child(even) {
                    @include lib-css(background, $_stripped-background-color);
                    @include lib-css(color, $_stripped-color);
                }
            }
        }
    }
}

@mixin lib-table-hover(
    $_table_cell-background-color-hover: $table-cell__hover__background-color,
    $_table_cell-odd-background-color-hover: $_table_cell-background-color-hover
) {
    > tbody > tr:nth-child(even):hover {
        > td,
        > th {
            @include lib-css(background, $_table_cell-background-color-hover);
        }
    }

    > tbody > tr:nth-child(odd):hover {
        > td,
        > th {
            @include lib-css(background, $_table_cell-odd-background-color-hover);
        }
    }
}

@mixin lib-table-overflow() {
    @include lib-css(margin-bottom, $table__margin-bottom);
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}

@mixin lib-table-responsive(
    $_table-background-color-responsive: $table-responsive__background-color,
    $_table-th-background-color-responsive: $table-responsive-th__background-color,
    $_reset-table-striped: false,
    $_reset-table-hover: false,
    $_table-responsive-cell-padding: $table-responsive-cell__padding
) {
    @if $_reset-table-striped == false and $_reset-table-hover == false {
        @include _lib-responsive-table(
            $_table-background-color-responsive: $_table-background-color-responsive,
            $_table-responsive-cell-padding: $_table-responsive-cell-padding
        );

        > tbody > tr > th {
            @include lib-css(background-color, $_table-th-background-color-responsive);
        }
    }

    @if $_reset-table-striped == true and $_reset-table-hover == true {
        @include lib-table-striped($_stripped-background-color: $_table-background-color-responsive);
        @include lib-table-hover(
            $_table_cell-background-color-hover: $_table-background-color-responsive,
            $_table_cell-odd-background-color-hover: $_table-background-color-responsive
        );

        @include _lib-responsive-table(
            $_table-background-color-responsive: $_table-background-color-responsive,
            $_table-responsive-cell-padding: $_table-responsive-cell-padding
        );

        > tbody > tr > th {
            @include lib-css(background-color, $_table-th-background-color-responsive '!important');
        }
    }

    @if $_reset-table-striped == true and $_reset-table-hover == false {
        @include lib-table-striped($_stripped-background-color: $_table-background-color-responsive);

        @include _lib-responsive-table(
            $_table-background-color-responsive: $_table-background-color-responsive,
            $_table-responsive-cell-padding: $_table-responsive-cell-padding
        );

        @include lib-css(background-color, $_table-th-background-color-responsive);
    }

    @if $_reset-table-striped == false and $_reset-table-hover == true {
        @include lib-table-hover(
            $_table_cell-background-color-hover: $_table-background-color-responsive,
            $_table_cell-odd-background-color-hover: $_table-background-color-responsive
        );

        @include _lib-responsive-table(
            $_table-background-color-responsive: $_table-background-color-responsive,
            $_table-responsive-cell-padding: $_table-responsive-cell-padding
        );

        > tbody > tr > th {
            @include lib-css(background-color, $_table-th-background-color-responsive '!important');
        }
    }
}

@mixin lib-table-caption(
    $_table-caption-hide: $table-caption__hide,
    $_table-caption-font-size: $table-caption__font-size,
    $_table-caption-color: $table-caption__color,
    $_table-caption-font-family: $table-caption__font-family,
    $_table-caption-font-weight: $table-caption__font-weight,
    $_table-caption-font-style: $table-caption__font-style,
    $_table-caption-line-height: $table-caption__line-height,
    $_table-caption-alignment: $table-caption__alignment,
    $_table-caption-margin-top: $table-caption__margin-top,
    $_table-caption-margin-bottom: $table-caption__margin-bottom
) {
    @if $_table-caption-hide == false {
        > caption {
            @include lib-typography(
                $_table-caption-font-size,
                $_table-caption-color,
                $_table-caption-font-family,
                $_table-caption-font-weight,
                $_table-caption-line-height,
                $_table-caption-font-style
            );
            @include lib-css(margin-bottom, $_table-caption-margin-bottom);
            @include lib-css(margin-top, $_table-caption-margin-top);
            @include lib-css(text-align, $_table-caption-alignment);
        }
    }

    @if $_table-caption-hide == true {
        > caption {
            @include lib-visually-hidden();
        }
    }
}

@mixin _lib-responsive-table(
    $_table-background-color-responsive: $_table-background-color-responsive,
    $_table-responsive-cell-padding: $_table-responsive-cell-padding
) {
    @include lib-css(background, $_table-background-color-responsive);
    border: none;
    display: block;

    > thead > tr > th {
        display: none;
    }

    > tbody {
        display: block;

        > tr {
            td,
            th {
                border-bottom: none;
                @include lib-css(padding, $_table-responsive-cell-padding);
            }

            td {
                @include lib-css(background, $_table-background-color-responsive);
            }
        }
    }
}

//
// Tables
// --------------------------------------------------

table {
    // Table cell sizing
    //
    // Reset default table behavior

    col[class*='col-'] {
        position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
        display: table-column;
        float: none;
    }

    td,
    th {
        &[class*='col-'] {
            position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
            display: table-cell;
            float: none;
        }
    }
}

// Baseline styles
.table-dc {
    &.table {
        width: 100%;
        max-width: 100%;
        // Cells
        > tbody,
        > thead,
        > tfoot {
            > tr {
                line-height: 3;
                font-size: 1.4rem;

                > th,
                > td {
                    border-top: 0.1rem solid $color-gray64;
                    vertical-align: middle;
                }

                &.selected {
                    @at-root *:not(.table-without-checkboxes)#{&} {
                        background: rgba(0, 137, 208, 0.25) !important;
                    }

                    > td {
                        background-color: transparent !important;
                    }
                }
            }

            .hidden {
                display: none;
            }

            td.status {
                white-space: nowrap;
            }

            td.actions {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;

                form {
                    margin-left: 0.3rem;
                }
            }
        }

        // Bottom align for column headings
        > thead > tr > th {
            vertical-align: bottom;
            border-bottom: 0.2rem solid $color-gray64;
        }

        // Remove top border from thead by default
        > caption + thead,
        > colgroup + thead,
        > thead:first-child {
            > tr:first-child {
                > th {
                    border: none;
                }
            }
        }

        // Account for multiple tbody instances
        > tbody + tbody {
            border-top: 0.2rem solid $color-gray64;
        }

        .action.primary {
            margin: 0.2rem 0.4rem;
            white-space: nowrap;
            height: auto;
            padding: $indent__xs $indent__s $indent__xs;
            line-height: 1.5;
        }
    }

    // Zebra-striping
    //
    // Default zebra-stripe styles (alternating gray and transparent backgrounds)

    &.table-striped {
        > tbody > tr:nth-of-type(odd) {
            background-color: $color-gray96;
        }
    }

    // Small effect

    &.table-small {
        > thead,
        > tbody,
        > tfoot {
            > tr {
                line-height: 1.5;
                padding: 0.4rem 1.2rem;
            }
        }
    }

    // Responsive tables
    //
    // Wrap your tables in `.table-responsive` and we'll make them mobile friendly
    // by enabling horizontal scrolling. Only applies <768px. Everything above that
    // will display normally.

    &.table-responsive {
        min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
        overflow-x: auto;

        @media screen and (max-width: $screen__m) {
            width: 100%;
            overflow-y: hidden;
            -ms-overflow-style: -ms-autohiding-scrollbar;

            // Tighten up spacing
            > .table {
                margin-bottom: 0;

                // Ensure the content doesn't wrap
                > thead,
                > tbody,
                > tfoot {
                    > tr {
                        > th,
                        > td {
                            white-space: nowrap;
                        }
                    }
                }
            }

            // Special overrides for the bordered tables
            > .table-bordered {
                border: 0;

                // Nuke the appropriate borders so that the parent can handle them
                > thead,
                > tbody,
                > tfoot {
                    > tr {
                        > th:first-child,
                        > td:first-child {
                            border-left: 0;
                        }

                        > th:last-child,
                        > td:last-child {
                            border-right: 0;
                        }
                    }
                }

                // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
                // chances are there will be only one `tr` in a `thead` and that would
                // remove the border altogether.
                > tbody,
                > tfoot {
                    > tr:last-child {
                        > th,
                        > td {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen__l) {
    // Baseline styles
    .table-dc {
        &.table {
            // Cells
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th,
                    > td {
                        &.mobile-hidden {
                            display: none;
                        }

                        a.action.primary {
                            &.mobile-hidden {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
