.quotation-index-index {
    .page-wrapper {
        .page-main {
            .columns {
                .main {
                    .#{$css-namespace}quotation_content-container {
                        background-color: $color-white;
                        margin-top: 2rem;
                        padding-top: 2rem;
                        padding-bottom: 1rem;

                        @include min-screen($screen__m) {
                            margin-top: 3rem;
                            padding-bottom: 3rem;
                            padding-top: 0;
                        }
                    }

                    .empty-quotes-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $color-grey-background;
                        border-radius: 0.3rem;
                        margin-right: 0.5rem;
                        margin-left: 0.5rem;
                        padding: 3rem 5rem 3rem 5rem;

                        @include min-screen($screen__l) {
                            margin-left: 3rem;
                            margin-right: 3rem;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        .empty-text-container {
                            @include max-screen($screen__m) {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .message-empty {
                                font-weight: $font-weight-bold;
                            }

                            .dcfonts-icons {
                                font-size: 2.2rem;
                                color: $color-secondary;
                                margin-right: 1rem;
                            }
                        }

                        .#{$css-namespace}collaborators_content {
                            display: none;
                        }

                        .#{$css-namespace}collaborators_table {
                            width: 100%;
                        }

                        .#{$css-namespace}quote-list-thead {
                            @include min-screen($screen__l) {
                                display: none;
                            }
                        }
                    }

                    .#{$css-namespace}quotation {
                        .#{$css-namespace}block_title .#{$css-namespace}quotation--title {
                            margin-top: 0;
                            margin-bottom: 2rem !important;
                        }

                        .#{$css-namespace}p {
                            margin-bottom: 2.7rem;
                            line-height: 1.9rem;
                        }
                    }

                    .#{$css-namespace}quotation_label {
                        width: 28%;
                        color: $color-grey-dove;
                        font-size: $font-size-s;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        line-height: 1.7rem;
                        margin-right: 7rem;
                        font-weight: $font-weight-regular;

                        @include min-screen($screen__l) {
                            display: none;
                        }
                    }

                    .quotation-row {
                        cursor: pointer;

                        &:hover {
                            @include min-screen($screen__l) {
                                box-shadow: var(--box-shadow);
                            }

                            td {
                                &:first-child {
                                    border-color: $color-secondary;
                                }
                            }

                            .quotation-number {
                                text-decoration: none;
                            }
                        }

                        td {
                            &:first-child {
                                @include min-screen($screen__l) {
                                    border-left: 0.3rem solid transparent;
                                }
                            }
                        }
                    }

                    .quotation-number {
                        color: $color-secondary;
                        text-decoration: underline;
                    }

                    .quotation-reference {
                        font-weight: $font-weight-bold;

                        @include min-screen($screen__l) {
                            width: 17.5rem;
                        }
                    }

                    .quotation-amount {
                        text-align: inherit;

                        > span {
                            font-weight: $font-weight-bold;
                        }
                    }

                    span.quotation-reference,
                    span.quotation-price {
                        margin-left: 0.2rem;
                    }

                    .quote-status-container {
                        width: 15.6rem;
                        height: 3.2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 1.6rem;
                        font-size: $font-size-s;
                        text-transform: uppercase;
                        text-align: center;

                        &--to-validate {
                            background-color: $color-warning;
                            color: $color-white;
                            font-weight: $font-weight-medium;
                        }

                        &--ordered {
                            border: 0.2rem solid $color-success-secondary;
                        }

                        &--refused {
                            border: 0.2rem solid $color-error;
                        }

                        &--in-process {
                            border: 0.2rem solid $color-secondary;
                        }

                        &--partialy {
                            border: 0.2rem solid transparentize($color-success-secondary, 0.5);
                        }

                        &--expired {
                            border: 0.2rem solid $color-grey-argent;
                        }

                        .quotation-price {
                            margin-left: 0.2rem;
                        }

                        .quote-status-text {
                            font-size: $font-size-s;
                            text-transform: uppercase;
                        }
                    }

                    #quotations-list-table_filter {
                        display: none;
                    }

                    table.dataTable {
                        thead > tr {
                            > th {
                                position: relative;

                                &.number {
                                    &::before,
                                    &::after {
                                        display: none !important;
                                    }
                                }
                            }

                            th.quotation-amount {
                                &::before {
                                    right: 3.5rem;
                                }

                                &::after {
                                    right: 3.5rem;
                                }
                            }
                        }

                        .dataTables_empty {
                            padding: 1rem 0 0;
                            font-size: 0;

                            @include min-screen($screen__m) {
                                padding: 2rem 0 0;
                            }

                            &_content {
                                font-size: $font-size-default;
                                background: $color-grey-background;
                                border-radius: 0.3rem;
                                font-weight: $font-weight-bold;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-height: 7.4rem;
                                margin: 0 0.5rem;
                                flex: 1;
                                padding: 0 5rem;

                                @include min-screen($screen__m) {
                                    padding: 0 var(--datatable-th-spacing);
                                    margin: 0 var(--datatable-th-spacing);
                                }

                                .dcfonts-icons {
                                    margin-right: 1.1rem;
                                    font-size: 2.256rem;
                                    color: $color-secondary;
                                }
                            }
                        }
                    }

                    .#{$css-namespace}collaborators_content {
                        padding: 0;
                    }
                }
            }
        }
    }
}
