// Todo refacto with app/design/frontend/Descours/Descours/styles/blocks/_pages.scss to use only one pagination

.dataTables_paginate {
    overflow: hidden;
    margin: 0 0 $indent__base;
    padding-top: $indent__base;

    @include min-screen($screen__l) {
        margin: 5rem auto 0;
        padding: 0;
    }
}
