#co-shipping-method-form {
    .#{$css-namespace}item-table_row {
        @include max-screen($screen-sm) {
            display: table-row;
        }
    }
}

.checkout__shipping {
    .#{$css-namespace}tooltip--top {
        :nth-child(2) {
            @include max-screen($screen__l) {
                width: 19.6rem;
            }
        }
    }

    &_phone-tooltip {
        &::after {
            top: 5.5rem;

            @include min-screen($screen__l) {
                top: 3.7rem;
            }
        }
    }

    .#{$css-namespace}form-field-wrapper {
        .input-text {
            &,
            ~ * {
                vertical-align: middle;
            }
        }

        .#{$css-namespace}tooltip {
            font-size: $font-size-m;
            margin-left: 0.7rem;
        }
    }

    .cart-wrapper {
        padding: $indent__base 1.5rem;

        @include min-screen($screen__l) {
            padding: $indent__base $indent__l $indent__base;
        }
    }

    &-methods {
        margin-top: 0;
    }

    &-infos {
        display: flex;
        align-items: center;
    }

    &-name {
        font-size: $font-size-xl;
        line-height: 1.25em;
        font-weight: $font-weight__semibold;
        color: $color-main-dark;
        margin-top: $space-1 / 2;
        margin-bottom: 0.5rem;

        @include max-screen($screen__l) {
            margin: 0;
            padding-right: 2rem;
            line-height: 1.2em;
        }
    }

    &-details {
        display: none;
        width: 100%;

        &[data-method='storepickup'] {
            margin-top: 2rem;
            padding-bottom: 2rem;

            .#{$css-namespace}agency-card-schedules {
                @include max-screen($screen__l) {
                    min-width: 18rem;
                }
            }
        }

        &_description {
            max-width: 45rem;
            line-height: 2.2rem;
        }
    }

    &-more-infos {
        .title {
            display: flex;
            align-items: center;
            color: $color-primary;

            .dcfonts-icons {
                font-size: 1.5rem;
                margin-right: 0.5rem;
            }
        }

        .mandatory {
            font-size: $font-size-s;
            font-style: italic;
            font-weight: $font-weight-regular;
        }

        .description {
            &--highlighted {
                color: $color-primary;
                font-style: italic;
                @include max-screen($screen__l) {
                    padding-right: 5rem;
                }
            }
        }

        .form-elements {
            margin-top: 2.7rem;

            &--small {
                .input-text {
                    width: 15rem;
                }
            }

            &--medium {
                .input-text {
                    width: 28rem;
                }
            }

            .input-text {
                max-width: 100%;
            }

            .#{$css-namespace}input-text {
                &.mage-error {
                    border-color: $color-red10;
                }
            }

            &.fieldset {
                .control {
                    .#{$css-namespace}input-text {
                        &[value='']:not(:focus) {
                            ~ label.with-error {
                                top: 1.3rem;
                                transform: none;
                            }
                        }
                    }

                    .label.active {
                        background: $color-white;
                        color: $color-primary;
                        font-size: $font-size-s;
                        font-weight: $font-weight-regular;
                        top: -0.7rem;
                        left: $space-1;
                        padding: 0 0.7rem;
                        transform: none;
                    }
                }
            }

            .#{$css-namespace}textarea_text {
                min-height: 7.8rem;
                height: 8.7rem;
                border-radius: $form-element-input__border-radius;

                @include min-screen($screen__l) {
                    height: 8.4rem;
                }
            }
        }
    }

    &-additional {
        margin-top: $indent__base;

        .#{$css-namespace}title {
            color: $color-main-dark;

            @include min-screen($screen__l) {
                line-height: 1.75em;
            }
        }

        .checkout {
            &__sub_title-container {
                .dcfonts-icons {
                    color: $color-accent;
                }
            }
        }

        p {
            margin-top: 1.5rem;
            margin-bottom: 2.7rem;

            @include min-screen($screen__l) {
                padding-right: 25rem;
            }
        }

        .cart-wrapper-form {
            margin-bottom: $indent__l;

            input {
                max-width: 15rem;
            }

            &.fieldset .control {
                .label.active {
                    background: $color-white;
                    color: $color-primary;
                    font-size: $font-size-s;
                    font-weight: $font-weight-regular;
                    top: -0.7rem;
                    left: $space-1;
                    padding: 0 0.7rem;
                    transform: none;
                }
            }
        }

        .#{$css-namespace}wishlist-agency {
            &-card {
                .#{$css-namespace}title {
                    color: $color-accent;
                }
            }
        }
    }

    .#{$css-namespace}agency-card-schedules {
        @include min-screen($screen__l) {
            min-width: 31.9rem;
        }
    }

    .#{$css-namespace}wishlist-agency {
        &-content {
            margin: 0;
        }

        &-card {
            .#{$css-namespace}title {
                font-size: $font-size-m;
                margin-bottom: 1.2rem;
                line-height: 1.7rem;

                @include max-screen($screen__xxs) {
                    max-width: 25rem;
                }
            }

            dd {
                margin-top: 0.5rem;

                @include max-screen($screen__l) {
                    margin-top: 1.5rem;
                }
            }

            .day {
                @include max-screen($screen__m) {
                    line-height: 2.8rem;
                }
            }
        }
    }
}
