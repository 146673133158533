.#{$css-namespace}form-radio-button-group {
    display: flex;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    label {
        position: relative;
        cursor: pointer;
        display: flex;
        background-color: $color-gray97;
        border-top: 0.1rem solid $color-disabled-light;
        border-bottom: 0.1rem solid $color-disabled-light;
        border-left: 0.1rem solid $color-disabled-light;
        height: 4rem;
        align-items: center;
        justify-content: center;
        padding-left: 1.7rem;
        padding-right: 1.7rem;
        min-width: 12.8rem;
        color: $color-black;
        text-align: center;
    }

    label + label {
        border-right: 0.1rem solid $color-disabled-light;
    }

    .#{$css-namespace}form-field-wrapper:first-child {
        label {
            border-radius: 2rem 0 0 2rem;
            padding-left: 2.1rem;
        }
    }

    .#{$css-namespace}form-field-wrapper:last-child {
        label {
            border-radius: 0 2rem 2rem 0;
            padding-right: 2.1rem;
            border-right: 0.1rem solid $color-disabled-light;
        }
    }

    .#{$css-namespace}form-field-wrapper {
        label {
            display: flex;

            &.custom-radio--primary {
                padding: 0 1.5rem;
            }
        }
    }
}

.#{$css-namespace}form-radio-button-group .#{$css-namespace}form-field-wrapper input:checked,
.#{$css-namespace}form-radio-button-group .#{$css-namespace}form-field-wrapper input.active {
    ~ label {
        background-color: $color-secondary;
        border-color: $color-secondary;
        color: $color-white;
        font-weight: $font-weight-bold;

        &.custom-radio--primary {
            background-color: $color-primary;
            border-color: $color-primary;
            padding: 0 1.5rem;
        }
    }
}

.#{$css-namespace}form-radio-button-group .#{$css-namespace}form-field-wrapper input:checked:disabled ~ label {
    color: $color-black;
    background-color: $color-disabled;
    border-color: $color-disabled-light;
}

.#{$css-namespace}form-radio-button-group
    .#{$css-namespace}form-field-wrapper
    input:checked
    ~ .#{$css-namespace}form-radio-button_label--accent {
    background-color: $color-accent;
    border-color: $color-accent;
}

.#{$css-namespace}form-radio-button-group .#{$css-namespace}form-field-wrapper input:disabled ~ label,
.#{$css-namespace}form-radio-button-group
    .#{$css-namespace}form-field-wrapper
    input:disabled
    ~ .#{$css-namespace}form-radio-button_label--accent {
    color: $color-black;
    background-color: $color-grey-background;
    border-color: $color-disabled-light;
    cursor: default;
}
