.#{$css-namespace}quick-order_mass-import {
    display: none;

    @include max-screen($screen__m) {
        display: none !important;
    }
}

.#{$css-namespace}quick-order_mass-import_steps-list {
    counter-reset: i;
    padding: 0;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    list-style: none;

    .#{$css-namespace}quick-order-mass-import_step-content {
        margin-bottom: 3rem;

        &--model {
            margin-top: 3rem;
        }
    }

    .#{$css-namespace}quick-order_mass-import_steps-list_title {
        font-weight: $font-weight-bold;
        margin-bottom: 2rem;
        font-size: $font-size-default;
        color: $color-primary;
        display: flex;
        align-items: center;
        line-height: 1.9rem;

        &::before {
            content: counter(i);
            counter-increment: i;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
            background: $color-white;
            color: $disabledTextColor;
            font-weight: $font-weight-bold;
            border-radius: 50%;
            border: solid 0.1rem $disabledTextColor;
            font-size: 1.234rem;
            line-height: 2;
            flex: 1 0 auto;
            max-width: 2.4rem;

            @include max-screen($screen__m) {
                font-size: $font-size-s;
            }
        }
    }
}

.#{$css-namespace}quick-order_mass-import_links-container {
    display: flex;
    margin-top: 2.5rem;
    flex-direction: column;
    gap: 2rem;

    @include min-screen($screen__m) {
        gap: 3rem;
        flex-direction: row;
    }

    .#{$css-namespace}quick-order_mass-import_links-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        border: 0.1rem solid $color-disabled-light;
        border-radius: 0.3rem;
        align-items: center;
        padding-top: 1.3rem;
        padding-bottom: 1.4rem;
        cursor: pointer;

        &:hover {
            background: $color-disabled-light;
            color: inherit;
        }

        @include max-screen($screen__m) {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
        }

        .mass-import-file-type {
            font-weight: $font-weight-bold;
            font-size: $font-size-default;
            line-height: 1.9rem;
        }

        .#{$css-namespace}p {
            font-weight: $font-weight-regular;
            font-size: $font-size-default;
            line-height: 1.9rem;
            margin-top: 1rem;
            @include max-screen($screen__m) {
                margin: 0;
                margin-right: 0.5rem;
            }
        }

        .file-template {
            text-decoration: none;

            @include max-screen($screen__m) {
                margin-left: 2rem;
                margin-right: 4rem;
            }
        }

        .dcfonts-icons--download {
            font-size: 2.4rem;
        }
    }
}

.#{$css-namespace}quick-order_mass-import_upload-wrapper {
    border: 0.1rem dashed $color-gray64;
    border-radius: 0.3rem;
    position: relative;
    margin-top: 2rem;
    cursor: pointer;

    .control {
        position: relative;
    }

    > label {
        position: relative;

        &.is-dragenter {
            .#{$css-namespace}quick-order_mass-import_file-field {
                background-color: $color-drag-hover;
            }
        }
    }

    input[type='file'] {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 100%;

        @include max-screen($screen__m) {
            width: 100%;
        }
    }
}

.#{$css-namespace}quick-order_mass-import_file-field {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        margin: $indent__s;
        font-size: $font-size-default;
        color: $color-sub-secondary;
        text-align: center;
    }

    .dcfonts-icons--upload {
        margin: 2rem auto 0;
        color: $color-primary;
        font-size: 2.4rem;
    }

    .#{$css-namespace}icon,
    .dcfonts-icons {
        width: 3rem;
        height: 3rem;
        fill: $color-sub-secondary;
    }

    .quick-order_mass-import_upload-list {
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
        list-style: none;
        padding-left: 0;

        @include max-screen($screen__m) {
            display: none;
        }

        &--label {
            display: flex;
            align-items: center;

            &::before {
                background-color: $color-secondary;
                content: '';
                margin-right: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0.1rem;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
            }
        }

        &--sub {
            font-weight: $font-weight-light;
            list-style: none;
            padding-left: 1rem;

            li {
                margin-bottom: 0;
            }
        }
    }

    &.is-active {
        display: flex;
    }
}

.#{$css-namespace}quick-order_mass-import_file-field--file-imported {
    display: none;
    padding-top: 9rem;
    padding-bottom: 3rem;

    @include min-screen($screen__m) {
        padding-bottom: 4.5rem;
    }

    img {
        width: 2.4rem;
    }
}

.#{$css-namespace}quick-order_mass-import_file-field--error {
    display: none;
}

.#{$css-namespace}quick-order_mass-import_file-field_img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    @include min-screen($screen__m) {
        margin-bottom: 4rem;
    }
}

.#{$css-namespace}quick-order_mass-import_file-field_txt {
    color: $subAltPrimaryColor;
    font-size: $font-size-default;
    text-align: center;
    line-height: 1.4rem;
    margin-bottom: 3rem;
    font-weight: $font-weight-regular;
}

.#{$css-namespace}quick-order_mass-import_file-field_filename {
    margin-left: $space-1;
    margin-bottom: 0;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
    color: $color-grey-dark2;
}

.#{$css-namespace}quick-order_mass-import_file-field_error-txt {
    display: none;
    font-size: $font-size-default;
    line-height: 1.4rem;
    color: $color-red;
    margin-bottom: 5rem;
    font-weight: $font-weight-regular;
}

.#{$css-namespace}quick-order_mass-import_file-field_link {
    font-size: $font-size-default;
    font-weight: $font-weight-medium;
    text-align: center;
    color: $color-secondary;
    cursor: pointer;
    text-decoration: underline;
    line-height: 1.4rem;
    margin: 1rem 0 3rem;
}

.#{$css-namespace}quick-order_import_mass-article-list_wrapper {
    @include max-screen($screen__m) {
        display: none;
    }
}
