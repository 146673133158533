.quick-access .mini-cart {
    &__wrapper,
    &__link {
        display: flex;
        align-items: center;
    }

    &__link {
        height: 100%;
        gap: $space-1;
        font-size: $font-size-s;
        position: relative;
        transition: color var(--transition-normal);

        &:hover,
        &:focus-within {
            color: $color-primary;
        }

        .dcfonts-icons {
            font-size: $font-size-xl;

            &.isCartFull {
                position: relative;
                margin-right: $space-1;

                &::after {
                    content: attr(data-title);
                    position: absolute;
                    background-color: $color-secondary;
                    color: $color-white;
                    border-radius: 100%;
                    top: -0.7rem;
                    left: 1.5rem;
                    padding: 0.1rem #{$space-1 / 2};
                    justify-content: center;
                    width: fit-content;
                    min-width: 1.6rem;
                    height: 1.6rem;
                    line-height: 1.6rem;
                    text-align: center;
                    font-size: $font-size-xs;
                    font-weight: $font-weight-bold;
                    font-family: $font-default;
                }

                @include min-screen($screen__l) {
                    margin-right: 0;

                    &[data-title='99+'] {
                        margin-right: $space-1 / 2;
                    }
                }
            }
        }
    }

    &__info {
        line-height: 1.4;
        display: none;

        &.isCartFull,
        &.isEmpty {
            color: $color-primary;
            font-weight: $font-weight-bold;
            margin-left: $space-1;

            @include min-screen($screen__l) {
                display: flex;
            }
        }

        &.isEmpty {
            width: 7rem;
        }

        &.isCartFull {
            .minicart-price__wrapper {
                white-space: nowrap;
            }
        }
    }
}
