.#{$css-namespace}select {
    position: relative;
    border-radius: 0.3rem;

    &:not(:empty):after {
        font-family: $font-icons;
        content: '\f10b';
        position: absolute;
        top: 50%;
        right: 1rem;
        font-size: 1.6rem;
        transform: translateY(-50%);
        color: $color-primary;
        pointer-events: none;
    }

    &:focus-within:after {
        font-family: $font-icons;
        content: '\f10b';
        position: absolute;
        top: 50%;
        right: 1rem;
        font-size: 1.6rem;
        transform: translateY(-50%);
        transform: rotate(180deg);
        transform-origin: 0.9rem 0.5rem;
        color: $color-primary;
        pointer-events: none;
    }

    select {
        border-radius: 0;
        border: 0.1rem solid $color-primary;
        width: 100%;
        padding: 1.2rem 1.5rem 0.9rem;
        font-size: $font-size-default;
        font-family: $font-default;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 0.1rem;
        text-overflow: '';
        height: auto;

        &[required]:invalid {
            border-color: $color-grey-dove;

            + label {
                font-size: $font-size-default;
                top: 50%;
                transform: translateY(-50%);
                color: $color-grey-dove;
                pointer-events: none;
            }
        }
    }

    label {
        position: absolute;
        font-size: $font-size-s;
        top: -1rem;
        left: 1rem;
        padding: 0 0.5rem;
        background-color: $color-white;
        transition: all 0.25s; // TODO : Uniformise using var(--transition-normal)
        color: $color-primary;
    }

    &--custom {
        &.--hasValue + &.nice-select {
            border-color: $color-primary;

            span {
                color: $color-main-dark;
                opacity: 1;
            }

            ~ label {
                background-color: $color-white;
                font-size: $font-size-s;
                top: -1rem;
                left: 1rem;
                padding: 0 0.5rem;
                color: $color-primary;
                width: auto;
            }
        }
    }

    &--custom.nice-select {
        border-radius: $form-element-input__border-radius;
        width: 100%;
        background-color: transparent;
        border-color: $color-grey-dove;
        float: none;

        &.open {
            border-color: $color-form-border;

            span {
                opacity: 1;
            }

            ~ label {
                background-color: $color-white;
                font-size: $font-size-s;
                top: -1rem;
                left: 1rem;
                padding: 0 0.5rem;
                color: $color-primary;
            }

            + i {
                transform: rotate(135deg) !important;
                transform-origin: 33% 66% !important;
            }
        }

        ~ label {
            position: absolute;
            padding: 0 1.5rem;
            font-size: $font-size-default;
            left: 0;
            top: 1rem;
            pointer-events: none;
            color: $color-grey-dove;

            @include max-screen($screen__l) {
                left: 0.1rem;
                background-color: $color-white;
            }
        }

        span {
            background-color: transparent;
            color: $color-disabled;
            width: calc(var(--popup-content-width, 100%) - 8.2rem);
            padding: 0 1.5rem;
            opacity: 0;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        + i {
            top: 1.25rem;
            right: 1rem;
            margin-left: 0 !important;
            margin-top: 0 !important;
            transition: none !important;
            position: absolute;
            width: 1rem;
            height: 1rem;
            display: block;
            border-left: 0.2rem solid $color-form-arrow;
            border-bottom: 0.2rem solid $color-form-arrow;
            transform: rotate(-45deg);
            transform-origin: 66% 66%;
        }

        &.disabled {
            &,
            + i,
            ~ label {
                opacity: 0.3;
            }
        }

        .list {
            display: none;
            padding: 0;
            line-height: 1;
            margin: 0;
            border: 0.1rem solid $color-disabled-light;
            width: calc(100% + 0.2rem);
            top: calc(100% + 0.1rem);
            left: -0.1rem;
            box-shadow: 0 0.4rem 1rem 0 rgba($color-black, 0.12);
            overflow: auto;
            max-height: 30rem;
            @extend %scrollbars !optional;

            .option {
                color: $color-main-dark;
                padding: 0.6rem 1.5rem;
                line-height: 1;
                min-height: inherit;
                font-size: $font-size-default;
                margin-bottom: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;

                &[data-value=''] {
                    display: none;

                    + .option {
                        margin-top: 1rem;
                    }
                }

                &:hover {
                    background-color: $color-secondary !important;
                    color: $color-white;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    select.#{$css-namespace}select--custom {
        border-radius: $form-element-input__border-radius;
        border: 0.1rem solid $color-form-border;
        width: 100%;
        padding: 1.2rem 1.5rem 0.9rem;
        font-size: $font-size-default;
        font-family: $font-default;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 0.1rem;
        text-overflow: '';
        height: auto;

        &[required]:invalid {
            border-color: $color-grey-dove;

            ~ label {
                font-size: $font-size-default;
                color: $color-grey-dove;
                pointer-events: none;
                width: auto;
            }
        }
    }
}

// Nice select overrides
.nice-select.mage-error + div.mage-error { // .nice-select is floating left, making ::before alignment issues
    clear: both;
    margin-top: 0;
    margin-bottom: $space-1;
    top: $space-1;
}

// Combotree overrides
.field:has(.comboTreeWrapper) .input-text { // prevent .mage-error:not([generated="true"]) ~ .comboTreeWrapper from .css file
    ~ .comboTreeWrapper .comboTreeArrowBtn {
        &,
        &--close {
            color: $color-primary;
        }
    }

    &.mage-error {
        ~ .comboTreeWrapper .comboTreeArrowBtn {
            &,
            &--close {
                color: $color-red;
            }
        }
    }
}
