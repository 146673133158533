.reinsurance-container {
    position: relative;
    padding: $indent__xl 0 $indent__l;
    background-color: $color-secondary;
    color: $color-white;

    > div {
        max-width: 132.5rem;
        margin: 0 auto;
        padding: 0 $offset-l;

        @include min-screen($screen-md) {
            grid-template-columns: repeat(12, 1fr);
        }

        .slick-slide {
            > div {
                > div {
                    margin: 0;

                    > a {
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        text-align: center;
                        color: $color-white;

                        strong {
                            display: block;
                            font-weight: $font-weight-regular;
                            font-size: $font-size-default;
                            line-height: 1.357;
                        }

                        b {
                            display: block;
                            margin-bottom: $indent__xs;
                            font-family: $font-secondary;
                            font-weight: $font-weight__bold;
                            font-style: normal;
                            @include lib-font-size(2.2rem);
                            line-height: 2.7rem;
                            text-transform: uppercase;
                        }

                        .icon {
                            display: block;
                            width: 6rem;
                            height: 6rem;
                            font-size: 6rem;
                            margin: 0 auto $indent__base;
                            color: $color-white;
                            fill: $color-white;

                            &.icon-pick-up-half-day {
                                width: 11rem;
                            }
                        }
                    }
                }
            }
        }
    }

    [data-js-reinsurrance-slider] {
        opacity: 0; // Because designers dislike when there is a glint on init slider...
        visibility: hidden;
        transition: all var(--transition-normal);

        &.slick-initialized {
            opacity: 1;
            visibility: visible;
        }
    }
}
