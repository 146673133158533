$last-input-space: $space-3 * 2 + $space-2 !default;
$last-input-space-mobile: $space-2 + $space-3 + $space-1 !default;

.search {
    max-width: 100rem;
    width: 100%;
    position: relative;

    @include max-screen($screen__m) {
        order: 5;

        .--scrolled & {
            order: 0;
        }
    }

    @include min-screen($screen__m) {
        gap: $space-3;
    }

    &__overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh; // vh is a fix for iOS
        background-color: rgba($color-black, 0.55);
        z-index: 5;

        @include min-screen($screen__m) {
            .--scrolled & {
                order: 0;
            }
        }
    }

    &__form {
        display: flex;
        align-items: center;

        @include max-screen($screen__m) {
            gap: $space-1;
        }

        .js-search & {
            position: relative;
            z-index: 6;
        }
    }

    &__input {
        padding: $space-1 $space-3;

        &-container {
            width: 100%;

            input {
                padding: $space-2 $last-input-space-mobile $space-2 $space-2;
                border-radius: $space-3;
                background: $color-grey-background;
                color: $color-main-dark;
                line-height: 1;
                font-size: $font-size-m;
                border: 0.1rem solid $color-border-grey-light;
                height: auto;

                @include min-screen($screen__m) {
                    padding: $space-2 $last-input-space $space-2 $space-3;
                }

                &::placeholder {
                    color: $color-main-dark;
                    opacity: 1;
                }
            }
        }
    }

    &__button {
        &-wrapper {
            display: none;
            align-items: center;
            justify-content: center;
            order: -1;
            height: 2.4rem;
            width: 2.4rem;

            @include max-screen($screen__m) {
                .js-search.--scrolled & {
                    display: flex;
                }
            }

            button {
                border: 0;
                padding: 0;
                background: none;
                color: $color-primary;
                font-size: $font-size-xl;
            }
        }

        &-search {
            position: absolute;
            right: $space-2;
            height: 2.4rem;
            width: 2.4rem;
            padding: 0;
            border: 0;
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.4rem;
            color: $color-primary;
            transition: color var(--transition-normal);

            &[disabled] {
                opacity: 1;
            }

            @include min-screen($screen__m) {
                right: $space-3;
            }

            &:hover,
            &:focus-within {
                border: 0;
                background: none;
                color: $color-secondary;
            }
        }
    }

    &__autocomplete {
        margin-top: $space-1;
    }
}
