.contact.custom-form {
    padding: 0 1rem;

    .contact-select-container {
        display: inline-block;
        cursor: pointer;
        position: relative;
        border-radius: 0.4rem;
        width: 100%;
        max-width: 40rem;

        &::after {
            display: inline-block;
            content: '▼';
            font-size: 0.7em;
            margin-left: 1.5rem;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $color-sub-secondary;
            color: $color-white;
            height: 100%;
            line-height: 5rem;
            padding: 0 2.3rem;
            pointer-events: none;
            cursor: pointer;
            border-radius: 0 0.4rem 0.4rem 0;
            max-height: 5.1rem;
        }

        select {
            cursor: pointer;
        }
    }

    .actions-toolbar {
        margin-left: 0 !important;
    }
}
