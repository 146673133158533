.checkout-success {
    text-align: center;
    $box-shadow: 0.2rem 0.4rem 0.6rem rgba($color-black, 0.12); // TODO: Use box-shadow var

    &_banner-img > img {
        width: 100%;
        margin-bottom: 2rem;

        @include min-screen($screen__l) {
            margin-bottom: $space-4;
        }
    }

    &_main-content {
        display: flex;
        flex-direction: column;
        margin: 0 0.5rem;

        @include min-screen($screen__l) {
            margin: 0 auto;
            max-width: 79.3rem;
        }
    }

    &_title-container {
        text-align: center;
    }

    &_main-title.#{$css-namespace}title {
        margin-bottom: $space-4;
        line-height: $space-4;

        @include min-screen($screen__l) {
            line-height: 1.125;
            font-size: $space-5;
        }
    }

    &_main-desc {
        color: $color-primary;
        font-size: $font-size-xl;
        font-weight: $font-weight-semi-bold;
        line-height: $space-3;
    }

    &_separator {
        margin: $space-4 -1.5rem;
        border-color: $color-grey-argent;

        @include min-screen($screen__l) {
            margin: $space-4 -3rem;
        }
    }

    &_title-container,
    &_recap-content {
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            margin: 0 auto;
            width: 48rem;
        }
    }

    &_info-value {
        font-weight: $font-weight-black;
    }

    &_order-number-container {
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            flex-direction: row;
            align-items: center;
        }
    }

    &_order-number {
        @include min-screen($screen__l) {
            margin-left: 0.5rem;
        }
    }

    .checkout-success-recap {
        display: flex;
        flex-direction: column;
        padding: $space-4 $space-2;
        margin-bottom: $space-4;
        text-align: start;
        background-color: $color-white;
        box-shadow: $box-shadow;

        @include min-screen($screen__l) {
            padding: $space-4;
        }

        .recap-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 1rem;

            @include min-screen($screen__l) {
                flex-direction: row;
                align-items: center;
            }

            .#{$css-namespace}title {
                font-size: $font-size-l;
                line-height: 2.22;
            }
        }

        .checkout-success-recap-customer_info {
            padding-right: 0;
            margin-bottom: $space-4;

            .#{$css-namespace}p {
                font-size: $font-size-default;
                margin: 0;
                line-height: $space-3;

                &--total {
                    font-weight: $font-weight-bold;

                    &-full {
                        font-size: $font-size-m;
                    }
                }

                &--discount {
                    .#{$css-namespace}title {
                        color: $color-red-promo;
                    }
                }
            }

            .#{$css-namespace}title {
                font-size: $font-size-default;
                color: $color-main-dark;
            }

            .color {
                &-accent {
                    color: $color-checkout-delivery-pickup;
                }

                &-primary {
                    color: $color-primary;
                }
            }

            .#{$css-namespace}mention {
                font-size: $font-size-xs;
                line-height: 1.2;
                color: inherit;
            }
        }

        .recap-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 0;

            @include min-screen($screen__l) {
                flex-direction: row;
            }

            .#{$css-namespace}order-address {
                border: none;
                font-size: $font-size-default;
                max-width: 30rem;
                flex: 1;
                min-width: 21.1rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            .#{$css-namespace}title-section {
                font-size: $font-size-l;
                line-height: 2.22;
            }

            .#{$css-namespace}order-address_line {
                line-height: 1.714;
                margin: 0;

                &--headLine {
                    font-weight: $font-weight-bold;
                    line-height: 1.357;
                    margin: 0.5rem 0 1rem;
                }
            }

            .#{$css-namespace}order-address--onlineShop {
                .#{$css-namespace}title-section {
                    color: $color-checkout-delivery-online;
                }

                .#{$css-namespace}order-address_line--headLine {
                    color: $color-checkout-delivery-online;
                }
            }

            .#{$css-namespace}order-address--pickUp {
                .#{$css-namespace}title-section {
                    color: $color-checkout-delivery-pickup;
                    white-space: nowrap;
                }

                .#{$css-namespace}order-address_line--headLine {
                    color: $color-checkout-delivery-pickup;
                }
            }
        }
    }

    &_back-btn {
        justify-content: center;
        margin-top: $space-4;

        & + & {
            margin-top: 1rem;
        }
    }

    &_question-container,
    &_email-reminder {
        display: flex;
        flex-direction: column;
        padding: $space-4 4rem;
        background-color: $color-white;
        box-shadow: $box-shadow;

        @include min-screen($screen__l) {
            margin: 0;
        }
    }

    &_question-header,
    &_email-header {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        @include min-screen($screen__l) {
            justify-content: center;
        }
    }

    &_email-header {
        flex-direction: column;
        align-items: center;
        @include min-screen($screen__l) {
            margin: 0 auto;
            max-width: 35.8rem;
            text-align: left;
        }
    }

    &_question-icon {
        margin-right: 2rem;
        margin-left: -1rem;
        color: $color-primary;
        font-size: 3.2rem;

        @include min-screen($screen__l) {
            position: absolute;
            left: 15.5rem;
            top: 2rem;
        }
    }

    &_question-title.#{$css-namespace}title-section,
    &_email-title.#{$css-namespace}title-section {
        color: $color-primary;
        text-align: left;
        line-height: $space-3;

        @include min-screen($screen__l) {
            font-size: $font-size-xl;
            line-height: $space-4;
        }
    }

    &_question-content,
    &_email-content {
        margin-bottom: 0;
        line-height: 1.9rem;

        @include min-screen($screen__l) {
            margin: 0 auto;
            max-width: 35.8rem;
            text-align: left;
        }
    }

    .agency--phoneNumber {
        font-weight: $font-weight-bold;
        color: $color-primary;
        white-space: nowrap;
    }

    // Block Récapitulatif de Commande - page récap
    // -----------------------------------------
    .recap-order {
        border: 0.2rem solid $color-blue4;
        width: 100%;
        max-width: 87.5rem;
        @include lib-background-image('background-recap-order.png', top right, no-repeat, transparent, false);
        background-size: auto calc(100%);
        padding: $space-3;
        margin: 0 auto $space-5;
        @include lib-css-box-sizing(border-box);

        .container {
            width: 100%;
            float: left;
        }

        .display-table-cell {
            display: table-cell;
        }

        .clear {
            clear: both;
        }

        .uppercase {
            text-transform: uppercase;
        }

        .bold {
            font-weight: $font-weight-bold;
        }

        h3 {
            width: 100%;
            display: inline-block;
            float: left;
            margin: 0.5rem 0 $space-3;
            text-align: left;
            font-size: $space-3;
            font-weight: $font-weight-bold;
        }

        .recap-order-details,
        .recap-order-address {
            font-family: $font-family__sans-serif;
            float: left;
            text-align: left;
            display: table-cell;
            min-height: 9rem;
        }

        .recap-order-details {
            width: calc(50% - 2.1rem);
            padding-right: $space-3;
            border-right: 0.2rem solid $color-gray-darken1;

            > table {
                tr {
                    td {
                        padding: 0;

                        &.first {
                            width: 60%;
                        }

                        &.second {
                            width: 40%;
                        }
                    }
                }
            }
        }

        .recap-order-address {
            width: calc(50% - #{$space-3});
            padding: 0 $space-4;
            box-sizing: border-box;
            position: relative;

            &::before {
                position: absolute;
                top: calc(50% - 1.2rem);
                left: -0.9rem;

                display: block;

                width: 1.5rem;
                height: 1.5rem;

                content: '';
                transform: translateY(-50%) rotate(-45deg);

                border-right: 0.2rem solid $color-gray-darken1;
                border-bottom: 0.2rem solid $color-gray-darken1;
                background: $color-white;
            }

            > h4 {
                margin: 0;
                color: $color-blue3;
                font-size: $font-size-default;
            }

            > address {
                p {
                    margin: 0;
                }
            }
        }
    }

    // Block Une question - page récap
    // -----------------------------------------
    .question {
        text-align: left;
        max-width: $screen__m;
        width: 100%;
        margin: 0 auto;

        .container {
            width: 100%;
            float: left;

            > h3 {
                color: $color-blue3;
                font-size: $space-3;
                font-weight: $font-weight__bold;
                margin: 0;
            }

            p {
                margin: 0;
                font-family: $font-family__sans-serif;
            }

            .question-magasin {
                padding-right: $space-5;
                width: calc(50% - 4.1rem);
                float: left;
                border-right: 0.1rem dotted $color-gray52;
                margin-top: $space-3;

                h4 {
                    color: $color-blue4;
                    margin: 0 0 0 $space-2;
                    position: relative;

                    &::before {
                        display: inline-block;
                        content: '';
                        @include lib-background-image('svg/chevron-left.svg', 100% center, no-repeat, transparent);
                        background-size: cover;
                        width: $space-4;
                        height: $space-4;
                        position: absolute;
                        top: -1rem;
                        left: -3rem;
                    }
                }

                address {
                    margin-left: $space-2;
                }

                .box-phone {
                    margin-left: $space-2;
                    display: block;
                    margin-top: 0.2rem;
                    margin-bottom: $space-1;

                    > b {
                        line-height: 2.2rem;
                        font-size: $space-2;
                        color: $color-blue3;
                    }

                    .icon-phone {
                        width: $space-4;
                        height: $space-4;
                        display: inline-block;
                        position: relative;
                        top: $space-1 / 2;
                        margin-right: $space-1;
                    }
                }

                .desktop-phone {
                    display: block;
                }

                .mobile-phone {
                    display: none;
                }

                @include max-screen($screen__m) {
                    .desktop-phone {
                        display: none;
                    }

                    .mobile-phone {
                        display: block;
                    }
                }
            }

            .question-faq {
                padding-left: $space-5;
                width: calc(50% - #{$space-5});
                float: left;
                margin-top: $space-2;

                h4 {
                    color: $color-blue3;
                    margin: 0 0 0 $space-2;
                    position: relative;

                    &::before {
                        display: inline-block;
                        content: '';
                        @include lib-background-image('svg/chevron-left.svg', 100% center, no-repeat, transparent);
                        background-size: cover;
                        width: $space-4;
                        height: $space-4;
                        position: absolute;
                        top: -1rem;
                        left: -3rem;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .checkout-success {
        .recap-order {
            background: none;
            padding: $space-4;

            .recap-order-details,
            .recap-order-address {
                width: 100%;
                border: none;
                margin: 0;
                padding: 0 !important;
            }

            .recap-order-address {
                padding-top: $space-3 !important;

                &::before {
                    display: none;
                }
            }
        }

        .question {
            .container {
                .question-magasin,
                .question-faq {
                    width: 100%;
                    border: none;
                    padding: 0;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .checkout-success {
        .recap-order {
            .recap-order-address {
                padding: 0 $space-10 0 $space-4;
            }
        }
    }
}
