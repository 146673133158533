.cartouche {
    border-radius: 0.4rem;
    background-color: $clearColor;
    border: 0.1rem solid $color-sub-secondary;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 0;
    @include lib-css-box-shadow($params: false, $default: true);

    &:hover {
        @include lib-css-box-shadow-hover($params: false, $default: true);
    }

    &:first-child {
        margin-bottom: 3rem;
    }

    .cartouche- {
        &image {
            width: 14.9rem;
            display: inline-block;
            overflow: hidden;
            height: 100%;
        }

        &content {
            display: inline-block;
            width: calc(100% - 14.9rem);
            overflow: hidden;
            box-sizing: border-box;
            padding: $space-3;
            font-family: $subFont;

            &--title {
                @include lib-font-size(18);
                font-weight: $font-weight-bold;
                color: $color-sub-secondary;
                margin-top: 0;
                margin-bottom: 1.8rem;
            }

            p {
                @include lib-font-size(12);
                margin-bottom: 1.8rem;
                color: $mainFontColor;
                font-weight: $font-weight-regular;
            }

            .more {
                display: inline-block;

                span {
                    &::before {
                        top: 0;
                    }
                }

                .icon-fleche {
                    color: $color-sub-secondary;
                    @include lib-font-size(1.2rem);
                }
            }

            &--link {
                color: $mainFontColor;
                font-weight: $font-weight-bold;
                @include lib-vendor-prefix-display(block);
                height: 21rem;

                &:hover {
                    text-decoration: none;
                }

                display: flex;
            }
        }
    }

    &.catalog {
        .cartouche- {
            &image {
                @include lib-background-image('catalogue_prolians.png', center, no-repeat, transparent, false);
            }

            &content {
                @include lib-background-image('logo_coupe.svg', 101% 100%, no-repeat, transparent, false);
                background-size: 17rem;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .cartouche {
        &:last-child {
            margin-bottom: 1.4rem;

            p {
                &:first-child {
                    display: none;
                }
            }
        }
    }
}

@include screen($screen__xs, $screen__l) {
    .cartouche {
        &:last-child {
            margin-bottom: 3rem;
        }
    }
}

@include max-screen($screen__l) {
    .footer {
        &.content {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-wrap(wrap);
            @include lib-vendor-prefix-flex-direction(row);
        }

        &_links {
            @include lib-vendor-prefix-order(2);
            flex: 1 1 100%;
        }

        &_blocks {
            @include lib-vendor-prefix-order(1);
            padding: 0 1.2rem;
            flex: 1;
        }
    }
    .payment.secure {
        @include lib-vendor-prefix-order(3);
        @include lib-font-size(1.1rem);
        padding: 3rem 1.5rem 0;
        display: none;

        span {
            &::before {
                margin: 0;
            }
        }
    }

    .cartouche {
        .cartouche {
            &-content {
                padding: 1.5rem;
            }
        }
    }
}

@include min-screen($screen__l) {
    .cartouche {
        &:first-child {
            margin-top: 2.7rem;
        }

        &-image {
            vertical-align: top;
        }

        .cartouche {
            &-content {
                height: 100%;
            }
        }

        &content {
            &--title {
                white-space: nowrap;
            }
        }

        .more {
            width: 106%;
        }
    }

    .footer {
        &.content {
            padding-top: $space-5;
        }

        &_links {
            float: left;
        }

        &_blocks {
            float: right;
            padding-bottom: 3.4rem;
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-wrap(wrap);
            @include lib-vendor-prefix-flex-direction(row);
            width: 35%;

            &::after {
                content: '';
                display: block;
                clear: both;
            }
        }
    }
}
