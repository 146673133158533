//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 0.3rem solid transparent !default;
$account-nav-current-border-color: $color-orange-red1 !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: $font-weight__semibold !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default; // TODO: Use global var if used
$_password-medium: #ffd6b3 !default; // TODO: Use global var if used
$_password-strong: #c5eeac !default; // TODO: Use global var if used
$_password-very-strong: #81b562 !default; // TODO: Use global var if used

.login-container,
.accountopeningrequest-wrapper {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 2.5rem;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);

        &::after {
            margin-top: 3.5rem;
        }
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;
        }
    }
}

.form-address-edit {
    .country-select-container {
        display: inline-block;
        position: relative;
        border-radius: 0.4rem;
        width: 100%;

        &::after {
            font-family: 'dcfonts-icons';
            content: $caret-down;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6em;
            padding-top: 0.3rem;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $color-secondary;
            color: $color-white;
            height: 100%;
            line-height: 4rem;
            pointer-events: none;
            cursor: pointer;
            border-radius: 0 0.3rem 0.3rem 0;
            max-height: 4rem;
            width: 4rem;
        }

        select {
            cursor: pointer;
            height: 4rem;
            border: 0.1rem solid $color-primary;
            padding: 1.2rem 1.5rem 1rem;

            ~ label {
                left: $space-1 !important;
                background: $color-white;
                font-size: $font-size-s !important;
                padding: 0 0.7rem;
                top: -0.7rem !important;
                transform: none;
                color: $color-primary !important;
            }

            &.mage-error {
                border-color: $color-red10;

                ~ label {
                    color: $color-red10;
                }
            }

            option[value=''] {
                display: none;
            }
        }
    }

    #region_id {
        display: none;
    }

    .actions-toolbar {
        .action {
            &.primary {
                @extend .abs-button-l;
                line-height: inherit;
                font-size: inherit;
                padding: $space-1 1.5rem;
            }
        }
    }
}

@include max-screen($screen__l) {
    .form-address-edit {
        padding: 1rem;

        .actions-toolbar {
            .primary {
                margin-bottom: 0;
                float: left;
                width: auto;
            }

            .secondary {
                margin-right: 1rem;
            }

            button {
                float: left;
                width: auto;
            }
        }
    }
}

@include min-screen($screen__m) {
    .form-address-edit {
        .actions-toolbar {
            margin-left: 0 !important;
            float: left;

            .secondary {
                margin-right: 1rem;
            }
        }

        .actions-toolbar {
            .action {
                &.primary {
                    line-height: inherit;
                    font-size: inherit;
                    padding: $space-1 1.5rem;
                }
            }
        }
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 2.2rem;
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    .column.main {
        h2 {
            margin-top: 3rem;
        }

        .toolbar {
            text-align: center;

            .limiter-options {
                width: auto;
            }
        }

        .limiter {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
        }
    }

    .sidebar-additional {
        margin-top: $space-5;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 1.5rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        @include lib-css(background, $account-nav-background);
        padding: 1.5rem 0;
    }

    .item {
        margin: 0.3rem 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 0.3rem solid transparent;
            display: block;
            padding: $indent__xs 1.8rem $indent__xs 1.5rem;
        }

        a {
            text-decoration: none;

            &:hover {
                @include lib-css(background, $account-nav-item-hover);
            }
        }

        &.current {
            a,
            strong {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
                @include lib-css(font-weight, $account-nav-current-font-weight);
            }

            a {
                @include lib-css(border-color, $account-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 0.1rem solid $account-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 1.8rem;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;

    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);

            .column.main & {
                font-size: $h3__font-size;
            }
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text {
            @include lib-vendor-prefix-order(0);
            z-index: 2;
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;
    display: none;

    &::before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        display: none;

        &::before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        display: block;

        &::before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        display: block;

        &::before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        display: block;

        &::before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        display: block;

        &::before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    .login-container {
        .fieldset {
            &::after {
                text-align: center;
            }
        }
    }

    .account {
        .page.messages {
            .message {
                margin-bottom: 0;
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;

            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &::after {
                @extend .abs-margin-for-forms-desktop;
            }

            > .field {
                > .control {
                    width: 55%;
                }
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 60rem;
        width: 50%;
    }

    //
    //  My account
    //  ---------------------------------------------
    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                font-size: $font-size__base;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}

.useraccount-account-resetpassword {
    .page-main {
        padding-top: 4rem;

        .page-title-wrapper {
            .page-title {
                font-size: $font-size-l;
                line-height: 1.42857;
                color: #03488f; // TODO: use var $color-sub-secondary
                margin-bottom: $space-2;

                span {
                    font-weight: $font-weight-bold;
                }
            }
        }

        .column.main {
            .form {
                @include max-screen($screen__l) {
                    width: 100%;
                }

                .actions-toolbar {
                    margin: 0;
                    line-height: 4.4rem;

                    .action.primary {
                        height: 4.4rem;
                        line-height: 3.1rem;
                        background: $color-blue1;
                        text-align: center;
                    }

                    a:not(.primary) {
                        color: $color-blue1;
                    }
                }
            }
        }
    }
}

.customer-account-forgotpassword {
    background: $color-grey-background;

    .#{$css-namespace}link-back {
        margin-top: $space-2;
        display: inline-flex;
    }

    .#{$css-namespace}password-action-box {
        box-shadow: var(--box-shadow);
        padding: 3rem 2rem $space-4;
        margin: 1.5rem auto 0;
        max-width: 79.3rem;
        text-align: center;
        border-radius: 0.3rem;
        background: $color-white;

        @include min-screen($screen__l) {
            margin-bottom: 6.9rem;
        }

        &_text {
            line-height: 1.9rem;
            margin-bottom: $space-4;
        }

        &_fieldset {
            margin-bottom: 1.5rem;
        }

        .#{$css-namespace}btn,
        &_field {
            width: 100%;
            max-width: 33.6rem;
            margin-left: auto;
            margin-right: auto;
        }

        .dcfonts-icons {
            font-size: 9.4rem;
            color: $color-primary;
            margin-bottom: 2.34rem;
        }

        .#{$css-namespace}title {
            margin-bottom: 1.5rem;
            line-height: 2.7rem;
        }

        > * {
            max-width: 46.9rem;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.customer-account-logoutsuccess {
    background: $color-grey-background;

    .#{$css-namespace}logout-box {
        box-shadow: 0 0 0.6rem 0 rgba($color-black, 0.12); // TODO: Use box-shadow var
        padding: 3.13rem 2rem 5rem;
        margin: 5rem auto 0;
        max-width: 79.3rem;
        text-align: center;
        border-radius: 0.3rem;
        background: $color-white;

        @include min-screen($screen__l) {
            margin-bottom: 5.9rem;
        }

        &_text {
            line-height: 1.9rem;
        }

        .dcfonts-icons {
            font-size: 9.4rem;
            color: $color-primary;
            margin-bottom: 2.34rem;
        }

        .#{$css-namespace}title {
            margin-bottom: 1.5rem;
            line-height: 2.7rem;
        }

        > * {
            max-width: 100%;
            width: 46.9rem;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
