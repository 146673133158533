.air-datepicker.secondary.-inline- {
    --adp-background-color: #{$color-grey-background};
    --adp-width: auto;
    --adp-padding: 0;
    --adp-nav-action-size: auto;
    --adp-day-name-color: #{$color-dark-grey};
    --adp-day-cell-height: 6.2rem;
    --adp-cell-background-color-selected: transparent;
    --adp-cell-background-color-selected-hover: #{$color-gray96};
    --adp-cell-chip-size: 0.6rem;
    --adp-cell-chip-available-color: #{$mainSecondaryColor};

    border: none;
    max-width: 50.2rem;

    * {
        box-sizing: border-box;
    }

    .air-datepicker {
        &-nav {
            border: none;
            justify-content: center;
            gap: $space-2;
            min-height: 5.8rem;

            &--title {
                min-width: 17rem;
                color: $color-main-dark;
                font-size: 2.2rem;
                line-height: 1.1818;
                font-weight: $font-weight-black;
                padding: 0;

                i {
                    color: inherit;
                }
            }

            &--action {
                padding: 0 $space-1;

                svg {
                    display: none;
                }

                &[data-action='prev'] {
                    &::before {
                        content: $caret-left;
                    }
                }

                &[data-action='next'] {
                    &::before {
                        content: $caret-right;
                    }
                }

                &::before {
                    font-size: $font-size-xl;
                    font-family: $font-icons;
                }
            }
        }

        &-body {
            padding: 0 $space-2 $space-2;
            background-color: $color-white;

            @include min-screen($screen__xs) {
                padding: 0 $space-4 $space-2;
            }

            &--day-names {
                margin: 0;
            }

            &--cells {
                gap: #{$space-1 / 2} $space-1;

                @include min-screen($screen__xs) {
                    gap: #{$space-1 / 2} $space-2;
                }
            }
        }

        &-body--day-names {
            gap: $space-1;
            padding: $space-1 0;
            height: 3.8rem;
        }

        &-body--day-name,
        &-cell {
            text-transform: none;
            font-size: $font-size-s;
        }

        &-cell {
            font-weight: $font-weight-bold;
            font-size: $font-size-m;
            line-height: 1.375;
            border: 0.2rem solid transparent;

            &.-selected- {
                &,
                &.-current- {
                    color: $color-main-dark;
                    border-color: $color-primary;
                }
            }

            &.-day- {
                position: relative;

                &.-disabled- {
                    text-decoration: line-through;
                    color: $color-disabled;
                }

                &:not(.-disabled-) {
                    &::after {
                        content: '';
                        width: var(--adp-cell-chip-size);
                        height: var(--adp-cell-chip-size);
                        position: absolute;
                        bottom: var(--adp-cell-chip-size);
                        left: 50%;
                        transform: translateX(-50%);
                        border-radius: 100%;
                        background-color: var(--adp-cell-chip-available-color);
                    }

                    &.-other-month-:not(.-selected-) {
                        &::after {
                            opacity: 0.25;
                        }
                    }
                }
            }

            &.-month- {
                &:not(.-disabled-) {
                    color: inherit;
                }
            }
        }
    }
}
