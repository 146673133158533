$pagination-gap: $space-2;
$pagination-step-radius: 0.3rem;
$pagination-step-size: 4rem;
$pagination-step-background: $color-white;
$pagination-step-color: $color-primary;
$pagination-step-background-active: $color-pagination-step-current-background;
$pagination-step-font-size: $font-size-default;
$pagination-step-font-weight: $font-weight-semi-bold;
$pagination-button-prev-icon: $caret-left;
$pagination-button-next-icon: $caret-right;

.#{$css-namespace}pagination,
.dataTables_paginate.paging_simple_numbers {
    display: flex;
    justify-content: center;
    margin: $space-3 0;
    gap: $pagination-gap;

    %step-current-state {
        cursor: default;
        background-color: $color-secondary;
        color: $pagination-step-background;
    }

    %step-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $space-1;
        background-color: $pagination-step-background;
        color: $pagination-step-color;
        font-size: $pagination-step-font-size;
        font-weight: $pagination-step-font-weight;
        border-radius: $pagination-step-radius;
        transition:
            color var(--transition-normal),
            background-color var(--transition-normal);
        line-height: 1;
        min-height: $pagination-step-size;
        min-width: $pagination-step-size;

        &.disabled {
            display: none;
        }

        &:is(a) {
            position: relative;
            overflow: hidden;
            color: inherit;
            cursor: pointer;

            @include lib-hover-focus {
                background-color: $color-secondary;
                color: $pagination-step-background;
            }
        }

        &:is(span) {
            cursor: default;

            &.accessibility-text {
                position: absolute;
                top: -10rem;
            }
        }

        &.current {
            @extend %step-current-state;
        }
    }

    &__pages,
    > span {
        // datatables pagination variation
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: inherit;
    }

    &__nav,
    &__page {
        display: flex;
        justify-content: center;
        align-items: center;

        a,
        span {
            @extend %step-item;
        }

        &.current {
            // .current & not working in previous extend
            a,
            span {
                @extend %step-current-state;
            }
        }
    }

    // datatables pagination variation
    .paginate_button {
        @extend %step-item;

        &.previous,
        &.next {
            text-indent: -999rem;
            font-weight: $font-weight-regular;

            &::before {
                text-indent: 0;
                font-family: $font-icons;
                content: $pagination-button-prev-icon;
            }
        }

        &.next::before {
            content: $pagination-button-next-icon;
        }
    }

    ul,
    li {
        margin: 0;
        padding: 0;
    }

    &:empty {
        display: none; // TODO : Refacto as generic :empty with display: none except in cells & slot
    }
}
