$media-queries-list: () !global;

$sizes: (
    //"xxs": $screen__xxs,
    //"xs": $screen__xs,
    //"s": $screen__s,
    'm': $screen__m,
    //"xm": $screen__xm,
    'l': $screen__l,
    //"xl": $screen__xl,
    //"xxl": $screen__xxl
) !default;

/// Create a class for each breakpoint with the given rules.
/// The media queries will be stored in a list to be applied later.
///
/// @param {String} $class - The class
/// @param {Map} $rules - The rules
///
/// @example
/// @include create-rule-by-breakpoints("d-none", ("display": "none !important"));
///
/// Will generate:
/// .d-none {}
/// .m:d-none {}
@mixin create-rule-by-breakpoints($class, $rules) {
    .#{$class} {
        @each $property, $value in $rules {
            #{$property}: #{$value};
        }
    }

    $media-queries-list: append($media-queries-list, ($class, $rules)) !global;
}

/// Apply the media queries from the list of the "create-rule-by-breakpoints" mixin.
@mixin apply-media-queries() {
    @each $label, $size in $sizes {
        @each $data in $media-queries-list {
            $class: nth($data, 1);
            $rules: nth($data, 2);

            .#{$label}\:#{$class} {
                @include min-screen($size) {
                    @each $property, $value in $rules {
                        #{$property}: #{$value};
                    }
                }
            }
        }
    }

    // Reset the list to prevent rules to be applied multiple times.
    $media-queries-list: () !global;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -moz-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -moz-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -moz-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    align-items: $value;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}
