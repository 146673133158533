.#{$css-namespace}modal-issue {
    .modal-content {
        min-height: 42rem;

        .#{$css-namespace}modal-formInput {
            &:not(:empty) {
                margin-top: $space-3;
            }
        }
    }
}
