// cms application
.#{$css-namespace}cms {
    .#{$css-namespace}panel-account {
        @extend %dc__panel;
        background-color: $color-blue2;
        background: linear-gradient(180deg, $color-blue3 0%, $color-blue2 50%, $color-blue3 100%);
        color: $defaultColor;
        padding: $indent__base;

        .#{$css-namespace}panel-body {
            > p + p,
            > p + ul,
            > ul + p {
                margin-top: $indent__s;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: $indent__xs;
            @include lib-font-size(20);
            font-weight: $font-weight__bold;
        }

        ul {
            margin: 0 0 0 $indent__s;
            padding: 0;

            > li {
                display: flex;

                &::before {
                    content: '';
                    display: inline-flex;
                    width: 16px;
                    height: 16px;
                    background: url(../images/png/chevron@1.png) 0 0 no-repeat transparent;
                }
            }
        }

        p {
            margin: 0;
        }

        .#{$css-namespace}btn,
        button {
            display: block;
            background-color: $defaultColor;
            color: $color-blue2;
            padding: $indent__s;
            @include lib-font-size(14);
            font-weight: $font-weight__semibold;
            text-align: center;
            text-transform: uppercase;
            border-radius: 0.3rem;
        }

        &-content {
            > footer {
                margin-top: $indent__base;
            }

            @include min-screen($screen__l) {
                flex-direction: row;
                align-items: center;
                padding: $indent__s $indent__base;

                > footer {
                    margin-top: 0;
                }
            }
        }

        &-sidebar {
            > header + .#{$css-namespace}panel-body {
                margin-top: $indent__base;
            }

            > footer {
                margin-top: $indent__base;
            }
        }
    }
}
