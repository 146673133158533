.checkout__payment {
    .cart-wrapper {
        padding: 3rem;
        margin-bottom: 2rem;

        @include max-screen($screen__l) {
            padding: 3rem 1.5rem 2rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.opc-payment {
            @include max-screen($screen__l) {
                padding-top: 2rem;
                padding-bottom: 1.95rem;
            }

            .payment-group .payment-method:only-of-type {
                &._active,
                &.disabled {
                    .payment-method {
                        &_label input[type='radio'] {
                            display: none;
                        }

                        &-content > * {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        + .cart-wrapper {
            margin-top: 0;
        }

        .#{$css-namespace}hr {
            &.first-hr {
                margin: 1rem 0 0.5rem 0;

                @include max-screen($screen__l) {
                    margin: 1.95rem 0 0;
                }
            }

            &.bottom-hr {
                margin-bottom: 0;
                margin-top: 1.15rem;

                @include max-screen($screen__l) {
                    margin-top: 1rem;
                }
            }
        }

        .description {
            margin-top: 1rem;
            max-width: 100%;
            line-height: 1.57em;

            @include min-screen($screen__l) {
                width: 46rem;
            }
            @include max-screen($screen__l) {
                margin-top: 2rem;
            }
        }

        .method-description {
            line-height: 2.2rem;
        }

        .mandatory {
            margin-top: 1.5rem;
            margin-bottom: -0.5rem;
            font-weight: $font-weight-regular;
            font-size: $font-size-s;
            font-style: italic;
            line-height: 2.2rem;
        }

        .#{$css-namespace}tooltip {
            .dcfonts-icons {
                font-size: $font-size-m;
            }
        }

        .fieldset .control .#{$css-namespace}input-text,
        .#{$css-namespace}select--custom {
            width: 23rem;
            max-width: calc(100% - 2.5rem);
            margin-right: 0.6rem;
            position: relative;

            &:not(:focus)[value=''] ~ label {
                font-size: $font-size-default;
            }

            ~ .field-error {
                &:not(:empty) {
                    display: flex;
                    padding-left: 0;
                }

                span {
                    width: 21.5rem;
                    display: inline-block;
                    vertical-align: top;
                    order: 9;
                }
            }
        }

        .fieldset .control .#{$css-namespace}input-text {
            ~ .field-error {
                &:not(:empty) {
                    display: flex;
                    padding-left: 0;
                }

                span {
                    width: fit-content;
                    max-width: 100%;
                }
            }
        }

        .fieldset {
            > .form-elements {
                margin-top: 2.7rem;

                @include max-screen($screen__l) {
                    margin-bottom: 1rem;
                }
            }
        }

        [name='chorus_legal_commitment'] {
            &::placeholder {
                color: transparent;
            }

            &:not([required]):not(:focus) {
                border-color: $color-main-dark;
            }
        }

        .chorus_legal_commitment_fieldset {
            .control {
                input {
                    ~ [data-error-required] {
                        display: none !important;
                    }
                }
            }
        }

        .chorus_legal_commitment--required {
            [name='chorus_legal_commitment'] {
                ~ label span {
                    &::after {
                        content: '*';
                    }
                }
            }

            .field {
                &:not(._error) {
                    .control {
                        input {
                            &:invalid {
                                border-color: $color-main-dark;

                                &:focus {
                                    border-color: $color-sub-secondary;
                                }
                            }
                        }
                    }
                }
            }

            &._error {
                .field {
                    .control {
                        input {
                            &,
                            &:invalid {
                                border-color: $color-red;
                            }

                            &:not(:focus) {
                                ~ .label {
                                    color: $color-red;
                                }
                            }

                            ~ [data-error-required] {
                                display: block !important;
                            }
                        }
                    }
                }
            }
        }

        .form-elements--select {
            &-container {
                position: relative;
                margin-right: 0.6rem;
            }

            &._error {
                .#{$css-namespace}select--custom {
                    border-color: $color-red;

                    ~ label {
                        color: $color-red;
                    }
                }

                .field-error {
                    &:not(.field-error--disabled) {
                        display: block !important;
                    }
                }
            }

            .field-error {
                display: none !important;
                order: 9;
            }

            .#{$css-namespace}select--custom {
                margin: 0;
                max-width: 100%;
                padding-right: 0;

                &::after {
                    display: none;
                }

                .current {
                    padding-right: 2.5rem;
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    width: calc(100% - 2.8rem);
                }

                + i {
                    pointer-events: none;
                }

                .list {
                    .option {
                        &[data-value=''] {
                            display: block;
                            margin-top: $indent__s;
                        }
                    }
                }
            }
        }

        .one_or_another_required {
            .form-elements--select {
                &._error {
                    .field-error {
                        display: none;
                    }
                }
            }
        }

        .field-error {
            &::before {
                display: inline-block;
                font-family: $font-icons;
                content: $cross;
                font-size: $font-size-xxs;
                margin-right: 0.3rem;
                margin-top: -0.1rem;
            }

            &.field-error--disabled {
                display: none;

                &.hide-other-errors {
                    display: block !important;

                    ~ .field-error {
                        display: none !important;
                    }
                }
            }
        }
    }

    &-additionnal-before {
        .control {
            display: flex;
            flex-flow: row wrap;

            .field-error {
                width: 100%;
            }
        }
    }

    .tooltip-to-move {
        display: none;
    }

    .#{$css-namespace}title[data-ui-title-level='3'] {
        font-size: $font-size-xl;
        line-height: 1.75em;
        font-weight: $font-weight-semi-bold;

        @include max-screen($screen__l) {
            line-height: 2.4rem;
            margin-bottom: $space-1 / 2;
        }
    }

    .#{$css-namespace}title[data-ui-title-level='2'] {
        font-size: $font-size-xxl;
        line-height: 4rem;

        @include max-screen($screen__l) {
            line-height: 1em;
        }
    }
}

#co-payment-form {
    .payment-group {
        .radio {
            width: inherit;
            border: none;
            box-shadow: none;
            margin-right: 1rem;
        }
    }
}

#checkout-payment-method-load {
    .payment-method {
        &_label {
            display: flex;
            align-items: center;
            padding: $space-1 0 0;

            .label-group {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 0;
            }

            label {
                font-size: $font-size-xl;
                padding-top: $space-1 / 2;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap: $space-2;
                margin-bottom: 0;

                @include min-screen($screen__s) {
                    column-gap: $space-4;
                }

                span {
                    color: $color-main-dark;
                }
            }

            label,
            .label {
                font-size: $font-size-xl;
                width: 100%;
            }
        }

        &._active {
            .payment-method_label {
                label {
                    color: $color-checkout-payment-label;
                }
            }
        }

        &_alert {
            width: 100%;
            background-color: $color-grey-background;
            padding: 1rem 1rem 1rem 2.5rem;
            margin-top: 1rem;
            font-weight: $font-weight__semibold;
            font-family: $font-secondary;
            font-size: $font-size-s;
            display: flex;
            align-items: end;

            @include max-screen($screen__l) {
                align-items: center;
            }

            &__mobile {
                display: none;

                @include max-screen($screen__l) {
                    display: flex;
                    margin-top: 1rem;
                }
            }

            i:not(.#{$css-namespace}product-chip_icon) {
                font-size: 1.8rem;
                color: $color-secondary;
                padding-right: $space-1;
            }
        }

        &.disabled {
            label,
            .method-description {
                color: $color-disabled-checkout !important;
            }
        }

        &--disabled {
            $payment-method-class: 'payment-method';

            .#{$payment-method-class} {
                &_method-name {
                    color: $color-disabled-normal;
                }

                &-content {
                    display: block;

                    .#{$payment-method-class}_alert:not(.payment-method_cb-not-eligible),
                    .payzen-form {
                        display: none;
                    }

                    .#{$payment-method-class}_cb-not-eligible {
                        margin-bottom: 2.65rem;
                        padding: 1rem 1rem 1rem 2.5rem;

                        .#{$payment-method-class}_alert-icon {
                            align-self: flex-start;
                            margin-top: $space-1;
                            margin-right: 1rem;
                            padding: 0;

                            @include min-screen($screen__l) {
                                margin-top: 0;
                            }
                        }

                        .#{$payment-method-class}_alert-message {
                            display: flex;
                            flex-direction: column;
                        }

                        .#{$payment-method-class}_cb-not-eligible-msg-container {
                            font-size: $font-size-s;
                            font-weight: $font-weight__semibold;
                            line-height: 2rem;
                        }

                        .#{$css-namespace}btn.#{$payment-method-class}_find-store-btn {
                            margin-top: 0.5rem;
                            min-height: 3rem;
                            line-height: 1;
                            font-weight: $font-weight-regular;
                            padding: $space-1 2.2rem;
                            align-self: flex-start;
                        }

                        .#{$payment-method-class}_back-cart-btn {
                            justify-content: center;
                            margin-top: 1rem;
                            padding-top: 0;
                            padding-bottom: 0;
                            min-height: 3rem;
                            font-weight: $font-weight__regular;

                            @include min-screen($screen__l) {
                                align-self: flex-start;
                            }
                        }

                        .#{$css-namespace}product-chip {
                            display: inline-flex;
                            line-height: initial;
                            background-color: $color-white;

                            .#{$css-namespace}product-chip_icon {
                                @extend .#{$css-namespace}product-chip_icon;
                            }
                        }

                        .#{$css-namespace}product-chip--orange {
                            @extend .#{$css-namespace}product-chip--orange;
                        }
                    }
                }
            }

            .#{$css-namespace}summary_payment-item {
                filter: grayscale(1);
            }
        }

        .method-description {
            font-size: $font-size-default;
            color: $color-main-dark;
        }
    }

    .no-radio-button {
        .payment-method_label {
            .label-group {
                padding-left: 0;

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}

.checkout-agreement {
    margin: 0 0 1rem !important;
    display: flex;
    align-items: center;
    color: $color-dark-grey;

    @include max-screen($screen__l) {
        align-items: start;
    }

    a {
        font-weight: $font-weight__semibold;
        text-decoration: underline;
    }

    .label {
        padding-left: $space-1;
    }
}
