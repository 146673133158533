.category-header {
    position: relative;
    text-align: center;

    &__name {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: $color-white;
        position: absolute;
        padding-left: $offset-l;
        padding-right: $offset-l;
        margin: 0;
        font-family: $font-secondary;
        font-weight: $font-weight-bold;
    }

    &__image {
        display: block;

        img {
            display: block;
            width: 100%;
            object-fit: cover;
            height: 16rem;
        }
    }

    &__arrow-border {
        position: absolute;
        right: 0;
        bottom: -6rem;
        border-top: 6rem solid transparent;
        border-bottom: 6rem solid transparent;
        border-left: 6rem solid transparent;
        border-right: 6rem solid white;
    }

    &__arrow {
        position: absolute;
        right: -6rem;
        bottom: -3rem;
        border-top: 3rem solid transparent;
        border-bottom: 3rem solid transparent;
        border-left: 3rem solid transparent;
        border-right: 3rem solid white;
    }

    .breadcrumbs {
        padding: 0;
        width: 100vw;
        max-width: 100%;
        position: absolute;
        top: $space-2;
        left: 0;
        background-color: transparent;
    }

    .items {
        width: 100%;
        max-width: $screen-xl;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        padding-left: $space-3;
        gap: $space-1 / 2;
    }

    .item:not(:last-child)::after {
        color: $color-white;
    }

    .item:last-child a,
    .item a {
        color: $color-white;
    }
}

@include min-screen($screen__l) {
    .category-header__image {
        img {
            min-height: 15.5rem;
        }
    }
}

@include max-screen($screen__l) {
    &__name {
        top: 6.5rem;
    }
    .category-header {
        margin: 0 (-($indent__base)); // @TODO: fix margin on global layout for mobile
    }
}

@include max-screen($screen__xs) {
    .category-header {
        margin: 0 (-($indent__s)); // @TODO: fix margin on global layout for mobile
    }
}

@include max-screen($screen__s) {
    .category-header {
        &__image img {
            max-height: 11rem;
        }

        &__arrow-border {
            bottom: -3rem;
            border-width: 3rem;
        }

        &__arrow {
            right: -3rem;
            bottom: -1.5rem;
            border-width: 1.5rem;
        }
    }
}
