@import 'module/popup';
@import 'module/wishlist-products';
@import 'module/wishlist-dropdown';

.account.page-multiple-wishlist:not(.wishlist-board) {
    .column.main {
        @include max-screen($screen__m) {
            padding: 2rem 0.5rem 3rem;
        }
    }

    .form-wishlist-items {
        background-color: $color-white;
        padding: $indent__l;
    }
}

.#{$css-namespace}wishlist-agency {
    background-color: $color-grey-background;
    padding: $indent__l 0 0;
    margin: 0 -#{$indent__l};

    @include max-screen($screen__m) {
        margin: 0 -0.5rem;
        padding-top: 2rem;
    }

    &--no-gap {
        margin: $indent__l 0 0;
        padding: 0;
    }

    &_container {
        .checkout-index-index & {
            padding: 0;
        }
    }

    dd,
    dl {
        margin-bottom: 0;
    }

    .#{$css-namespace}title[data-ui-title-level='4'] {
        font-weight: $font-weight-semi-bold;
    }

    &_container:not(.#{$css-namespace}useraccount_heading-form-wrapper) {
        padding: $indent__xl $indent__xl 0;
        background-color: $color-white;

        @include max-screen($screen__m) {
            padding: 3rem 2rem 0;
        }
    }

    &-content {
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        flex-direction: row;
        margin-top: $offset-l;

        @include max-screen($screen__m) {
            flex-direction: column;
        }
    }

    &-card {
        display: inline-flex;
        flex-direction: column;

        &:first-child {
            margin-right: $space-10;

            @include max-screen($screen__m) {
                margin-bottom: 2rem;
                margin-right: 0;
            }
        }

        .#{$css-namespace}title {
            margin-bottom: $indent__s;
            color: $color-secondary;
        }
    }

    .wishlist-top_achat-index & {
        padding-top: $indent__base;
        margin-top: $indent__l;
    }
}

.wishlist-index-index {
    &:not(.wishlist-board) {
        .column.main {
            padding-top: 3rem;

            @include max-screen($screen__l) {
                padding-top: 2rem;
            }

            .#{$css-namespace}block-addbysku {
                padding-bottom: 3rem;
                background: $color-white;

                @include max-screen($screen__m) {
                    padding-bottom: 1.5rem;
                }

                &_form {
                    margin: 0;
                }
            }

            .#{$css-namespace}wishlist_products-section {
                display: none;
                background-color: $color-white;
                padding-bottom: 2rem;

                &.is-active {
                    display: block;

                    @include min-screen($screen__l) {
                        display: flex;
                        flex-flow: row wrap;

                        .#{$css-namespace}pagination {
                            order: 4;
                        }
                    }
                }

                .#{$css-namespace}products-section_products-table {
                    margin-bottom: 0;
                }

                .#{$css-namespace}wishlist-agency {
                    flex: 1;
                }
            }
        }
    }

    &.account.page-multiple-wishlist.wishlist-board {
        .columns {
            @include max-screen($screen-sm) {
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .column.main {
                @include max-screen($screen-sm) {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    .#{$css-namespace}link-back {
        margin-bottom: 1.5rem;
        display: inline-block;

        @include max-screen($screen__l) {
            margin-bottom: 1.5rem;
        }
    }

    .page.messages {
        word-break: break-word;
    }
}

.#{$css-namespace}wishlist-title-container {
    .#{$css-namespace}p {
        text-align: justify;
    }
}

.#{$css-namespace}wishlist-container {
    .#{$css-namespace}title {
        margin-bottom: 0.7rem;
    }

    &:not(.#{$css-namespace}useraccount_heading-form-wrapper) {
        padding-top: 5rem;

        @include max-screen($screen__l) {
            padding-top: 3rem;
            padding-bottom: 2rem;
        }
    }
}

.#{$css-namespace}wishlist-create-wrapper {
    display: flex;
    flex-direction: row;

    @include min-screen($screen__l) {
        flex-wrap: wrap;
    }
    @include max-screen($screen__l) {
        flex-direction: column;
    }

    .mage-error:not(:empty) {
        width: 100%;
        margin-top: 0;

        @include min-screen($screen__l) {
            order: 9;
        }

        @include max-screen($screen__l) {
            margin-bottom: 1.3rem;
        }
    }

    .#{$css-namespace}wishlist-input-create {
        width: 23.6rem;
        height: 4rem;
        border: 0.1rem solid $color-primary;
        padding: 0 1rem;
        margin-bottom: 1.3rem;
        font-family: $font-family-name__base;
        font-weight: $font-weight__heavier;

        @include max-screen($screen__l) {
            width: 100%;
        }

        &::placeholder {
            color: $color-main-dark;
        }

        &:focus,
        &:active {
            border-color: $color-primary;
        }
    }

    .#{$css-namespace}wishlist-button-create,
    .#{$css-namespace}wishlist-button-create[data-ui-btn-size='sm'] {
        width: 16.6rem;
        height: 4rem;
        margin-left: 1rem;
        display: flex;
        font-size: $font-size-default;
        font-weight: $font-weight__heavier;

        .#{$css-namespace}icon {
            margin-right: 4rem;
        }

        @include max-screen($screen__l) {
            margin-left: 0;
        }
    }
}

#wishlist-list-table_wrapper {
    @include min-screen($screen__l) {
        margin-left: -3rem;
        margin-right: -3rem;
    }
}

.#{$css-namespace}wishlist-table {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    direction: ltr;

    @include min-screen($screen__l) {
        table-layout: fixed;

        .sorting_1 + td {
            display: table-cell;
            word-break: break-word;
        }

        .#{$css-namespace}wishlists_table_creation-date,
        .#{$css-namespace}wishlists_table_item_creation_item_date {
            width: 16rem;
        }
        .#{$css-namespace}wishlists_table_shopping-list-name,
        .#{$css-namespace}wishlists_table_item_shopping-list-name {
            width: 60%;
        }
        .#{$css-namespace}wishlists_table_shopping-actions,
        .#{$css-namespace}wishlists_table_item_shopping-actions {
            width: 16rem;
        }
    }
    @include max-screen($screen__l) {
        border-collapse: separate;
        border-spacing: 0 1.5rem;
        direction: rtl;
        margin: 0 !important;

        .sorting_1 {
            min-width: 10rem;
            display: table-cell;
        }
        > tbody {
            display: table;

            > tr > td {
                display: table-cell;
            }
        }
    }

    .dt-checkboxes-cell {
        display: none;
    }

    > tbody > tr > td {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
        line-height: 1.6rem;
    }

    thead {
        th {
            &:nth-of-type(2) {
                @include min-screen($screen__l) {
                    padding-left: var(--datatable-cell-spacing);
                }
            }

            &:last-child {
                @include min-screen($screen__l) {
                    padding-right: var(--datatable-cell-spacing);
                    text-align: right;
                }
            }
        }
    }

    tbody {
        tr {
            background-color: $color-white;
        }

        .#{$css-namespace}wishlist-table-edit-link {
            display: flex;
            word-break: break-all;

            &:hover {
                color: inherit;
            }
        }

        td {
            &:nth-of-type(2) {
                @include min-screen($screen__l) {
                    padding-left: var(--datatable-cell-spacing);
                }
            }

            &:last-child {
                display: none;

                @include min-screen($screen__l) {
                    padding-right: var(--datatable-cell-spacing);
                    display: table-cell;
                }

                &:not(.dataTables_empty) {
                    text-align: right;
                }
            }

            &:nth-of-type(2) {
                position: relative;
                text-align: center;

                @include max-screen($screen__l) {
                    text-align: right;

                    .#{$css-namespace}wishlist-table-edit-link {
                        justify-content: right;
                    }
                }
            }

            &:nth-of-type(3) {
                font-weight: $font-weight-bold;
                direction: ltr;
            }
        }
    }

    &#wishlist-list-table {
        border-collapse: collapse;

        @include max-screen($screen__l) {
            border-spacing: 0;
            direction: ltr;
        }

        > tbody {
            @include max-screen($screen__l) {
                display: table-row-group;
            }

            > tr {
                line-height: inherit;
                display: table-row;

                &:hover {
                    td:nth-of-type(2) {
                        border-left-color: $color-secondary;
                    }
                }
            }

            td {
                &:nth-of-type(2) {
                    border-left: 0.3rem solid transparent;

                    .#{$css-namespace}wishlist-table-edit-link {
                        @include max-screen($screen__l) {
                            justify-content: left;
                        }
                    }
                }

                &:last-child {
                    @include max-screen($screen__l) {
                        display: table-cell;
                        width: 8rem;
                    }
                }
            }
        }
    }
}

.#{$css-namespace}wishlist-table-row-title {
    &-container {
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: table-row;

        @include max-screen($screen__l) {
            display: none;
        }

        > th:nth-child(2) {
            max-width: 17.2rem;
        }

        .dcfonts-icons--arrows-left-right {
            transform: rotate(90deg);
            font-size: 1.7rem;
        }

        span {
            vertical-align: middle;
        }
    }

    &--centered {
        text-align: center;
    }
}

.#{$css-namespace}wishlist-table-action-link {
    .table-dc & {
        &:hover {
            color: $color-primary;
        }
    }

    .dcfonts-icons {
        font-size: 1.5rem;
    }

    &-text {
        text-decoration: underline;
        padding-left: 0.5rem;
    }

    &--share {
        position: relative;
        padding-right: var(--datatable-cell-spacing);
    }
}

.product-addto-links {
    display: none;

    &.isLogged {
        display: block;
    }
}

#total-wishlit-amount {
    color: $color-sub-secondary;
    @include lib-font-size(30);
    font-weight: $font-weight-bold;
}

.block-wishlist-management {
    margin: 0;
    background-color: $color-white;
    padding-bottom: 3rem;

    .wishlist-info {
        float: none;
    }

    .#{$css-namespace}wishlist-download-popin-content {
        .description {
            text-align: center;
        }

        &_base {
            display: none;
        }
    }
}

.#{$css-namespace}wishlist-add-all-cart-container {
    @include min-screen($screen__l) {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }

    &--bottom {
        width: 100%;
    }

    @include max-screen($screen__l) {
        position: sticky;
        bottom: 0;
        left: 0;
        padding: 1rem;
        background: $color-white;
        z-index: 3;
        width: 100%;
    }

    .#{$css-namespace}wishlist-add-all-cart {
        font-weight: $font-weight-medium;

        &.click-and-collect {
            background-color: $color-accent !important;
            border-color: $color-accent !important;
        }

        @include max-screen($screen__l) {
            width: 100%;
        }

        .dcfonts-icons {
            font-size: $font-size-m;
        }

        &--text {
            font-size: $font-size-default;
        }
    }
}

/* form-wishlist-items */
.form-wishlist-items {
    @include max-screen($screen__l) {
        padding: 0 var(--datatable-th-spacing);
    }
    @include max-screen($screen__m) {
        .block-wishlist-management .wishlist-select {
            margin: 0;
        }
    }

    .action.primary {
        height: 5rem;
        line-height: 3rem;
    }

    .panel-wishlist {
        display: flex;
        flex-direction: column;

        .box-content {
            dl {
                display: flex;
                flex-direction: column;
                margin: $indent__s 0 0;

                dt {
                    margin: 0;
                    font-weight: $font-weight__bold;
                }

                dd {
                    margin: 0;
                }
            }

            .fn {
                display: block;
                @include lib-font-size(16);
                font-weight: $font-weight__bold;
            }

            .adr {
                > span {
                    display: block;
                }
            }

            .tel {
                dd {
                    color: $color-sub-secondary;
                }
            }
        }
    }

    .cart.table-wrapper {
        @include max-screen($screen__l) {
            padding: 0;
        }

        .items .cart.item {
            @include max-screen($screen__m) {
                .col.item {
                    .product-item-photo,
                    .product-item-name {
                        margin: 0;
                    }
                }
            }

            .col.price {
                .price-wrapper {
                    display: block;
                    font-family: $font-family__sans-serif;
                    color: $color-gray-light7;
                    @include lib-font-size(18);
                }

                .price_unit,
                .unit {
                    font-size: $text-price-unit-size;
                    color: $text-price-unit-color;
                }
            }

            .col.qty {
                .update-link {
                    .wishlist-row-button {
                        display: none;
                        white-space: nowrap;
                        font-size: $font-size-s;
                        border: none;
                        background: unset;
                        text-transform: unset;
                        padding: 0.5rem 0;
                        font-weight: $font-weight-regular;

                        & span {
                            margin-left: -8%;
                            color: $color-blue1;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .col.subtotal .price-container + span {
                display: none;
            }

            .actions-toolbar .wishlist.to-cart .button:before {
                content: '';
                width: 2.6rem;
                height: 2.6rem;
                display: table-cell;
                @include lib-background-image('svg/panier.svg', left center, no-repeat, transparent, false);
                background-size: contain;
                padding-right: 0.2rem;
            }
        }
    }
}

.#{$css-namespace}regular-purchases {
    &-title-container {
        margin-bottom: 3rem;

        @include max-screen($screen__s) {
            margin-bottom: 1.5rem;
        }

        &_description {
            margin-top: $space-1;
            margin-bottom: 0;
        }
    }
}
