// quickorder bottom part

$quickorder-add-btn-size: 4rem;
$unitary-add-min-width: 17.6rem;
$unitary-add-max-width: 35.2rem;

.column .#{$css-namespace}block-addbysku {
    &_form {
        margin: $space-1 0 $space-3;
        position: relative;

        @include min-screen($screen__l) {
            margin: $space-1 0;
            padding: $space-2;
            display: flex;
            align-items: flex-end;
            background: $color-grey-background;
        }

        @include max-screen($screen__l) {
            &::before {
                content: '';
                display: block;
                position: absolute;
                background: $color-white;
                bottom: 0;
                left: 0;
                right: 0;
                top: 11.5rem;
            }
        }

        .quick-order__bottom {
            width: 100%;
            @include max-screen($screen__l) {
                margin: 0 auto;
            }
        }

        &_description {
            color: $color-primary;
            font-size: $font-size-default;
            line-height: 1.6rem;
            font-weight: $font-weight__heavier;

            &.mobile {
                @include min-screen($screen__l) {
                    display: none;
                }
            }

            &.desktop {
                @include max-screen($screen__l) {
                    display: none;
                }
            }
        }

        &_input-text {
            border-color: $color-main-dark;
            border-radius: $input-text__border-radius;

            @include min-screen($screen__l) {
                min-width: $unitary-add-min-width;
                max-width: $unitary-add-max-width;
            }

            &:focus,
            &[value]:not([value='']) {
                border-color: $color-primary;
                padding-bottom: 1.2rem;

                &.mage-error {
                    border-color: $color-red;
                }
            }
        }

        &_fields {
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            @include max-screen($screen__l) {
                margin-top: $space-1;
                flex-direction: column;
            }

            .field-wrapper {
                width: 100%;
            }

            @include min-screen($screen__l) {
                align-items: flex-end;

                .field-wrapper {
                    width: 100%;
                    min-width: $unitary-add-min-width;
                    max-width: $unitary-add-max-width;
                    margin-right: 1.5rem;
                }
            }
        }

        &_sku {
            margin-top: $space-1;
            margin-bottom: 0;

            .mage-error {
                @include min-screen($screen__l) {
                    white-space: nowrap;
                }
            }

            @include min-screen($screen__l) {
                margin-bottom: 0;
            }

            .label {
                color: $color-grey-dove;
                top: 1.1rem; // TODO : generic style for labels with app/design/frontend/Descours_v2/Descours/styles/toolkit/common/01_components/forms/text/_text.scss
                left: 1.5rem;
            }

            input {
                height: 4rem;
                border-width: 0.1rem;
                background: $defaultColor;
                padding: 0 1.5rem;
                font-family: $font-default;
                border-color: $color-grey-dove;
                border-radius: $form-element-input__border-radius;

                &.mage-error {
                    border-color: $color-red;
                }
            }

            div.mage-error {
                color: $color-red;

                &::before {
                    display: inline-block;
                    font-family: $font-icons;
                    content: $cross;
                    font-size: $font-size-xxs;
                    margin-right: 0.3rem;
                    margin-top: -0.1rem;
                }
            }
        }
    }

    .fieldset .control .input-text:focus ~ .label,
    .fieldset .control .input-text:not([value='']) ~ .label,
    .fieldset .control .input-text:not([value]) ~ .label,
    .fieldset .control .input-text:not(:empty) ~ .label,
    .fieldset .control .input-text:where(&:is([type='email']), &:is([type='number']), &:is([type='password']), &:is([type='text']), &:is([type="url"]), &:is([type="search"]), textarea):not(:placeholder-shown) ~ label {
        background: $color-grey-background; // TODO : generic style for labels with app/design/frontend/Descours_v2/Descours/styles/toolkit/common/01_components/forms/text/_text.scss
        color: $color-primary;
        font-size: $font-size-s;
        font-weight: $font-weight__regular;
        top: -0.6rem;
        left: 0.9rem;
        padding: 0 $indent__xs;
    }

    .fieldset .control .input-text:not([value='']).mage-error ~ .label,
    .fieldset .control .input-text:focus.mage-error ~ .label,
    .fieldset .control .mage-error ~ .label {
        color: $color-red;
    }

    &_result,
    &_result_cart {
        display: none;
        max-width: 64.7rem;
        width: 100%;
        padding: 1.25rem 1.2rem 0.95rem 1.25rem;
        box-shadow: 0 0.2rem 0.4rem rgba($color-black, 0.2);
        background: $color-white;
        position: relative;
        min-height: 6rem;
        margin-top: 1rem;

        @include min-screen($screen__l) {
            padding: 1rem 1.2rem 1rem;
            margin-top: 0;
            max-width: calc(100% - 24.5rem);
        }

        &_wrapper {
            display: flex;
            width: 100%;
            align-items: center;

            @include max-screen($screen__l) {
                flex-direction: column;
            }
        }

        &_product-image {
            display: flex;
            width: $quickorder-add-btn-size;
            flex: 0 0 $quickorder-add-btn-size;
            max-width: $quickorder-add-btn-size;

            @include max-screen($screen__l) {
                height: $quickorder-add-btn-size;
            }

            img {
                max-height: $quickorder-add-btn-size;
            }
        }

        &_product-datas {
            position: relative;
            flex: 1;
            max-width: 100%;
            margin: auto 1.65rem 1rem;

            @include min-screen($screen__l) {
                margin: auto $space-2 auto 1.3rem;
            }

            @include max-screen($screen__l) {
                margin: $space-2 auto;
            }

            &_name {
                margin-bottom: $space-1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-weight: $brand-text-weight;
                color: $brand-text-color;
                text-transform: $brand-text-case;

                @include max-screen($screen__l) {
                    .tooltip-text {
                        position: absolute;
                        z-index: 2;
                        top: calc(100% - 1rem);
                        right: 0;
                        left: 0;
                        visibility: hidden;
                        width: 23.2rem;
                        padding: $indent__s;
                        text-align: center;
                        color: $color-white;
                        border-radius: 0.4rem;
                        background: $color-primary;
                        box-shadow: 0 0.5rem 0.3rem 0 $color-tertiary-10;

                        &::before {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                            display: block;
                            width: 1.2rem;
                            height: 1.2rem;
                            content: '';
                            background: inherit;
                        }
                    }
                }

                span {
                    font-weight: $font-weight-regular;
                    color: $color-main-dark;
                    text-transform: none;
                }
            }

            &_reference {
                font-size: $reference-text-size;
                font-weight: $reference-text-weight;

                .ref {
                    margin-left: $space-1 / 2;
                }

                @include min-screen($screen__l) {
                    margin-bottom: 0;
                }
            }
        }

        &_product-add {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            width: 100%;

            .wishlist-index-index &,
            .useraccount-quickorder-index & {
                display: flex !important;
            }

            @include min-screen($screen__l) {
                margin-left: auto;
                max-width: $quickorder-add-btn-size;

                button {
                    height: $quickorder-add-btn-size;
                    font-size: 0;
                    font-weight: $font-weight-regular;
                    gap: 0;

                    &::after {
                        font-family: $font-icons;
                        background: none;
                        content: $plus;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $color-white;
                        @include lib-font-size(16);
                    }
                }
            }

            button {
                width: 100%;
                justify-content: center;
                height: $quickorder-add-btn-size;
                font-weight: $font-weight__heavier;
            }
        }
    }
}

//quickorder on checkout step
.checkout-cart-index {
    .column .#{$css-namespace}block-addbysku {
        &_form {
            margin: 1.5rem -1rem 2rem;

            &_sku {
                width: 100%;

                @include min-screen($screen__l) {
                    max-width: 35.2rem;
                }
            }
        }
    }
}
