@mixin lib-hover-focus() {
    &:hover,
    &:focus {
        @content;
    }
}

@mixin lib-active-visited() {
    &:active,
    &:visited {
        @content;
    }
}

@mixin lib-hover-focus-visible() {
    &:hover,
    &:focus-visible {
        @content;
    }
}
