.#{$css-namespace}add_cart_layer {
    $root: &;

    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    align-items: stretch;
    margin: -1.1rem;
    border: none;
    padding: 1rem;
    color: $color-white;
    background-color: $color-primary;

    .slick-list &,
    .#{$css-namespace}equivalent-products & {
        gap: $space-1;
    }

    &__close {
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        padding: 0.5rem;

        &,
        &:hover,
        &:active,
        &:focus {
            border: none;
            background: none;
            color: initial;
        }

        > .#{$css-namespace}icon {
            width: 2.4rem;
            height: 2.4rem;
            font-size: $font-size-m;
            color: $color-white;
        }
    }

    &__reference,
    &__title {
        margin-top: $space-1;
    }

    &__reference {
        font-size: $reference-text-size;
        font-weight: $reference-text-weight;
    }

    &__title {
        margin-right: $space-3;

        #{$root}__brand {
            text-transform: $brand-text-case;
            font-weight: $brand-text-weight;
            line-height: (20 / 14);
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            height: fit-content;
            max-height: 2rem;
        }

        #{$root}__name {
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }

    &__price_container {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        #{$root}__price {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            line-height: 1.18;

            .slick-list &,
            .#{$css-namespace}equivalent-products & {
                align-items: baseline;
            }

            #{$root}__amount {
                font-size: 2.2rem; // No variable available.
                font-weight: $font-weight-black;
                line-height: (26 / 22);

            }

            #{$root}__currency {
                margin-left: 0.1rem;
                font-size: $font-size-m;
                line-height: 1.3;
            }

            #{$root}__price_reference {
                margin-left: $space-1;
                font-size: $font-size-default; // Instead of $text-price-unit-size but Figma is fun
                line-height: 1.4;
            }
        }

        #{$root}__eco_part {
            font-size: $font-size-xs;
            font-style: italic;
            font-weight: $font-weight-light;

            .#{$css-namespace}product_slot & {
                display: none;
            }
        }
    }

    &__not_in_store_wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        #{$root}__not_in_store_container {
            display: flex;
            flex-direction: column;
            flex-grow: 4;
            align-items: center;
            justify-content: center;
            padding: 0 4rem;
            text-align: center;
            background-color: $color-white;
            box-shadow: 0.2rem 0.4rem 0.4rem rgba($color-black, 17%); // TODO: Use box-shadow var

            #{$root}__not_in_store_title {
                color: $color-primary;
                font-size: $font-size-m;
                font-weight: $font-weight-bold;
            }

            #{$root}__not_in_store_text {
                color: $color-disabled-dark;
                font-size: $font-size-xs;
            }

            #{$root}__not_in_store_phone {
                margin-top: 0.5rem;
                color: $color-secondary;
                font-size: $font-size-default;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__check_stock_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 1.1rem;

        #{$root}__switch_icon {
            display: inline-block;
            @include lib-background-image(
                'svg/_clean/white/arrows-left-right.svg',
                center,
                no-repeat,
                transparent,
                false
            );
            background-size: 1.2rem;
            min-width: 1.2rem;
            min-height: 1.2rem;
        }

        #{$root}__stock_link {
            font-size: $font-size-s;
            color: $color-white;
            text-decoration: underline;
            letter-spacing: 0.06rem;
        }

        &:hover {
            #{$root}__switch_icon {
                fill: $color-secondary;
            }

            #{$root}__stock_link {
                text-decoration: none;
            }
        }

        &:active {
            #{$root}__switch_icon {
                fill: $color-secondary;
            }

            #{$root}__stock_link {
                color: $color-secondary;
                text-decoration: underline;
            }
        }
    }
}
