// variables
$icon-size: 2.4rem;
$icon-xxxs-size: 1.2rem;
$icon-xxs-size: 1.5rem;
$icon-xs-size: 1.6rem;
$icon-sm-size: 2.2rem;
$icon-md-size: $icon-size;
$icon-lg-size: 3rem;
$icon-xl-size: 5rem;

%icon {
    display: inline-flex;
    width: $icon-size;
    height: $icon-size;
    color: currentColor;
    fill: currentColor; // for svg file
    font-size: $icon-size;

    // size declination
    &[data-ui-icon-size='xxxs'] {
        width: $icon-xxxs-size;
        height: $icon-xxxs-size;
        font-size: $icon-xxxs-size;
    }

    &[data-ui-icon-size='xxs'] {
        width: $icon-xxs-size;
        height: $icon-xxs-size;
        font-size: $icon-xxs-size;
    }

    &[data-ui-icon-size='xs'] {
        width: $icon-xs-size;
        height: $icon-xs-size;
        font-size: $icon-xs-size;
    }

    &[data-ui-icon-size='sm'] {
        width: $icon-sm-size;
        height: $icon-sm-size;
        font-size: $icon-sm-size;
    }

    &[data-ui-icon-size='md'] {
        width: $icon-md-size;
        height: $icon-md-size;
        font-size: $icon-md-size;
    }

    &[data-ui-icon-size='lg'] {
        width: $icon-lg-size;
        height: $icon-lg-size;
        font-size: $icon-lg-size;
    }

    &[data-ui-icon-size='xl'] {
        width: $icon-xl-size;
        height: $icon-xl-size;
        font-size: $icon-xl-size;
    }
}

// import all components
@import 'icon/icon';
