@import 'address_container';

.#{$css-namespace}order-address-area {
    $root: &;

    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    border: 0.1rem solid $color-disabled-light;
    padding: 1.5rem 2rem 0 $space-5;

    @include min-screen($screen__l) {
        padding: 0;
    }

    & ~ & {
        margin-top: 1.5rem;

        @include min-screen($screen__l) {
            margin-top: 0;
        }
    }

    &_title {
        font-family: $font-secondary;
        font-size: $font-size-xl;
        font-weight: $font-weight-semi-bold;
        line-height: 2;
        padding: 0;
        color: $color-primary;

        @include min-screen($screen__l) {
            padding: 1.5rem 4rem 0.45rem;
        }
    }

    &[data-delivery-mode='online'] {
        .#{$css-namespace}order-address--delivery .#{$css-namespace}order-address_name {
            color: $color-checkout-delivery-online;
        }
    }

    &[data-delivery-mode='pickup'] {
        .#{$css-namespace}order-address--delivery .#{$css-namespace}order-address_name {
            color: $color-checkout-delivery-pickup;
        }
    }

    .#{$css-namespace}order-address {
        @include min-screen($screen__l) {
            margin-left: 2rem;
        }
    }
}
