#password-error,
#password-confirmation-error {
    font-size: $font-size-default;
}

.box.box-billing-address,
.box.box-shipping-address,
.box.box-address-billing,
.box.box-address-shipping,
.items.addresses > li.item {
    padding: 1rem;
    width: calc(48% - 2.2rem) !important;
}

.items.addresses > li.item:nth-child(odd) {
    margin-left: 0 !important;
}

.useraccount-account-resetpassword,
.useraccount-account-confirmation,
.useraccount-account-forgotpasswordexpired {
    .page-wrapper {
        .page-main {
            padding: 0 3rem;
        }
    }

    .message {
        padding: 1rem 2rem;

        > *:first-child:before {
            content: '';
        }
    }

    .page.messages {
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
    }

    .reset-password-logo {
        text-align: center;

        img {
            height: 12rem;
            width: auto;
            -webkit-transform: scale(0.8); /* Saf3.1+, Chrome */
            -moz-transform: scale(0.8); /* FF3.5+ */
            -ms-transform: scale(0.8); /* IE9 */
            -o-transform: scale(0.8); /* Opera 10.5+ */
            transform: scale(0.8);
        }
    }

    .msg-header {
        text-align: center;
        width: 80%;
        margin: 3rem auto;
    }

    .page-main .column.main .reset-password-container {
        h1.page-title {
            color: $color-gray-light7;
        }

        .message-success,
        .message-error {
            padding: $space-2;
            background: rgba($color-success-secondary, 0.1);
            border: 0.1rem solid $color-success-secondary;
            border-radius: 0.3rem;
            margin: $space-2 auto;

            p {
                margin: 0;
                font-size: $font-size-default;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            i {
                margin-right: $space-2;
                font-size: 1.6rem;
                color: $color-success-secondary;
            }

            &.message-error {
                border: 0.1rem solid $color-error-link;
                background: rgba($color-error-link, 0.05);

                i {
                    color: $color-error-link;
                    font-size: $font-size-xl;
                }
            }
        }

        .page-title-wrapper,
        .reset-password-form-container {
            margin: 0 16%;
        }

        .form .actions-toolbar .primary {
            width: 100%;

            .action.primary.submit {
                background: $color-main-dark;
                border: 0.1rem solid $color-main-dark;
                width: 100%;

                &:hover {
                    background: $color-main-dark;
                    border: 0.1rem solid $color-main-dark;
                }

                &.disable {
                    background: $mainNeutralColor;
                    color: $color-dark-grey;
                    border: 0.1rem solid $mainNeutralColor;

                    &:hover {
                        background: $mainNeutralColor;
                        color: $color-dark-grey;
                    }
                }
            }
        }

        .action.dark {
            background: $color-main-dark;
            border: 0.1rem solid $color-main-dark;
            width: 100%;

            &:hover {
                background: $color-main-dark;
                border: 0.1rem solid $color-main-dark;
            }
        }
    }

    .reset-password-logos {
        margin: $space-10 auto 0;
    }

    .reset-password-logos,
    .reset-password-confirmation-logos {
        text-align: center;

        img {
            height: 8rem;
            width: auto;
            -webkit-transform: scale(0.8); /* Saf3.1+, Chrome */
            -moz-transform: scale(0.8); /* FF3.5+ */
            -ms-transform: scale(0.8); /* IE9 */
            -o-transform: scale(0.8); /* Opera 10.5+ */
            transform: scale(0.8);
        }
    }

    .reset-password-container-footer {
        text-align: center;
        background: $color-white;
        padding: $space-4 $space-6;

        .reset-password-reassurance {
            font-size: $font-size-s;
            margin-bottom: 0;
        }

        .forgot-password-footer-logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: $space-1;
            column-gap: $space-1;

            div {
                height: $space-3;

                > img {
                    max-height: 100%;
                    width: auto;
                }
            }

            @include min-screen($screen__m) {
                display: none;
            }
        }
    }

    .reset-password-logo-container {
        display: inline;

        img[alt*='prolians-logo'] {
            width: 15.3rem;
            height: 3rem;
        }

        img[alt*='dexis-logo'] {
            width: 11rem;
            height: 3rem;
        }

        img[alt*='hydralians-logo'] {
            width: 19.1rem;
            height: 3rem;
        }
    }

    .logo-link-container {
        background: $color-white;
        border-radius: 0.4rem;
        padding: 1rem 2rem;
        max-width: 100%;
        margin: $space-2 auto;

        img[alt*='prolians-logo'],
        img[alt*='hydralians-logo'] {
            height: 2.4rem;
            vertical-align: text-top;
        }

        img[alt*='dexis-logo'] {
            width: 11rem;
            height: 3rem;
            vertical-align: text-top;
        }
    }

    .page-wrapper {
        padding-top: 0 !important;

        .page-main {
            padding: 0;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .columns {
                padding-bottom: 0;
                flex-grow: 1;
            }

            .reset-password-container {
                max-width: 40rem;
                margin: 0 auto;
                padding: $space-4 0;

                @include min-screen($screen__m) {
                    padding: $space-7 0;
                }

                .content-msg {
                    font-size: $font-size-m;
                }

                .input-text {
                    height: 4.8rem;
                    border: 0.1rem solid $color-grey-light3;
                    padding: 0 $space-2;
                    margin-bottom: $space-2;
                    line-height: 1;

                    ~ .label {
                        top: $space-2;
                        background-color: $color-white;
                        left: $space-2;
                        color: $color-dark-grey;
                        transition: all 0.15s ease-out; // TODO : Uniformise using var(--transition-normal)
                    }

                    &:focus,
                    &:not([value='']),
                    &:not(:empty) {
                        border-color: $color-primary;

                        ~ .label {
                            color: $color-primary;
                            left: $space-2;
                            top: -0.75 * $font-size-s;
                            font-size: $font-size-s;

                            span {
                                padding: 0 #{0.5 * $space-1};
                            }
                        }
                    }
                }

                .password-legend {
                    margin-bottom: $space-1;
                    font-size: $font-size-s;
                    font-weight: $font-weight-medium;
                }

                .password-info {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: $space-2;
                    margin-top: $space-1;
                    color: #515151; // TODO: Use global var
                    font-size: $font-size-s;
                    line-height: 1.5;

                    [data-rule] {
                        display: flex;
                        align-items: center;

                        &.is-valid {
                            color: $color-success;
                            font-weight: $font-weight-semi-bold;
                        }

                        .dcfonts-icons {
                            margin-right: $space-1;
                        }

                        strong {
                            font-weight: $font-weight-black;
                        }
                    }

                    .full {
                        grid-column: span 2;
                    }
                }

                .action.submit.disable {
                    opacity: 1;
                }

                .reset-password-form-container,
                .page-title-wrapper {
                    margin: 0 !important;

                    .page-title {
                        font-size: $font-size-xxl;
                        line-height: 3.5rem;
                        font-family: $font-secondary;
                        font-weight: $font-weight-bold;

                        span {
                            display: block;
                            font-size: $font-size-m;
                        }
                    }

                    .form.password.reset {
                        min-width: 100% !important;
                    }
                }

                .fieldset {
                    margin-bottom: $space-2;
                }

                .action.submit {
                    height: 4.4rem;
                    line-height: 1;
                    padding: 0 $space-2;
                }
            }

            .reset-password-logo {
                background: $color-white;
                height: $space-12;
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;
                gap: $space-3;

                > div {
                    width: fit-content;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        height: 2.2rem;
                        width: auto;
                        transform: none;
                        display: block;
                    }
                }
            }
        }
    }
}
