.quotationcheckout-checkout-overview {
    .#{$css-namespace}progress-bar_container {
        @include max-screen($screen__l) {
            justify-content: center;
        }
    }

    .#{$css-namespace}quotation_content {
        .quotation {
            max-width: calc(38.7rem + 2.5rem);
            margin-left: 0;
            margin-bottom: $space-1 / 2;
        }
    }

    .#{$css-namespace}loyalty_subscription_info .dcfonts-icons {
        align-self: baseline;
    }

    .#{$css-namespace}summary-container {
        margin-top: 0 !important;
    }

    .cart-wrapper-click-and-collect {
        padding: 0 0.5rem;

        @include min-screen($screen__l) {
            padding: 0;
        }
    }

    .cart-wrapper-container {
        @include max-screen($screen__l) {
            padding: 0 1rem;
        }
    }

    .checkout__products {
        margin-bottom: 3rem;

        .cart-wrapper {
            padding: 0;
        }
    }

    .checkout__addresses {
        margin: 2rem 0;
    }

    .#{$css-namespace}quotation_disabledProduct {
        @include max-screen($screen__l) {
            padding: 0 1rem;
        }
    }

    .cart-wrapper_header {
        padding: 0;
    }

    .#{$css-namespace}loyalty_subscription_info {
        font-weight: $font-weight-regular;
        margin: 0;
        padding: 1rem;
    }

    .checkout__item {
        border: none;
        padding: 0;

        .checkout__shipping-more-infos + .checkout__shipping-more-infos {
            border-top: 0.1rem solid $color-gray83;
            padding-top: 2rem;
        }
    }

    .checkout__shipping-more-infos {
        .#{$css-namespace}form-radio-group {
            display: flex;
            gap: 3rem;
        }
    }

    .#{$css-namespace}quotation_disabledProduct {
        margin-top: 2rem;

        &-border {
            border-bottom: 0.1rem solid $color-gray83;
            padding-bottom: 2rem;

            @include min-screen($screen__l) {
                padding-bottom: 3rem;
            }
        }

        &-text {
            font-weight: $font-weight__bold;
            color: $color-red-promo;
            margin-bottom: 0.5rem;
        }
    }

    @include min-screen($screen__l) {
        .#{$css-namespace}progress-bar_item:last-child {
            .#{$css-namespace}progress-bar_item-label {
                white-space: nowrap;
                margin-left: calc(50% + 2.6rem);
            }
        }
    }

    .#{$css-namespace}opc-wrapper {
        @include min-screen($screen__l) {
            flex-wrap: wrap;
        }
    }

    .checkout__button {
        width: 100%;
        color: $color-secondary;
        margin: 0 0.5rem 2rem 0.5rem;

        @include min-screen($screen__l) {
            margin: 0 0 1.5rem;
        }

        .checkout__back-btn {
            display: inline-flex;
        }
    }

    .step-content {
        margin-bottom: 0;
        @include max-screen($screen__l) {
            padding: 0 1rem;
        }
    }
}

.quotationcheckout-checkout-result {
    .#{$css-namespace}title--campton {
        font-size: 2rem !important;
        font-weight: $font-weight__semibold !important;
        font-style: italic !important;
        font-family: $font-secondary !important;
        color: $color-primary !important;
    }
}
