// abstract list
%list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column wrap;
    list-style: none;

    > li {
        margin: 0; // override Magento-UI rule
    }
}

// abstract vertical list
%list-block {
    @extend %list;

    > li {
        flex: 0 0 auto;
        display: block;

        + li {
            margin-top: $space-1;
        }
    }
}

// abstract inline list + multiline
%list-inline {
    @extend %list;
    flex-direction: row; // conflict with old style
}

%list-ordered {
    counter-reset: skew-ol-counter;
    list-style: none;
    padding-left: $space-4;
    display: flex;
    flex-direction: column;
    gap: $space-2;

    li {
        margin: 0;
        counter-increment: skew-ol-counter;
        position: relative;

        &::before {
            content: counter(skew-ol-counter);
            color: $color-white;
            font-size: $font-size-xs-l;
            position: absolute;
            left: -$space-4;
            top: -0.2rem;
            line-height: 2.2rem;
            width: 2rem;
            height: 2rem;
            background: $color-secondary;
            border-radius: 50%;
            text-align: center;
        }
    }
}

//TODO : Add ol counter class & Check usability of app/design/frontend/Descours/*/styles/cms/lists/_lists.scss in all themes
@import 'list/list';
@import 'list/list-dl';
@import 'list/steps';
