.modal-popup.#{$css-namespace}modal {
    &_shipping {
        .modal-inner-wrap {
            .form-shipping-address {
                max-width: none;

                &::before {
                    content: attr(data-hasrequired);
                    font-size: $font-size-s;
                    font-style: italic;
                    margin-bottom: 2.7rem;
                    display: block;
                    font-weight: $font-weight-light;
                }
            }

            .fieldset {
                legend {
                    display: none;
                }

                .field {
                    margin-bottom: 3.7rem;
                    width: 100%;

                    &.street {
                        margin-bottom: 0;
                    }

                    &.postcode {
                        width: 10rem;

                        &:has(+ .field:not(._error)) {
                            .field-error:not(:empty),
                            div.mage-error:not(:empty) {
                                width: max-content;
                            }
                        }
                    }

                    &.city {
                        width: calc(100% - 10rem - 1.5rem);
                        position: relative;
                    }

                    &.country-select,
                    &.region-select {
                        select {
                            padding: 1.2rem 1.5rem 1rem;
                        }

                        .label {
                            left: $space-1;
                            top: -0.7rem;
                            padding: 0 $indent__xs;
                            font-family: inherit;
                            font-size: $font-size-s;
                            background: $color-white;
                            color: $color-primary;
                        }
                    }

                    &.choice {
                        margin-bottom: 0;

                        &::before,
                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            border-top: 0.2rem dotted $color-disabled-light;
                        }

                        &::before {
                            margin: 0 0 $indent__base;
                            padding-top: 0;
                        }

                        &::after {
                            margin: $indent__base 0 0;

                            &:last-child {
                                border-bottom: 0.2rem dotted $color-disabled-light;
                            }
                        }
                    }

                    [aria-required='true'] {
                        // Todo uniformiser
                        ~ .label {
                            span {
                                &::after {
                                    content: '*';
                                }
                            }
                        }
                    }
                }
            }
        }

        .fieldset {
            > .field {
                &.default-address {
                    margin-bottom: 0;
                    order: 99;

                    .control {
                        .choice.field {
                            &::before {
                                border: 0.1rem solid transparent;
                            }
                        }

                        .label {
                            top: 50%;
                            transform: translateY(-50%);
                            color: inherit;

                            @include min-screen($screen__l) {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                &.default-address,
                &.choice {
                    display: none;

                    .label {
                        margin-left: 0.5rem;
                    }
                }
            }
        }

        .field {
            &:not(.default-address):not(.choice) {
                .label {
                    left: 1rem;
                    font-size: 1.2rem !important;
                    color: $color-main-dark;
                    padding: 0 #{$space-1 / 2};
                    background: $color-white;
                }
            }
        }

        .nice-select:after {
            border-bottom: 0.2rem solid $color-primary;
            border-right: 0.2rem solid $color-primary;
        }

        .#{$css-namespace}select--custom.nice-select span {
            opacity: 1;
            color: $color-black;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .warning {
            background: none;
            color: $color-error;
            padding: 0;
            width: 390%;
            margin-bottom: 0;

            span {
                &::before {
                    content: none;
                }
            }
        }

        .form-switcher {
            &-single {
                flex: 1;

                label {
                    font-weight: $font-weight-regular !important; //component override
                }
            }
        }

        &-update-address {
            .modal-inner-wrap {
                .fieldset {
                    .field {
                        &.choice {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .change-shipping-address {
            &-item {
                position: relative;
                margin-top: 2rem;
            }

            &-action {
                position: absolute;
                top: 0;
                left: 0;
                width: 0.1rem;
                height: 0.1rem;
                opacity: 0;
                visibility: hidden;
                padding: 0;
                border: none;
            }
        }
    }

    .shipping-new-address-form,
    .form-edit-shipping-address .fieldset {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
}
