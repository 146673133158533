.#{$css-namespace}connection-insert {
    margin: $space-2;
    padding: $space-3 $space-2;
    background-color: rgba($color-secondary, 0.1); // TODO: Use global var
    border-radius: 0.3rem;

    @include min-screen($screen__l) {
        margin: $space-4 0; // To fit with gap in homepage sliders
        padding: $space-4 $space-5;
    }

    .#{$css-namespace}title {
        line-height: 3.5rem;
        font-size: $font-size-xxl;

        @include min-screen($screen__l) {
            line-height: 5.5rem;
            font-size: $font-size-xxxl;
        }

        &-section {
            line-height: 3rem;
            font-size: 2.5rem;

            @include min-screen($screen__l) {
                line-height: 3.5rem;
                font-size: $font-size-xxl;
            }
        }
    }

    &__content,
    &__actions {
        display: flex;
        align-items: center;
        gap: $space-2;
    }

    &__content {
        margin-top: $space-1 / 2;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__actions {
        .#{$css-namespace}btn {
            // TODO : Refacto needed useless ?
            min-height: auto;
            max-height: 3.8rem;
        }
    }
}
