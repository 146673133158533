$promo-text-wrapper-spacing: 2rem !default;

.#{$css-namespace}wishlist_products-section {
    display: none;

    &.is-active {
        display: block;

        &.#{$css-namespace}wishlist_products-section--quickorder.#{$css-namespace}wishlist_products-section--is-empty {
            display: none;
        }
    }

    .products-list-mention {
        margin-bottom: 0;
    }
}

.has-quantity-promo {
    td {
        vertical-align: top;
    }

    .#{$css-namespace}item-table_row-qty:not(.simple) {
        .quantity-picker-container {
            .#{$css-namespace}form-quantity-picker_input-wrapper {
                @include min-screen($screen-md) {
                    padding-top: 1rem;
                }
            }

            .#{$css-namespace}form-quantity-picker_update-link {
                position: relative;
            }

            .promo-tooltip {
                margin-left: 2rem;
            }
        }
    }
}

.#{$css-namespace}wishlist_products-section_order-selector {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: $space-3;
    margin-top: $space-2;

    @include max-screen($screen-sm) {
        flex-direction: column;
        align-items: stretch;
        flex: 1 0;
    }
}

.#{$css-namespace}wishlist_products-section_selectors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .#{$css-namespace}wishlist-add-all-cart-container {
        @include min-screen($screen__l) {
            margin-top: $space-2;
        }
    }
}

.#{$css-namespace}wishlist_products-section_order-selector_store-location {
    padding: 0 2rem;
    margin-top: $space-2;

    @include min-screen($screen__s) {
        margin-top: 0;
    }

    @include max-screen($screen-sm-2) {
        text-align: center;
    }

    .dcfonts-icons {
        margin-right: $space-1 / 2;
    }
}

.#{$css-namespace}wishlist_products-section_order-selector_text {
    font-weight: $font-weight-bold;
    margin-right: 1rem;
}

.#{$css-namespace}wishlist_products-section_order-selector_radio-buttons {
    // TODO : Refacto in app/design/frontend/Descours_v2/Descours/styles/toolkit/common/01_components/forms/radio-button/_radio-button.scss & app/code/Descours/Wishlist/view/frontend/templates/button/list-selector.phtml:20
    display: flex;

    @include max-screen($screen-sm-2) {
        flex: 1 0;
    }

    > .#{$css-namespace}form-radio-button-group {
        @include max-screen($screen-sm-2) {
            flex: 1 0;
        }

        > .#{$css-namespace}form-field-wrapper {
            @include max-screen($screen-sm-2) {
                display: inline-flex;
                flex: 1 0;
            }

            > label {
                @include max-screen($screen-sm-2) {
                    flex: 1 0;
                }
            }
        }
    }
}

.qty--text-only {
    min-width: 3.2rem;
    min-height: 3.2rem;
    line-height: 3.2rem;
    background-color: $color-grey-background;
    text-align: center;
    display: inline-block;
    border-radius: 0.3rem;
    color: $color-grey-dark2;
    font-weight: $font-weight-medium;
    padding: 0 $space-1 / 2;

    .disable-row & {
        opacity: 0.5;
        filter: grayscale(1);
    }
}

.#{$css-namespace}products-section_products-table {
    td {
        @include max-screen($screen-sm) {
            display: block;
        }

        &.hidden {
            display: none;
        }
    }

    .#{$css-namespace}order-items {
        th.col.qty {
            min-width: $quantity-picker-width;
        }

        th.col.availability {
            text-align: left;
        }

        .#{$css-namespace}item-table_header-row > th:first-child {
            width: 27%;
            padding-left: $space-2;
        }

        tr.disabled,
        tr.disable-row {
            .#{$css-namespace}item-table_row-item_subtotal {
                color: $mainNeutralColor;
            }

            .#{$css-namespace}item-table_row-item_price-unit {
                color: $mainNeutralColor;
            }
        }

        .#{$css-namespace}item-table_row {
            height: auto;

            @include min-screen($screen__m) {
                min-height: 10rem;
            }

            &::after {
                display: none;
            }

            &:has(.tooltip-text):hover {
                z-index: 3;
            }

            &-promo-badge {
                + .#{$css-namespace}item-table_row {
                    .#{$css-namespace}item-table_row-delete {
                        top: -2.8rem;
                    }
                }
            }

            .has-quantity-promo {
                height: inherit;

                @include max-screen($screen__m) {
                    min-height: 36rem;
                }

                .#{$css-namespace}item-table_row-item {
                    vertical-align: top;
                }

                .#{$css-namespace}item-table_row-availability {
                    vertical-align: top;
                }

                .quantity-picker-container {
                    padding-top: $space-3;
                    min-height: 16.7rem;

                    @include max-screen($screen__m) {
                        min-height: 35.7rem;
                        padding-top: 0;
                    }

                    .#{$css-namespace}form-quantity-picker_update-link {
                        position: inherit;
                    }
                }

                .#{$css-namespace}item-table_row-price {
                    vertical-align: top;

                    .#{$css-namespace}item-table_row-price_ctn {
                        @include min-screen($screen__l) {
                            margin-top: 2rem;
                        }
                    }
                }

                .table_row-add-to-cart-container {
                    padding-top: 1rem;
                }
            }

            &.has-promo-price-level-displayed {
                @include min-screen($screen-sm) {
                    border-bottom: none;
                }

                &:not(.d-flex) {
                    .#{$css-namespace}item-table_row-promo-text {
                        &-wrapper {
                            padding-bottom: 0;
                            display: block;

                            @include min-screen($screen-sm) {
                                display: none;
                            }
                        }

                        &-content {
                            display: block;
                        }
                    }
                }

                + .#{$css-namespace}item-table_row-promo-text {
                    display: none;

                    @include min-screen($screen-sm) {
                        display: table-row;
                        border: 0.1rem solid $color-grey-argent;
                        border-top: none;
                    }

                    .#{$css-namespace}item-table_row-promo-text {
                        &-wrapper {
                            text-align: right;
                        }

                        &-content {
                            display: inline-block;
                            max-width: none;
                            text-align: center;

                            @include min-screen($screen-sm) {
                                max-width: calc(100% - #{$space-8});
                            }
                        }
                    }
                }
            }

            &.no-js-add-quantity-promo-class:not(.has-promo-price-level-displayed) {
                .#{$css-namespace}item-table_row-promo-text-wrapper,
                + .#{$css-namespace}item-table_row-promo-text {
                    display: none;
                }
            }

            &-item-container {
                width: 100%;
            }

            td {
                &:last-of-type.#{$css-namespace}item-table_row-price {
                    text-align: right;
                    padding-right: $space-2 !important;
                }
            }

            &_error {
                border-width: 0 0.1rem;
                border-color: $color-grey-background;

                &_content {
                    min-height: 10rem;
                    background: $color-grey-background;
                    font-style: italic;
                    padding: $indent__base;
                    vertical-align: middle;

                    @include min-screen($screen-lg) {
                        padding: $indent__s 2.8rem;
                    }
                }
            }

            .#{$css-namespace}item-table_row-item {
                display: flex;
                align-items: center;
                padding: $space-2 $space-1;

                @include max-screen($screen-sm) {
                    padding: $space-1 $space-2;
                    width: 100%;
                    max-height: none;
                    margin-bottom: 0;
                }

                &_specific {
                    @include min-screen($screen-sm) {
                        min-width: 55rem;
                    }

                    .#{$css-namespace}item-table_row-details {
                        max-width: 100%;
                    }

                    .#{$css-namespace}item-table_row-item_title {
                        max-width: 24rem;
                    }
                }

                .#{$css-namespace}item-table_row-details {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    position: static;

                    @include min-screen($screen-sm) {
                        padding-left: $space-2;
                    }
                }

                .#{$css-namespace}item-table_row-picto {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 10.6rem;
                    width: 10.6rem;
                    overflow: hidden;
                    padding: 0;
                    margin-left: 0;

                    @include min-screen($screen-sm) {
                        height: 7.5rem;
                        width: 7.5rem;
                    }

                    img {
                        @include min-screen($screen-sm) {
                            max-width: none;
                            max-height: none;
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    @include max-screen($screen-sm) {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }

                    .dcfonts-icons {
                        font-size: 4rem;
                        margin-right: auto;
                        padding: 1rem;
                        color: $color-primary;

                        @include max-screen($screen-sm) {
                            padding: 2.3rem 1rem 4rem;
                        }
                    }
                }
            }

            .#{$css-namespace}item-table_row-availability {
                text-align: left;
                color: $color-dark-grey;

                p.small {
                    display: flex;
                    margin-bottom: 0;
                }

                .#{$css-namespace}round_light {
                    margin-right: $space-1 / 2;
                    margin-top: 0.3rem;
                }

                .#{$css-namespace}item-table_row-availability_cross {
                    margin-right: 0.6rem;
                    display: inline-block;
                    font-size: $font-size-xs;
                    color: $color-red;
                    margin-top: -$space-1 / 2;
                }
            }

            .#{$css-namespace}item-table_row-qty {
                &.-empty {
                    display: none !important;

                    @include min-screen($screen-sm) {
                        display: table-cell !important;
                    }
                }
            }

            .#{$css-namespace}item-table_row-qty--hidden {
                float: none;
                display: inline-block;
                vertical-align: middle;

                @include min-screen($screen__l) {
                    display: none;
                }
            }

            .#{$css-namespace}item-table_row-qty--text-only {
                @extend .qty--text-only;

                &:only-child {
                    @include max-screen($screen-md) {
                        min-width: $quantity-picker-width;
                    }
                }
            }

            .#{$css-namespace}item-table_row-qty:not(.simple) {
                position: relative;
                padding: 0;
                width: $quantity-picker-width;

                @include min-screen($screen__l) {
                    min-height: 10rem;
                    width: auto;
                }

                @include max-screen($screen-sm) {
                    bottom: auto;
                    left: auto;
                    display: inline-block;
                    padding: 0 1.2rem;
                    margin: 0;
                    min-height: 4rem;
                    width: 15.2rem;
                }

                &.align-top {
                    vertical-align: top;

                    @include min-screen($screen-sm) {
                        padding-top: $space-2;
                    }
                }

                .#{$css-namespace}form-quantity-picker_update-link {
                    @include max-screen(64rem) {
                        padding-top: 0.7rem;
                    }

                    @include max-screen(60rem) {
                        padding-top: 0;
                    }

                    @include max-screen(58.5rem) {
                        padding-top: 1rem;
                    }

                    @include max-screen($screen-sm) {
                        width: $quantity-picker-width;
                    }
                }

                .#{$css-namespace}form-quantity-picker_input-wrapper {
                    justify-content: center;
                }

                @include max-screen($screen-lg) {
                    padding-left: 0;
                    padding-right: 0;
                }

                @include max-screen($screen-sm) {
                    padding-left: $space-2;
                    padding-right: 1.2rem;
                }
            }

            .#{$css-namespace}item-table_row-add-to-cart {
                padding: $space-2;
                width: 100%;

                .table_row-add-to-cart-container {
                    display: flex;
                    flex-direction: column;
                    gap: $space-2;
                }

                @include max-screen($screen-sm) {
                    display: block;
                    order: 1;
                }

                .#{$css-namespace}btn {
                    // TODO : Refacto needed uniformisation
                    @include max-screen($screen-sm) {
                        padding-left: 1.2rem;
                        padding-right: 1.2rem;
                    }

                    @include max-screen($screen-md) {
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                    }
                }

                .#{$css-namespace}btn.is-disabled {
                    @extend .#{$css-namespace}btn[disabled] !optional;
                }
            }

            &:not(.d-flex) .#{$css-namespace}item-table_row-price {
                .sales-order_view-weborder &,
                .quotation-create-index & {
                    text-align: right;
                }

                @include max-screen($screen-sm) {
                    text-align: left;
                    position: relative;
                    right: auto;
                    top: auto;
                    padding: 0.5rem 2rem $space-1 0;
                    max-width: calc(100% - #{$quantity-picker-width});
                    flex: 1;

                    .checkout__products-wrapper & {
                        position: relative;
                    }
                }

                .#{$css-namespace}item-table_row-price_promo {
                    background-color: $color-red;
                    border-radius: 0.2rem;
                    font-size: $font-size-xs;
                    color: $color-white;
                    line-height: 1.5rem;
                    padding: 0 0.2rem;
                    margin-bottom: 0.5rem;
                    display: inline-block;
                }

                &.has_promo,
                &.has_promo_price_level {
                    .#{$css-namespace}item-table_row-item_subtotal {
                        color: $color-red;
                    }
                }

                .#{$css-namespace}item-table_row-price_ctn {
                    text-align: right;

                    @include min-screen($screen-sm) {
                        display: inline-block;

                        .sales-order_view-weborder &,
                        .quotation-create-index & {
                            text-align: right;
                        }
                    }
                }
            }

            .#{$css-namespace}item-table_row-price {
                .#{$css-namespace}item-table_row-price_ctn {
                    .sales-order_view-weborder &,
                    .quotation-create-index & {
                        text-align: right;
                    }
                }

                &.has_promo {
                    color: $color-main-dark;
                }

                &.has_promo,
                &.has_promo_price_level {
                    .#{$css-namespace}item-table_row-item_subtotal {
                        color: $color-red;
                    }
                }
            }

            .#{$css-namespace}item-table_row-item_delete,
            .#{$css-namespace}item-table_row-delete {
                @include max-screen($screen-sm) {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: $space-1;

                    i {
                        margin: 0;
                    }
                }
            }

            .#{$css-namespace}item-table_row-actions,
            .#{$css-namespace}item-table_row-delete,
            .#{$css-namespace}item-table_row-equivalent-products {
                line-height: 1.4rem;

                @include min-screen($screen-sm) {
                    padding-top: 0.5rem;

                    &:only-child {
                        padding-top: 0;
                    }
                }

                > * + * {
                    @include min-screen($screen-sm) {
                        margin-left: 2rem;
                    }
                }

                .#{$css-namespace}link {
                    line-height: 1.4rem;
                }

                .dcfonts-icons {
                    color: $color-primary;
                    font-size: $font-size-m;
                }

                a.#{$css-namespace}link,
                button.#{$css-namespace}link {
                    @include max-screen($screen-sm) {
                        text-decoration: none;
                    }

                    .#{$css-namespace}item-table_row-delete_icon {
                        text-decoration: none;
                        font-size: 2.4rem;
                        display: none;

                        @include max-screen($screen-sm) {
                            display: block;
                        }
                    }

                    .#{$css-namespace}item-table_row-delete_text {
                        @include max-screen($screen-sm) {
                            display: none;
                        }
                    }
                }
            }

            .#{$css-namespace}item-table_row-equivalent-products {
                width: auto;
                font-size: $font-size-s;

                @include max-screen($screen-md) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: $font-size-default;
                }

                @include min-screen($screen-sm) {
                    padding-top: 0;
                }

                &_text {
                    font-weight: $font-weight-bold;
                }

                &:empty {
                    display: none;
                }

                .#{$css-namespace}link {
                    @include min-screen($screen-sm) {
                        justify-content: flex-end;
                    }
                }
            }

            .#{$css-namespace}item-table_row-availability {
                @include max-screen($screen-sm) {
                    display: block;
                    padding-bottom: 0;
                    margin-bottom: 1.5rem;
                    width: 100%;
                }
            }
        }

        .#{$css-namespace}item-category_row {
            @include max-screen($screen-sm) {
                display: block;
                border-bottom: 0.1rem solid $color-disabled-light;
            }

            .#{$css-namespace}item-category_row-name {
                color: $color-primary;
                font-weight: $font-weight-bold;
                font-size: $font-size-default;
                padding-top: $indent__base;
                padding-bottom: 0.5rem;
                padding-left: 0;
            }
        }
    }
}

.useraccount-quickorder-index {
    .#{$css-namespace}form-quantity-picker_update-link {
        @include max-screen(69.4rem) {
            padding-top: 1.2rem;
        }

        @include max-screen(61.4rem) {
            padding-top: 1.2rem;
        }
    }
}

.#{$css-namespace}quick-order_products-section_total {
    display: flex;
    grid-gap: 1rem;
    text-align: right;
    padding: 2rem 0;
    justify-content: flex-end;
    width: 100%;

    @include max-screen($screen-sm) {
        text-align: left;
    }

    > span {
        display: flex;
        font-family: $font-secondary;
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        color: $color-primary;

        @include min-screen($screen-sm) {
            font-size: $font-size-xxl;
        }
    }
}

.#{$css-namespace}wishlist_products-section_bottom-actions {
    display: flex;
    grid-gap: 1rem;
    justify-content: flex-end;

    @include max-screen($screen-sm) {
        flex-direction: column-reverse;
    }

    .is-hidden {
        display: none;
    }

    &_add-to-cart-content,
    &-add-to-quotation-content {
        @include max-screen($screen-sm) {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 1.2rem 0.7rem 1.5rem !important;
            width: 100%;
            background: $color-white !important;
            z-index: 2;
        }
    }
}

.#{$css-namespace}wishlist_products-section_bottom-actions_left-ctn {
    flex: 1;
    display: flex;

    &.--desktop {
        display: flex;
        @include max-screen($screen-sm) {
            display: none;
        }
    }

    &.--mobile {
        display: none;
        @include max-screen($screen-sm) {
            display: flex;
            margin-top: 2rem;
        }
    }
}

.#{$css-namespace}wishlist_products-section_bottom-actions_clear-list.#{$css-namespace}link {
    text-decoration: none;

    span {
        text-decoration: underline;
    }

    &:hover {
        span {
            text-decoration: none;
        }
    }

    .dcfonts-icons {
        font-size: 1.8rem;
    }

    @include max-screen($screen-sm) {
        display: block;
        text-align: center;
        width: 100%;
    }
}

.#{$css-namespace}wishlist_products-section_bottom-actions_add-to-cart {
    @include max-screen($screen__l) {
        display: none;
    }
}

.#{$css-namespace}wishlist_products-section_bottom-actions_add-to-wishlist,
.#{$css-namespace}wishlist_products-section_bottom-actions_add-to-cart {
    @include min-screen($screen-md) {
        min-width: 27.5rem;
    }

    @include max-screen($screen-lg) {
        width: auto;
    }

    @include max-screen($screen-sm) {
        display: block;
        width: 100%;
    }
}

.#{$css-namespace}wishlist_products-section_category-selector {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: $space-2;

    .wishlist-top_achat-index & {
        flex: 1;

        @include max-screen($screen-sm) {
            flex-shrink: 0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .fieldset {
        width: 23rem;
        padding-bottom: 1.5rem;

        @include max-screen($screen-sm) {
            width: 100%;
        }

        .wishlist-top_achat-index & {
            padding-bottom: 0;
        }

        .field.whishlist-category {
            margin-bottom: 0.5rem;
            position: relative;

            .#{$css-namespace}select--custom {
                span.current {
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @include min-screen($screen__l) {
                        width: 23rem;
                    }
                }

                .list {
                    overflow: auto;
                }
            }
        }

        .#{$css-namespace}wishlist_products-section_category-selector_delete {
            visibility: hidden;

            &.is-active {
                visibility: visible;
            }
        }
    }
}
