$toolbar-mode-icon-font-size: 2.4rem !default;
$toolbar-element-background: $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
    }

    .sidebar-main {
        .#{$css-namespace}cms {
            margin-top: $indent__xl;
        }
    }

    @include max-screen($screen__l) {
        .#{$css-namespace}cms .#{$css-namespace}panel-account-content {
            display: none;
        }
    }
}

.toolbar {
    @extend .abs-add-clearfix;
    display: none;
}

.toolbar-amount {
    display: none;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: $space-1 0 0;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    padding: $space-1 0;
    text-align: center;
    border-top: 0.1rem solid $color-gray76;
    border-bottom: 0.1rem solid $color-gray76;

    .pages {
        display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }
}

.sorter {
    .sorter-label {
        color: $color-gray52;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.sorter-options-wrapper {
    position: relative;
    display: inline-block;
    width: 7.5rem;
    margin-left: $space-1 / 2;
}

.sorter-options {
    margin: 0;
    width: auto;
    border: 0;
    border-radius: 0;
    padding: 0;
    appearance: none;

    &:focus {
        box-shadow: none;
    }
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size: 2.8rem,
        $_icon-font-line-height: 3.2rem,
        $_icon-font-color: $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide: true
    );
}

.sorter {
    .sort-desc {
        &::before {
            content: $icon-arrow-down;
        }
    }
}

.modes {
    display: none;
}

.limiter-options {
    margin: 0 0.5rem 0 $space-1;
    width: auto;
}

.limiter-label {
    font-weight: $font-weight-regular;
}

.limiter {
    .page-products .toolbar & {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .toolbar {
        .products.wrapper ~ & .pages {
            float: left;
        }
    }

    .toolbar-amount {
        float: left;
    }

    .sorter {
        float: right;
    }

    .modes {
        display: none;
        float: left;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        color: $text__color__muted;
        border: 0.1rem solid $border-color__base;
        border-right: 0;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: $space-1 1rem;
        text-align: center;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size: $toolbar-mode-icon-font-size,
            $_icon-font-text-hide: true,
            $_icon-font-color: $text__color__muted,
            $_icon-font-color-hover: $text__color__muted,
            $_icon-font-display: block
        );

        &:not(.active) {
            &:hover {
                color: $text__color__muted;
                background: darken($toolbar-element-background, 7%);
            }
        }

        &:last-child {
            border-right: 0.1rem solid $border-color__base;
        }

        &.active {
            color: $primary__color__light;
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}
