$product-info-radius: 0.3rem !default;

.product {
    &-info {
        &-main {
            display: flex;
            flex-direction: column;

            &-wrapper {
                display: flex;
                flex-direction: row;
                margin-left: auto;
                background-color: $color-grey-background;
                border-radius: 0.3rem;
                padding: $space-2 $space-3;
                overflow: hidden;
                width: 100%;

                @include min-screen($screen__l) {
                    width: 33.5rem;
                }

                .product-info-main {
                    flex-grow: 1;
                    margin: 0 auto;
                }
            }

            &__add-cart-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: $space-3;
            }

            .#{$css-namespace}product_slot {
                &__price_info {
                    font-size: $font-size-s;
                    line-height: $font-size-default;
                    font-style: normal;
                    font-weight: $font-weight__regular;
                }
            }

            ~ .product-store-more,
            .product-store-more {
                .product-agency-other-button {
                    border: none;
                    color: $color-secondary;
                    font-size: $font-size-default;
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                    text-transform: none;
                    background: none;
                    padding: 0;

                    &:hover {
                        text-decoration: none;
                    }

                    &:active {
                        text-decoration: none;
                        color: $color-primary;
                    }
                }
            }
        }

        &-cart {
            &-wrapper {
                @include min-screen($screen__xs) {
                    .product-add-form-wrapper {
                        flex-direction: row;
                        align-items: center;

                        .product-add-form {
                            margin: 0 0 0 $indent__s;
                        }
                    }
                }

                &.not-logged {
                    margin-top: 0;
                }
            }
        }

        &-price {
            display: flex;
            align-items: center;
            gap: $space-2;

            .has_promo & {
                color: $color-red-promo;
            }

            .has_promo.--disconnected & {
                color: $color-main-dark;
            }

            &.product-info-price-banner {
                background-color: $color-info-price-promo;
            }

            &.product-info-price-wrapper {
                background-color: transparentize($color-info-price-promo, 0.95);
                color: $color-info-price-promo;
            }

            .#{$css-namespace}product_slot__price_info {
                color: $color-main-dark;
                margin-top: $indent__xs;
            }

            &-top {
                width: 100%;
                position: relative;
                margin-bottom: $space-1;

                &:last-child {
                    margin: 0;
                }

                &:not(:has(:first-child)),
                &:empty {
                    display: none;
                }

                .--connected & {
                    padding-bottom: $space-3;
                    z-index: 2;

                    &::before {
                        content: '';
                        position: absolute;
                        top: -#{$space-2};
                        left: 50%;
                        width: 100vw;
                        height: calc(100% + #{$space-2});
                        margin-left: -50vw;
                        background-color: $color-primary;
                        z-index: -1;
                    }
                }

                .has_promo & {
                    &::before {
                        background-color: rgba($color-red-promo, 0.1);
                    }
                }
            }

            &-top,
            &-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: $space-1;
            }

            &__detail {
                display: flex;
                flex-direction: column;
                gap: $space-1 / 2;

                .--connected & {
                    justify-content: center;
                }
            }

            &__eco-contrib {
                font-size: $font-size-s;
                line-height: 1.8rem;
            }

            &__public-price {
                font-weight: $font-weight__bold;
                font-size: $font-size__s;
                line-height: 1.7rem;

                &:empty {
                    display: none;
                }
            }

            &-banner {
                color: $color-white;
                padding: 0.2rem $space-1;
                font-size: $font-size-s;
                font-weight: $font-weight-semi-bold;
                background-color: $color-info-price-banner-background;
                border-radius: 0.3rem;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                line-height: 1.8;

                &.has_promo {
                    background-color: $color-red-promo;
                }

                &.is_market_price {
                    background-color: $color-blue-promo;
                }

                &.is_refurbished {
                    background-color: $color-green-promo;
                }

                &.is_loyalty {
                    background-color: $color-purple;
                }
            }

            &-wrapper {
                display: flex;
                flex-direction: column;
                gap: $space-1;
                color: $color-white;

                .has_promo &,
                .--disconnected & {
                    color: $color-main-dark;
                }

                .product-custom-price-text {
                    font-size: $font-size-xs;
                    font-weight: $font-weight-medium;
                    font-style: italic;
                }

                .product-info-price {
                    display: flex;
                    align-items: stretch;
                    flex-wrap: wrap;

                    &-wrapper {
                        padding-right: $indent__s;
                    }

                    .price-container {
                        font-size: $font-size-xxl;
                        font-weight: $font-weight-black;
                        white-space: nowrap;

                        @include min-screen($screen__l) {
                            font-size: 4rem;
                            line-height: 1;
                        }
                    }

                    .unit,
                    .price-incl-vat {
                        font-weight: $font-weight-regular;
                        font-size: $font-size-s;
                        line-height: 1.5;
                    }

                    .unit {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-around;

                        .conditioning {
                            font-size: $font-size-xs;
                            font-weight: $font-weight-regular;
                            white-space: nowrap;

                            @include min-screen($screen__l) {
                                font-size: $font-size-s;
                            }
                        }
                    }
                }

                button.#{$css-namespace}link {
                    font-size: $font-size-s;
                    color: inherit;

                    @include lib-hover-focus {
                        color: inherit;
                    }
                }
            }

            &-link {
                text-decoration: none !important;
                display: flex;
                align-items: center;
                padding: $space-1 $space-2;
                background-color: $color-white;
                font-weight: $font-weight-bold;
                gap: $space-1;
                width: 100%;

                span {
                    text-align: left !important;
                    text-decoration: underline;
                }

                i {
                    width: 1.4rem !important;
                }

                &,
                &:visited {
                    color: $color-secondary;
                }
            }
        }

        &-not-sellable {
            background: transparent;
            text-align: center;
            border-radius: 0.3rem;
            padding: 3rem;

            &:not(.visible) {
                display: none;
            }

            .dcfonts-icons {
                font-size: 3.2rem;
                color: $color-primary;
            }

            .#{$css-namespace}content {
                font-size: $font-size-xl;
                line-height: 2.5rem;
                margin-top: $indent__base;
                font-weight: $font-weight-bold;
                color: $color-grey-dark;
            }

            .contact {
                margin: 1.5rem auto 0;

                .link {
                    display: block;
                    margin-top: $indent__s;
                    text-decoration: none;
                    color: $color-primary;
                    font-size: $font-size-xl;
                    font-weight: $font-weight-bold;
                }
            }
        }

        &-not-logged {
            border: 0.1rem solid $color-primary;
            border-radius: $product-info-radius;
            min-height: 23rem;
            justify-content: center;
            padding: 2rem;
            box-shadow: var(--box-shadow);

            @include min-screen($screen__l) {
                max-width: 48rem;
            }

            .#{$css-namespace}content {
                b {
                    font-size: $font-size-default;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    color: $color-grey-dark2;
                    margin-bottom: 0;
                }
            }

            .#{$css-namespace}account-creation {
                margin-top: $indent__base;
                letter-spacing: 0.1rem;
            }

            .action {
                font-weight: $font-weight__semibold;
                width: 100%;
                text-align: center;
                border-radius: $product-info-radius;
                margin-top: 2rem;
            }

            .#{$css-namespace}account-creation {
                font-weight: $font-weight-medium;
            }
        }
    }

    &-promo {
        &-text {
            &-container {
                text-align: left;
            }

            .#{$css-namespace}badge--tag {
                width: 100%;
                background-color: $color-red-light;
                color: $color-main-dark;
                gap: $space-1;
                min-height: 3.4rem;

                > span {
                    text-align: left;
                    line-height: 1.2;
                    font-size: $font-size-s;
                }
            }

            [data-js-legal-notice-display] {
                cursor: pointer;
            }
        }
    }

    &-add-form {
        display: flex;

        .fieldset {
            display: inline-flex;
            align-items: center;

            > .field.qty {
                flex: 0 0 auto;
                margin: 0;
            }

            > .action {
                flex: 0 0 auto;
                min-width: 13.8rem;
                padding: $indent__s;
                margin-left: $indent__xs;
                @include lib-font-size(12);
                line-height: 1;
            }
        }
    }
}
