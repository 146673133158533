// --------------------------------------------------------------------
// abstract base for panels
// --------------------------------------------------------------------

%dc__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &:not([class*='col-']) > li {
        flex: 0 0 auto;
    }
}

%dc__list-inline {
    @extend %dc__list;
    flex-direction: row;
}

%dc__list-chip {
    @extend %dc__list;

    > li {
        display: flex;
        align-items: flex-start;

        &::before {
            flex: 0 0 auto;
            content: '*';
            margin-right: $indent__xs;
        }
    }
}

.#{$css-namespace}list {
    @extend %dc__list;

    > li + li {
        margin-top: $indent__xs;
    }
}

.#{$css-namespace}list-inline {
    @extend %dc__list-inline;
}

.#{$css-namespace}cms {
    ul {
        @extend %dc__list-chip;
    }
}
