.useraccount-approvalflow-index {
    .columns {
        .column.main {
            padding: 0;
            background-color: transparent;
            box-shadow: none;
        }
    }
}

.#{$css-namespace}approval-flow {
    display: flex;
    flex-direction: column;
}
