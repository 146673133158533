$slider-arrow-size: $font-size-m;
$slider-arrow-svg-size: 3.2rem;
$slider-gap: $space-3;
$slider-arrow-svg-offset: $space-1;

@import 'products/sliders-product';

%slick-arrow {
    color: $color-primary;
    background-color: $color-white;
    font-size: $slider-arrow-size;
    padding: $space-1;
    border: 0.1rem solid $mainNeutralColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    transition: border var(--transition-speed-normal) ease-in-out; // TODO : Uniformise animation-timing-function in var(--transition-normal)

    &:not(.slick-disabled) {
        &:hover {
            // TODO : Accessibility focus
            border-color: $color-primary;
        }
    }

    &.slick-disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;

        .dcfonts-icons {
            color: $mainNeutralColor;
        }
    }

    .dcfonts-icons {
        width: $slider-arrow-size;
        height: $slider-arrow-size;
    }
}

%svg-arrow {
    position: absolute;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: $slider-arrow-svg-size;
    height: $slider-arrow-svg-size;
    padding: 0;
    border-color: transparent;
    background: url(../images/svg/slider-left.svg) no-repeat center center;

    &.prev {
        left: $slider-arrow-svg-offset;
    }

    &.next {
        right: $slider-arrow-svg-offset;
        background-image: url(../images/svg/slider-right.svg);
    }
}

%slick-dots {
    padding: #{$space-1 / 2} 0.45rem;
    border-radius: 0.8rem;
    background-color: transparentize($color-white, 0.1);
    position: absolute;
    bottom: 1rem;
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    @include min-screen($screen-lg) {
        right: $space-5;
        left: auto;
        transform: none;
    }

    li {
        margin: 0;
        float: none;
    }
}

%slick-dots-products {
    position: static;
    padding: 0;
    transform: none;
    margin-top: $space-2;
    background: transparent;
    display: flex;
    gap: $space-1 / 2;

    li {
        padding: 0.1rem; // cf Figma

        &.slick-active {
            button {
                background-color: $color-white;
                border-color: $color-disabled;
            }
        }

        button {
            margin: 0;
            background-color: $color-primary;
            border: 0.1rem solid $color-primary; // cf Figma
        }
    }
}

#homepage_brand-carousel, //specific because set in CMS page
.#{$css-namespace}custom-slider,
.#{$css-namespace}custom-picture {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-gtm-action='promotion'] {
        height: 30.8rem;

        &:not(.slick-initialized) {
            img {
                width: 100%;
            }
        }
    }

    &_content {
        width: 100%;
        height: 100%;

        picture {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &_slide {
        & + &:not(.slick-slide) {
            display: none;
        }
    }

    &-cta {
        display: flex;
        margin-top: $slider-gap;
        justify-content: center;

        &:empty {
            display: none;
        }
    }

    &-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        row-gap: $space-2;
        column-gap: $space-1;
        align-items: flex-start;
        margin-bottom: $space-2;

        @include min-screen($screen__m) {
            align-items: flex-end;
            column-gap: $space-3;
        }

        &-arrows {
            display: inherit;
            flex-flow: inherit;
            justify-content: flex-end;
            gap: $space-1;
            margin-left: auto;

            .slick-arrow {
                @extend %slick-arrow;
            }

            &:has(.slick-arrow[disabled] + .slick-disabled) {
                opacity: 0;
            }
        }
    }

    &.w-full-fluid {
        .slick-slide {
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .slick {
        &-list,
        &-track,
        picture {
            height: 100%;
            display: flex;
        }

        &-slider {
            padding: 0;
        }

        &-slide {
            img {
                width: 100%;
            }
        }

        &-dots {
            @extend %slick-dots;
        }

        &-arrow {
            &:not(.arrows) {
                // standard arrow
                @extend %slick-arrow;
            }
        }
    }

    .arrows {
        @extend %svg-arrow;
    }
}

#homepage_brand-carousel, //specific because set in CMS page
.#{$css-namespace}custom-slider {
    display: block;
    gap: $space-2;
}

#homepage_brand-carousel,
.#{$css-namespace}equivalent-products,
.#{$css-namespace}product-carousel__wrapper {
    .slick {
        &-dots {
            @extend %slick-dots-products;
        }
    }
}

.#{$css-namespace}custom-slider {
    .slick {
        &-dots {
            @extend %slick-dots;
        }
    }
}

.slick-dotted[data-dots-colors='alternate'] {
    flex-direction: column;

    .slick-dots {
        margin-top: $space-3;

        > li {
            > button {
                background-color: $color-primary;
            }

            &.slick-active button {
                background-color: $color-white;
            }
        }
    }
}
