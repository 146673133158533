@include max-screen($screen__s) {
    .cartouche {
        &.catalog {
            p {
                &:first-of-type {
                    display: none;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .cartouche.catalog {
        width: 100%;
        height: 21.2rem;
    }
}

@include min-screen($screen__l) {
    .cartouche {
        width: 100%;

        &.catalog {
            height: 21.2rem;
        }
    }
}
