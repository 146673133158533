@mixin lib-css-box-shadow($params: false, $default: true) {
    transition: box-shadow 0.2s ease-in-out; // TODO : Uniformise using var(--transition-normal)
    @if $default == true {
        box-shadow: 0 0.3rem 0.3rem 0 rgba(221, 226, 230, 1); // TODO: Use box-shadow var
    } @else {
        -webkit-box-shadow: $params;
        -moz-box-shadow: $params;
        box-shadow: $params;
    }
}

@mixin lib-css-box-shadow-hover($params: false, $default: true) {
    @if $default == true {
        box-shadow: 0 0.3rem 0.3rem 0 rgba(201, 204, 208, 1); // TODO: Use box-shadow var
    } @else {
        -webkit-box-shadow: $params;
        -moz-box-shadow: $params;
        box-shadow: $params;
    }
}

@mixin lib-shadow-block() {
    @include lib-css(box-shadow, $shadow);
    @include lib-css(border, 0.1rem solid $subAltPrimaryColor-lighten);
    @include lib-css(background, $defaultColor);
    @include lib-css(border-radius, $border-radius-default);
}
