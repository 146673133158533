// Common values

//$mainPrimaryColor: #1d1d1b;
//$subPrimaryColor: #00A3CF;
$altPrimaryColor: #00a3cf; // Same as $color-blue3
//$subPrimaryColor-lighten: #0089d0;
$subAltPrimaryColor: #858585; // TODO Remove redefinition of the same hexa in Descours
$subPrimaryColor-darken: #001020;
$subAltPrimaryColor-darker: #717171;
$subAltPrimaryColor-lighten: #c9ccd0;
$shadowColor: #dde2e6;
$colorAgency: #ff8200; // TODO Remove redefinition of the same hexa as Descours

$mainSecondaryColor: #32d355;
//$subSecondaryColor: #e40521;

$mainNeutralColor: #bfbfbf;
//$subNeutralColor: #e5e5e5;
//$altNeutralColor: #f0f0f0;
//$subAltNeutralColor: #e5f6fa;

$defaultColor: $color-white;

$deleteColor: #de2c19; // Same as $color-red10

//status
//$colorSucces: #27ae60;
$colorWarning: #ff6600;
$colorDanger: $deleteColor;
//$colorInfo: $color-sub-secondary;

// Background values

//$mainBackgroundColor: $color-sub-secondary;
$subBackgroundColor: $altPrimaryColor;
$altBackgroundColor: $color-gray-light7;
$lightBlueBackgroundColor: #e3f3f9;

// Buttons values

$defaultButtonBackgroundColor: #0089d0;
//$mainButtonBackgroundColor: $color-sub-secondary;
$subButtonBackgroundColor: $subBackgroundColor;
$alButtonBackgroundColor: $altBackgroundColor;

// Border values

$defaultBorderColor: #0089d0;
$mainBorderColor: $color-gray-light7;
//$subBorderColor: $color-sub-secondary;
$altBorderColor: $mainNeutralColor;

// Font value

$mainFontColor: $color-gray-light7;
//$subFontColor: $color-sub-secondary;
$altFontColor: $defaultColor;

$mainButtonFontColor: $altFontColor;
//$subButtonFontColor: $color-sub-secondary;
$altButtonFontColor: $mainFontColor;

// Input value

$mainInputColor: $mainNeutralColor;
