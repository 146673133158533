.#{$css-namespace}collaborator {
    display: flex;
    flex-direction: column;

    &_line {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 0;
    }

    &_label {
        width: 28%;
        color: $color-grey-dove;
        font-size: $font-size-s;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        line-height: 1.7rem;

        a {
            display: flex;
            margin-right: $indent__xs;
            font-size: $font-size-m;
        }
    }
}
