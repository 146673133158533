$navigation-top-header_mobile: 5.6rem;
$navigation-container_width: 33.5rem;
$navigation-desktop-max-height: 64.6rem;
$navigation-primary-color: $color-primary;
$navigation-secondary-color: $color-secondary;
$navigation-back-icon-size: 2.4rem;
$navigation-nav-items-padding: $space-2;
$navigation-buttons-wrapper-width: $navigation-back-icon-size + $navigation-nav-items-padding +
    $navigation-nav-items-padding; // icon wrapper size plus padding on both sides
$navigation-title-wrapper-height_mobile: 6.6rem;

%link-in-menu {
    font-size: $font-size-m;
    background: none;
    color: $color-menu-mobile-links;
    text-decoration: underline;
    font-weight: $font-weight-semi-bold;
    padding: 0 $space-2;
    margin-bottom: $space-2;
    line-height: 2rem;
    border: none;

    &:hover {
        text-decoration: none;
    }
}

body[data-js-menu='nav-open'] {
    @include max-screen($screen__l) {
        overflow: hidden;
    }

    .#{$css-namespace}navbar-aside {
        z-index: 151;
        visibility: visible;

        &-overlay {
            @include min-screen($screen__l) {
                visibility: visible;
            }
        }

        @include max-screen($screen__l) {
            top: 0;
            height: var(--app-height);

            .mobile-navigation-header {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: $space-1 $space-4;
                font-size: $font-size-s;
                background-color: $color-menu-mobile-default;
                color: $color-white;

                .mobile-navigation-header-logo {
                    margin: auto;
                    height: 4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 100%;
                    }
                }

                .mobile-agency-name {
                    font-weight: $font-weight-bold;
                    display: flex;
                    align-items: center;
                    gap: $space-2;
                    line-height: 1;

                    svg {
                        fill: $color-white;
                    }
                }

                .mobile-agency-schedule {
                    margin-top: $font-size-xxs;

                    .dot {
                        border-radius: $indent__s;
                        display: inline-block;
                        width: 0.8rem;
                        height: 0.8rem;
                        margin-right: $indent__xs;
                    }

                    span {
                        font-size: $font-size-default;
                    }
                }

                [data-store-status='opened'] {
                    .dot {
                        background-color: $color-availability-opened;
                    }
                }

                [data-store-status='closed-in-hour'] {
                    .dot {
                        background-color: $color-availability-low;
                    }
                }

                [data-store-status='closed'] {
                    .dot {
                        background-color: $color-availability-closed;
                    }
                }
            }
        }

        @include min-screen($screen__l) {
            > .#{$css-namespace}btn-overlay {
                position: absolute;
                inset: 0 0 0 50%;
                width: 100%;
                height: 100%;
                padding: 0;
                border: none;
                background: $color-black;
                opacity: 0.5;
                max-width: $layout__max-width;
                transform: translateX(-50%);

                .#{$css-namespace}icon {
                    display: none;
                }
            }
        }
    }
}

.#{$css-namespace}navbar-aside {
    position: fixed;
    top: var(--header-height);
    left: 0;
    z-index: -1;
    width: 100%;
    overflow-y: auto;
    visibility: hidden;

    /* Safari mobile */
    @include max-screen($screen__l) {
        .level1:has(.level2.active) > .#{$css-namespace}submenu-wrapper > .#{$css-namespace}title-wrapper {
            display: none;
        }
    }

    margin-left: 0;

    &-overlay {
        position: fixed;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-black;
        opacity: 0.55;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        display: block;
        visibility: hidden;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    > .#{$css-namespace}container {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: visible;
        width: 100%;
        height: 100%;

        @include max-screen($screen__l) {
            overflow-y: auto;
            background: $color-white;
        }

        &[aria-expanded='false'] {
            left: -32rem;
        }

        &[aria-expanded='true'] {
            left: 0;

            @include max-screen($screen__l) {
                .level0 > .#{$css-namespace}submenu-wrapper {
                    visibility: visible;
                    position: relative;
                }
            }

            .level0 > .#{$css-namespace}submenu-wrapper {
                .#{$css-namespace}title-wrapper--level0 {
                    display: none;
                }
            }
        }

        @include min-screen($screen__l) {
            width: $navigation-container_width;

            &[aria-expanded='false'] {
                left: -$navigation-container_width;
            }
        }

        > header {
            flex: 0 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 11.2rem;
            padding: $indent__base;

            .#{$css-namespace}btn-close {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                margin-left: $indent__base;
                padding: 0;
                border: none;
                @include lib-font-size(12);
                background: none;

                > .#{$css-namespace}icon {
                    width: 2.2rem;
                    height: 2.2rem;
                    font-size: 2.2rem;
                }

                > span {
                    margin-top: $indent__xs;
                }
            }
        }

        > .#{$css-namespace}navbar-wrapper {
            flex: 1 1 auto;
            position: relative;

            @include max-screen($screen__l) {
                padding-bottom: $space-2;

                &:has(.level0 .category-item [data-js-menu='submenu-wrapper'][aria-expanded='true']) {
                    overflow: hidden;
                }
            }

            @include min-screen($screen__l) {
                .mobile-navigation-header {
                    display: none;
                }

                background: transparent;
            }
        }
    }

    .#{$css-namespace}btn-overlay,
    .#{$css-namespace}close {
        .#{$css-namespace}icon,
        i {
            display: none;
        }

        @include max-screen($screen__l) {
            top: 50%;
            transform: translateY(-50%);
            left: $space-1;
            height: 4rem;
            width: 4rem;
            margin: 0;
            z-index: 1;
            opacity: 1;
            padding: $space-1;
            border: none;
            position: absolute;
            background: none;

            .#{$css-namespace}icon,
            i {
                display: block;
                font-size: $font-size-xl;
                color: $color-white;
            }
        }

        @include min-screen($screen__l) {
            opacity: 0.5;
            background: $color-primary;
            width: $layout__max-width;
            left: 0;
        }

        @include screen($screen__l, $layout__max-width) {
            width: calc(100vw - 5rem);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .#{$css-namespace}btn-categ {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        font-family: $font-default;
        appearance: none;

        > span {
            flex: 1 0 auto;
            text-align: left;
        }

        .#{$css-namespace}icon-caret {
            flex: 0 0 auto;
            margin-left: $indent__base;
            font-size: 1.6rem;
            color: $color-grey-argent;
        }
    }

    ul {
        margin: 0;
        list-style: none;

        &:not(.top-links) {
            padding: 0;
        }

        > li {
            margin: 0;

            &.level0.nav-2 {
                @include max-screen($screen__l) {
                    border-bottom: 0.1rem solid $color-grey-light;
                    padding-top: $space-2;
                }
            }

            &.level0.nav-2,
            &.level0.nav-3 {
                @include min-screen($screen__l) {
                    display: none !important;
                }
            }
        }
    }

    .#{$css-namespace}navigation {
        display: flex;
        flex-direction: column;
        padding: 0;
        max-width: 100%;
        max-height: 100%;

        @include min-screen($screen__l) {
            overflow: hidden;
        }

        &-shortcuts {
            @include min-screen($screen__l) {
                display: none;
            }
            padding: 2rem 0;
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            &-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: calc(100% / 3);
                width: 100%;
                text-align: center;
                @include lib-font-size(14);
                font-weight: $font-weight-medium;
                color: $navigation-primary-color;

                @include lib-hover-focus {
                    .text {
                        text-decoration: none;
                    }
                }

                .#{$css-namespace}icon {
                    width: 3rem;
                    height: 3.5rem;
                    font-size: 3rem;
                    margin-bottom: 0.5rem;
                    fill: $navigation-primary-color;
                }

                .text {
                    text-decoration: underline;
                }
            }
        }

        > ul {
            > li {
                display: flex;
                flex-direction: column;

                &:not([data-js-menu='submenu-products']) {
                    @include min-screen($screen__l) {
                        display: none;
                    }
                }

                > a,
                > button {
                    &.#{$css-namespace}btn-categ {
                        @extend %link-in-menu;
                        margin-bottom: 0;
                        padding: $space-2;

                        @include min-screen($screen__l) {
                            display: none;
                        }

                        .#{$css-namespace}icon-caret {
                            display: none;
                        }
                    }
                }

                > a {
                    @extend %link-in-menu;
                }
            }
        }

        button {
            background: none;
        }
    }

    .level0 div:first-of-type {
        .#{$css-namespace}buttons-wrapper {
            display: none;
        }
    }

    .level1 div:first-of-type,
    .level2 div:first-of-type {
        .#{$css-namespace}buttons-wrapper {
            display: flex;
        }

        @include min-screen($screen__l) {
            .#{$css-namespace}buttons-wrapper {
                display: none;
            }
        }
    }

    .level1 .#{$css-namespace}submenu-wrapper {
        @include min-screen($screen__l) {
            left: $navigation-container_width;
            border-left: 0.1rem solid $color-grey-border-light;
        }

        @include min-screen('1676px') {
            left: calc(((100% - #{$screen-xl}) / 2) + #{$navigation-container_width});
        }
    }

    .level2 .#{$css-namespace}submenu-wrapper {
        @include min-screen($screen__l) {
            left: $navigation-container_width * 2;
            border-left: 0.1rem solid $color-grey-border-light;
        }

        @include min-screen('1676px') {
            left: calc(((100% - #{$screen-xl}) / 2) + (#{$navigation-container_width} * 2));
        }
    }

    .#{$css-namespace}submenu-wrapper {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        top: 0;
        background: $color-white;
        transition: left 200ms ease-in-out; // TODO : Uniformise using var(--transition-normal)

        .#{$css-namespace}submenu-wrapper {
            // For subemnu wrappers
            @include max-screen($screen__l) {
                max-height: calc(100% - #{$navigation-top-header_mobile});
                top: $navigation-top-header_mobile;
            }
        }

        @include min-screen('1676px') {
            left: calc((100% - #{$screen-xl}) / 2);
        }

        @include min-screen($screen__l) {
            top: var(--header-height);
            overflow: auto;
            overflow-x: hidden;
            max-width: $navigation-container_width;
            height: var(--menu-desktop-height);
            max-height: calc(var(--app-height) - var(--header-height) - #{$space-4});
        }

        &.active > button,
        .active > button,
        button:hover,
        a:hover {
            @include min-screen($screen__l) {
                background-color: $color-grey-background !important;
            }
        }

        .#{$css-namespace}title-wrapper {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 5.5rem;
            flex-direction: column;
            position: sticky;
            top: 0;
            z-index: 1;

            @include min-screen($screen__l) {
                width: $navigation-container_width;
                flex-direction: row;
                position: static;
            }

            .#{$css-namespace}icon {
                flex: 0 0 auto;
                width: 4.4rem;
                height: 4.4rem;
                color: $defaultColor;

                @include min-screen($screen__l) {
                    margin-left: $indent__base;
                }
            }

            > .#{$css-namespace}buttons-wrapper {
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                height: $navigation-title-wrapper-height_mobile;
                width: $navigation-buttons-wrapper-width;
                position: absolute;
                top: 0;
                left: 0;

                > .#{$css-namespace}back {
                    background: none;
                    border: none;
                    top: 0;
                    color: $color-primary;
                    flex: 0 0 auto;
                    align-items: center;
                    padding: 0 0 0 $navigation-nav-items-padding;
                    text-transform: none;
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .#{$css-namespace}icon-caret {
                        font-size: $font-size-xl;
                    }

                    &::before {
                        content: '';
                        left: 0;
                        top: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            > a {
                flex: 1 1 auto;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                gap: $space-3;
                min-height: $navigation-title-wrapper-height_mobile;
                padding: $navigation-nav-items-padding $navigation-nav-items-padding $navigation-nav-items-padding
                    $navigation-buttons-wrapper-width; // for back button in absolute
                font-size: $font-size-m;
                line-height: 2.2rem;
                font-weight: $font-weight-semi-bold;
                white-space: normal;
                background-color: $color-blue-background-1;
                color: currentColor;
                width: 100%;

                @include min-screen($screen__l) {
                    background-color: $color-white;
                    border-bottom: 0.1rem solid $color-grey-border-light;
                    min-height: 5.4rem;
                    padding: $navigation-nav-items-padding $space-3;
                }

                &:hover {
                    .#{$css-namespace}link {
                        text-decoration: none;
                    }
                }

                .#{$css-namespace}link {
                    font-weight: $font-weight-medium;
                    font-size: $font-size-s;
                    white-space: nowrap;
                }
            }

            .level-top {
                background-color: transparent;
                width: 100%;
                text-decoration: none;
                @include lib-font-size(14);
                @include min-screen($screen__l) {
                    display: none;
                }
            }
        }

        .submenu {
            flex: 1 1 auto;
            margin: 0;
            background-color: $defaultColor;
            width: 100%;
            position: relative;

            @include max-screen($screen__l) {
                overflow: auto;
            }

            @include min-screen($screen__l) {
                width: $navigation-container_width;

                &.level0 {
                    padding-top: $space-1;
                }
            }

            > li {
                margin: 0;
                display: flex;
                align-items: center;

                &.level1 {
                    @include min-screen($screen__l) {
                        margin-bottom: $space-2;
                    }

                    @include max-screen($screen__l) {
                        > button {
                            padding: $space-1 0;
                        }
                    }
                }

                &.active,
                &:hover {
                    > button {
                        > .#{$css-namespace}icon-caret {
                            @include min-screen($screen__l) {
                                color: $color-menu-mobile-default;
                            }
                        }
                    }
                }

                > a,
                > button {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-height: 6rem;
                    padding: $navigation-nav-items-padding 0;
                    margin: 0 $navigation-nav-items-padding;
                    border: none;
                    @include lib-font-size(14);
                    font-weight: $font-weight-regular;
                    text-transform: none;
                    text-align: left;
                    color: $color-main-dark;
                    flex: 1;

                    @include min-screen($screen__l) {
                        background-color: $color-white;
                        margin: 0;
                        min-height: 4.4rem;
                        padding: 0.5rem $navigation-nav-items-padding 0.5rem $space-3;
                    }

                    @include max-screen($screen__l) {
                        border-bottom: 0.1rem solid $color-border-grey-light;
                    }

                    > span {
                        flex: 1 1 auto;
                        @include lib-font-size(14);

                        @include max-screen($screen__l) {
                            white-space: normal;
                        }

                        + .#{$css-namespace}link {
                            justify-content: end;
                        }
                    }

                    > .#{$css-namespace}icon {
                        flex: 0 0 auto;
                        display: block;
                        width: 3.2rem;
                        height: 3.2rem;
                        margin-right: $space-1;
                        line-height: 1;
                        color: $color-black;
                    }
                }
            }

            > .level3 {
                @include min-screen($screen__l) {
                    border: none;
                }

                a {
                    width: 100%;
                }
            }
        }

        &[aria-expanded='false'] {
            visibility: hidden;

            @include min-screen($screen__l) {
                .is-safari & {
                    display: none;
                }
            }

            .#{$css-namespace}close {
                @include max-screen($screen__l) {
                    display: none;
                }
            }
        }

        &[aria-expanded='true'] {
            visibility: visible;
            transition: all 200ms ease-in-out; // TODO : Uniformise using var(--transition-normal)

            @include min-screen($screen__l) {
                .is-safari & {
                    &[data-js-submenu-opened='true'] {
                        overflow: visible;

                        > ul > li {
                            &:not(.active) {
                                opacity: 0.75;
                            }

                            .#{$css-namespace}icon-caret {
                                transition: transform 0.15s; // TODO : Uniformise using var(--transition-normal)
                            }

                            &.active {
                                > .#{$css-namespace}btn-categ {
                                    .#{$css-namespace}icon-caret {
                                        transform: rotateZ(180deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @include max-screen($screen__l) {
                .#{$css-namespace}close {
                    display: block;
                }
            }
        }

        &.level1 {
            z-index: 3;
        }

        &.level2 {
            z-index: 4;
        }

        &.level3 {
            z-index: 5;
        }

        > li {
            display: flex;
            flex-direction: column;
        }
    }

    .level1 {
        .#{$css-namespace}title-wrapper {
            min-height: $navigation-title-wrapper-height_mobile;

            @include min-screen($screen__l) {
                min-height: 5.5rem;
            }
        }
    }

    .top-links-item,
    .link.quick-order,
    .greet.welcome,
    .#{$css-namespace}link-logout-wrapper {
        margin: 0;
        text-decoration: none; // quick fix for "dirty" base css implementation (.link.order)
        color: $color-gray-light-7; // quick fix for "dirty" base css implementation (.link.order)

        > a {
            display: flex;
            align-items: center;
            padding: $indent__s $indent__base;
            @include lib-font-size(16);
            text-decoration: none;
            color: $color-gray-light-7;

            > span,
            > .#{$css-namespace}icon {
                flex: 0 0 auto;
                margin: 0;

                + span {
                    flex: 1 1 auto;
                    width: auto;
                    height: auto;
                    margin-left: $indent__s;
                    text-decoration: underline;
                }
            }

            // Quick fix = targetting specific css class because icons are not implement equal
            .#{$css-namespace}icon,
            .icon-account {
                width: 2.6rem;
                height: 2.6rem;
                margin: 0;

                + span {
                    text-decoration: underline;
                }
            }

            &:hover,
            &:focus {
                > span + span {
                    text-decoration: underline;
                }
            }
        }
    }

    .#{$css-namespace}link-logout-wrapper {
        .#{$css-namespace}icon {
            width: 2.2rem;
            height: 2.2rem;
        }
    }

    .#{$css-namespace}navbar-account-links-wrapper {
        margin: $indent__base 0;

        @include max-screen($screen__l) {
            margin: 0;
            padding-bottom: 1rem;
            background-color: $color-white;
            position: relative;
            z-index: 1;
        }
    }

    .top-links {
        @include min-screen($screen__l) {
            display: none;
        }

        .top-links-item {
            > a {
                @extend %link-in-menu;
            }
        }
    }
}

.#{$css-namespace}navbar-main {
    display: none;

    @include min-screen($screen__l) {
        display: flex;
        color: $color-white;

        .#{$css-namespace}navbar-main-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            max-width: 132.5rem;
            min-height: 3.5rem;
            background-color: $color-blue3;

            .product-menu-link {
                display: flex;
            }

            > .top-links {
                flex: 0 0 auto;
            }
        }

        .#{$css-namespace}navbar-menu {
            > ul {
                flex: 0 0 auto;
                display: flex;
                margin: 0;
                padding: 0;

                .category-item.first.level-top {
                    > a {
                        margin: 0 auto;
                        background-color: $color-main-dark;
                        color: $color-main-light;
                        user-select: none;
                    }
                }

                > li {
                    margin: 0;
                    display: flex;
                    align-items: center;

                    > a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 4rem; // quickfix icon size bug
                        padding: $offset-s $offset-l;
                        font-weight: $font-weight-medium;

                        &:hover,
                        &:focus {
                            text-decoration: underline;
                            color: inherit;
                        }
                    }

                    + li:not(:last-child):after {
                        content: '';
                        width: 0.1rem;
                        height: 1.8rem;
                        background-color: $color-secondary;
                    }
                }

                > li.nav-1 {
                    display: block;
                    min-width: 22rem;

                    > a {
                        &::before {
                            content: '';
                            @include lib-background-image(
                                'svg/_clean/white/bars.svg',
                                center,
                                no-repeat,
                                transparent,
                                false
                            );
                            width: 2.4rem;
                            height: 2.4rem;
                            top: 0;
                            left: 0;
                            margin-right: $offset;
                        }
                    }

                    &[aria-expanded='true'] {
                        > a {
                            &::before {
                                width: 2rem;
                                height: 2rem;
                                @include lib-background-image(
                                    'svg/_clean/white/cross.svg',
                                    center,
                                    no-repeat,
                                    transparent,
                                    false
                                );
                            }
                        }
                    }

                    &[aria-expanded='false'] {
                        > a {
                            &::before {
                                @include lib-background-image(
                                    'svg/_clean/white/bars.svg',
                                    center,
                                    no-repeat,
                                    transparent,
                                    false
                                );
                            }
                        }
                    }
                }
            }
        }

        .top-links {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                display: flex;
                align-items: center;
                margin: 0;

                a {
                    display: flex;
                    padding: $offset-s $offset-l;
                    font-weight: $font-weight-medium;
                    color: $color-main-light;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                        color: inherit;
                    }
                }

                + li {
                    &::before {
                        content: '';
                        display: block;
                        width: 0.1rem;
                        height: 1.8rem;
                        background-color: $color-secondary;
                    }
                }
            }

            &-item {
                &--brands,
                &--good-deals {
                    display: none !important;

                    + .top-links-item {
                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .#{$css-namespace}navbar-main {
        display: block;
    }

    .#{$css-namespace}navbar-main-wrapper {
        .#{$css-namespace}btn-menu {
            position: absolute;
            top: 0.6rem;
            padding-left: 1rem;
            background-color: $color-primary;

            .#{$css-namespace}icon-menu {
                fill: $color-blue3;
                width: 3.2rem;
                height: 3.2rem;
            }
        }

        .#{$css-namespace}navbar-menu,
        .top-links {
            display: none;
        }
    }
}

@include screen($screen__l, $layout__max-width) {
    .#{$css-namespace}navbar-aside {
        padding: 0 $indent__m;
    }
}

// fix issues before DOM is full loaded
body:not(.#{$css-namespace}menu_ready),
body[aria-busy='true'] {
    @include min-screen($screen__l) {
        .#{$css-namespace}navbar-main .#{$css-namespace}navbar-menu > ul [data-js-menu='submenu-products'] > a {
            pointer-events: none;
        }
    }
}
