/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;

    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 0.1rem;

    [dir='rtl'] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 0.1rem solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        display: block;
        float: left;
        margin-bottom: $space-2;

        button {
            list-style: none;
            bottom: 0;
            display: block;
            padding: 0;
            margin: 0 0.25rem;
            left: 0;
            right: 0;
            width: 0.8rem;
            height: 0.8rem;
            font-size: 0;
            border-radius: 50%;
            background: $color-disabled;
            border: 0;
        }

        &.slick-active {
            button {
                background: $color-secondary;
            }
        }
    }
}
