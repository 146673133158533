@import "quick-access/account";
@import "quick-access/mini-cart";
@import "quick-access/store";

.quick-access {
    display: flex;
    align-items: center;
    gap: $space-3;
    margin-left: auto;

    @include max-screen($screen__m) {
        .js-search.--scrolled & {
            display: none;
        }
    }

    @include min-screen($screen__m) {
        gap: $space-4;
    }

    .border-bottom {
        &.active {
            &::after {
                @include min-screen($screen__m) {
                    content: '';
                    position: absolute;
                    top: calc(100% + #{$space-1});
                    left: 0;
                    width: 100%;
                    height: $border-bottom-active;
                    border-radius: 0.4rem;
                    background-color: $color-secondary;
                    z-index: $dropdown-index + 1;
                    pointer-events: none;
                }

                @include min-screen($screen__l) {
                    bottom: -$border-bottom-active / 2;
                }
            }
        }
    }
}
