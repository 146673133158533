.#{$css-namespace}account-email-preferences {
    &__title,
    &__content {
        @include lib-css-wrapper();
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 0 !important;

        @include max-screen($screen__s) {
            padding: 1.5rem 1.5rem 2rem;
        }
    }

    &__content {
        margin-top: 2.8rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        &-element {
            display: flex;

            &-left {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                flex: 1;
            }

            &-right {
                display: flex;
                align-items: center;
            }
        }
    }

    &__email-type {
        color: $color-primary;
        font-family: $font-secondary;
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: 2.5rem;
    }
}

.#{$css-namespace}switch {
    position: relative;
    width: 3.9rem;
    height: 2rem;
    border-radius: 1rem;
    transition:
        box-shadow 0.3s,
        opacity 0.3s; // TODO : Uniformise using var(--transition-normal)

    &,
    input[type='checkbox'] {
        cursor: pointer;
    }

    &::before,
    &::after {
        content: '';
        display: block;
    }

    &::before {
        width: 100%;
        height: 100%;
        background-color: $color-disabled;
        border-radius: 1rem;
        transition: background-color 0.3s; // TODO : Uniformise using var(--transition-normal)
    }

    &::after {
        position: absolute;
        top: 50%;
        left: 0.2rem;
        width: 1.5rem;
        height: 1.5rem;
        transform: translateY(-50%);
        background-color: $color-white;
        border-radius: 100%;
        transition: transform 0.3s; // TODO : Uniformise using var(--transition-normal)
    }

    input[type='checkbox'] {
        position: absolute;
        inset: 0;
        opacity: 0;
    }

    &:has(input[type='checkbox']:focus-visible) {
        box-shadow: 0 0 0 0.3rem $color-primary; // TODO: Use box-shadow var
    }

    &:has(input[type='checkbox']:checked) {
        &::before {
            background-color: $color-secondary;
        }

        &::after {
            transform: translate(2rem, -50%);
        }
    }

    &:has(input[disabled]) {
        opacity: 0.5;
    }
}
