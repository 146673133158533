$product-slot-height: 37.8rem !default;
$product-slot-top-height: $space-13 !default;
$product-slot-carousel-height: 36.4rem !default;
$product-slot-padding: $space-1 !default;
$product-slot-padding-large: $space-2 !default;
$product-slot-width: 22.5rem !default;
$product-slot-offset-top: $badge-min-height !default;
$slot: #{'.' + $css-namespace}product_slot;

%name-on-card-hovered {
    color: $color-primary;
    text-decoration: underline;
}

#{$slot} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: $color-white;
    text-align: left;
    padding: $product-slot-padding $product-slot-padding $product-slot-padding-large;
    position: relative;

    #{$slot}__top_part {
        margin-top: $product-slot-offset-top;
    }

    @include min-screen($screen-sm) {
        min-height: $product-slot-height;
    }

    &.--equivalent-product {
        min-height: auto;

        &#{$slot}--layer_open {
            padding: 0;
        }
    }

    &.--carousel {
        width: $product-slot-width;
        min-height: $product-slot-carousel-height;
        border: 0.1rem solid $color-grey-light;

        &#{$slot}--layer_open {
            padding: 0;
        }
    }

    &__infos {
        display: flex;
        flex-direction: column;
        gap: $product-slot-padding;
        flex: 1;
        margin-top: $product-slot-padding;

        .--carousel & {
            gap: $product-slot-padding;
        }

        .--default & {
            gap: $product-slot-padding;

            @include min-screen($screen-sm) {
                gap: $product-slot-padding-large;
            }
        }

        &-top {
            padding: $product-slot-padding;
            height: $product-slot-top-height;

            .--default & {
                height: auto;

                @include min-screen($screen-sm) {
                    height: $product-slot-top-height;
                }
            }

            .--carousel & {
                height: auto;
            }

            .--equivalent-product & {
                height: 8.3rem;
            }
        }

        &-bottom {
            display: flex;
            flex-direction: column;
            gap: $product-slot-padding;
            margin-top: auto;
            padding-left: $product-slot-padding;
            padding-right: $product-slot-padding;

            &--unavailable {
                .--carousel & {
                    margin-top: auto;
                }
            }

            .#{$css-namespace}link {
                margin-left: 0;
                padding: 0;
                line-height: 2.6rem;
            }
        }
    }

    &__brand {
        color: $brand-text-color;
        font-weight: $brand-text-weight;
        text-transform: $brand-text-case;
        line-height: (20 / 14);
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        height: fit-content;
        max-height: 2rem;
    }

    &__reference {
        margin-top: $product-slot-padding;
        font-size: $reference-text-size;
        min-height: #{$reference-text-line-height * $reference-text-size};
        line-height: $reference-text-line-height;
        position: relative;
        z-index: 1;
        display: none;

        @include min-screen($screen-sm) {
            display: inline-block;
        }

        .--carousel &,
        .--equivalent-product & {
            display: none;
        }

        &:empty {
            position: static;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: $product-slot-padding 0 0;

        &.--carousel {
            padding: 0;
        }

        .--default & {
            flex-direction: row;
            gap: $product-slot-padding-large;

            @include min-screen($screen-sm) {
                flex-direction: column;
                gap: 0;
            }
        }

        .#{$css-namespace}modal-equivalent-products-modal &,
        #dc_equivalent-products & {
            height: auto;
        }
    }

    &__see_products_button {
        margin-top: auto !important;
    }

    &__login {
        font-weight: $font-weight-bold;
        position: relative;

        &,
        &:visited {
            color: $color-secondary;
        }
    }

    .#{$css-namespace}product_slot__wishlist {
        .dcfonts-icons {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
        }

        .#{$css-namespace}modal-checkout-addtocart-modal & {
            display: none;
        }
    }

    .#{$css-namespace}add_cart_layer {
        display: none;
        max-height: 100%;
        height: 100%;
        width: 100%;
        margin: 0;

        &__title {
            -webkit-line-clamp: 3;
            height: fit-content;
            max-height: 6rem;
            overflow: hidden;
            line-height: (20 / 14);
            margin-top: 0;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
        }

        &__reference {
            display: none;
        }

        .#{$css-namespace}availability {
            margin-top: $space-1 / 2;
        }
    }

    &__icon_container {
        display: inline-flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        padding: 0;
        border: none;
        background-color: transparent;

        @include lib-hover-focus {
            border: none;
            background-color: transparent;
        }

        .dcfonts-icons {
            background-color: $color-white;
            border-radius: 0.3rem;
        }
    }

    &__pick_up {
        top: 0;
        left: $space-1;
        margin-left: 0;
        border-radius: 0.3rem;
        border: none;

        .#{$css-namespace}icon {
            width: 4rem;
            height: 4rem;
            font-size: 3rem;
            color: $color-accent;
            padding: 0;
            margin-top: 1rem;
        }

        @include lib-hover-focus {
            border: none;
        }
    }

    &__wishlist {
        top: $space-1;
        right: $space-1;
    }

    &__top_part {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .#{$css-namespace}modal-equivalent-products-modal &,
        #dc_equivalent-products & {
            margin-bottom: 0;
        }

        @include lib-hover-focus {
            color: initial;
        }

        .--default & {
            max-width: 33%;

            @include min-screen($screen-sm) {
                max-width: 100%;
            }
        }
    }

    &__main_link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include lib-hover-focus {
            ~ * #{$slot}__name {
                @extend %name-on-card-hovered;
            }
        }
    }

    &__image_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-height: 20rem;
        margin: 0 auto;
        max-width: 100%;

        .--default & {
            max-height: 16.4rem;

            @include min-screen($screen-sm) {
                max-height: 20rem;
            }
        }

        .product-image-wrapper {
            display: flex;

            .--carousel & {
                width: 15rem;
                height: 15rem;
            }
        }

        img {
            object-fit: contain;
            width: 100%;
        }
    }

    &__title {
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 3.8rem;

        @include min-screen($screen-sm) {
            margin-top: $space-1 / 2;
        }

        &:hover {
            .tooltip-text {
                visibility: visible;
            }
        }

        .tooltip-text {
            position: absolute;
            z-index: 2;
            visibility: hidden;
            width: 23.2rem;
            padding: $space-1;
            text-align: center;
            color: $color-main-light;
            border-radius: 0.4rem;
            background: $color-primary;
            box-shadow: 0 0.5rem 0.3rem 0 $color-tertiary-10; // TODO: Use box-shadow var
            line-height: 1.1;

            @include max-screen($screen__m) {
                display: none;
            }
            @include min-screen($screen__m) {
                right: 0;
                left: 0;
                margin: 0 auto;
            }

            .--carousel & {
                max-width: calc(100% - #{$space-3});
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                font-size: $font-size-s;
                text-transform: none;
            }

            &::before {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                display: block;
                width: 1.2rem;
                height: 1.2rem;
                content: '';
                background: inherit;
            }
        }
    }

    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 2rem;
        max-height: 3.8rem;
        position: relative;
        text-decoration: none;

        &:first-letter {
            text-transform: uppercase;
        }

        @include lib-hover-focus {
            @extend %name-on-card-hovered;
        }
    }

    &__reference {
        color: $reference-text-color;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: $space-1;
    }

    &__price_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 6rem; //Height for alignment between items

        @include min-screen($screen-sm) {
            gap: $space-1 / 2;
        }

        .--default & {
            min-height: auto;

            @include min-screen($screen-sm) {
                min-height: 6rem;
            }
        }

        #{$slot}__custom_price_text {
            font-size: $font-size-s;
            line-height: (18 / 12);
            text-transform: lowercase;
        }
    }

    &__price {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        color: $text-price-color;

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &:not(:empty) {
                min-height: 2rem; //Height for alignment between items
            }
        }

        &__amount {
            font-size: 2.2rem;
            line-height: 2.6rem;
            font-weight: $font-weight-black;
        }

        &__currency {
            font-size: $font-size-m;
            line-height: 2.2rem;
            font-weight: $font-weight-black;
        }

        &__container {
            margin-top: 1rem;
        }

        &__reference {
            margin-left: $space-1;
            line-height: $reference-text-line-height;
            color: $reference-text-color-alt;
        }
    }

    &__public-price {
        font-size: $font-size-s;
        font-style: italic;
        line-height: 1.25;

        .--carousel &,
        .--equivalent-product & {
            display: none;
        }
    }

    &__promo span {
        color: $color-red;
    }

    &__price_info {
        display: block;
        margin-top: -$space-1 / 2;
        font-size: $font-size-s;
        font-weight: $font-weight-light;
        font-style: italic;
    }

    &__availability {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        line-height: 1.6rem;
        gap: $space-1;
        margin: auto 0 0;

        > .#{$css-namespace}availability {
            flex-shrink: 0;

            @include min-screen($screen-sm) {
                min-height: 2rem;
            }

            &:has(.#{$css-namespace}availability__text:empty) {
                display: none;

                @include min-screen($screen-sm) {
                    display: flex;
                }
            }

            .--default & {
                &:empty {
                    display: none;

                    @include min-screen($screen-sm) {
                        display: block;
                    }
                }
            }
        }
    }

    #{$slot}__main_button {
        position: relative;

        &_base {
            position: relative;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-family: $font-default;
            border: none;
            color: $color-white;
            text-transform: none;
            padding: 0 1rem;
            cursor: pointer;

            > .#{$css-namespace}icon {
                position: absolute;
                left: 1rem;
                fill: $color-white;
            }
        }

        &__add_cart_main_button {
            span {
                $font-weight: inherit;
                $font-size: inherit;
                margin-left: 2.6rem;
            }
        }
    }

    &__price_delivery {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        gap: $product-slot-padding-large;
    }

    #{$slot}__not_in_store {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: $space-1;
        border: 0.1rem solid $color-border-grey-light;
        margin: auto 0;

        #{$slot}__disabled_area {
            &__title {
                color: $color-primary;
                font-size: $font-size-m;
                line-height: 2.2rem;
                font-weight: $font-weight-bold;
            }

            &__text {
                line-height: 2rem;
            }
        }

        &__phone_number {
            font-size: $font-size-default;
            font-weight: $font-weight-bold;
            color: $color-secondary;
        }
    }

    #{$slot}__price_from {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: 1rem;

        &__from {
            font-size: $font-size-xs;
            font-style: italic;
        }
    }

    .#{$css-namespace}chip {
        position: absolute;
        top: $space-1;
        left: $space-1;
        margin-right: auto;

        ~ .#{$css-namespace}chip {
            display: none;
        }
    }

    #{$slot}__promo {
        #{$slot}__price {
            &__amount,
            &__currency{
                color: $color-red;
            }

            &__reference {
                color: $reference-text-color-alt;
            }
        }
    }

    #{$slot}__see_products_button,
    #{$slot}__see_product_button,
    #{$slot}__add_cart_main_button,
    .equivalent-products-modal-link {
        position: relative;

        > .#{$css-namespace}icon {
            width: $font-size-m;
            height: $font-size-m;
            font-size: $font-size-m;
            left: $product-slot-padding-large !important;
        }
    }

    &--layer_open {
        height: 100%;
        padding: 0;

        #{$slot}__container {
            display: none !important;
        }

        .#{$css-namespace}add_cart_layer {
            display: flex !important;
        }
    }

    /** WHISLIST OVERRIDE **/
    .action.wishlist {
        position: absolute;
        top: 0;
        right: 0;

        .items {
            top: $space-4;
            right: 0.3rem; // Keep it to align please!

            &::before {
                right: 1rem;
                left: inherit;
            }

            &::after {
                right: 0.9rem;
                left: inherit;
            }
        }

        .modal-popup & {
            display: none;
        }

        ~ #{$slot}__container #{$slot}__chip { // Prevent overlapping tag and wishlist button
            margin-left: 0;
            margin-right: $space-4;
        }
    }

    .equivalent-products-modal-link {
        font-weight: $font-weight-bold;
        line-height: 1.6rem;

        @at-root .--carousel#{&} {
            line-height: 4rem;
        }

        @at-root .--default#{&} {
            margin-top: $space-1 / 2;
            margin-bottom: -$space-1 / 2;
        }

        .dcfonts-icons {
            font-size: $font-size-m;
        }
    }
}

.#{$css-namespace}tag--secondary + #{$slot} #{$slot}__top_part { // Equivalent product tag is next to product slot
    margin-top: $product-slot-offset-top;

    .#{$css-namespace}chip { // Prevent multiple tags from overlapping
        display: none;
    }
}
