.menu-burger {
    background: none;
    border: 0;
    width: $space-3;
    height: $space-3;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    &:hover,
    &:focus-within {
        background: none;
        border: 0;
    }

    @include max-screen($screen__m) {
        .js-search.--scrolled & {
            display: none;
        }
    }

    @include min-screen($screen__m) {
        display: none;
    }

    i {
        font-size: 2.4rem;
        color: $color-primary;
    }
}
