.#{$css-namespace}actions-bar {
    background: $color-grey-background;
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 2rem;

    @include max-screen($screen__s) {
    }

    &_action {
        padding: 1.7rem 2.2rem 1.8rem;
        vertical-align: middle;
        white-space: nowrap;
        display: flex;
        align-items: center;
        position: relative;
        line-height: 1.9rem;

        @include max-screen($screen__s) {
            width: 50%;
        }

        &:not(:hover) {
            * {
                color: $color-secondary;
            }
        }

        &:not(:last-child) {
            &::after {
                content: '';
                height: 3rem;
                width: 0.1rem;
                background: $color-gray80;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            @include max-screen($screen__s) {
                &::after {
                    display: none;
                }
            }
        }

        &[disabled] {
            color: $mainNeutralColor;
            cursor: default;

            * {
                color: $mainNeutralColor;
            }
        }

        &_name {
            @include max-screen($screen__s) {
                width: 12rem;
            }
        }

        [class^='icon-'],
        [class^='dcfonts-icons'] {
            margin-right: 0.5rem;
            font-size: 1.7rem;
            display: inline-block;
        }
    }

    .separator-mobile {
        &::after {
            display: block;
            content: '';
            height: 3rem;
            width: 0.1rem;
            background: $color-gray80;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
