/* -------------------------------------------------
    Datepicker vars
   ------------------------------------------------- */

.air-datepicker {
    --adp-font-size: #{$font-size-default};
    --adp-width: 24.6rem;
    --adp-z-index: 100;
    --adp-padding: #{$space-1 / 2};
    --adp-grid-areas: 'nav' 'body' 'timepicker' 'buttons';

    --adp-transition-duration: 0.3s;
    --adp-transition-ease: ease-out;
    --adp-transition-offset: #{$space-1};

    --adp-background-color: #{$color-white};
    --adp-background-color-hover: #{$color-grey-background-light};
    --adp-background-color-active: #{$color-grey-background};
    --adp-background-color-in-range: rgba(92, 196, 239, 0.1);
    --adp-background-color-in-range-focused: rgba(92, 196, 239, 0.2);
    --adp-background-color-selected-other-month-focused: transparent;
    --adp-background-color-selected-other-month: transparent;

    --adp-color: #{$color-gray-darken4};
    --adp-color-secondary: #{$color-gray60};
    --adp-accent-color: #{$color-secondary};
    --adp-color-current-date: var(--adp-accent-color);
    --adp-color-other-month: #{$color-gray46};
    --adp-color-disabled: #{$color-grey-light};
    --adp-color-disabled-in-range: #{$color-gray55};
    --adp-color-other-month-hover: #{$color-gray-middle2};

    --adp-border-color: #{$color-secondary};
    --adp-border-color-inner: #{$color-white-dark-smoke};
    --adp-border-radius: #{$form-element-input__border-radius};
    --adp-border-color-inline: #{$color-secondary};

    --adp-nav-height: #{$space-4};
    --adp-nav-arrow-color: var(--adp-color-secondary);
    --adp-nav-action-size: #{$space-4};
    --adp-nav-color-secondary: var(--adp-color-secondary);

    --adp-day-name-color: #{$color-primary};
    --adp-day-name-color-hover: #8ad5f4;

    --adp-day-cell-width: 1fr;
    --adp-day-cell-height: #{$space-4};
    --adp-month-cell-height: #{$space-5};
    --adp-year-cell-height: #{$space-7};

    --adp-pointer-size: 1rem;
    --adp-poiner-border-radius: 0.2rem;
    --adp-pointer-offset: #{$space-1};

    --adp-cell-border-radius: 0.4rem;
    --adp-cell-background-color-hover: var(--adp-background-color-hover);
    --adp-cell-background-color-selected: #{$color-secondary};
    --adp-cell-background-color-selected-hover: #{$color-secondary};
    --adp-cell-background-color-in-range: rgba(92, 196, 239, 0.1);
    --adp-cell-background-color-in-range-hover: rgba(92, 196, 239, 0.2);
    --adp-cell-border-color-in-range: var(--adp-cell-background-color-selected);

    --adp-btn-height: #{$space-4};
    --adp-btn-color: var(--adp-accent-color);
    --adp-btn-color-hover: var(--adp-color);
    --adp-btn-border-radius: var(--adp-border-radius);
    --adp-btn-background-color-hover: var(--adp-background-color-hover);
    --adp-btn-background-color-active: var(--adp-background-color-active);

    --adp-time-track-height: 0.1rem;
    --adp-time-track-color: #{$color-gray46};
    --adp-time-track-color-hover: $color-gray-light2;
    --adp-time-thumb-size: #{$font-size-s};
    --adp-time-padding-inner: #{$space-1};
    --adp-time-day-period-color: var(--adp-color-secondary);

    --adp-mobile-font-size: #{$font-size-m};
    --adp-mobile-nav-height: #{$space-5};
    --adp-mobile-width: 32rem;
    --adp-mobile-day-cell-height: #{$space-5};
    --adp-mobile-month-cell-height: #{$space-6};
    --adp-mobile-year-cell-height: #{$space-8};
}

.air-datepicker-overlay {
    --adp-overlay-background-color: #{rgba($color-black, 0.3)};
    --adp-overlay-transition-duration: 0.3s;
    --adp-overlay-transition-ease: ease-out;
    --adp-overlay-z-index: 99;
}
