// ----- Display ----- //
$grid-columns: 12 !default; // TODO : move into _var-grid.scss
$grid-default-gap: $space-1 !default; // TODO : move into _var-grid.scss
$container-padding-mobile: $space-2;

.container {
    max-width: $layout__max-width;
    padding-left: $container-padding-mobile;
    padding-right: $container-padding-mobile;
    @extend .h-auto;

    &.narrow {
        width: #{'min(46rem, 100%)'} !important;
        padding-left: 0;
        padding-right: 0;
    }

    @include min-screen($screen__m) {
        padding-left: 0;
        padding-right: 0;
    }
}

// TODO : Move into Toolkit until here ?
.w-full {
    width: 100% !important;
}

.w-full-fluid {
    width: 100vw !important;
    position: relative;
    margin-left: -50vw;
    left: 50%;
}

.h-flex,
.v-flex {
    display: flex !important;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex: 0 1 auto;

    [class*='col-'] {
        display: flex;
        width: 100%;
        flex: 0 0 auto;
        max-width: 100%;

        &.col-auto {
            flex: 1;
        }
    }

    .col-full {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.h-flex {
    &.flex-row-reverse {
        flex-direction: row-reverse !important;
    }
}

.v-flex {
    flex-direction: column !important;

    &.flex-col-reverse {
        flex-direction: column-reverse !important;
    }
}

.h-auto {
    margin-left: auto;
    margin-right: auto;
}

@include create-rule-by-breakpoints(
    'mt-auto',
    (
        'margin-top': 'auto !important',
    )
);
@include create-rule-by-breakpoints(
    'mr-auto',
    (
        'margin-right': 'auto !important',
    )
);
@include create-rule-by-breakpoints(
    'mb-auto',
    (
        'margin-bottom': 'auto !important',
    )
);
@include create-rule-by-breakpoints(
    'ml-auto',
    (
        'margin-left': 'auto !important',
    )
);
@include create-rule-by-breakpoints(
    'mx-auto',
    (
        'margin-left': 'auto !important',
        'margin-right': 'auto !important',
    )
);
@include create-rule-by-breakpoints(
    'my-auto',
    (
        'margin-top': 'auto !important',
        'margin-bottom': 'auto !important',
    )
);
@include create-rule-by-breakpoints(
    'm-auto',
    (
        'margin': 'auto !important',
    )
);

@include create-rule-by-breakpoints(
    'd-none',
    (
        'display': 'none !important',
    )
);

@include create-rule-by-breakpoints(
    'hidden',
    (
        'display': 'none',
    )
);

@include create-rule-by-breakpoints(
    'flex',
    (
        'display': 'flex !important',
    )
);

@include create-rule-by-breakpoints(
    'block',
    (
        'display': 'block !important',
    )
);

@include create-rule-by-breakpoints(
    'inline-block',
    (
        'display': 'inline-block !important',
    )
);

// ----- Margins ----- //

@for $i from 0 through 8 {
    @include create-rule-by-breakpoints(
        'm-#{$i}',
        (
            'margin': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'mx-#{$i}',
        (
            'margin-left': '#{$space-1 * $i} !important',
            'margin-right': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'my-#{$i}',
        (
            'margin-top': '#{$space-1 * $i} !important',
            'margin-bottom': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'ml-#{$i}',
        (
            'margin-left': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'mr-#{$i}',
        (
            'margin-right': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'mt-#{$i}',
        (
            'margin-top': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'mb-#{$i}',
        (
            'margin-bottom': '#{$space-1 * $i} !important',
        )
    );

    @include create-rule-by-breakpoints(
        'p-#{$i}',
        (
            'padding': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'px-#{$i}',
        (
            'padding-left': '#{$space-1 * $i} !important',
            'padding-right': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'py-#{$i}',
        (
            'padding-top': '#{$space-1 * $i} !important',
            'padding-bottom': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'pl-#{$i}',
        (
            'padding-left': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'pr-#{$i}',
        (
            'padding-right': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'pt-#{$i}',
        (
            'padding-top': '#{$space-1 * $i} !important',
        )
    );
    @include create-rule-by-breakpoints(
        'pb-#{$i}',
        (
            'padding-bottom': '#{$space-1 * $i} !important',
        )
    );
}

// ----- Gap ----- //

@for $i from 0 through 6 {
    @include create-rule-by-breakpoints(
        'gap-#{$i}',
        (
            'gap': $space-1 * $i,
            '--gap': $space-1 * $i,
        )
    );
    @include create-rule-by-breakpoints(
        'gap-column-#{$i}',
        (
            'column-gap': $space-1 * $i,
        )
    );
    @include create-rule-by-breakpoints(
        'gap-row-#{$i}',
        (
            'row-gap': $space-1 * $i,
        )
    );
}

// ----- Flex Columns ----- //
// TODO : move into _var-grid.scss
@for $i from 1 through $grid-columns {
    $flex-width-percentage: percentage($i / $grid-columns);
    $flex-basis: calc(
        #{$flex-width-percentage} - var(--gap, $grid-default-gap) +
            calc(var(--gap, $grid-default-gap) / #{$grid-columns / $i})
    );

    @if ($i == $grid-columns) {
        $flex-basis: 100%;
    }

    @include create-rule-by-breakpoints(
        'col-#{$i}',
        (
            'display': 'flex',
            'flex-basis': '#{$flex-basis} !important',
            'max-width': '#{$flex-basis}  !important',
        )
    );
}

// Justify content
$justify-content-values: flex-start, flex-end, center, space-between, space-around;
@each $value in $justify-content-values {
    .justify-content-#{$value} {
        @include justify-content($value !important);
    }
}

// Align-content
$align-content-values: flex-start, flex-end, center, space-between, space-around, stretch;
@each $value in $align-content-values {
    .align-content-#{$value} {
        @include align-content($value !important);
    }
}

// Align-items
$align-items-values: flex-start, flex-end, center, baseline, stretch;
@each $value in $align-items-values {
    .align-items-#{$value} {
        @include align-items($value !important);
    }
}

// Align-self
$align-self-values: auto, flex-start, flex-end, center, baseline, stretch;
@each $value in $align-self-values {
    .align-self-#{$value} {
        @include align-self($value !important);
    }
}
