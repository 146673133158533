.#{$css-namespace}badge--light {
    @extend %badge;
    background: $color-primary;
    border: none;
    color: $color-white;
    padding: #{$space-1 / 2} 1rem 0.5rem;
    line-height: 1.6rem;

    &.green {
        background: $color-success-background;
        color: $color-success-secondary;
        padding: #{$space-1 / 2} 1.4rem 0.5rem 0.6rem;
    }

    > .#{$css-namespace}icon + span {
        margin-left: 1.4rem;
        font-weight: $font-weight-medium;
    }
}
