.accountopeningrequest-error-page {
    text-align: center;

    &.accountopeningrequest-container {
        &:not(.p-0) {
            max-width: 100%;
            margin: 2rem auto;
            padding: 2rem;

            @include min-screen($screen-md) {
                padding: $space-5;
                margin: $space-5 auto;
            }
        }
    }

    > p {
        color: $color-main-dark;
        line-height: 2rem;
        margin-bottom: $space-3;
    }

    .store-locator-cta {
        background-color: $color-blue-background-1;
        margin-top: 1.7rem;
        margin-bottom: $space-3;
        padding: 2rem;

        @include min-screen($screen-md) {
            padding: $space-3;
        }

        .dcfonts-icons {
            color: $color-primary;
            font-size: 2.4rem;
        }

        .#{$css-namespace}btn {
            // TODO : Refacto needed and uniformisation
            display: inline-block;
            width: auto;
            border: none;
            padding: 1rem $space-3;
        }
    }

    .#{$css-namespace}title {
        &[data-ui-title-level='1'] {
            margin-bottom: $space-3;
            padding: 0;
            font-size: $font-size-xxl;
            line-height: 3.5rem;
            font-weight: $font-weight-bold;

            @include min-screen($screen-md) {
                padding: 0 2.5rem;
            }
        }

        &[data-ui-title-level='3'] {
            margin: $space-2 0;
            line-height: 2.5rem;
            font-size: $font-size-xl;
            font-weight: $font-weight-semi-bold;
        }
    }

    .#{$css-namespace}btn {
        // TODO : Refacto needed and use w-full
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
