@import '../../../../../Descours/Descours/styles/mixins/_media-queries.scss'; //@TODO: shift toolkit styles globally

%table-row {
    border: 0.1rem solid $color-disabled-light;

    @include max-screen($screen-sm) {
        display: flex;
        flex-wrap: wrap;

        &:first-child,
        &:nth-child(2) {
            border-top: 0.1rem solid $color-disabled-light;
        }
    }

    > td {
        padding: 0 $space-2 $space-2;
        vertical-align: middle;
        text-align: left;

        &:first-child {
            @include max-screen($screen-sm) {
                padding: 1rem $offset-m 3rem;
            }
        }

        @include min-screen($screen-sm) {
            padding: $offset 0;

            &:first-child {
                padding-left: $offset-l;
            }

            &:last-child {
                padding-right: $offset-l;
            }

            &:not(:first-child) {
                text-align: center;
            }
        }
    }
}
