.#{$css-namespace}datatable {
    &_container {
        @include max-screen($screen__l) {
            padding: 2rem 2rem !important;
        }
    }

    &_filters {
        // TODO : Refacto
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 0.3rem;
        padding: 2rem 1rem;
        background-color: $color-white-smoke-2;

        @include max-screen($screen__l) {
            &.padding-bottom-400 {
                padding-bottom: $space-5;
            }
        }

        @include min-screen($screen__l) {
            flex-direction: row;
            align-items: center;
            padding: 3rem;
            height: 10rem;
        }

        input::placeholder,
        label.placeholder {
            color: $color-grey-dove;
        }
    }

    &_filter-container {
        position: relative;
        background-color: $color-white;
        border-radius: 0.3rem;
        display: flex;

        .quotation-list-select-icon {
            top: 1rem !important;
        }

        &.date-filters {
            margin-top: 1rem;
            height: 4rem;

            @include max-screen($screen__l) {
                flex-direction: column;
                height: auto;
            }

            .date-range {
                display: flex;
            }

            .#{$css-namespace}form-field-wrapper {
                display: flex;
                align-items: center;

                &:last-child {
                    .control {
                        @include max-screen($screen__l) {
                            margin-right: 0;
                        }
                    }
                }

                .control {
                    margin-right: 1rem;

                    .input-text {
                        padding-right: 3rem;

                        @include min-screen($screen__l) {
                            width: 14rem;
                        }
                    }

                    .label {
                        cursor: text;
                        pointer-events: auto;
                    }

                    .label-icon {
                        cursor: pointer;
                        color: $color-primary;
                        position: absolute;
                        left: auto;
                        right: 0;
                        font-size: 1.6rem;
                        top: 0;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        padding: 0 1rem 0 0.5rem;
                    }
                }
            }

            span {
                margin-right: 1rem;
            }

            .#{$css-namespace}btn {
                display: block;
                margin-left: 2rem;
                width: 12rem;
                text-align: center;
                padding-top: 1rem;
                padding-bottom: 1rem;

                @include max-screen($screen__l) {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 2rem;
                }
            }
        }

        .control {
            display: flex;
            align-items: center;

            span {
                margin-right: 1rem;
            }

            .#{$css-namespace}input-text.input-text {
                border-color: $color-main-dark;
                border-radius: 0.3rem;
                height: $custom-input-text-height;
                border-width: 0.1rem;
                background: $color-white;
                padding: 1.1rem 1.5rem 1rem;
                font-family: $font-default;
                line-height: 2rem;
            }
        }
    }

    &_status-filter {
        &.#{$css-namespace}select--custom {
            &:not(.nice-select) {
                max-height: 4rem;

                ~ label {
                    display: none;
                }

                + .nice-select {
                    ~ label {
                        display: block;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            &.nice-select {
                height: 4rem;
                border: 0.1rem solid $color-grey-dove;
                border-radius: 0.3rem;
                background: transparent;
                z-index: 1;

                @include min-screen($screen__l) {
                    width: 25.7rem;
                }

                &::after {
                    display: none;
                }

                &.--hasValue,
                &.open {
                    .list {
                        width: calc(100% + 0.2rem);
                        margin: -0.1rem 0 0 0;
                        border-top-color: $color-disabled-light;

                        &::before {
                            content: '';
                            background: $color-white;
                            position: absolute;
                            top: -0.6rem;
                            left: 0;
                            width: 100%;
                            height: 0.5rem;
                        }
                    }

                    ~ label {
                        display: block;
                        top: -$space-1 !important;
                        background: none !important;
                        transform: none;
                    }
                }

                &.open {
                    overflow-y: initial;
                    border-bottom: none;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    &:not(.--hasValue) {
                        .current {
                            color: $color-disabled;
                        }
                    }

                    ~ i {
                        transform: rotate(180deg) !important;
                        transform-origin: 50% 32% !important;
                    }
                }

                ~ i {
                    z-index: 0;
                    transform: translateY(calc(-50% - 0.1rem));
                    color: $color-primary;
                    border: none;
                    width: 1.6rem;
                    height: 1.6rem;
                    top: 50% !important;
                    font-style: normal;
                    font-size: 1.6rem;

                    &::after {
                        font-family: $font-icons;
                        content: $caret-down;
                    }
                }

                ~ label {
                    z-index: 1;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0.1rem;
                        width: 100%;
                        height: 0.8rem;
                        bottom: 0;
                        z-index: -1;
                        background: $color-white;
                    }
                }

                &.--hasValue {
                    .current {
                        color: $color-main-dark;
                    }
                }

                .current {
                    color: $color-grey-dove;
                    height: auto;
                    opacity: 1;
                    text-transform: lowercase;
                    padding-left: 0;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                .list {
                    margin-top: 0;
                    border: 0.1rem solid $color-gray83;
                    border-top-color: transparent;
                    border-bottom-left-radius: 0.3rem;
                    border-bottom-right-radius: 0.3rem;
                    transform-origin: initial;
                    transform: initial;
                    width: 100%;
                    max-height: initial;
                    padding: 1.5rem 1.5rem 1.7rem 1.5rem;

                    .option {
                        margin: 0;
                        padding: 0;
                        line-height: 1.9rem;
                        display: flex;
                        align-items: center;

                        &:hover {
                            background-color: transparent !important;
                        }

                        &:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }

                        &[data-value=''] {
                            + .option {
                                margin-top: 0;
                            }
                        }

                        &.selected {
                            span {
                                color: $color-secondary;
                                font-weight: $font-weight-bold;
                            }

                            input[type='checkbox']:after {
                                background-color: $color-secondary;
                                border-color: $color-secondary;
                            }
                        }

                        input[type='checkbox'] {
                            height: 1.5rem;
                            width: 1.5rem;
                            border-radius: 0.2rem;
                            border-color: $color-disabled;

                            &:checked {
                                background-color: $color-secondary;
                                border-color: $color-secondary;
                            }
                        }

                        span {
                            height: 1.9rem;
                            opacity: 1;
                            color: $color-main-dark;
                            font-size: $font-size-default;
                            text-transform: lowercase;

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    &_search-container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;
        border-radius: 0.3rem;
        height: 4rem;
        background-color: $color-white;
        box-shadow: var(--box-shadow);

        @include min-screen($screen__l) {
            margin-bottom: 0;
            margin-right: 3rem;
            width: 60rem;
        }
    }

    &_search-icon {
        padding-left: 1rem;
        font-size: $font-size-xl;
        color: #666666; // TODO : use $color-gray40 var instead of hexa
    }

    &_search-input[type='text'] {
        // Remove attribute selector once global style fixed.
        border: none;
        height: 4rem;
        padding: 1rem;

        &::placeholder {
            color: $color-grey-dove;
        }
    }

    &_reset-filters-button {
        &.#{$css-namespace}link {
            display: none;
            position: absolute;
            bottom: -0.5rem;
            left: 0;
            transform: translateY(100%);
            font-size: $font-size-s;
            text-decoration: underline;
            color: $color-main-dark;
            border: none;
            background: none;
            font-weight: $font-weight-regular;
            padding: 0;
        }
    }
}
