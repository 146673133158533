.quotation-create-index {
    background-color: $color-grey-background;

    .page-wrapper {
        min-height: auto;

        .breadcrumbs {
            margin: $space-2 auto;
        }

        .page-main {
            .columns {
                padding-bottom: $space-5;
                margin-top: $space-4;

                .column.main {
                    padding: 0;
                    background: none;
                    box-shadow: none;
                    margin: 0;
                }

                @include max-screen($screen__l) {
                    margin-top: $space-1;
                }

                @include max-screen($screen__m) {
                    padding-bottom: 0;
                }

                .main {
                    background-color: transparent;
                    box-shadow: none;
                    padding: 0;
                }
            }
        }
    }

    .#{$css-namespace}quotation_content {
        align-items: baseline;
    }

    .#{$css-namespace}wishlist_products-section {
        .#{$css-namespace}products-section_products-table {
            .#{$css-namespace}order-items_wrapper {
                .#{$css-namespace}item-table_header {
                    background-color: $color-background-page;
                    border: 0.1rem solid $color-grey-argent;
                    border-bottom: 0;

                    &-row {
                        border-color: $color-gray83;
                        border-bottom: none;
                    }

                    .#{$css-namespace}item-table_header-row {
                        th {
                            font-weight: $font-weight__regular;
                            color: $color-grey-dove;

                            &.col {
                                &:last-child {
                                    display: none;
                                }

                                &.name {
                                    padding-left: 14.8rem;

                                    @include min-screen($screen__l) {
                                        width: 40.65%;
                                    }
                                }

                                &.price {
                                    @include min-screen($screen-sm) {
                                        width: 14rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .#{$css-namespace}item-table_body {
                    .#{$css-namespace}item-table_row {
                        td {
                            &.#{$css-namespace}item-table_row-item {
                                @include min-screen($screen__l) {
                                    width: 40rem;
                                }
                            }

                            &.#{$css-namespace}item-table_row-qty,
                            &.#{$css-namespace}item-table_row-price {
                                padding: $space-1;
                            }

                            &.#{$css-namespace}item-table_row-add-to-cart {
                                display: none;
                            }
                        }
                    }
                }
            }

            ~ [data-js-specific-item-table] {
                .#{$css-namespace}item-table_header {
                    display: none;
                }

                .#{$css-namespace}item-table_row {
                    border-top: 0;
                }
            }
        }

        .#{$css-namespace}wishlist_products-section_bottom-actions {
            &_clear-list {
                span {
                    line-height: 1.9rem;
                    text-decoration: none;
                    color: $color-main-dark;
                }
            }

            &.force-display,
            &.force-display-specific {
                display: flex;
            }

            .#{$css-namespace}btn {
                // TODO : Refacto needed using var ?
                min-width: 23.9rem;
            }
        }
    }

    .#{$css-namespace}wishlist_products-section {
        &--display {
            display: block;
        }
    }

    .#{$css-namespace}quotation {
        .#{$css-namespace}title[data-ui-title-level='1'] {
            margin-top: 0;
            line-height: 4rem;
        }
    }

    .#{$css-namespace}quotation_description {
        @include min-screen($screen__l) {
            margin-bottom: $space-5;
        }
    }
}

.#{$css-namespace}specific-item_wrapper.#{$css-namespace}form-field-wrapper {
    margin: 0 !important;
}

.#{$css-namespace}quotation_clear {
    @include max-screen($screen__l) {
        margin-top: $space-2;
    }
}

.#{$css-namespace}quotation_step-content {
    background: $color-white;
}

.#{$css-namespace}quotation_wrapper {
    @include lib-css-wrapper();
    padding-bottom: $space-6;

    @include max-screen($screen__l) {
        padding: $space-3 $space-2;

        &.step-1 {
            margin-top: $space-2;
            margin-bottom: 0;
            padding: 0.1rem;
            box-shadow: none;
            background: transparent;
        }

        &.step-2 {
            margin-bottom: $space-4;
            padding-left: $space-1;
            padding-right: $space-1;
        }

        .#{$css-namespace}quotation_step-content {
            padding: $space-3 $space-1;
            box-shadow: var(--box-shadow);

            + .#{$css-namespace}quotation_step-content {
                margin-top: $space-3;
            }
        }

        .#{$css-namespace}quotation_title {
            margin-bottom: $space-1;
        }

        .#{$css-namespace}quotation_description {
            margin-bottom: 0;
        }

        .#{$css-namespace}quotation_content {
            flex-direction: column;
            align-items: start;
            gap: $space-2;

            .form-quotation-name {
                width: 100%;
                margin-bottom: $space-1;

                .fieldset.quotation {
                    width: 100%;
                }
            }

            .#{$css-namespace}quotation_import-selector {
                padding-left: $space-2;
                line-height: 1.9rem;

                @include max-screen($screen-sm) {
                    padding-left: $space-3;
                }
            }
        }

        .#{$css-namespace}block-addbysku_form {
            margin-top: 0;
        }

        .#{$css-namespace}wishlist_products-section {
            .#{$css-namespace}products-section_products-table {
                .#{$css-namespace}order-items_wrapper {
                    .#{$css-namespace}item-table_body {
                        .#{$css-namespace}item-table_row {
                            &_specific {
                                @include max-screen($screen-sm) {
                                    height: 28.5rem;
                                }

                                td {
                                    &.#{$css-namespace}item-table_row-item {
                                        .#{$css-namespace}item-table_row-item_title {
                                            @include max-screen($screen-sm) {
                                                max-width: 100%;
                                                margin-right: $space-1;
                                                margin-bottom: $space-1;
                                            }
                                        }

                                        .#{$css-namespace}item-table_row-picto {
                                            @include max-screen($screen-sm) {
                                                height: auto;
                                            }
                                        }

                                        .#{$css-namespace}item-table_row-delete {
                                            position: static;

                                            [data-js-specific-item-remove] {
                                                @include max-screen($screen-sm) {
                                                    position: absolute;
                                                    top: 1.2rem;
                                                    right: 1.2rem;
                                                }
                                            }

                                            [data-js-specific-item-details='button'] {
                                                @include max-screen($screen-sm) {
                                                    position: absolute;
                                                    bottom: $space-2;
                                                    left: 50%;
                                                    transform: translateX(-50%);
                                                    text-decoration: underline;
                                                }
                                            }

                                            .attachments {
                                                @include max-screen($screen-sm) {
                                                    margin-top: $space-1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &-details {
                                @include max-screen($screen-sm) {
                                    position: static;
                                }
                            }

                            td {
                                &.#{$css-namespace}item-table_row-qty {
                                    @include max-screen($screen-sm) {
                                        float: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .#{$css-namespace}wishlist_products-section_bottom-actions {
                grid-gap: 0;

                &_add-to-quotation {
                    width: 100%;
                }
            }
        }
    }

    &.step-1 {
        @include min-screen($screen__l) {
            padding-bottom: $space-4;
        }
    }

    &.step-2 {
        margin-top: $space-2;
        padding: $space-2 $space-1;
        display: flex;
        flex-direction: column;
        gap: $space-2;

        @include min-screen($screen__l) {
            padding: $space-4;
        }

        .#{$css-namespace}block-addbysku {
            &_form {
                margin-bottom: 0;
                padding: $space-2 $space-3;

                @include min-screen($screen__l) {
                    padding-right: $space-2;
                    padding-left: $space-5;
                }
            }
        }
    }

    .actions-toolbar {
        margin-bottom: $space-2;
    }
}

.#{$css-namespace}quotation_subtitle.#{$css-namespace}title {
    margin-bottom: $space-2;
}

.#{$css-namespace}quotation_description {
    margin-bottom: $space-6;
}

.#{$css-namespace}quotation_content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-quotation-name {
        @include min-screen($screen__l) {
            flex: 1;
            margin-left: $space-2;
            display: flex;
        }
    }

    .fieldset {
        &.quotation {
            width: 100%;

            @include min-screen($screen__l) {
                margin-left: auto;
                max-width: 65.2rem;
            }

            .quotation-name-input {
                + div.mage-error {
                    margin-bottom: -$space-2;
                    background: $color-white;
                    margin-right: $space-6;
                }
            }
        }

        .secondary {
            display: flex;
            justify-content: space-between;
            font-size: $font-size-s;
            font-style: italic;
            margin: #{$space-1 / 2} 0 $space-1 $space-2;
            font-weight: $font-weight__light;
            line-height: 1.4rem;
        }
    }

    input[type='radio'].hidden {
        visibility: hidden;
    }

    .#{$css-namespace}icon {
        width: 1.7rem;
        font-size: $font-size-m;
        color: $color-secondary;
        vertical-align: middle;
    }

    .#{$css-namespace}form-radio-group {
        align-items: center;
        white-space: nowrap;

        .#{$css-namespace}form-field-wrapper {
            cursor: pointer;

            @include max-screen($screen-sm) {
                margin-right: 0 !important;
                margin-bottom: $space-4 !important;
            }

            &:last-child {
                @include max-screen($screen-sm) {
                    margin-bottom: $space-2 !important;
                }
            }

            &.#{$css-namespace}import_mass-article-list_wrapper {
                display: none;

                @include min-screen($screen__l) {
                    display: block;
                }
            }

            .label {
                padding-left: $space-1;
                vertical-align: middle;

                @include max-screen($screen-sm) {
                    margin-bottom: 0;
                }

                &:hover {
                    color: $color-secondary;
                    text-decoration: underline;
                }
            }

            &--mobile {
                cursor: default;
                display: flex;

                @include min-screen($screen__l) {
                    display: none;
                }

                .label {
                    white-space: normal;
                    padding-left: $space-3;
                    padding-right: $space-3;
                    text-align: left;

                    &:hover {
                        text-decoration: none;
                        color: inherit;
                    }
                }
            }
        }
    }
}
