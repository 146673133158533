@import 'row';
@import 'header';
@import '../../../../../Descours/Descours/styles/mixins/_media-queries.scss'; //@TODO: shift toolkit styles globally

.#{$css-namespace}item-table {
    &_header {
        @extend %table-header;
    }

    &_row,
    &_row_comment {
        @extend %table-row;
        @include max-screen($screen-sm) {
            position: relative;
        }

        &-picto {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &--hidden {
                display: none;
            }
        }

        &-details {
            @include min-screen($screen-sm) {
                display: table-cell;
                vertical-align: middle;
            }
        }

        &-item {
            @include max-screen($screen-sm) {
                display: flex;
                flex-direction: column;
            }

            &_subtotal {
                color: $text-price-color;
                font-size: $font-size-default;
                font-weight: $font-weight-black;
                line-height: 1;
                white-space: nowrap;

                @include min-screen($screen-md) {
                    font-size: $font-size-m;
                }

                &.price-disabled {
                    color: $color-disabled;
                }

                &.price-discount {
                    color: $color-red;
                }

                .taxes::before {
                    content: ' ';
                }
            }

            &_price-unit {
                display: block;
                margin-top: $space-1 / 2;
                margin-bottom: $space-1;
                font-size: $font-size-xs;
                color: $text-price-unit-color;

                @include min-screen($screen-md) {
                    font-size: $font-size-s;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &--lower {
                    text-transform: lowercase;
                }

                &.price-disabled {
                    color: $color-disabled;
                }

                ~ span {
                    display: block;
                    margin-top: 0.5rem;
                }
            }
        }

        &-qty {
            @include max-screen($screen-sm) {
                bottom: 1.2rem;
                left: 0;
                font-weight: $font-weight-medium;
            }
        }

        &-price {
            @include max-screen($screen-sm) {
                top: 18.5rem;
                right: $space-1;
            }
        }

        &.disable-row {
            .#{$css-namespace}item-table_row-item_subtotal,
            .#{$css-namespace}item-table_row-item_price-unit {
                color: $color-disabled;
            }
        }

        & + & {
            border-top: none;
        }
    }

    &_header--secondary {
        display: none !important;

        @include min-screen($screen__l) {
            display: table-header-group !important;
        }

        .#{$css-namespace}item-table_header {
            background: $color-white;

            &-row {
                border: 0.1rem solid $color-disabled-light;
                border-bottom: none;
                font-size: $font-size-s;
                text-transform: uppercase;

                &.border-bottom {
                    border-bottom: 0.1rem solid $color-disabled-light;
                }

                th {
                    font-weight: $font-weight-regular;
                    color: $color-gray43;
                }

                .name {
                    width: 30% !important;
                    padding-left: 8% !important;

                    .d-flex & {
                        flex: 1;
                        width: auto !important;
                        max-width: 45%;
                        padding-left: 2rem !important;
                        padding-right: 3rem;
                    }
                }

                .qty,
                .subtotal {
                    width: 15%;

                    .#{$css-namespace}quotation_section_products-table & {
                        width: 7%;
                    }
                }

                .qty {
                    text-align: center;

                    .d-flex & {
                        flex: 0 0 14rem;
                        max-width: 14rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                }

                .subtotal {
                    .d-flex & {
                        flex: 0 0 25%;
                        max-width: 25%;
                        text-align: center;
                        padding-left: $space-4;
                    }
                }

                .col {
                    .d-flex & {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &_body--secondary {
        .#{$css-namespace}item-table_row-price {
            text-align: left !important;
            padding: $space-1 1rem;

            &.has_promo_price_level {
                @include max-screen($screen-sm) {
                    margin-top: -1.8rem;
                }
            }
        }

        .#{$css-namespace}item-table_row:nth-child(even) {
            background: none !important;
        }

        .availability {
            position: absolute;
            top: 17rem;

            @include min-screen($screen__l) {
                position: static;
            }
        }
    }
}
