$order-items-row-padding-lg: 2rem;

.#{$css-namespace}order-items {
    &_wrapper {
        &:not(:first-child) {
            margin-top: $offset-xxl;

            @include max-screen($screen-sm) {
                margin-top: 5rem;
            }
        }
    }

    &_title {
        margin-bottom: $offset-l;
        font-family: $font-secondary;
        font-size: $font-size-xxl;
        font-weight: $font-weight-bold;
        color: $color-primary;

        &--pickup {
            color: $color-checkout-delivery-pickup;
        }
    }

    &_title--ellispis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }

    &_table {
        $itemTable: '.#{$css-namespace}item-table';
        table-layout: fixed;

        .#{$css-namespace}item-table_header {
            .col {
                &.name {
                    width: auto;
                }

                &.qty,
                &.subtotal {
                    width: 15.5%;
                }
            }
        }

        .#{$css-namespace}item-table_body {
            .#{$css-namespace}item-table_row-item {
                padding: $space-2;
                position: relative;
            }

            td:last-of-type.#{$css-namespace}item-table_row-price {
                text-align: right;
                padding-right: $space-2;
            }

            .#{$css-namespace}item-table_row-price {
                text-align: center;

                .account:not(.wishlist-top_achat-index):not(.wishlist-index-index) & {
                    text-align: right;
                }

                .sales-order_view-weborder & {
                    &:last-of-type {
                        padding-right: $space-4;
                    }
                }

                .#{$css-namespace}product_promo__old_price {
                    @include max-screen($screen-sm) {
                        display: block;
                        padding-right: 0;
                    }
                }

                @include max-screen($screen-md) {
                    padding-top: #{$space-1 / 2} !important;

                    .#{$css-namespace}sales-order-view & {
                        padding-top: 0 !important;
                    }
                }
            }
        }

        // If next_price_level
        .has-quantity-promo {
            @include min-screen($screen-md) {
                .#{$css-namespace}item-table_row-availability {
                    vertical-align: top;

                    &.small-spacing {
                        padding-left: 0;
                    }

                    &:not(.small-spacing) small {
                        height: 4rem !important;
                        margin-top: 1.8rem;
                        margin-bottom: 0.5rem;
                    }
                }

                .small {
                    display: flex;
                    align-items: center;

                    .#{$css-namespace}round_light_ctn {
                        display: flex;
                    }
                }
            }

            &:has(.#{$css-namespace}item-table_row-promo-text.fullwidth) td:not(.fullwidth) {
                @include min-screen($screen-md) {
                    padding-bottom: 1rem !important;
                }
            }

            td {
                vertical-align: top;

                &:not(.fullwidth) {
                    @include min-screen($screen-md) {
                        padding-top: $space-3 !important;
                        padding-bottom: $space-3 !important;
                    }
                }

                &.#{$css-namespace}item-table_row-qty {
                    .promo-tooltip {
                        position: relative;
                        left: 0;
                        margin-left: 0;

                        @include min-screen($screen-md) {
                            right: 0;
                            left: auto;
                            transform: translateX(-#{$space-6});
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .#{$css-namespace}item-table_row-availability:not(.small-spacing) {
                .small {
                    @include min-screen($screen-md) {
                        min-height: 5rem;
                        padding-top: 0;
                    }

                    .cart & {
                        @include min-screen($screen-md) {
                            min-height: 0;
                        }
                    }
                }
            }

            .quantity-picker-container--bigger {
                min-height: 17.5rem !important;

                @include min-screen($screen-md) {
                    min-height: 19.3rem !important;
                }

                .promo-tooltip {
                    @include min-screen($screen__m) {
                        margin-top: 0.5rem !important;
                    }
                }
            }

            @include min-screen($screen-md) {
                .#{$css-namespace}item-table_row-price {
                    vertical-align: top;

                    &_ctn {
                        height: 100%;
                        padding-top: 1.3rem;
                    }
                }
            }

            @include min-screen($screen-md) {
                .#{$css-namespace}product_promo {
                    + .#{$css-namespace}item-table_row-item_subtotal {
                        padding-top: 0;
                    }
                }
                .#{$css-namespace}item-table_row-item_subtotal {
                    padding-top: 0.9rem;
                    white-space: nowrap;
                }
            }

            .#{$css-namespace}item-table_row-qty {
                .quantity-picker-container {
                    .#{$css-namespace}form-quantity-picker_update-link {
                        position: relative;
                    }

                    .promo-tooltip {
                        margin-left: 0;

                        @include min-screen($screen__m) {
                            margin-left: 0.2rem;
                            margin-top: $space-1 / 2;
                        }

                        &--orderRequest {
                            margin-top: 1rem;
                            margin-left: 2.2rem;

                            @include min-screen($screen__m) {
                                margin-top: 1rem;
                                margin-left: 6rem;
                            }
                        }
                    }
                }
            }
        }

        .has_promo_price_content {
            .has_promo {
                .#{$css-namespace}item-table_row-price_ctn {
                    @include min-screen($screen-md) {
                        padding-top: 0;
                    }
                }
            }
        }

        .has-quantity-promo .has_promo_price_level .#{$css-namespace}item-table_row-price_ctn {
            @include min-screen($screen-md) {
                padding-top: 2.1rem !important;
            }
        }

        .has_promo_price_content {
            .#{$css-namespace}item-table_row-price_ctn {
                padding-top: $space-1 / 2;

                @include min-screen($screen-md) {
                    padding-top: 1rem !important;
                }
            }

            .has_promo_price_level {
                @include min-screen($screen-md) {
                    vertical-align: top;
                }

                .#{$css-namespace}item-table_row-price_ctn {
                    padding-top: 0;

                    @include min-screen($screen-md) {
                        padding-top: $space-1 !important;
                        margin-top: 0;
                    }
                }
            }

            .price-picker-container--bigger {
                @include min-screen($screen-md) {
                    vertical-align: top;
                }

                .#{$css-namespace}item-table_row-price_ctn {
                    padding-top: 1rem;

                    @include min-screen($screen-md) {
                        padding-top: $space-1 !important;
                        margin-top: 0;
                    }
                }
            }
        }

        &.d-flex {
            display: flex;
            flex-flow: row wrap;

            #{$itemTable}_header {
                &--secondary {
                    width: 100%;

                    @include min-screen($screen__l) {
                        display: flex !important;
                    }
                }

                &-row {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                }
            }

            #{$itemTable}_body {
                width: 100%;
            }
        }
    }

    .#{$css-namespace}item-table {
        $table: &;

        &_header {
            @include max-screen($screen-sm) {
                display: none;
            }

            &-row {
                background-color: $color-white;
                color: $color-gray43;

                > th:first-child {
                    @include min-screen($screen-sm) {
                        width: 65%;
                        padding-left: 2.7rem;
                    }
                }

                th {
                    font-weight: $font-weight-regular;
                    font-size: $font-size-s;
                    text-transform: uppercase;

                    &.cart {
                        width: 21%;
                    }
                }
            }
        }

        &_row {
            min-height: 15rem;
            padding: $space-1;

            @include min-screen($screen-sm) {
                min-height: 10rem;
                padding: $order-items-row-padding-lg;
            }

            &.no-border-top {
                border-top: none;
            }

            // Tips to keep right border, table you know
            &--white,
            &--grey {
                position: relative;
                z-index: 2;

                &::before {
                    content: '';
                    position: absolute;
                    width: calc(100% - 0.2rem);
                    height: calc(100% - 0.2rem);
                    top: 0.1rem;
                    left: 0.1rem;
                    z-index: -1;
                }
            }

            &--white,
            &--white:nth-child(even) {
                &::before {
                    background-color: $color-white;
                }
            }

            &--grey,
            &--grey:nth-child(even) {
                &::before {
                    background-color: rgba($color-grey-argent, 0.25);
                }
            }

            &-promo-badge {
                padding: 0;
                border: 0.1rem solid $color-grey-argent;
                border-bottom: none;
                min-height: auto;

                .#{$css-namespace}item-table_row-promo-badge {
                    &-wrapper {
                        padding: 0;
                    }

                    &-text {
                        margin: $space-1 0 0;

                        @include min-screen($screen-sm) {
                            margin: $space-1 0 0 $space-1;
                        }
                    }
                }
            }

            &-picto {
                img {
                    vertical-align: bottom;
                    width: auto;
                    height: auto;
                    max-height: 100%;

                    @include min-screen($screen-sm) {
                        max-height: 6rem;
                        width: auto;
                    }
                }

                .disable-row & {
                    opacity: 0.5;
                }
            }

            &-details {
                @include max-screen($screen-sm) {
                    margin-top: 1rem;
                }

                &--inline {
                    padding-top: $space-1 / 2;
                    display: flex;
                    flex-direction: column;
                }
            }

            &-delete {
                flex: 1;
                align-items: end;
                display: flex;
            }

            &-add-to-cart,
            &-add-to-quotation {
                a,
                .#{$css-namespace}btn {
                    // TODO : Refacto needed using w-full
                    width: 100%;
                }
            }

            &-delete {
                flex: 1;
                align-items: end;
                display: flex;
            }

            &-add-to-cart,
            &-add-to-quotation {
                a,
                .#{$css-namespace}btn {
                    // TODO : Refacto needed using w-full
                    width: 100%;
                }
            }

            &-picto {
                &--inline {
                    width: 7.5rem;
                    padding: 0;
                    margin-right: 2rem;
                    margin-left: 0;
                }
            }

            &-delete {
                flex: 1;
                align-items: end;
                display: flex;

                a {
                    font-size: $font-size-s;
                    color: $color-secondary;
                    text-decoration: underline;

                    @include lib-hover-focus {
                        color: $color-sub-secondary;
                        text-decoration: none;
                    }
                }
            }

            &-picto {
                &--inline {
                    width: 7.5rem;
                    padding: 0;
                    margin-right: 2rem;
                    margin-left: 0;
                }
            }

            &-actions,
            &-delete {
                flex: 1;
                align-items: end;
                display: flex;

                a,
                button {
                    font-size: $font-size-s;
                    color: $color-secondary;
                    text-decoration: underline;

                    @include lib-hover-focus {
                        color: $color-sub-secondary;
                        text-decoration: none;
                    }
                }
            }

            &-item {
                &--inline {
                    display: flex;
                    padding: 1.2rem 1.2rem 1.2rem 4.3rem !important;
                }

                &_title {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    height: fit-content;
                    max-height: 5.8rem;

                    @include max-screen($screen__xs) {
                        // Trash icon size
                        width: calc(100% - 3rem);
                    }

                    &:first-letter {
                        text-transform: uppercase;
                    }

                    @include min-screen($screen__m) {
                        max-width: none;
                    }

                    @include min-screen($screen-sm) {
                        -webkit-line-clamp: 2;
                        max-height: 4.35rem;
                    }

                    &:hover {
                        .tooltip-text {
                            visibility: visible;
                        }
                    }

                    .tooltip-text {
                        @include max-screen($screen__m) {
                            display: none;
                        }
                        @include min-screen($screen__m) {
                            top: $space-8;
                            right: 0;
                            left: 0;
                            margin: 0 auto;
                            width: auto;
                        }

                        position: absolute;
                        z-index: 2;
                        visibility: hidden;
                        width: 23.2rem;
                        padding: $indent__s;
                        text-align: center;
                        color: $color-white;
                        border-radius: 0.4rem;
                        background: $color-primary;
                        box-shadow: 0 0.5rem 0.3rem 0 $color-tertiary-10; // TODO: Use box-shadow var

                        &::before {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                            display: block;
                            width: 1.2rem;
                            height: 1.2rem;
                            content: '';
                            background: inherit;
                        }
                    }
                }

                &_brand {
                    color: $brand-text-color;
                    text-transform: $brand-text-case;
                    font-weight: $brand-text-weight;

                    &:has(.hyphen) {
                        display: block;
                        margin-bottom: $space-1 / 2;

                        @include min-screen($screen-sm) {
                            display: inline;
                            margin-bottom: 0;
                        }

                        .hyphen {
                            color: $color-black;
                            font-weight: $font-weight-regular;
                            display: none;

                            @include min-screen($screen-sm) {
                                display: inline;
                            }
                        }
                    }
                }

                &_specific {
                    color: $color-grey-dark;
                }

                &_reference {
                    font-size: $reference-text-size;
                    line-height: $reference-text-line-height;
                    font-weight: $reference-text-weight;
                }
            }

            &-qty-label {
                vertical-align: middle;

                @include min-screen($screen-sm) {
                    display: none;
                }
            }

            &-price {
                text-align: left;
                @include min-screen($screen-lg) {
                    padding-left: 2rem;
                }

                @include max-screen($screen__m) {
                    padding-top: 0 !important;
                }

                &.has_promo {
                    color: $color-red;
                }

                &.has_promo_level {
                    .#{$css-namespace}item-table_row-item_subtotal {
                        color: $color-red;
                        padding-top: 0.3rem;
                    }
                }

                .disable-row & {
                    opacity: 0.5;
                    filter: grayscale(1);
                }
            }

            &-promo-text {
                &-content {
                    gap: $space-1 / 2;
                    padding: $space-1 $space-2;
                    color: $color-main-dark;
                    border-radius: 0.3rem;
                    font-weight: $font-weight__regular;
                    width: 100%;
                    justify-content: center;
                    text-align: center;

                    > span {
                        flex: 0 0 auto;
                    }
                }

                &-price {
                    color: $color-red-promo;
                    font-weight: $font-weight__bold;
                }
            }

            &.d-flex {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                gap: $space-1 / 2;
                padding: 0;

                &::after {
                    display: none !important;
                }

                td {
                    padding: $space-2;

                    @include min-screen($screen-sm) {
                        padding: $space-1;
                    }

                    &.fullwidth {
                        flex: 0 0 100%;
                        width: 100%;
                        clear: both;

                        @include min-screen($screen__m) {
                            padding-top: $space-1 / 2;
                        }
                    }
                }

                .#{$css-namespace}item-table_row {
                    &-promo-badge {
                        padding: 0;
                        margin-bottom: -0.5rem;
                        border: none;

                        &-text {
                            margin-top: $space-1;
                            margin-left: 0;

                            @include min-screen($screen-lg) {
                                margin-left: $space-1;
                            }

                            &:empty {
                                display: none;
                            }
                        }
                    }

                    &-qty {
                        align-items: center;

                        .quantity-picker-container {
                            position: relative;

                            .#{$css-namespace}link {
                                font-weight: $font-weight-medium;
                            }
                        }

                        .#{$css-namespace}form-quantity-picker_input-wrapper {
                            padding-top: $space-1;
                        }
                    }

                    &-details {
                        text-align: left;
                    }

                    &-availability {
                        padding-left: 0;
                        margin-top: $space-1;

                        @include min-screen($screen-sm) {
                            margin-top: 0;
                        }
                    }

                    @include max-screen($screen-sm) {
                        &-qty,
                        &-price {
                            position: static;
                        }

                        &-qty {
                            bottom: auto;
                        }

                        &-price {
                            top: 0;
                            right: auto;
                            width: calc(100% - #{$space-20});
                        }

                        &-availability {
                            margin-bottom: 0;
                            width: 100%;
                            padding: $space-1;
                            flex: none;
                        }

                        &-promo-text-content {
                            display: block;
                            padding-left: $space-2;
                            padding-right: $space-2;
                        }
                    }

                    @include max-screen($screen__m) {
                        &-item {
                            position: static;
                            width: 100%;

                            &_title {
                                margin-right: 0;
                            }
                        }

                        &-details {
                            position: static;
                            max-width: 36.7rem;
                        }
                    }

                    @include min-screen($screen__m) {
                        &-item {
                            flex: 1;
                            position: relative;
                        }

                        &-qty {
                            flex: 0 0 14rem;
                            max-width: 14rem;
                        }

                        &-price {
                            flex: 0 0 32%;
                            max-width: 32%;

                            &_ctn {
                                padding-top: 0;

                                @include min-screen($screen-lg-2) {
                                    padding-top: 1.5rem;
                                }
                            }

                            &:last-of-type {
                                text-align: right;
                                padding-right: $space-4;
                            }
                        }

                        &-promo-text-wrapper {
                            margin-left: $space-13;
                            margin-right: $space-1;
                        }
                    }
                }
            }
        }
    }
}
