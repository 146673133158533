$search-bar-height: 5.4rem !default;
$menu-slider-height: 4rem !default;
$margin-height-static: 3.2rem !default;
$margin-height-scrolled: 2.8rem !default;
$border-bottom-active: 0.4rem !default;
$dropdown-index: 25 !default;
$shift-right: -3.5rem !default;

@import 'header/logo';
@import 'header/menu-burger';
@import 'header/quick-access';
@import 'header/search';
@import 'header/skip';

.header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: $space-1 $space-2;

    &:has(.isLogged) {
        // Logged-in mode
        gap: $space-1 $space-4;
    }

    @include max-screen($screen__m) {
        position: relative;

        .--scrolled & {
            flex-wrap: nowrap;
        }
    }

    @include min-screen($screen__m) {
        flex-wrap: nowrap;

        &,
        &:has(.isLogged) {
            // Logged-in mode
            gap: $space-3;
        }
    }

    &__wrapper {
        background-color: $color-white;
        width: calc(100% - var(--scroll-bar-gap, 0px));
        box-shadow: var(--box-shadow);
        position: fixed;
        top: 0;
        z-index: 150;

        [data-js-menu='nav-open'] & {
            @include min-screen($screen__l) {
                z-index: 100;
            }
        }

        @include max-screen($screen__m) {
            .--scrolled & {
                gap: $space-1;
                padding-bottom: $space-1;
            }
        }
    }

    &__wrap {
        max-width: $screen-xl;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: $space-2;
        padding: 0 $space-2;

        @include min-screen($screen__m) {
            padding-left: $space-3;
            padding-right: $space-3;
            padding-top: $space-2;
        }

        h5 {
            margin: 0 0 $indent__xs 0;
        }
    }
}
