@mixin slider-prev-next-buttons {
    &-prev,
    &-next {
        color: $color-primary;
        background-color: $color-white;
        font-size: $font-size-m;
        padding: $space-1;
        border: 0.1rem solid $mainNeutralColor;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.3rem;
        transition: border var(--transition-normal);

        &:not(.slick-disabled) {
            &:hover {
                border-color: $color-primary;
            }
        }

        .dcfonts-icons {
            height: $space-2;
            width: $space-2;
        }
    }

    &-disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;

        .dcfonts-icons {
            color: $mainNeutralColor;
        }
    }
}

.#{$css-namespace}equivalent-products {
    &__products-slider {
        width: calc(100% - 2rem);

        @include min-screen($screen__l) {
            width: calc(100% - #{$slide-width + $space-3});
            padding-right: 0;
            height: 100%;
        }

        &:not(.slick-initialized) {
            display: flex;
            gap: 2rem;
        }

        .slick {
            &-slide {
                margin-right: $space-3;
                width: $slide-width;
                height: auto;

                > div {
                    height: 100%;
                }
            }

            &-list {
                height: 100%;
            }

            &-track {
                display: flex;
                margin-left: 0;
                height: 100%;
            }

            /**
             * Apply prev/next base appearance and specify positioning
             */
            @include slider-prev-next-buttons;

            &-prev,
            &-next {
                position: absolute;
                top: 0;
                right: 0;
            }

            &-prev {
                right: 2.5rem;
            }

            &-next {
                right: -$space-1;
            }
        }
    }

    &__slide {
        height: 100%;
        display: flex !important;
    }
}
