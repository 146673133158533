//
//  Variables
//  _____________________________________________

$autocomplete__background-color: $color-white !default;
$autocomplete__border: 0.1rem solid $form-element-input__border-color !default;
$autocomplete-item__border: 0.1rem solid $color-gray90 !default;
$autocomplete-item__hover__color: $color-gray91 !default;
$autocomplete-item-amount__color: $color-gray60 !default;

.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-size: 3.5rem,
            $_icon-font-line-height: 3.3rem,
            $_icon-font-color: $minicart-icons-color,
            $_icon-font-color-hover: $minicart-icons-color-hover,
            $_icon-font-color-active: $minicart-icons-color-hover,
            $_icon-font-text-hide: true
        );
        display: inline-block;
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .control {
        border-top: 0.1rem solid $border-color__base;
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -0.1rem;
        padding: 0 $layout__width-xs-indent;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 0.7rem;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 2.5rem;

                    &::after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 0.6rem;
                        width: 2.5rem;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error:not(:empty) {
                    left: 0;
                    position: absolute;
                    top: 3.2rem;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 4.5rem;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 0.6rem;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}
