.gallery-placeholder {
    position: relative;
    border: 0.1rem solid $color-blue-light;
    border-radius: 0.3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 34.7rem;
    max-height: 47.1rem;

    @include min-screen($screen__l) {
        margin-bottom: 0;
        max-width: 39.45rem;
        flex: 1;
        height: 47.1rem !important;
    }

    .gallery-item {
        appearance: none;
        padding: 0;
        border: none;
        display: flex;
        background: transparent;
        justify-content: center;
        align-items: center;

        .product-image {
            max-width: 32rem;
            max-height: 32rem;
            margin: 0 auto;
        }
    }

    .thumbnails-wrapper {
        display: flex;
        gap: $space-2;
        border-top: 0.1rem solid $color-blue-light;
        min-height: 5.8rem;
        padding: 1.3rem $space-2;
        position: relative;
        margin-top: auto;
        min-width: 0;

        &:not(:has(.slick-initialized)) {
            * {
                display: none;
            }
        }

        .thumbnails-container {
            display: flex;
            min-width: 0;
        }

        .zoom-product-image {
            appearance: none;
            background: transparent;
            border: none;
            font-family: $font-icons;
            font-size: 2.4rem;
            color: $color-primary;
            padding: 0.3rem;
            margin-left: auto;
            display: flex;
            align-items: center;
        }
    }

    .product-nav-btn {
        appearance: none;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        color: $color-primary;
        font-size: $font-size-m;
        position: relative;
        z-index: 2;
        padding: 0;

        &--prev {
            margin-right: $space-2;
            justify-content: flex-start;
        }

        &--next {
            margin-left: $space-1;
            justify-content: flex-end;
        }
    }
}

#main-image-slider {
    height: 100%;
    display: flex;
    align-items: center;

    @include min-screen($screen__l) {
        min-height: 41.4rem !important;
    }

    &:not(.slick-initialized) {
        justify-content: center;
        height: 100%;

        > * + * {
            display: none;
        }
    }

    .slick-list {
        width: 100%;
    }
}

#thumbs-slider {
    min-width: 0;

    .slick-list {
        margin: 0 -#{$space-1};
    }

    .slick-slide {
        width: 3rem;
        height: 3rem;
        padding: 0.2rem;
        border: 0.1rem solid $color-blue-light;
        position: relative;
        cursor: pointer;
        margin: 0 $space-1;

        &.slick-current {
            cursor: default;

            &:after {
                content: '';
                position: absolute;
                top: -0.1rem;
                right: -0.1rem;
                bottom: -0.1rem;
                left: -0.1rem;
                border: 0.2rem solid $color-primary;
            }
        }

        img {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
}
