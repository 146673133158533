//
//  Shopping cart
//  ---------------------------------------------

$border-color__base: #c7c6c4; // TODO: Same as $color-gray64
$checkout-steps-margins: $space-3;

// layout
.checkout-cart-index,
.checkout-index-index {
    background: $color-grey-background;

    .#{$css-namespace}progress-bar {
        .#{$css-namespace}link-back {
            display: none;

            @include min-screen($screen__l) {
                display: block;
            }
        }
    }

    .columns + .product-left-container {
        margin-top: -$checkout-steps-margins;

        @include min-screen($screen__l) {
            margin-top: -4rem;
        }
    }

    .#{$css-namespace}item-category_row {
        @include max-screen($screen__l) {
            padding-left: 1.5rem;
        }
    }

    .column.main {
        padding: $indent__base 3rem;
    }

    .block-dashboard-info {
        display: none;
    }

    // override layout
    @include max-screen($screen__l) {
        .column.main {
            padding: 0 $indent__xs;
        }
    }

    @include max-screen($screen__m) {
        .column.main {
            margin: 0;
        }
    }

    .modal-popup.confirm .modal-header .modal-title {
        padding-top: 5rem;
        text-transform: none;
        font-size: $font-size-xl;
        font-style: italic;
        line-height: $indent__xl;
    }

    .modal-popup.confirm .modal-content {
        padding: $indent__l $indent__base;
        text-align: center;
        line-height: 2.2rem;
    }

    .modal-popup.confirm .modal-footer {
        display: flex;
        flex-direction: column-reverse;
        margin: 0;
        padding: 0 $indent__base;
    }

    @include min-screen($screen__l) {
        .modal-popup.confirm .modal-header .modal-title {
            padding-top: $indent__m;
        }
        .modal-popup.confirm .modal-content {
            padding: $indent__l 18rem;
            text-align: center;
        }
        .modal-popup.confirm .modal-footer {
            flex-direction: row;
            padding-bottom: 5rem;
        }
    }
}

.modal-popup.#{$css-namespace}modal_change-store-modal .modal-inner-wrap .modal-header .modal-title {
    font-weight: $font-weight__semibold;
}

.cart-container {
    display: flex;
    flex-direction: column;
    gap: $space-2;

    @include min-screen($screen__l) {
        flex-direction: row;
        gap: 0;
    }

    .cart-date {
        display: none;
    }

    // Specific to step 1
    .form-cart {
        position: relative;
        z-index: 0;
        flex: 1;

        @include max-screen($screen__l) {
            float: none;
            width: 100%;
        }

        .cart-wrapper_header {
            @include lib-css-wrapper();
            background-color: $color-white;
            padding: $space-2;

            @include min-screen($screen__l) {
                padding: $space-3;
            }
        }

        .cart-wrapper {
            background: none;
            gap: $space-2;
            padding: 0;
            box-shadow: none;

            @include min-screen($screen__l) {
                padding: 0;
            }

            &_description {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: $space-2;

                &_text {
                    margin: 0;
                    text-align: left;
                    width: 100%;
                }

                &_button {
                    justify-content: flex-start;
                }
            }

            &-inner {
                @include lib-css-wrapper();
                background: $color-white;
                padding: $space-2;
                margin-top: 0;

                @include min-screen($screen__l) {
                    padding: $space-3;
                }

                .#{$css-namespace}order-items_table .#{$css-namespace}item-table_body .#{$css-namespace}item-category_row {
                    border-bottom: 0;

                    .#{$css-namespace}item-category_row-name {
                        color: $color-dark-grey;
                        font-size: 2.2rem;
                        padding: 0 0 $space-1;

                        &-count_product {
                            font-weight: $font-weight-regular;
                            font-size: $font-size-default;
                            color: $color-black;
                        }
                    }
                }

                .#{$css-namespace}products-section_products-table,
                .#{$css-namespace}order-items,
                .#{$css-namespace}item-category_row {
                    border-bottom: none;
                }

                .#{$css-namespace}order-items_table .#{$css-namespace}item-table_body {
                    .#{$css-namespace}item-table_row {
                        border: 0;
                        border-top: 0.1rem solid $color-disabled-light;
                    }

                    tr:nth-child(2) {
                        border-top: 0;
                    }
                }
            }
        }
    }

    .#{$css-namespace}form-quantity-picker_input-wrapper div.mage-error:empty {
        display: none !important;
    }
}

.cart-summary-footer {
    margin-top: 2rem;
    padding: 2rem;
    background: $color-white;

    @include max-screen($screen__l) {
        display: none;
    }

    #downloadCartPopinContent {
        display: none;
    }

    .#{$css-namespace}link-desc {
        margin-bottom: $checkout-steps-margins;
        display: inline-flex;
        flex-direction: column;

        .#{$css-namespace}link {
            text-decoration: none;
            color: $color-secondary;

            &:not(:hover) *:not(.dcfonts-icons) {
                color: $color-black;
            }

            &:hover {
                *:not(.dcfonts-icons) {
                    text-decoration: underline;
                }
            }

            *:not(.dcfonts-icons) {
                font-weight: $font-weight-regular;
                line-height: 2.2rem;
            }

            + .#{$css-namespace}desc {
                margin: $space-1 0 0 $space-4;
                cursor: default;
                font-size: $font-size-s;
                font-style: italic;
            }
        }

        .dcfonts-icons {
            margin-right: 0.5rem;
            font-size: 1.7rem;
            width: auto;
            height: auto;
        }

        &:first-of-type {
            margin-top: 1.5rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

//  Products table

.cart-wrapper {
    @include lib-css-wrapper();
    $root: &;
    display: flex;
    flex-flow: column nowrap;
    background: $color-white;
    padding: $indent__base $indent__xs;

    @include min-screen($screen__l) {
        padding: $indent__base $indent__l $indent__l;
    }

    &_header {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;

        @include min-screen($screen__l) {
            padding: 0;
        }

        &_title-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            column-gap: 1rem;

            @include min-screen($screen__l) {
                flex-direction: row;
                align-items: center;
            }

            + .checkout__item {
                margin-top: 2rem;
            }
        }

        &_icon {
            display: none;
            margin-right: 0.3rem;
            font-size: 4rem;

            @include min-screen($screen__l) {
                display: inline-block;
            }
        }

        &_title {
            margin: 0;
            font-size: $font-size-xxl;
            font-family: $font-secondary;
            font-weight: $font-weight-bold;
            line-height: $indent__l;
        }
    }

    &_sub-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.3rem;
        line-height: 1.6rem;

        @include min-screen($screen__l) {
            margin-top: $indent__base;
        }

        &_store-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            > .dcfonts-icons--shop {
                font-size: $font-size-l;
                margin-top: -$space-1 / 2;
            }
        }

        &_store-name {
            display: inline-block;
            margin-left: $space-1 / 2;
            max-width: 12rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include min-screen($screen__l) {
                max-width: 50rem;
            }
        }

        &_switch-store {
            color: $color-secondary !important;
            text-decoration: underline;

            &:hover {
                color: $color-secondary;
                text-decoration: none;
            }

            &:focus {
                // Outline for accessibility concerns.
                outline: auto;
            }

            &:active {
                color: $color-primary;
            }
        }
    }

    &_description {
        margin: $space-1 0 $space-2;
        color: $color-black;

        [data-delivery-mode='pickup'] & {
            margin: $space-1 0 0;
        }
    }

    &-inner {
        margin-top: -2.2rem;

        @include max-screen($screen__l) {
            margin-top: -$space-2;
        }

        .#{$css-namespace}item-category_row-name {
            padding-top: $indent__base;

            &-count_product {
                color: $color-primary;
                font-weight: initial;
            }
        }

        .#{$css-namespace}products-section_products-table
        .#{$css-namespace}order-items
        .#{$css-namespace}item-category_row {
            border-bottom: none;
        }

        .#{$css-namespace}item-table_row {
            border-top: 0.1rem solid $color-disabled-light;
        }
    }

    &-form {
        max-width: 30rem;

        + .notice {
            margin: $indent__s 0 0;
        }
    }

    &-articles {
        text-transform: none;
    }

    .#{$css-namespace}block-addbysku.quick-order {
        width: 100%;

        .form-addbysku {
            width: 100%;
            background: $color-white;
            margin: 0;
            padding: 0;
            min-height: 0;
        }
    }

    &--default {
        #{$root}-title,
        #{$root}-highlight {
            color: $color-blue3;
        }
    }

    &--padding-mobile {
        @include max-screen($screen__l) {
            padding: $indent__base 1.5rem;
        }
    }

    &--no-padding-bottom {
        padding-bottom: 0;

        @include min-screen($screen__l) {
            padding-bottom: 0;
        }
    }

    & + & {
        margin-top: 2rem;

        @include max-screen($screen__l) {
            margin-top: $space-2;
        }
    }

    .#{$css-namespace}empty-cart-link {
        &:focus {
            border: none;
            outline: none;
        }
    }

    &[data-delivery-mode='online'] {
        .cart-wrapper {
            &_header {
                &_icon,
                &_title {
                    color: $color-checkout-delivery-online;
                }

                &_icon {
                    @include min-screen($screen__l) {
                        display: flex;
                        align-items: center;
                    }

                    &::before {
                        content: $truck;
                        font-size: $font-size-xxl;
                        position: relative;
                    }
                }
            }
        }
    }

    &[data-delivery-mode='pickup'] {
        .cart-wrapper {
            &_header {
                &_icon,
                &_title {
                    color: $color-checkout-delivery-pickup;
                }

                &_icon {
                    &::before {
                        content: $retrait-2h;
                        position: relative;
                        top: -0.1rem;
                    }
                }
            }
        }
    }

    .#{$css-namespace}item-table_row {
        &-item-container {
            padding: 0 !important;
        }

        &-picto {
            align-items: flex-end;
            margin: 0;

            @include min-screen($screen-sm) {
                margin-right: $space-4 !important;
            }
        }

        &-options {
            text-align: left;
        }

        &-qty {
            @include max-screen($screen-sm) {
                padding-bottom: $space-1 !important;
                height: auto !important;

                .#{$css-namespace}form-quantity-picker_update-link {
                    position: static;
                }
            }
        }
    }

    &:not(:has(.checkout__shipping-methods .checkout__item ~ .checkout__item)) {
        // Be careful, it mustn't impact cart
        .checkout-index-index & {
            #{$root}_description {
                // hide choice text if there is only one choice available
                display: none;
            }

            .checkout__item {
                margin-top: $checkout-steps-margins;
            }
        }
    }
}

.click-wrapper {
    @include min-screen($screen__m) {
        margin-top: $indent__xl;
    }
}

.cart.table-wrapper {
    .items {
        table-layout: fixed;

        thead {
            border-top: $border-width__base solid $border-color__base;

            .col {
                padding-top: 1.8rem;
                padding-bottom: 1.7rem;
                line-height: 1em;
                white-space: nowrap;
                font-weight: $font-weight__regular;

                &.item {
                    width: 32%;
                }

                &.price {
                    width: 17%;
                }

                &.qty {
                    width: 14%;
                    text-align: center;
                }

                &.availability {
                    width: 18%;
                    text-align: center;
                }

                &.subtotal {
                    width: 19%;
                    text-align: right;
                }

                &.actions {
                    display: none;
                }
            }

            @include max-screen($screen__l) {
                display: none;
            }
        }

        .cart.item {
            border-top: $border-width__base solid $border-color__base;
            position: relative;
            @include max-screen($screen__l) {
                display: block;
                padding-left: 9rem;
            }

            .item-info {
                @include max-screen($screen__l) {
                    @include lib-clearfix();
                    display: block;
                }

                .col {
                    padding: 1.9rem;
                    @include max-screen($screen__l) {
                        display: block;
                        padding: 0;
                    }

                    &.item {
                        padding-left: 0;
                        @include max-screen($screen__l) {
                            padding-top: $indent__m;
                        }
                    }

                    &.price {
                        font-family: $font-family-name__base;
                        width: 17%;
                        @include max-screen($screen__l) {
                            display: none;
                        }

                        .cart-price .price {
                            font-weight: $font-weight__regular;
                        }
                    }

                    &.availavility {
                        min-height: 2rem;
                        font-weight: $font-weight__bold;
                        color: $color-sub-secondary;
                        display: flex;

                        span {
                            display: block;
                            padding-left: $space-1 / 2;
                            margin-bottom: 1.2rem;
                            position: relative;

                            &.icon-checkmark {
                                color: $color-green1;
                            }

                            &.icon-cross {
                                color: $colorDanger;
                            }

                            &[data-color='green'] {
                                color: $color-green1;
                            }

                            &[data-color='red'] {
                                color: $colorDanger;
                            }

                            &[data-color='orange'] {
                                color: $colorWarning;
                            }

                            &[data-color='blue'] {
                                color: $color-sub-secondary;
                            }
                        }
                    }

                    &.qty {
                        @include max-screen($screen__l) {
                            margin-bottom: 1.2rem;
                            .control {
                                margin: 0;
                            }
                        }
                    }

                    &.qty,
                    &.subtotal {
                        @include max-screen($screen__l) {
                            float: left;
                            box-sizing: border-box;
                        }
                    }

                    &.subtotal {
                        font-family: $font-family-name__baseBold;
                        padding-right: 0;
                        text-align: right;

                        @include max-screen($screen__l) {
                            font-size: 1.7rem;
                            line-height: $indent__l;
                            float: right;

                            .price-unit {
                                font-family: $font-family-name__base;
                                font-size: 0.9rem;
                                line-height: normal;
                                color: $subAltPrimaryColor;

                                .price-unit-value {
                                    .price_unit_quantity {
                                        font-family: inherit;
                                        font-size: inherit;
                                        color: inherit;
                                    }
                                }
                            }
                        }
                    }

                    &.actions {
                        display: none;
                    }
                }

                .product {
                    &-item-photo-picto {
                        display: table-cell;
                        max-width: 100%;
                        padding-right: $indent__base;
                        position: static;
                        vertical-align: top;
                        width: 1%;
                    }

                    &-item-details {
                        display: table-cell;
                        vertical-align: top;
                        white-space: normal;
                        width: 99%;
                    }
                }

                // Product item image & pics
                .product-item-photo-picto {
                    @include max-screen($screen__l) {
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        overflow: hidden;
                        width: 8.1rem;
                        padding: 0;
                    }

                    .product-image-wrapper {
                        display: block;
                        width: 9.2rem !important;

                        @include max-screen($screen__l) {
                            width: 8.1rem !important;
                            padding-top: $indent__m;
                        }

                        .product-image-wrapper {
                            @extend .abs-reset-image-wrapper;
                        }
                    }

                    .product-item-picto {
                        img {
                            display: block;
                            max-width: 100%;
                            margin: auto;
                            margin-top: 1.2rem;
                            @include max-screen($screen__l) {
                                margin-top: 2.3rem;
                            }
                        }
                    }
                }

                // Product item details
                .product-item-details {
                    .product-item-name {
                        font-size: $font-size-default;
                        font-weight: $font-weight__bold;
                        line-height: normal;
                        margin-bottom: 1.2rem;
                        @include max-screen($screen__l) {
                            font-size: $font-size-m;
                            margin-bottom: 1.7rem;
                        }

                        a {
                            color: inherit;
                        }
                    }

                    .product-brand {
                        margin-bottom: 1.2rem;

                        img {
                            max-width: 100%;
                            max-height: 2.4rem;
                        }
                    }

                    .product-options {
                        color: $subAltPrimaryColor;
                        font-weight: $font-weight__regular;
                        font-size: $font-size-s;
                        line-height: 1.6rem;

                        .item-options {
                            @extend .abs-product-options-list;
                            @extend .abs-add-clearfix;
                            margin: 0;

                            dt,
                            dd {
                                margin: 0;
                            }
                        }

                        @include max-screen($screen__l) {
                            margin-bottom: 1.2rem;
                        }
                    }

                    .actions-toolbar {
                        margin-top: 1.7rem;
                        @include max-screen($screen__l) {
                            display: none;
                        }
                    }
                }
            }

            .item-actions {
                display: none;
                @include max-screen($screen__l) {
                    display: block;
                    margin-bottom: 2rem;
                    td {
                        display: block;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            // action toolbar common styles
            .actions-toolbar {
                margin-left: 0;

                .wishlist {
                    display: block;
                }

                .wishlist .button,
                .action-delete {
                    &,
                    &:hover {
                        font-size: $font-size-s;
                        color: $color-gray-light7;
                        text-transform: none;
                        font-weight: $font-weight__regular;
                        height: auto;
                        width: auto;
                        padding: 0;
                        background: none;
                        border: none;
                    }
                }

                .wishlist .button {
                    font-family: $font-family__sans-serif;
                    display: table;
                    line-height: 1.2rem;
                    text-align: left;
                    @include max-screen($screen__l) {
                        max-width: 40%;
                    }

                    span {
                        display: table-cell;
                        vertical-align: middle;

                        &.icon-fav {
                            padding-right: 0.6rem;

                            &::before {
                                display: inline-block;
                                content: '';
                                width: 2.3rem;
                                height: 2.4rem;
                                @include lib-background-image(
                                        'png/liste-achat60.png',
                                        center,
                                        no-repeat,
                                        transparent,
                                        'Descours_Checkout'
                                );
                                background-size: cover;
                                vertical-align: middle;
                            }
                        }
                    }
                }

                .action-delete {
                    text-decoration: underline;
                }
            }
        }
    }

    .#{$css-namespace}order-items_table {
        table-layout: auto;

        thead {
            display: none;
        }
    }

    .#{$css-namespace}products-section_products-table {
        .#{$css-namespace}order-items {
            .#{$css-namespace}item-table_row {
                @include max-screen($screen-sm) {
                    margin-bottom: -0.1rem;
                }

                &::after {
                    content: '';
                    clear: both;
                    display: table;
                }

                .#{$css-namespace}item-table_row-item {
                    .#{$css-namespace}item-table_row-picto {
                        .product-item-photo,
                        .product-image-wrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .#{$css-namespace}item-table_row-details {
                        @include min-screen($screen-sm) {
                            max-width: 23.7rem;
                            padding: 0;
                        }

                        .#{$css-namespace}item-table_row-item_title_wrapper {
                            @include min-screen($screen-sm) {
                                line-height: 1.554;
                            }
                        }
                    }
                }

                .#{$css-namespace}item-table_row-actions,
                .#{$css-namespace}item-table_row-delete {
                    @include min-screen($screen-sm) {
                        line-height: 1rem;
                    }

                    a.#{$css-namespace}link {
                        .#{$css-namespace}item-table_row-delete_icon {
                            font-size: $font-size-xl;
                            width: auto;
                            height: auto;
                        }
                    }
                }

                .#{$css-namespace}item-table_row-item_complementary {
                    white-space: nowrap;
                    line-height: 1.4rem;
                    margin-top: 1rem;
                    text-decoration: underline;
                    font-weight: $font-weight-medium;

                    @include min-screen($screen-sm) {
                        margin: 0 0 0 $space-1;
                        border-left: 0.1rem solid $color-gray-light8;
                        padding-left: 1.1rem;
                        font-size: $font-size-s;
                    }
                }

                .#{$css-namespace}item-table_row-availability {
                    white-space: nowrap;
                    display: flex;
                    flex-direction: column;
                    padding: 0 1.2rem 0 0;
                    gap: $space-1 / 2;
                    margin-top: $space-1 / 2;

                    .small {
                        max-width: 100%;

                        .#{$css-namespace}round_light_ctn {
                            padding: 0 1.2rem 0 0;
                        }
                    }
                }

                .#{$css-namespace}item-table_row-qty {
                    padding: 0 $space-1 0 $space-2;
                    margin-bottom: auto;

                    @include min-screen($screen-sm) {
                        padding: 0 1.2rem;
                        margin-bottom: 0;
                    }

                    .#{$css-namespace}form-quantity-picker_update-link {
                        @include min-screen($screen-sm) {
                            left: 0;
                        }
                    }
                }

                .#{$css-namespace}item-table_row-price {
                    text-align: right;
                    padding-left: 0;
                    flex-grow: 1;

                    @include min-screen($screen-sm) {
                        padding-right: 1.5rem;
                    }

                    @include min-screen($screen__m) {
                        width: auto;
                    }

                    &_ctn {
                        @include min-screen($screen-sm) {
                            min-width: 10rem;
                        }
                        @include min-screen($screen__m) {
                            text-align: right;
                        }
                    }
                }

                &-tooltips {
                    [data-ui='tooltip'] {
                        margin-bottom: 0.5rem;
                        display: flex;
                        flex-flow: row wrap;
                        gap: $space-1;
                        border: solid 0.1rem $color-main-dark;
                        border-radius: 0.3rem;
                        padding: 0.2rem $space-1 0.1rem;
                        font-size: $font-size-s;
                        font-weight: $font-weight-medium;
                        width: fit-content;
                        margin-left: auto;
                        opacity: 0;

                        &:hover,
                        &:has([data-role="tooltip-box"]) { // Prevent showing tooltip before it's initialized
                            opacity: 1;
                        }

                        .dcfonts-icons {
                            height: $font-size-s;
                        }

                        @include min-screen($screen__m) {
                            margin-right: 0;
                            margin-left: auto;
                        }

                        &:first-child {
                            margin-top: 0.5rem;
                        }

                        > span {
                            text-align: right;
                            box-shadow: 0 0.2rem 0.6rem 0 rgba($color-main-dark, 0.16); // TODO: Use box-shadow var
                            color: $color-main-dark;
                            font-weight: $font-weight-regular;
                            max-width: 32rem;
                            margin-top: 0.5rem;

                            &::before {
                                pointer-events: none;
                            }

                            @include min-screen($screen__m) {
                                max-width: 40rem;
                                width: max-content;
                                margin-right: 0;
                            }
                        }

                        &[data-ui-color='red'] {
                            color: $color-red;
                            border-color: $color-red;
                        }

                        &[data-ui-color='orange'] {
                            color: $color-orange;
                            border-color: $color-orange;
                        }
                    }
                }
            }
        }

        .spacer {
            padding: 0;
            height: 1rem;
            border: none;

            @include max-screen($screen-sm) {
                display: none;
            }
        }
    }
}

//  Cart main actions
.cart.main.actions {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $indent__s;

    .action {
        flex: 0 1 auto;
        display: inline-flex;
        align-items: center;
        width: calc(50% - #{$indent__s});
        min-width: 18%;
        padding: 1.2rem $space-2;
        margin: $indent__xs;
        border: $button__border;
        font-size: $font-size-default;
        font-weight: $font-weight__regular;
        text-transform: none;
        background: none;
        color: $color-gray-light7;
        box-sizing: border-box;

        &::before {
            flex: 0 0 auto;
            content: '';
            display: inline-block;
            width: 3.5rem;
            height: 3.5rem;
            background-size: contain !important;
        }

        &.convertwishlist:before {
            @include lib-background-image('svg/liste-achat.svg', center, no-repeat, transparent);
        }

        &.update:before {
            @include lib-background-image('svg/actualistation.svg', center, no-repeat, transparent);
        }

        &.clear:before {
            @include lib-background-image('svg/supprimer.svg', center, no-repeat, transparent);
        }

        &.download:before {
            @include lib-background-image('svg/download.svg', center, no-repeat, transparent);
        }

        span {
            display: inline-block;
            margin-left: $indent__s_l;
            text-align: left;
        }
    }

    @include min-screen($screen__s) {
        .action {
            width: calc((100% / 3) - #{$indent__s});
        }
    }

    @include min-screen($screen__l) {
        flex-wrap: nowrap;
        padding: 0;

        .action {
            flex-grow: 1;
            width: auto;
            min-width: 18%;
            margin: 0 -0.1rem 0 0;
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 1.5rem;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 7rem;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

//
// remove the availability bloc if the MS return empty array
//
.availability-hide {
    display: none !important;
}

// manage promotion
.block.discount {
    .coupon {
        .field {
            display: flex;

            label {
                @include lib-visually-hidden();
            }

            .field-action-apply {
                margin-left: $indent__xs;

                button {
                    text-transform: uppercase;
                    border-color: $color-gray96;
                    background-color: $color-gray96;
                    color: $color-gray-light7;
                }
            }
        }

        .field-action-cancel {
            margin-top: $indent__xs;

            button {
                background: none;
                border: none;
                padding: 0;
                font-size: $font-size__s;
                text-transform: none;
                color: $color-sub-secondary;
                text-decoration: underline;
            }
        }
    }
}

.checkout-index-index {
    .cart-wrapper_header_title-wrapper--border {
        padding: 3rem 0 1.2rem;
        border-top: 0.1rem solid $color-gray83;

        @include min-screen($screen__l) {
            padding: 2rem 0;
        }
    }

    .cart-wrapper {
        @include max-screen($screen__l) {
            margin: 0;
        }
    }

    .checkout__products-wrapper {
        .cart-wrapper {
            padding: 0;
        }

        .cart-wrapper-container {
            @include max-screen($screen__l) {
                padding: 0 1rem;
            }
        }
    }
}
