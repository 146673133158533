/*
@deprecated Use services pages components instead
TODO : Refacto uses in cms_block fidelity_subscribed, fidelity_withpopin, fidelity_withpopin
 */
.#{$css-namespace}cms-block-50-50 {
    $root: &;

    min-height: 32rem;
    border-radius: 0.3rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include min-screen($screen__m) {
        flex-direction: row;
    }

    & ~ & {
        margin-top: 3rem;
    }

    &__half {
        flex: 1;

        &__wrapper {
            width: 36rem;
            max-width: 100%;
            margin: 2rem;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
        }
    }

    &__title {
        font-size: $font-size-l;
        line-height: 2.1rem;
        margin-bottom: 2rem;
        color: $color-primary;
        font-weight: $font-weight-bold;

        br {
            @include min-screen($screen__l) {
                display: none;
            }
        }
    }

    &__description {
        line-height: 2.2rem;
    }

    &__action {
        margin-top: 2rem;
        width: 23rem;
        max-width: 100%;

        @include min-screen($screen__l) {
            margin-top: 3rem;
        }

        .#{$css-namespace}btn {
            width: 100%;
            letter-spacing: -0.009rem;
            justify-content: center;
            line-height: $font-size-m;
            border: none;
        }
    }

    &--text {
        display: flex;
        justify-content: center;
        align-content: center;

        @include max-screen($screen__l) {
            order: 2;
        }
    }

    &--media {
        background-size: contain;

        @include max-screen($screen__m) {
            height: calc((100vw - 4rem) * (150 / 280));
            order: -1;
            flex: auto;
        }

        iframe {
            max-height: 100%;
        }
    }

    &--with-background {
        background: $color-primary;

        &,
        #{$root}__title {
            color: $color-white;
        }
    }
}
