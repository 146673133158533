.sidebar {
    .#{$css-namespace}account-nav {
        background-color: $color-main-dark;
        color: $color-white;
        padding: 0;

        .#{$css-namespace}account-nav_user {
            background-color: $color-sidebar-customer-name;
            padding: 2rem 1.5rem;
            margin-bottom: 0;

            @include min-screen($screen__l) {
                padding: 2rem;
            }

            &--customerName {
                line-height: 1.9rem;
                margin-bottom: 1rem;

                @include min-screen($screen__l) {
                    font-size: $font-size-xl;
                    line-height: 0.95;
                }
            }

            &--companyName {
                font-weight: $font-weight-regular;
                line-height: 1.9rem;

                @include min-screen($screen__l) {
                    line-height: 1.4;
                }
            }

            &--number {
                background-color: $color-sidebar-customer-account-number;
                padding: 1rem 1.5rem;

                border: none;
                color: $color-white;

                line-height: 1.35;
                font-weight: $font-weight-black;

                text-transform: uppercase;

                @include min-screen($screen__l) {
                    padding: 2rem;
                }
            }
        }

        .dcfonts-icons {
            font-size: 2.2rem;
        }

        .#{$css-namespace}account-nav {
            display: none;
        }
    }
}

.#{$css-namespace}account-nav-item {
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid $color-workflow-sidebar-border;
    border-bottom: 0.1rem solid $color-workflow-sidebar-border;
    margin: 0 1.5rem;

    @include min-screen($screen__l) {
        margin: 0;
    }

    & ~ & {
        border-top: none;
    }

    &_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 1rem 0;

        @include min-screen($screen__l) {
            padding: 3rem 0 0;
            margin-left: 2rem;
        }

        &--dashboard {
            margin: 0 1.5rem;
            padding: 1.5rem 3rem 1.5rem $space-4;
            border-top: none;
            line-height: 1;

            @include min-screen($screen__l) {
                padding: 2rem 2.7rem 2rem 2rem;
                margin: 0;
                line-height: 1.35;
            }
        }

        &--active {
            > i,
            .#{$css-namespace}account-nav-item_title {
                color: $color-sidebar-account-management;
            }
        }
    }

    &_title {
        margin-left: 1.2rem;
        color: $color-white;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    &_collapse-icon {
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.6rem !important;
        margin-left: auto;
        fill: $color-white;

        @include min-screen($screen__l) {
            display: none;
        }

        [aria-expanded='true'] & {
            transform: rotate(180deg);
        }
    }

    &_content {
        margin-left: 3.4rem;
        list-style: none;
        padding: 0;
        margin-bottom: 0.6rem;

        @include min-screen($screen__l) {
            padding-left: 1rem;
            margin-left: 4.4rem;
            margin-bottom: 2rem;
        }
    }

    &_link {
        margin-top: 2rem;
        color: $color-white;

        &:first-child {
            @include max-screen($screen__l) {
                margin-top: 0.5rem;
            }
        }

        &--hasCounter {
            display: flex;

            a {
                margin-right: $space-1;
            }

            .sidebar-counter {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: 1.4rem;
                color: $color-black;
                background: $color-white;
                height: 2rem;
                min-width: 2rem;
                border-radius: 50%;
                text-align: center;
                font-size: $font-size-s;
            }
        }

        &--active {
            position: relative;
            font-weight: $font-weight-bold;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -$space-3;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background: $color-secondary;
                width: $space-1 / 2; // TODO : refacto bullets on Dexis cf https://gitlab.addcnet.com/digital/prolians/magento/-/merge_requests/7263/diffs
                height: $space-1 / 2;
            }
        }

        &--inactive {
            a {
                color: $color-gray-light2 !important;
                pointer-events: none;

                &:hover {
                    color: $color-gray-light2 !important;
                }
            }
        }

        &--account {
            margin: $space-2 0;
        }

        a {
            @include lib-hover-focus {
                text-decoration: underline;
                color: $color-white;
            }
        }
    }
}
