table.dataTable {
    thead {
        > tr {
            > th,
            > td {
                cursor: default;

                &.sorting_asc {
                    .sort-arrows:before {
                        opacity: 0.6 !important;
                    }
                }

                &.sorting_desc {
                    .sort-arrows:after {
                        opacity: 0.6 !important;
                    }
                }

                &.sorting,
                &.sorting_asc,
                &.sorting_desc,
                &.sorting_asc_disabled,
                &.sorting_desc_disabled {
                    cursor: pointer;

                    .sort-arrows {
                        display: inline-block;
                        margin-left: 0.5rem;
                        height: 1rem;
                        width: 0.8rem;

                        &::before,
                        &::after {
                            content: '';
                            position: absolute;
                            display: block;
                            left: 0;
                            right: 0;
                            top: 50%;
                            width: 0;
                            height: 0;
                            border-style: solid;
                        }

                        &::before {
                            margin-top: -0.5rem;
                            border-width: 0 0.4rem 0.4rem 0.4rem;
                            border-color: transparent transparent $color-grey-dove transparent;
                        }

                        &::after {
                            margin-top: 0.1rem;
                            border-width: 0.4rem 0.4rem 0 0.4rem;
                            border-color: $color-grey-dove transparent transparent transparent;
                        }
                    }
                }

                .sort-arrows {
                    position: relative;
                }
            }
        }
    }
}
