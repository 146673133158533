// EXAMPLE OF override module UserAccount
// TODO: Check usage
// @TODO DELETE THIS AFTER FIRST IMPLEMENTATION
/*
.customer-account-login .login-container .block.block-customer-login .actions-toolbar {
  display: flex;
  justify-content: space-between;
  background-color: $color-secondary;
  padding: $offset;

  // nettoyage en amont + suppression de la regle suivante
  &::before,
  &::after {
    display: none;
  }
}
*/
