$destockage-item-width: 28.5rem;
$destockage-item-margin: 1rem;
$destockage-item-by-line: 3;
$destockage-container-width: ($destockage-item-width * $destockage-item-by-line) +
    ((1rem * 2) * $destockage-item-by-line);

.#{$css-namespace}destockList {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: $destockage-container-width;
    margin: 0 0 0;

    @include max-screen($screen__l) {
        padding: 0 2rem;
    }

    @include min-screen($screen__l) {
        margin: 6rem auto 0;
    }

    &Item {
        width: 100%;
        margin: 0 0 1rem;
        background-color: $color-white;
        box-shadow: var(--box-shadow);
        text-decoration: none;
        text-align: center;
        padding: 2.5rem 1.5rem 1.5rem;
        border-radius: 0.6rem;

        @include max-screen($screen__l) {
            min-height: 8rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1rem 0;
        }

        @include min-screen($screen__l) {
            max-width: 28.5rem;
            margin: 0 $destockage-item-margin 2rem;
        }

        .#{$css-namespace}icon {
            width: 6.4rem;
            height: 6.4rem;
            color: initial !important;

            @include max-screen($screen__l) {
                width: 4rem;
                height: 4rem;
            }
        }

        &-label {
            display: block;
            text-transform: uppercase;
            line-height: 1.4;
            color: $color-primary;
            font-weight: $font-weight-black;
            font-size: $font-size-m;

            @include min-screen($screen__l) {
                margin-top: 1.5rem;
            }

            @include max-screen($screen__l) {
                width: 55%;
                text-align: center;
                font-size: $font-size-default;
                margin-left: 3rem;
                margin-right: 1.5rem;
            }
        }
    }
}
