.or-container {
    > header {
        background-color: $color-blue1;
        background: linear-gradient(90deg, $color-sub-secondary 0%, $color-sub-secondary 50%, $color-blue1 100%);
        color: $color-white;

        .logo-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5rem 0;

            .logo {
                width: 32rem;
            }

            .logo-baseline {
                display: block;
                position: relative;
                top: -2.5rem;
                left: $space-1 / 2;
                text-transform: uppercase;
                @include lib-font-size(12);
                font-weight: $font-weight__bold;
            }

            @include min-screen($screen__l) {
                .logo {
                    width: 48rem;
                }

                .logo-baseline {
                    top: -4.5rem;
                    left: -2rem;
                    @include lib-font-size(14);
                }
            }
        }

        .#{$css-namespace}cms-container {
            position: relative;
            margin-left: 6.8rem;
            background-color: $color-white;

            &::before {
                content: '';
                display: block;
                border-bottom: 6.8rem solid $color-white;
                border-left: 6.8rem solid transparent;
                position: absolute;
                z-index: 2;
                bottom: 0;
                left: -6.8rem;
            }

            @include min-screen($screen__s) {
                &::after {
                    content: '';
                    display: block;
                    border-bottom: 9rem solid $color-white;
                    border-left: 38rem solid transparent;
                    position: absolute;
                    z-index: 2;
                    top: -9rem;
                    right: 0;
                }
            }

            @include min-screen($screen__l) {
                padding-right: 6.8rem;
            }
        }

        .#{$css-namespace}cms {
            padding: $indent__l;
            background: $color-white;
            color: $mainFontColor;
            @include lib-css-box-shadow();

            h1 {
                margin-bottom: 0;
                @include lib-font-size(26);
                font-weight: $font-weight__bold;
                text-transform: uppercase;
                text-align: center;
                color: $color-sub-secondary;
            }

            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
                padding: 0;
                margin: $indent__base (-$indent__l);

                > li {
                    flex: 0 0 auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    min-width: 21.5rem;
                    margin: 0;
                    padding: $indent__l;
                    text-align: center;
                    box-sizing: border-box;

                    &::before {
                        display: none;
                    }

                    img {
                        display: block;
                        width: 6rem;
                        height: 6rem;
                        margin: 0 auto;
                    }

                    h2 {
                        margin-bottom: $indent__xs;
                        @include lib-font-size(18);
                        font-weight: $font-weight__bold;
                        text-transform: uppercase;
                        color: $color-sub-secondary;
                    }

                    a {
                        color: $color-sub-secondary;

                        &:hover,
                        &:focus {
                            text-decoration: underline;
                        }
                    }
                }

                @include min-screen($screen__xs) {
                    flex-direction: row;

                    > li {
                        flex: 0 0 50%;
                    }
                }

                @include min-screen($screen__m) {
                    > li {
                        flex: 0 0 (100%/3);
                    }
                }

                @include min-screen($screen__l) {
                    > li {
                        flex: 0 0 20%;
                    }
                }
            }

            form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .or-reserved {
                    display: block;
                    margin-top: $indent__xs;
                    @include lib-font-size(12);
                    color: $color-gray20;
                }
            }
        }

        .or-convert-message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > p {
                margin: 0;
                padding: $indent__s $indent__base;
                text-align: center;
                background-color: #f0f0f0; // TODO: Use global var
                color: $color-green1;
            }
        }
    }

    > .or-body {
        padding: $indent__xl 0;

        .#{$css-namespace}cms {
            max-width: 85rem;
            margin: 0 auto;

            ul {
                @extend %dc__list;
                margin: 0 $indent__base;
                padding: 0;

                > li {
                    display: flex;

                    &::before {
                        display: none;
                    }

                    // icon img
                    > img {
                        flex: 0 0 auto;
                        display: block;
                        max-width: 50%;

                        + div {
                            margin-left: $indent__base;
                        }
                    }

                    > div {
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;

                        p {
                            margin: 0;

                            // specific label
                            em {
                                display: inline-flex;
                                margin-bottom: $indent__base;
                                padding: $indent__xs $indent__s;
                                @include lib-font-size(12);
                                font-weight: $font-weight__bold;
                                font-style: normal;
                                line-height: 1;
                                text-transform: uppercase;
                                background: $color-sub-secondary;
                                color: $color-white;
                            }

                            + p {
                                margin-top: $indent__base;
                            }
                        }

                        h2 {
                            margin: 0 0 $indent__xs;
                            @include lib-font-size(16);
                            font-weight: $font-weight__heavier;
                            text-transform: uppercase;
                            color: $color-sub-secondary;
                        }

                        a {
                            color: $color-sub-secondary;

                            &:hover,
                            &:focus {
                                text-decoration: underline;
                            }
                        }

                        + img {
                            margin-left: $indent__base;
                        }
                    }

                    + li {
                        margin-top: ($indent__base * 3);
                    }
                }
            }
        }
    }

    > footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $indent__xl auto 0;
        padding: 0 $indent__xl;
        max-width: 85rem;

        .btn-link {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 4rem;

            > span {
                display: block;
                padding: $indent__s (2rem + 4rem) $indent__s $indent__base; // Because ($indent__base + 4rem) calc failed using px
                text-transform: uppercase;
                text-align: center;
                background-color: $color-sub-secondary;
                color: $mainButtonFontColor;
            }

            .img-wrapper {
                position: relative;
                left: -4rem;
                width: 27.5rem;
            }
        }
    }
}
