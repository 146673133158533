$container-width-m: 100%;
$container-width-s: 85%;
$step-count: 3;
$step-width-m: $container-width-m/$step-count;
$step-width-s: $container-width-s/$step-count;
$step-bullet-size: 3.6rem;

@mixin dashed-line($color: $color-black) {
    height: 0.1rem;
    background: repeating-linear-gradient(
        to right,
        $color 0,
        // Dash start
        $color 0.3rem,
        // Dash end
        rgba($color-white, 0) 0.3rem,
        // Space start
        rgba($color-white, 0) 0.5rem
    ); // Space end
}

.checkout-cart-index {
    .#{$css-namespace}progress-bar_item-step:after {
        background-color: $color-grey-background;
    }
}

.useropeningrequest-search-index,
.useropeningrequest-user-index {
    .#{$css-namespace}link-back {
        @include max-screen($screen__m) {
            margin-bottom: 0;
        }
    }

    .customer-come-back a {
        margin-top: -2.5rem;
        @include max-screen($screen__m) {
            margin-bottom: 0;
        }
    }
}

.useropeningrequest-search-index {
    .customer-come-back {
        @include max-screen($screen__m) {
            margin-bottom: 1rem;
        }
    }
}

.#{$css-namespace}progress-bar {
    align-items: center;
    margin: $offset-l 0;
    position: relative;

    .#{$css-namespace}link-back {
        margin-bottom: 0;
        position: absolute;

        @include max-screen($screen__m) {
            margin: $offset-l 0 0;
        }
    }

    @include min-screen($screen__l) {
        margin: 0.5rem 0 4.2rem;
    }

    &_container {
        margin: 0 auto;
        padding: 0;
        counter-reset: i;
        font-size: 0;
        text-align: center;
        width: 100%;

        @include max-screen($screen__m) {
            display: flex;
            justify-content: space-between;
        }

        @include min-screen($screen__l) {
            max-width: 55%;
        }

        @include min-screen($screen__m) {
            width: 100%;
            padding: 0;
        }
    }

    &_item {
        position: relative;
        display: inline-block;
        width: $step-width-s;
        margin-bottom: 0;
        vertical-align: top;
        text-align: center;

        @include min-screen($screen__m) {
            width: $step-width-m;
        }

        &:not(:last-child) {
            &::before {
                content: '';
                position: absolute;
                left: calc(50% + #{$step-bullet-size} / 2 + 1rem);
                right: 0;
                // Width calculation details for responsive design
                // (100vw - 1rem) : steps container width
                // (100% * #{$step-count})) / (#{$step-count} - 1)) : space between 2 step
                // (100% - #{$step-bullet-size} - 2rem) : size of 1 step less bullet width less space between bullet and line
                width: calc(
                    ((100vw - 1rem) - (100% * #{$step-count})) / (#{$step-count} - 1) +
                        (100% - #{$step-bullet-size} - 2rem)
                );
                top: calc(#{$step-bullet-size} / 2);
                @include dashed-line();

                // tablet & small desktop only
                @include min-screen($screen__m) {
                    left: 75%;
                    width: 45%;
                }

                @include min-screen($screen__l) {
                    left: calc(75% + (#{$step-width-m} / 2));
                }

                .#{$css-namespace}progress-bar--white & {
                    @include dashed-line($color-white);
                }
            }
        }

        &:first-child {
            &::before {
                @include min-screen($screen__l) {
                    left: calc((75% + (#{$step-width-m} / 2)) - #{$offset-s});
                }
            }
        }

        &-wrapper {
            @include min-screen($screen__l) {
                display: flex;
                justify-content: left;
            }
        }

        &-step {
            flex: 1;
            height: 35px;

            .dcfonts-icons,
            &::before,
            &::after {
                position: absolute;
                left: 0;
                right: 0;
                border-radius: 50%;
            }

            &::before {
                content: '';
                margin: 0 auto;
                height: $step-bullet-size;
                width: $step-bullet-size;
                background: $color-main-dark;
            }

            &::after {
                content: counter(i);
                counter-increment: i;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0.1rem;
                margin: 0 auto;
                width: calc(#{$step-bullet-size} - 0.2rem);
                height: calc(#{$step-bullet-size} - 0.2rem);
                line-height: 1.75;
                background: $color-white;
                color: $color-black;
                font-size: $font-size-l;
                font-weight: $font-weight-bold;
            }

            .dcfonts-icons {
                display: none;
            }
        }

        &-label {
            font-size: $font-size-default;
            color: $color-black;
            font-weight: $font-weight-regular;

            @include min-screen($screen__l) {
                flex: 1;
                margin-left: calc(#{$step-bullet-size} + #{$space-2});
                margin-top: calc(#{$step-bullet-size} / 4);
                text-align: left;
            }

            @include max-screen($screen__l) {
                display: block;
                padding-top: calc(#{$step-bullet-size} + #{$offset-xs});
            }
        }

        &--active,
        &--completed {
            .#{$css-namespace}progress-bar_item {
                &-step {
                    &::before,
                    &::after {
                        color: $color-white;
                    }
                }

                &-label {
                    font-weight: $font-weight-bold;
                }
            }
        }

        &--active {
            &:last-child {
                &::before {
                    background: $color-checkout-step-active;
                }
            }

            &:not(:last-child) {
                &::before {
                    background: $color-checkout-step-active;
                }
            }

            .#{$css-namespace}progress-bar_item {
                &-step {
                    &::before,
                    &::after {
                        background: $color-checkout-step-active;
                    }
                }

                &-label {
                    color: $color-checkout-step-active;
                }
            }
        }

        &--completed {
            cursor: pointer;

            &:not(:last-child) {
                &::before {
                    border-top: 0.1rem solid $color-success-secondary;
                }
            }

            .#{$css-namespace}progress-bar_item {
                &::before {
                    border-top: 0.1rem solid $color-white;
                }

                &-step {
                    &::before,
                    &::after {
                        background: $color-success-secondary;
                    }

                    &::after {
                        opacity: 0;
                    }

                    .dcfonts-icons {
                        display: block;
                        font-size: $font-size-m;
                        line-height: $step-bullet-size;
                        color: $color-white;
                    }
                }

                &-label {
                    color: $color-success-secondary;
                }
            }
        }
    }
}

.#{$css-namespace}progress-bar--acount {
    margin: 0 0 0 auto;
    @include screen($screen__m, $screen__m) {
        width: 42rem;
    }
    @include max-screen($screen__m) {
        margin: 1rem 0 0;
    }
    @include min-screen($screen__l) {
        margin-right: -6.7rem;
    }

    .#{$css-namespace}progress-bar_container {
        max-width: 100%;
        @include min-screen($screen__l) {
            min-width: 84rem;
        }
        @include max-screen($screen__m) {
            margin-left: 12%;
        }
    }

    .#{$css-namespace}progress-bar_item {
        width: 33.3%;
        padding-right: 5rem;
        @include max-screen($screen__m) {
            min-width: 33.333%;
            padding-right: 5rem;
        }

        &:not(:last-child) {
            &::before {
                border-color: $color-white;
            }
        }

        &-label {
            color: $color-white;
            @include max-screen($screen__l) {
                margin-left: -50%;
            }

            .label--dekstop {
                display: none;
                @include min-screen($screen__l) {
                    display: block;
                }
            }

            .label--mobile {
                text-align: left;
                @include min-screen($screen__l) {
                    display: none;
                }
            }
        }

        &--active {
            &::before {
                border-top: 0.1rem solid $color-white;
            }

            .#{$css-namespace}progress-bar_item-step {
                &::before {
                    background: $color-checkout-step-active;
                }

                &::after {
                    background: $color-checkout-step-active;
                    color: $color-white;
                }
            }

            .#{$css-namespace}progress-bar_item-label {
                color: $color-white;
                @include max-screen($screen__m) {
                    position: relative;
                }
            }
        }

        &::before {
            left: inherit !important;
            right: 1rem;
            @include screen($screen__m, $screen__l) {
                right: 2rem;
            }
            @include max-screen($screen__s) {
                width: 3rem !important;
            }
            @include min-screen($screen__l) {
                width: 3rem !important;
            }
            @include max-screen($screen__m) {
                width: calc(100% - 5.6rem) !important;
                right: 1rem;
            }
        }

        &-step {
            flex: inherit;

            &::before {
                margin: 0 !important;
                background: $color-white;
            }

            &::after {
                margin: 0 0 0 0.1rem !important;
                background: $color-primary;
                color: $color-white;
            }
        }
    }
}
