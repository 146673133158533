$quantity-picker-button-width: 2.4rem;
$quantity-picker-wrapper-height: 4rem;
$quantity-picker-border-width: 0.1rem;
$quantity-picker-radius: 0.3rem;
$quantity-picker-padding-x: $space-1;
$quantity-picker-padding-y: $quantity-picker-padding-x - $quantity-picker-border-width; // to make qty picker and button having same render height 40rem
$quantity-picker-color-disabled: #dcdee2; // TODO: use $color-gray97
$quantity-picker-background-color-disabled: $color-grey-background;

// abstract quantity picker
%quantity-picker-readonly {
    border-color: $quantity-picker-background-color-disabled;
    background-color: $quantity-picker-background-color-disabled;
    cursor: not-allowed;

    button[data-update-qty],
    + .#{$css-namespace}form-quantity-picker_update-link {
        display: none;
    }

    input[type='number'] {
        cursor: not-allowed;
    }
}

%quantity-picker-wrapper {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 100%;
    position: relative;
    width: $quantity-picker-width;
    max-width: $quantity-picker-width;
    border: solid $quantity-picker-border-width $quantity-picker-color-disabled;
    border-radius: $quantity-picker-radius;
    background-color: $color-white;
    padding: $quantity-picker-padding-y $quantity-picker-padding-x;
    gap: $quantity-picker-padding-x;

    div.mage-error {
        order: 3;
        padding: 0;
        margin: 0;

        &::before {
            display: none !important;
        }
    }

    &:has(div.mage-error:not(:empty)) {
        border-color: $color-error;
    }

    &.#{$css-namespace}form-quantity-picker-readonly {
        @extend %quantity-picker-readonly;
    }

    .#{$css-namespace}product_slot & {
        flex: 1;
        width: auto;
        max-width: calc(100% - #{$quantity-picker-wrapper-height});

        + .#{$css-namespace}add_cart_slot__add_button--icon {
            flex: 0 0 $quantity-picker-wrapper-height;
            max-width: $quantity-picker-wrapper-height;
            min-width: $quantity-picker-wrapper-height;
        }
    }
}

%quantity-picker-input {
    height: $quantity-picker-button-width;
    background-color: transparent;
    border: none;
    border-radius: 0;
    flex: 1;
    padding: 0;
    min-width: 3rem;
    max-width: calc(100% - #{$quantity-picker-button-width * 2 + $quantity-picker-padding-x * 2}); // Fit all available width and preventing flex-wrap to modify buttons alignments
    font-size: $font-size-default;
    font-weight: $font-weight-bold;
    color: $color-primary;
    text-align: center;
    line-height: $quantity-picker-button-width;
    -moz-appearance: textfield;

    &:focus {
        border-color: $color-primary;
        outline: none;
    }

    &:disabled {
        opacity: 1;
        z-index: 3;

        + button[data-update-qty] {
            background-color: $color-blue-background-1;
            position: static;

            .dcfonts-icons {
                opacity: 0;
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: $color-blue-background-1;
                border-radius: $quantity-picker-radius;
            }
        }
    }

    .#{$css-namespace}product_slot & {
        max-width: inherit;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

%quantity-picker-button {
    &,
    &:focus {
        position: relative;
        display: inline-flex;
        flex: 0 0 $quantity-picker-button-width;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: $quantity-picker-button-width;
        height: $quantity-picker-button-width;
        border-radius: $quantity-picker-radius;
        border: none;
        color: $color-primary;
        fill: $color-primary;
        background-color: $quantity-picker-background-color-disabled;
    }

    [data-delivery-mode='pickup'] &,
    .click-and-collect-table & {
        &:hover,
        &:active {
            background-color: $color-accent-darken;
        }
    }

    &:hover,
    &:active {
        border: none;
        color: $color-white;
        fill: $color-white;
        background-color: $color-primary;
    }

    &:disabled {
        opacity: 1;
        color: $quantity-picker-color-disabled;
        fill: $quantity-picker-color-disabled;
        z-index: 1;
        pointer-events: none;
    }

    .dcfonts-icons {
        font-size: inherit;
    }
}

%quantity-picker-update-link {
    position: absolute;
    width: 100%;
    text-align: center;
    padding-top: 0.65rem !important;
    white-space: nowrap;

    [data-item-update] {
        display: none;
    }

    .quantity-picker-container--bigger & {
        padding-top: 0.5rem !important;
    }
}

// defined quantity picker
.#{$css-namespace}form-quantity-picker_input-wrapper {
    @extend %quantity-picker-wrapper !optional;

    &--disabled {
        pointer-events: none;
    }
}

.#{$css-namespace}form-quantity-picker_button {
    @extend %quantity-picker-button !optional;
}

.#{$css-namespace}form-quantity-picker_input[type='number'] {
    @extend %quantity-picker-input !optional;
}

.#{$css-namespace}form-quantity-picker_update-link {
    @extend %quantity-picker-update-link !optional;

    .wishlist-top_achat-index & {
        display: none;
    }
}

.#{$css-namespace}quantity-readonly {
    @extend %quantity-picker-readonly;
    padding: 0.7rem 1.3rem;
    font-weight: $font-weight-medium;
}

.quantity-picker-container {
    position: relative;
}

.#{$css-namespace}wishlist_products-section--quotation {
    %quantity-picker-update-link {
        width: $quantity-picker-width;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
    }
}
