@import './webOrders';
@import './pending-order-request';

.account {
    &.sales-order-weborders,
    &.sales-order-storeorders,
    &.sales-order-pendingorderrequests {
        .column.main {
            border-radius: 0;
            background-color: transparent;
            box-shadow: none;

            @include min-screen($screen__l) {
                padding: 0;
            }
        }
    }
}

.#{$css-namespace}orders {
    $root: &;

    &_timestamp-col {
        display: none !important; // Override default user account table style.
    }

    &_action-button {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 0.225rem;
        padding: 0.7rem 1.8rem;
        color: $color-white;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        background-color: $color-secondary;

        &:visited {
            color: $color-white;
        }

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-secondary-darken;
        }
    }

    &_price-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-weight: $font-weight-bold;

        @include min-screen($screen__l) {
            align-items: baseline;
        }
    }

    &_price-symbol {
        display: inline-flex;
        flex-direction: row;
        align-items: baseline;
        margin-left: 0.2rem;
        font-size: $font-size-s;
    }

    .#{$css-namespace}user-account_mobile-row {
        &.actions {
            @include max-screen($screen__l) {
                align-items: flex-start;
            }
        }
    }

    &_mobile-label {
        max-width: 9.3rem;
    }

    .#{$css-namespace}user-account_actions {
        flex-direction: column;
        flex-grow: 1;
        align-items: stretch;

        @include min-screen($screen__l) {
            flex-direction: row;
            align-items: flex-start;
        }

        #{$root}_action-button ~ #{$root}_action-button {
            @include max-screen($screen__l) {
                margin-top: 1rem;
            }
            @include min-screen($screen__l) {
                margin-left: 1rem;
            }
        }
    }

    &_status-chip {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 0.2rem solid $color-grey-dove;
        border-radius: 1.3rem;
        padding: 0.5rem 1rem;
        width: 11.5rem;
        font-size: $font-size-xs;
        text-transform: uppercase;
        line-height: 1.1;

        @include min-screen($screen__l) {
            border-radius: 1.62rem;
            padding: 0.7rem 1rem;
            width: 15.5rem;
            font-size: $font-size-s;
            line-height: 1.167;
        }

        &--green {
            border-color: $color-success-secondary;
        }
    }
}
