$step-bar-width-m: 34.4rem !default;
$step-bar-width-s: 17.8rem !default;
$step-bar-height: 0.3rem !default;
$step-bar-spacing: #{$space-1 / 2} !default;

.#{$css-namespace}progress-bar--steps {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 1.4rem 1rem 0; // Using rem value as the header height is fixed and it looks weird with $space-1.

    @include min-screen($screen__m) {
        padding: 0;
    }

    > p {
        display: none;
        @include lib-font-size($font-size-xl);
        font-weight: $font-weight-bold;
        margin-bottom: 1rem;
        font-family: $font-secondary;
        text-align: center;
        width: 100%;
        line-height: 1;

        @include min-screen($screen__m) {
            display: block;
        }

        &:only-child {
            margin-bottom: 0;
        }
    }

    &_container {
        display: flex;
        flex-flow: row wrap;
        list-style-type: none;
        width: $step-bar-width-s;
        max-width: 100%;
        padding: 0;
        margin: 0 -$step-bar-spacing;

        @include min-screen($screen__m) {
            width: $step-bar-width-m;
        }
    }

    &_item {
        width: calc(100% / var(--header-simplify-number-steps, 4));
        padding-left: $step-bar-spacing;
        padding-right: $step-bar-spacing;
        margin-bottom: 0;
        border-radius: $step-bar-height;
        overflow: hidden;

        &-step {
            height: $step-bar-height;
            display: block;
            position: relative;
            background-color: $color-white;
            overflow: hidden;
            border-radius: $step-bar-height;
        }

        &--completed,
        &--active {
            .#{$css-namespace}progress-bar--steps_item-step {
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    content: '';
                    max-width: 100%;
                    width: var(--step-progression);
                    transition: width var(--transition-normal);
                    background-color: $color-secondary;
                }
            }
        }

        &--completed .#{$css-namespace}progress-bar--steps_item-step::before {
            width: 100%;
        }
    }

    .#{$css-namespace}header-simplify-content {
        @include max-screen($screen__m) {
            padding-bottom: 2rem;
        }
    }

    &.-only-text {
        padding-top: 0;
        padding-bottom: 0;

        > p {
            line-height: 1;
        }

        .#{$css-namespace}progress-bar--steps {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
}
