.products-list {
    .#{$css-namespace}product_slot {
        width: 100%;
        height: 100%;
    }

    .#{$css-namespace}event-slot {
        height: 100%;
    }

    &-mention {
        color: $color-grey-dark2;
        font-size: $font-size-xs;
        line-height: 1.4rem;
        margin-top: $space-3 !important;
    }
}
