$form-tooltip-icon-size: $size-default * 3 !default;
$form-input-text-padding-x: $space-1 !default;
$form-input-text-color-disabled: $color-dark-grey !default;
$form-input-bg-disabled: $color-blue-background-1 !default;

%custom-input-text {
    border-color: $color-grey-light3;
    border-radius: 0.3rem;
    height: $custom-input-text-height;
    border-width: 1px;
    background: $color-white;
    padding: 1.2rem $form-input-text-padding-x;
    font-family: $font-default;
    line-height: 2rem;
    appearance: none;

    &--border-light {
        border-color: $color-grey-dove;
    }

    ~ label.label-required > span::after {
        content: '*';
    }

    &:invalid:not(:focus):not(:placeholder-shown) {
        border-color: $color-red;

        ~ label {
            color: $color-red;
        }
    }

    &:invalid:focus:not(:placeholder-shown) {
        border-color: $color-primary;

        ~ label {
            color: $color-primary;
        }
    }

    &.active ~ .label,
    &:focus,
    &[value]:not([value='']) {
        border-color: $color-primary;
    }

    &:has(~ .mage-error:not(.hidden):not(.d-none):not(:empty):not([style*='display: none'])),
    &.mage-error {
        border-color: $color-red !important;

        ~ label {
            color: $color-red !important;
        }
    }

    &.active ~ .label,
    &:focus ~ .label,
    &:not([value='']) ~ .label,
    &:not([value]) ~ .label,
    &:not(:empty) ~ .label,
    &.mage-error:not([value='']) ~ label,
    &.mage-error:not([value]) ~ label
    &:where(&:is([type='email']), &:is([type='number']), &:is([type='password']), &:is([type='text']), &:is([type="url"]), &:is([type="search"]), textarea):not(.#{$css-namespace}datepicker):not(:placeholder-shown) ~ label {
        background: $color-white;
        font-size: $font-size-s;
        font-weight: $font-weight-regular;
        top: -0.7rem;
        left: $space-1;
        padding: 0 0.7rem;
        transform: none;
        pointer-events: none;
        cursor: text;
    }

    &.active ~ .label,
    &:focus ~ .label,
    &:not([value='']) ~ .label,
    &:not([value]) ~ .label,
    &:not(:empty) ~ .label {
        color: $color-primary;
    }

    &.disabled,
    &:disabled,
    &[readonly] {
        opacity: 1;
        color: $form-input-text-color-disabled;
        border-color: $form-input-bg-disabled !important;
        background-color: $form-input-bg-disabled;

        ~ label {
            color: $color-primary !important;
        }
    }

    &[readonly] {
        cursor: default;
    }

    ~ label {
        color: $color-grey-dove;
        top: 1.3rem;
        left: 1.5rem;
        line-height: 1.4rem;
        font-size: $font-size-s;
        transition: // TODO : Uniformise using var(--transition-normal)
            all 0.25s,
            color 0s;
    }

    &[type='number'] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.#{$css-namespace}input-text {
    &--search-icon {
        position: relative;

        &::after {
            font-family: $font-icons;
            content: $search;
            translate: 0 -50%;
            scale: -1 1;
            font-size: $font-size-xl;
            position: absolute;
            top: 50%;
            right: $space-2;
            color: $color-primary;
        }
    }
}

.fieldset {
    .control {
        .#{$css-namespace}input-container & {
            .input-text {
                @extend %custom-input-text;
            }
        }

        .#{$css-namespace}input-text {
            @extend %custom-input-text;
        }

        &:has([data-ui='tooltip']) {
            display: flex;
            flex-flow: column wrap;
            position: relative;

            input {
                padding-right: $form-input-text-padding-x + $form-tooltip-icon-size + $space-1; // prevent input value behind tooltip icon
            }

            [data-ui='tooltip'] {
                position: absolute;
                right: $form-input-text-padding-x;
                width: $form-tooltip-icon-size;
                height: $custom-input-text-height;
                display: flex;
                align-items: center;

                .dcfonts-icons {
                    font-size: $font-size-xl;
                    color: $color-primary;
                }
            }
        }
    }

    &.with-background {
        background-color: $color-blue-background-1;

        .field-checkbox .control [type='checkbox']:not(:checked) ~ label::before {
            background-color: $color-white;
        }

        &:not([data-hasrequired])::after {
            display: none;
        }
    }
}

.applied .control {
    .#{$css-namespace}input-text {
        &,
        &:disabled {
            border-color: $color-success-secondary !important;
            background-color: $color-white;
        }
    }

    .label {
        color: $color-success-secondary !important;
    }
}

.error .control {
    .#{$css-namespace}input-text {
        border-color: $color-error !important;
    }

    .label {
        color: $color-error !important;
    }
}

.field.zip div.mage-error:not(:empty) {
    > span {
        display: none;

        &:not(:empty) {
            display: block;
            margin-top: 0.7rem;
        }
    }
}
