:root {
    // Header
    --header-height: 10.5rem;
    --header-simplify-height: 6.4rem;

    // Containers
    --container-narrow-width: 59rem; // Pasteque perfect

    // Modals
    --modal-content-padding-inline: 2rem;
    --modal-scrollbar-offset-right: 2.5rem;
    --modal-small-width: 59.2rem;
    --modal-medium-width: 83.2rem;
    --modal-large-width: 123.2rem;

    // Box shadows
    --box-shadow: 0 0 0.6rem 0 #{rgba($color-black, 0.12)};
    --drop-shadow: 0 0 0.6rem #{rgba($color-black, 0.12)};
    --box-shadow-light: 0 0.2rem 0.6rem 0 rgba(0, 0, 18, 0.16);
    --drop-shadow-light: 0 0.2rem 0.6rem rgba(0, 0, 18, 0.16);

    // Transitions
    --transition-speed-normal: 0.3s;
    --transition-animation-normal: ease;
    --transition-normal: var(--transition-speed-normal) var(--transition-animation-normal);

    // z-indexes
    --tooltip-zindex: 1050; // modals start from 1000 so we keep a margin of maneuver

    // datatables
    --datatable-cell-spacing: 1.6rem;
    --datatable-th-spacing: 2.4rem;
}

body {
    background: $defaultColor;

    &.-modal-open,
    &:has(.lg-show) {
        overflow: hidden;
    }
}

a,
.alink {
    text-decoration: none;
    color: inherit;

    &:visited {
        text-decoration: none;
        color: inherit;
    }
}

.row {
    display: flex;
    @include max-screen($screen__l) {
        flex-direction: column;
    }
}

.col {
    flex: 1;
    margin: 0 0 $indent__base;
}

.print-only,
.print-only-inline-flex {
    display: none !important;
}
