$block-loyalty-spacing: 2rem;

.#{$css-namespace}box_content_title {
    margin-bottom: 2rem;

    @include min-screen($screen__s) {
        margin-bottom: 3rem;
    }
}

.#{$css-namespace}loyaltyprogram {
    $root: &;

    .#{$css-namespace}loyalty {
        @include min-screen($screen__l) {
            max-width: 49.1rem;
        }

        .#{$css-namespace}loyalty_header {
            margin-bottom: 4.5rem;
            @include min-screen($screen__l) {
                margin-bottom: 5.5rem;
            }
        }
    }

    .block-dashboard-loyalty {
        @include min-screen($screen__l) {
            .#{$css-namespace}loyalty {
                max-width: 100%;
            }
        }
    }

    &_pending {
        &_text {
            background-color: #dbf7e8; // TODO: Define in var-colors
            padding: 0 1.2rem;
            margin-bottom: 3rem;
            display: flex;

            .dcfonts-icons {
                display: flex;
                align-items: center;
                font-size: $font-size-xl;
                line-height: 1.6rem;
                color: $color-success-secondary;
            }

            p {
                font-size: $font-size-default;
                line-height: 1.9rem;
                padding: 1rem 0 0 1.2rem;
            }
        }

        .#{$css-namespace}loyaltyprogram_morepoints_block {
            filter: grayscale(1);

            a {
                pointer-events: none;
            }
        }
    }

    &_head {
        margin-bottom: $space-3;

        @include min-screen($screen__l) {
            display: flex;
            justify-content: space-between;
        }

        &_block {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include min-screen($screen__l) {
                width: calc(50% - (#{$block-loyalty-spacing / 2}));
                margin-right: 1rem;
            }
        }

        .block-dashboard-loyalty {
            margin: 2rem 0 0;

            @include min-screen($screen__l) {
                margin: 0 0 0 $space-1;
            }
        }

        &_status {
            height: 5.6rem;
            padding: $space-2 $space-3;
            border-radius: 0.8rem;
            margin-bottom: $block-loyalty-spacing;
            background-color: $color-orange;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include min-screen($screen__s) {
                margin-bottom: 3rem;
            }

            p,
            span {
                font-size: $font-size-l;
                line-height: 2.4rem;
                color: $color-white;
                font-weight: $font-weight__bold;
                margin: 0;

                &.#{$css-namespace}title {
                    line-height: inherit;
                }
            }

            span {
                text-transform: uppercase;
            }

            &_stars {
                position: relative;
                height: 3.2rem;
                width: 3.6rem;

                @for $i from 1 through 3 {
                    &[data-level='#{$i}'] {
                        .dcfonts-icons--star:nth-child(-n + #{$i}) {
                            opacity: 1;
                        }
                    }
                }

                .dcfonts-icons--star {
                    color: $color-white;
                    opacity: 0.5;
                    position: absolute;

                    &:first-child,
                    &:nth-child(2) {
                        bottom: 0;
                    }

                    &:nth-child(2) {
                        right: 0;
                    }

                    &:last-child {
                        top: 0;
                        left: calc((100% - 1.4rem) / 2);
                    }
                }
            }
        }

        &_points {
            padding: 2rem 2rem 3rem;
            border-radius: 0.3rem;
            background-color: $color-white;
            background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-presentation_xs.svg');
            background-size: 100% 100%;
            margin-bottom: 2rem;

            @include min-screen($screen__s) {
                background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-presentation_lg.svg');
                margin-bottom: 0;
                padding-top: 3rem;
                background-size: cover;
                background-position: center bottom;
                background-repeat: no-repeat;
            }

            @include min-screen($screen__l) {
                margin-top: auto;
                background-size: contain;
            }

            &_list {
                margin: $space-2 0 $space-3 0;
                display: flex;
                justify-content: center;

                @include min-screen($screen__s) {
                    margin: 0 auto 2.5rem;
                    display: block;
                }

                &_subtitle {
                    font-size: $font-size-default;
                    line-height: 2.2rem;
                    font-weight: $font-weight__bold;
                    margin-bottom: 0.7rem;
                }
            }
        }
    }

    &_morepoints {
        background-color: $color-main-light;
        border-radius: 0.3rem;
        padding: 2rem 1.5rem;

        @include min-screen($screen__s) {
            padding: $space-3 $space-5;
        }

        &_blocks {
            display: flex;
            flex-direction: column;
            gap: $block-loyalty-spacing;
            padding: $block-loyalty-spacing 0 0;

            @include min-screen($screen__m) {
                flex-direction: row;
            }
        }

        &_block {
            width: 100%;
            height: 17rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center right;
            border-radius: 1.8rem;
            padding: 2rem 1.5rem;
            display: flex;
            flex-direction: column;
            margin: 0;

            @include min-screen($screen__m) {
                width: calc(100% / 2);
                max-width: 30.8rem;
            }

            .dcfonts-icons--caret-right {
                line-height: 1.7rem;
                font-size: 1.7rem;
                color: $color-secondary;
                margin-left: 0.925rem;
            }

            p {
                font-size: $font-size-default;
                line-height: 1.9rem;
                color: $color-primary;

                @include min-screen($screen__m) {
                    max-width: 25rem;
                }
            }

            a {
                font-size: $font-size-default;
                line-height: 1.9rem;
                margin: auto 0 0;

                &:hover {
                    color: inherit;
                }
            }
        }

        &_elevation {
            background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-challenge-elevation_xs.svg');

            @include min-screen($screen__m) {
                background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-challenge-elevation_lg.svg');
            }
        }

        &_finition {
            background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-challenge-finition_xs.svg');

            @include min-screen($screen__m) {
                background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-challenge-finition_lg.svg');
            }
        }

        &_construction {
            background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-challenge-construction_xs.svg');

            @include min-screen($screen__m) {
                background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-challenge-construction_lg.svg');
            }
        }

        &_h2o {
            background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-challenge-h2o.svg');
        }

        .disabled {
            filter: grayscale(1);
        }
    }

    .#{$css-namespace}loyalty {
        min-height: 35.5rem;
        background-image: url('../Descours_UserAccount/images/dashboard/loyalty-program-subscribe_xs.svg');
        background-size: 100% 100%;

        @include min-screen($screen__s) {
            background-image: url('../Descours_UserAccount/images/dashboard/loyalty-program-subscribe_lg.svg');
            min-height: 34rem;
            background-size: cover;
        }

        .#{$css-namespace}title[data-ui-title-level='3'] {
            font-size: $font-size-xl;

            @include min-screen($screen__m) {
                font-size: $font-size-xxl;
            }
        }

        &_button {
            margin: 0;
        }

        &_list {
            line-height: 2.2rem;
            margin-top: 2rem;
            max-width: 45rem;
            width: 100%;

            @include min-screen($screen__l) {
                max-width: 100%;
                margin: 2rem auto 0 auto;
            }

            &_entry {
                &::before {
                    display: none;

                    @include min-screen($screen__l) {
                        display: block;
                    }
                }
            }
        }
    }

    .#{$css-namespace}loyalty_list,
    #{$root}_head_points .#{$css-namespace}title {
        font-size: $font-size-xl;

        @include min-screen($screen__s) {
            max-width: 40rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .#{$css-namespace}btn {
        // TODO : Refacto needed uniformisation
        line-height: 1.4rem;
        padding: 1.2rem 3rem;

        &--inactive {
            // TODO : Refacto needed using disabled ?
            background-color: $mainNeutralColor;
            border: 0.1rem solid $mainNeutralColor;
            pointer-events: none;
        }
    }
}
