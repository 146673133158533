$table-row-height: 9rem;
$custom-input-text-height: 4rem;
$quantity-picker-width: 12rem;

//  Brand
$brand-text-weight: $font-weight-bold;
$brand-text-case: uppercase;
$brand-text-color: $color-dark-grey;

// Reference
$reference-text-color: $color-black;
$reference-text-color-alt: $color-dark-grey; // Should be all $reference-text-color, ask Figma for details
$reference-text-weight: $font-weight-regular;
$reference-text-size: $font-size-xs;
$reference-text-line-height: 1.4;

// Badge
$badge-padding: #{$space-1 / 2} $space-1;
$badge-border-radius: 4rem;
$badge-border-radius-light: 0.3rem;
$badge-text-size: $font-size-s;
$badge-min-height: 2.4rem; // badge height for top part
$badge-text-line-height: 1.4rem;
$badge-icon-size: $font-size-m;
$badge-text-weight: $font-weight-semi-bold;
