$column-main-padding: 3rem !default;

.customer-account-filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    div {
        display: flex;
        flex-direction: column;
        width: 40%;
    }

    .customer-account-searchbox-container {
        width: 35%;
    }

    .customer-account-searchbox {
        border-radius: 0;
        border-color: $color-blue1;
        margin-top: $indent__s;
        height: 4rem;
    }

    .customer-account-filter-dropdown {
        width: 35%;
    }

    .customer-account-filter-dropdown.is-selected {
        border-radius: 0;
        width: 35%;
        @include lib-vendor-prefix-appearance();
        @include lib-background-image('svg/_clean/caret-up.svg', 97% 1.5rem, no-repeat, transparent, false);
        background-size: 2rem;
        padding: $indent__s $indent__base $indent__s $indent__s;
    }
}

@include max-screen($screen__s) {
    .customer-account-filters {
        flex-direction: column;
        padding: 0 $indent__xs;

        div {
            flex: 1 1 auto;
        }

        .customer-account-searchbox-container {
            width: 100%;
        }

        .customer-account-filter-dropdown {
            width: 100%;
            margin-top: $indent__base;
        }
    }
}

// list filter form
.#{$css-namespace}useraccount_heading-form-wrapper {
    margin-top: $column-main-padding;
    padding: 2rem $column-main-padding;
    background: $color-white-smoke-2;

    .customer-account-filters .customer-account-searchbox {
        border-color: $color-gray64;
    }

    .nice-select {
        background-color: $color-white;
        border-color: $color-gray64;

        span {
            color: inherit;
            padding-left: 1.5rem;
        }

        &::after {
            border-color: $color-blue-dark;
        }
    }

    form input[type='text'] {
        border-color: transparent;
        border-radius: 0.3rem;
        box-shadow: 0 0 0.4rem rgba($color-black, 0.15); // TODO: Use box-shadow var
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    @include min-screen($screen__l) {
        margin-left: $column-main-padding * -1;
        margin-right: $column-main-padding * -1;
    }
}
