// defined icons
.#{$css-namespace}tooltip {
    // Fat grey tooltip
    &_container {
        position: absolute;
        background-color: #d2d2d2; // TODO: use $color-gray83 instead of hexa
        text-align: center;
        color: #666666; // TODO : use $color-gray40 var instead of hexa
        padding: 2.8rem 2rem;
        bottom: 4.2rem;
        box-shadow: 0.2rem 0.4rem 0.6rem 0 rgba($color-black, 0.17); // TODO: Use box-shadow var

        &_icon {
            fill: #666666; // TODO : use $color-gray40 var instead of hexa
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            width: 2.2rem;
            height: 2.2rem;
        }

        &_arrow {
            position: absolute;
            background-color: #d2d2d2; // TODO: use $color-gray83 instead of hexa
            top: 100%;
            left: 5%;
            width: 1rem;
            height: 1rem;
            transform: translate3d(50%, -50%, 0) rotate3d(0, 0, 1, 45deg);
            box-shadow: 0.4rem 0.2rem 0.6rem 0 rgba($color-black, 0.17); // TODO: Use box-shadow var
        }
    }
}
