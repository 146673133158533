.#{$css-namespace}modal-equivalent-products-modal {
    .modal-title {
        display: flex;
        margin: 0;
        width: 100%;
        text-align: left !important;
        padding: 0 0 3.98rem !important;
        font-style: normal !important;
        font-size: $font-size-xxl !important;
        line-height: $font-size-xxl !important;
        color: $color-secondary !important;
        font-weight: $font-weight-bold !important;
    }

    #equivalent-products-modal {
        width: 100% !important;
    }

    .#{$css-namespace}equivalent-products__modal__empty-text {
        font-weight: $font-weight-bold;
        font-size: $font-size-m;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.hidden {
            display: none !important;
        }

        span {
            margin-top: $space-2;
            padding: $space-1;
        }
    }

    .#{$css-namespace}equivalent-products__modal__container {
        width: 100%;

        .#{$css-namespace}equivalent-products {
            border-radius: initial;
            box-shadow: none;
            margin: 0;
            padding: 0;
            width: 100%;
            left: 0;

            &__top {
                margin: 0;
            }
        }
    }
}
