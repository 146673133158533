/*
  Grid system implementation
  Responsive layout: 4, 8, 12 columns
  Based on Material specification
  https://material.io/design/layout/responsive-layout-grid.html
  TODO: update Magento breakpoints to adapt Responsive mode or keep existing:
*/

/**
 * @function 'getNbCols'
 * @desc: calculate number of columns in specific context
 *        rounded
 *        minimum number of columns = 1
 * @params:
 *    $col (int): number of columns desired
 *    $col-max (int): number of max columns in grid (ex: 12 columns grid)
 *    $col-context (int): number of max columns in context (4, 8 or 12)
 * @return (int): number of columns in context
 * @example:
 *    test for '5 cols' in small grid (8 columns in context / 12 columns max)
 *    apply on 'grid-5' or 'col-5' class
 *    col = 5
 *    col-max = 12
 *    col-context = 8
 *
 *    calcul : 5/12 * 8 = 3.3333
 *    return = 3
 */
@function getNbCols($col, $col-max, $col-context) {
    $res: round(($col / $col-max) * $col-context);

    // force round to '1' if result = 0
    @if ($res == 0) {
        $res: 1;
    }

    @return $res;
}

/**
* @mixin 'makeGrid'
* @desc: auto-generate number of grid-template-columns for grid declaration
* @params:
*    $gridcol-min (int): number of min columns in grid (ex: 1 column)
*    $gridcol-max (int): number of max columns in grid (ex: 12 columns grid)
*    $gridcol-context (int): number of max columns in context (4, 8 or 12)
*/
@mixin makeGrid($gridcol-min, $gridcol-max, $gridcol-context) {
    @for $i from $gridcol-min through $gridcol-max {
        // calculate nb of columns
        $nb-cols: getNbCols($i, $gridcol-max, $gridcol-context);

        // generate CSS rules
        @if ($nb-cols == 1) {
            &[data-ui-grid-cols='#{$i}'] {
                grid-template-columns: 1fr;
            }
        } @else {
            &[data-ui-grid-cols='#{$i}'] {
                grid-template-columns: repeat($nb-cols, 1fr);
            }
        }
    }
}

/**
 * @mixin 'makeCol'
 * @desc: auto-generate size of grid-column
 * @params:
 *    $gridcol-min (int): number of columns desired
 *    $gridcol-max (int): number of max columns in grid (ex: 12 columns grid)
 *    $gridcol-context (int): number of max columns in context (4, 8 or 12)
 *    $screen (string) : specific code for RWD (xs, sm, md, lg, xl)
 */
@mixin makeCol($gridcol-min, $gridcol-max, $gridcol-context, $screen: 'all') {
    @for $i from $gridcol-min through $gridcol-max {
        // generate CSS rules
        @if ($screen != 'all') {
            &[data-ui-grid-col-size-#{$screen}='#{$i}'] {
                grid-column: auto / span getNbCols($i, $gridcol-max, $gridcol-context);
            }
        } @else {
            &[data-ui-grid-col-size='#{$i}'] {
                grid-column: auto / span getNbCols($i, $gridcol-max, $gridcol-context);
            }
        }
    }
}

@import 'grid/grid';
@import 'grid/grid-col';
