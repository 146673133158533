$custom-table-border-color: $color-gray82 !default;

@mixin list-table-text-xs {
    font-size: $font-size-s;
    line-height: 1.416;
}

@mixin list-table-text-lg {
    font-size: $font-size-default;
    line-height: 1.357;
}

@mixin full-width-td {
    display: flex;
    flex-direction: row;
    margin-bottom: $space-1 / 2;
    align-items: center;

    &:last-child {
        margin-bottom: 0;
    }
}

// Assigned custom tables
.#{$css-namespace}orders,
.#{$css-namespace}wishlists {
    &_table {
        display: block;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &.table-dc {
            display: table;
            border-collapse: collapse;
            border-spacing: 0;
            direction: ltr;

            @include min-screen($screen__l) {
                &:not(:has(.dataTables_empty)) {
                    table-layout: fixed;
                }
            }
        }

        &.table-dc.table > tbody > tr,
        &.table-dc.table > thead > tr,
        &.table-dc.table > tfoot > tr {
            line-height: 1.4;
        }

        > tbody > tr > td,
        td,
        td * {
            vertical-align: top;

            p:last-child {
                margin-bottom: 0;
            }
        }

        // thead
        &_head {
            &_group {
                width: 100%;

                th {
                    font-size: $font-size-s;
                    line-height: 1.416;
                    font-weight: $font-weight-regular;
                    padding: var(--datatable-th-spacing) var(--datatable-cell-spacing);

                    &:empty {
                        display: none;
                    }

                    &:first-child {
                        @include min-screen($screen__l) {
                            padding-left: var(--datatable-cell-spacing);
                        }
                    }

                    &:not(.hidden):last-child {
                        @include min-screen($screen__l) {
                            padding-right: var(--datatable-cell-spacing);
                        }
                    }
                }
            }

            > div:last-child {
                @include max-screen($screen__l) {
                    padding: 0.3rem 0 #{$space-1 / 2} 0;
                }
            }
        }

        // tbody
        &_block_item,
        &_item {
            @include min-screen($screen__l) {
                display: flex;
            }

            > div {
                @include max-screen($screen__l) {
                    @include full-width-td();
                }
            }
        }

        > tbody > tr,
        &_block {
            padding-top: var(--datatable-cell-spacing);
            padding-bottom: var(--datatable-cell-spacing);
            border-top: 0.1rem solid $custom-table-border-color;
            border-bottom: none;
            border-left: 0.3rem solid transparent;
            cursor: pointer;
            position: relative;

            &:hover {
                @include min-screen($screen__l) {
                    box-shadow: var(--box-shadow);
                    border-left-color: $color-secondary;
                }

                .#{$css-namespace}orders_table_item_number,
                > td {
                    &:first-child {
                        @include min-screen($screen__l) {
                            border-left-color: $color-secondary;
                        }
                    }
                }
            }
        }

        > tbody > tr {
            border-top: 0.1rem solid $color-disabled-light;
        }

        .#{$css-namespace}orders_table_head_group,
        .#{$css-namespace}orders_table_item {
            @include min-screen($screen__l) {
                padding: 0 var(--datatable-cell-spacing);

                &.#{$css-namespace}orders_table-lg:is(div) {
                    // Fake table in dashboard
                    margin-bottom: var(--datatable-th-spacing);
                }
            }
        }

        > tbody {
            width: 100%;

            @include max-screen($screen__l) {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
            }

            > tr {
                @include max-screen($screen__l) {
                    width: 100%;
                }

                > td {
                    @include min-screen($screen__l) {
                        padding: var(--datatable-cell-spacing);
                    }

                    @include max-screen($screen__l) {
                        @include full-width-td();
                    }

                    &:first-child {
                        @include min-screen($screen__l) {
                            padding-left: var(--datatable-cell-spacing);
                        }
                    }

                    &:not(.hidden):last-child {
                        @include min-screen($screen__l) {
                            padding-right: var(--datatable-cell-spacing);
                        }
                    }
                }
            }
        }

        //
        &_item {
            &_text {
                overflow-wrap: break-word;

                @include max-screen($screen__l) {
                    width: 58%;
                }

                &-number {
                    @include list-table-text-xs();
                    font-weight: $font-weight__heavier;
                    margin-bottom: 0;

                    @include min-screen($screen__l) {
                        margin-bottom: 0.3rem;
                        font-size: $font-size-default;
                        line-height: 1.6rem;
                    }
                }

                &-date {
                    @include list-table-text-xs();
                    color: $color-grey-dove;
                }

                &-sender {
                    color: $color-grey-dark2;
                    @include list-table-text-xs();

                    @include min-screen($screen__l) {
                        @include list-table-text-lg();
                    }
                }

                &-total {
                    @include list-table-text-xs();

                    @include min-screen($screen__l) {
                        @include list-table-text-lg();
                    }
                }
            }

            &_status {
                [class*='_item_text'] {
                    text-transform: uppercase;
                    text-align: center;
                    border-radius: 1.6rem;
                    padding: 0.3rem;
                    font-size: $font-size-xs;
                    line-height: 1.5rem;
                    width: 12.9rem;
                    max-width: 12.9rem;

                    &.#{$css-namespace}status-Encours {
                        border: $status-border solid $color-order-status-pending;
                    }

                    &.#{$css-namespace}status-Annule {
                        border: $status-border solid $color-order-status-canceled;
                    }

                    &.#{$css-namespace}status-Termine {
                        border: $status-border solid $color-success-secondary;
                    }

                    @include min-screen($screen__l) {
                        @include list-table-text-xs();
                        width: $status-width-lg;
                        max-width: $status-width-lg;
                        padding: 0.55rem;
                    }
                }

                @include max-screen($screen__l) {
                    margin-top: $space-1 / 2;
                }

                &-size {
                    @include min-screen($screen__l) {
                        width: $status-width-lg;
                        text-align: center;
                    }
                }
            }

            &_actions {
                .#{$css-namespace}orders_table-xs {
                    @include max-screen($screen__l) {
                        margin-bottom: auto;
                        padding-top: 0.5rem;
                    }
                }
            }

            &_actions &_text {
                @include min-screen($screen__l) {
                    width: var(--actions-cell-width, 5.1rem);
                    min-width: fit-content;
                    white-space: nowrap; // Keep icons aligned
                }

                // Specific for fake datatable in dashboard
                &.align-items-flex-start .action {
                    @include min-screen($screen__l) {
                        margin-top: 0;

                        + form {
                            vertical-align: auto;

                            button {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }

        .action {
            margin-top: 0.5rem;
            font-size: $font-size-l;

            &:empty {
                // Keep viewmore link as it is used to redirect on row click
                margin: 0 !important;
                font-size: 0;
            }

            + form {
                display: inline-block;
                vertical-align: middle;

                button {
                    border: none;
                    margin: -0.3rem 0 0;
                    padding: 0;

                    &:not(.#{$css-namespace}btn) {
                        background: transparent;
                    }
                }
            }

            @include min-screen($screen__l) {
                display: inline-block;
                font-size: $font-size-xl;
                vertical-align: middle;

                &:not(:last-child) {
                    margin-right: var(--datatable-cell-spacing);
                }

                @include lib-hover-focus {
                    color: $color-primary;
                }

                &.viewmore {
                    .#{$css-namespace}dashboard_orders & {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                &:not(.viewmore) {
                    position: relative;
                    z-index: 2;
                }
            }

            @include max-screen($screen__l) {
                &.#{$css-namespace}btn {
                    width: 100%;
                    text-align: center;

                    .dcfonts-icons {
                        // Hide icon if button is rebuilt in js
                        display: none;
                    }
                }
            }
        }

        // Specific font-size due to icon ratio
        .dcfonts-icons--edit {
            font-size: 2.2rem;
        }

        .dcfonts-icons--eye {
            font-size: $font-size-m;
        }

        &-lg,
        &-xs {
            @include list-table-text-xs();
            color: $color-grey-dove;
            text-transform: uppercase;
        }

        &-xs {
            line-height: 1.7rem;
            width: 42%;
            padding-right: 2rem;
            display: flex;
            align-items: center;

            @include min-screen($screen__l) {
                display: none;
            }
        }

        &-lg {
            display: none;
            line-height: 1.4rem;
            height: 1.4rem;

            @include min-screen($screen__l) {
                display: flex;
                flex-direction: row;
                .table-dc & {
                    display: table-row-group;
                }
            }
        }
    }
}
