%table-label {
    color: $color-grey-dove;
    font-size: $font-size-s;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
}

.#{$css-namespace}user-account {
    $root: &;
    $box-shadow: var(--box-shadow);
    $color-search-icon: $color-gray40;

    &_page-head {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 0.3rem;
        padding: 2rem 1rem;
        background-color: $color-white;
        box-shadow: $box-shadow;
        margin-top: $indent__base;

        @include min-screen($screen__l) {
            align-items: flex-start;
            padding: 3rem;
            margin-top: 0;
        }
    }

    &_title,
    &_title[data-ui-title-level='1'] {
        line-height: 4rem;

        @include min-screen($screen__l) {
            line-height: 5rem;
        }
    }

    &_description {
        margin: 0;
        line-height: 1.9rem;

        @include min-screen($screen__l) {
            margin: 1.5rem 0 0;
        }
    }

    &_cta {
        justify-content: center;
        height: 4rem;
        font-weight: $font-weight-medium;

        @include min-screen($screen__l) {
            min-width: 25.7rem;
            margin-top: 3rem;
        }
    }

    &_page-head + &_content-container {
        margin-top: 2rem;

        @include min-screen($screen__l) {
            margin-top: 3rem;
        }
    }

    &_content-container {
        display: flex;
        flex-direction: column;
        border-radius: 0.3rem;
        background-color: $color-white;
        box-shadow: $box-shadow;
    }

    &_filters {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 0.3rem;
        padding: 2rem 1rem;
        background-color: $color-primary;

        @include min-screen($screen__l) {
            flex-direction: row;
            align-items: center;
            padding: 3rem;
            height: 10rem;
            background-color: $color-white-smoke-2;
            margin-bottom: 0;
        }
    }

    &_search-container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;
        border-radius: 0.3rem;
        height: 4rem;
        background-color: $color-white;
        box-shadow: $box-shadow;

        @include min-screen($screen__l) {
            margin-bottom: 0;
            margin-right: 3rem;
            width: 60rem;
        }
    }

    &_search-icon {
        padding-left: 1rem;
        font-size: $font-size-xl;
        color: $color-search-icon;
    }

    &_search-input[type='text'] {
        // Remove attribute selector once global style fixed.
        border: none;
        height: 4rem;
        padding: 1rem;
        text-overflow: ellipsis;

        &::placeholder {
            color: $color-grey-dove;
        }
    }

    &_nice-select {
        height: 4rem;
        border: 0.1rem solid $color-grey-dove;
        border-radius: 0.3rem;
        background-color: $color-white;

        &::after {
            border-color: $color-primary;
        }

        &.open {
            overflow-y: initial;
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .list {
                width: calc(100% + 0.2rem);
            }
        }

        .list {
            margin: 0 -0.1rem 0;
            border: 0.1rem solid $color-primary;
            border-top-color: transparent;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
            max-height: 22.5rem;
            overflow: auto;
            overflow-x: hidden;
            @extend %scrollbars !optional;

            .option {
                margin: 0;
                min-height: 2.6rem;
                line-height: 2.6rem;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .current {
            color: $color-main-dark;
            height: auto;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        @include min-screen($screen__l) {
            width: 19rem;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;

        @include min-screen($screen__l) {
            padding: 0 0 3rem;
        }
    }

    &_table {
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            display: table;
        }

        thead {
            display: none;

            @include min-screen($screen__l) {
                display: table-header-group;
            }
        }

        th {
            @extend %table-label;
            padding-top: 3rem;
        }

        th,
        td {
            vertical-align: middle;

            @include min-screen($screen__l) {
                height: 7.2rem;
            }
        }

        tbody {
            display: flex;
            flex-direction: column;

            @include min-screen($screen__l) {
                display: table-row-group;
            }

            tr {
                display: flex;
                flex-direction: column;
                padding: $space-2 0;
                border-top: 0.1rem solid $color-disabled-light;

                @include min-screen($screen__l) {
                    display: table-row;
                    padding: 0;
                }

                &:hover {
                    @include min-screen($screen__l) {
                        cursor: pointer;
                        box-shadow: $box-shadow;

                        td:first-child {
                            border-left-color: $color-secondary;
                        }

                        #{$root}_name-label {
                            text-decoration: none;
                        }
                    }
                }
            }

            td {
                display: flex;

                flex-direction: column;
                align-items: flex-start;

                @include min-screen($screen__l) {
                    display: table-cell;
                }

                &:first-child {
                    border-left: 0.3rem solid transparent;
                }
            }

            #{$root}_mobile-row {
                display: flex;
                flex-direction: row;
                align-items: center;

                @include min-screen($screen__l) {
                    display: table-cell;
                }

                #{$root}_mobile-label {
                    width: 50%;
                }
            }
        }

        .dataTables_empty {
            pointer-events: none;
        }
    }

    &_name-label {
        color: $color-secondary;
        font-weight: $font-weight-bold;
        text-decoration: underline;
    }

    &_mobile-label {
        display: flex;

        @extend %table-label;

        @include min-screen($screen__l) {
            display: none;
        }
    }

    &_actions {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &_action-btn {
        display: inline-flex;
        flex-direction: row;
        border: none;
        padding: 0;
        color: $color-grey-dove;
        font-size: 2.1rem; // Icon size
        background: transparent;

        & + & {
            margin-left: 1.2rem;
        }

        @include lib-hover-focus {
            color: $color-secondary;
        }

        &[type='button'] {
            @include lib-hover-focus {
                border: none;
                background-color: transparent;
            }
        }
    }

    &_delete-action {
        padding: 0;
        font-size: $font-size-m;
        color: $color-grey-dark2;
    }
}
