.account--success {
    display: flex;
    flex-direction: column;
    gap: $space-3;

    &-title {
        padding: 0 !important;
    }

    &-content {
        display: flex;
        align-items: center;
        gap: $space-2;
        padding: $space-2;
        background-color: rgba($color-success-secondary, 0.1);
        border: 0.1rem solid $color-success-secondary;
        border-radius: 0.3rem;

        i {
            font-size: 2.4rem;
            color: $color-success-secondary;
        }

        p {
            margin: 0;
        }
    }

    &-summaries {
        padding: $space-2 $space-3;
        list-style-type: none;
        background-color: $color-grey-background;
        margin: 0;
    }

    &-summary {
        margin: 0;

        b {
            color: $color-primary;
        }
    }

    &-login {
        justify-content: center;
    }
}
