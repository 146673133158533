$productNameHeight: 3.5rem;
$product-table-border-spacing: 0.5rem;

// Prevent regressions due to dataTable library's fixed width //
.#{$css-namespace}reference_table {
    $root: &;

    &--no-fixed-width {
        width: auto !important;
    }

    &_caption {
        display: block;
        font-size: $font-size__xxs;
        margin-top: $space-1;

        &:first-child {
            margin-top: 0;
        }
    }

    &_wishlist {
        position: relative;

        .wishlist {
            &::before,
            &::after {
                content: none !important;
            }
        }

        .items {
            font-weight: $font-weight-regular;
            color: $color-black;
        }

        .#{$css-namespace}actions_button {
            border: 0;
            background: none;
            padding: 0;
            margin: 0;

            svg {
                fill: $color-primary;
            }

            .dcfonts-icons {
                font-size: $font-size-xl;
                margin-right: 1rem;
                color: $color-primary;
            }

            ~ .items {
                left: -0.7rem;
            }
        }
    }

    &_table {
        border-collapse: separate;
        border-spacing: 0 $space-1;

        tr {
            td,
            th {
                position: relative;

                + td,
                + th {
                    &::before {
                        content: ' ';
                        position: absolute;
                        top: $product-table-border-spacing;
                        bottom: $product-table-border-spacing;
                        left: 0;
                        width: 0.1rem;
                    }
                }
            }

            td + td {
                &::before {
                    bottom: auto;
                    top: $productNameHeight + $space-1;
                    height: calc(100% - (#{$productNameHeight} + #{$space-2}));
                    background-color: rgba($color-black, 0.4);
                }
            }

            td.addToCard {
                &::before {
                    content: none;
                }
            }

            td.addToCardForm {
                .flex-direction-row {
                    // column logic
                    justify-content: center;
                    align-content: space-between;
                }

                .#{$css-namespace}add_cart_slot__content {
                    align-items: center;
                    padding: 0;
                }
            }
        }

        thead {
            background-color: $color-primary;
            color: $color-white;
            font-size: $font-size-xs;

            th {
                padding: 1.4em 1em;
                text-align: center;
                vertical-align: middle;

                &::before {
                    background-color: $color-white;
                }

                &.discriminants.--narrow {
                    width: 7rem;

                    @include min-screen($screen__xl) {
                        width: 10rem;
                    }
                }
            }

            .sorting,
            .sorting_asc,
            .sorting_desc {
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center right;
            }

            .sorting {
                background-image: url('../images/png/sort_both.png');
            }

            .sorting_asc {
                background-image: url('../images/png/sort_asc.png');
            }

            .sorting_desc {
                background-image: url('../images/png/sort_desc.png');
            }
        }

        tbody {
            tr {
                background-color: $color-white;
                height: 16.5rem;
            }

            td {
                padding: $productNameHeight 1rem 0;
                vertical-align: middle;
                min-height: 11rem;
                height: 100%;

                @include min-screen($screen__l) {
                    padding-left: $space-1;
                    padding-right: $space-1;
                }

                > .flex-direction-row {
                    display: flex;
                    flex-flow: row wrap;
                    gap: $space-1;
                    justify-content: space-between;

                    &.rerefence-table-row-checked {
                        height: 100%;
                    }

                    .--empty {
                        display: none;
                    }
                }

                > .productName {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: $productNameHeight;
                    padding: $indent__xs $indent__s;
                    background-color: $color-grey-background;
                    border: 0;
                    font-size: $font-size-s;
                    color: $color-primary;
                    white-space: nowrap;

                    &::before {
                        content: ' ';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        right: -1.5rem;
                        height: $productNameHeight;
                        width: 3rem;
                        transform: skew(-20deg);
                        background-color: $color-grey-background;
                    }

                    .#{$css-namespace}icon {
                        margin-right: $indent__s;
                        font-size: 2rem;
                    }

                    a {
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                > .info-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: $space-1;
                    gap: $space-1;
                    background-color: transparent;
                    color: $color-secondary;
                    font-size: $font-size-s;
                    text-transform: none;

                    > span {
                        text-decoration: underline;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                        cursor: pointer;

                        .info-tooltip {
                            @include min-screen($screen__l) {
                                display: block;
                                font-weight: $font-weight-regular;
                            }
                        }
                    }

                    .#{$css-namespace}fonts-icons {
                        font-size: 1.6rem;
                    }
                }

                .info-tooltip {
                    position: absolute;
                    z-index: 2;
                    top: $productNameHeight; // height of productName
                    left: 0;
                    display: none;
                    min-height: calc(100% - #{$productNameHeight});
                    width: 38rem;
                    background: $color-primary;
                    font-size: $font-size-s;
                    color: $color-white;
                    font-weight: $font-weight-regular;

                    &.show {
                        display: block;
                    }

                    header {
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding: 1em;
                        font-size: $font-size-default;
                        gap: $space-1;

                        .#{$css-namespace}fonts-icon {
                            font-size: 1.3rem;
                        }

                        span {
                            line-height: 1;
                        }

                        [data-js-info-close-button] {
                            position: absolute;
                            top: 1rem;
                            right: 1rem;
                            cursor: pointer;
                            font-size: $font-size-xs;
                            font-weight: $font-weight-black;
                            width: 2.5rem;
                            height: 2.5rem;

                            @include min-screen($screen__l) {
                                display: none;
                            }
                        }
                    }

                    .tooltip-close-btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        border: 0;

                        &:hover,
                        &:focus {
                            background-color: transparent;
                            color: currentColor;
                        }

                        > .#{$css-namespace}icon {
                            width: 1em;
                            height: 1em;
                            font-size: $font-size-s;
                        }
                    }

                    .info-tooltip_content {
                        padding: 0 1.25em 1.25em;
                    }
                }
            }

            .productInfo {
                > span {
                    font-size: $reference-text-size;
                    color: $reference-text-color;
                }
            }

            .discriminants {
                font-weight: $font-weight-bold;
                font-size: $font-size-s;
                text-align: center;
                vertical-align: middle;
            }

            .not_sellable {
                text-align: center;

                b {
                    display: block;
                    color: $color-primary;
                }

                a {
                    display: block;
                }
            }

            .priceWrapper {
                font-size: $font-size-xs;
                text-align: center;
                vertical-align: middle;

                .product-custom-price-text {
                    display: none;
                }

                .product-info-price {
                    color: $text-price-color;
                    display: flex;
                    flex-direction: column;
                    gap: $space-1;

                    .price-container {
                        white-space: nowrap;
                        color: $color-primary;

                        .disconnected & {
                            color: $text-price-color;
                        }
                    }

                    &.promo {
                        .price-container {
                            color: $color-main-dark;
                        }
                    }

                    &-wrapper {
                        padding: $space-1;
                        align-items: center;
                        color: $color-main-dark;

                        .#{$css-namespace}product_promo__percent {
                            background-color: rgba($color-red-promo, 0.1);
                        }

                        &.has_promo {
                            gap: 0;

                            .price-container,
                            .product-info-price {
                                color: $color-red-promo;
                            }

                            .product-info-price {
                                gap: 0;
                            }

                            .#{$css-namespace}product_promo {
                                margin-top: $space-1;
                                gap: $space-2;
                            }
                        }
                    }
                }

                .price-container {
                    font-size: $font-size__baseplus;
                    display: block;
                    font-weight: $font-weight-semi-bold;
                    line-height: 2rem;
                }

                .price_unit,
                .unit {
                    color: inherit;
                }

                .unit {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    color: $text-price-unit-color;
                    font-size: $text-price-unit-size;
                }

                .#{$css-namespace}product_slot__price_info {
                    margin-top: 0;
                    font-style: normal;
                    font-weight: $font-weight-regular;
                }

                .#{$css-namespace}price-levels_chip {
                    &_text {
                        font-size: $font-size-s;
                        line-height: 1.7rem;
                    }
                }
            }

            .form {
                > .#{$css-namespace}add_cart_slot__content {
                    padding: 1rem 0;
                }
            }

            .addToCardForm,
            .addToCard {
                vertical-align: middle;

                &:has([data-delivery-mode='online'].--empty) .flex-direction-row {
                    // Click collect to stay on its row logic
                    align-content: flex-end;
                }
            }

            .addToCardForm__link {
                white-space: nowrap;
                font-weight: $font-weight-bold;
                text-decoration: underline;
                color: $color-secondary;
            }

            .equivalent-products-modal-link {
                margin-left: auto;
            }

            .addToCard {
                text-align: right;

                .flex-direction-row {
                    // column logic
                    justify-content: flex-end;
                    align-content: space-between;
                }

                &:has(.equivalent-products-modal-link) .equivalent-products-modal-link {
                    justify-content: flex-end; // keep flex-end alignment to wrapper
                }

                .#{$css-namespace}add_cart_slot__form {
                    flex: 0 0 $space-5;
                }

                .#{$css-namespace}add_cart_slot__add_button {
                    width: 100%;
                    max-width: 100%; // prevent override in _add_cart_slot.scss:93 on #{$root}__add_button {
                    margin-left: 0;
                }

                .#{$css-namespace}add_cart_slot__content,
                .#{$css-namespace}add_cart_slot__form {
                    justify-content: flex-end;
                    margin-top: auto;
                    min-height: auto;
                    padding: 0;
                    gap: $space-1;
                    width: 100%;
                    background-color: transparent;

                    .flex-direction-row & {
                        margin-left: auto;
                    }
                }
            }

            .stock {
                &__delivery,
                &__local {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: $space-1;
                }

                .flex-direction-row {
                    row-gap: $space-3;
                }

                .#{$css-namespace}availability {
                    line-height: 1;

                    &:last-child:not(.low-stock) {
                        min-height: 3.3rem; // to keep availability centered if it contain only one line
                        padding: 0;
                    }

                    &.low-stock {
                        padding: 0;
                    }

                    &__shop {
                        font-size: $font-size-s;
                        color: $color-secondary;
                        width: 100%;
                    }

                    &__desc {
                        font-size: $font-size-s;
                    }
                }

                .#{$css-namespace}add_cart_layer__stock_link {
                    border: none;
                    background: none;
                    font-size: $font-size-xs;
                    text-decoration: underline;
                    color: $color-secondary;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .price_amount {
                font-size: $font-size-m;
                color: $color-primary;
                font-weight: $font-weight-bold;
            }

            .price_vat {
                font-size: $font-size-m;
                font-weight: $font-weight-bold;
            }
        }
    }

    &_ref-header {
        @include min-screen($screen__l) {
            min-width: 11rem;
        }
    }

    &_price-header {
        @include min-screen($screen__l) {
            min-width: 10rem;
        }
    }

    &_stock {
        .#{$css-namespace}availability {
            width: 100%;
            padding: 1.5rem 0 0 !important;
        }
    }

    &_quantity-header {
        width: $quantity-picker-width + $space-2;
    }

    &_addtocart-header {
        width: 20rem;
    }

    &:not(#{$root}--disconnected) #{$root}_table tbody td {
        @include min-screen($screen__l) {
            &:first-child {
                padding-left: $space-1 !important;
            }

            &:last-child {
                padding-right: $space-1 !important;
            }
        }
    }

    &#{$root}--disconnected #{$root}_table tbody td.addToCardForm {
        padding-right: $space-5;
        text-align: right;
    }
}
