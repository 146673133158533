// Common value

$defaultFont: Arial, verdana, sans-serif !default;
$mainFont: 'Proxima Nova';
$subFont: Arial, Verdana, sans-serif;
$alterFont: $mainFont;

// Button value
// TODO: Refacto
$mainButtonFont:
    $mainFont + ' Bold',
    sans serif;
$subButtonFont: arial;
$alterButtonFont: verdana;

// Price value

$mainPriceFont: $mainFont;
$subPriceFont: $subFont;
$alterPriceFont: $alterFont;
$text-price-color: $color-main-dark;
$text-price-unit-color: $color-dark-grey;
$text-price-unit-size: $font-size-s;

// Text value

$mainTextFont: $mainFont;
$subTextFont: $subFont;
$alterTextFont: $alterFont;

// Heading value

$mainHeadingFont: $mainFont;
$subHeadingFont: $subFont;
$alterHeadingFont: $alterFont;
