body {
    background-color: $color-background-page;

    &.catalog-product-view,
    &.checkout-cart-index,
    &.catalog-product-view,
    &.checkout-onepage-success,
    &.catalogsearch-result-index,
    &.descours_events-catalogevent-index,
    &.account,
    &.catalog-category-view.page-layout-2columns-left,
    &.sales-order_view-weborder.page-layout-2columns-left,
    &.sales-order_view-storeorder.page-layout-2columns-left,
    &.cms-operations-bons-plans-du-moment {
        background-color: $color-grey-background;
    }
}

.page-footer {
    .#{$css-namespace}footer {
        &,
        &-bottom {
            padding: 3rem 1.5rem 0;
            background-color: $color-main-dark;
            color: $color-text-invert;

            .#{$css-namespace}list a {
                pointer-events: auto;
                color: currentColor;
                font-size: $font-size-s;

                @include min-screen($screen__l) {
                    font-size: $font-size-default;
                }

                @include lib-hover-focus {
                    text-decoration: underline;
                    color: currentColor;
                }
            }
        }

        &-bottom {
            border-top: 0.1rem solid $color-main-light;
        }
    }

    .#{$css-namespace}footer-navigation {
        max-width: 1325px; // = see $layout__max-width / layout magento limitation. TODO change in all site ?
        margin: 0 auto;
    }

    .#{$css-namespace}footer-bottom {
        border-top: 0.1rem solid $color-main-light;
        margin: 0 auto;
        padding: $space-2;
    }

    @media screen and (min-width: $screen__l) {
        .#{$css-namespace}footer {
            padding: 5.4rem $space-6 $space-2;
        }
    }
}

// import sections inside footer
// @import "footer/reinsurance"; // TODO block reinsurance
@import 'footer/footer-customer-support'; // TODO block newsletter + find a shops
@import 'footer/footer-navigation';
@import 'footer/footer-bottom';
@import 'footer/footer-before-footer';

@import 'confirm/confirm-sections';
