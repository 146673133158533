$loyalty-content-max-width: 143.8rem;

body.useraccount-loyalty-proliansetmoi {
    background: $color-grey-background;

    .columns {
        .column {
            &.main {
                padding: 0 0.5rem;
            }
        }
    }
}

.#{$css-namespace}loyalty-cms-page {
    background-color: $color-white;
    padding: $space-3;
    max-width: $loyalty-content-max-width;
    margin: $space-1 auto 0;
    width: 100%;
    color: $color-black;

    @include min-screen($screen__m) {
        padding: $space-5;
    }

    @include min-screen($screen__l) {
        margin-top: 3rem;
    }

    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    p {
        line-height: 2rem;
    }

    .#{$css-namespace}hr {
        margin: 3rem 0 0;
    }

    .#{$css-namespace}title {
        &-section {
            padding-left: 0;
            margin-bottom: 1.5rem;

            @include min-screen($screen__l) {
                margin-bottom: 2.78rem;
            }
        }

        &[data-ui-title-level='3'] {
            font-size: $font-size-xl;
            line-height: 2.5rem;
            padding-top: 3rem;
            margin-bottom: 2rem;
        }

        br {
            @include min-screen($screen__l) {
                display: none;
            }
        }
    }

    & &__description {
        line-height: 2rem;
    }

    & &__notice {
        margin-top: 1.1rem;
        color: $color-grey-dove;
        font-size: $font-size-xs;
        line-height: 1.4rem;
    }

    &__cards {
        padding-bottom: 3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        @include min-screen($screen__m) {
            flex-wrap: nowrap;
        }
    }

    &__card {
        background: $color-loyalty-blue-grey;
        width: 100%;
        border-radius: 0.3rem;
        overflow: hidden;
        min-height: 33.7rem;

        @include min-screen($screen__s) {
            width: calc(50% - 1rem);
        }

        @include min-screen($screen__m) {
            flex: 1;
        }

        p {
            margin: 0;

            + p {
                margin-top: 1rem;
            }
        }

        &-image {
            background-image: url('../Descours_UserAccount/images/cms/benefit-xs-1.svg');
            background-repeat: no-repeat;
            height: calc((100vw - 4rem) * (200 / 280));
            background-size: cover;

            @include min-screen($screen__s) {
                height: 20rem;
                background-size: auto;
                background-position: center;
            }

            @include min-screen($screen__m) {
                background-image: url('../Descours_UserAccount/images/cms/benefit-lg-1.svg');
            }

            &[data-image='first-order'] {
                background-image: url('../Descours_UserAccount/images/cms/benefit-xs-2.svg');

                @include min-screen($screen__m) {
                    background-image: url('../Descours_UserAccount/images/cms/benefit-lg-2.svg');
                }
            }

            &[data-image='shop'] {
                background-image: url('../Descours_UserAccount/images/cms/benefit-xs-3.svg');

                @include min-screen($screen__m) {
                    background-image: url('../Descours_UserAccount/images/cms/benefit-lg-3.svg');
                }
            }

            &[data-image='subscription'] {
                background-image: url('../Descours_UserAccount/images/cms/benefit-xs-4.svg');

                @include min-screen($screen__m) {
                    background-image: url('../Descours_UserAccount/images/cms/benefit-lg-4.svg');
                }
            }
        }

        &-content {
            padding: 1.5rem 1.5rem $space-3;
        }

        &-title {
            color: $color-primary;
            font-weight: $font-weight-bold;
            margin-bottom: 1.2rem;

            @include min-screen($screen__m) {
                max-width: 25.2rem;
            }
        }
    }

    .#{$css-namespace}cms-block-50-50 {
        @include min-screen($screen__m) {
            height: 32rem;

            &__half__wrapper {
                width: 100%;
                max-width: 45.8rem;
            }

            &__action {
                max-width: fit-content;
            }

            .#{$css-namespace}btn {
                // TODO : Refacto needed uniformisation
                padding: 1rem $space-3;
            }
        }

        &--media {
            &[data-image='points-selection'] {
                background: url('../Descours_UserAccount/images/cms/points-selection.png') no-repeat center / contain;
            }
        }
    }
}

.#{$css-namespace}loyalty-cms-seo {
    max-width: calc(#{$loyalty-content-max-width} + 4rem);
    margin: 2.1rem auto;
    padding: 0 2rem;

    &__notice {
        font-size: $font-size-xs;
        color: $color-grey-dark2;
        margin-bottom: 2.9rem;
    }

    .#{$css-namespace}cms-seo-block {
        padding: 0;

        &_title {
            line-height: 2.1rem;
        }

        &_content {
            line-height: 1.9rem;
        }
    }
}
