.muted {
    color: $color-gray-light3;
}

.legal-condition {
    @extend .small, .muted;
}

.h1 {
    @extend h1;
}

.h2 {
    @extend h2;
}

.h3 {
    @extend h3;
}

.h4 {
    @extend h4;
}

.title {
    display: block;
    font-weight: $font-weight__bold;

    &--small {
        font-size: $font-size__base;
        font-weight: $font-weight__bold;
    }

    &--medium {
        font-size: $font-size__baseplus;
        margin-bottom: $indent__s;
    }

    &--primary {
        color: $color-sub-secondary;
    }
}

.text {
    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}

.nowrap {
    white-space: nowrap;
}

.link,
a.link {
    color: $color-blue1;

    .icon {
        width: 23px;
        height: 23px;
        margin-right: 3px;
        vertical-align: bottom;
    }
}

.smaller {
    font-size: $font-size__xxs;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
    background-size: contain !important;
    position: relative;
    margin-right: 6px;

    &-store {
        @include lib-background-image('svg/point-vente.svg', 50% 50%, no-repeat, transparent, false);
    }

    &-delivery {
        @include lib-background-image('svg/livraison.svg', 50% 50%, no-repeat, transparent, false);
    }
}

.link,
a.link {
    color: $color-secondary;
    text-decoration: underline;

    .icon {
        width: 23px;
        height: 23px;
        margin-right: 3px;
        vertical-align: bottom;
    }

    &:hover {
        text-decoration: none;
    }

    &-danger {
        color: $colorDanger;
    }

    + .link {
        margin-left: $indent__xs;
    }
}

.#{$css-namespace}step {
    &_wrapper {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 0;
        height: 2.5rem;
    }

    &_number {
        border-radius: 50%;
        border: 0.1rem solid $color-disabled-text;
        padding: 0.3rem $space-1;
        margin-right: 1.5rem;
        font-size: $font-size-default;
        font-weight: $font-weight-bold;
    }

    &_title {
        color: $color-primary;
        font-size: $font-size-l;
        height: 2.5rem;
        line-height: 3rem;
        font-weight: $font-weight-medium;
    }
}
