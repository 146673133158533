@import '../../../../Descours_v2/Hydralians/styles/toolkit/config/config';
//
//  Home blocks styles
//  _____________________________________________

@import 'module/utils/mixins';
@import 'module/utils/container';
@import 'module/utils/text';

@include apply-media-queries();
@import 'module/home/brands';
@import 'module/home/catalog-events';
@import 'module/home/popular-categories';
@import 'module/home/login-banner';
@import 'module/home/current_offers';
@import 'module/home/our-jobs';
@import 'module/home/event-showcase';
@import 'module/home/current_products';
@import 'module/reinsurance/footer';
@import 'module/contact/contact';
@import 'module/cms/page';
@import 'module/preheader/preheader';
@import 'module/header/simplify-header';
@import 'module/filters';
@import 'module/modals/choose-store'; //TODO: remove after modal rework

//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

//  Header
$header__background-color: false !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: $primary__color !default;

$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$button__shadow: inset 0 0.2rem 0.1rem rgba($color-black, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

//  Footer
$footer__background-color: false !default;

body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.logo {
    display: flex;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.cms-index-index {
    .column.main {
        padding: 0;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.page.messages {
    position: relative;
    z-index: 1;
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

.all-brands-url,
.all-url {
    .icon-fleche {
        color: $color-blue3;
        margin-left: $indent__s;
        vertical-align: middle;
    }

    span {
        &:not(.icon-fleche) {
            font-weight: $font-weight__bold;
            @include lib-font-size(15);
        }
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.error a {
    color: inherit;
}

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Logo
//  ---------------------------------------------

@include screen($screen__xs, $screen__l) {
    .header {
        &.content {
            .logo-wrapper {
                margin-left: 7.5rem;
                @include lib-vendor-prefix-flex-basis(18rem);

                .logo {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .header {
        &.content {
            .logo-wrapper {
                margin-left: 6rem;
                flex: 1 1 8rem;

                .logo {
                    img {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .header {
        &.content {
            .logo {
                margin-bottom: 0;
            }

            .baseline {
                display: none;
            }
        }
    }

    .all-brands-url,
    .all-url {
        order: 2;
    }
}

@include min-screen($screen__l) {
    body.cms-index-index {
        .page-main {
            max-width: 100%;
        }
    }

    .all-brands-url,
    .all-url {
        position: absolute;
        top: 0;
        right: 0;
    }

    .header {
        &.content {
            .logo-wrapper {
                @include lib-vendor-prefix-order(1);
                @include lib-vendor-prefix-flex-basis(22rem);
                @include lib-vendor-prefix-flex-shrink(0);
                @include lib-vendor-prefix-flex-grow(0);
                margin: -0.6rem 0 0 0;
                position: relative;
            }

            .baseline {
                display: inline-block;
                position: relative;
                top: -1.8rem;
                z-index: 2;
                margin-left: 5.8rem;

                @include lib-typography(
                    $_font-size: 1rem,
                    $_color: #303030,
                    // TODO : use $color-gray19 var instead of hexa
                    $_font-family: $font-family__base,
                    $_font-weight: $font-weight__bold,
                    $_line-height: $line-height__base,
                    $_font-style: $font-style__base
                );
                text-transform: uppercase;
            }
        }
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        margin-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-top: 0;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 $space-1;
            }
        }

        .switcher-store {
            margin: 0 0 3rem;
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .payment.secure {
        .text {
            &::before {
                content: '';
                @include lib-background-image('paiement-securise.svg', center, no-repeat, transparent, false);
                vertical-align: middle;
                display: inline-block;
                margin-left: -$space-1 / 2;
            }

            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5rem;
        }

        .cards {
            display: inline-block;
            vertical-align: middle;

            ul {
                li {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 0.5rem;
                }
            }
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 2rem 0 0;
    }
}

@include max-screen($screen__l) {
    .payment.secure {
        .text {
            &::before {
                width: 2.5rem;
                height: 2.5rem;
                background-size: 4rem 4rem !important;
            }
        }
    }

    .cards {
        ul {
            li {
                width: 2.5rem;
            }
        }
    }
}

@include min-screen($screen__l) {
    .payment.secure {
        .text {
            &::before {
                width: 3.5rem;
                height: 3.5rem;
                background-size: 7rem 7rem !important;
            }
        }
    }
    .cards {
        ul {
            li {
                width: 3.9rem;
            }
        }
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 1rem;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 2.2rem,
                $_icon-font-line-height: 2.2rem,
                $_dropdown-list-min-width: 16rem
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: $space-1;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

@include max-screen($screen__l) {
    .desktop-only {
        display: none;
    }
}

@include min-screen($screen__l) {
    .mobile-only {
        display: none;
    }
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________
.page-layout-checkout,
.checkout-index-index {
    .page-wrapper {
        padding-top: 0 !important;
    }
}

.customer-account-login {
    .input-password-container {
        .icons-container {
            color: $color-blue-dark !important;

            &.show-password .dcfonts-icons--eye-slash {
                font-size: 2.8rem;
                position: relative;
                right: -0.2rem;
            }
        }

        .action.remind {
            color: $color-secondary !important;
            text-decoration: underline !important;
        }
    }
}

@include max-screen($screen__l) {
    .useropeningrequest-confirm-index {
        .page-wrapper {
            padding-top: 14.5rem;
        }
    }
}

body {
    overflow-x: hidden;

    &.noScroll {
        overflow: hidden;
    }
}

@include min-screen($screen__m) {
    body {
        &[data-js-menu='nav-open'],
        &.js-search {
            overflow: hidden;
        }
    }

    .useropeningrequest-confirm-index {
        .page-wrapper {
            padding-top: 28rem;
        }
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }
    }

    .header {
        &.panel {
            > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 1.5rem;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                @include lib-typography(
                    $_color: $mainFontColor,
                    $_font-family: $subFont,
                    $_font-size: 2rem,
                    $_font-style: false,
                    $_font-weight: $font-weight__bold,
                    $_line-height: 2rem
                );
                margin: 0 0 $space-3;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }
}

.breadcrumbs {
    @media (max-width: $screen__l) {
        display: none;
    }
}

//
//  HP accompany_activity block
//  ---------------------------------------------
.icon {
    color: #00a3cf; // TODO: Remove as it seems useless
    fill: #00a3cf; // TODO: Remove as it seems useless
}

//
//  HP reinsurance block
//  ---------------------------------------------
.reinsurance-container {
    > ul {
        @include min-screen($screen-md) {
            grid-template-columns: repeat(15, 1fr);
        }
    }
}

// ------------------------------------------------
// ------------------------------------------------
// Specific to Hydralians below
// ------------------------------------------------
// ------------------------------------------------

//
//  Checkout page
//  ---------------------------------------------
.cart-wrapper {
    &[data-delivery-mode='pickup'] {
        .cart-wrapper {
            &_header {
                &_icon:before {
                    content: $pick-up-half-day;
                }
            }
        }
    }
}
