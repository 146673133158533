$radio-form-group-card-padding-x: $space-2 !default;
$radio-form-group-card-padding-y: $space-3 !default;
$radio-form-group-card-button-width: 18.8rem !default;
$radio-form-group-card-extra-text-right-width: 7rem !default;
$radio-form-group-card-chip-size: $space-2 !default;

.#{$css-namespace}form-radio-group {
    .#{$css-namespace}form-radio-group-inner {
        display: flex;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        margin: 0;

        &:checked:not(:disabled) ~ label {
            &::before {
                transform: translateY(-50%) scale(0.5);
            }

            &::after {
                border-color: $color-primary;
                background-color: $color-primary;
            }
        }

        &[readonly] ~ label,
        &:disabled ~ label {
            &,
            &::after {
                cursor: not-allowed;
            }

            &::after {
                background-color: $color-disabled;
                border-color: $color-disabled;
            }
        }
    }

    label {
        position: relative;
        padding-left: $radio-form-group-card-chip-size + $space-2;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 3rem;

        &::before,
        &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            display: block;
            width: $radio-form-group-card-chip-size;
            height: $radio-form-group-card-chip-size;
            border: 0.1rem solid $color-disabled;
            border-radius: 50%;
            cursor: pointer;
            margin-top: -0.1rem;
            background-color: $color-white;
            transition: all var(--transition-normal);
        }

        &::before {
            transform-origin: center;
            transform: translateY(-50%) scale(0);
            z-index: 2;
            background-color: $color-white;
        }

        @media screen and (min-width: $screen-sm) {
            margin-bottom: auto;
        }

        &.hide-input {
            &::before,
            &::after {
                visibility: hidden;
            }
        }
    }

    &[data-ui-form-status='inline'] {
        display: block;

        .#{$css-namespace}form-field-wrapper {
            margin-right: 3rem;
        }

        @media screen and (min-width: $screen-sm) {
            display: flex;
        }
    }

    &[data-ui-skin='button'] {
        position: relative;
        margin-bottom: 1rem;

        input {
            position: absolute;
            width: 0.1rem;
            height: 0.1rem;
            top: 0;
            left: 0;
            visibility: hidden;
            margin: 0;

            &:checked ~ label {
                border-color: $color-primary;
                background-color: $color-primary;
                color: $color-white;
            }
        }

        label {
            width: 100%;
            cursor: pointer;
            min-height: 3rem;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 1;
            padding: 0.5rem 1rem;
            border-radius: 1.75rem;
            overflow: hidden;
            border: solid 0.1rem $color-blue-background-1;
            background-color: $color-blue-background-1;
            color: $color-primary;

            &::before,
            &::after {
                display: none;
            }

            &:hover {
                border-color: $color-primary;
            }
        }
    }

    &--with-background {
        .#{$css-namespace}form-field-wrapper:not(:last-child) & {
            margin-bottom: 1.5rem;
        }

        .control {
            position: relative;
            display: flex;
        }

        input {
            position: absolute;
            margin: 0;
            width: 0.1rem;
            height: 0.1rem;
            top: 0;
            left: 0;
            opacity: 0;

            &:checked {
                + label {
                    background: $color-primary;

                    .chip {
                        border-color: $color-white;
                        background-color: $color-white;

                        &::after {
                            transform: translate(-50%, -50%) scale(1);
                        }
                    }

                    * {
                        color: $color-white;
                    }
                }
            }

            &:disabled + label {
                cursor: not-allowed;

                .chip {
                    background-color: $color-disabled;
                }
            }
        }

        label {
            @extend .rounded-3;
            padding: $space-2 $space-3;
            display: flex;
            align-items: center;
            background: $color-grey-background;
            transition: all var(--transition-normal);
            flex: 1;
            cursor: pointer;
            text-align: left;
            line-height: 2rem;
        }

        .chip {
            min-width: $radio-form-group-card-chip-size;
            width: $radio-form-group-card-chip-size;
            height: $radio-form-group-card-chip-size;
            margin-right: $space-2;
            border: 0.1rem solid $color-disabled;
            background-color: $color-white;
            border-radius: 100%;
            transition: all var(--transition-normal);
            position: relative;

            &::after {
                content: '';
                width: 50%;
                height: 50%;
                background-color: $color-primary;
                position: absolute;
                top: 50%;
                left: 50%;
                border-radius: 100%;
                transform: translate(-50%, -50%) scale(0);
                transform-origin: center;
                transition: all var(--transition-normal);
            }
        }

        .text {
            color: inherit;
            font-weight: $font-weight-bold;
            transition: all 0.25s; // TODO : Uniformise using var(--transition-normal)
        }

        .description {
            margin-top: $indent__xs;
            display: block;
            line-height: 1.357;
        }

        &[data-ui-radio-group-skin='primary'] {
            background-color: $color-blue-background-1;
            border: solid 0.1rem $color-blue-background-1;

            input[type='radio'] {
                &:checked {
                    + label {
                        background-color: transparent;

                        .chip {
                            border-color: $color-primary;
                            background: $color-primary;

                            &::after {
                                background-color: $color-white;
                            }
                        }

                        .selected-text {
                            &-primary {
                                @extend .text-primary;
                            }

                            &-secondary {
                                @extend .text-secondary;
                            }
                        }

                        * {
                            color: $color-main-dark;
                        }
                    }
                }
            }

            label {
                color: $color-main-dark;

                span:not(.chip):not(.text) {
                    color: inherit;
                }
            }
        }

        &[data-ui-skin='card'] {
            border-radius: 0.3rem;
            overflow: hidden;
            transition: box-shadow var(--transition-normal);

            .modal-shipping & {
                margin-top: $space-1;
            }

            &:not(.selected):hover {
                box-shadow: var(--box-shadow);
            }

            &:has(input[type='radio']:checked) {
                border: 0.1rem solid $color-primary;
            }

            .control {
                padding: $radio-form-group-card-padding-y $radio-form-group-card-padding-x;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                position: relative;
                row-gap: $space-2;
                justify-content: space-between;

                @include min-screen($screen-md) {
                    min-height: 12.2rem;
                    column-gap: $space-3;
                }
            }

            input[type='radio'] {
                width: 100%;
                height: 100%;
                z-index: 1;
                cursor: pointer;

                &:checked {
                    ~ button.select {
                        opacity: 1;
                        visibility: visible;

                        &:disabled {
                            cursor: not-allowed;
                            border-color: $color-disabled-light;
                            background-color: $color-white;
                            color: $color-disabled-text;
                            pointer-events: none;
                        }

                        @include max-screen($screen-md) {
                            display: block;
                        }
                    }
                }
            }

            label {
                padding: 0;
                width: 100%;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    content: '';
                }

                @include min-screen($screen-md) {
                    max-width: calc(100% - #{$radio-form-group-card-button-width});
                    width: auto;
                }

                .extra-text-right {
                    position: absolute;
                    top: $radio-form-group-card-padding-y;
                    right: $radio-form-group-card-padding-x;
                    max-width: $radio-form-group-card-extra-text-right-width;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    z-index: 1;

                    &.width-auto {
                        max-width: none;
                    }
                }

                .text {
                    display: block;
                }

                [data-ui-title-level='3'] {
                    text-transform: uppercase;
                }

                .#{$css-namespace}btn {
                    // TODO : Refacto needed
                    font-size: $font-size-xs-l;
                    font-weight: $font-weight-regular;
                    padding: 0.3rem 1rem;
                    background: transparent !important;
                    pointer-events: none;
                    text-align: center;
                    min-height: 3rem;
                    border-radius: 3rem;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }
            }

            button {
                font-weight: $font-weight-medium;
                position: relative;
                z-index: 2;
                width: 100%;
                min-width: $radio-form-group-card-button-width;

                @include min-screen($screen-md) {
                    width: auto;
                }

                &.action {
                    opacity: 0;
                    visibility: hidden;
                    transition:
                        opacity var(--transition-normal),
                        visibility var(--transition-normal);

                    @include max-screen($screen-md) {
                        margin-bottom: 0;
                        display: none;
                    }
                }
            }

            &.selected {
                border-color: $color-primary;

                label {
                    cursor: text;
                }

                .control > input[type='radio'] {
                    pointer-events: none;
                }

                button.action {
                    visibility: visible;
                    opacity: 1;

                    @include max-screen($screen-md) {
                        display: block;
                    }
                }
            }

            &[data-ui-button-position='bottom'] {
                .control {
                    flex-direction: column;
                    align-items: normal;
                }

                label {
                    max-width: none;
                }

                &:not(.selected) {
                    button.action {
                        display: none;
                    }
                }
            }
        }
    }

    &-warning {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        padding: 1rem;
        background-color: rgba($color-red-promo, 0.15);

        &-content {
            flex: 1;
            color: $color-black;
            line-height: 1.9rem;
        }

        .dcfonts-icons {
            color: $color-red-promo;
            display: inline-block;
            vertical-align: middle;
            margin-right: $space-2;
            font-size: $font-size-l;
        }
    }
}
