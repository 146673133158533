// Light tooltip

/**
 * @deprecated Use or extend x-tooltip instead
 */
%light-tooltip {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    color: $color-black;

    > span {
        text-align: left;
        font-style: normal;
        line-height: 1.5;
        background: $color-white;
        background-clip: padding-box;
        border: 0.1rem solid $color-primary;
        position: absolute;
        width: 29rem;
        margin-left: 0;
        padding: $indent__s;
        visibility: hidden;
        opacity: 0;
        font-size: $font-size-s;
        font-weight: $font-weight-regular;
        min-height: 5.3rem;
        box-shadow: 0 0.4rem 1rem 0 rgba($color-black, 0.1); // TODO: Use box-shadow var
        cursor: default;
        z-index: 1;

        @include max-screen($screen__l) {
            width: 26rem;
        }

        &::after {
            content: "";
            position: absolute;
            z-index: 1;
            border: 0.1rem solid $color-primary;
            width: 2rem;
            height: 2rem;
            background: $color-white;
            border-left: none;
            border-bottom: none;
        }
    }

    &.active,
    &.active.hide:hover,
    &:not(.hide):hover {
        > span {
            visibility: visible;
            opacity: 1;
            min-height: fit-content;
        }

        &.#{$css-namespace}tooltip {
            @include max-screen($screen__l) {
                &--top,
                &--left {
                    > span {
                        left: auto;
                    }
                }

                &--top {
                    > span {
                        right: -1.9rem;
                    }
                }
            }
        }
    }

    // START Behaviour when we come from a mobile version
    &.active.hide {
        > span {
            @include min-screen($screen__l) {
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    &.hide:hover {
        > span {
            @include min-screen($screen__l) {
                visibility: visible;
                opacity: 1;
                min-height: fit-content;
            }
        }
    }

    // END Behaviour when we come from a mobile version

    .tooltip-close {
        float: right;
        position: relative;
        z-index: 2;

        @include min-screen($screen__l) {
            display: none;
        }

        .dcfonts-icons {
            color: $color-primary;
            font-size: $font-size-s;
            cursor: pointer;
        }
    }
}

/**
 * @deprecated Use or extend x-tooltip instead
 */
.tooltip-mobile {
    @include min-screen($screen__l) {
        display: none;
    }
}

/**
 * @deprecated Use or extend x-tooltip instead
 */
.tooltip-desktop {
    @include max-screen($screen__l) {
        display: none;
    }
}

/**
 * @deprecated Use or extend x-tooltip instead
 */
.#{$css-namespace}tooltip {
    &--light {
        @extend %light-tooltip;
    }

    &--top {
        > span {
            bottom: 100%;
            margin-bottom: 1.5rem;
            left: -1.9rem;

            @include max-screen($screen__m) {
                left: inherit;
                right: -1.9rem;
            }

            &::after {
                transform: translateY(50%) rotate(135deg);
                bottom: -0.1rem;

                @include min-screen($screen__l) {
                    left: 1.5rem;
                }
                @include max-screen($screen__l) {
                    right: 1.5rem;
                }
            }
        }
    }

    &--bottom {
        > span {
            top: 100%;
            margin-top: $space-2;
            left: -1.9rem;

            &::after {
                transform: translateY(-50%) rotate(-45deg);
                left: 1.5rem;
                top: -0.1rem;
            }
        }
    }

    &--right {
        > span {
            left: 100%;
            margin-left: $space-2;
            top: -1.9rem;

            &::after {
                transform: translateX(-50%) rotate(225deg);
                top: 1.5rem;
                left: -0.1rem;
                z-index: -1;
            }
        }
    }

    &--left {
        > span {
            right: 100%;
            margin-right: $space-2;
            top: -1.9rem;

            &::after {
                transform: translateX(50%) rotate(45deg);
                top: 1.5rem;
                right: -0.1rem;
            }
        }
    }

    // Works with left and right only
    &--thin {
        > span {
            top: 50%;
            transform: translateY(-50%);
            min-height: 3.7rem;
            width: auto;
            white-space: nowrap;

            &::after {
                width: 1.6rem;
                height: 1.6rem;
                top: 50%;
                transform: translate(-50%, -50%) rotate(225deg);
            }
        }
    }

    &--desktop-only {
        > span {
            @include max-screen($screen__l) {
                display: none;
            }
        }
    }

    .tooltip-text {
        z-index: 30;
        padding-right: 1rem;
    }
}


$sqrt-2: 1.4142;

/**
 * Tooltip
 */
$tooltip-arrow-size: 1.6rem; // Controls the size of the arrow before it is rotated
$tooltip-arrow-offset: 1.6rem; // Controls the space between one corner of the content box and where the arrow starts
$tooltip-arrow-spacing: 0.4rem; // Between content and tip of arrow
$tooltip-arrow-half-diagonal: 0.5 * $sqrt-2 * $tooltip-arrow-size;
$tooltip-padding: $space-2;
$tooltip-shadow: var(--drop-shadow);

/**
 * Positions the tooltip content box relatively to its containing link depending
 * on a given position (top|right|bottom|left)
 */
@mixin tooltip-content-layout($primary-axis, $secondary-axis) {

    $is-primary-vertical: $primary-axis == top or $primary-axis == bottom;

    // The adjustment to apply to the rotated arrow so it starts flush with the side of the box
    $arrow-delta: $tooltip-arrow-half-diagonal - 0.5 * $tooltip-arrow-size + $tooltip-arrow-offset;

    /**
     * The tooltip content box
     */
    $spacing-between-content-arrow-tip-and-host: $tooltip-arrow-half-diagonal + $tooltip-arrow-spacing;

    @if $primary-axis == top {
        bottom: 100%;
        margin-bottom: $spacing-between-content-arrow-tip-and-host;
    } @else if $primary-axis == bottom {
        top: 100%;
        margin-top: $spacing-between-content-arrow-tip-and-host;
    } @else if $primary-axis == right {
        left: 100%;
        margin-left: $spacing-between-content-arrow-tip-and-host;
    } @else if $primary-axis == left {
        right: 100%;
        margin-right: $spacing-between-content-arrow-tip-and-host;
    }

    @if $secondary-axis == top {
        margin-bottom: -$arrow-delta;
        bottom: 0;
    } @else if $secondary-axis == bottom {
        margin-top: -$arrow-delta;
        top: 0;
    } @else if $secondary-axis == right {
        right: 0;
        margin-right: -$arrow-delta;
    } @else if $secondary-axis == left {
        left: 0;
        margin-left: -$arrow-delta;
    }

    &::after {
        transform: rotate(45deg);
        box-shadow: $tooltip-shadow;

        // Move arrow box by half its size on main axis before applying 45deg rotation
        @if $primary-axis == top {
            bottom: -0.5 * $tooltip-arrow-size;
        } @else if $primary-axis == bottom {
            top: -0.5 * $tooltip-arrow-size;
        } @else if $primary-axis == right {
            left: -0.5 * $tooltip-arrow-size;
        } @else if $primary-axis == left {
            right: -0.5 * $tooltip-arrow-size;
        }

        @if $is-primary-vertical {
            @if $secondary-axis == right {
                right: $arrow-delta;
                margin-right: 0;
            } @else if $secondary-axis == left {
                left: $arrow-delta;
                margin-left: 0;
            }
        } @else {
            @if $secondary-axis == top {
                bottom: $arrow-delta;
                margin-bottom: 0;
            } @else if $secondary-axis == bottom {
                top: $arrow-delta;
                margin-top: 0;
            }
        }
    }

    /**
     * :before pseudo-element to ensure hover continuity between link and tooltip content
     */
    &::before {
        @if $primary-axis == top {
            top: 100%;
        } @else if $primary-axis == bottom {
            bottom: 100%;
        } @else if $primary-axis == right {
            right: 100%;
        } @else if $primary-axis == left {
            left: 100%;
        }

        @if $is-primary-vertical {
            left: 0;
            right: 0;
            height: $tooltip-arrow-half-diagonal + $tooltip-arrow-spacing;
        } @else {
            top: 0;
            bottom: 0;
            width: $tooltip-arrow-half-diagonal + $tooltip-arrow-spacing;
        }
    }
}

/**
 * The concrete tooltip base styles, without positioning
 */
[data-ui="tooltip"] {
    cursor: pointer;
    position: relative;
}


[data-role="tooltip-box"] {
    cursor: default;
    position: absolute;
    padding: $tooltip-padding;
    background-color: $color-white;
    min-width: 20rem;
    max-width: 28rem;
    border-radius: 0.4rem;
    z-index: var(--tooltip-zindex);
    box-shadow: $tooltip-shadow;
    text-wrap: initial !important;
    box-sizing: border-box;
    text-align: left;

    ._has-modal & {
        z-index: var(--tooltip-zindex);
    }

    @supports (filter: drop-shadow(0 0)) {
        box-shadow: none;
        filter: drop-shadow($tooltip-shadow);
    }

    [data-ui="tooltip"] & {
        visibility: hidden;
    }

    &::after {
        content: "";
        position: absolute;
        width: $tooltip-arrow-size;
        height: $tooltip-arrow-size;
        background-color: $color-white;
        border-bottom-left-radius: 0.4rem;

        @supports (filter: drop-shadow(0 0)) {
            box-shadow: none !important;
        }
    }

    &::before {
        content: "";
        position: absolute;
    }

    @each $position-x in (left, right) {
        @each $position-y in (top, bottom) {

            &.#{$position-x}-#{$position-y} {
                @include tooltip-content-layout($position-x, $position-y);
            }

            &.#{$position-y}-#{$position-x} {
                @include tooltip-content-layout($position-y, $position-x);
            }
        }
    }
}
