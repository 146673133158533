$box-info-spacing: 2rem;
$status-border: 0.2rem;
$status-width-lg: 15.6rem;
$box-info-image-height-xs: 10.7rem;
$box-info-image-height-lg: 16rem;
$box-content-title-margin-bottom-xs: 1.5rem;
$box-content-title-margin-bottom-lg: 2rem;

@mixin dashboard-text-xs {
    font-size: $font-size-s;
    line-height: 1.7rem;
}

@mixin dashboard-text-lg {
    font-size: $font-size-default;
    line-height: 1.9rem;
}

@mixin block-half-size() {
    width: calc(50% - (#{$box-info-spacing} / 2));
}

.account.customer-account-index {
    .#{$css-namespace}box_content_title {
        margin: 0;
    }

    // DASHBOARD INFO

    .#{$css-namespace}dashboard_info {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $space-3;

        .#{$css-namespace}loyalty {
            &[data-step='validated'] {
                .#{$css-namespace}loyalty_header {
                    margin-bottom: 1.5rem;
                    @include min-screen($screen__m) {
                        margin-bottom: 2rem;
                    }
                }
            }

            &[data-step='pending'] {
                .#{$css-namespace}loyalty_header {
                    margin-bottom: 4.5rem;
                }
            }
        }
    }

    // DASHBOARD INFO : CUSTOM BLOCKS

    .#{$css-namespace}dashboard_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include min-screen($screen__m) {
            flex-direction: row;

            &.same-height {
                align-items: stretch;

                .#{$css-namespace}card[data-ui-action='reassurance'] {
                    display: flex;
                    height: 100%;
                    align-content: stretch;

                    .#{$css-namespace}card-content {
                        align-self: center;
                    }
                }
            }
        }

        &_content {
            display: flex;
            width: 100%;
            flex-direction: column;

            @include min-screen($screen__m) {
                flex-direction: row;
            }

            //DASHBOARD INFO : SIZING BLOCKS
            &--column {
                @include min-screen($screen__m) {
                    flex-basis: 50%;
                    margin-right: $space-3;
                    flex-direction: column;
                }

                .#{$css-namespace}box_content_custom {
                    width: 100%;

                    &:first-child {
                        margin: 0 0 3rem 0 !important;
                    }
                }
            }

            .#{$css-namespace}orders_table_item_account {
                .#{$css-namespace}box_content_title {
                    margin-bottom: $box-content-title-margin-bottom-xs;

                    @include min-screen($screen__l) {
                        margin-bottom: $box-content-title-margin-bottom-lg;
                    }
                }
            }

            .#{$css-namespace}box_content_custom {
                background-color: $color-main-light;
                border-radius: 0.3rem;
                box-shadow: 0 0 0.7rem $color-gray-darken2;
                width: 100%;
                display: flex;
                flex-direction: column;

                &:first-child {
                    margin: 0 0 3rem 0;
                }

                @include min-screen($screen__m) {
                    flex-direction: row;

                    &:first-child {
                        margin: 0 $box-info-spacing 0 0;
                    }
                }

                @include min-screen($screen__m) {
                    min-height: 18.1rem;
                }

                .#{$css-namespace}box_content_img {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 100%;
                    height: auto;
                    min-height: $box-info-image-height-xs;

                    @include min-screen($screen__m) {
                        height: 100%;
                        width: $box-info-image-height-lg;
                    }
                }

                .#{$css-namespace}box_content_img-agency {
                    background-image: url('../Descours_UserAccount/images/dashboard/my-agency_sm.png');

                    @include min-screen($screen__s) {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-agency_md.png');
                    }

                    @include min-screen($screen__m) {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-agency_lg.png');
                    }

                    &--hydralians {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-agency--hydralians_sm.png');

                        @include min-screen($screen__s) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-agency--hydralians_md.png');
                        }

                        @include min-screen($screen__m) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-agency--hydralians_lg.png');
                        }
                    }

                    &--dexis {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-agency--dexis_sm.png');

                        @include min-screen($screen__s) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-agency--dexis_md.png');
                        }

                        @include min-screen($screen__m) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-agency--dexis_lg.png');
                        }
                    }
                }

                .#{$css-namespace}box_content_img-information {
                    background-image: url('../Descours_UserAccount/images/dashboard/my-informations_sm.png');

                    @include min-screen($screen__s) {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-informations_md.png');
                    }

                    @include min-screen($screen__m) {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-informations_lg.png');
                    }

                    &--hydralians {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-informations--hydralians_sm.png');

                        @include min-screen($screen__s) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-informations--hydralians_md.png');
                        }

                        @include min-screen($screen__m) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-informations--hydralians_lg.png');
                        }
                    }

                    &--dexis {
                        background-image: url('../Descours_UserAccount/images/dashboard/my-informations--dexis_sm.png');

                        @include min-screen($screen__s) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-informations--dexis_md.png');
                        }

                        @include min-screen($screen__m) {
                            background-image: url('../Descours_UserAccount/images/dashboard/my-informations--dexis_lg.png');
                        }
                    }
                }

                .#{$css-namespace}box_content {
                    padding: 1.5rem 1.5rem 2rem 1.5rem;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: calc(100% - #{$box-info-image-height-xs});

                    @include min-screen($screen__m) {
                        width: calc(100% - #{$box-info-image-height-lg});
                        height: 100%;
                    }

                    &_subtitle {
                        font-size: $font-size-xl;
                        color: $color-secondary;
                        font-family: $font-secondary;
                        font-weight: $font-weight__bold;
                        margin-bottom: 1rem;
                        margin-top: 0.2rem;

                        @include min-screen($screen__m) {
                            margin: 0;
                        }
                    }

                    &_text {
                        @include dashboard-text-lg();
                        margin: 0;

                        &_mobile-phone {
                            @include min-screen($screen__m) {
                                display: none;
                            }
                        }

                        &_desktop-phone {
                            @include max-screen($screen__m) {
                                display: none;
                            }
                        }
                    }

                    &_text-spacing {
                        margin: 0.5rem 0 0;
                    }

                    &_information {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: calc(
                            100% - (#{$box-content-title-line-height} + #{$box-content-title-margin-bottom-xs}) + 1.5rem *
                                2
                        );

                        @include min-screen($screen__m) {
                            height: calc(
                                100% - (#{$box-content-title-line-height} + #{$box-content-title-margin-bottom-lg}) +
                                    1.5rem * 2
                            );
                        }

                        &_text {
                            font-size: $font-size-default;
                            line-height: 2.2rem;
                            margin-bottom: 0.3rem;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        &_text-label {
                            color: $color-primary;
                            font-weight: $font-weight__semibold;
                        }
                    }
                }

                .#{$css-namespace}agency-card-schedules {
                    margin-bottom: 0.9rem;
                    line-height: 1.5rem;
                    font-size: $font-size-xs;
                    min-width: 0;

                    @include min-screen($screen__m) {
                        font-size: $font-size-s;
                        line-height: 1.4rem;
                        margin-bottom: 1.5rem;
                    }

                    .note {
                        margin: 0;
                    }
                }
            }
        }

        .block-dashboard-loyalty {
            margin: $indent__l 0 0;

            @include min-screen($screen__m) {
                margin: 0;
            }

            &.--is-prospect {
                margin-top: 0;
            }
        }

        .#{$css-namespace}card[data-ui-action='reassurance'] {
            align-self: flex-start;
            margin-left: auto;
            margin-right: auto;
            margin-top: 3rem;

            .#{$css-namespace}card-content {
                ul,
                ol {
                    li {
                        padding-left: 0;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                > p:last-child {
                    max-width: 25rem;
                    margin-left: auto;
                    margin-right: auto;
                    line-height: 1.9;
                }
            }

            @include min-screen($screen__m) {
                max-width: 50%;
                margin-top: 0;
                margin-left: 1rem;
                margin-bottom: 0;

                .#{$css-namespace}card-wrapper {
                    padding: $box-info-spacing;
                }
            }
        }
    }

    .#{$css-namespace}loyalty {
        min-height: 36rem;
        background-image: url('../Descours_UserAccount/images/dashboard/loyalty-program-dashboard-bottom_xs.svg');
        background-size: contain;

        @include min-screen($screen__s) {
            min-height: auto;
            background-image: url('../Descours_UserAccount/images/dashboard/loyalty-program-dashboard-bottom_lg.svg');
        }

        &::before {
            content: '';
            position: absolute;
            height: 10rem;
            background-color: $color-loyalty-shape-background;
            left: -10rem;
            right: 0;
            top: 0;
            transform: translate(0, -98%) rotate(-7deg);
            transform-origin: bottom right;
        }

        &[data-step='subscribe'] {
            .#{$css-namespace}loyalty_content {
                @include min-screen($screen__m) {
                    margin-top: auto;
                    margin-bottom: 0;
                }
            }

            .#{$css-namespace}loyalty_button {
                &,
                button {
                    width: 100%;
                }

                button {
                    justify-content: center;
                }
            }
        }

        &-conditions {
            @include max-screen($screen__m) {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }

    // DASHBOARD ORDERS

    .#{$css-namespace}dashboard_orders {
        .order {
            display: flex;
            flex-direction: column;
            padding: 0 1.5rem 1.5rem;

            @include min-screen($screen__l) {
                justify-content: space-between;
                padding: 0 0 2rem;
                flex-direction: row;
            }
        }

        .block-content {
            background-color: $color-main-light;
            border-radius: 0.3rem;
            box-shadow: var(--box-shadow);
            padding: 1.5rem 1rem $space-2 1rem;

            @include min-screen($screen__l) {
                padding: 2rem 0;
            }

            .#{$css-namespace}orders_table {
                padding-bottom: 0;

                &_block {
                    &:last-child {
                        border-bottom: none;
                    }

                    @include max-screen($screen__l) {
                        padding: 1.5rem 0 1.5rem 0;
                        border-bottom: 0.1rem solid $color-disabled-light;

                        &:nth-child(2) {
                            padding: 0 0 1.5rem 0;
                        }

                        &:last-child {
                            padding: 1.5rem 0 0 0;
                        }
                    }
                }
            }

            // DASHBOARD ORDERS : SIZING BLOCKS

            @include min-screen($screen__l) {
                .#{$css-namespace}orders_table_item {
                    &_number,
                    &_sender,
                    &_total,
                    &_actions {
                        margin-top: 0.6rem;
                    }
                }

                .#{$css-namespace}orders_table_number,
                .#{$css-namespace}orders_table_item_number {
                    width: 28%;
                }

                .#{$css-namespace}orders_table_sender,
                .#{$css-namespace}orders_table_item_sender {
                    width: 20%;
                }

                .#{$css-namespace}orders_table_status,
                .#{$css-namespace}orders_table_item_status {
                    width: 25%;
                }

                .#{$css-namespace}orders_table_total,
                .#{$css-namespace}orders_table_item_total {
                    width: 15%;
                }

                .#{$css-namespace}orders_table_actions,
                .#{$css-namespace}orders_table_item_actions {
                    width: 12%;
                    margin-top: 0;
                }
            }
        }
    }
}
