$checkbox-form-group-gap: $space-2 !default;
$checkbox-form-group-checkbox-size: $size-default * 2 !default;

.field-checkbox {
    .control {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        position: relative;
        flex: 1;

        [type='checkbox'] {
            position: absolute;
            left: 0;
            opacity: 0.01;

            + label {
                position: relative;
                padding-left: $checkbox-form-group-checkbox-size + $checkbox-form-group-checkbox-size;
                cursor: pointer;
                flex: 1;

                &::before {
                    font-family: $font-icons;
                    content: $check;
                    position: absolute;
                    left: 0;
                    // This is intended to center the checkbox with the text due to the text line height at the top
                    // of the text.
                    top: 0.1rem;
                    font-size: 0;
                    border-radius: 0.2rem;
                    border: solid 0.1rem $color-border-grey-light;
                    background-color: $color-grey-background;
                    transition: all var(--transition-normal);
                    text-align: center;
                    width: $checkbox-form-group-checkbox-size;
                    height: $checkbox-form-group-checkbox-size;
                    line-height: $checkbox-form-group-checkbox-size - 0.2rem;
                    color: $color-white-smoke;
                }

                a {
                    text-decoration: underline;
                    font-weight: $font-weight-bold;
                }
            }

            &:checked + label {
                &::before {
                    border-color: $color-primary;
                    background-color: $color-primary;
                    font-size: $font-size-xs;
                }

                &::after {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }

            &:disabled + label {
                cursor: not-allowed;
                color: $color-disabled;

                &::before {
                    background-color: $color-disabled-light;
                    color: $color-disabled;
                }
            }

            // Accessibility
            &:focus + label::before {
                box-shadow: var(--box-shadow);
            }
        }

        div.mage-error {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
