//  Tertiary button
$button-tertiary__line-height: false !default;
$button-tertiary__width: false !default;
$button-tertiary__margin: false !default;
$button-tertiary__padding: $button__padding !default;
$button-tertiary__gradient: false !default;
$button-tertiary__gradient-direction: false !default;

$button-tertiary__background: $colorAgency !default;
$button-tertiary__border: 0.1rem solid $colorAgency !default;
$button-tertiary__color: $color-white !default;
$button-tertiary__gradient-color-start: false !default;
$button-tertiary__gradient-color-end: false !default;

$button-tertiary__hover__background: darken($colorAgency, 10) !default;
$button-tertiary__hover__border: 0.1rem solid darken($colorAgency, 10) !default;
$button-tertiary__hover__color: $color-white !default;
$button-tertiary__hover__gradient-color-start: false !default;
$button-tertiary__hover__gradient-color-end: false !default;

$button-tertiary__active__background: darken($colorAgency, 10) !default;
$button-tertiary__active__border: 0.1rem solid darken($colorAgency, 10) !default;
$button-tertiary__active__color: $color-white !default;
$button-tertiary__active__gradient-color-start: false !default;
$button-tertiary__active__gradient-color-end: false !default;

@mixin lib-button-tertiary(
    $_button-line-height: $button-tertiary__line-height,
    $_button-width: $button-tertiary__width,
    $_button-margin: $button-tertiary__margin,
    $_button-padding: $button-tertiary__padding,
    $_button-color: $button-tertiary__color,
    $_button-background: $button-tertiary__background,
    $_button-border: $button-tertiary__border,
    $_button-gradient-color-start: $button-tertiary__gradient-color-start,
    $_button-gradient-color-end: $button-tertiary__gradient-color-end,
    $_button-color-hover: $button-tertiary__hover__color,
    $_button-background-hover: $button-tertiary__hover__background,
    $_button-border-hover: $button-tertiary__hover__border,
    $_button-gradient-color-start-hover: $button-tertiary__hover__gradient-color-start,
    $_button-gradient-color-end-hover: $button-tertiary__hover__gradient-color-end,
    $_button-color-active: $button-tertiary__active__color,
    $_button-background-active: $button-tertiary__active__background,
    $_button-border-active: $button-tertiary__active__border,
    $_button-gradient-color-start-active: $button-tertiary__active__gradient-color-start,
    $_button-gradient-color-end-active: $button-tertiary__active__gradient-color-end,
    $_button-gradient: $button-tertiary__gradient,
    $_button-gradient-direction: $button-tertiary__gradient-direction
) {
    @include lib-button(
        $_button-line-height: $_button-line-height,
        $_button-width: $_button-width,
        $_button-margin: $_button-margin,
        $_button-padding: $_button-padding,
        $_button-color: $_button-color,
        $_button-background: $_button-background,
        $_button-border: $_button-border,
        $_button-color-hover: $_button-color-hover,
        $_button-background-hover: $_button-background-hover,
        $_button-border-hover: $_button-border-hover,
        $_button-color-active: $_button-color-active,
        $_button-background-active: $_button-background-active,
        $_button-border-active: $_button-border-active,
        $_button-gradient: $_button-gradient,
        $_button-gradient-direction: $_button-gradient-direction,
        $_button-gradient-color-start: $_button-gradient-color-start,
        $_button-gradient-color-end: $_button-gradient-color-end,
        $_button-gradient-color-start-hover: $_button-gradient-color-start-hover,
        $_button-gradient-color-end-hover: $_button-gradient-color-end-hover,
        $_button-gradient-color-start-active: $_button-gradient-color-start-active,
        $_button-gradient-color-end-active: $_button-gradient-color-end-active
    );
}

.back--radius {
    border-radius: 0.3rem;
}

a.action.primary {
    @include lib-link-as-button();
    border-radius: 0.3rem;
}

.action.primary {
    @include lib-button-primary();
    border-radius: 0.3rem;
}

a.action.tertiary {
    @include lib-link-as-button();
}

.action.tertiary {
    @include lib-button-tertiary();
}

@include max-screen($screen__s) {
    a.action,
    .action {
        &.primary,
        &.secondary,
        &.tertiary {
            font-size: $font-size__s;
        }
    }
}
