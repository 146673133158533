// Default
.catalogsearch {
    &-refine {
        margin: $indent__base 0 $indent__xs 0;
        text-align: center;
        font-family: $font-default;
        padding: 0 2rem;
        @include lib-font-size(1.4rem);
        @include min-screen($screen__l) {
            margin-top: $indent__s;
        }
    }
}

.catalogsearch-result-index {
    .page-wrapper {
        .header.content {
            background: $color-main-light;
        }
    }

    .sidebar-main {
        .facet {
            background-color: $color-main-light;
        }
    }

    .breadcrumbs,
    .page-main {
        background-color: transparent;
    }

    &.has-related-products,
    &:has(.related-products-container) {
        .column.main {
            min-height: 7rem;
        }

        .search_without_result {
            display: none;
        }
    }

    &.has-no-related-products,
    &:not(:has(.related-products-container)) {
        .search_without_result_with_similar_products {
            display: none;
        }
    }
}

// Search result

.page-title-wrapper {
    .page-title {
        margin: 0;

        .search-result-page-title {
            display: block;
            color: $color-secondary;
            font-size: $font-size-m;
            line-height: $offset-xxl;
            text-align: center;
            margin-top: $offset-xl;
            font-family: $font-default;
            font-weight: $font-weight-bold;

            span {
                display: block;
                color: $color-primary;
                font-size: $font-size-xxxl;
                font-weight: inherit;
                margin-top: $offset-m;
                margin-bottom: $offset-m;
                font-family: $font-secondary;
            }
        }
    }
}

.search_without_result {
    margin: auto;
    max-width: 70rem;

    h2 {
        font-size: $font-size-xl;
        color: $color-secondary;
        margin: $offset-xxl 0;
        text-align: center;
    }

    p {
        margin-bottom: $offset;
    }

    strong {
        font-size: $font-size-m;
    }

    ul {
        margin-bottom: $offset-xl;

        li {
            line-height: 1.9rem;
        }
    }

    a {
        color: $color-secondary;
        text-decoration: underline;
    }

    a:hover {
        color: #006ea6; // TODO: use $color-blue2 var instead of hexa
        text-decoration: underline;
    }

    &_with_similar_products {
        text-align: center;

        a {
            display: inline-block;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}

.#{$css-namespace}category {
    &-container {
        display: flex;

        @media (pointer: coarse) {
            width: max-content;
        }

        @media (pointer: fine) {
            padding: $offset-xs $offset-m 0;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: $indent__xs;
            margin: -($indent__xs) auto;
            max-width: 106rem;
        }

        &-scroll {
            padding: 0 2rem 1rem;

            @media (pointer: fine) {
                display: flex;
            }
        }
    }

    &-badge {
        margin: $indent__xs;
        font-weight: $font-weight__regular;
        border-color: $color-main-light !important;

        &:hover {
            background-color: $color-secondary;
            color: $color-main-light;
        }

        &:first-child {
            margin-left: 0;
        }

        span {
            width: max-content;
        }
    }
}

.suggested-container {
    @include min-screen($screen__m) {
        margin-bottom: 2.5rem;
    }

    .suggested-block {
        margin: 0 auto;

        @include min-screen($screen__m) {
            overflow: hidden;
        }

        .page-title-wrapper {
            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }

        .suggested-container-common {
            overflow: hidden;

            .suggested-scroll-common {
                overflow-x: scroll;
                overflow-y: hidden;
                margin: 0 0 -1.5rem;
                padding: 0 0 1.5rem;
                position: relative;
                height: 5.5rem;
            }
        }

        .suggested-container-category {
            width: 100%;
        }

        .suggested-container-brand {
            .suggested-scroll-brand {
                .suggested-brand {
                    color: $color-black;

                    .suggested-name-brand {
                        color: $color-black;
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .catalogsearch-result-index {
        .page-main {
            .page-title-wrapper {
                .page-title {
                    .search-without-result-title {
                        margin: $offset-xl auto 0 auto;

                        .keyword {
                            margin: $offset-xs 0 0 0;
                        }
                    }
                }
            }
        }

        .search_without_result {
            margin: auto;

            h2 {
                margin: $offset-m * 2 0 $offset-xl 0;
            }
        }
    }
}

//Desktop filters

@include min-screen($screen__l) {
    .facet {
        .facet__container {
            .facet__categories {
                .facet__list {
                    .facet__item.facet__item--parent-categ.facet__item--subcat {
                        background-image: none;

                        .facet__link_categ {
                            border-bottom: 0 none;
                            align-items: normal;
                            margin-left: 0;
                            margin-right: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
