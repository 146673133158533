//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

$screen__xxs : 370px !default;
$screen__xs  : 480px !default;
$screen__s   : 640px !default;
$screen__m   : 768px !default;
$screen__xm  : 992px !default;
$screen__l   : 1200px !default;
$screen__xl  : 1440px !default;
$screen__xxl : 1600px !default;


//
//  Spécifique Descours & Cabaud Front Utilisateur
//  _____________________________________________
$screen__fu  : 1366px !default;
$screen__sl  : 1325px !default;
