$event-showcase-banner-breakpoint: $screen__m;

.event-showcase {
    margin: $space-4 0; // To fit with gap in homepage sliders
}

.event-showcase-top {
    .event-showcase_title {
        display: flex;
    }

    .#{$css-namespace}title-section {
        &--recap {
            padding-right: $indent__l;
            border: none;
        }
    }

    .event-showcase_recap-subtitle {
        display: block;
        margin-top: $indent__s;

        @include min-screen($screen__l) {
            margin-top: -1.5rem;
        }
    }
}

.#{$css-namespace}event-showcase {
    $root: &;

    &_title.#{$css-namespace}title-section {
        grid-column: 1 / 2;
        grid-row: 1;
        width: fit-content;

        @include min-screen($screen__l) {
            grid-column: 1 / 3;
            line-height: 4rem;
        }
    }

    &_header &_more-link-container {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        color: $color-secondary;

        &:hover {
            #{$root}_more-link-text {
                text-decoration: none;
            }
        }

        @include min-screen($screen__l) {
            grid-row: 2;
        }
    }

    &_more-link-text {
        color: $color-secondary;
        text-decoration: underline;
    }

    &_more-link-icon {
        margin-left: 0.5rem;
        font-size: 1.7rem;
    }

    &_description {
        grid-column: 1 / 3;
        grid-row: 2;
        margin-top: 1rem;
        line-height: 1.9rem;

        @include min-screen($screen__l) {
            grid-column: 1 / 2;
            margin-top: 0.5rem;
        }
    }
}

.event-showcase-banner {
    $c: &;

    margin: $indent__s 0 0;
    background-color: $color-primary;
    text-align: center;

    @include min-screen($screen__m) {
        display: flex;
        margin: $indent__l 3rem 0; // quick fix for align carousels arrows with other block-content
        text-align: left;
        min-height: 20.5rem;
    }

    &--no-mobile-image {
        @include max-screen(102.4rem) {
            #{$c}__content {
                flex: 1 1 100%;
            }

            #{$c}__image {
                display: none;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        flex: 0 0 36.2rem;
        padding: $indent__base $indent__l;
        color: $color-white;

        @include min-screen($event-showcase-banner-breakpoint) {
            padding: 3.5rem #{$indent__base * 2.25};
            border-right: 0.3rem solid $color-white;
        }

        &__triangle {
            position: absolute;
            top: 100%;
            right: 50%;
            width: 2.4rem;
            height: 2.4rem;
            transform: translate3d(50%, -50%, 0) rotate3d(0, 0, 1, 45deg);
            background: $color-primary;
            border: 0 solid $color-white;

            @include min-screen($event-showcase-banner-breakpoint) {
                top: 50%;
                right: -0.2rem;
                width: 3.4rem;
                height: 3.4rem;
                border-width: 0.3rem 0.3rem 0 0;
            }
        }
    }

    &__title {
        max-width: 18.5rem;
        margin: auto;
        margin-top: 0;
        @include lib-font-size(1.6rem);
        font-family: $font-secondary;
        font-weight: $font-weight__bold;

        span {
            display: block;
            color: $color-yellow;
            @include lib-font-size(1.2rem);
            line-height: 1.3;
            font-weight: $font-weight__semibold;
            margin-bottom: 0.5rem;
        }

        @include min-screen($event-showcase-banner-breakpoint) {
            max-width: unset;
        }

        @include min-screen($event-showcase-banner-breakpoint) {
            @include lib-font-size(2.4rem);

            span {
                @include lib-font-size(1.6rem);
            }
        }
    }

    &__image {
        position: relative;
        z-index: 1;
        display: none;

        @include min-screen($event-showcase-banner-breakpoint) {
            display: block;
            flex: 1 1 auto;
        }

        figure {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.event-showcase-carousel {
    padding: 0 $indent__s;
    display: flex;
    flex-direction: column;

    @include min-screen($screen__l) {
        padding: 0;

        .products_offer__container {
            padding-top: 0;
            max-width: 100%;
        }
    }

    .related-products-container {
        .product_grid {
            margin: 0 0 $indent__s;
            display: flex;
            width: 100%;
        }
    }

    .#{$css-namespace}product_slot__image_container {
        @include max-screen($screen__s) {
            overflow: hidden;

            img {
                margin: 0 !important; //overwrite margin from Theme cms page
            }
        }
    }
}

.recap-offer-events-catalog-showcases {
    background: $color-grey-background;

    @include min-screen($screen__l) {
        height: 100%;
    }

    .event-showcase {
        &:last-child {
            margin-bottom: 0; // Prevent background white
        }

        &:not(:last-child) {
            .products-list-mention {
                display: none;
            }
        }

        .products-list-mention {
            white-space: normal;

            @include max-screen($screen__s) {
                margin-right: $indent__l;
            }
        }
    }

    .event-showcase-top {
        @include max-screen($screen__s) {
            margin: 0 1.5rem;
        }
    }
}
