@import 'seo/module';
@import 'destockage/module';

.cms-destockage {
    background-color: $color-grey-background !important;

    .breadcrumbs {
        @include min-screen($screen__l) {
            padding-top: 1.5rem;
            line-height: 1.6rem;
        }
    }

    .columns .column.main {
        padding: 0 !important;
    }

    @include max-screen($screen__l) {
        .#{$css-namespace}catalog-event-page_images {
            img {
                margin: 0 !important;
            }
        }
    }
    @include min-screen($screen__l) {
        .#{$css-namespace}catalog-event-page {
            margin-top: 0.7rem;
        }
    }
}
