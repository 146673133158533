.useraccount-quickorder-index,
.useraccount-reorder-index {
    background-color: $color-grey-background;

    .page-wrapper {
        min-height: auto;

        .breadcrumbs {
            margin: 1.5rem auto;
        }

        .page-main {
            .columns {
                padding-bottom: 4rem;
                margin-top: 3rem;

                @include max-screen($screen__l) {
                    margin-top: 0.5rem;
                }

                @include max-screen($screen__m) {
                    padding-bottom: 0;
                }

                .main {
                    @include min-screen($screen__l) {
                        padding: 0 4rem;
                    }

                    @include max-screen($screen__s) {
                        padding: 0 0.5rem;
                    }
                }
            }
        }
    }
}

.useraccount-reorder-index {
    .#{$css-namespace}form-radio-group {
        display: none !important;
    }
}
