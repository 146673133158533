.#{$css-namespace}header-simplify {
    background-color: $color-sub-secondary;

    &-content {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: var(--header-simplify-height);
        padding: $space-2 $space-3;
        color: $color-white;
        font-family: $font-default;
        font-size: $font-size-default;
        max-width: $layout__max-width-lg;

        .logo {
            width: auto;
        }

        img {
            width: auto;
            height: 3.2rem;

            @include max-screen($screen__m) {
                height: 2.6rem;
            }
        }

        &-img {
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: left;
            position: relative;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            min-height: 6.5rem;
            max-width: $layout__max-width-lg;
            width: 100%;

            &--desktop {
                @include max-screen($screen__m) {
                    display: none;
                }
            }

            &--mobile {
                max-height: 13rem;
                object-fit: cover;

                .quotation-success-header & {
                    max-height: 41vw;
                }

                @include min-screen($screen__m) {
                    display: none;
                }
            }
        }
    }

    &.#{$css-namespace}header-steps .#{$css-namespace}header-simplify-content {
        &:has(.#{$css-namespace}progress-bar--steps) {
            @include min-screen($screen__m) {
                justify-content: center;
                align-items: center;
            }

            a.logo {
                @include min-screen($screen__m) {
                    position: absolute;
                    left: 2.5rem;
                }
            }
        }

        // TODO: Once FIXME solved in `view/frontend/templates/header_simplify.phtml` delete this block of style
        .#{$css-namespace}progress-bar--steps {
            .useraccount-multiuser-agency & {
                display: none;
            }
        }
    }
}
