.#{$css-namespace}order-summary {
    &_wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: $offset-l;

        @include min-screen($screen__l) {
            flex-direction: row;
            justify-content: space-between;
        }

        .actions-toolbar {
            flex: 0 0 auto;
            display: flex;
            margin: $offset-l 0 0 0;
        }
    }

    &_action-wrapper {
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            margin-bottom: 0;
        }
    }

    &_details {
        flex: 1 0 auto;

        @include min-screen($screen__l) {
            .#{$css-namespace}title-section {
                margin-left: 1rem;
            }
        }

        .#{$css-namespace}title {
            line-height: 1.1;
            margin-top: $space-3;
        }

        &-list {
            .#{$css-namespace}form-field-wrapper {
                width: 100%;

                @include min-screen($screen__l) {
                    width: 22rem;
                }
            }
        }

        &-item {
            display: flex;
            flex-direction: row;
            margin: 0;
            line-height: 1.6;
        }

        &-label {
            flex: 0 1 auto;
            margin: 0;
            font-weight: $font-weight-regular;
            min-width: fit-content;
        }

        &-value {
            flex: 1 1 auto;
            margin: 0 0 0 $space-1;
            font-weight: $font-weight-regular;
            color: $color-primary;
        }
    }
}
