/* Basic HTML rules */
html {
    height: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: $font-size-root;
    box-sizing: border-box;

    &.noScroll {
        overflow: hidden;
    }
}

/* Reset box-model components */
*,
*:before,
*:after {
    box-sizing: inherit;
}

/* Reset + apply default browser rules */
body {
    margin: 0;
    font-family: $font-default;
    font-size: $font-size-default;
    font-variant-ligatures: no-common-ligatures;
    line-height: $line-height-default;
    background-color: $color-background-default;
    color: $color-text-default;
    width: calc(100% - var(--scroll-bar-gap, 0px));
}

/* Remove _all_ animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-play-state: paused !important;
        transition: none !important;
        scroll-behavior: auto !important;
    }

    html {
        scroll-behavior: auto;
    }
}

.page-wrapper {
    padding-top: var(--header-height);
}
