.#{$css-namespace}opc-wrapper {
    display: flex;
    padding: 0;
    width: auto;
    float: none;
    max-width: $layout-checkout__max-width;
    margin: 1.5rem auto 0;

    @include max-screen($screen__l) {
        flex-direction: column;
        margin-top: 2rem;
    }

    .opc {
        flex: 1;
    }

    .step-content {
        margin-bottom: 2rem;

        @include max-screen($screen__l) {
            padding: 0 $indent__xs;
        }
    }
}

.opc-sidebar {
    margin: 0 0 $indent__base;
    width: 100%;

    @include min-screen($screen__m) {
        width: calc(100% / 3);
        margin: $indent__xl 0 $indent__base;
        float: right;
        order: 2;
    }

    @include min-screen($screen__l) {
        align-self: stretch;
    }
}

.checkout {
    &__item {
        position: relative;
        background-color: $color-grey-background;
        border-radius: 0.3rem;
        transition: box-shadow 0.3s; // TODO : Uniformise using var(--transition-normal)

        & + & {
            margin-top: 2rem;
        }

        &:not(&--active):hover {
            box-shadow: var(--box-shadow-light);
        }

        &:empty {
            display: none;
        }

        .checkout__sub_title-container {
            padding: 2rem;
        }

        &--active {
            padding: 2rem;
            border: 0.1rem solid $color-primary;
            box-shadow: none;

            & .payment-method-content {
                display: block;
            }

            .checkout {
                &__sub_title-container {
                    margin-bottom: $space-2;
                    cursor: auto;
                }

                &__shipping-details {
                    display: flex;
                    flex-direction: column;
                    gap: $space-2;
                }
            }

            .checkout__sub_title-container {
                padding: 0;
            }
        }

        &--no-border {
            border-color: transparent;
        }
    }

    &__title {
        color: #03488f; // TODO: use var $color-sub-secondary
        font-weight: $font-weight-bold;
        font-size: 2.4rem;
        margin-bottom: 1.5rem;

        @include min-screen($screenSizeM) {
            font-size: 2.8rem;
        }

        &--space-top {
            margin-top: 1rem;
        }
    }

    &__sub_title {
        margin: 0 !important;
        color: $color-black !important;

        &-container {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            cursor: pointer;

            .dcfonts-icons {
                font-size: 2.4rem;
                color: $color-primary;
                display: inline-flex;

                &--magasin,
                &--magasin::before {
                    width: 2.4rem;
                    height: 2.2rem;
                }
            }

            .#{$css-namespace}title {
                font-weight: $font-weight-semi-bold;
            }
        }
    }

    &__shipping_container {
        display: flex;
        flex-direction: column;
    }

    &_switchstore_link {
        margin: 1rem 0 0 2.5rem;

        @include min-screen($screen__l) {
            margin: $space-1 0 0;
            width: 25%;
            text-align: right;
        }
    }

    &__price {
        display: flex;
        column-gap: 1rem;
        width: fit-content;
        margin-left: auto;

        @include min-screen($screen__l) {
            margin-bottom: auto;
        }

        &-content {
            height: 2.2rem;
            padding: 0 0.7rem;
            color: $color-primary;
            line-height: 2.2rem;
            font-size: $font-size-xs-l;
            border: 0.1rem solid $color-primary;
            border-radius: 1.1rem;
        }

        .#{$css-namespace}tooltip > span {
            left: -1.4rem;
        }
    }

    &__info_link {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: auto;
        padding-top: 1.5rem;
        font-size: 0;

        &.#{$css-namespace}link {
            display: block;
            margin-right: auto;
            text-decoration: none;

            &:visited {
                color: $color-secondary;
            }

            &:hover {
                > span {
                    text-decoration: none;
                }
            }

            &.hide-option {
                display: none;
            }

            > span {
                vertical-align: middle;
                font-size: $font-size-default;
                text-decoration: underline;
            }

            > .dcfonts-icons {
                margin-right: 0.5rem;
                font-size: 1.7rem;
                width: auto;
                height: auto;
            }
        }
    }

    &__input,
    &__textarea {
        padding-top: 2.7rem;
    }

    &__textarea {
        &.field {
            margin: 0;
        }

        .control {
            textarea {
                padding: 1rem;
                min-height: 5rem;

                &.input-text,
                &.input-text:focus,
                &.input-text:not([value='']) {
                    padding: 2.3rem 2.1rem 0.7rem;
                }
            }
        }
    }

    &__input {
        width: 28.5rem;

        &.field .control input.input-text {
            padding-top: 0;
        }
    }

    &__break_line {
        width: 100%;
        border-bottom: 0.2rem dotted #d5d4d2; // TODO: use $color-gray76 instead of hexa
        margin: 3rem 0;
    }

    &__notice {
        label.label span {
            font-weight: $font-weight-regular;
        }

        textarea {
            @include max-screen($screen__s) {
                min-height: 14rem;
            }
        }
    }

    &__notice_description {
        margin-bottom: 1.5rem;
    }

    &__buttons {
        margin-bottom: 1.5rem;
        display: flex;

        @include max-screen($screen__l) {
            margin-bottom: 2rem;
            padding: 0 1.5rem;
        }
    }

    &__back-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: $color-secondary;

        &-icon {
            font-size: 1.7rem;
        }

        &-text {
            margin-left: $indent__xs;
            text-decoration: underline;
            line-height: 1.9rem;
        }
    }

    &__demo_message {
        color: $color-error;
        font-weight: $font-weight-bold;
        text-align: center;
        padding-top: 2rem;
    }

    &__secure_payment {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: $indent__xs;
    }

    &__secure_title {
        font-weight: $font-weight-bold;
    }

    &__secure_description {
        text-align: center;
        color: #777777; // TODO: Use global var
        font-size: $font-size-xs;
        width: 35rem;

        a {
            text-decoration: underline;
        }
    }

    &__keeptogether-shipping.field {
        padding-left: 2.1rem;
        margin: 0 !important;

        div.control .field.choice input.checkbox {
            vertical-align: middle;
        }

        div.control label.label {
            top: 0.2rem !important;
            color: $color-gray20;
            font-weight: $font-weight-bold;
        }
    }

    &__method-card {
        &-information {
            $root: &;

            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            background: $color-grey-background;
            padding: 1.5rem 2rem;
            border: 0.1rem solid $color-grey-argent;
            border-radius: 0.8rem;
            line-height: 1.9rem;

            &_subtitle {
                font-weight: $font-weight-bold;
            }

            &_label {
                color: $color-primary;
                font-weight: $font-weight__bold;
                margin-bottom: 1rem;
            }

            &-container {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                @include min-screen($screen__m) {
                    flex-direction: row;
                    width: 100%;
                }

                &--click-and-collect #{$root}_label {
                    color: $color-accent;
                }
            }

            &.with-message {
                min-height: 17.4rem;
                padding-top: 4.3rem;

                .message {
                    margin-bottom: 2.6rem;
                    text-align: center;
                }
            }
        }

        &-action-button {
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }

    &__opening-hours-tooltip {
        margin-top: 1rem;
        width: fit-content;

        .dcfonts-icons--info {
            font-size: 2.3rem;
        }

        > span {
            border-radius: 0.3rem;

            &,
            &::after {
                border-color: $color-secondary !important;
            }
        }

        .#{$css-namespace}open_title {
            display: flex;
            column-gap: 1rem;
            color: $color-secondary;
            font-weight: $font-weight-bold;

            i {
                font-size: 1.7rem;
            }
        }

        dd {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 0;

            .day {
                display: flex;

                .#{$css-namespace}day {
                    margin-right: auto;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                &.is_opened,
                &.is_almost_closed,
                &.is_closed {
                    font-weight: $font-weight-bold;
                }

                &.is_opened {
                    color: $color-success-secondary;
                }

                &.is_almost_closed {
                    color: $color-orange;
                }

                &.is_closed {
                    color: $color-error;
                }
            }
        }
    }

    &__delivery-contact-modal,
    &__phone-required-modal {
        fieldset {
            input {
                width: 100%;

                @include min-screen($screen__l) {
                    width: 25rem;
                }
            }
        }
    }

    &__delivery-contact-modal {
        fieldset {
            margin: 0;

            &:first-of-type {
                margin: $space-1 0;
            }
        }
    }

    &__phone-required-modal fieldset {
        margin-top: $space-1;
    }

    &__method-card-action-button {
        margin-bottom: 0;
    }

    &__address-list.modal-popup {
        .change-shipping-address {
            width: 100%;
            overflow-x: hidden;
        }

        .#{$css-namespace}card-with-fieldset {
            border-color: $color-primary;
            padding-right: 1.8rem;

            &-right {
                > * {
                    color: $color-secondary !important;
                }
            }

            &.small-margin {
                margin-bottom: 1rem;
            }

            @include max-screen($screen__m) {
                &--fake-radio {
                    flex-wrap: wrap;

                    .#{$css-namespace}card-with-fieldset-right {
                        display: block;
                        margin-top: 1rem;
                        margin-left: 4.5rem;
                        width: 100%;
                    }
                }
            }
        }

        .change-shipping-address-item {
            border: 0.1rem solid $color-grey-background;

            &:first-of-type {
                margin-top: 0;
            }

            &.selected-item {
                border-color: $color-primary;
            }

            &-container {
                display: flex;
                flex-direction: column;
                row-gap: 1.5rem;
                width: 100%;
            }

            .control {
                min-height: 12rem !important;

                .change-shipping-address-action {
                    margin: auto 0 0 auto;
                    border-color: $color-primary;
                    background-color: $color-primary;
                }
            }

            .address-container {
                display: flex;
                flex-direction: column;

                @include max-screen($screen__m) {
                    .extra-text-right {
                        display: inline-block;
                        position: static;
                        z-index: 2;
                        order: 5;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

.#{$css-namespace}form-radio-group--with-background[data-ui-skin='card'] input[type='radio'] ~ button.select {
    min-width: 13.1rem;
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
        background-color: $color-primary-darken;
        border-color: $color-primary-darken;
    }
}
