.cms-page-view:not(.cms-toolkit):not(.cms-operations-bons-plans-du-moment):not(.cms-destockage) {
    .columns {
        h1 {
            @include lib-font-size(28);
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__m;
            color: $color-blue3;
            text-align: center;
            line-height: 1.8;
        }

        h2 {
            @include lib-font-size(24);
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__s;
            color: $color-blue3;
            line-height: 1.6;
            border-bottom: 0.1rem solid $color-gray-light1;
        }

        h3 {
            @include lib-font-size(20);
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__s;
            line-height: 1.4;
        }

        p {
            @include lib-font-size(16);
            margin: 0 0 $indent__s;
        }

        .img-first-of-line {
            margin-left: 0 !important;
        }

        .img-last-of-line {
            margin-right: 0 !important;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            margin-bottom: $indent__s;

            li {
                @include lib-font-size(16);
                margin-bottom: 0.5rem;

                &::before {
                    margin-right: 1rem;
                    content: '\2022';
                    @include lib-font-size(18);
                    font-weight: $font-weight__bold;
                    color: $color-blue3;
                }
            }
        }

        a.btn,
        button {
            @include lib-button-primary();

            &.btn-center {
                margin: 2rem auto;
                display: block;
                width: 11rem;
                text-align: center;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .img-with-button {
            position: relative;

            img {
                width: 100% !important;
            }

            a.btn,
            button {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: #fdc239; // TODO: Use global var
                border: 0.1rem solid #fdc239; // TODO: Use global var

                &:hover {
                    background-color: $altPrimaryColor;
                }
            }
        }

        .grid-w50 {
            text-align: center;
        }

        .btn_see_more {
            font-weight: $font-weight-bold;
            font-size: 1.5rem;
            float: left;
            margin: 1rem 0;

            span {
                color: $color-sub-secondary;
                vertical-align: middle;
                line-height: 1.9;
            }
        }

        img {
            margin-bottom: 1rem !important;
        }
    }

    &.cms-plan-du-site,
    &.cms-no-route {
        h1 ~ ul > li:has(a > button) {
            // Until rework in all cms pages
            text-align: center !important;
            margin-bottom: $space-2;

            &::before {
                display: none;
            }
        }
    }
}

.cms-page-view.cms-operations-bons-plans-du-moment {
    .event-showcase_title {
        .#{$css-namespace}title-section {
            color: $color-primary;
        }
    }

    .catalog-events-title {
        .#{$css-namespace}title-section {
            span {
                @include min-screen($screen__l) {
                    line-height: 1;
                }
            }
        }
    }

    .column.main {
        padding: 0;
    }
}

@include max-screen($screen__l) {
    .cms-page-view:not(.cms-toolkit) {
        .columns {
            img,
            .img,
            .img-responsive {
                display: block;
                width: 100% !important;
                height: auto;
                margin: $indent__m 0;
            }

            .loading-mask .loader > img {
                width: auto !important;
                margin: auto;
            }
        }
    }
}

@include min-screen($screen__l) {
    .cms-page-view:not(.cms-toolkit) {
        #maincontent {
            margin-bottom: 1.5rem;
        }

        .columns {
            .img-w33 {
                width: calc(33.333% - 1.3333rem);
                margin: 0 1rem;
                float: left;
            }

            .img-w50 {
                width: calc(50% - 1.5rem);
                margin: 0 1.5rem;
                float: left;
            }

            .img-w100 {
                display: block;
                margin: 0;
                width: 100%;
            }

            .grid-w33 {
                width: calc(33.333% - 1.3333rem);
                margin: 0 1rem;
                float: left;
            }

            .grid-w50 {
                width: calc(50% - 2rem);
                padding: 0 1rem;
                float: left;
            }

            .grid-w100 {
                display: block;
                margin: 0;
                width: 100%;
                float: left;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .cms-page-view:not(.cms-toolkit) {
        .breadcrumbs {
            margin-top: 1rem;
        }
    }
}

@include max-screen($screen__s) {
    .cms-page-view:not(.cms-toolkit) {
        .columns {
            h1 {
                line-height: 1.2;
            }
        }
    }
}

@include max-screen($screen__l) {
    .cms-page-view:not(.cms-toolkit) {
        .columns {
            .column {
                padding-top: $space-5;
            }
        }
    }
}
