$list-element-height: 3rem;

.#{$css-namespace}input-autocomplete {
    @include lib-list-reset-styles();

    position: absolute;
    z-index: 1;
    max-height: $list-element-height * 7;
    overflow: auto;
    border-radius: 0 0 0.3rem 0.3rem;
    background-color: $color-white;
    box-shadow: 0 0.4rem 1rem 0 rgba($color-black, 0.12); // TODO: Use box-shadow var
    @extend %scrollbars !optional;

    li {
        display: flex;
        align-items: center;
        height: $list-element-height;
        margin-bottom: 0;
        padding: 0 1.5rem;
        cursor: pointer;
        transition: all 0.3s; // TODO : Uniformise using var(--transition-normal)

        &:hover,
        &.#{$css-namespace}input-autocomplete_selected {
            color: $color-white;
            background-color: $color-input-autocomplete-search-btn;
        }

        b {
            display: contents;
        }
    }
}
