@import 'module/variables';
@import 'module/sidebar';
@import 'module/modal-shops-availability';
@import 'module/related-products';
@import 'module/discriminant-selection';
@import 'module/reference-table';
@import 'module/catalog-events-banner';
@import 'module/product-promo';

.catalog-category-view.page-layout-2columns-left {
    .breadcrumbs,
    .page-main {
        background-color: transparent;
    }

    .#{$css-namespace}list-dl {
        .#{$css-namespace}list {
            .#{$css-namespace}badge {
                text-align: center;

                @media screen and (max-width: $screen-sm-2) {
                    min-height: 3.5rem;
                }
            }
        }
    }
}
