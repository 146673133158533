// Mixins
@import 'mixins/a11y';
@import 'mixins/border';
@import 'mixins/box-shadow';
@import 'mixins/box-sizing';
@import 'mixins/button';
@import 'mixins/font';
@import 'mixins/input';
@import 'mixins/link';
@import 'mixins/media-queries';
@import 'mixins/wrapper';

// webfonts / icons fonts
@import '../web/fonts/webFonts/dcfonts-icons.scss';

// Vendor libs
//@import 'vendor/normalize/lib'; // Normalize.css
// Magento UI
@import 'vendor/magento-ui/lib';

// Theme variables
// old variables
@import 'variables';
// new variables v2 socle (QUICK-FIX)
@import '../../../Descours_v2/Descours/styles/toolkit/config/config';
// new variables v2 Hydralians (QUICK-FIX)
@import '../../../Descours_v2/Hydralians/styles/toolkit/config/config';
// new variables v2 socle (QUICK-FIX)
@import '../../../Descours_v2/Descours/styles/components/config/config';

// Override
@import 'extend';

// Reset default styles with magento-reset
@import 'blocks/reset';

// Theme blocks
@import 'blocks/actions-toolbar';
@import 'blocks/actions-bar';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/extends';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/layout';
@import 'blocks/loaders';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/pages';
@import 'blocks/datatable-pagination';
@import 'blocks/datatable-sorting';
//@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/sections';
@import 'blocks/tables';
@import 'blocks/tooltips';
@import 'blocks/typography';
@import 'blocks/qty-picker';
@import 'blocks/nice-select';
@import 'blocks/category-header';
@import 'blocks/category-title';
@import 'blocks/login-block';
@import 'blocks/catalog-events-page';
@import 'blocks/input-autocomplete';

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_Search/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';
@import '../Magento_Ui/styles/utilities';
@import '../Descours_UserAccount/styles/module';
@import '../Descours_Catalog/styles/module';
@import '../Descours_CatalogSearch/styles/module';
@import '../Descours_Wishlist/styles/module';
@import '../Descours_AccountOpeningRequest/styles/module';
@import '../Pictime_Multimenu/styles/module';
@import '../Descours_Brands/styles/module';
@import '../Descours_CapAddress/styles/module';
@import '../Descours_Sales/styles/module';
@import '../Descours_ProductSlot/styles/module';
@import '../Descours_Schedules/styles/module';
@import '../Descours_Payzen/styles/module';
@import '../Descours_Cms/styles/module';
@import '../Descours_FavoriteShop/styles/module';
@import '../Descours_EquivalentProducts/styles/module';
@import '../Descours_LinkedArticle/styles/module';
@import '../Descours_Checkout/styles/module';
@import './cms/modules';
// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';

@import 'theme';

@keyframes hide-messages {
    to {
        display: none;
        visibility: hidden;
        height: 0;
        padding: 0;
        margin: 0;
        position: absolute;
    }
}

.messages.messages--persistent {
    display: none; // Will be displayed in a forthcoming version
    width: 100%;

    .message > div,
    .message {
        animation: none !important;
    }
}

.message.info,
.message.warning,
.message.notice,
.message.error,
.message.success,
.message.info > div,
.message.warning > div,
.message.notice > div,
.message.error > div,
.message.success > div,
.discount-coupon-validated-text,
.discount-coupon-error-text {
    animation: hide-messages 0s 20s forwards;
}
