// Default variables
$font_size: $font-size-default !default;
$font_size_small: $font-size-s !default;

$input_border_radius: 0 !default;
$input_height: 4rem !default;
$input_height_small: 3.6rem !default;
$dropdown_padding: $space-1 !default;

$primary_light: $mainNeutralColor !default;
$arrow_color: $defaultColor !default;

// Style the dropdown
.nice-select {
    -webkit-tap-highlight-color: rgba($color-black, 0);
    border-radius: $input_border_radius;
    border: solid 0.1rem $color-blue1;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: $font_size;
    font-weight: $font-weight-regular;
    height: $input_height;
    line-height: $input_height - 0.2rem;
    outline: none;
    padding-left: 0;
    padding-right: 3.6rem;
    position: relative;
    text-align: left !important;
    transition: all 0.2s ease-in-out; // TODO : Uniformise using var(--transition-normal)
    user-select: none;
    white-space: nowrap;
    width: auto;
    background-color: $color-blue1;

    & span {
        display: block;
        height: $input_height;
        padding-left: $dropdown_padding;
        padding-right: $dropdown_padding + 1.2;
        background-color: $defaultColor;
        color: $color-blue1;
    }

    &:hover {
        border-color: darken($color-sub-secondary, 5%);
    }

    &:active,
    &:focus {
        border-color: $color-sub-secondary;
    }

    // Arrow
    &::after {
        border-bottom: 0.2rem solid $arrow_color;
        border-right: 0.2rem solid $arrow_color;
        content: '';
        display: block;
        height: 1rem;
        margin-top: -$space-1;
        pointer-events: none;
        position: absolute;
        right: 1.2rem;
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg);
        transition: all 0.15s ease-in-out; // TODO : Uniformise using var(--transition-normal)
        width: 1rem;
    }

    &.open {
        @extend :active;

        &::after {
            transform: rotate(-135deg);
        }

        .list {
            display: block !important;
            width: 100%;
            opacity: 1;
            pointer-events: auto;
            transform: scale(1) translateY(0);
        }
    }

    &.disabled {
        border-color: lighten($subAltPrimaryColor, 2%);
        color: $subAltPrimaryColor;
        pointer-events: none;

        &::after {
            border-color: lighten($arrow_color, 20%);
        }
    }

    // Modifiers
    &.wide {
        width: 100%;

        .list {
            left: 0 !important;
            right: 0 !important;
        }
    }

    &.right {
        float: right;

        .list {
            left: auto;
            right: 0;
        }
    }

    &.small {
        font-size: $font_size_small;
        height: $input_height_small;
        line-height: $input_height_small - 0.2rem;

        &::after {
            height: 0.4rem;
            width: 0.4rem;
        }

        .option {
            line-height: $input_height_small - 0.2rem;
            min-height: $input_height_small - 0.2rem;
        }
    }

    // List and options
    .list {
        background-color: $defaultColor;
        border-radius: $input_border_radius;
        box-shadow: 0 0 0 0.1rem rgba($subAltPrimaryColor, 0.11); // TODO: Use box-shadow var
        box-sizing: border-box;
        margin-top: 0.1rem;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        transform-origin: 50% 0;
        transform: scale(0.75) translateY(-$input_height / 2);
        transition:
            all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
            opacity 0.15s ease-out; // TODO : Uniformise using var(--transition-normal)
        z-index: 9;

        &:hover .option:not(:hover) {
            background-color: transparent !important;
        }
    }

    .option {
        cursor: pointer;
        font-weight: $font-weight-regular;
        line-height: $input_height - 0.2rem;
        list-style: none;
        min-height: $input_height - 0.2rem;
        outline: none;
        padding-left: $dropdown_padding;
        padding-right: $dropdown_padding + 1.1rem;
        text-align: left;
        transition: all 0.2s; // TODO : Uniformise using var(--transition-normal)

        &:hover {
            background-color: $color-blue1;
            color: $defaultColor;
        }

        &.focus,
        &.selected.focus {
            font-weight: $font-weight-bold;
        }

        &.selected {
            font-weight: $font-weight-bold;
        }

        &.disabled {
            background-color: transparent;
            color: $subAltPrimaryColor;
            cursor: default;
        }
    }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
    .list {
        display: none;
    }

    &.open {
        .list {
            display: block;
        }
    }
}
