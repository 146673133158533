/**
    Override for default M2 messages
 */
$screen-max-width-messages: 129.2em; // $screen-xl doesn't apply the right width as it is in em

.messages {
    .message {
        color: $color-main-dark;
        padding: 0;
        border: 0.1rem solid;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $space-1;

        > :first-child {
            display: flex;
            align-items: center;
            gap: $space-1;
            max-width: $screen-max-width-messages;
            width: 100%;
            padding: $space-2;

            @include min-screen($screen__m) {
                padding-left: $space-3;
                padding-right: $space-3;
            }

            &::before {
                position: static;
                font-family: $font-icons;
                font-size: 2.4rem;
                width: auto;
                margin: 0;
                line-height: 1;
                flex-shrink: 0;
            }
        }


        &.notice {
            background: $color-message-notice-background;
            border-color: $color-message-notice;

            > :first-child::before {
                content: $information-circle;
                color: $color-message-notice;
            }
        }

        &.warning {
            background: $color-message-warning-background;
            border-color: $color-message-warning;

            > :first-child::before {
                content: $warning-triangle;
                color: $color-message-warning;
            }
        }

        &.error {
            background: $color-message-error-background;
            border-color: $color-message-error;

            > :first-child::before {
                content: $close-line;
                color: $color-message-error;
            }
        }

        &.success {
            background: $color-message-success-background;
            border-color: $color-message-success;

            > :first-child::before {
                content: $check-line;
                color: $color-message-success;
            }
        }
    }
}
