.#{$css-namespace}modal_complementary-products {
    .#{$css-namespace}product-carousel__top {
        @include min-screen($screen-md) {
            margin-top: $space-3;
        }
    }

    .teaser-text {
        font-size: $font-size-m;
    }
}
