.page-header {
    .header.content {
        .block-search {
            margin-top: $indent__base;

            .block-content {
                @extend .abs-add-box-sizing;
                @include lib-css(position, relative);
                z-index: 1;
                padding: 0;
                overflow: hidden;
                width: 100%;
                margin: auto;

                .label {
                    display: none;
                }

                .form.minisearch {
                    &.active,
                    &.active:hover {
                        .input-text {
                            @include lib-css-border(0.2rem, solid, $form-element-input__border--prolians);
                        }

                        .actions {
                            background: $color-secondary;
                        }
                    }
                }

                .input-text {
                    @include lib-css(position, static);
                    @include lib-input-placeholder(
                        $form-element-input-placeholder__color--prolians,
                        $font-weight-regular
                    );
                    @include lib-css(font-family, $subFont);
                    @include lib-font-size(1.4rem);
                    border: 0.1rem solid $border-color__base;
                    border-radius: 0;
                    width: calc(100% - 4.4rem);
                    box-shadow: none;
                    @include lib-css(color, $form-element-input-placeholder__color--prolians);
                    padding: 0 $indent__s;
                    background: $color-white;
                    border-right: 0;
                }
            }

            .actions {
                background-color: $color-secondary;
                @include lib-css(position, absolute);
                right: 0;
                top: 0;
                width: 4rem;
                height: 100%;
                padding-left: 0;

                &:hover {
                    cursor: pointer;
                }

                .action.search {
                    @include lib-button-reset();
                    @include lib-css(position, absolute);
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 0 0.3rem;

                    span {
                        display: none;
                    }

                    .#{$css-namespace}icon {
                        width: 1em;
                        height: 1em;
                        color: $color-secondary;
                        font-size: 2.2rem;
                    }

                    &::before {
                        display: none;
                    }

                    &:disabled {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .page-header {
        .header.content {
            .block-search {
                padding: 1.1rem;
                height: auto;

                .block-content {
                    height: 4rem;
                    box-shadow: 0 1rem 1.5rem -1rem #999; // TODO: use $color-gray60 instead of hexa and Use box-shadow var

                    .input-text {
                        height: 4rem;
                    }
                }
            }
        }
    }
}

@include screen($screen__xs, $screen__l) {
    .page-header {
        .header.content {
            .block-search {
                padding: $indent__base;
                height: 4rem;

                .block-content {
                    box-shadow: 0 1rem 1.5rem -1rem #999; // TODO: use $color-gray60 instead of hexa and Use box-shadow var
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .page-header {
        .header.content {
            .block-search {
                @include lib-vendor-prefix-order(6);
                @include lib-vendor-prefix-flex-basis(100%);
                margin-top: 0;
                padding: 0;

                &.bottom {
                    box-shadow: 0 1.5rem 1rem -1.5rem #111; // TODO: Use box-shadow var
                }

                .field.search {
                    text-align: left;
                }

                .action.search {
                    display: block;
                }

                .block-content {
                    .input-text {
                        border: 0;
                    }
                }

                .actions {
                    @include lib-css(background, $defaultColor);
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .page-header {
        .header.content {
            .block-search {
                overflow: hidden;
                padding: 0;
                margin: 0 $indent__xl;
                flex: 1;

                .block-content {
                    max-width: 43.9rem;
                }

                .actions {
                    .action.search {
                        .#{$css-namespace}icon {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
}
