%custom-underline {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0.4rem;
        border-radius: $space-1;
        background-color: $color-secondary;
        opacity: 0;
        transition:
            opacity var(--transition-normal),
            width var(--transition-normal);
    }

    &:hover,
    &:focus-within {
        &::before {
            opacity: 1;
            width: 100%;
        }
    }
}

@import 'megamenu/nav-bar';
@import 'megamenu/top-links';
