// variables
$title-font-family: $font-secondary;
$title-font-size: $font-size-default;
$title-font-weight: $font-weight-bold;
$title-line-height: $line-height-default;
$title-color: $color-primary;

// abstract titles
%title {
    margin: 0;
    font-family: $title-font-family;
    font-size: $title-font-size;
    font-weight: $title-font-weight;
    line-height: $title-line-height;
    color: $title-color;
}

// abstract titles by level
%title-level-1 {
    font-size: $font-size-xxl;

    @media screen and (min-width: $screen-sm) {
        font-size: $font-size-xxxl;
    }
}

%title-level-2 {
    font-size: $font-size-xl;

    @media screen and (min-width: $screen-sm) {
        font-size: $font-size-xxl;
    }
}

%title-level-3 {
    font-size: $font-size-m;
    font-weight: $font-weight-semi-bold;

    @media screen and (min-width: $screen-sm) {
        font-size: $font-size-xl;
    }
}

%title-level-4 {
    font-family: $font-default;
    font-size: $font-size-m;
    font-weight: $font-weight-regular;

    @media screen and (min-width: $screen-sm) {
        font-size: $font-size-xl;
    }
}

%title-level-5 {
    font-family: $font-default;
    font-size: $font-size-default;
    font-weight: $font-weight-bold;
}

%title-level-6 {
    font-family: $font-default;
    font-size: $font-size-default;
}

.#{$css-namespace}title {
    @extend %title;

    &[data-ui-title-level='1'] {
        @extend %title-level-1;
    }

    &[data-ui-title-level='2'] {
        @extend %title-level-2;
    }

    &[data-ui-title-level='3'] {
        @extend %title-level-3;
    }

    &[data-ui-title-level='4'] {
        @extend %title-level-4;
    }

    &[data-ui-title-level='5'] {
        @extend %title-level-5;
    }

    &[data-ui-title-level='6'] {
        @extend %title-level-6;
    }

    &--primary {
        color: $color-primary;
    }

    &--secondary {
        color: $color-secondary;
    }

    &--main-dark {
        color: $color-main-dark;
    }
}

.#{$css-namespace}title-section {
    @extend %title;

    font-size: $font-size-xl;
    line-height: 2.9rem;
    color: $color-secondary;

    span {
        display: block;
        font-size: $font-size-xxl;
        color: $color-primary;
    }

    @media screen and (min-width: $screen-sm) {
        font-size: $font-size-xxl;
        line-height: 3.9rem;

        span {
            font-size: 4.5rem;
        }
    }
}

// paragraphs
.#{$css-namespace}p {
    margin: 0;

    + .#{$css-namespace}p {
        margin-top: $offset-m;
    }
}

// paragraphs
.#{$css-namespace}mention {
    font-size: $font-size-xs;
    color: $color-text-light;
}

//hr
hr {
    border-top: 0.1rem solid $color-gray97;
    margin: $space-2 0;
}

.#{$css-namespace}hr--light {
    border-color: $color-disabled-light;
}

// Text utilities

.first-letter-uppercase {
    display: inline-block;

    &:first-letter {
        text-transform: uppercase;
    }
}
