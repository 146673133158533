.modals-wrapper .#{$css-namespace}modal_choose-store-popin,
.modals-wrapper .#{$css-namespace}modal-shops-availability {
    .modal-inner-wrap {
        background-color: $color-grey-background;
        padding: $space-6 $space-3 $space-4;
        display: flex;
        flex-direction: column;
        gap: $space-1;

        .modal-content {
            padding: 0 $space-2 0 0;
        }

        .#{$css-namespace}availability {
            font-weight: $font-weight-bold;
        }

        .#{$css-namespace}card {
            margin: 0;
        }

        .store-availability,
        .#{$css-namespace}choose-store-popin {
            &,
            .store-results {
                display: flex;
                flex-direction: column;
                gap: $space-1;
                width: 100%;
            }

            .input-wrapper {
                display: flex;
            }

            .field {
                width: 100%;
            }

            .input-text {
                padding: $space-1 $space-10 $space-1 $space-2;
                border-color: $color-primary;
            }

            .input-text,
            .reset,
            .submit {
                height: $space-5;
            }

            .actions {
                display: flex;
                position: absolute;
                right: 0;
            }

            .reset {
                display: none;
                padding: $space-1 / 2;
                font-size: $font-size-m;
                background: none;
                border: none;
                margin-right: $space-2;
            }

            .submit {
                font-size: 2.03rem;
                width: $space-5;
                padding: 0;
            }

            .store-results {
                &:empty {
                    display: none;
                }
            }

            .result-entry {
                color: $color-primary;
                font-size: $font-size-m;
                font-weight: $font-weight-bold;
            }
        }
    }
}
