@import '_section';
@import '_labels';
@import '_slider';
@import '_modal';

.#{$css-namespace}equivalent-products {
    $root: &;

    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    margin: 0;
    padding: $space-2;
    border-radius: 0.8rem;
    box-shadow: var(--box-shadow);
    background-color: $color-white;
    width: 100%;

    @include min-screen($screen__m) {
        padding: $space-4;
    }

    @include min-screen($screen__l) {
        position: relative;
        width: calc(100% + #{$space-2});
        left: -$space-1;
    }

    &_container {
        margin-top: $space-2;
        margin-bottom: $space-2;
    }

    &:not(&--show-all) {
        &:not(#{$root}--show-six):not(#{$root}--show-seven) {
            #{$root}__label:nth-child(n + 6),
            #{$root}__attributes li:nth-child(n + 6) {
                display: none;
            }
        }

        &#{$root}--show-six {
            #{$root}__label:nth-child(n + 7),
            #{$root}__attributes li:nth-child(n + 7) {
                display: none;
            }
        }

        &#{$root}--show-seven {
            #{$root}__label:nth-child(n + 8),
            #{$root}__attributes li:nth-child(n + 8) {
                display: none;
            }
        }

        #{$root}__see-less-label {
            display: none;
        }
    }

    &--show-all {
        #{$root}__see-more {
            .dcfonts-icons--caret-down {
                rotate: 180deg;
            }

            &-label {
                display: none;
            }
        }
    }

    .#{$css-namespace}title-section {
        margin-bottom: 0;
        font-size: $font-size-xxl;
    }

    &__wrapper {
        position: relative;
        display: flex;
        column-gap: $space-3;
        margin-top: $space-2;
    }

    &__products {
        display: flex;
        align-items: end;
        column-gap: $space-3;
        flex: 1;

        &,
        &-slider {
            overflow: hidden;
        }
    }

    &__see-more {
        margin-top: 1rem;
        margin-left: $space-1;
        width: fit-content;
        font-weight: $font-weight-bold;

        .dcfonts-icons--caret-down {
            transition: rotate 0.3s; // TODO : Uniformise using var(--transition-normal)
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        margin-bottom: $space-2;
        flex-wrap: wrap !important; // overrides slider default

        @include min-screen($screen__m) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: $space-3;
        }

        .#{$css-namespace}title {
            color: $color-secondary;
            font-size: $font-size-xxl;
            font-weight: $font-weight-semi-bold;
            line-height: 1;
        }
    }

    &__slider-arrows-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: $space-1;

        @include min-screen($screen__m) {
            margin-left: $space-3;
        }

        &:empty {
            display: none;
        }

        /**
         * Apply prev/next appearance as defined in `_slider.scss`
         */
        .slick {
            @include slider-prev-next-buttons;
        }
    }
}
