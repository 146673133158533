.#{$css-namespace}footer-bottom {
    display: flex;
    justify-content: center;
    padding: $offset;

    .#{$css-namespace}list {
        justify-content: center;

        > li {
            display: flex;
            align-items: center;
            padding: 0;

            > a {
                padding: 0 $offset-s;
                font-size: $font-size-s;
            }

            + li {
                &::before {
                    content: '';
                    width: 0.1rem;
                    height: 1rem;
                    background-color: currentColor;
                }
            }
        }
    }
}
