.useraccount-customer-createcollaborator,
.useraccount-customer-edit,
.useraccount-approvalflow-create,
.useraccount-approvalflow-edit {
    .column.main {
        // overwrite default styling to create sections
        background-color: transparent !important;
        padding: 0 !important;
        box-shadow: none !important;
    }

    .fieldset {
        margin: 0;
        padding: 0 !important;
    }

    .field {
        margin: 0;

        @include min-screen($screen__l) {
            margin-left: 4rem;
        }
    }
}

.useraccount-customer-createcollaborator,
.useraccount-customer-edit {
    .#{$css-namespace}collaborator_edit {
        .#{$css-namespace}title[data-ui-title-level='1'] {
            margin-bottom: 2rem;
            margin-top: 2rem;
            line-height: 1;

            @include min-screen($screen__l) {
                margin-bottom: 3rem;
            }
        }
    }
}

.useraccount-approvalflow-create,
.useraccount-approvalflow-edit {
    .#{$css-namespace}collaborator_edit {
        .#{$css-namespace}title[data-ui-title-level='1'] {
            margin-bottom: 2rem;
            margin-top: 2rem;
            line-height: 1;

            @include min-screen($screen__l) {
                margin-bottom: 1.5rem;
            }
        }

        .#{$css-namespace}subtitle {
            margin-bottom: 3rem;
            display: flex;
        }
    }
}

.useraccount-customer-edit {
    .field-user-email {
        .control {
            width: 26rem;
            @include min-screen($screen__l) {
                width: fit-content;
            }
        }
    }
}

.#{$css-namespace}collaborator {
    $root: &;

    &_section {
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        padding: 2rem 1rem;
        margin-bottom: 2rem !important;
        box-shadow: var(--box-shadow);
        border-radius: 0.3rem;
        position: relative;

        @include min-screen($screen__l) {
            padding: 3rem;
        }

        &_title {
            .#{$css-namespace}link {
                font-size: $font-size-default;
                font-weight: $font-weight-regular;
                text-decoration: none;
                color: $color-main-dark;
            }
        }

        &.last-section {
            margin-bottom: 3rem !important;
        }

        &.expander {
            padding: 0;
        }
    }

    &_steps {
        border-radius: 50%;
        border: 0.1rem solid $color-disabled-text;
        padding: 0.3rem $space-1;
        margin-right: 1.5rem;
        font-size: $font-size-default;
        font-weight: $font-weight-bold;

        &_wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            .#{$css-namespace}title {
                font-family: $font-secondary;
                font-weight: $font-weight-medium;
            }
        }

        &_content {
            display: flex;
            flex-direction: column;

            .#{$css-namespace}collaborator_tooltip_span {
                @include max-screen($screen__l) {
                    max-width: 80vw !important; // Overwriting toolkit defaults
                    left: 0 !important;
                    top: 2rem !important;
                }
            }
        }
    }

    &_edit {
        .#{$css-namespace}title[data-ui-title-level='5'] {
            line-height: 1;
            font-size: $font-size-l;
        }

        .#{$css-namespace}input-text {
            width: 100%;

            @include min-screen($screen__l) {
                width: 30rem;

                .useraccount-approvalflow-index & {
                    width: 26rem;
                }
            }

            .#{$css-namespace}title[data-ui-title-level='1'] {
                margin-bottom: 2rem;
                margin-top: 2rem;
                line-height: 1;

                @include min-screen($screen__l) {
                    margin-bottom: 3rem;
                }
            }

            &.container-open {
                border-bottom: none;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-color: #005296 !important; // TODO: Use global var

                ~ label {
                    background: $color-white !important;
                    color: $color-primary !important;
                    font-size: 1.2rem !important;
                    font-weight: $font-weight-regular !important;
                    top: -0.7rem !important;
                    left: $space-1 !important;
                    padding: 0 0.7rem !important;
                    transform: none !important;
                    width: auto;
                }
            }
        }

        .field-user-firstname,
        .field-user-surname {
            width: 100%;

            @include min-screen($screen__l) {
                width: auto;
            }

            .control {
                margin-bottom: 2.7rem;
            }
        }

        .field-user-email {
            .control {
                width: 100%;
                display: block;

                @include max-screen($screen__l) {
                    display: flex;

                    > .input-text,
                    > .control-pass {
                        display: inline-block;
                        width: calc(100% - 2.6rem) !important;
                    }

                    .#{$css-namespace}tooltip {
                        margin-left: 0.6rem;
                        font-size: $font-size-m;
                        order: 1;

                        .tooltip-text {
                            margin-top: 1.3rem;
                        }
                    }
                }

                &.not-editable {
                    display: flex;
                    align-items: center;

                    .#{$css-namespace}tooltip {
                        margin-left: 1.4rem;
                    }
                }

                .#{$css-namespace}collaborator_tooltip_span {
                    width: 80vw;
                    right: 2rem;

                    @include min-screen($screen__l) {
                        width: 50rem;
                        right: -1.9rem;
                    }

                    @include max-screen($screen__l) {
                        right: 2rem !important;
                        top: -0.5rem;
                    }

                    &::after {
                        @include min-screen($screen__l) {
                            top: 1.5rem;
                        }
                    }

                    .tooltip--header {
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }

        .control {
            width: 100%;

            @include min-screen($screen__l) {
                width: fit-content;
            }
        }

        .field-user-phone {
            position: relative;

            @include min-screen($screen__l) {
                position: initial;
            }

            .#{$css-namespace}input-text {
                width: 16rem;
            }

            .control {
                display: block;
                position: initial;

                @include min-screen($screen__l) {
                    position: relative;
                }

                &.has-tooltip {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .#{$css-namespace}tooltip {
                        margin-left: 2rem;
                        font-size: $font-size-m;
                        order: 1;

                        &--bottom {
                            > span {
                                @include max-screen($screen__xm) {
                                    left: 1rem;
                                }

                                @include min-screen($screen__xm) {
                                    left: 2.1rem;
                                    min-width: auto;
                                }
                            }
                        }

                        &--right {
                            > span {
                                @include max-screen($screen__l) {
                                    left: 0.1rem;
                                    top: 4rem;
                                }
                            }
                        }
                    }
                }

                input {
                    width: 16rem !important;
                    order: 0;

                    ~ div.mage-error {
                        order: 3;
                    }
                }

                .#{$css-namespace}tooltip {
                    position: initial;

                    @include min-screen($screen__l) {
                        position: relative;
                    }

                    .#{$css-namespace}collaborator_tooltip_span {
                        min-width: 100%;
                        width: 100%;
                        right: 0;

                        @include max-screen($screen__l) {
                            top: 3rem;
                        }

                        @include min-screen($screen__l) {
                            min-width: 50rem;
                            width: 50rem;
                            right: auto;
                            top: -2.8rem;
                        }

                        &::after {
                            left: 18rem;
                            top: 0;

                            @include max-screen($screen__l) {
                                left: 15.7rem;
                                transform: translateY(-50%) rotate(-45deg);
                            }

                            @include min-screen($screen__l) {
                                left: -0.1rem;
                                top: 2.5rem;
                                z-index: -1;
                            }
                        }
                    }
                }
            }
        }

        .#{$css-namespace}tooltip {
            &:hover {
                .dcfonts-icons {
                    color: $color-primary;
                }
            }

            > span {
                width: max-content;
                max-width: 50rem;
            }

            .dcfonts-icons {
                margin-right: 1rem;
                font-size: $font-size-m;
            }
        }

        .tooltip {
            right: -2.6rem !important;
            width: 1.6rem;
            height: 1.6rem;
            top: 1.2rem;

            &.static {
                flex-shrink: 0;
                position: static !important;
                margin-right: 1rem;
                margin-top: 1rem;

                .tooltip-text,
                .tooltip-text::before {
                    border: 0.1rem solid $color-secondary;
                    background-color: $color-white;
                    color: $color-black;
                    box-shadow: none;
                }

                .tooltip-text::before {
                    border-bottom: none;
                    border-left: none;
                }
            }
        }

        #{$root}_collab-tooltip {
            @include min-screen($screen__l) {
                // ** Copy of .#{$css-namespace}tooltip--thin **
                // (issues with include)
                > span {
                    top: 50%;
                    transform: translateY(-50%);
                    min-height: 3.7rem;
                    width: auto;
                    white-space: nowrap;

                    &::after {
                        width: 1.6rem;
                        height: 1.6rem;
                        top: 50%;
                        transform: translate(-50%, -50%) rotate(225deg);
                    }
                }
                // ** End of copy **
            }
        }

        #userPhone-error {
            flex-basis: 100%;
        }

        // to handle error message display
        ##{$css-namespace}form-field-wrapper_user-civility-wrapper {
            display: flex;
            flex-direction: column-reverse;
        }

        .field-user-civility {
            .#{$css-namespace}form-field-wrapper {
                margin-right: 2rem !important;
            }
        }

        .#{$css-namespace}form-radio-group {
            margin-bottom: 1.7rem;
            display: flex;

            .#{$css-namespace}form-radio-group-inner {
                display: flex;
            }

            &.column {
                flex-direction: column;
            }

            label {
                margin-bottom: 0 !important;

                &[for='amountFrom'] {
                    padding-right: 2rem;
                }
            }

            &.field-user-role {
                margin-bottom: 0;
                width: 100%;

                .#{$css-namespace}form-field-wrapper {
                    margin-right: 0 !important;
                    margin-bottom: 2rem;

                    @include min-screen($screen__s) {
                        margin-right: 2rem !important;
                        margin-bottom: 0;
                    }

                    input[type='radio']:checked ~ .#{$css-namespace}collaborator_role_label {
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }

        #collaborator-role-modal-link {
            display: flex;
            justify-content: flex-end;

            @include min-screen($screen__l) {
                justify-content: flex-start;
            }
        }

        #collaborator-remove-modal-link {
            border-color: $color-main-dark;
        }

        .#{$css-namespace}collaborator_expander_header--title:hover {
            color: $color-secondary;
        }

        &-actions {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;

            @include min-screen($screen__l) {
                flex-direction: row;
            }

            .#{$css-namespace}btn {
                // TODO : Refacto needed
                margin-right: 0;
                width: 100%;
                margin-bottom: 2rem;

                &[data-ui-btn-skin='secondary']:not([disabled]):not([aria-disabled='true']):not(
                        [data-ui-btn-status='disabled']
                    ).back {
                    border-color: $color-primary;
                    color: $color-primary;
                }

                &.remove {
                    background-color: $color-main-dark !important;
                }

                @include min-screen($screen__m) {
                    width: 48%;
                }

                @include min-screen($screen__l) {
                    margin-bottom: 0;
                    width: 25rem;
                    margin-right: 2rem;
                }
            }
        }

        .mage-error-wrapper {
            .label {
                color: $color-red !important;
            }
        }
    }

    &_inputs_wrapper {
        margin-left: 0;
        display: flex;
        position: relative;
        flex-direction: column;

        @include min-screen($screen__l) {
            flex-direction: row;
        }

        &.column {
            flex-direction: column;
        }

        .tags {
            padding: 0;
            margin-top: 0;
            max-width: 50rem;

            @include min-screen($screen__l) {
                margin-top: $space-1 / 2;
                padding: 0 2rem;
            }

            .#{$css-namespace}modal-popup & {
                padding: 0;
                max-width: 100%;

                &:empty {
                    display: none;
                }
            }

            .tag {
                margin-right: 1rem;
                margin-top: 2rem;
                margin-bottom: 0;
                user-select: none;
                display: inline-flex;
                align-items: center;

                @include min-screen($screen__l) {
                    margin-top: 0;
                    margin-bottom: 2rem;
                }

                .dcfonts-icons--cross {
                    color: $color-secondary;
                }

                &:hover {
                    .dcfonts-icons--cross {
                        color: $color-white;
                    }
                }
            }
        }

        @include min-screen($screen__l) {
            margin-left: $space-5;

            .#{$css-namespace}modal-popup & {
                margin-left: 0;
                flex-direction: column;
                gap: $space-2;
            }

            .#{$css-namespace}form-field-wrapper {
                margin-right: 2rem;
            }
        }

        .control--disable {
            .input-disabled {
                border-color: $color-grey-argent !important;
                color: $color-grey-dove !important;
            }

            .label {
                color: $color-grey-dove !important;
            }
        }

        &_amount {
            display: flex;
            align-items: center;
            margin-bottom: 3rem;

            @include min-screen($screen__l) {
                margin-bottom: 2rem;
            }

            .control {
                display: flex;
                flex: 1 0;
                align-items: center;
                width: auto;

                .input-text {
                    position: relative;
                    opacity: 1;
                    width: 13rem;

                    @include min-screen($screen__l) {
                        width: 19rem;
                    }
                }

                .label {
                    padding-left: 0;

                    &::before {
                        content: none;
                    }
                }
            }
        }
    }

    &_from-amount-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1.5rem;

        #approvalflowThreshold,
        [for='approvalflowThreshold'] {
            cursor: auto;
        }

        #approvalflowThreshold-error {
            position: absolute;
            top: 3.5rem;
            left: 0;

            @include min-screen($screen__l) {
                white-space: nowrap;
            }
        }
    }

    &_select {
        &_approbator {
            padding-bottom: 2rem;
            margin-bottom: 2.7rem;
            position: relative;
        }

        &_separator {
            display: flex;
            margin: 1rem -1rem;
            border-top: 0.1rem solid $color-grey-argent;

            @include min-screen($screen__l) {
                margin: 1rem -3rem;
            }
        }

        &_placeholder {
            padding: 1rem 0;

            .useraccount-approvalflow-index & {
                @include max-screen($screen__l) {
                    padding: 0;
                }
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            @include min-screen($screen__l) {
                &:first-child {
                    padding-top: 1rem;
                }
                &:last-child {
                    padding-bottom: 1rem;
                }
            }

            .control {
                margin-top: 2.7rem;

                .useraccount-approvalflow-index & {
                    margin-top: 0;
                }

                @include min-screen($screen__l) {
                    margin-top: 0;
                }
            }

            .#{$css-namespace}input-text {
                padding-left: 4rem !important;

                .useraccount-approvalflow-index & {
                    border-color: inherit;
                }

                ~ label {
                    background: $color-white;
                    width: 80%;
                    height: 1.8rem;
                    cursor: pointer;
                }

                &:not([value='']) {
                    ~ .label {
                        width: auto;
                        height: auto;
                    }
                }

                &:focus ~ .label {
                    width: auto;
                    height: auto;
                }
            }

            .dcfonts-icons--search-bar {
                left: 1.5rem;
                top: 1.3rem;
                position: absolute;
                font-size: $font-size-m;
            }

            .comboTreeItemTitle {
                .comboTreeItemInput {
                    margin-right: 1rem;
                }

                .comboTreeItemInput:checked ~ .comboTreeItemText,
                .comboTreeItemText:hover {
                    color: $color-secondary;
                    font-weight: $font-weight-bold;
                }
            }

            .comboTreeArrowBtn,
            .comboTreeArrowBtn--close {
                height: 4rem;
            }

            .comboTreeDropDownContainer {
                $bottom-corners-radius: 0.3rem;

                border-color: $color-primary;
                padding: 1.5rem 1.5rem;
                border-top-color: $color-disabled-light;
                border-bottom-left-radius: $bottom-corners-radius;
                border-bottom-right-radius: $bottom-corners-radius;

                .useraccount-approvalflow-index & {
                    position: absolute;
                    width: 100%;
                    top: 4rem;
                    z-index: 1;
                }

                .theList {
                    max-height: 18.8rem;
                }

                li {
                    padding-left: 0;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                .#{$css-namespace}btn {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 1.8rem;
                }
            }

            .#{$css-namespace}collaborator_tooltip_span {
                @include min-screen($screen__l) {
                    margin-left: 11.5rem;
                }
            }
        }

        &_title {
            margin-right: 1.7rem;
            min-width: 10.3rem;
            font-weight: $font-weight-bold;
            font-style: normal;
        }
    }

    &_select-title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include min-screen($screen__l) {
            align-items: flex-start;
            margin-top: 1rem;
        }
    }

    &_role {
        &_wrapper {
            border: 0.2rem solid;
            border-radius: 2rem;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;

            &.admin {
                border-color: $color-collaborator-admin;
            }

            &.admin_demo,
            &.manager {
                border-color: $color-collaborator-admin-demo;
            }

            &.buyer {
                border-color: $color-collaborator-buyer;
            }

            &.guest {
                border-color: $color-collaborator-guest;
            }

            &.demo {
                border-color: $color-collaborator-demo;
            }
        }

        &_label {
            text-transform: uppercase;
            margin-bottom: 0 !important;
            text-align: left;
        }
    }

    &_expander {
        &:not(.is-active) {
            .#{$css-namespace}collaborator_expander_content {
                display: none !important;
            }
        }

        &_wrapper_title {
            background-color: transparentize($color-primary, 0.9);
            margin: 0;
            padding: 1rem;
            font-family: $font-secondary;
            font-size: $font-size-l;
            color: $color-grey-dark;
            font-weight: $font-weight-medium;

            @include min-screen($screen__l) {
                padding: 2rem 3rem;
                line-height: 2.5rem;
            }
        }

        &_header {
            padding: 2rem 1rem;
            font-size: $font-size-m;
            font-family: $font-secondary;
            font-weight: $font-weight-medium;
            display: flex;
            align-items: center;
            width: 100%;

            &--title {
                color: $color-grey-dark;
                line-height: 2.1rem;
            }

            &,
            &:hover,
            &:focus {
                background: none;
                border: none;
                color: $color-grey-dark;
            }

            @include min-screen($screen__l) {
                padding: 2rem 3rem;

                &,
                &:hover,
                &:focus {
                    color: $color-secondary;
                }
            }

            .dcfonts-icons {
                margin-right: 2rem;
                font-size: 2.4rem;
                color: $color-secondary;
            }
        }

        &_content {
            padding: 0.5rem;
            border-top: 0.1rem solid #d2d2d2; // TODO: use $color-gray83 instead of hexa
            flex-direction: column;

            @include min-screen($screen__l) {
                padding: 3rem 4.7rem 3rem 7.7rem;
            }

            div:last-of-type {
                border-bottom: none !important;
            }

            &.desktop {
                display: none;

                @include min-screen($screen__l) {
                    display: flex;
                }
            }

            &.mobile {
                display: flex;

                @include min-screen($screen__l) {
                    display: none;
                }
            }
        }
    }

    &_validation_table {
        &_item {
            border-bottom: 0.1rem solid $color-disabled-light;
        }

        &_row {
            padding: 1.65rem 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            @include min-screen($screen__l) {
                justify-content: space-between;
                flex-wrap: nowrap;
            }

            .first-column {
                width: 80%;
            }

            .second-column {
                width: 15%;
                display: flex;
                margin-right: 4rem;
            }

            .third-column {
                width: 5%;
                display: flex;
                justify-content: center;
            }
        }

        &_header {
            font-size: $font-size-s;
            text-transform: uppercase;
            color: #6d6d6d; // TODO : use $color-gray43 var instead of hexa
            font-weight: $font-weight-regular;

            &.fix-width {
                width: 22%;
                margin-right: 1rem;
            }
        }

        &_label {
            font-weight: $font-weight-bold;
            font-size: $font-size-s;
            color: $color-secondary;
            text-decoration: underline;
            width: 100%;
            margin-top: 0.5rem;

            @include min-screen($screen__l) {
                width: auto;
                margin-top: 0;
                font-size: $font-size-default;
            }
        }

        &_role {
            color: $color-grey-dark2;
            text-transform: capitalize;
            font-size: $font-size-s;

            @include min-screen($screen__l) {
                font-size: $font-size-default;
            }
        }

        &_icon {
            display: flex;
            justify-content: center;

            .dcfonts-icons {
                color: #6d6d6d; // TODO : use $color-gray43 var instead of hexa
                font-size: 2.4rem;
            }
        }
    }
}

.#{$css-namespace}modal-collaborator-role-modal {
    .modal-content {
        .#{$css-namespace}collaborator_role_container {
            padding: 2rem 0;
            border-bottom: 0.1rem solid $color-disabled-light;

            &:last-child {
                padding-bottom: 3rem;
                border: 0;

                @include min-screen($screen__l) {
                    padding-bottom: 2.5rem;
                }
            }
        }

        .#{$css-namespace}collaborator_role_wrapper {
            margin-right: 0;
            width: 100%;

            @include min-screen($screen__l) {
                flex: 0 1 19rem;
                margin-right: 2rem;
                padding: 0.5rem 1.3rem;
            }
        }

        .#{$css-namespace}collaborator_role_modal {
            &_header {
                display: flex;
                align-items: center;
                flex-direction: column;

                @include min-screen($screen__l) {
                    flex-direction: row;
                }
            }

            &_description {
                font-size: $font-size-s;
                font-weight: $font-weight-bold;
                text-align: left;
                margin-top: 2rem;
                line-height: 1.4;

                @include min-screen($screen__l) {
                    margin-top: 0;
                    flex: 1;
                }
            }

            &_text {
                text-align: left;
                display: flex;
                font-size: $font-size-s;
                line-height: 1.41;
                margin-top: 1rem;

                &:empty {
                    display: none;
                }
            }
        }
    }
}
