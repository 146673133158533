$banner-tint-info: $color-form-blue-standard !default; // Global to all platforms.
$banner-tint-warning: $color-warning !default;

@mixin alert-banner-color($color) {
    border: 0.1rem solid $color;
    background: rgba($color, 0.1);

    .dcfonts-icons {
        color: $color;
    }
}

%alert-banner-content {
    display: flex;
    align-items: center;
    padding: $space-2;
    gap: $space-2;
    margin: $space-1 0;
    font-size: $font-size-s;
    line-height: 1.42;
    border-radius: 0.3rem;

    p {
        line-height: 1.5;
    }

    .dcfonts-icons {
        font-size: $font-size-xl;
    }
}

.#{$css-namespace}alert-banner {
    @extend %alert-banner-content;

    &--error {
        @include alert-banner-color($color-error);
        @extend %alert-banner-content;
    }

    &--info {
        @include alert-banner-color($banner-tint-info);
        @extend %alert-banner-content;
    }

    &--warning {
        @include alert-banner-color($banner-tint-warning);
        @extend %alert-banner-content;
    }

    &--success {
        @include alert-banner-color($color-success-secondary);
        @extend %alert-banner-content;
    }
}
