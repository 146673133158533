.#{$css-namespace}product-chip {
    $root: &;

    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0.1rem solid $color-grey-dove;
    border-radius: 0.3rem;
    padding: 0.15rem 0.35rem;
    color: $color-grey-dove;
    cursor: default;

    &_icon {
        font-size: 0.9rem;
    }

    &_text {
        font-size: $font-size-xxs;
        font-weight: $font-weight-medium;
    }

    &_icon + &_text {
        margin-left: 0.5rem;
    }

    &_tooltip,
    &_tooltip::after {
        border: none;
    }

    &_tooltip {
        left: auto;
        right: 0;
        margin-top: 1.2rem;
        border-radius: 0.3rem;
        font-size: $font-size-xs;

        @include min-screen($screen__l) {
            left: -0.1rem;
            right: auto;
        }

        &::after {
            left: auto;
            right: 2.5rem;
            margin-top: 0;
            border-radius: 0.2rem;
            width: 1rem;
            height: 1rem;

            @include min-screen($screen__l) {
                left: 2.5rem;
            }
        }
    }

    &--red {
        border-color: $color-red;
        color: $color-red;

        #{$root}_tooltip,
        #{$root}_tooltip::after {
            color: $color-text-default;
            background-color: #ffd9d9; // TODO: Use global var
        }
    }

    &--orange {
        border-color: $color-warning;
        color: $color-warning;

        #{$root}_tooltip,
        #{$root}_tooltip::after {
            color: $color-text-default;
            background-color: #fee8cc; // TODO: Use global var
        }
    }
}
