.#{$css-namespace}pre-header {
    $root: &;
    display: none;

    &--display {
        display: block;
        background-color: rgba($color-secondary, 0.1);

        body.account &,
        & ~ & {
            display: none;
        }

        #{$root}-desktop {
            display: none;

            @include min-screen($screen__l) {
                display: block;
            }
        }

        #{$root}-mobile {
            display: block;

            @include min-screen($screen__l) {
                display: none;
            }
        }
    }

    &-content {
        display: flex;
        flex-grow: 1;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        min-height: 4.5rem;
        padding: $space-1 $space-4 $space-1 $space-2;
        color: $color-white;
        font-family: $font-default;
        font-size: $font-size-default;
        max-width: $screen-xl;
        line-height: 1.9rem;
        background: none;

        @include min-screen($screen__m) {
            padding: $space-1 $space-3;
        }

        &.-login {
            color: $color-main-dark;

            .close {
                color: $color-primary;
            }
        }
    }

    &-value {
        max-width: 100%;
        overflow: hidden;
    }

    &-text {
        white-space: nowrap;
        display: inline-block;

        &.-login {
            white-space: normal;
            font-size: $font-size-s;

            @include min-screen($screen__l) {
                font-size: $font-size-default;
            }
        }
    }

    .close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $space-2;
        background: none;
        color: $color-white;
        border: none;
        padding: 0.3rem;
        font-size: $font-size-xs;

        @include min-screen($screen__l) {
            padding: 0.3rem;
            right: $space-3;
        }
    }

    &_home-page {
        @include max-screen($screen__l) {
            display: block;
        }
    }

    &--animated {
        .#{$css-namespace}pre-header-text {
            animation: var(--pre-header-scroll-time, 10s) cubic-bezier(0.2, 0, 0.86, 1) infinite pre-header-scroll;
        }

        .#{$css-namespace}pre-header-content {
            &:hover {
                .#{$css-namespace}pre-header-text {
                    animation-play-state: paused;
                }
            }
        }
    }

    .#{$css-namespace}link {
        font-weight: $font-weight-bold;
    }
}

@keyframes pre-header-scroll {
    0% {
        transform: translate(0, -0.5rem);
        opacity: 0;
    }
    2%,
    6% {
        transform: translate(0, 0);
        opacity: 1;
    }
    90%,
    98% {
        // marked as wrong by IDE but syntax is correct!
        transform: translate(calc(var(--pre-header-container-width, 0) - 100%), 0);
        opacity: 1;
    }
    100% {
        transform: translate(calc(var(--pre-header-container-width, 0) - 100%), 0.5rem);
        opacity: 0;
    }
}
