@import 'product/product-list';

$section-padding: $space-2;

// override layout implementation
.descours_events-catalogevent-index,
.brands-brand-index {
    .page-main {
        padding: 0 $indent__s;
    }
}

.catalog-category-view:has(.brands-section.container) {
    .brands-section.container {
        margin-top: $section-padding;
    }

    @include screen($screen__m, $screen__l) {
        // Align Footer padding on those specific containers for 4.5% of tablet users
        .columns .column.main {
            padding-left: $section-padding;
            padding-right: $section-padding;
        }
    }

    @include min-screen($screen__m) {
        .brands-section.container {
            // Faking with column and page-main paddings even if the section located in the footer
            padding-left: $section-padding + $section-padding;
            padding-right: $section-padding + $section-padding;
        }
    }
}

.descours_events-catalogevent-index,
.brands-brand-index,
.catalog-category-view,
.catalogsearch-result-index {
    .columns {
        .sidebar-main {
            order: 0;
            padding: 0;
        }

        .column.main {
            padding: 0;

            @include min-screen($screen__l) {
                padding: inherit;
            }
        }
    }

    @include min-screen($screen__xs) {
        .page-main {
            padding: 0 $section-padding;
        }
    }

    @include min-screen($screen__l) {
        .page-main {
            padding: 0;
        }

        .columns {
            display: flex;
            flex-wrap: nowrap;
            padding: 0 $section-padding;

            .sidebar {
                flex: 0 0 28rem;
                float: none;
                display: block;
                width: 28rem;

                &-main {
                    will-change: min-height;

                    .inner-wrapper-sticky {
                        transform: translate3d(0, 0, 0);
                        will-change: position, transform;
                    }
                }
            }

            .column.main {
                flex: 1 1 auto;
                float: none;
            }
        }
    }
}
