$favorite-shop-prompt-padding: $space-2;

.quick-access > .store {
    height: 100%;

    @include min-screen($screen__m) {
        position: relative;
    }

    @include min-screen($screen__l) {
        display: flex;
        align-items: stretch;
        // Visually force 2 lines
        min-height: 4.2rem;
    }

    .store {
        .agency-submenu {
            display: none;
        }

        &.active {
            gap: 0;

            @include max-screen($screen__m) {
                &::before {
                    content: '';
                    position: fixed;
                    top: calc(var(--header-height) - (#{$menu-slider-height} + #{$search-bar-height} + #{$margin-height-static}));
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color-black, 0.55);
                    z-index: 10;

                    .--scrolled & {
                        top: $menu-slider-height + $margin-height-scrolled;
                    }
                }
            }

            .agency-submenu {
                &-container {
                    position: absolute;
                    top: calc(100% - #{$search-bar-height});
                    width: 33.2rem;
                    height: auto;
                    max-height: calc(100vh - var(--header-height) + 6rem);
                    max-height: calc(100dvh - var(--header-height) + 4rem);
                    overflow-x: hidden;
                    border-radius: 0.8rem;
                    z-index: $dropdown-index - 1;
                    left: 50%;
                    transform: translateX(-50%);
                    box-shadow: var(--box-shadow);

                    @include min-screen($screen__m) {
                        top: calc(100% + #{$space-1});
                        left: auto;
                        right: $shift-right;
                        transform: none;
                    }

                    .--scrolled & {
                        @include max-screen($screen__m) {
                            top: calc(100% + #{$space-3});
                        }
                    }
                }

                &-wrapper {
                    background-color: $color-white;
                }

                .agency-container {
                    display: flex;
                    flex-direction: column;
                    gap: $space-2;
                    padding: $space-3;
                }

                .agency-data {
                    &.name,
                    &.subname {
                        font-family: $font-secondary;
                    }

                    &.name {
                        font-size: $font-size-xl;
                        font-weight: $font-weight-semi-bold;
                        line-height: 2.7rem;
                        color: $color-primary;
                    }

                    &.subname {
                        color: $color-secondary;
                        font-size: $font-size-m;
                        font-weight: $font-weight-bold;
                        line-height: 1.7rem;
                        margin-bottom: $space-1 / 2;
                    }
                }

                .telephone {
                    margin-top: 1rem;
                    line-height: 1.9rem;
                }

                .agency-details {
                    padding: 1.5rem 1.8rem;
                    background-color: $color-grey-background;
                    border-radius: 0.3rem;
                }

                .change-shop-button {
                    width: 100%;
                    text-decoration: none;
                    text-transform: none;
                }
            }

            .favorite-shop-prompt {
                display: none;
            }
        }

        &__wrapper {
            height: 100%;
        }

        &__link {
            font-size: $font-size-s;
            font-weight: $font-weight-regular;
            background: none;
            border: 0;
            height: 100%;
            display: flex;
            align-items: center;
            gap: $space-1;
            transition: color var(--transition-normal);

            &:hover,
            &:focus-within {
                background: none;
                border: 0;
                color: $color-primary;
            }

            .closed-in-hour {
                display: none;
            }

            .store-informations {
                display: none;
                text-align: start;

                @include min-screen($screen__l) {
                    display: flex;
                }
            }

            &--logged {
                color: $color-main-dark;
                background: none;
                border: 0;
                text-align: start;
                font-weight: $font-weight-regular;
                padding: 0;

                &.active {
                    color: $color-primary;
                }

                .agency-name {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    line-height: 1.4;
                }
            }

            .dcfonts-icons {
                font-size: $font-size-xl;
            }
        }

        &:has(.favorite-shop-prompt:not(.hidden)) {
            .border-bottom {
                &::after {
                    @include min-screen($screen__m) {
                        content: '';
                        position: absolute;
                        top: calc(100% + #{$space-1});
                        left: 0;
                        width: 100%;
                        height: $border-bottom-active;
                        border-radius: 0.4rem;
                        background-color: $color-secondary;
                        z-index: $dropdown-index;
                        pointer-events: none;
                    }

                    @include min-screen($screen__l) {
                        bottom: -$border-bottom-active / 2;
                    }
                }
            }
        }

        .favorite-shop-prompt {
            display: flex;
            max-width: 73.26vw;
            width: 40.5rem;
            line-height: 1.679;
            top: 100%;
            right: 0.2rem;
            padding: $favorite-shop-prompt-padding $space-3 $favorite-shop-prompt-padding $favorite-shop-prompt-padding;
            z-index: 25;

            @include min-screen($screen__l) {
                top: calc(100% + #{$font-size-s});
                right: $favorite-shop-prompt-padding;
                padding-right: $favorite-shop-prompt-padding;
            }

            &.hidden {
                display: none !important;
            }

            &,
            &::after {
                background-color: $color-tooltip-background;
            }

            a {
                font-weight: $font-weight-bold;
                text-decoration: underline;
                line-height: 1.429;
            }

            button {
                text-decoration: none !important;
                width: 2.6rem;
                height: 3.2rem;
            }

            .dcfonts-icons {
                font-size: $font-size-xs;
                color: $color-dark-grey;
                margin: auto;
            }
        }
    }
}
