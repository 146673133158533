$imageSize: 14.6rem !default;

.#{$css-namespace}bundle {
    margin: $space-2 auto;
    padding: $space-2;
    border-radius: 1rem;
    background-color: $color-white;
    width: 100%;

    @include min-screen($screen__m) {
        max-width: 103.6rem;
        padding: $space-4 $space-7 $space-3;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: $space-3;

        @include min-screen($screen-md) {
            flex-flow: row wrap;
            margin-top: $space-2;
        }
    }

    .#{$css-namespace}title[data-ui-title-level='2'] {
        color: $color-secondary;
        line-height: 1.2;
        font-size: $font-size-xxl;
        font-weight: $font-weight-bold;
    }

    &__products {
        max-width: 100%;
    }

    &__images {
        display: flex;
        align-items: center;
        justify-content: center;

        @include min-screen($screen-md) {
            justify-content: flex-start;
            flex: 1;
            order: 1;
            gap: $space-4;
        }
    }

    &__image {
        display: flex;
        align-items: center;
        max-width: $imageSize;
        max-height: $imageSize;

        @include min-screen($screen-md) {
            max-width: none;
            width: $imageSize;
            height: $imageSize;
        }

        &__link {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        .product-image-photo {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__plus {
        color: $color-primary;

        @include min-screen($screen-md) {
            font-size: $space-4;
        }
    }

    &__selector {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        @include min-screen($screen-md) {
            order: 3;
        }
    }

    &__product-select {
        display: flex;
        cursor: pointer;
        padding-bottom: 2rem;
        border-bottom: 0.1rem solid $color-blue-light;
        align-items: center;

        @include min-screen($screen-md) {
            padding-bottom: 0;
            border-bottom: none;
        }

        input {
            width: $space-2;
            height: $space-2;
            margin: 0 $space-2 0 0;
            vertical-align: middle;
        }

        .badge {
            margin-right: $space-1;
            border: solid 0.1rem $color-secondary;
            color: $color-secondary;
            background-color: $color-white;
            padding: 0.2rem $space-1;
            font-size: $font-size-s;
            border-radius: 0.3rem;
            line-height: (18 / 12);
            white-space: nowrap;
            min-height: $space-2;
            font-weight: $font-weight-semi-bold;
        }

        .brand {
            color: $brand-text-color;
            font-weight: $brand-text-weight;
            text-transform: $brand-text-case;
        }

        .name {
            margin-right: $space-2;
        }

        .price {
            font-weight: $font-weight-bold;
            color: $text-price-color;
            line-height: 1;

            @include min-screen($screen-md) {
                display: inline;
            }

            span {
                font-weight: $font-weight-regular;
                color: $text-price-unit-color;

                &.price {
                    font-weight: inherit;
                    color: inherit;
                    margin-right: $space-1 / 2;
                }
            }
        }

        &__label {
            display: inline;
            align-items: center;
            width: 100%;

            @include min-screen($screen-md) {
                display: inline-flex;
            }
        }

        &__name {
            @include min-screen($screen-md) {
                line-height: 1;
            }
        }

        &__old-price {
            margin-right: $space-2;
            color: $color-dark-grey;
            text-decoration: line-through;
        }

        &__price {
            display: inline-flex;

            .price {
                white-space: nowrap;

                &.-promo {
                    color: $color-red-promo;

                    .-disconnected & {
                        color: $text-price-color;
                    }

                    span {
                        color: $color-red-promo;

                        .-disconnected & {
                            color: $text-price-color;
                        }
                    }
                }
            }
        }
    }

    &__total {
        width: 100%;
        margin-top: $space-2;

        @include min-screen($screen-md) {
            width: 50%;
            max-width: 26.8rem;
            order: 2;
            margin-left: auto;
            margin-top: 0;
        }
    }

    &__price {
        font-weight: $font-weight-bold;
        display: flex;
        align-items: end;
        margin-bottom: $space-2;
        justify-content: flex-end;
        gap: $space-1;

        &-label {
            font-size: $font-size-m;
        }

        &-value {
            color: $text-price-color;
            font-size: 2.2rem;
            line-height: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .has_promo {
                color: $color-red-promo;
            }

            .vat {
                font-weight: $font-weight-regular;
            }
        }

        &-withoutDiscount {
            color: $color-black;
            font-size: $font-size-m;
            font-weight: $font-weight-regular;
            text-decoration-line: line-through;
            margin-bottom: $space-1;
            min-height: 1.6rem;
        }
    }

    &__action {
        display: flex;
        justify-content: flex-end;

        .#{$css-namespace}btn { // TODO : Refacto needed using w-full
            width: 100%;
        }

        .#{$css-namespace}link {
            font-weight: $font-weight-bold;
            text-decoration: underline;
            color: $color-secondary;
        }
    }

    [data-state='disabled'] {
        input,
        &.product-image-photo {
            opacity: 0.5;
        }
    }
}
