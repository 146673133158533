// variables
$button-color-background: $color-main-dark;
$button-color-text: $color-main-light;
$button-color-border: $color-main-dark;

$button-hover-color-background: $color-main-light;
$button-hover-color-text: $color-main-dark;
$button-hover-color-border: currentColor;

$button-disabled-color-background: $color-disabled-light;
$button-disabled-color-text: $color-disabled-dark;
$button-disabled-color-border: $color-disabled-light;

$button-padding: 1rem $space-2;
$button-font-family: $font-default;
$button-font-size: $font-size-default;
$button-line-height: (20/16);
$button-icon-size: $font-size-xl;
$button-min-height: 4rem;
$button-border-radius: 0.3rem;

$button-m-padding: $offset-s $offset-m;
$button-m-font-size: $font-size-default;
$button-m-icon-size: $font-size-m;
$button-m-font-weight: $font-weight-medium;

$button-sm-padding: $offset-s $offset-m;
$button-sm-font-size: $font-size-s;
$button-sm-icon-size: $font-size-m;

// abstract button
%button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: $space-1;
    padding: $button-padding;
    border: 0.1rem solid $button-color-border;
    min-height: $button-min-height;
    font-family: $button-font-family;
    font-size: $button-font-size;
    line-height: $button-line-height;
    background-color: $button-color-background;
    color: $button-color-text;
    cursor: pointer;
    border-radius: $button-border-radius;
    font-weight: $font-weight-medium;

    // svg icon
    > .#{$css-namespace}icon {
        flex: 0 0 auto;
        font-size: $button-icon-size;
        width: $button-icon-size;
        height: $button-icon-size;
        color: inherit;
        fill: currentColor;
    }

    > .dcfonts-icons {
        font-size: $font-size-l;
    }

    > span {
        text-align: center;

        + .#{$css-namespace}icon {
            margin-left: $offset-s;
        }
    }

    &:hover,
    &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
    &:active,
    &[data-ui-btn-status='selected'] {
        border-color: $button-hover-color-border;
        text-decoration: none;
        background-color: $button-hover-color-background;
        color: $button-hover-color-text;
    }

    &[disabled],
    &[aria-disabled='true'],
    &[data-ui-btn-status='disabled'] {
        border-color: $color-disabled;
        background-color: $color-disabled;
        color: $disabledTextColor;

        opacity: 1;
        cursor: not-allowed;
    }

    // alignments
    &[data-ui-btn-align='center'] {
        // Todo refacto to rebase as it is the default alignment
        justify-content: center;
    }

    // skin declination
    // primary button
    &[data-ui-btn-skin='primary'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-primary;
            background-color: $color-primary;
            color: $color-main-light;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                border-color: $color-primary-darken;
                background-color: $color-primary-darken;
            }
        }
    }

    // secondary button
    &[data-ui-btn-skin='secondary'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-secondary;
            background-color: $color-main-light;
            color: $color-secondary;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                border-color: $color-primary-darken;
                color: $color-primary-darken;
            }
        }
    }

    // skin declination
    // transparent button
    &[data-ui-btn-skin='transparent'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            color: $color-secondary;
            font-weight: $font-weight__regular;
            text-decoration: underline;
            background-color: transparent;
            border: none;
            padding: 0;
            min-height: initial;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                border: none;
                background-color: transparent;
            }
        }
    }

    // accent button
    &[data-ui-btn-skin='accent'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-accent;
            background-color: $color-accent;
            color: $color-main-light;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                border-color: $color-accent-darken;
                background-color: $color-accent-darken;
            }
        }
    }

    // specific button
    &[data-ui-btn-skin='primary-alt'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-secondary;
            background-color: $color-secondary;
            color: $color-main-light;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                border-color: $color-secondary-darken;
                background-color: $color-secondary-darken;
            }
        }
    }

    // specific button
    &[data-ui-btn-skin='secondary-alt'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-primary;
            background-color: $color-white;
            color: $color-primary;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                border-color: $color-secondary;
                color: $color-secondary;
            }
        }
    }

    // black button
    &[data-ui-btn-skin='black'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-black;
            background-color: $color-main-dark;
            color: $color-white;
            fill: $color-white;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                background-color: $color-white;
                color: $color-black;
                fill: $color-black;
            }
        }
    }

    // validate generic green button
    &[data-ui-btn-skin='validate'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-success-secondary;
            background-color: $color-success-secondary;
            color: $color-white;
            fill: $color-white;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                background-color: $color-white;
                color: $color-success-secondary;
                fill: $color-success-secondary;
            }
        }
    }

    // refuse generic red button
    &[data-ui-btn-skin='refuse'] {
        &:not([disabled]):not([aria-disabled='true']):not([data-ui-btn-status='disabled']) {
            border-color: $color-error-link;
            background-color: $color-error-link;
            color: $color-white;
            fill: $color-white;

            &:hover,
            &:focus, // TODO : Refacto accesibility using outline and its data-ui-btn-status="focus" state
            &:active,
            &[data-ui-btn-status='selected'] {
                background-color: $color-white;
                color: $color-error-link;
                fill: $color-error-link;
            }
        }
    }

    // size declination
    &[data-ui-btn-size='m'] {
        padding: $button-m-padding;
        font-size: $button-sm-font-size;
        font-weight: $button-m-font-weight;

        @include min-screen($screen-md) {
            font-size: $button-m-font-size;
        }

        > .#{$css-namespace}icon {
            width: $button-m-icon-size;
            height: $button-m-icon-size;
        }
    }

    &[data-ui-btn-size='sm'] {
        padding: $button-sm-padding;
        font-size: $button-sm-font-size;

        > .#{$css-namespace}icon {
            width: $button-sm-icon-size;
            height: $button-sm-icon-size;
        }
    }

    &[data-ui-btn-bg='none'] {
        background-color: transparent !important;
    }
}

// import all components
@import 'button/button';
