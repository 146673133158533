.#{$css-namespace}summary_payment {
    display: flex;
    flex-direction: column;
    gap: $space-2;
    padding: $space-2;

    @include min-screen($screen__l) {
        padding: $space-3;
    }

    &-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $space-2;

        @include min-screen($screen__s) {
            gap: $space-3;
        }
    }

    &-item {
        display: flex;
        font-size: $font-size-default;
        font-weight: $font-weight-bold;

        img {
            max-width: 3.5rem;
        }

        .dcfonts-icons {
            font-size: 3rem;
            color: $color-blue-dark;
        }
    }

    &-sub_block {
        font-size: $font-size-s;
        line-height: (20 / 14);
    }
}
