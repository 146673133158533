.#{$css-namespace}quotation {
    #downloadQuotePopinContent {
        display: none;
    }

    &_section {
        &_title {
            margin: 0.5rem 0 0;

            @include min-screen($screen__l) {
                margin: 0 0 0.74rem;
            }

            .#{$css-namespace}link {
                color: $color-black;
                text-decoration: none;

                &-back {
                    display: block;
                    margin-bottom: 0;
                }
            }

            .#{$css-namespace}title {
                display: flex !important;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 2rem !important;
                margin-bottom: 1rem !important;
                line-height: 3.75rem !important;

                @include min-screen($screen__l) {
                    flex-direction: row;
                    line-height: 6.9rem !important;
                    margin-top: 1.52rem !important;
                    margin-bottom: -$space-1 / 2 !important;
                }
            }

            .#{$css-namespace}status {
                display: inline-block;
                padding: 0.6rem 1rem 0.5rem;
                border: 0.2rem solid $color-success-secondary;
                border-radius: 1.6rem;
                text-transform: uppercase;
                font-size: $font-size-s;
                margin: 1rem 0 0;
                color: $color-black;
                white-space: nowrap;
                font-weight: $font-weight__regular;
                font-family: $font-family-name__base;
                line-height: 1.7rem;

                @include min-screen($screen__l) {
                    margin: 1.5rem 0 0 2rem;
                }

                &.refusedquotation,
                &.deleted {
                    color: $color-black;
                    border: 0.2rem solid $color-red9;
                }

                &.partiallyvalidated {
                    color: $color-black;
                    border: 0.2rem solid transparentize($color-success-secondary, 0.5);
                }

                &.tovalidate {
                    color: $color-white;
                    border: 0.2rem solid $color-orange2;
                    background-color: $color-orange2;
                }

                &.validated {
                    color: $color-black;
                    border: 0.2rem solid $color-dark-green1;
                }

                &.timeexceeded {
                    color: $color-gray19;
                    border: 0.2rem solid $color-gray83;
                }
            }
        }

        &_quick-links {
            margin: 2rem 0;

            .#{$css-namespace}form-radio-group {
                display: flex;
                @include max-screen($screen__m) {
                    display: flex;
                    flex-direction: column;
                }
            }

            .quotation-quick-link {
                margin-bottom: 2rem;
                padding: 0;

                @include min-screen($screen__m) {
                    padding: 0 1.5rem;
                    border-right: 0.1rem solid $color-gray64;
                    margin-bottom: 0;
                }

                &:first-child {
                    @include min-screen($screen__m) {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    border: none;
                    margin-bottom: 0;
                }

                &--wording {
                    &:active {
                        color: $color-secondary;
                        text-decoration: underline;
                    }

                    &-mobile {
                        text-align: center;
                        margin-top: 2rem;
                        display: none;

                        @include max-screen($screen__s) {
                            display: block;
                        }
                    }
                }

                &--icon-container {
                    margin-right: 0.5rem;
                    display: flex;
                    align-items: center;
                }

                &-content {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        color: $color-sub-secondary;
                    }
                }
            }

            .dcfonts-icons {
                color: $color-secondary;
                font-size: 1.7rem;

                &--edit {
                    transform: scale(1.4);
                }
            }
        }

        &_information {
            background-color: $color-blue-background-1;
            padding: 2rem;

            @include min-screen($screen__l) {
                padding: 2.6rem 3rem 2rem;
            }

            .#{$css-namespace}title {
                line-height: 2.7rem;
            }

            &_content {
                display: flex;
                font-size: $font-size-default;

                @include max-screen($screen__l) {
                    flex-direction: column;
                }
            }

            &_left {
                display: flex;
                flex-direction: column;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
            }

            &_right {
                display: flex;
                flex-direction: column;
                margin-left: auto;
                order: 2;
                justify-content: center;

                @include max-screen($screen__l) {
                    margin-left: inherit;
                    margin-top: 2rem;
                }

                .action.primary {
                    padding: 1.2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    @include min-screen($screen__l) {
                        min-width: 24.7rem;
                        width: auto;
                    }
                }

                .#{$css-namespace}btn {
                    // TODO : Refacto needed
                    padding: 1.2rem 2.6rem;
                    font-weight: $font-weight__regular;

                    @include min-screen($screen__l) {
                        min-width: 24.7rem; // TODO : Refacto needed using var ?
                        width: auto;
                    }

                    + .#{$css-namespace}btn {
                        margin-top: 1rem;
                    }
                }

                a {
                    &.validate,
                    &.question,
                    &.refuse {
                        span {
                            text-decoration: none;
                        }
                    }

                    &.validate {
                        font-weight: $font-weight-regular;
                        border: none;

                        @include lib-hover-focus {
                            background-color: $color-success-secondary;
                            border: none;
                            cursor: pointer;
                        }

                        &:not([disabled]) {
                            background-color: $color-success-secondary;
                        }
                    }

                    &.question {
                        background-color: $color-white;
                        border: 0.1rem solid $color-primary-darken;
                        color: $color-primary-darken;
                        font-weight: $font-weight-medium;

                        &:hover {
                            background-color: $color-primary-darken;
                            color: $color-white;
                            cursor: pointer;
                        }
                    }

                    &.refuse {
                        background-color: $color-white;
                        border: 0.1rem solid $color-red;
                        color: $color-red;
                        font-weight: $font-weight-medium;

                        @include lib-hover-focus {
                            background-color: $color-red;
                            color: $color-white;
                            border: 0.1rem solid $color-red;
                            cursor: pointer;
                        }
                    }
                }
            }

            &_sub_section {
                &_content {
                    margin-top: 1.7rem;
                }

                &_entry {
                    @include min-screen($screen__l) {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                    }

                    &_left,
                    &_right {
                        line-height: 2.2rem;
                    }

                    &_left {
                        @include min-screen($screen__l) {
                            display: flex;
                            width: 20rem;
                        }

                        .dot-separator {
                            white-space: pre;

                            @include min-screen($screen__l) {
                                display: none;
                            }
                        }
                    }

                    &_right {
                        color: $color-primary;
                        font-weight: $font-weight__semibold;

                        .status {
                            &.refusedquotation,
                            &.deleted {
                                color: $color-red9;
                            }

                            &.partiallyvalidated {
                                color: transparentize($color-success-secondary, 0.5);
                            }

                            &.tovalidate {
                                color: $color-orange2;
                            }

                            &.validated {
                                color: $color-dark-green1;
                            }

                            &.timeexceeded {
                                color: $color-gray19;
                            }
                        }
                    }
                }
            }
        }

        &_products-table {
            margin-top: 3rem;

            .product-name {
                font-weight: $font-weight-black;
            }

            .#{$css-namespace}item-table_row {
                &_comment {
                    padding: 2rem;
                    background-color: $color-grey-background;

                    @include min-screen($screen-sm) {
                        padding: 1.62rem 0 1.98rem 0;
                        height: 7.7rem;
                    }

                    td {
                        @include max-screen($screen-sm) {
                            padding: 0 !important;
                        }
                    }

                    &_title {
                        line-height: 2.2rem;
                        color: $color-secondary;
                        font-weight: $font-weight__bold;
                        text-transform: uppercase;
                    }

                    &_title--mobile {
                        margin-left: 1rem;
                        @include min-screen($screen-sm) {
                            display: none;
                        }
                    }

                    &_title--desktop {
                        display: none;
                        @include min-screen($screen-sm) {
                            margin: 0;
                            display: block;
                        }
                    }

                    &_text {
                        line-height: 1.9rem;
                        margin: 0;
                    }

                    &_content {
                        @include min-screen($screen-md) {
                            width: 60.2rem;
                        }
                    }

                    .#{$css-namespace}toggle_text {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .dcfonts-icons {
                        font-size: 2.4rem;
                        line-height: 2.1rem;
                        color: $color-primary;
                    }

                    .#{$css-namespace}btn_toggle {
                        margin-top: 1rem;

                        @include min-screen($screen-sm) {
                            margin-top: 0;
                        }

                        &.show {
                            @include min-screen($screen-sm) {
                                display: inline;
                            }
                        }
                    }

                    .#{$css-namespace}item-table_row {
                        &-picto {
                            display: table-cell;
                        }

                        &-details {
                            max-width: 100%;
                        }
                    }

                    .show {
                        display: block;
                    }
                }
            }
        }

        &_footer {
            margin-top: 2.62rem !important;

            @include min-screen($screen__l) {
                margin-top: 3rem !important;
            }

            .#{$css-namespace}order {
                &-address {
                    &-container {
                        margin: 0;
                    }

                    &-area_title {
                        color: $color-primary;
                    }
                }

                &-total-container {
                    text-align: left;
                    line-height: 2.7rem;
                    margin-bottom: 2.68rem;

                    @include min-screen($screen__l) {
                        text-align: right;
                        margin-top: -1.1rem;
                        line-height: 4.1rem;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .#{$css-namespace}title[data-ui-title-level='3'] {
        font-weight: $font-weight__semibold;
        font-style: italic;
        font-size: $font-size-xl;
    }

    .#{$css-namespace}title[data-ui-title-level='1'] {
        margin-bottom: 2rem;
        margin-top: 3rem;
        line-height: 0.78;
        display: inline-block;
        position: relative;

        @include min-screen($screen__l) {
            margin-bottom: 1.5rem;
        }
    }

    .#{$css-namespace}subtitle {
        margin-bottom: 2rem;
        display: flex;
        line-height: 2.2rem;

        &_total {
            line-height: 2.2rem;
        }
    }
}

#modal-refuse-block,
#modal-share-block {
    .#{$css-namespace}p {
        margin: 1rem 0;
        text-align: left;
    }

    .#{$css-namespace}textarea {
        min-width: inherit;
    }

    .field {
        margin-bottom: 1rem;
    }

    #box-refuseQuotation,
    #box-collabsQuotation {
        width: 100%;

        > input {
            width: 100%;
            padding: 1.1rem 1.5rem 1rem !important;
        }

        > label {
            text-align: left;
        }
    }
}

#modal-refuse-block {
    .#{$css-namespace}textarea {
        &_text {
            &:focus ~ label,
            &:not([value='']) ~ label,
            &:not([value]) ~ label,
            &:not(:empty) ~ label {
                font-size: $font-size-xs-l;
            }
        }

        &_label {
            text-align: left;
        }
    }
}

#modal-share-block {
    .#{$css-namespace}collaborator_inputs_wrapper {
        flex-direction: column;
        margin-left: 0;
        margin-bottom: 0;
        padding: 0;
    }

    #share-form {
        .#{$css-namespace}p {
            margin: 0;
        }
    }

    .tags {
        margin: 1rem 0;
        padding: 0;
    }

    .checkbox-content {
        margin: 1rem 0;
        text-align: left;
        display: flex;
        align-items: center;

        .checkbox {
            margin: 1rem 1rem 1rem 0;
        }

        .title-checkbox {
            &:hover {
                cursor: pointer;
            }

            &.checked {
                color: $color-secondary;
                font-weight: $font-weight__heavier;
            }
        }
    }

    .info-content {
        display: none;
    }

    .fieldset {
        display: flex;
        margin-bottom: 2.5rem;

        .#{$css-namespace}form-field-wrapper {
            width: 100%;
            margin-left: 1rem;

            &:first-child {
                margin: 0;
            }
        }

        .control {
            width: 100%;

            .#{$css-namespace}input-text {
                width: 100%;
            }
        }
    }
}

.#{$css-namespace}modal_sales-note {
    width: 100%;
    padding-bottom: 1.5rem;

    &_message {
        border: 0.1rem solid $color-gray43;
        border-radius: 0.2rem;
        padding: $space-2 1.5rem;
        color: $color-gray43;
        margin-top: 1rem;
        min-height: 16.878rem;

        @include min-screen($screen__l) {
            margin-top: 3rem;
        }

        &_list {
            list-style: none;
            margin: 0;
            padding: 0;

            li:last-child {
                margin-bottom: 0;
            }
        }
    }
}
