//Overrides default Magento style with this first class
.wishlist.split.button {
    .#{$css-namespace}wishlist-button {
        &.added {
            .dcfonts-icons {
                &::after {
                    position: static;
                    color: $color-primary;
                }
            }
        }

        .dcfonts-icons {
            color: $color-primary;
            font-size: 2rem;

            .#{$css-namespace}product_slot & {
                font-size: 2.4rem;
            }

            &::before {
                z-index: 1;
            }
        }
    }

    .items {
        margin-top: $space-1;
        font-size: $font-size-s;
        z-index: 1;

        .item {
            padding: 0;

            .action {
                display: block;
                padding: 0.5rem 0.5rem 0.5rem 2.2rem;
            }
        }
    }
}
