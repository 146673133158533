@import 'module/topmenu';
@import 'module/submenu';

$levelWidth: 33.5rem;
$levelTop: 4.8rem;
$levelHeight: 4.2rem;

.navigation {
    ul {
        padding: 0;
        margin: 0;
    }
}

@include min-screen($screen__l) {
    .ui-menu-item-back {
        display: none;
    }

    .all-category {
        display: none;
    }

    html {
        &.nav-open-desktop {
            body {
                &::before {
                    background: #001020; // TODO: Use global var
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    content: '  ';
                    z-index: 123;
                    opacity: 0.7;
                }
            }

            .level0.submenu {
                display: block;
            }

            .page-header,
            .sections.nav-sections {
                position: relative;
                z-index: 123;
            }

            .nav-sections-item-content {
                box-shadow: none;
            }

            .page-footer {
                position: relative;
                z-index: -1;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-css-box-shadow();
    }
}
