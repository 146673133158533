.useraccount-store-index {
    .box-content {
        .#{$css-namespace}agency-card-schedules {
            .#{$css-namespace}title,
            .#{$css-namespace}open_icon,
            .#{$css-namespace}current-open {
                display: none;
            }
        }
    }
}

.customer-account-index {
    .box-newsletter .box-content p {
        margin-bottom: 0;
    }
}

.#{$css-namespace}current-open {
    font-size: $font-size-default;
    margin-left: $space-1 / 2;
    font-weight: $font-weight-regular;
}

.#{$css-namespace}agency-card-schedules {
    min-width: 30rem;
    line-height: 2.2rem;

    .#{$css-namespace}title {
        margin-bottom: $space-1 !important;

        @include max-screen($screen__l) {
            margin-bottom: 1rem !important;
        }
    }

    @include max-screen($screen__m) {
        width: 100%;
        min-width: 28rem;
    }

    dd {
        margin-top: $space-1;
    }

    .#{$css-namespace}day {
        text-transform: capitalize;
        text-align: left;
    }

    .#{$css-namespace}schedules {
        text-transform: capitalize;
        text-align: right;
        float: right;
    }

    .#{$css-namespace}day,
    .#{$css-namespace}schedules {
        &.active {
            color: $color-success-secondary;
            font-weight: $font-weight-bold;
        }
    }
}
