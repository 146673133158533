/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */

.air-datepicker {
    background: var(--adp-background-color);
    border: 0.1rem solid var(--adp-border-color);
    box-shadow: 0 #{$space-1 / 2} 1.2rem rgba($color-black, 0.15);
    border-radius: var(--adp-border-radius);
    box-sizing: content-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-template-areas: var(--adp-grid-areas);
    font-size: var(--adp-font-size);
    color: var(--adp-color);
    width: var(--adp-width);
    position: absolute;
    transition:
        opacity var(--adp-transition-duration) var(--adp-transition-ease),
        transform var(--adp-transition-duration) var(--adp-transition-ease);
    z-index: var(--adp-z-index);

    &:not(.-custom-position-) {
        opacity: 0;
    }

    &.-from-top- {
        transform: translateY(calc(var(--adp-transition-offset) * -1));
    }

    &.-from-right- {
        transform: translateX(var(--adp-transition-offset));
    }

    &.-from-bottom- {
        transform: translateY(var(--adp-transition-offset));
    }

    &.-from-left- {
        transform: translateX(calc(var(--adp-transition-offset) * -1));
    }

    &.-active- {
        &:not(.-custom-position-) {
            transform: translate(0, 0);
            opacity: 1;
        }

        &.-custom-position- {
            transition: none;
        }
    }

    &.-inline- {
        border-color: var(--adp-border-color-inline);
        box-shadow: none;
        position: static;
        left: auto;
        right: auto;
        opacity: 1;
        transform: none;
        margin: auto;

        .air-datepicker--pointer {
            display: none;
        }
    }

    &.-is-mobile- {
        --adp-font-size: var(--adp-mobile-font-size);
        --adp-day-cell-height: var(--adp-mobile-day-cell-height);
        --adp-month-cell-height: var(--adp-mobile-month-cell-height);
        --adp-year-cell-height: var(--adp-mobile-year-cell-height);
        --adp-nav-height: var(--adp-mobile-nav-height);
        --adp-nav-action-size: var(--adp-mobile-nav-height);

        position: fixed;
        width: var(--adp-mobile-width);
        border: none;

        * {
            -webkit-tap-highlight-color: transparent;
        }

        .air-datepicker--pointer {
            display: none;
        }

        &:not(.-custom-position-) {
            transform: translate(-50%, calc(-50% + var(--adp-transition-offset)));
        }

        &.-active- {
            &:not(.-custom-position-) {
                transform: translate(-50%, -50%);
            }
        }
    }

    &.-custom-position- {
        transition: none;
    }
}

.air-datepicker-global-container {
    position: absolute;
    left: 0;
    top: 0;
}

.air-datepicker--pointer {
    --pointer-half-size: calc(var(--adp-pointer-size) / 2);

    position: absolute;
    width: var(--adp-pointer-size);
    height: var(--adp-pointer-size);
    z-index: -1;
    background: transparent;

    &::after {
        content: '';
        position: absolute;
        background: $color-white;
        border-top: 0.1rem solid var(--adp-border-color-inline);
        border-right: 0.1rem solid var(--adp-border-color-inline);
        border-top-right-radius: var(--adp-poiner-border-radius);
        width: var(--adp-pointer-size);
        height: var(--adp-pointer-size);
        box-sizing: border-box;
    }

    //  Main axis
    // -------------------------

    .-top-left- &,
    .-top-center- &,
    .-top-right- &,
    [data-popper-placement^='top'] & {
        top: calc(100% - var(--pointer-half-size) + 0.1rem);

        &::after {
            transform: rotate(135deg);
        }
    }

    .-right-top- &,
    .-right-center- &,
    .-right-bottom- &,
    [data-popper-placement^='right'] & {
        right: calc(100% - var(--pointer-half-size) + 0.1rem);

        &::after {
            transform: rotate(225deg);
        }
    }

    .-bottom-left- &,
    .-bottom-center- &,
    .-bottom-right- &,
    [data-popper-placement^='bottom'] & {
        bottom: calc(100% - var(--pointer-half-size) + 0.1rem);

        &::after {
            transform: rotate(315deg);
        }
    }

    .-left-top- &,
    .-left-center- &,
    .-left-bottom- &,
    [data-popper-placement^='left'] & {
        left: calc(100% - var(--pointer-half-size) + 0.1rem);

        &::after {
            transform: rotate(45deg);
        }
    }

    //  Secondary axis
    // -------------------------

    .-top-left- &,
    .-bottom-left- & {
        left: var(--adp-pointer-offset);
    }

    .-top-right- &,
    .-bottom-right- & {
        right: var(--adp-pointer-offset);
    }

    .-top-center- &,
    .-bottom-center- & {
        left: calc(50% - var(--adp-pointer-size) / 2);
    }

    .-left-top- &,
    .-right-top- & {
        top: var(--adp-pointer-offset);
    }

    .-left-bottom- &,
    .-right-bottom- & {
        bottom: var(--adp-pointer-offset);
    }

    .-left-center- &,
    .-right-center- & {
        top: calc(50% - var(--adp-pointer-size) / 2);
    }
}

.air-datepicker--navigation {
    grid-area: nav;
}

.air-datepicker--content {
    box-sizing: content-box;
    padding: var(--adp-padding);
    grid-area: body;

    .-only-timepicker- & {
        display: none;
    }
}

.air-datepicker--time {
    grid-area: timepicker;
}

.air-datepicker--buttons {
    grid-area: buttons;
}

.air-datepicker--buttons,
.air-datepicker--time {
    padding: var(--adp-padding);
    border-top: 0.1rem solid var(--adp-border-color-inner);
}

/*  Overlay
   ------------------------------------------------- */

.air-datepicker-overlay {
    position: fixed;
    background: var(--adp-overlay-background-color);
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    transition:
        opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease),
        left 0s,
        height 0s,
        width 0s;
    transition-delay: 0s, var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration),
        var(--adp-overlay-transition-duration);
    z-index: var(--adp-overlay-z-index);

    &.-active- {
        opacity: 1;
        width: 100%;
        height: 100%;

        transition:
            opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease),
            height 0s,
            width 0s;
    }
}
