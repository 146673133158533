.#{$css-namespace}availability {
    $root: &;
    display: flex;
    align-items: baseline;

    &-label {
        .wishlist-index-index &,
        .useraccount-quickorder-index & {
            display: none !important;
        }
    }

    &__text {
        &--bold {
            font-weight: $font-weight-semi-bold;
        }
    }

    > &__text {
        font-size: $font-size-default;
        line-height: 1;
    }

    [data-availability='green'],
    &[data-availability='green'],
    &__icon[data-availability='green'] {
        color: $color-availability-available;

        .#{$css-namespace}round_light {
            background-color: $color-availability-available;
        }
    }

    [data-availability='orange'],
    &[data-availability='orange'],
    &__icon[data-availability='orange'] {
        color: $color-availability-low;

        .#{$css-namespace}round_light {
            background-color: $color-availability-low;
        }
    }

    [data-color='red'],
    [data-availability='red'],
    &[data-availability='red'],
    &__icon[data-availability='red'] {
        color: $color-availability-unavailable;

        .#{$css-namespace}round_light {
            background-color: $color-availability-unavailable;
        }
    }

    > .dcfonts-icons {
        font-size: $font-size-xxs;
    }

    > .dcfonts-icons,
    > .#{$css-namespace}round_light {
        + #{$root}__text {
            margin-left: $space-1;
            font-size: $font-size-s;
            line-height: (18 / 12);
        }
    }
}
