// Implement old rules for deleted file for modal shops availability
.#{$css-namespace}modal-shops-availability {
    .modal-content,
    .store-availability {
        width: 100%;
    }

    .agencies {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: left;

        > li {
            display: flex;
            flex-direction: column;
            padding: 1.5rem 2.2rem;
            margin: 0;
            border: 0.1rem solid $color-disabled;
            border-radius: 0.3rem;

            @include min-screen($screen__l) {
                padding-right: $space-2;
            }
        }

        .#{$css-namespace}card {
            margin-bottom: 0;
        }

        .#{$css-namespace}availability {
            font-weight: $font-weight-bold;

            .in-stock {
                text-transform: uppercase;
            }
        }

        p:not(.#{$css-namespace}title) {
            margin: 0;
        }
    }
}

.modal-shops-availability-alert {
    .error-message {
        margin: 0;
        display: flex;
        padding: $indent__s $indent__base;
        font-weight: $font-weight-bold;
        align-items: flex-start;
        border-top: 0.1rem dashed $color-disabled-light;
        border-bottom: 0.1rem dashed $color-disabled-light;

        .dcfonts-icons--warning {
            margin-right: 1.5rem;
            font-size: 2.4rem;
            color: $color-red14;
        }
    }
}
