.product-info-missing-price,
.product-info-not-sellable {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-8;
    width: 100%;
    background-color: $color-white;

    .#{$css-namespace}account-creation {
        font-weight: $font-weight-medium;
    }

    .#{$css-namespace}content {
        text-align: center;
        margin: 0;

        b {
            display: block;
            @include lib-font-size(20);
            color: $color-primary;
            font-weight: $font-weight-medium;
            line-height: 1.2;
            margin-bottom: $space-2;

            @include min-screen($screen__l) {
                margin-bottom: $space-1;
            }
        }

        a {
            display: block;
        }
    }

    .action {
        margin-top: $space-2;
        background-color: $color-primary;
        border-color: $color-primary;
        text-transform: none;
        padding: $space-2 $space-8;
        font-weight: $font-weight__regular;
        font-size: $font-size__base !important;

        @include min-screen($screen__l) {
            margin-top: $space-3;
        }
    }

    .#{$css-namespace}account-creation {
        color: $color-secondary;
        margin-top: $space-2;
        text-decoration: underline;
        font-weight: $font-weight__semibold;
        font-size: $font-size__base;
    }
}
