$radio-form-group-card-chip-size: $space-2 !default;

.#{$css-namespace}card-with-fieldset {
    border: 0.1rem solid $color-primary;
    padding: 2rem 1rem 2rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 0;
    background: $color-white;
    border-radius: 0.3rem;
    z-index: 2;
    //Box-shadow is a trick for the fake fieldset
    box-shadow: 0 -0.5rem 0 0 rgba($color-white, 1);

    &--fake-radio {
        border-color: $color-accent;

        .radio-btn {
            height: $radio-form-group-card-chip-size;
            width: $radio-form-group-card-chip-size;
            border: 0.12rem solid $mainNeutralColor;
            border-radius: 0.75rem;
            background-color: $color-primary;
            display: block;
        }

        .#{$css-namespace}card-with-fieldset-right {
            margin-bottom: auto;
        }
    }

    &-label {
        position: absolute;
        background: $color-white;
        left: 1.2rem;
        top: 0;
        translate: 0 -50%;
        padding: 0 0.7rem 0 0.6rem;
        display: inline-block;
        letter-spacing: 0.011rem;
        font-size: $font-size-s; /* $font-size-s */
        color: $color-primary;
    }

    &-left {
        flex: 1;
        text-align: left;

        @include min-screen($screen__m) {
            margin-right: $space-2;
        }

        &-radio {
            text-align: left;
            margin-right: $space-2;
        }
    }

    &-right {
        @include max-screen($screen__m) {
            display: none;
        }
    }

    .text {
        color: $color-primary;
        font-weight: $font-weight-bold;
    }

    .description {
        display: block;
        margin-top: 0.5rem;
        line-height: 1.357;
    }
}
