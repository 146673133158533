// defined icons
.#{$css-namespace}icon {
    @extend %icon;

    // primary button
    &[data-ui-icon-skin='primary'] {
        color: $color-primary;
        fill: $color-primary;
    }

    // secondary button
    &[data-ui-icon-skin='secondary'] {
        color: $color-secondary;
        fill: $color-secondary;
    }

    // accent button
    &[data-ui-icon-skin='accent'] {
        color: $color-accent;
        fill: $color-accent;
    }
}
