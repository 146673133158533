// --------------------------------------------------------------------
// abstract base for panels
// --------------------------------------------------------------------

%dc__panel {
    display: flex;
    flex-direction: column;

    > header,
    > .#{$css-namespace}panel-header {
        flex: 0 0 auto;
    }

    > .#{$css-namespace}panel-body {
        flex: 1 1 auto;
    }

    > footer,
    > .#{$css-namespace}panel-footer {
        flex: 0 0 auto;
    }
}

// --------------------------------------------------------------------
// common base
// --------------------------------------------------------------------

.#{$css-namespace}panel {
    @extend %dc__panel;
}

// --------------------------------------------------------------------
// imports
// --------------------------------------------------------------------

@import 'panel-account';
