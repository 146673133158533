.#{$css-namespace}round_light {
    $root: &;

    display: inline-flex;
    flex-shrink: 0;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 0.5rem;

    &#{$root}[data-availability='green'] {
        background-color: $color-availability-available;
    }

    &#{$root}[data-availability='yellow'],
    &#{$root}[data-availability='orange'] {
        background-color: $color-availability-low;
    }

    &#{$root}[data-availability='red'] {
        background-color: $color-availability-unavailable;
    }
}
