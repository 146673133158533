$slider-padding: $space-4; // To fit with gap in homepage sliders

.brands {
    &-section {
        margin: 0 auto $slider-padding;
    }

    &-list {
        padding: 0;
        margin: 0; // Homepage gap

        .slick-slide {
            position: relative;
            margin: 0 #{$space-3 / 2}; // To fit with homepage slider rules
            padding: 0 $space-2;
            display: inline-flex;
            align-items: center;
            width: 23rem;
            min-height: $offset-xxxl;
            justify-content: center;
            border: solid #dbdbdb 0.1rem; // TODO: Use global var
            border-radius: $offset-xs;
            background-color: $color-main-light;
            height: 10.5rem;

            li a::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .brand {
            padding: 0 $space-1;
            max-width: 16rem;
            display: inline-block;
        }
    }

    @media screen and (min-width: $screen__m) {
        &-list .slick-slide {
            min-height: 8rem;
        }
    }
}
