.#{$css-namespace}detail-list {
    $root: &;

    margin: 0;
    padding: 0;

    &--catalog-event {
        height: calc((100vw - 2rem) * (275 / 600));
        max-height: 27.5rem;

        @include min-screen($screen__m) {
            height: calc((100vw - 4rem) * (155 / 1165));
            max-height: 15.5rem;
        }
    }

    > li {
        display: block;
        background-color: $color-main-light;

        &#{$root}--catalog-event {
            display: flex;
        }

        &:not(:last-child) {
            margin-bottom: $offset-l;
        }

        &:not(.#{$css-namespace}detail-list--catalog-event) {
            box-shadow: 0 0.3rem 0.7rem rgba($color-black, 0.17); // TODO create global box-shadow variables
            border-radius: 0.3rem;
        }

        // TODO: manage a card component
        .#{$css-namespace}card {
            display: flex;
            flex-direction: column;

            .#{$css-namespace}card-media {
                flex: 0 0 auto;
                padding: $offset-s;
                background-color: #f6f6f6; // TODO: use $color-gray-light0 instead of hexa

                .#{$css-namespace}media-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 12rem;

                    img {
                        flex: 0 0 auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            .#{$css-namespace}card-body {
                flex: 1 1 100%;
                padding: $offset-s;

                .#{$css-namespace}badge-wrapper {
                    margin: $offset-s 0 0;

                    // TODO: manage a badge in toolkit
                    .#{$css-namespace}badge {
                        padding: $base-step $offset-s;
                    }
                }

                .#{$css-namespace}list-dl {
                    margin: $offset 0 0;

                    .#{$css-namespace}list {
                        > li {
                            padding: $base-step;
                            margin-top: $offset-s;

                            h3 {
                                margin: 0;
                            }

                            // TODO: manage a badge in toolkit
                            .#{$css-namespace}badge {
                                border: none;
                                background-color: $color-grey-background;
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: $screen-sm-2) {
        > li {
            padding: 0;

            &:not(:last-child) {
                margin-bottom: $offset-xl;
            }

            .#{$css-namespace}card {
                flex-direction: row;

                .#{$css-namespace}card-media {
                    width: 29.5rem;
                    min-height: 27.5rem;
                    padding: $offset-xl 4rem $offset-xl $offset-xl;
                    clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 0% 100%); // 100% div - 4rem padding-right
                    border-top-left-radius: 0.3rem;
                    border-bottom-left-radius: 0.3rem;

                    .#{$css-namespace}media-wrapper {
                        height: 100%;
                    }
                }

                .#{$css-namespace}card-body {
                    margin: $offset-xl 0 $offset-xl $offset-l;

                    .#{$css-namespace}badge-wrapper {
                        // TODO: manage a badge in toolkit
                        .#{$css-namespace}badge {
                            font-size: $font-size-default;
                        }
                    }

                    .#{$css-namespace}list-dl {
                        margin: $offset-xl 0 0;

                        .#{$css-namespace}list {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: $offset-s;

                            > li {
                                .#{$css-namespace}badge {
                                    &:hover {
                                        background-color: $color-secondary;
                                        color: $color-main-light;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
