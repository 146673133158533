.logo {
    display: flex;
    max-width: 12rem;
    max-height: 4rem;
    width: 100%;
    line-height: 1;

    @include max-screen($screen__m) {
        .--scrolled & {
            display: none;
        }
    }

    @include min-screen($screen__m) {
        max-width: 19rem;
    }

    // Keep it for iOS
    svg {
        width: 100%;
    }
}
