.#{$css-namespace}footer-navigation {
    .#{$css-namespace}nav-grid {
        .#{$css-namespace}nav-list-wrapper {
            display: flex;
            flex-direction: column;

            .#{$css-namespace}title {
                color: $color-white;
            }

            .#{$css-namespace}img-wrapper {
                display: none;
                width: 234px;
                height: 234px;
                max-width: 100%;
                margin: $offset auto 0;

                @include min-screen($screen__l) {
                    display: block;
                }

                img {
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            ul {
                + ul {
                    margin-top: $offset-xl;
                }
            }

            + .#{$css-namespace}nav-list-wrapper {
                margin-top: 1rem;

                &:not(.#{$css-namespace}nav-list-expander) {
                    margin-top: 2.5rem;
                }

                @include min-screen($screen__l) {
                    &:not(.#{$css-namespace}nav-list-expander) {
                        margin-top: 0;
                    }
                }
            }
        }

        .#{$css-namespace}nav-list-expander {
            border-bottom: 0.1rem solid rgba($color-white, 0.5);
            padding-bottom: 1.5rem;
            width: 100%;

            @include min-screen($screen__l) {
                border-bottom: none;
                pointer-events: none;
                cursor: none;
            }

            &:not(.is-active) {
                .#{$css-namespace}nav-list-expander-content {
                    display: none !important;
                }
            }

            @include min-screen($screen__l) {
                &:not(.is-active) {
                    .#{$css-namespace}nav-list-expander-content {
                        display: block !important;
                    }
                }
            }

            .#{$css-namespace}nav-list-expander-header {
                display: flex;
                justify-content: space-between;
                background: none;
                border: none;
                padding: 0;
                width: 100%;

                .dcfonts-icons {
                    font-size: 2rem;
                    color: $color-white;
                    display: block;

                    @include min-screen($screen__l) {
                        display: none;
                    }
                }
            }

            .#{$css-namespace}nav-list-expander-content {
                margin-top: 1.5rem;
                margin-left: 1rem;

                @include min-screen($screen__l) {
                    margin-left: 0;
                }
            }
        }

        .#{$css-namespace}payment-methods {
            display: flex;
            justify-content: center;
            margin-top: $offset;
            padding: 0 $offset;
        }
    }

    @media screen and (min-width: $screen-sm) {
        .#{$css-namespace}nav-grid {
            .#{$css-namespace}nav-list-wrapper {
                margin-top: 0;

                + .#{$css-namespace}nav-list-wrapper {
                    margin-top: 0;
                }
            }
        }
    }
}
