.copyright-container {
    background: $color-gray-light6;
    padding: 1rem 0;
    text-align: center;
    clear: both;
    font-size: $font-size-s;
    font-family: $subFont;
    color: $subAltPrimaryColor;

    a {
        color: $subAltPrimaryColor;

        &:hover {
            text-decoration: none;
        }
    }
}
