// Toggle more, less
.#{$css-namespace}btn_toggle {
    margin-top: 1rem;

    @include min-screen($screen-sm) {
        margin-top: 0;
    }
}

.#{$css-namespace}toggle_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.#{$css-namespace}btn_toggle,
.seemore,
.seeless {
    display: none;
}

.show {
    display: block;
}
