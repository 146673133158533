/* Product page implementation */
.catalog-product-view {
    .breadcrumbs:has(> .items > .item.home + .item.product) {
        opacity: 0;
    }

    .#{$css-namespace}reference_table {
        .#{$css-namespace}title-section {
            margin-bottom: 1.2rem;
        }
    }

    .#{$css-namespace}limit_checkbox {
        position: absolute;
        left: -999rem;
        height: 0;

        &:checked ~ .#{$css-namespace}limit_action {
            .#{$css-namespace}limit_action {
                &--more {
                    display: none;
                }

                &--less {
                    text-decoration: underline;
                    display: inline-block;

                    &:hover {
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }

        &:checked ~ .attributes {
            height: auto !important;
        }
    }

    .#{$css-namespace}limit_action {
        color: $color-secondary;
        margin-top: $space-3;
        text-decoration: none;
        display: flex;
        align-items: center;
        line-height: 1.9rem;
        font-weight: $font-weight__bold;

        &--less {
            display: none;
        }

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }

        .dcfonts-icons {
            margin-right: $indent__xs;
            font-size: $font-size-s;
            margin-left: 1.3rem;
        }
    }

    .product-info-custom {
        .attributes {
            &.data {
                height: auto;
            }
        }
    }

    .attributes {
        &.data {
            display: flex;
            flex-direction: column;
            max-width: 86rem;
            padding: 0;
            margin: 0;
            list-style-type: none;
            height: 0;
        }

        &--overflow {
            overflow: hidden;
        }

        > li {
            flex: 0 0 auto;
            margin: 0;

            .attribute {
                display: flex;
                margin: 0;

                dt,
                dd {
                    display: flex;
                    align-items: center;
                    margin: 0;
                }

                dt {
                    flex: 0 0 50%;
                    padding: 1rem 1.5rem;
                    color: $color-primary;
                    font-size: $font-size-default;
                    font-weight: $font-weight-medium;

                    @include min-screen($screen__l) {
                        flex: 0 0 22.6rem;
                    }
                }

                dd {
                    flex: 0 0 50%;
                    margin-left: 0.1rem;
                    padding: 1rem 1.5rem;
                    font-size: $font-size-s;

                    @include min-screen($screen__l) {
                        flex: 0 0 60%;
                        margin-left: 0.1rem;
                    }
                }

                .value {
                    font-weight: $font-weight-regular;
                }
            }

            &:nth-child(odd) {
                .attribute {
                    dt,
                    dd {
                        background-color: #f5f5f5; // TODO: use $color-white-smoke instead of hexa
                    }
                }
            }
        }
    }

    .product-availability-info {
        color: $color-grey-dark;
        font-size: $font-size-xs;
        margin-top: 1rem;
        line-height: 1.5rem;
    }

    .column.main {
        @include lib-css-box-sizing-border-box-reset();
        display: flex;
        flex-direction: column;
        padding: 0 0.5rem;
        overflow: hidden;

        @include min-screen($screen-lg) {
            padding: 0 $space-3;
        }

        .product-info-custom {
            flex: 1 1 auto;
            align-items: flex-start;

            .#{$css-namespace}attribut_data {
                display: none;
            }
        }

        .product-left-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .product-infos-container {
                position: relative;
                background: $color-white;
                border-radius: 1rem;
                box-shadow: var(--box-shadow);
                width: 100%;
                margin-top: $space-2;
                margin-bottom: $space-2;

                @include min-screen($screen__l) {
                    display: flex;
                    width: calc(100% + #{$space-2});
                    left: -$space-1;
                }

                > div {
                    padding: $space-2;

                    @include min-screen($screen__l) {
                        width: 50%;
                        padding: $space-4 $space-7;
                    }
                }
            }
        }
    }

    @include min-screen($screen__m) {
        .column.main {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    @include min-screen($screen__l) {
        .column.main {
            .product-info-custom {
                width: auto;
                max-width: none;

                .#{$css-namespace}attribut_data {
                    display: block;
                }
            }
        }
    }

    .#{$css-namespace}taxes-descriptions {
        display: none;

        @at-root .#{$css-namespace}taxes-descriptions {
            .modal-eco-part & {
                display: flex !important;
                flex-direction: column;
                row-gap: $space-5;
            }
        }

        &__title {
            margin-top: 0;
            font-weight: $font-weight-bold;
        }

        img {
            margin-bottom: $space-2;
        }

        p {
            margin-bottom: $space-3;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
