// Spacing based on a multiple of 8px.
$space-1: $size-default !default; // 8px
$space-2: $space-1 * 2 !default; // 16px
$space-3: $space-1 * 3 !default; // 24px
$space-4: $space-1 * 4 !default; // 32px
$space-5: $space-1 * 5 !default; // 40px
$space-6: $space-1 * 6 !default; // 48px
$space-7: $space-1 * 7 !default; // 56px
$space-8: $space-1 * 8 !default; // 64px
$space-9: $space-1 * 9 !default; // 72px
$space-10: $space-1 * 10 !default; // 80px
$space-11: $space-1 * 11 !default; // 88px
$space-12: $space-1 * 12 !default; // 96px
$space-13: $space-1 * 13 !default; // 104px
$space-20: $space-1 * 20 !default; // 160px
$space-23: $space-1 * 23 !default; // 184px
