.#{$css-namespace}order-summary {
    &_recap {
        padding: $space-2;
        border: 0.1rem solid $color-gray97;
        border-radius: 0.3rem;
        margin-top: $space-3;

        @include min-screen($screen__l) {
            width: 30.2rem;
        }

        &-title {
            font-family: $font-secondary !important; // rewrite toolkit data-ui-title-level font
            font-size: $font-size-l !important;
            line-height: 1.334 !important;
        }

        .data.table.order-totals {
            line-height: 1.429;

            dl {
                margin: 0;
                display: flex;
                align-items: center;

                &.total-excl {
                    dt,
                    dd {
                        font-size: $font-size-m;
                        font-weight: $font-weight-bold;
                        line-height: 1.375;
                    }
                }

                dt {
                    flex: 0 1 auto;
                    margin: 0;
                    font-weight: $font-weight-regular;
                }

                dd {
                    flex: 1 1 auto;
                    margin: 0 0 0 $indent__xs;
                    font-weight: $font-weight-bold;

                    .price-discount {
                        color: $color-red-promo;
                    }
                }

                + dl {
                    margin: 0 0 0;
                }
            }

            .color-accent {
                color: $color-accent;
            }
        }
    }

    &_cgv {
        &-container {
            &.no-recap {
                @include min-screen($screen__l) {
                    height: 100%;
                    margin-top: 0;
                }
            }

            margin-top: 1rem;
            display: none;

            @include min-screen($screen__l) {
                display: flex;
                align-items: end;
            }
        }

        &-message {
            font-size: $font-size-s;
            width: 100%;
            text-align: right;
        }
    }
}
