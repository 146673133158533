@include screen($screen__xs, $screen__l) {
    .greet.welcome {
        @include lib-font-size(18);
        line-height: 2.1rem;

        .icon-account {
            width: 4.2rem;
            height: 4.2rem;
        }
    }
}

@include screen($screen__xxs, $screen__xs) {
    #topPanelMobile {
        .greet.welcome {
            @include lib-font-size(18);
            line-height: 2.1rem;

            .icon-account {
                width: 3.2rem;
                height: 3.2rem;
            }
        }
    }
    .header.content {
        .greet.welcome {
            .icon-account {
                width: 2.6rem;
                height: 2.6rem;
            }
        }
    }
}

@include max-screen($screen__xxs) {
    #topPanelMobile {
        .greet.welcome {
            @include lib-font-size(16);
            line-height: 1.8rem;

            .icon-account {
                width: 2.6rem;
                height: 2.6rem;
            }
        }
    }

    .header.content {
        .greet.welcome {
            .icon-account {
                width: 2.6rem;
                height: 2.6rem;
            }
        }
    }
}

@include max-screen($screen__l) {
    #topPanelMobile {
        .greet.welcome {
            .welcome {
                display: inline-block;

                margin-left: $indent__s;

                vertical-align: middle;
            }
        }
    }

    .header.content {
        .greet.welcome {
            @include lib-vendor-prefix-order(3);

            margin: 0 3rem;

            .welcome {
                display: none;
            }
        }
    }
}

@include min-screen($screen__l) {
    .header.content {
        .greet.welcome {
            @include lib-vendor-prefix-order(5);

            margin: 0 3.5rem;
        }
    }
}
