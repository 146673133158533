.#{$css-namespace}modal_change-store-modal {
    .shopStockWarning {
        margin-top: $space-2; // egal to $modal-gap
        text-align: left;
        width: 100%;

        .shopStockWarningMessageWrapper {
            display: flex;
            align-items: center;
            margin-bottom: 1.85rem;
        }

        .dcfonts-icons--warning {
            margin-right: 1.5rem;
            font-size: 2.4rem;
            color: $color-red;
        }

        .shopStockWarningMessage {
            font-weight: $font-weight-bold;
            text-align: left;
        }
    }

    .shopStockWarningIds {
        max-height: 8.7rem;
        overflow: auto;
        list-style: none;
        padding: 0.95rem 1rem;
        background: $color-grey-background;
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
        text-transform: uppercase;

        li {
            text-align: left;
            margin: 0;
        }
    }
}
