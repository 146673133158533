$loyalty-block-width: 43.6rem;

.block-dashboard-loyalty-PROLIANS {
    flex: 1 0;
    margin-bottom: $space-1;

    body:not(.useraccount-account-loyaltyprogram) & {
        flex-basis: $loyalty-block-width;
    }
}

.#{$css-namespace}loyalty-PROLIANS {
    background-color: $color-white;
    border-radius: 0.3rem;
    cursor: default;

    &.is-on-opening-request {
        max-width: 100%;
        width: 46rem;
        margin: 0 auto;
    }

    &.h-flex {
        .image {
            width: $loyalty-block-width;
        }

        .#{$css-namespace}loyalty {
            &_content {
                flex: 1;

                @include min-screen($screen__l) {
                    padding: $space-4 $space-5;
                }
            }
        }
    }

    .image {
        background: linear-gradient(264deg, rgba(121, 58, 176, 0.00) 0.64%, rgba(121, 58, 176, 0.49) 0.64%, #793AB0 80.78%), url('../Descours_UserAccount/images/PROLIANS/josiane-pichet.jpeg') lightgray 50% / cover no-repeat;
        position: relative;
        padding: $space-4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: $space-1 / 2;
        font-weight: $font-weight-bold;
        color: $color-white;
        min-height: 18.6rem;

        &:before {
            display: none;
        }

        .first-line,
        .second-line {
            text-transform: uppercase;
        }

        .first-line {
            margin-top: $space-1 / 2;
            margin-bottom: -$space-1;
            font-size: 4.9rem;
            line-height: 1.165;

            &.smaller {
                font-size: 2.4rem;
            }
        }

        .second-line {
            line-height: 1.15;
        }

        .link {
            margin-top: $space-1 / 2;
            text-decoration: underline;
            color: inherit;
            line-height: 1.42;
            font-weight: $font-weight-medium;
        }
    }

    .#{$css-namespace}loyalty {
        &_content {
            padding: $space-3;

            &.two-columns {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: $space-2;
                flex: 1;

                @include min-screen($screen__xs) {
                    flex-direction: row;
                    gap: $space-1;
                }
            }
        }

        &_list {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: $space-3;
            padding: 0;
            margin: 0;
            font-size: $font-size-default;
            max-width: 100%;

            &_entry {
                display: flex;
                flex-direction: column;
                gap: $space-1 / 2;
                align-items: flex-start;

                &::before {
                    display: none;
                }

                &_title {
                    display: flex;
                    gap: $space-1;
                    align-items: flex-start;
                    font-weight: $font-weight-bold;
                }
            }

            .dcfonts-icons {
                color: $loyalty-PROLIANS-purple;
                font-size: $font-size-m;
                margin-top: 0.2rem;

                &.green {
                    color: $loyalty-PROLIANS-green;
                }
            }

            .dc__link {
                color: $color-main-dark;
                font-weight: $font-weight-regular;
            }
        }

        &_points {
            align-items: flex-end;
            margin-top: 0;
            padding: 0;
            gap: $space-1;

            &_value {
                color: $color-main-dark;
                text-align: right;
            }

            &_expired {
                text-align: right;
            }

            .dc__loyalty_button {
                margin-top: $space-1;
            }
        }

        &_button {
            margin-top: $space-3;
            display: flex;
            justify-content: flex-end;
        }
    }
}

.#{$css-namespace}loyalty_tag {
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    padding: $space-1 / 2 $space-1;
    color: $color-white;
    display: inline-flex;
    align-items: center;
    gap: $space-1 / 2;
    rotate: -4deg;
    margin-bottom: $space-1 / 2;

    &.brand {
        background-color: $loyalty-PROLIANS-green;
    }

    &.kitty {
        background-color: $loyalty-PROLIANS-purple;
    }

    &.challenge {
        background-color: $color-white;
        color: $loyalty-PROLIANS-purple;

        &.disabled {
            color: $color-main-dark;
        }
    }

    &.small {
        font-size: $font-size-s;
    }

    &.big {
        font-size: $font-size-m;
    }

    &-icon {
        font-size: 1.6rem; // Can't use $icon-xs-size here
    }

    p {
        margin: 0;
    }
}

.is-PROLIANS {
    .account.customer-account-index & {
        &.dc__dashboard_container_content--column {
            flex-basis: auto;

            .dc__box_information_account {
                margin-top: initial;
            }
        }
    }
}

.#{$css-namespace}loyaltyprogram_morepoints {
    &.PROLIANS-challenges {
        padding: 0 $space-2;
        background-color: transparent;

        @include min-screen($screen__xm) {
            padding: 0;
        }

        .disabled {
            filter: none;
        }
    }
    .PROLIANS-challenges & {
        &_blocks {
            padding: 0;

            @include min-screen($screen__xm) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: $space-2;
            }
        }

        &_block {
            padding: 0;
            border-radius: 0.3rem;
            background-color: $color-white;
            height: auto;
            cursor: default;

            @include min-screen($screen__xm) {
                width: auto;
                max-width: none;
            }

            &_status {
                padding: $space-3;
                background-color: $loyalty-PROLIANS-purple;
                color: $color-white;

                &.disabled {
                    background-color: $loyalty-PROLIANS-disabled;
                }
            }
        }
    }
}
