// ----- Border Radius ----- //

@for $i from 0 through 2 {
    .rounded-#{$i * 3} {
        border-radius: #{$i * 0.3}rem;
    }
}

// ----- Box Shadows ----- //

.box-shadow {
    box-shadow: var(--box-shadow);

    &-light {
        box-shadow: var(--box-shadow-light);
    }
}
