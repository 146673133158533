.#{$css-namespace}approv-order-req {
    &_table {
        font-size: $font-size-s;

        @include min-screen($screen__l) {
            font-size: $font-size-default;
        }
    }

    &_actions-header {
        text-align: center;
    }

    &_price-excl-vat {
        font-weight: $font-weight-bold;
    }

    &_actions {
        justify-content: center;
    }
}
