.sealed-form {
    .action.pay.primary {
        @include lib-visually-hidden();
    }
}

.checkout-payment-method .payment-method {
    .payment-method-billing-address,
    .actions-toolbar.monetico {
        @include lib-visually-hidden();
    }

    .payment-method-content {
        display: none;
        flex-direction: column;
        padding: 0;

        @include max-screen($screen__l) {
            align-items: center;
        }

        .payzen-form {
            padding: 0 2rem;

            @include max-screen($screen__l) {
                padding: 0;
            }
        }

        h2 {
            @include lib-font-size(16);
            margin: 0 0 $indent__base;
            font-weight: $font-weight__bold;
            color: $color-sub-secondary;
        }

        p {
            margin: 0 0 $indent__base;
        }

        .fieldset {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            .payment-method-icon {
                flex: 0 0 auto;
                display: flex;
                align-items: center;

                .payment-method-bank-icon {
                    display: block;
                    height: 2.5rem;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin: 0;

                    + .payment-method-bank-icon {
                        margin-left: $indent__s;
                    }

                    &.payment_method_monetico_cb {
                        width: 3.5rem;
                        background-image: url('../images/logo_cb.jpg');
                    }

                    &.payment_method_monetico_visa {
                        width: 4.4rem;
                        height: 1.4rem;
                        background-image: url('../images/logo_visa.png');
                    }

                    &.payment_method_monetico_master {
                        width: 3.2rem;
                        background-image: url('../images/logo_mastercard.png');
                    }
                }
            }

            @include min-screen($screen__l) {
                flex-direction: row;

                .payment-method-icon {
                    margin: 0 0 0 $indent__base;
                }
            }
        }
    }
}
