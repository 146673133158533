.#{$css-namespace}web-orders {
    .#{$css-namespace}user-account_search-container {
        @include min-screen($screen__l) {
            max-width: 46rem;
        }
    }

    .#{$css-namespace}user-account_nice-select {
        @include min-screen($screen__l) {
            width: 25.7rem;
        }
    }

    // Forcing width of DataTable columns.
    &_web-ref-header {
        @include min-screen($screen__l) {
            width: 14rem !important;
        }
    }

    &_internal-ref-header {
        @include min-screen($screen__l) {
            width: 17rem !important;
        }
    }

    &_date-header {
        @include min-screen($screen__l) {
            width: 10rem !important;
        }
    }

    &_status-header {
        @include min-screen($screen__l) {
            width: 18rem !important;
        }
    }

    &_amount-header {
        @include min-screen($screen__l) {
            width: 12rem !important;
        }
    }
}
