.text-left {
    text-align: start;
}

.text-center {
    // TODO : Refacto with app/design/frontend/Descours/Descours/styles/theme/_typography.scss
    text-align: center;
}

.text-right {
    text-align: end;
}

.font-medium {
    font-weight: $font-weight-medium;
}

.text-semi-bold,
.font-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.text-bold,
.font-bold {
    font-weight: $font-weight-bold;
}

.font-black {
    font-weight: $font-weight-black;
}

.text-underline {
    text-decoration: underline;
}

.text-underline-offset {
    text-underline-offset: 0.5 * $space-1;
}

// ----- Colors ----- //

.text {
    &-black {
        color: $color-black !important;
    }

    &-primary {
        color: $color-primary !important;
    }

    &-secondary {
        color: $color-secondary !important;
    }
}
