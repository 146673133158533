.home-our-jobs-container {
    padding: 5.5rem 2%;
    background: $color-blue3;
}

.our-jobs {
    &-section {
        position: relative;
    }

    &-list {
        font-size: 0;
        margin: 0;
        padding: 0;
    }

    &-url {
        display: block;
        padding: 0;
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out; // TODO : Uniformise using var(--transition-normal)
    }
}

li.our-jobs-category {
    display: inline-block;
    box-sizing: border-box;
    width: calc(25% - 1.5rem);
    margin: 1rem;
    padding: $space-1 / 2;
    cursor: pointer;
    transition: 0.3s; // TODO : Uniformise using var(--transition-normal)
    text-align: center;
    vertical-align: top;
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background: white;

    &:first-child,
    &:nth-child(4n + 1) {
        margin-left: 0;
    }

    &:nth-child(4n) {
        margin-right: 0;
    }

    &:hover {
        padding: 0;
        border: 0.4rem solid $color-blue1;

        span:not(.our-jobs-img) {
            color: $color-blue1;
        }
    }

    span {
        display: block;
        margin-top: 3rem;

        &:not(.our-jobs-img) {
            @include lib-font-size(16);

            font-weight: $font-weight__bold;
            width: 90%;
            margin: $indent__s auto 3rem;
        }
    }
}

@include screen($screen__m, $screen__l) {
    li.our-jobs-category {
        span {
            &:not(.our-jobs-img) {
                @include lib-font-size(13);
            }
        }
    }
}

@include max-screen($screen__m) {
    .home-our-jobs-container {
        padding: 5.5rem 2rem;
    }
    li.our-jobs-category {
        width: calc(50% - 1rem);
        margin: 1rem 0;

        &:nth-child(2n + 1) {
            margin-right: 2rem;
        }

        span {
            margin-top: 1.5rem;

            &:not(.our-jobs-img) {
                margin: $indent__s auto 1.5rem;
            }
        }
    }
}

@include max-screen($screen__xs) {
    li.our-jobs-category {
        span {
            &:not(.our-jobs-img) {
                min-height: 4.4rem;
            }
        }
    }
}
