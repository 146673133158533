// variables
$badge-color-background: $color-main-light;
$badge-color-text: $color-primary;
$badge-color-border: $color-primary;

$badge-hover-color-background: $color-secondary;
$badge-hover-color-text: $color-main-light;
$badge-hover-color-border: $color-main-light;

$badge-disabled-color-background: $color-disabled-light;
$badge-disabled-color-text: $color-disabled-dark;
$badge-disabled-color-border: $color-disabled-light;

$badge-color-error: $color-red;
$badge-color-warning: $color-warning-secondary;
$badge-color-success: $color-success-secondary;
$badge-color-tag: $color-white;

$badge-color-error-background: $color-error-background;
$badge-color-warning-background: $color-waring-background;
$badge-color-success-background: $color-success-background;
$badge-color-tag-background: $color-red-promo;

// abstract badge
%badge {
    display: inline-flex;
    align-items: center;
    padding: $badge-padding;
    border: 0.1rem solid $badge-color-border;
    border-radius: $badge-border-radius;
    font-size: $badge-text-size;
    line-height: $badge-text-line-height;
    text-transform: none;
    background: $badge-color-background;
    color: $badge-color-text;
    min-height: $badge-min-height;

    &:where(.#{$css-namespace}badge--border-light) {
        border-radius: $badge-border-radius-light !important;
    }

    &:where(.#{$css-namespace}badge--font-medium) {
        font-weight: $font-weight-medium;
    }

    // svg icon
    > .#{$css-namespace}icon {
        flex: 0 0 auto;
        width: $badge-icon-size;
        height: $badge-icon-size;
        font-size: $badge-icon-size;
        fill: currentColor;

        + span {
            margin-left: $offset-s;
        }
    }

    > span {
        flex: 1 1 auto;
        text-align: center;

        + .#{$css-namespace}icon {
            margin-left: $offset-xs;
        }
    }

    &[disabled],
    &[aria-disabled='true'],
    &[data-ui-badge-status='disabled'] {
        border-color: $badge-disabled-color-border;
        background-color: $badge-disabled-color-background;
        color: $badge-disabled-color-text;
        opacity: 1;
        cursor: not-allowed;
    }
}

// import all components
@import 'badge/default';
@import 'badge/success';
@import 'badge/warning';
@import 'badge/error';
@import 'badge/light';
@import 'badge/tag';
