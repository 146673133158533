.#{$css-namespace}quick-order {
    @include lib-css-wrapper();

    .#{$css-namespace}block-addbysku_form {
        padding: $space-2 !important;
    }

    .#{$css-namespace}products-section_products-table {
        margin-top: 2.5rem;
    }

    .#{$css-namespace}wishlist_products-section_order-selector_radio-buttons {
        margin-bottom: 1.2rem;

        @include min-screen($screen__l) {
            margin-bottom: 0;
        }
    }

    .#{$css-namespace}wishlist_products-section_order-selector {
        margin-top: 1rem !important;

        @include min-screen($screen__l) {
            margin-top: 2.5rem !important;
            margin-bottom: 0 !important;
        }
    }
}

.#{$css-namespace}quick-order_wrapper {
    .actions-toolbar {
        margin-bottom: 1.5rem;
    }
}

.#{$css-namespace}quick-order_subtitle {
    margin-bottom: 1.2rem;
}

.#{$css-namespace}quick-order_description {
    margin-bottom: 4.5rem;
}

.quick-order-menu-header {
    display: flex;
    justify-content: space-between;

    @include max-screen($screen__m) {
        flex-direction: column;
    }

    .#{$css-namespace}quick-order_subtitle {
        font-weight: $font-weight-medium;
        @include max-screen($screen__m) {
            font-weight: $font-weight-bold;
            font-size: $font-size-xl;
        }
    }

    .#{$css-namespace}form-radio-group {
        display: flex;
        @include max-screen($screen__m) {
            display: flex;
            flex-direction: column;
        }
    }

    .quick-order-link {
        margin-left: 3rem;
        cursor: pointer;
        @include max-screen($screen__m) {
            margin-top: 3rem;
        }

        &-mass {
            @include max-screen($screen__s) {
                display: none;
            }
        }

        &--wording {
            &:active {
                color: $color-secondary;
                text-decoration: underline;
            }

            &-mobile {
                text-align: center;
                margin-top: 2rem;
                display: none;

                @include max-screen($screen__s) {
                    display: block;
                }
            }
        }

        &--icon-container {
            margin-right: 0.5rem;
        }
    }

    .dcfonts-icons {
        color: $color-secondary;
        font-size: 1.7rem;
    }
}

@include max-screen($screen__s) {
    .#{$css-namespace}dc__quick-order_import_mass-article-list_wrapper {
        display: none;
    }
}
