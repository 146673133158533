@import 'order/view';
// @TODO: replace /view by /module
@import 'order/module';
@import 'approv-order-req';

.sales-order-toapprovalorderrequests {
    .page-header {
        max-width: 100vw;

        @include min-screen($screen__l) {
            max-width: 100%;
        }
    }

    .columns {
        .column.main {
            padding: 0;
            background: none;
            box-shadow: none;
        }
    }
}

.sales-order_view-toapprovalorderrequest,
.sales-order_view-toapprovalorderrequestedit {
    .#{$css-namespace}order_wrapper {
        padding: 0;
        background: none;
        box-shadow: none;

        .#{$css-namespace}order-bottom-container {
            margin-top: $space-3;
        }
    }

    .#{$css-namespace}modal_order_request_action {
        .modal-content {
            // Fieldset effect fixed
            padding-top: $space-1 !important;
        }
    }
}

.sales-order_view-toapprovalorderrequestedit {
    .column.main {
        padding: 0;
        background: none;
        box-shadow: none;
    }

    .#{$css-namespace}order_ref {
        box-shadow: var(--box-shadow);
        background: $color-white;
        padding: $space-3;

        &_title {
            padding: $space-2;

            @include min-screen($screen__l) {
                padding: $space-5;
            }
        }
    }

    .#{$css-namespace}order-address-container {
        margin-top: 0 !important;
    }

    .#{$css-namespace}order-summary_details_reference {
        max-width: 26rem;
    }
}

.sales-order_view-pendingorderrequest,
.sales-order_view-toapprovalorderrequest,
.sales-order_view-toapprovalorderrequestedit {
    .column.main {
        background: none;
        padding: 0;
        box-shadow: none;
    }

    .cart-wrapper {
        background: none;
        gap: $space-3;
        padding: 0;
        box-shadow: none;

        @include min-screen($screen__l) {
            padding: 0;
        }

        &_description {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: $space-2;

            &_text {
                margin: 0;
                text-align: left;
                width: 100%;
            }

            &_button {
                justify-content: flex-start;
            }
        }

        &-inner {
            @include lib-css-wrapper();
            background: $color-white;
            padding: $indent__base $indent__xs;
            margin-top: 0;

            @include min-screen($screen__l) {
                padding: $indent__base $indent__l $indent__l;
            }

            .#{$css-namespace}order-items_table .#{$css-namespace}item-table_body .#{$css-namespace}item-category_row {
                border-bottom: 0;

                .#{$css-namespace}item-category_row-name {
                    color: $color-dark-grey;
                    font-size: 2.2rem;
                    padding: 0 0 $space-1;

                    &-count_product {
                        font-weight: $font-weight-regular;
                        font-size: $font-size-default;
                        color: $color-black;
                    }
                }
            }

            .#{$css-namespace}products-section_products-table,
            .#{$css-namespace}order-items,
            .#{$css-namespace}item-category_row {
                border-bottom: none;
            }

            .#{$css-namespace}order-items_table .#{$css-namespace}item-table_body {
                .#{$css-namespace}item-table_row {
                    border: 0;
                    border-top: 0.1rem solid $color-disabled-light;
                }

                tr:nth-child(2) {
                    border-top: 0;
                }
            }
        }
    }

    .#{$css-namespace}item-table_row-availability {
        margin-bottom: 0;
    }

    .#{$css-namespace}order-items_wrapper:not(:first-child) {
        margin-top: $indent__base;
    }

    .#{$css-namespace}order-summary_wrapper,
    .#{$css-namespace}order-bottom-container {
        @include lib-css-wrapper();
        background: $color-white;
        padding: $indent__base $indent__xs;
        margin-top: 0;
        margin-bottom: 0;

        @include min-screen($screen__l) {
            padding: $indent__base $indent__l $indent__l;
        }
    }

    .#{$css-namespace}order-summary_wrapper {
        gap: $indent__base;
    }

    .#{$css-namespace}order-summary_details_reference {
        width: fit-content;
        min-width: 26rem;
    }
}

.sales-order_view-pendingorderrequest {
    .#{$css-namespace}order-summary_recap {
        @include min-screen($screen__l) {
            margin-top: 0;
        }
    }
}

.sales-order_view-toapprovalorderrequestedit {
    .#{$css-namespace}block-addbysku_form {
        background-color: transparent;
        padding: 0;
        margin: 0;

        .field-wrapper {
            max-width: 35rem;
        }

        input {
            max-width: 100%;
        }
    }

    .#{$css-namespace}item-table_row-delete {
        &.desktop {
            display: none;

            @include min-screen($screen__l) {
                display: block;
            }
        }

        &.mobile {
            display: block;
            position: absolute;
            top: -10rem;
            right: 0;
            color: $color-secondary;

            .#{$css-namespace}item-table_row-delete-button {
                font-size: $font-size-xl !important;
                text-decoration: none !important;
            }

            @include min-screen($screen__l) {
                display: none;
            }
        }
    }
}

.#{$css-namespace}sales-order-view {
    .actions-toolbar {
        margin-bottom: $offset-l;

        svg {
            margin-right: 1rem;
            height: $space-2;
            width: $space-2;
        }

        .#{$css-namespace}order-summary_back {
            &:visited {
                color: $color-secondary;
            }
        }
    }
}

.sales-order_view-weborder {
    .column.main {
        .order-items {
            margin-top: 5rem;
        }
    }
}

.#{$css-namespace}order-bottom-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2rem;

    @include min-screen($screen__l) {
        flex-direction: row;
    }

    &--column {
        flex-direction: column;

        .#{$css-namespace}order-total-container {
            order: 0;
            margin: 0;
            width: 100%;
        }
    }
}

.#{$css-namespace}order-address-container {
    display: flex;
    flex-direction: column;
    order: 2;
    margin-top: 2rem;
    justify-content: flex-start;

    @include min-screen($screen__l) {
        flex-direction: row;
        order: 1;
        justify-content: space-between;
        width: 46.5%;
        align-items: flex-start;
    }
}

.#{$css-namespace}order-address-container--full {
    @include min-screen($screen__l) {
        width: 100%;
    }

    .#{$css-namespace}order-address-area {
        @include min-screen($screen__l) {
            flex: 0 0 auto;
            margin-right: 2rem;
            width: calc(50% - 1rem);

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.#{$css-namespace}order-total {
    &-container {
        font-family: $font-secondary;
        font-weight: $font-weight-bold;
        order: 1;
        font-size: $font-size-l;
        text-align: right;
        margin-bottom: 2rem;

        @include min-screen($screen__l) {
            flex-direction: row;
            font-size: $font-size-xxl;
            order: 2;
            justify-content: space-between;
            width: 50%;
        }
    }

    &-text {
        position: relative;
        margin-right: 1.5rem;
        padding-bottom: 2rem;
        min-width: 17rem;
        display: inline-flex;
        justify-content: flex-end;

        small {
            position: absolute;
            bottom: 0;
            right: 1.7rem;
            font-size: $font-size-default;
            text-align: right;
            font-family: $font-default;
            font-weight: $font-weight-regular;
            white-space: nowrap;
        }

        @include min-screen($screen__l) {
            padding-bottom: 2.3rem;

            small {
                font-size: $font-size-m;
            }
        }
    }
}

// @TODO: review usage of classes below in Descours_Sales for refactoring

.block-title {
    @include lib-font-size(2rem);
    color: $color-blue3;
    line-height: 1;
    font-weight: $font-weight__bold;

    span {
        @extend .block-title;
        display: block;
        border-bottom: 0.1rem solid $color-blue3;
        padding-bottom: $indent__s;
    }
}

.block-order-details-view {
    .block-header {
        &-item {
            display: flex;
            flex-direction: column;
            margin: 0;

            &-label {
                flex: 0 1 auto;
                margin: 0;
                @include lib-font-size(18);
            }

            &-value {
                flex: 1 1 auto;
                margin: 0 0 0 $indent__xs;
                @include lib-font-size(18);
            }
        }
    }

    #form-approval-choice {
        margin: 2rem 0;
    }

    @include min-screen($screen__s) {
        .block-header {
            &-item {
                flex-direction: row;
            }
        }
    }
}

.rowTitle {
    .hide {
        display: none;
    }
}

.order-request {
    &-block-validation {
        display: flex;
        justify-content: space-between;
        flex-flow: column wrap;
        align-items: center;
        width: 100%;
        text-align: left;
        border-radius: 0.4rem;
        gap: $space-1;

        @include min-screen($screen__l) {
            flex-direction: row;
            text-align: center;
            row-gap: normal;
        }

        .order-request-buttons-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: $space-2;

            @include min-screen($screen__l) {
                flex-direction: row;
                width: auto;
            }
        }

        .action.primary {
            padding: 1.2rem;
            font-weight: $font-weight-medium; // Keep to prevent magento-ui to change dc__btn style
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            width: 100%;

            @include min-screen($screen__l) {
                min-width: 18rem;
                width: auto;
            }
        }

        a {
            &.save {
                margin-bottom: 0;
            }

            &.cancel {
                border-color: $color-primary !important;
                min-width: 18rem;
                width: auto;
            }
        }

        ~ [class*='#{$css-namespace}alert-banner'] {
            margin-top: $space-2;
        }
    }

    &-label {
        font-size: $font-size-default;
        font-weight: $font-weight-bold;
    }
}

.sales-order_view-weborder,
.sales-order_view-toapprovalorderrequest,
.sales-order_view-toapprovalorderrequestedit,
.sales-order_view-pendingorderrequest {
    @include min-screen($screen__m) {
        .#{$css-namespace}order-items_table {
            table-layout: auto;
        }

        .#{$css-namespace}item-table {
            &_header .col.qty,
            &_row-qty {
                width: 14rem;
            }

            &_header .col.subtotal,
            &_row-price {
                width: 32%;
                min-width: 23rem;
            }

            &_row-price {
                text-align: right;
            }
        }

        .#{$css-namespace}item-table_header .col.subtotal {
            padding-left: $space-4;
            text-align: right;
        }
    }
}
