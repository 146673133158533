$root: '.#{$css-namespace}loyalty-cms-page';

.#{$css-namespace}loyalty-cms-page-PROLIANS {
    p {
        margin-bottom: $space-2;
    }

    .#{$css-namespace}loyalty_tag {
        font-size: $font-size-m;
    }

    .#{$css-namespace}title {
        &-section {
            margin-bottom: $space-2;
            color: $color-main-dark;
            font-size: 3rem;
            line-height: 1.1;

            @include min-screen($screen__xm) {
                font-size: 5rem;
            }
        }

        &[data-ui-title-level='3'] {
            font-size: $font-size-l;
            line-height: $line-height-default;
            margin-top: $space-5;
            margin-bottom: $space-3;
            color: $color-main-dark;
            padding-top: 0;
        }
    }

    &#{$root} #{$root}__description,
    &#{$root} #{$root}__notice {
        font-size: $font-size-default;
        line-height: #{(20/14)};
    }

    &#{$root} #{$root}__notice {
        color: $color-dark-grey;
    }

    #{$root}__cards {
        gap: $space-3;

        @include min-screen($screen__s) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @include min-screen($screen__xm) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    #{$root}__card {
        box-shadow: var(--box-shadow-light);
        background: $color-white;
        min-height: 0;
        width: 100%;

        &-image {
            min-height: 10.7rem;
            height: auto;
            background-color: $loyalty-PROLIANS-purple;
            background-image: none;
            padding: $space-2;
            display: flex;
            flex-direction: column;
            gap: $space-1;
            align-items: flex-start;
            justify-content: center;
            color: $color-white;

            &[data-image="easy-program"] {
                background: linear-gradient(264deg, rgba(121, 58, 176, 0.00) 0.64%, rgba(121, 58, 176, 0.49) 0.64%, #793AB0 80.78%), url('../Descours_UserAccount/images/PROLIANS/josiane-pichet.jpeg') lightgray 50% / cover no-repeat;
            }

            &[data-image="shop"] {
                background-color: $loyalty-PROLIANS-green;
            }

            span {
                line-height: #{(16/14)};
                margin-top: -$space-1 / 2;
            }
        }

        &-content {
            padding: $space-3;

            .#{$css-namespace}loyalty_list_entry {
                gap: $space-2;
            }
        }
    }
}
