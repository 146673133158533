@import 'add_cart_layer/add_cart_layer';
@import 'add_cart_slot/add_cart_slot';
@import 'add_cart_slot/add_cart_slot_v2';
@import 'availability/availability';
@import 'chip/chip';
@import 'round_light/round_light';
@import 'product_slot/slot';

.#{$css-namespace}flex_filler {
    display: flex;
    flex-grow: 1;

    &:empty {
        display: none;
    }
}
