.product-info-custom-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border-radius: 0.3rem;
    padding: $space-3 $space-2 $space-5;
    margin-bottom: $space-2;
    width: 100%;
    box-shadow: var(--box-shadow-light);

    @include min-screen($screen__m) {
        padding: $space-3 $space-4 $space-5;
    }
}

.#{$css-namespace}productBrandTitle {
    color: $color-main-dark;
    font-family: $font-secondary;
    font-size: $font-size-default;
    font-weight: $font-weight-bold;
    text-decoration: underline;
    line-height: 1.9rem;
    margin-bottom: 1rem;
    display: inline-block;
    text-transform: uppercase;

    @include min-screen($screen__l) {
        font-size: $font-size-m;
        line-height: 2.2rem;
        margin-bottom: 0.5rem;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color-main-dark;
        text-decoration: none;
    }
}

.product-info-custom-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @include min-screen($screen__l) {
        flex-direction: row;
    }

    .page-title-wrapper {
        padding: 0;
    }

    .page-title {
        font-family: $font-secondary;
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
        word-break: break-all;
        color: $color-primary;
        line-height: 2.3rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        max-width: 101rem;

        @supports (hyphens: auto) {
            hyphens: auto;
            word-break: normal;
        }

        @include min-screen($screen__l) {
            font-size: $font-size-xxl;
            line-height: 3.6rem;
            -webkit-line-clamp: 2;
            padding: 0.6rem 0 0;
            margin: -0.6rem 0 0;
        }

        span {
            display: block;
        }
    }

    .product-head-wrapper {
        > .product + .product {
            margin-top: $space-1;
            display: flex;
            flex-wrap: wrap;
        }
    }

    .references {
        color: $color-grey-dark;
        line-height: $font-size-m;
        gap: $space-1;

        .attribute {
            position: relative;
            display: flex;
            align-items: center;

            &:not(:last-child):has(.value:not(:empty)) {
                &::after {
                    content: '';
                    display: block;
                    height: 1.2rem;
                    width: 0.2rem;
                    margin-left: $space-1;
                    background-color: $color-main-dark;
                }
            }
        }
    }

    .#{$css-namespace}actions {
        display: inline-flex;
        align-items: center;
        padding: 0;
        list-style: none;
        margin: 0 0 0 $indent__l;
        border-radius: 0.3rem;
        flex-direction: row-reverse;
        position: relative;
        z-index: 3;

        @include max-screen($screen__l) {
            margin-left: auto;
            margin-bottom: -$indent__xl;
            right: 0;
            position: relative;
            top: $indent__l;
            z-index: 1;
        }

        /** WHISLIST OVERRIDE **/
        .action.wishlist {
            .items {
                right: $space-1;

                &::before {
                    right: 1rem;
                    left: inherit !important;
                }

                &::after {
                    right: $space-1;
                    left: inherit !important;
                }
            }
        }

        &_button {
            border: none;
            background: none;
            transition: background 0.3s; // TODO : Uniformise using var(--transition-normal)
            padding: $indent__s;
            max-width: 100%;

            &--print {
                padding: $indent__s $indent__s_l;
            }

            &:hover {
                background: $color-sub-secondary;
                border: none;
            }

            &.valid,
            &:focus {
                background: $color-secondary-darken;
                border: none;
            }

            &,
            .wishlist.split.button {
                .dcfonts-icons {
                    font-size: 2rem;
                    color: $color-white;
                }
            }
        }

        li {
            padding: 0;
            margin: 0;

            & + & {
                margin-top: 2rem;
            }
        }
    }

    .product {
        &.action {
            background: $color-secondary;

            &:only-child {
                .#{$css-namespace}actions_button {
                    padding-right: $space-1;
                }
            }

            &:first-child {
                border-radius: 0 0.3rem 0.3rem 0;

                .#{$css-namespace}actions_button {
                    padding-right: $space-2;
                }
            }

            &:last-child {
                border-radius: 0.3rem 0 0 0.3rem;

                .#{$css-namespace}actions_button {
                    padding-left: $space-2;
                }

                &:first-child {
                    border-radius: 0.3rem;
                }
            }
        }

        .#{$css-namespace}actions_button {
            padding: $space-1;

            &.added {
                background-color: $color-sub-secondary;
                border-radius: 0 0.3rem 0.3rem 0;
            }
        }
    }

    .product.issue {
        background: $color-warning;
        width: 100%;
        text-align: center;

        .#{$css-namespace}actions_button:focus {
            background: none;
        }
    }

    .product {
        &.share {
            position: relative;

            #product-url {
                position: absolute;
                top: 0;
                left: 100%;
                height: 0;
                margin: 0;
                padding: 0;
                border: none;
                z-index: -10;
            }
        }
    }
}

.backToProductLink {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    text-decoration-color: $color-secondary;
    margin-top: 2.2rem;

    .catalog-product-view & {
        margin-bottom: 1rem;
    }

    @include min-screen($screen__l) {
        margin-left: 4rem;
    }

    &-icon {
        font-size: 1.7rem;
        color: $color-secondary;
    }

    &-text {
        color: $color-main-dark;
        margin-left: $indent__xs;

        &:hover,
        &:active {
            text-decoration: underline;
        }

        &:active {
            color: $color-primary-darken;
        }
    }
}

.product-info-custom {
    position: relative;
    margin-top: $indent__base;
    gap: $space-3;
    display: flex;
    flex-direction: column;

    @include min-screen($screen__l) {
        flex-direction: row;
        gap: $space-4;
    }

    .product-brand-logo {
        position: absolute;
        top: $indent__s;
        left: $indent__s;
        max-width: 9rem;
        max-height: 5rem;

        @include min-screen($screen__l) {
            left: $indent__base;
        }
    }

    .attribute {
        color: $color-black;
        font-size: $font-size__s;
        font-weight: $font-weight-light;
        font-style: italic;
    }

    .anchor-item {
        display: flex;
        align-items: center;

        .anchor-item {
            flex: 1 0 auto;
            color: $color-gray-light7;
        }

        .icon-fleche {
            flex: 0 0 auto;
            margin-left: $indent__xs;
            @include lib-font-size(12);
            color: $color-sub-secondary;
        }

        + .anchor-item {
            margin-top: $indent__xs;
        }
    }

    .#{$css-namespace}attribut_data {
        flex: 1;
        min-width: 34rem;

        @include min-screen($screen__l) {
            margin-bottom: 0;
        }

        .#{$css-namespace}title-section {
            font-family: $font-secondary;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            margin-bottom: $space-3;

            &,
            span {
                color: $color-main-dark;
                font-size: $font-size-default;
                line-height: 1.9rem;
                display: inline-block;

                @include min-screen($screen__l) {
                    font-size: $font-size-m;
                    line-height: 2.2rem;
                    display: inline-block;
                }
            }
        }

        .attributes.data {
            li + li {
                margin-top: 0.3rem;
            }
        }

        .attribute {
            font-style: normal;

            .name,
            .value {
                padding: 1rem 1.5rem;
            }

            .name {
                flex: 0 0 40%;
            }

            .value {
                color: $color-grey-dark2;
            }
        }
    }
}
