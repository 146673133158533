//
// Cuts a corner with white color by default.
//
// - To override the default cut width, set a custom CSS class with '::before' and
//   define 'border-width'.
// - To change the cut color, depending on the cut position,
//   set 'border-top-color' or 'border-bottom-color' in your custom CSS class.
//
// (using single line comments to not output them in CSS file)
//
.#{$css-namespace}cut-corner {
    position: relative;

    &::before {
        position: absolute;
        content: '';
    }

    &--top {
        &-left::before {
            top: 0;
            left: 0;
            border-top: 2.5rem solid $color-white;
            border-right: 2.5rem solid transparent;
        }

        &-right::before {
            top: 0;
            right: 0;
            border-top: 2.5rem solid $color-white;
            border-left: 2.5rem solid transparent;
        }
    }

    &--bottom {
        &-left::before {
            bottom: 0;
            left: 0;
            border-bottom: 2.5rem solid $color-white;
            border-right: 2.5rem solid transparent;
        }

        &-right::before {
            bottom: 0;
            right: 0;
            border-bottom: 2.5rem solid $color-white;
            border-left: 2.5rem solid transparent;
        }
    }

    &--body-background::before {
        border-bottom-color: $color-grey-background;
    }
}
