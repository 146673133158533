// variables
$link-color-text: currentColor;
$link-hover-color-text: currentColor;
$link-icon-size: 2.4rem; // TODO manage links with icons

// abstract link
%link {
    display: inline-flex;
    align-items: center;
    text-decoration: underline;
    color: $link-color-text;
    cursor: pointer;

    // svg icon
    > .#{$css-namespace}icon,
    .dcfonts-icons {
        width: $link-icon-size;
        height: $link-icon-size;
        fill: currentColor;
        text-decoration: none;
    }

    > span {
        flex: 0 1 auto;
        text-align: center;

        + .#{$css-namespace}icon {
            margin-left: $offset-xs;
        }
    }

    @include lib-hover-focus {
        text-decoration: none;
    }
}

// import all components
@import 'link/link';
