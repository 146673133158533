// parent has to be at least relative and with width and height
body {
    > .loading-mask,
    + .loading-mask {
        user-select: none;

        .loader {
            > img {
                [alt] {
                    color: transparent;
                }
            }

            p {
                display: none;
                margin-left: 0;
                translate: -50% -1rem;
            }
        }
    }
}
