.#{$css-namespace}quotation_section_messages {
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.messages.messages--persistent {
    display: block !important;

    + .#{$css-namespace}quotation_section_products-table {
      margin-top: 0;
    }
  }

  .message {
    animation: none;
    color: $color-black;
    border: solid 0.1rem $color-primary;
    background-color: transparent;
    border-radius: 0.1rem;
    @include lib-font-size(14);

    > *:first-child:before {
      animation: none !important;
      color: $color-primary;
      display: inline-block !important;
    }
  }
}
