// quotation bottom part

.column .#{$css-namespace}block-addbysku {
    &_form {
        .useraccount-quotation-index & {
            margin: 2.7rem 0 0 0;
        }

        @include max-screen($screen__l) {
            .useraccount-quotation-index & {
                margin: 3rem 0 0;
            }
        }
    }
}
