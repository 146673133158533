.#{$css-namespace}datepicker {
    max-width: var(--adp-width);

    @at-root .fieldset .control #{&} {
        &--inline {
            padding: 0;
            background: none;
            border: none;
            display: inline;
            line-height: 1;
            height: auto;
            width: auto;
        }

        &[readonly] {
            color: inherit;
            background: transparent;
        }
    }
}
