@import 'checkbox/checkbox';
@import 'radio/radio';
@import 'quantity-picker/quantity-picker';
@import 'radio-button/radio-button';
@import 'text/text';
@import 'air-datepicker/base';
@import 'select/module';
@import 'textarea/module';

div.mage-error {
    &:not(:empty) {
        &::before {
            content: $close-circle-2;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-family: $font-icons;
            font-size: $font-size-m;
        }

        &.force-visible:not(.hidden):not(.d-none) {
            display: block !important;
        }
    }
}
