%disabled_add_cart_slot_btn {
    background-color: $mainNeutralColor;
    display: flex;
    justify-content: center;

    &::after {
        font-family: $font-icons;
        background: none;
        content: $cart;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $disabledTextColor;
        font-size: 2.4rem;
    }

    > svg {
        fill: $color-white;
    }

    .dcfonts-icons {
        color: $color-white;
    }
}

.#{$css-namespace}add_cart_slot {
    $root: &;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $color-text-default;
    gap: $space-1;

    .slick-list &,
    .#{$css-namespace}equivalent-products & {
        gap: 0;
    }

    &[data-delivery-mode='online'] {
        .slick-list &,
        .#{$css-namespace}equivalent-products & {
            background-color: $color-secondary;
        }
    }

    &[data-delivery-mode='pickup'] {
        .slick-list &,
        .#{$css-namespace}equivalent-products & {
            background-color: $color-accent;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 2.5rem;

        .slick-list &,
        .#{$css-namespace}equivalent-products & {
            color: $color-white;
        }

        #{$root}__header_title {
            font-size: $font-size-default;
            font-weight: $font-weight-semi-bold;
        }

        #{$root}__header_icon {
            font-size: 1.7rem;
            color: $color-white;

            &.dcfonts-icons--truck {
                font-size: 2.1rem; // Padding in few icons is incredible
            }

            &--hidden {
                display: none;
            }
        }

        #{$root}__header_icon + #{$root}__header_title {
            margin-left: $space-1;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .slick-list &,
        .#{$css-namespace}equivalent-products & {
            background-color: $color-white;
            padding: $space-1;
        }

        #{$root}__form {
            display: flex;
            flex-direction: row;
            flex: 1 0 4rem;

            @include min-screen($screen__l) {
                width: 100%;
                flex: none;
            }

            #{$root}__quantity_button {
                @extend %quantity-picker-button !optional;
            }

            #{$root}__quantity_input {
                @extend %quantity-picker-input !optional;
            }

            #{$root}__add_button {
                margin-left: $space-1;
                padding: 0;
                max-width: 4rem;
                display: flex;
                align-items: center;
                border-radius: 0.3rem;
                $add_cart_btn_icon_size: 2.2rem;

                &--icon {
                    @include lib-background-image('svg/_clean/white/cart.svg', center, no-repeat, transparent, false);
                    background-size: $add_cart_btn_icon_size;
                    min-height: $add_cart_btn_icon_size;
                    min-width: $add_cart_btn_icon_size;

                    &.#{$css-namespace}add_cart_single {
                        min-width: 4rem;
                    }
                }

                span {
                    font-size: $font-size-default;
                    margin: 0;
                    flex: none;
                    line-height: 1;
                }

                &,
                &:hover,
                &:active,
                &:focus {
                    border: none;
                }

                > svg {
                    width: $add_cart_btn_icon_size;
                    height: $add_cart_btn_icon_size;
                }
            }
        }

        & .#{$css-namespace}availability {
            align-self: flex-start;
        }
    }

    &__content--success {
        position: absolute;
        top: 2rem;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        z-index: 1;
        width: 100%;

        .dcfonts-icons--check {
            color: $color-success;
            margin-right: 0.5rem;
        }

        span {
            color: $color-primary;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__delivery_info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;

        .dcfonts-icons--info {
            color: $color-primary;
        }

        #{$root}__delivery_info_icon {
            width: 1.5rem;
            height: 1.5rem;
            font-size: 1.5rem;
            color: $color-dark-grey;
        }

        #{$root}__delivery_info_text {
            margin-left: $offset-xs;
            font-size: $font-size-xs;
            color: $color-dark-grey;
            line-height: 1;
            padding-top: 0.2rem;
        }

        #{$root}__delivery_info_icon:hover ~ .#{$css-namespace}tooltip_container,
        #{$root}__delivery_info_text:hover ~ .#{$css-namespace}tooltip_container {
            display: block;
        }

        .#{$css-namespace}tooltip_container {
            display: none;
            left: 0;
            width: 29rem;
            max-width: 100%;
            top: 0;
            bottom: auto;
            z-index: 2;

            .#{$css-namespace}tooltip_container_arrow {
                display: none;
            }
        }
    }

    &__store_location_text {
        margin-top: 0.5rem;
        font-size: $font-size-xs;
    }

    &[data-delivery-mode='online'] {
        #{$root}__form {
            #{$root}__add_button {
                &:disabled {
                    @extend %disabled_add_cart_slot_btn;
                }
            }
        }

        #{$root}__store_location_text {
            display: none;
        }
    }

    &[data-delivery-mode='pickup'] {
        #{$root}__form {
            #{$root}__add_button {
                &:not(:disabled) {
                    background-color: $color-checkout-delivery-pickup;
                }

                &,
                &:hover,
                &:active,
                &:focus {
                    color: $color-main-light;
                    fill: $color-main-light;
                }

                &:hover,
                &:active,
                &:focus {
                    background-color: $color-accent-darken;
                }

                &:disabled {
                    @extend %disabled_add_cart_slot_btn;
                }
            }
        }

        #{$root}__delivery_info {
            display: none;
        }
    }
}
