.modals-wrapper {
    &.-modal--right-position {
        .modal-inner-wrap {
            height: 100%;
            max-height: none;
            margin-right: 0;
            margin-left: auto;
            background-color: $color-grey-background;
            transform: translateX(100%) translateY(0);
        }

        .modal-popup {
            align-items: start;
            overflow-y: hidden;

            &._show .modal-inner-wrap {
                transform: translate(0);
            }
        }
    }
}
