.#{$css-namespace}product_promo {
    font-size: $font-size-s;
    display: inline-flex;
    align-items: center;
    gap: $space-1;

    > span:last-child {
        margin-right: 0;
    }

    &__old_price,
    &__percent {
        &:empty {
            display: none;
        }
    }

    &__old_price {
        text-decoration: line-through;
        color: $color-main-dark;
        font-size: $font-size-s;
        line-height: 1;
    }

    &__percent {
        padding: #{$space-1 / 2} $space-1;
        border-radius: 0.3rem;
        font-size: $font-size-s;
        color: $color-red-promo;
        background-color: rgba($color-red-promo, 0.1);
        font-weight: $font-weight-semi-bold;
        line-height: 1.5;

        .has_promo & {
            background-color: $color-white;
        }

        .has_promo.--disconnected & {
            background-color: rgba($color-red-promo, 0.1);
        }
    }
}
