$card-width: 29.7rem;
$card-width-larger: 25.5rem !default;
$card-height: 26rem;
$card-height-desktop: 12rem;
$card-height-mobile: 17rem;
$card-recap-width: 24rem;
$fixed-card-size: 22.5rem;
$card-width-fixed-screen-width: 1340px !default; // Keep px for media query

.catalog-events {
    margin: $space-4 0; // To fit with gap in homepage sliders

    .#{$css-namespace}event-showcase_header {
        display: none;

        @include min-screen($screen__s) {
            display: block;
        }
    }
}

.catalog-events-recap {
    @include min-screen($screen__m) {
        padding-bottom: 1rem;
    }

    .catalog-events-title {
        padding: $indent__l $indent__l 0;

        @include max-screen($screen__l) {
            padding-top: 0;
            margin-top: -1rem;
        }
        @include min-screen($screen__l) {
            padding: 0;
        }
    }

    .catalog-events-recap-list {
        height: 100%;
        margin: 0;
        overflow: visible;
        visibility: visible;
        opacity: 1;
        display: grid;
        flex-wrap: wrap;

        @include min-screen($screen__s) {
            position: relative;
            grid-gap: $space-3;
        }

        @include max-screen($screen__s) {
            display: block;
            padding-top: $indent__base;

            .slick-slide {
                > div {
                    margin: 0 #{$space-3 / 2};
                }
            }
        }

        .catalog-events-card-image {
            img {
                margin: 0 !important; //overwrite margin from Theme cms page
                height: 100% !important; //overwrite margin from Theme cms page
            }
        }
    }
}

.catalog-events-wrapper {
    position: relative;

    .slick-arrow {
        position: absolute;
        right: 100%;
        top: calc(50% - 1.6rem);

        display: none;
        width: 2.2rem;
        height: 3.2rem;
        padding: 0;
        border-color: transparent;
        background: url(../images/arrow.svg) no-repeat top left;

        &.slick-next {
            right: auto;
            left: 100%;

            transform: rotate(180deg);
        }
    }
}

.catalog-events-fixed-card {
    position: relative;
    display: none;

    @include min-screen($screen__s) {
        display: block;
    }

    .catalog-events-card {
        display: flex;
        align-items: center;
        padding: $indent__s 0 $indent__s $indent__l;
        min-height: 0;
        border-radius: 0;
        background-color: $color-primary;
    }

    .catalog-events-card-content {
        max-width: 18rem;
        flex: 0 1 auto;
    }

    .arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 6rem;
        overflow: hidden;

        &::after {
            content: ' ';
            position: absolute;
            left: 7.2rem;
            top: calc(50% - 7rem);
            width: 14rem;
            height: 14rem;
            transform: rotate(45deg);
            background-color: $color-secondary;
            box-shadow: 0 0 0 3rem rgba($color-main-light, 0.12); // TODO: Use box-shadow var
            outline-color: rgba($color-main-light, 0.12); // TODO: Use global var
        }
    }
}

.catalog-events-slider,
.catalog-events-recap-list {
    margin: $space-3 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;

    &.slick-initialized {
        height: auto;
        overflow: visible;
        visibility: visible;
        opacity: 1;
    }

    .slick-track {
        display: inline-flex;
        vertical-align: bottom;
    }

    &.no-scroll {
        .slick-slide {
            @include min-screen($screen__m) {
                width: 23.6rem !important;
            }
        }
    }

    .slick-list {
        margin: 0 -#{$space-1};
        padding: 0 15% 0 0;

        @include min-screen($screen__m) {
            padding: 0;
        }
    }

    .slick-slide {
        height: auto;

        > div {
            height: 100%;
            margin: 0 #{$space-3 / 2};
        }
    }

    .slick-dots {
        margin-top: $indent__s;
    }

    .catalog-event-slider-container {
        display: flex !important; // Overwrite js component inline style
        flex-direction: column;
        height: 100%;
        border-radius: 0.3rem;
        overflow: hidden;
    }
}

.catalog-events-card {
    display: flex;
    flex-direction: row;
    height: 100%;
    text-align: left;
    color: $color-text-invert;

    &.link {
        color: $color-text-invert;
        text-decoration: none;

        &:hover {
            color: $color-text-invert;

            .catalog-events-card-image {
                figure {
                    &::after {
                        transform: translate3d(0, 100%, 0);
                    }
                }
            }
        }
    }
}

.catalog-events-card-inner {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background: $color-white;

    @include min-screen($screen__s) {
        flex-direction: column;
    }
}

.catalog-events-card-image {
    position: relative;
    display: flex;
    z-index: 1;
    overflow: hidden;
    min-height: 18.1rem;
    max-width: 11.2rem;

    @include min-screen($screen__s) {
        min-height: auto;
        height: 16rem;
        max-width: 100%;
    }

    picture,
    figure {
        width: 100%;
        height: 100%;
        position: relative;

        &::after {
            content: ' ';
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background: linear-gradient(to bottom, rgba($color-black, 0), rgba($color-black, 1));
            opacity: 0.3;
            transition: transform 0.3s ease; // TODO : Uniformise using var(--transition-normal)
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.catalog-events-card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $space-1;
    padding: $space-2;
    font-family: $font-secondary;

    &:last-of-type {
        flex: 1;
        background-color: $color-white;
        color: $color-dark-grey;
        margin-top: auto;
        margin-bottom: auto;

        @include min-screen($screen__s) {
            margin-top: auto;
            margin-bottom: 0;
        }
    }

    .#{$css-namespace}icon {
        color: $color-secondary;
        font-size: $font-size-default;
        width: auto;
        height: auto;
        margin-top: auto;
    }

    h4,
    h5,
    p {
        margin: 0;
    }

    h5 {
        align-self: flex-start;
        font-size: $font-size-s;
        font-weight: $font-weight-regular;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
    }
}

.catalog-events-card-content-inner {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    gap: $space-1 / 2;
    min-height: 6.2rem;

    &:last-of-type {
        font-family: $font-default;
        color: $color-dark-grey;
        min-height: 0;
        justify-content: space-between;
        margin-top: auto;
    }

    h4 {
        line-height: 1.286;
    }
}

@include min-screen($screen__s) {
    .catalog-events-wrapper {
        display: flex;
        justify-content: center;
        margin-top: $space-3;
    }

    .catalog-events-fixed-card {
        flex: 0 0 auto;
        padding-right: $space-3;
        width: $fixed-card-size;

        .catalog-events-card {
            min-height: $card-height;
            padding: $indent__s 0;
        }

        .catalog-events-card-content {
            width: 100%;
            max-width: 100%;
            padding: 0 $space-2;
            background-color: transparent;
            color: $color-white;
            margin-bottom: auto;
        }

        .catalog-events-card-title {
            margin: 0;
            font-size: $font-size-xl;
            line-height: 2.5rem;
            font-weight: $font-weight-semi-bold;

            strong {
                font-weight: $font-weight-semi-bold;
            }
        }

        .arrow {
            right: $space-3;

            &::after {
                top: $indent__s;
            }
        }
    }

    .catalog-events-slider {
        flex: 1 1 auto;
        width: calc(100% - #{$fixed-card-size});
        margin: 0 -#{$indent__s} 0 0;
    }

    .catalog-events-card {
        min-height: $card-height;
    }
}

@include screen($screen__m, $card-width-fixed-screen-width) {
    .catalog-events-fixed-card {
        width: $card-width-larger;
    }

    .catalog-events-slider {
        width: calc(100% - #{$card-width-larger});
    }
}
