.#{$css-namespace}category-description {
    padding: $space-4 $space-2;

    @include min-screen($screen__l) {
        padding: $space-4 $space-5;
    }

    .column.main & {
        @include min-screen($screen__l) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    h1, h2, h3 {
        @extend %title !optional;
        margin: 0 0 $space-2;

        &:not(:first-child) {
            margin-top: $space-5;
        }
    }

    h1 {
        @extend %title-level-1 !optional;
    }

    h2 {
        @extend %title-level-2 !optional;
    }

    h3 {
        @extend %title-level-3 !optional;
    }

    p {
        color: $color-dark-grey;
        font-weight: $font-weight-semi-bold;
        line-height: (20/14);
        margin-bottom: $space-2;
    }
}
