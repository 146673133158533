.#{$css-namespace}brands-page {
    .#{$css-namespace}brands-cms {
        // header block
        > div {
            text-align: center;
            max-width: 80rem;
            margin: 0 auto;
            padding: $indent__xl $indent__base;

            h1 {
                @include lib-font-size(48);
                font-weight: $font-weight__bold;
                margin-bottom: $indent__base;
                color: $color-cms-brand-index-title;
            }

            p {
                margin-bottom: 0;

                + p {
                    margin-top: $indent__s;
                }
            }

            @include min-screen($screen__l) {
                padding: $indent__l $indent__base $indent__xl $indent__base;
            }
        }

        > section {
            padding: $indent__xl $indent__base $indent__base;
            background-color: #f0f0f0; // TODO: Use global var

            h1 {
                margin-bottom: $indent__xs;
                @include lib-font-size(24);
                font-weight: $font-weight__bold;
                text-align: center;
            }

            ul {
                display: flex;
                overflow-x: auto;
                margin: 0;
                padding: 0 0 $indent__base;
                list-style: none;

                > li {
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: ($indent__s * 2) $indent__s 0;
                    width: 17rem;
                    height: 8.5rem;
                    padding: $indent__s;
                    background-color: $color-white;
                    box-sizing: border-box;

                    img {
                        display: block;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }

    .#{$css-namespace}brands-index {
        padding: $indent__xl $indent__base;

        > header {
            h1 {
                @include lib-font-size(24);
                font-weight: $font-weight__bold;
                text-align: center;
                margin-bottom: $indent__base;
            }

            .#{$css-namespace}brands-nav {
                display: flex;
                justify-content: center;
                position: relative;
                margin-top: $indent__base;

                > ul {
                    flex: 0 0 auto;
                    display: none;
                    justify-content: center;
                    flex-wrap: wrap;
                    max-width: 100%;
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    > li {
                        margin: 0;
                        min-width: 3.6rem;
                        border: 0.1rem solid $border-color__base;

                        > a {
                            display: block;
                            padding: $space-1;
                            text-align: center;
                            color: $color-cms-brand-index-link;

                            &:hover,
                            &:focus {
                                text-decoration: underline;
                            }
                        }

                        + li {
                            border-left: 0.1rem solid $border-color__base;
                        }
                    }
                }

                .#{$css-namespace}brands-select-wrapper {
                    max-width: 20rem;

                    label {
                        @include lib-visually-hidden();
                    }
                }
            }
        }

        .#{$css-namespace}brands-list {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: $indent__xl 0 0;
            padding: 0;

            > li {
                margin: 0;
                padding: 0;

                + li {
                    padding-top: 6.8rem;
                }
            }

            .#{$css-namespace}brand-letter {
                display: block;
                border-bottom: 0.1rem solid $border-color__base;
                margin-bottom: $indent__s;
                @include lib-font-size(24);
                font-weight: $font-weight__bold;
            }

            .#{$css-namespace}brands-items {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin: $indent__xs 0 0;
                padding: 0;

                > li {
                    flex: 0 0 auto;
                    width: 100%;
                    padding: 0 $indent__s;
                    margin: 0;
                    box-sizing: border-box;

                    > span,
                    > a {
                        display: inline-flex;
                        padding: $indent__xs;
                        color: $color-cms-brand-index-link;
                    }

                    > a {
                        @include lib-hover-focus {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        @include min-screen($screen__xs) {
            > header {
                .#{$css-namespace}brands-nav {
                    > ul {
                        display: inline-flex;
                    }

                    .#{$css-namespace}brands-select-wrapper {
                        display: none;
                    }
                }
            }

            .#{$css-namespace}brands-list {
                .#{$css-namespace}brands-items {
                    > li {
                        width: 50%;
                    }
                }
            }
        }

        @include min-screen($screen__m) {
            .#{$css-namespace}brands-list {
                .#{$css-namespace}brands-items {
                    > li {
                        width: (100%/3);
                    }
                }
            }
        }

        @include min-screen($screen__l) {
            padding: $indent__xl 0;

            .#{$css-namespace}brands-list {
                .#{$css-namespace}brands-items {
                    > li {
                        width: 25%;
                    }
                }
            }
        }
    }

    @include min-screen($screen__s) {
        .#{$css-namespace}brands-cms {
            > section {
                padding-bottom: $indent__xl;

                ul {
                    flex-wrap: wrap;
                    justify-content: center;
                    padding-bottom: 0;
                }
            }
        }
    }
}

.#{$css-namespace}brands-page-details {
    .page-main {
        margin-top: $indent__base;
    }

    .page-title-wrapper {
        padding: 0 $indent__base;
        margin-top: 3rem;

        .page-title {
            margin-bottom: $indent__s;
        }
    }

    .#{$css-namespace}brands-description {
        margin: 0;
    }

    .#{$css-namespace}brands-header {
        padding: 0 $indent__base;
        margin-bottom: 3rem;

        .#{$css-namespace}brands-logo-wrapper {
            max-width: 15rem;

            + .#{$css-namespace}brands-description {
                margin-top: $indent__s;
            }
        }
    }

    @include min-screen($screen__l) {
        .page-title-wrapper {
            padding: 0;
        }

        .#{$css-namespace}brands-header {
            padding: 0;
        }
    }
}

.brands-index-index {
    .breadcrumbs {
        display: none;
    }
}
