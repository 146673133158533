.wishlist.window.popup {
    top: auto;
    left: auto;
    border: none;

    &.active {
        display: flex;
    }

    fieldset {
        margin: 0;
    }

    .modal-content {
        overflow-x: hidden;
        padding-top: $space-1; // avoid cut label when input is focused
    }

    #select-wishlist-form .field {
        position: relative;
        margin-top: $space-1;
    }
}
