.footer_links {
    ul {
        margin-bottom: $space-2 !important;
        line-height: 2rem;

        li {
            @include lib-typography(
                $_font-size: 1.4rem,
                $_color: $text__color,
                $_font-family: $subFont,
                $_font-weight: $font-weight__regular,
                $_line-height: 1.9rem,
                $_font-style: $font-style__base
            );

            &::before {
                @include lib-css(color, $color-sub-secondary);
                @include lib-font-size(0.9rem);
                content: '●';
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.5rem;
            }

            a {
                @include lib-css(color, $mainFontColor);
                display: inline-block;
                vertical-align: middle;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .univers {
        margin-top: 2.5rem;

        ul {
            li {
                a {
                    @include lib-css(color, $color-sub-secondary);
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .footer_links {
        h4 {
            padding: 1.5rem;
            @include lib-font-size(1.6rem);
        }

        .block- {
            &left {
                margin-top: 1.5rem;
            }

            &right {
                margin-bottom: 1.5rem;
            }
        }
    }
}

@include screen($screen__xs, $screen__l) {
    .footer_links {
        h4 {
            padding: 1.5rem;
            @include lib-font-size(2rem);
        }
    }
}

@include max-screen($screen__l) {
    .footer_links {
        &-block {
            h4 {
                overflow: hidden;

                &::after {
                    content: '';
                    right: 2rem;
                    top: 1rem;
                    position: absolute;
                    @include lib-background-image('svg/fleche-simple.svg', 100% center, no-repeat, transparent);
                    background-size: cover;
                    width: 7.5rem;
                    height: 7.5rem;
                    color: #adb0b3; // TODO: Use global var
                }
            }
        }

        h4 {
            @include lib-typography(
                $_font-size: 1.4rem,
                $_color: $text__color,
                $_font-family: $subFont,
                $_font-weight: $font-weight__bold,
                $_line-height: 1.9rem,
                $_font-style: $font-style__base
            );
            position: relative;
            background: $color-gray-light6;
            border-bottom: 0.1rem solid #adb0b3; // TODO: Use global var
            margin: 0;

            &.active {
                &::after {
                    transform: rotate(180deg);
                    top: -3.5rem;
                    right: -4rem;
                }
            }
        }

        .links-container {
            display: none;
        }

        .footer-links-list {
            padding: 1.5rem 1.5rem 0 !important;
        }
    }
}

@include min-screen($screen__l) {
    .footer_links {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-wrap(wrap);
        @include lib-vendor-prefix-flex-direction(row);
        width: 60%;

        h4 {
            @include lib-typography(
                $_font-size: 1.8rem,
                $_color: $mainFontColor,
                $_font-family: $subFont,
                $_font-weight: $font-weight__bold,
                $_line-height: normal,
                $_font-style: $font-style__base
            );
            display: inline-block;
            border-bottom: 0.1rem solid #a8acaf; // TODO : Use a global var
            margin: 0 0 $indent__s;
            padding: 0 0 1.5rem 0;
        }

        > div {
            @include lib-vendor-prefix-flex-basis(50%);
        }

        .block {
            &-left {
                h4 {
                    width: 60%;
                }
            }

            &-right {
                h4 {
                    width: 100%;
                }
            }
        }
    }
    .payment.secure {
        float: left;
        margin-top: 2rem;
        display: none;
    }
}
