.companyRow.selected {
    .col {
        &.action {
            .primary {
                padding: 0;
                color: $mainFontColor;
                border: 0;
                background: none;

                span {
                    display: inline-block;
                    width: 8rem;
                    vertical-align: middle;
                }
            }
        }
    }
}

.table-companies-list {
    .companyRow {
        box-sizing: border-box;
        margin-bottom: $indent__l;
        padding: $indent__m;
        border: 0.1rem solid $color-gray94;
        border-radius: 0.4rem;

        &.selected {
            border: 0.2rem solid $color-green1;

            .actions {
                .primary {
                    .action {
                        &::after {
                            @include lib-background-image(
                                'svg/coche-blanche.svg',
                                center,
                                no-repeat,
                                $color-green1,
                                $_module: false
                            );

                            display: inline-block;
                            box-sizing: border-box;
                            width: 2.5rem;
                            height: 2.5rem;
                            content: '';
                            text-align: center;
                            vertical-align: middle;
                            color: $clearColor;
                            border-radius: 100%;
                            background-size: 55%;
                        }
                    }
                }
            }
        }

        .col {
            &:first-child {
                padding-top: 0 !important;
            }

            &:last-child {
                padding-bottom: 0 !important;
            }

            margin: 0;

            &.range {
                .icon-range {
                    @include lib-background-image(
                        'png/position@3x.png',
                        center,
                        no-repeat,
                        transparent,
                        $_module: false
                    );

                    display: block;
                    width: 2.5rem;
                    height: 3.2rem;
                    margin-bottom: 0.5rem;
                    background-size: contain;
                }

                .range-value {
                    @include lib-font-size(14);

                    font-family: $subFont;
                }
            }

            &.name {
                line-height: 1.75;

                h3 {
                    font-weight: $font-weight__bold;
                    margin-bottom: 0.5rem;
                    color: $mainFontColor;
                }

                ul {
                    line-height: 1.8rem;
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        margin: 0;
                    }
                }
            }

            &.action {
                width: 14rem;
                padding: 0;

                .primary {
                    width: 100%;
                    height: 5rem;
                }
            }
        }

        .col {
            padding: $space-1 1rem;
        }
    }
}

@include max-screen($screen__l) {
    .actions {
        > .secondary {
            margin-bottom: $indent__l;
        }
    }

    .table-wrapper {
        margin: 0;
    }

    .table-companies-list {
        .companyRow {
            display: flex;
            align-items: center;
            flex-flow: row wrap;

            .col {
                padding: 0 !important;

                &.siret {
                    display: none;
                }

                &.range {
                    display: block;
                    align-items: center;

                    justify-items: left;

                    @media (orientation: portrait) {
                        flex-basis: 50%;
                        flex-grow: unset;
                    }

                    .icon-range {
                        display: inline-block;
                        flex: initial;
                        margin: 0;
                        margin-right: 0.6rem;
                        vertical-align: middle;
                    }
                }

                &.name {
                    @media (orientation: portrait) {
                        flex-basis: 50%;
                    }

                    h3 {
                        @include lib-font-size(14);
                    }
                }

                &.action {
                    @media (orientation: portrait) {
                        margin: 0 auto;
                        margin-top: 2.5rem;
                    }
                }
            }
        }
    }

    .accountopeningrequest-side {
        margin-bottom: $indent__l;
    }
}

@include min-screen($screen__l) {
    .table-companies-list {
        .companyRow {
            @include lib-vendor-prefix-display(flex);

            align-items: center;
            flex-flow: row wrap;

            &:last-child {
                margin: 0;
            }

            .col {
                padding-top: 0;
                padding-bottom: 0;

                flex: none;
                float: left;

                &.siret {
                    flex: 1;
                    padding-left: 0;
                }

                &.name {
                    flex: 1;

                    h3 {
                        margin-top: 0;
                        @include lib-font-size(16);
                    }
                }

                &.action {
                    float: right;
                }
            }
        }
    }
}
