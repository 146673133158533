.#{$css-namespace}badge--tag {
    @extend %badge;
    background-color: $badge-color-tag-background;
    border: none;
    border-radius: $badge-border-radius-light;
    padding: $badge-padding;
    color: $badge-color-tag;
    font-weight: $badge-text-weight;
    font-size: $badge-text-size;
    line-height: $badge-text-line-height;
    pointer-events: none;

    &[data-ui-size='m'] {
        font-size: $font-size-default;
        padding: $space-1 $space-2;
        font-weight: $font-weight-regular;
    }

    &[data-ui-skin='secondary'] {
        background-color: $color-blue-promo;
    }

    &[data-ui-skin='green'] {
        background-color: $color-green-promo;
    }

    &[data-ui-skin='purple'] {
        background-color: $color-purple;
    }

    &--have-events {
        pointer-events: inherit;
    }
}
