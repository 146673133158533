$summary_padding: 1.5rem;
$summary_padding-bottom: 1.4rem;
$summary-banner-background: #dbf7ea; // TODO: Use global var
$summary-shadow: 0.2rem 0.4rem 0.6rem rgba($color-black, 0.12);
$summary-shadow-mobile: 0 -0.2rem 0.4rem 0 rgba($color-black, 0.1);
$discount-coupon-remove: 1.35rem;
$summary-top: 3.4rem;

.#{$css-namespace}summary {
    background: $color-white;
    padding: $space-4;
    display: flex;
    flex-direction: column;
    gap: $space-2;

    &-container {
        margin: 0 -#{$indent__xs} $space-1;

        @include min-screen($screen__l) {
            margin-bottom: $space-2;
        }

        @at-root body > .loading-mask[style='display: block;'] ~ .page-wrapper ._block-content-loading#{&},
        body > .loading-mask:not([style]) ~ .page-wrapper ._block-content-loading#{&} {
            .loading-mask {
                display: none;
            }
        }

        .checkout-index-index & {
            @include min-screen($screen__l) {
                margin-top: $summary-top;
                height: 100%;
            }
        }
    }

    &-wrapper {
        position: relative;
        margin: $space-2 auto 0;
        width: 38.7rem;
        max-width: 100%;
        float: none;
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            margin: 0 0 0 $space-3;
            filter: drop-shadow(0 0.4rem 0.6rem rgba($color-black, 0.12));

            &--sticky {
                position: sticky;
                top: calc(var(--header-height) + #{$summary-top});
            }
        }
    }

    > * {
        margin: 0;
    }

    &-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include min-screen($screen__m) {
            align-items: center;
        }

        .#{$css-namespace}title {
            &[data-ui-title-level='2'] {
                font-size: $font-size-xxl;
                line-height: 1.167;
            }
        }
    }

    .cart-summary-collapse {
        display: none;
    }

    &-table-wrapper {
        position: static;
        margin-bottom: 0;
        overflow: visible;
    }

    &-table {
        @include max-screen($screen__l) {
            border-bottom: none;
        }

        tr {
            th,
            td {
                padding: 0 0 $space-2;
                vertical-align: middle;
                line-height: 1.429;
                font-weight: $font-weight-regular;
            }

            td {
                text-align: right;
                font-weight: $font-weight__bold;
            }

            &:last-child {
                th,
                td {
                    padding: 0;
                }
            }

            &.padding-bottom-160 {
                th,
                td {
                    padding-bottom: $space-2;
                }
            }

            &.padding-bottom-0 {
                th,
                td {
                    padding-bottom: 0;
                }
            }

            &.totals {
                &.weee {
                    .label {
                        font-weight: $font-weight-regular;
                    }
                }

                th,
                td {
                    .label {
                        vertical-align: inherit;
                    }

                    .price-discount {
                        color: $color-red-promo;
                    }
                }
            }

            &.summary__shipping {
                .label {
                    font-weight: $font-weight-regular;
                }

                th,
                td {
                    padding: 0;
                }

                th {
                    .dcfonts-icons--info:before {
                        font-size: 1.5rem;
                    }
                }

                td {
                    .dcfonts-icons--info:before {
                        font-size: 1.128rem;
                    }
                }
            }

            &.summary__until-free {
                &.padding-bottom-160 {
                    th,
                    td {
                        padding-bottom: $space-2;
                    }
                }
            }

            &.totals-subtotal-incl-tax {
                position: relative;
                padding: $space-2 0;
                border-top: 0.1rem solid $color-disabled-light;
                color: $color-primary;

                &.no-border {
                    border: none;

                    th,
                    td {
                        padding-top: 0;
                    }
                }

                th,
                td {
                    padding-top: $space-2;
                }

                td {
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-image: linear-gradient(
                                90deg,
                                $color-disabled-light $indent__xs,
                                hsla(0, 0%, 100%, 0) 0
                        );
                        background-position: 0;
                        background-size: 0.1rem 0.1rem;
                        background-repeat: repeat-x;
                        height: 0.1rem;
                        width: 100%;
                    }
                }

                .mark {
                    font-size: $font-size-m;
                }

                .price {
                    font-size: 2.2rem;
                    line-height: 1.1591;
                }
            }

            &.totals-tax {
                th,
                td {
                    padding-top: $space-2;
                }

                th {
                    font-weight: $font-weight-regular;
                }
            }

            &.summary {
                &__shipping {
                    th,
                    td {
                        @include max-screen($screen__l) {
                            position: relative;
                        }

                        .#{$css-namespace}tooltip.#{$css-namespace}tooltip--light.#{$css-namespace}tooltip--top {
                            @include max-screen($screen__l) {
                                position: initial;
                            }

                            span {
                                @include max-screen($screen__l) {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    th {
                        .label {
                            margin-right: 0.2rem;
                        }

                        .label,
                        .label ~ * {
                            vertical-align: middle;
                        }

                        .dcfonts-icons {
                            font-size: $font-size-m;
                        }

                        [class*='dc__tooltip'] {
                            > span {
                                width: 27rem;
                                left: -3.8rem;

                                @include max-screen($screen__l) {
                                    left: -1rem !important;
                                }

                                &::after {
                                    left: 3.3rem;
                                    top: 7.3rem;

                                    @include max-screen($screen__l) {
                                        left: 11.5rem !important;
                                    }
                                }
                            }
                        }
                    }

                    .is_free {
                        color: $color-checkout-summary-delivery;

                        &--click-and-collect {
                            color: $color-checkout-summary-delivery-click-collect;
                        }

                        &--light {
                            color: $color-secondary;
                        }

                        ~ .active.#{$css-namespace}tooltip--light > span,
                        ~ .active.hide.#{$css-namespace}tooltip--light:hover > span,
                        ~ .#{$css-namespace}tooltip--light:not(.hide):hover > span {
                            @include max-screen($screen__l) {
                                right: 1.1rem;
                                top: -2.1rem;
                            }
                        }
                    }

                    &-info {
                        color: $color-primary;
                        font-style: italic;

                        .th {
                            font-weight: $font-weight__heavier;
                        }
                    }
                }

                &__shipping-francoport {
                    .info {
                        text-align: left;
                        padding-left: $space-3;
                        padding-top: $indent__xs;
                        padding-bottom: $summary_padding-bottom;
                        @include lib-background-image(
                                'png/arrow_child.png',
                                top left,
                                no-repeat,
                                transparent,
                                'Descours_Checkout'
                        );
                    }
                }

                &__until-free {
                    td {
                        .banner {
                            font-size: $font-size-s;
                            line-height: 1.667;
                            padding: $space-1;
                            background: $summary-banner-background;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    #block-discount {
        background: $color-grey-background;
        border-radius: 0.3rem;

        .title {
            margin: 0;
            padding-right: $space-4;
            cursor: pointer;
            position: relative;

            strong {
                display: block;
                margin: 0;
                padding: $summary_padding;
                font-size: $font-size-default;
                font-weight: $font-weight-medium;
                line-height: 1.9rem;
            }

            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 4.5rem,
                $_icon-font-position: after,
                $_icon-font-display: block
            );

            &::after {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                height: 100%;
                font-weight: $font-weight__regular;
                color: $color-primary;
            }
        }

        &.active {
            .title {
                border-bottom: none;

                &::after {
                    content: $icon-up;
                }
            }

            .content {
                display: block;
            }
        }

        .content {
            display: none;
            padding: $space-1 $space-2 $space-2;

            .fieldset {
                .field {
                    margin: 0;
                    display: flex;
                    gap: $space-1;

                    &::after {
                        display: none;
                    }
                }

                .input-text {
                    flex: 1;

                    &:focus {
                        ~ label {
                            background: $color-grey-background;
                        }
                    }
                }

                .#{$css-namespace}form-field-wrapper {
                    width: 100%;
                }

                .#{$css-namespace}form-field-legal-notice {
                    [data-js-legal-notice-display] {
                        color: $color-primary;
                        font-size: $font-size-m;
                        cursor: pointer;
                        margin-top: $space-1;
                    }
                }

                .discount-coupon-submit {
                    width: 6rem;
                    padding: 0;
                    text-transform: uppercase;
                    justify-content: center;
                }

                .discount-coupon-remove {
                    border: none;
                    padding: 0;
                    background: transparent;
                    position: absolute;
                    right: 1rem;
                    top: calc((#{$custom-input-text-height} / 2) - #{$discount-coupon-remove} / 1.5);
                    color: $color-primary;
                    width: auto !important;

                    .discount-coupon-remove-icon {
                        font-size: $discount-coupon-remove;
                    }
                }

                .discount-coupon-validated-text,
                .discount-coupon-error-text,
                .mage-error {
                    font-size: $font-size-s;
                    line-height: 1.7rem;
                    padding: 0.5rem 0 0 1.5rem;
                    margin-bottom: -1.7rem;
                    margin-top: 0;

                    &::before {
                        display: none;
                    }
                }

                .mage-error[generated='true'] {
                    padding-top: 1.3rem;
                    padding-bottom: 0.1rem;
                }

                .discount-coupon-validated-text {
                    color: $color-success-secondary;
                }

                .discount-coupon-error-text {
                    color: $color-error;
                }

                .actions-toolbar {
                    margin: 0;
                    padding: 1.9rem 3.1rem;

                    > div {
                        float: none;
                        display: block;
                    }
                }
            }

            .#{$css-namespace}input-text[type='text'][disabled] {
                color: $color-main-dark;
                opacity: 1;
            }
        }
    }

    .checkout.methods {
        @include max-screen($screen__l) {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: 0 -0.2rem 0.4rem 0 rgba($color-black, 0.1); // TODO: Use box-shadow var
            background: $color-white;
            padding: 1rem 1.5rem 1.5rem;
            z-index: 10;
        }

        .#{$css-namespace}btn {
            min-width: 29rem;
            width: 100%;

            &--quotation {
                justify-content: center;
            }

            .price {
                @include min-screen($screen__l) {
                    display: none;
                }
            }
        }
    }

    .convertwishlist {
        margin-top: $indent__s;
        width: 100%;

        @include min-screen($screen__l) {
            display: none;
        }
    }

    .action {
        font-weight: $font-weight-medium;

        @include min-screen($screen__l) {
            width: 100%;
        }
    }

    .#{$css-namespace}btn--quotation {
        @include lib-hover-focus {
            border-color: $color-primary !important;
            background-color: $color-primary !important;
            color: $color-white !important;
        }
    }

    &-mention {
        margin-bottom: $space-1;
        font-size: $font-size-xs;
    }
}

@include min-screen($screen__l) {
    .checkout-index-index {
        .modal-inner-wrap {
            margin-left: auto;
            left: 0 !important;

            .changeShopPopinContentForm {
                max-height: unset !important;
            }
        }
    }
}
