.#{$css-namespace}cms-seo-block {
    padding: 5rem $indent__base $indent__m;
    background: $color-grey-background;

    @include min-screen($screen__s) {
        padding: 5rem $indent__xl $indent__m;
    }

    &_title {
        margin: 0 0 1.5rem;
        font-size: $font-size-m;
        font-weight: $font-weight__bold;
        font-family: $font-default;
        line-height: 1.28571;
        color: $color-primary;
    }

    &_content {
        line-height: 1.35;
    }
}
