@import 'nav_item';

.#{$css-namespace}account-nav {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    &_user {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        line-height: 1.6;

        &_account-number {
            color: $color-primary;
            text-transform: uppercase;
            line-height: 1;
        }
    }

    &_link {
        &:hover {
            color: $color-blue3;
        }
    }

    & &_logout-link {
        align-self: center;
        color: $color-secondary;
        text-decoration: underline;

        &:hover,
        &:active {
            text-decoration: none;
        }

        &:active {
            color: $color-primary;
        }
    }

    .#{$css-namespace}account-nav-item + &_logout-link {
        margin-top: $space-2;
    }
}
