$box-content-title-line-height: 2.5rem;

@import 'module/top';
@import 'module/userAccount';
@import 'module/multiuser';
@import 'module/quickorder';
@import '../../Descours_Quotation/styles/quotation';
@import 'module/tableFilters';
@import 'module/collaborator';
@import 'module/dashboard';
@import 'module/quicklinks';
@import 'account_nav/module';
@import 'collaborators/module';
@import 'common/module';
@import 'loyalty/loyaltyprogram';
@import 'loyalty/module';
@import 'common/module';
@import 'approval_flow/module';
@import 'orders/module';

@import './copyPaste/module';
@import './customer_account/module';
@import './account_email_preferences/module';
@import 'module/list-table';
@import './module/contactPage';

@mixin border-title {
    border-width: 0 0 0.1rem;
    border-style: solid;
    border-color: #8d8d8d; // TODO: Use global var
    text-align: center;
    margin-bottom: 3rem !important;
    > strong {
        font-family: $mainFont + ' Bold'; // TODO: Use Campton instead of Proxima Nova
        @include lib-font-size(2.4rem, !important);
        font-weight: bold !important;
        line-height: 2.33 !important;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: $color-black;
    }
}

.page-main .column.main {
    @include min-screen($screen__l) {
        .contact-title {
            margin-top: 3rem;
        }
    }
}

.useropeningrequest-search-index,
.useropeningrequest-confirm-index,
.useropeningrequest-agency-index,
.useropeningrequest-user-index,
.useropeningrequest-account-index {
    background-color: $color-grey-background;
}

.quotation-manage-approve,
.quotation-create-createpost {
    .sidebar {
        display: none;
    }
}

.quotation-index-index {
    .page-wrapper {
        .page-main {
            .columns {
                .main {
                    background-color: inherit !important;
                    padding: 0;
                    border-radius: inherit !important;
                    box-shadow: inherit !important;

                    .#{$css-namespace}quotation {
                        .#{$css-namespace}block_title {
                            background-color: $color-white;
                            border-radius: 0.3rem;
                            box-shadow: var(--box-shadow);
                            padding: 1.52rem 3rem 3rem;

                            .#{$css-namespace}quotation--title {
                                margin-bottom: 1rem;

                                @include min-screen($screen__l) {
                                    line-height: 6.9rem;
                                    margin-bottom: 0.58rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.useropeningrequest-search-index {
    .message.error {
        margin: 0;
    }

    .tooltip-text-search--footer {
        top: -3.6rem;

        &::after {
            top: 1rem;
        }
    }
}

.tags {
    // todo : define in toolkit if not exist
    margin-top: $indent__s;

    .tag {
        display: inline-block;
        margin-right: 0.5rem;
        color: $mainFontColor;
        cursor: pointer;
        position: relative;
        margin-bottom: 0.5rem;
        border: 0.2rem solid $color-useraccount-tag-border;
        border-radius: 1.5rem;
        padding: 0.6rem 1.5rem;

        .dcfonts-icons--cross {
            margin-left: 1.2rem;
            font-size: 1.2rem;
            color: inherit;
        }

        &:hover {
            border-color: $color-useraccount-tag-background;
            background: $color-useraccount-tag-background;
            color: $color-white;
        }

        &.generated:not(:hover) {
            border-color: $color-disabled-light;
        }
    }
}

.actions.left {
    .back {
        a {
            color: $mainFontColor;
        }
    }
}

.useraccount-approvalflow-createapprovalflow,
.form-edit-account {
    @include max-screen($screen__m) {
        padding: 0 1rem;
        .actions-toolbar {
            .secondary,
            .primary {
                float: left;
                margin: 0 0 0.5rem 0.5rem;
            }
        }
    }
    @include min-screen($screen__m) {
        .actions-toolbar {
            float: left;
            margin-left: 0 !important;
        }
        .primary {
            margin-right: 2rem;
        }
    }

    .fieldset {
        .control {
            &.has-currency {
                .input-text {
                    width: calc(100% - 4.8rem) !important;
                }
            }
        }

        legend {
            span {
                font-weight: $font-weight__light;
                color: $color-sub-secondary;
            }
        }
    }
}

form.customer-account-login--company-search {
    .fieldset {
        .control {
            &.has-tooltip {
                > .input-text,
                > .control-pass {
                    display: inline-block;
                    width: calc(100% - 2.6rem) !important;
                }
            }
        }
    }
}

.info-userapproval {
    margin-top: $indent__s;
    color: $color-black;
    font-size: 1.25rem;
    margin-left: 2.25rem;
}

.approval-amount-currency {
    position: absolute;
    top: 1.5rem;
    right: 0;
}

.link.quick-order {
    .quick-order-link {
        display: block;
        color: $mainFontColor;

        &:hover {
            text-decoration: none;
        }

        .dcfonts-icons {
            color: $color-sub-secondary;
            font-size: 2.4rem;
        }
    }

    .text {
        display: inline-block;
        vertical-align: middle;
    }
}

.wishlist-link,
.quotation-link {
    display: inline-flex;
    flex-direction: column;
    text-decoration: none;

    .dcfonts-icons {
        color: $color-sub-secondary;
        font-size: 2.4rem;
    }

    .text {
        @include lib-font-size(12);
        font-weight: $font-weight-medium;
        color: $color-sub-secondary;
        text-decoration: none;
    }
}

.account {
    .page-main {
        .columns {
            display: flex;
            margin-bottom: 2rem;

            @include min-screen($screen__l) {
                margin-top: 3rem;
                margin-bottom: 4rem;
                padding: 0 4rem 0 0;
            }

            .#{$css-namespace}box_content_title {
                font-size: $font-size-xl;
                line-height: $box-content-title-line-height;
                color: $color-primary;
                font-family: $font-secondary;
                font-weight: $font-weight__bold;
            }
        }

        .sidebar.sidebar-main {
            flex-grow: 0;

            @include min-screen($screen__l) {
                width: 22rem;
                margin-right: $indent__base;
            }
        }
    }

    .column.main {
        @include lib-css-wrapper();

        @include max-screen($screen__l) {
            margin: 0;
        }
    }

    &.page-layout-2columns-left {
        .column.main {
            max-width: 100%;
        }

        h2 {
            font-weight: $font-weight-bold;
            line-height: normal;
            color: $color-sub-secondary;
            @include lib-font-size(1.8rem);
            margin-bottom: 1.2rem;
        }

        .desc {
            margin-bottom: 1.8rem;

            p {
                margin-bottom: 0.2rem;
                color: $color-black;
            }
        }

        .block-dashboard-addresses,
        .block-dashboard-info,
        .block-dashboard-orders {
            .box-title {
                line-height: 2.5;

                > span {
                    color: $color-black;
                    @include lib-font-size(1.6rem, !important);
                }
            }

            .block-content {
                .box {
                    flex: 1 1 auto;
                    width: auto !important;
                    border: none;
                    padding: 0;

                    .box-title {
                        line-height: 2.5;

                        > span {
                            @include lib-font-size(2rem);
                        }
                    }

                    .box-content {
                        color: $color-gray-light7;

                        > p,
                        > address,
                        .box_contact_information {
                            line-height: 1.3;
                        }
                    }

                    .box-actions {
                        a.action {
                            font-weight: $font-weight-bold;
                            color: $color-gray-light7;
                            display: block;

                            .icon-fleche {
                                color: $color-sub-secondary;
                                @include lib-font-size(1rem);
                                margin-left: $space-1 / 2;
                            }
                        }
                    }
                }

                .box-separator {
                    flex: 1 1 auto;
                    border-left: 0.1rem dotted $subAltPrimaryColor;
                }
            }
        }

        .block-dashboard-orders {
            .block-title {
                .box-title {
                    @include lib-font-size(2rem);
                    font-weight: $font-weight-bold;
                    color: $color-blue3;
                    line-height: 1;
                }

                .view {
                    float: right;

                    .icon-fleche {
                        color: $color-blue3;
                    }
                }
            }

            .orders-recent {
                &-element {
                    border: 0.1rem solid $border-color__base;
                    box-sizing: border-box;
                    margin-bottom: 0.9rem;
                    width: 100%;
                    font-family: $defaultFont;
                    display: flex;
                    justify-content: center;

                    &:hover {
                        box-shadow: 0 0.1rem 0.3rem $color-gray80; // TODO: Use box-shadow var
                    }

                    .box-orders {
                        &-reference,
                        &-information {
                            float: left;
                            box-sizing: border-box;

                            & p {
                                margin: 0;
                            }
                        }

                        &-reference {
                            width: 33%;
                            background-color: $color-gray96;
                            padding: 2rem;
                            min-height: 8rem;
                        }

                        &-information {
                            width: 67%;
                            padding: 1rem;
                            display: flex;
                            justify-content: center;

                            &-date,
                            &-price,
                            &-details {
                                margin: auto;
                            }

                            &-details {
                                .icon-fleche {
                                    color: $color-blue3;

                                    &::before {
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .block-dashboard-info {
            .block-title {
                @include border-title;
            }

            .block-content {
                display: flex;
                justify-content: space-between;

                .box-title {
                    line-height: 2.5;

                    > span {
                        color: $color-blue3;
                        @include lib-font-size(2rem, !important);
                    }
                }

                .box-content {
                    color: $color-gray-light7;

                    > p,
                    .box_contact_information {
                        line-height: 1.3;
                    }

                    .box-phone {
                        display: block;
                        margin-top: 0.2rem;
                        margin-bottom: $space-1;

                        > b {
                            line-height: 2.2rem;
                        }

                        .icon-phone {
                            width: 3rem;
                            height: 3rem;
                            display: inline-block;
                            position: relative;
                            top: $space-1 / 2;
                            margin-right: $space-1;
                        }
                    }

                    .desktop-phone {
                        display: block;
                    }

                    .mobile-phone {
                        display: none;
                    }

                    @include max-screen($screen__m) {
                        .desktop-phone {
                            display: none;
                        }

                        .mobile-phone {
                            display: block;
                        }
                    }
                }
            }

            /***/
            .box.box-store-info {
                width: 100% !important;

                .box-content {
                    box-sizing: border-box;

                    padding: 0 !important;
                    width: 100%;
                    display: block;
                    float: left;
                    @include lib-shadow-block();

                    .box-content-info-store {
                        width: calc(50% - 2rem);
                        float: left;
                        clear: none;
                        padding: 1rem;

                        dl {
                            display: flex;
                            flex-direction: column;
                            margin: $indent__s 0 0;

                            dt {
                                margin: 0;
                                font-weight: $font-weight__bold;
                            }

                            dd {
                                margin: 0;
                            }
                        }

                        .fn {
                            display: block;
                            @include lib-font-size(16);
                            font-weight: $font-weight__bold;
                        }

                        .adr {
                            > span {
                                display: block;
                            }
                        }

                        .tel {
                            dd {
                                color: $color-sub-secondary;

                                &.desktop-phone {
                                    display: block;
                                }

                                &.mobile-phone {
                                    display: none;
                                }

                                @include max-screen($screen__m) {
                                    &.desktop-phone {
                                        display: none;
                                    }

                                    &.mobile-phone {
                                        display: block;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }

                    .box-content-img-cms {
                        width: 50%;
                        float: left;

                        img {
                            float: right;
                        }
                    }

                    .box-content-img-cms,
                    .box-content-info-store {
                        @include max-screen($screen__l) {
                            width: 100%;
                        }
                    }
                }

                &:nth-child(odd) {
                    border-right: none !important;
                    padding-right: inherit !important;
                    border-bottom: none !important;
                }
            }

            /***/
        }

        .block-dashboard-addresses {
            .block-content {
                display: flex;
            }

            .block-title {
                line-height: 2.5;

                > span {
                    @include lib-font-size(2rem);
                    font-weight: $font-weight-bold;
                    color: $color-sub-secondary;
                }
            }

            .box-actions {
                a.action {
                    line-height: 2.2rem;
                }
            }
        }

        ul.multiUserList {
            max-width: 79.4rem;
            width: 100%;
            list-style-type: none;
            padding: 0;

            > li {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border: 0.1rem solid $color-gray64;
                @include lib-css-border-radius(0.4rem);
                margin-bottom: $indent__base;
                padding: $indent__base;

                .multiUserAddress {
                    flex: 1 1 auto;

                    > span {
                        display: block;
                        font-weight: $font-weight__bold;
                    }

                    .fn {
                        @include lib-font-size(16);
                    }

                    .org {
                        color: $color-sub-secondary;
                    }

                    .adr {
                        > span {
                            display: block;
                        }
                    }
                }

                > button {
                    flex: 0 0 auto;
                    border: none;
                    min-height: 5rem;
                    min-width: 14rem;
                    margin: $indent__base 0 0 0;

                    > span {
                        color: $color-white;
                    }
                }
            }

            @include min-screen($screen__l) {
                > li {
                    flex-direction: row;
                    align-items: center;

                    > button {
                        margin: 0 0 0 $indent__base;
                    }
                }
            }
        }

        &.useraccount-multiuser-index {
            .sidebar-main {
                display: none;
            }

            .column.main {
                float: left;
            }
        }
    }
}

@include max-screen($screen__l) {
    .useraccount-approvalflow-create {
        .page-title-wrapper {
            padding: $indent__l $indent__l 0;

            .page-tile {
                font-weight: $font-weight__bold;
            }
        }

        #maincontent {
            padding: 0;

            .column.main {
                padding: 0 $indent__l $indent__l;
            }
        }

        .actions {
            .secondary {
                margin: 0;

                .primary {
                    width: 100%;
                }
            }
        }
    }
    .page-header {
        .header.content {
            .link.quick-order,
            .link.quotation {
                display: none;
            }
        }
    }
}

@include min-screen($screen__l) {
    .useraccount-approvalflow-create {
        .actions {
            display: inline-block;

            &.right {
                margin-left: $indent__s;
                vertical-align: middle;
            }
        }
    }
    .page-header {
        .header.content {
            .link.quick-order,
            .link.quotation {
                order: 4;
                flex: 0 0 13rem;
                text-decoration: none;
            }
        }
    }
}

@include max-screen($screen__l) {
    #searchbox {
        text-overflow: ellipsis;
        width: 100%;
    }
    .account {
        &.page-layout-2columns-left {
            .block-dashboard-addresses {
                .block-content {
                    flex-direction: column;

                    .box {
                        width: 100% !important;
                        padding: 0;

                        &:nth-child(odd) {
                            border-right: none;
                            border-bottom: 0.1rem dotted $subAltPrimaryColor;
                            padding: 0 0 2rem 0;
                        }

                        &:nth-child(even) {
                            padding: 0;
                        }

                        .box-actions {
                            display: block;

                            a.action {
                                width: 100%;
                                display: inline-block;
                            }
                        }
                    }
                }
            }

            .block-dashboard-info {
                .block-content {
                    flex-direction: column;

                    .box {
                        width: 100% !important;
                        padding: 0;

                        &:nth-child(odd) {
                            border-right: none;
                            border-bottom: 0.1rem dotted $subAltPrimaryColor;
                            padding: 0 0 4rem 0;
                        }

                        &:nth-child(even) {
                            padding: 4rem 0 0 0;
                        }

                        .box-actions {
                            display: block;

                            a.action {
                                width: 100%;
                                display: inline-block;
                            }
                        }
                    }

                    .box-separator {
                        display: none;
                    }
                }
            }

            .block-dashboard-orders {
                .block-title,
                .block-content {
                    box-sizing: border-box;
                }

                .orders-recent {
                    &-element {
                        margin-bottom: 3rem;
                        padding: 0;
                        display: block;
                        float: left;

                        .box-orders {
                            &-reference,
                            &-information {
                                display: block;
                                width: 100%;
                                padding: 2rem $space-2;
                            }

                            &-reference {
                                min-height: auto;
                            }

                            &-information {
                                &-details {
                                    margin-top: 2rem;
                                }
                            }
                        }
                    }
                }
            }

            &.useraccount-multiuser-index {
                .columns {
                    padding: 0 3rem;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .account {
        &.page-layout-2columns-left {
            .breadcrumbs {
                margin: 0;
            }

            main.page-main {
                padding-top: 0;
            }

            .sidebar-main {
                order: 1;
            }

            .column.main {
                order: 2;
            }
        }
    }
}

@include max-screen($screen__s) {
    .account.page-layout-2columns-left {
        .block-dashboard-info {
            .block-content {
                .box-actions {
                    display: block;
                }
            }
        }
    }
}

/* Come back link */
.customer-come-back {
    padding-top: 2rem;

    @include min-screen($screen__l) {
        padding-top: 1.5rem;
    }

    &:has(~ .container.narrow),
    &:has(~ div > .container.narrow) {
        margin-left: auto;
        margin-right: auto;
        width: #{'min(46rem, 100%)'};
    }

    a {
        display: inline-flex;
        align-content: center;
        margin-bottom: 1.5rem;
        color: $color-secondary;

        .dcfonts-icons--arrow-left {
            margin-right: 0.5rem;
        }

        @include lib-hover-focus {
            span {
                text-decoration: none;
            }
        }
    }
}

/* customer-account-login */
$separator-color: $color-gray64;
$login-margin: 3rem;
.accountopeningrequest-company-index .page-wrapper {
    background-color: $color-grey-background;
}

.customer-account-login-confirm {
    .new-customer-container {
        .block-new-customer {
            .block-content {
                @include max-screen($screen__m) {
                    margin-left: 2rem;
                }
                @include min-screen($screen__m) {
                    max-width: 58rem;
                    margin-left: 8.6rem;
                }
            }
        }
    }
}

.customer-account-login,
.customer-personal-informations,
.customer-account-login-confirm {
    background-color: $color-grey-background;

    span[role='sub-title'] {
        text-align: center;
        display: block;
    }

    .block-new-customer {
        .field {
            .#{$css-namespace}tooltip {
                position: absolute !important;
                top: 1.5rem;
                right: 0;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
            }
        }
    }

    .page-title-wrapper {
        display: none;
    }

    .customer-account-container {
        @include max-screen($screen__m) {
            margin-top: 2rem;
        }

        .block-content {
            text-align: left;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: solid 0.1rem $color-disabled-light;
            border-radius: 0.3rem;
            padding: 1.5rem 2rem;

            @include max-screen($screen__m) {
                flex-direction: column;
            }

            .block-content-new-customer {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: none;
                padding: 0;
                margin: 0;
            }

            .tk__wrapper {
                align-self: center;
                margin-right: 4.5rem;

                @include max-screen($screen__m) {
                    margin: 0;
                    margin-top: 2rem;
                    width: 100%;
                }
            }

            .#{$css-namespace}badge {
                color: $color-success-secondary;
                background-color: $color-success-background;
                border: none;
                min-width: 16rem;

                @include max-screen($screen__m) {
                    width: 100%;
                }
            }
        }
    }

    .customer-account-container,
    .new-customer-container {
        margin-bottom: 2rem;

        .label {
            font-size: $font-size-m;
            color: $color-primary;
        }
    }

    .new-customer-container {
        margin-top: 0;
        margin-bottom: 0;
    }

    .new-customer-container {
        margin-top: 0;
        margin-bottom: 0;
    }

    .login-container,
    .new-customer-container,
    .customer-account-container,
    .customer-personal-informations-container,
    .accountopeningrequest-wrapper {
        width: auto;
        max-width: 79.3rem;
        margin-left: auto;
        margin-right: auto;
        padding: $login-margin;
        border-radius: 0.3rem;
        background-color: $color-main-light;
        box-shadow: 0 0 0.7rem $color-gray-darken2; // TODO: Use box-shadow var

        .block-title {
            .#{$css-namespace}title[data-ui-title-level='3'] {
                @include max-screen($screen__m) {
                    font-size: $font-size-xl;
                }
            }

            .confirmation-top-title {
                color: $color-secondary;
                font-size: $font-size-xxl;
                line-height: 3.9rem;
                @include max-screen($screen__m) {
                    line-height: 1.8rem;
                }
            }

            &--primary {
                margin-bottom: 4rem;
                text-align: center;

                .confirmation-title {
                    line-height: 3.9rem;
                    @include max-screen($screen__m) {
                        line-height: 2.9rem;
                        padding-left: 3rem;
                        padding-right: 3rem;
                    }
                }

                h2 {
                    line-height: $indent__xs;
                    margin-top: 3rem;
                    color: $color-secondary;
                }
            }
        }

        &--confirm {
            background: transparent;
            box-shadow: inherit;

            .block-title {
                text-align: left;
                margin-bottom: 3rem;
            }

            .input-submit {
                margin-top: 5rem;
            }

            ol {
                padding-left: 1.8rem;
                text-align: left;

                li {
                    margin-bottom: 1.5rem;

                    &::marker {
                        color: $color-primary;
                        font-weight: $font-weight-medium;
                    }
                }
            }
        }

        .block-bar {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            padding: 3rem 0;

            @include max-screen($screen__m) {
                flex-direction: column;
            }

            &-circle {
                border: 0.1rem solid $color-text-default;
                border-radius: 50%;
                width: 3.5rem;
                height: 3.5rem;

                i {
                    font-size: 2.3rem;
                    height: 3.2rem;
                    color: $color-text-default;
                }
            }

            &-note {
                color: $color-text-default;
                font-weight: $font-weight-bold;
                padding: 0 1rem;

                @include max-screen($screen__m) {
                    margin-top: 1rem;
                    padding: 0;
                }
            }

            &-separator {
                width: 6%;
                background: $color-text-default;
                height: 0.2rem;
                margin-right: 1rem;

                @include max-screen($screen__m) {
                    width: 0.2rem;
                    height: 4rem;
                    margin: 1rem 0;
                }
            }

            &--confirm {
                .block-bar-note {
                    color: $color-secondary;
                }

                .block-bar-separator {
                    background: $color-secondary;
                }

                .dcfonts-icons {
                    color: $color-white;
                }

                .block-bar-circle {
                    background-color: $color-secondary;
                    border-color: $color-secondary;
                }
            }
        }

        &--account {
            max-width: 120rem;
        }

        .#{$css-namespace}form-radio-group {
            display: flex;

            &.column {
                flex-direction: column;
            }
        }
    }

    .new-customer-container--search {
        .block-title {
            .#{$css-namespace}title[data-ui-title-level='3'] {
                @include max-screen($screen__m) {
                    text-align: center;
                }
            }
        }
    }

    .customer-personal-informations-container {
        padding: $space-5;
        max-width: var(--container-narrow-width);
    }

    .customer-account-container,
    .customer-personal-informations-container {
        display: flex;
        width: 100%;

        @include max-screen($screen__m) {
            flex-direction: column;
        }

        .block {
            width: 100%;
            box-sizing: border-box;

            .action.primary {
                height: 5rem;
                line-height: 3.1rem;
                background: $color-blue1;
                text-align: center;
            }

            a:not(.primary) {
                color: $color-blue3;
            }

            &.block-customer-login,
            &.block-new-customer {
                @include min-screen($screen__m) {
                    margin-bottom: 0;
                    width: 50%;
                }

                .block-title {
                    margin-bottom: 1rem;
                }

                .block-content {
                    margin-top: 0;
                }
            }

            &.block-customer-login {
                display: flex;
                flex-direction: column;

                @include min-screen($screen__m) {
                    padding-right: $login-margin;
                    border-right: 0.1rem solid $color-gray-darken1;
                }

                @include max-screen($screen__m) {
                    border-bottom: 0.1rem solid $separator-color;
                    padding-bottom: $login-margin;
                    margin-bottom: $login-margin;
                }

                .block-content,
                .form-login {
                    height: 100%;
                }

                .#{$css-namespace}login_forgot-pswd {
                    margin-bottom: 2.1rem;
                }

                .fieldset.login {
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .control {
                        width: 100%;

                        .input-text {
                            z-index: 0;
                        }
                    }

                    .input-submit {
                        margin-top: 0;
                    }

                    &::after {
                        display: none;
                    }
                }
            }

            &.block-new-customer {
                display: flex;
                flex-direction: column;

                @include min-screen($screen__m) {
                    position: relative;
                    padding-left: $login-margin;
                    justify-content: flex-start;

                    .block-content-login {
                        margin-top: 2rem;
                    }
                }

                .block-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: none;
                    height: 100%;
                    padding: 0;
                    margin-bottom: 0;

                    .color-secondary {
                        color: $color-secondary;
                    }

                    &-login {
                        margin-top: 2.1rem;
                        text-align: center;

                        .#{$css-namespace}link {
                            color: $color-secondary;
                            font-weight: $font-weight-bold;

                            @include max-screen($screen__m) {
                                display: block;
                            }
                        }
                    }
                }

                .reserved {
                    color: $color-blue3;
                    margin-top: 1rem;
                    text-align: center;

                    &::before {
                        content: '';
                        width: 2rem;
                        height: 2rem;
                        @include lib-background-image(
                                'png/cadenas.png',
                                center,
                                no-repeat,
                                transparent,
                                'Descours_UserAccount'
                        );
                        background-size: cover;
                        display: inline-block;
                        vertical-align: bottom;
                    }

                    span {
                        vertical-align: middle;
                        @include lib-font-size(1.3rem);
                    }
                }

                .note {
                    margin: 0;

                    @include min-screen($screen__m) {
                        margin-bottom: 0.5rem;
                    }
                }

                .advantages {
                    margin: 2.9rem 0 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 0;

                        + li {
                            margin-top: 1.9rem;
                        }
                    }

                    .dcfonts-icons {
                        margin-right: 1.2rem;
                        font-size: 2rem;
                        color: $color-secondary;
                    }
                }

                .input-submit {
                    @include max-screen($screen__m) {
                        margin-top: 2rem;
                    }
                }
            }

            .block-title {
                color: $color-blue3;
                border-bottom: none;
                padding-bottom: 0;
                text-align: center;

                strong {
                    font-weight: $font-weight-bold;
                }
            }
        }

        div.mage-error {
            &::before {
                display: inline-block;
                font-family: $font-icons;
                content: $cross;
                font-size: $font-size-xxs;
                margin-right: 0.3rem;
                margin-top: -0.1rem;
            }
        }

        .block-content {
            margin-top: 3rem;
        }

        .fieldset.customer-personal-informations-form {
            margin-left: auto;
            margin-right: auto;

            label {
                margin-bottom: 0;
            }

            .field-user-phone {
                .#{$css-namespace}tooltip--right {
                    > span {
                        top: -0.7rem;
                    }

                    .tooltip-text-phone {
                        top: -3rem;
                    }
                }

                .control {
                    display: flex;
                    flex-wrap: wrap;

                    div.mage-error:not(:empty) {
                        order: 2;
                    }

                    .dcfonts-icons--question-circle {
                        margin-left: 0.6rem;
                    }
                }
            }

            .form-field-wrapper-civility {
                @include min-screen($screen__m) {
                    display: flex;
                    flex-direction: column-reverse;
                }

                div.mage-error:not(:empty) {
                    @include max-screen($screen__m) {
                        margin-bottom: 1rem;
                    }
                }
            }

            .radio .control input:checked + .value {
                color: inherit;
            }

            .mandatory {
                font-style: italic;
                margin-top: 2rem;
                font-weight: $font-weight-light;
            }

            .tooltip {
                top: $space-1 / 2;

                &-text--opening-request {
                    margin-top: $space-1;
                    border: 0.1rem solid $color-secondary;
                    width: 20.5rem;

                    &::after {
                        border-top: 0.1rem solid $color-secondary;
                        border-right: 0.1rem solid $color-secondary;
                    }
                }
            }

            input {
                border-color: $color-gray43;
            }

            .radio {
                border: none;
                box-shadow: none;

                .control {
                    width: auto;
                    padding: 0;
                    margin-right: 3rem;

                    input {
                        position: initial;
                        width: auto;
                        height: auto;
                        opacity: 100%;
                    }
                }
            }

            button.#{$css-namespace}btn {
                width: 100%;
                justify-content: center;
            }

            .field-user-email {
                > span {
                    font-size: $font-size-s;
                    font-style: italic;
                    font-weight: $font-weight-light;
                }
            }

            .field-user-loyalty {
                display: flex;
                line-height: 2.2rem;
                margin-bottom: $indent__base;
                align-items: baseline;

                [type='checkbox'] {
                    margin: 0 1rem 0 0;
                    position: relative;
                    top: 0.3rem;
                    width: 1.5rem;
                    height: 1.5rem;
                }

                .label {
                    cursor: pointer;
                    pointer-events: auto;
                }
            }
        }
    }

    .login-container {
        width: #{'min(46rem, 100%)'};
        margin-bottom: 2rem;

        @include min-screen($screen__m) {
            position: relative;
        }

        @include max-screen($screen__m) {
            padding: 3rem 1rem;
        }

        .input-password-container {
            input {
                width: 100%;

                &::-ms-reveal {
                    display: none;
                }
            }
        }

        .block-customer-login {
            width: 100%;
        }

        .input-submit {
            margin: 0;
        }

        .fieldset::after {
            display: none;
        }
    }

    .note {
        text-align: center;
    }

    .input-password-container,
    .field-user-registration-number {
        margin-top: 2.2rem;

        .control {
            position: relative;

            &.has-tooltip {
                display: flex;
                flex-wrap: wrap;

                .#{$css-namespace}tooltip {
                    margin-left: 0.6rem;
                    font-size: $font-size-m;
                    order: 1;

                    .tooltip-text {
                        margin-top: 1.3rem;

                        &,
                        &::after {
                            border-top: 0.1rem solid $color-primary;
                            border-right: 0.1rem solid $color-primary;
                        }
                    }
                }
            }
        }

        input {
            width: calc(100% - 2.6rem);
            order: 0;

            ~ div.mage-error {
                order: 3;
            }
        }

        label {
            font-size: $font-size-default;
        }

        input[name='login[password]'] {
            padding-right: 4rem;
        }

        .icons-container {
            position: absolute;
            right: 1rem;
            top: 2rem;
            transform: translateY(-50%);
            color: $color-secondary;
            font-size: 2.4rem;
            cursor: pointer;

            .dcfonts-icons--eye {
                display: none;
            }

            &.show-password {
                .dcfonts-icons--eye-slash {
                    display: none;
                }

                .dcfonts-icons--eye {
                    display: inherit;
                }
            }
        }

        .remind {
            display: inline-block;
            margin-top: 0.4rem;
            margin-left: 1.5rem;
            font-size: $font-size-s;
        }
    }

    .input-submit {
        justify-content: center;
        margin-top: $space-2;
        width: 100%;
        font-weight: $font-weight-medium;
        border-radius: 0.3rem;
    }

    .new-customer-container {
        @include max-screen($screen__m) {
            padding: 3rem 1rem;
        }

        &--confirmation-page {
            margin-top: 2rem;
            margin-bottom: 2rem !important;

            @include max-screen($screen__m) {
                margin-top: 5rem;
            }
        }

        .block-new-customer {
            text-align: center;

            &.block-new-customer--search {
                .note {
                    margin-top: $indent__s;
                    color: $color-grey-dark;

                    @include max-screen($screen__m) {
                        margin-bottom: 0;
                    }
                }
            }

            &--email-reminder {
                @include min-screen($screen__l) {
                    margin-left: 7rem;
                }
            }
        }

        .block-content-search {
            margin-left: 8.4rem;
            margin-right: 8.4rem;

            @include max-screen($screen__m) {
                margin-left: 0;
                margin-right: 0;
            }

            .search-footer {
                text-align: left;
                display: flex;
                margin: 0 0 $indent__base 0;

                @include max-screen($screen__m) {
                    margin-left: 0;
                }

                .#{$css-namespace}tooltip {
                    vertical-align: middle;
                    margin-left: 0.6rem;
                }
            }

            .required-fields {
                display: block;
                margin-top: 2rem;
                text-align: left;
                font-size: $font-size-s;
                font-weight: 100;
            }

            .#{$css-namespace}btn {
                display: flex;
                max-width: calc(100% - 2.6rem);
                margin-bottom: 2rem;

                @include max-screen($screen__m) {
                    max-width: 100%;
                }
            }
        }

        .note {
            margin-top: 1.5rem;
            margin-bottom: 2.5rem;

            .dcfonts-icons--mail {
                color: $color-secondary;
                font-size: 3rem;
                margin-right: 1rem;
            }
        }

        .input-submit {
            margin-top: 0;
        }
    }

    &--company-search {
        .mobile-hidden {
            @include max-screen($screen__m) {
                display: none;
            }
        }

        .desktop-hidden {
            @include min-screen($screen__l) {
                display: none;
            }
        }

        .note {
            padding: 0 3rem 0 3rem;
            @include max-screen($screen__m) {
                padding: 0;
            }
        }

        .new-customer-container {
            text-align: center;
            max-width: var(--container-narrow-width);
        }

        .fieldset {
            margin-bottom: 1.5rem;
            width: 100% !important;
            text-align: left;

            @include max-screen($screen__m) {
                width: 100% !important;
                margin-left: 0;
            }

            .field {
                .control {
                    .#{$css-namespace}input-text {
                        border-radius: 0.4rem;
                    }

                    .label {
                        padding-left: 1.5rem;
                    }

                    .dcfonts-icons {
                        margin-left: 0.6rem;
                        font-size: 1.6rem;
                    }
                }
            }
        }

        .tooltip {
            top: inherit !important;
            right: inherit !important;
        }
    }

    .search-footer-confirm {
        text-align: left;
        padding: 0 1.5rem;
        margin: 0;
        display: flex;

        @include min-screen($screen__l) {
            padding: 0 7rem;
        }

        &_list {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: flex;
                margin-bottom: 1.9rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: '\2022';
                    font-family: $font-icons;
                    font-size: 1.5rem;
                    margin-right: 0.7rem;
                    color: $color-secondary;
                    position: relative;
                    top: -0.3rem;
                }
            }
        }

        .search-company-subtext {
            font-size: $font-size-s;
            font-weight: $font-weight-regular;
        }

        &--confirm {
            display: flex;
            margin-bottom: 0;
            align-items: center;
            padding: 0 6rem;

            @include min-screen($screen__m) {
                margin-left: $indent__base;
            }

            @include max-screen($screen__m) {
                margin-top: 1rem;
                padding: 0;
            }

            .note {
                text-align: left;
                margin: 0 0 0 1rem;
            }

            .dcfonts-icons {
                color: $color-secondary;
                font-size: 2.8rem;
            }
        }

        .tooltip-desktop {
            .tooltip-text {
                &::after {
                    top: 1rem;
                }
            }
        }

        .#{$css-namespace}tooltip {
            margin-left: 0.6rem;
            font-size: $font-size-m;

            .tooltip-text {
                margin-top: $space-1;
                z-index: 0;

                &::after {
                    border-top: 0.1rem solid $color-primary;
                    border-right: 0.1rem solid $color-primary;
                    z-index: -1;
                }
            }
        }
    }
}

.customer-account-login {
    .fieldset {
        margin: 0;
    }

    .g-recaptcha {
        margin-top: 1.5rem;
        margin-bottom: 0;

        @include min-screen($screen__l) {
            position: absolute;
            bottom: -2rem;
            transform: translateY(100%);
        }
    }
}

/* customer-account-edit */
input.input-disabled {
    opacity: 1 !important;
    color: $color-disabled-normal !important;
    background-color: rgb(235, 235, 228);
}

.legend-title {
    span {
        @include lib-font-size(2rem);
    }
}

@include min-screen($screen__l) {
    .customer-account-edit {
        .actions {
            display: inline-block;
        }
    }
}

// addresses bloc titles common style
.block {
    &.block-addresses-default,
    &.block-addresses-list {
        margin-bottom: 0;
        background-color: $color-white;
        padding: 2rem 3rem;

        .block-title {
            color: $color-sub-secondary;
            @include lib-font-size(1.6rem);

            strong {
                font-weight: $font-weight-bold;
            }
        }
    }
}

// addresses boxs common style
.block-addresses-default .block-content .box,
.block-addresses-list .block-content .addresses > .item {
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 1.5rem;
    @include lib-shadow-block();

    // links common styles
    .action:not(.primary) {
        display: block;
        color: $color-blue1;
        text-decoration: underline;
    }

    .box-actions,
    .item.actions {
        margin-top: 0;
        padding-left: 0;
        padding-top: 2rem;
    }
}

// boxes & boxes containers
.block-addresses-default .block-content,
.block-addresses-list .block-content .addresses {
    display: flex;
    flex-wrap: wrap;
    @include max-screen($screen__l) {
        display: block;
    }

    &::before,
    &::after {
        display: none;
    }
}

.block-addresses-default .block-content {
    .box {
        width: calc((100% - 4rem) / 2) !important;
        margin-right: 4rem;
        padding: 1rem;

        &:nth-child(2n) {
            margin-right: 0;
        }

        @include max-screen($screen__l) {
            width: 100% !important;
            margin-right: 0;
        }
    }
}

.block-addresses-list .block-content .addresses {
    > .item {
        width: calc((100% - 3rem) / 3) !important;
        margin-right: 1.5rem;
        /**/
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /**/
        &:nth-child(3n) {
            margin-right: 0;
        }

        @include max-screen($screen__l) {
            width: 100% !important;
            margin-right: 0;
        }
    }
}

.block-addresses-list .actions-toolbar {
    margin-bottom: 2rem;

    .primary {
        .action {
            height: 5rem;

            span {
                vertical-align: middle;
            }
        }
    }

    .secondary {
        display: none;
    }
}

.password-forget .actions-toolbar {
    margin-left: 0;
}

// Account informations
.disabled-input {
    p {
        color: $color-black;
        margin-bottom: 0;
        margin-top: 0.6rem;
        @include lib-font-size(1.25rem);
        margin-left: 2.25rem;
    }
}

//useraccount-customer
.useraccount-customer-createcollaborator,
.useraccount-customer-edit {
    form {
        &.useraccountform {
            .tooltip {
                right: 0.5rem;

                .tooltip-text {
                    @include screen($screen__l, $screen__xxl) {
                        top: 50%;
                        transform: translateY(-50%);
                        right: 4rem;
                        max-width: calc(100vw - 12.5rem);
                    }

                    &::before {
                        @include screen($screen__l, $screen__xxl) {
                            top: 50%;
                            left: 97.5%;
                            transform: translateY(-50%) rotate(-45deg);
                        }
                    }
                }
            }

            .field-user-pwd,
            .field-user-confirm-pwd {
                display: none;
            }

            .fieldset {
                .field {
                    &.field-user-email {
                        clear: both;
                    }

                    &.field-user-civility {
                        label {
                            display: none;
                        }
                    }

                    //custom checkbox
                    input[type='checkbox'] {
                        display: none;

                        + label {
                            position: relative;
                            display: block;
                            margin: 0.2em 0;
                            cursor: pointer;
                            padding: 0.2em 0;

                            &::before {
                                content: '';
                                border: 0.1em solid #ccc; // TODO: use $color-gray80 instead of hexa
                                border-radius: 0.2em;
                                background: $color-white;
                                display: inline-block;
                                width: 1em;
                                height: 1em;
                                margin-right: 0.2em;
                                color: transparent;
                                transition: 0.2s; // TODO : Uniformise using var(--transition-normal)
                            }

                            &::after {
                                content: '\2714';
                                position: absolute;
                                z-index: 1;
                                top: 0.3em;
                                left: 0.2em;
                                display: inline-block;
                                font-size: 0.8em;
                                color: transparent;
                                transition: 0.2s; // TODO : Uniformise using var(--transition-normal)
                            }

                            &:active {
                                &::before {
                                    transform: scale(0);
                                }
                            }
                        }

                        &:checked {
                            + label {
                                &::before {
                                    background-color: $color-sub-secondary;
                                    border-color: $color-sub-secondary;
                                }

                                &::after {
                                    color: $color-white;
                                }
                            }
                        }
                    }

                    //Custom radio button
                    input[type='radio'] {
                        &.custom-radio {
                            &:checked,
                            &:not(:checked) {
                                display: none;

                                + label {
                                    position: relative;
                                    padding-left: 2.6rem;
                                    cursor: pointer;
                                    line-height: 2.3rem;
                                    display: inline-block;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 1.8rem;
                                        height: 1.8rem;
                                        border: 0.1rem solid #ccc; // TODO: use $color-gray80 instead of hexa
                                        border-radius: 100%;
                                        background: $color-white;
                                    }

                                    &::after {
                                        content: '';
                                        width: 1.2rem;
                                        height: 1.2rem;
                                        background-color: $color-sub-secondary;
                                        position: absolute;
                                        top: 0.3rem;
                                        left: 0.3rem;
                                        border-radius: 100%;
                                        transition: all 0.2s ease; // TODO : Uniformise using var(--transition-normal)
                                    }
                                }
                            }

                            &:checked {
                                + label {
                                    &::before {
                                        border: 0.1rem solid $color-sub-secondary;
                                    }

                                    &::after {
                                        opacity: 1;
                                        -webkit-transform: scale(1);
                                        transform: scale(1);
                                    }
                                }
                            }

                            &:not(:checked) {
                                + label {
                                    &::after {
                                        opacity: 0;
                                        -webkit-transform: scale(0);
                                        transform: scale(0);
                                    }
                                }
                            }
                        }
                    }
                }

                legend {
                    span {
                        font-weight: $font-weight__light;
                        color: $color-sub-secondary;
                    }
                }

                @include max-screen($screen__s) {
                    padding: 1rem;
                }
            }

            @include min-screen($screen__m) {
                .actions-toolbar {
                    margin-left: 0;
                    float: left;

                    .primary {
                        margin-right: 2rem;
                    }

                    button {
                        transition: background-color 0.3s ease; // TODO : Uniformise using var(--transition-normal)
                    }

                    &.user-edit {
                        float: left;
                        width: 100%;

                        .primary:not(button) {
                            width: 10%;
                            margin-right: 0;
                        }

                        .secondary:not(button) {
                            float: left;
                            width: 29%;
                            margin-left: 0.5rem;

                            a {
                                &:hover {
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }
            }
            @include max-screen($screen__m) {
                .actions-toolbar {
                    .secondary,
                    .primary {
                        float: left;
                        margin: 0 0 0.5rem 0.5rem;
                    }
                }
            }

            .actions-toolbar {
                &.user-edit {
                    button {
                        &.remove {
                            background-color: $color-red10;
                            border-color: $color-red10;

                            @include lib-hover-focus {
                                background-color: $color-red9;
                                border-color: $color-red9;
                            }
                        }
                    }
                }
            }
        }
    }
}

.form-edit-account {
    @include min-screen($screen__m) {
        .actions-toolbar {
            margin-left: 0;
            float: left;

            .primary {
                margin-right: 2rem;
            }

            button {
                transition: background-color 0.3s ease; // TODO : Uniformise using var(--transition-normal)
            }

            .primary:not(button) {
                margin-right: 0;
            }

            .secondary:not(button) {
                float: left;
                margin-left: 0.5rem;

                a {
                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
    }
    @include max-screen($screen__m) {
        .actions-toolbar {
            .secondary,
            .primary {
                float: left;
                margin: 0 0 0.5rem 0.5rem;
            }
        }
    }

    .actions-toolbar {
        button {
            &.remove {
                background-color: $color-red10;
                border-color: $color-red10;

                &:hover,
                &:focus {
                    background-color: $color-red9;
                    border-color: $color-red9;
                }
            }
        }
    }
}

.account.customer-account-index,
.account.useraccount-account-loyaltyprogram {
    .column.main {
        background-color: $color-grey-background;
        box-shadow: none;
        padding: 0;
        margin-top: 1rem;

        @include min-screen($screen__l) {
            margin: 0;
        }

        .#{$css-namespace}block_title {
            border: none;
            text-align: left;
            box-shadow: var(--box-shadow);
            background-color: $color-main-light;
            border-radius: 0.3rem;
            margin-bottom: 2rem !important;
            padding: 2rem 1.5rem;
            width: 100%;

            @include min-screen($screen__l) {
                margin-bottom: 3rem !important;
                padding: 3rem;
            }

            strong {
                color: $color-primary;
                font-family: $font-secondary;
                font-size: 3rem !important;
                line-height: 4rem !important;

                @include min-screen($screen__l) {
                    font-size: 5rem !important;
                    line-height: 3.92rem !important;
                }
            }
        }
    }
}

.#{$css-namespace}modal_loyalty_subscription {
    .#{$css-namespace}form-field-wrapper {
        display: flex;
        align-items: baseline;

        &:not(:last-child) {
            margin-bottom: $space-2;
        }

        input {
            margin: 0 $indent__s 0 0;
            border-color: $color-disabled;
            border-width: 0.127rem;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.2rem;
            position: relative;
            top: 0.3rem;
        }
    }
}

.#{$css-namespace}loyalty_subscription {
    &_form {
        margin-bottom: $indent__base;

        @include min-screen($screen__m) {
            padding-right: 1.5rem;
        }

        label {
            cursor: pointer;
        }
    }

    &_info {
        display: flex;
        align-items: center;
        border-radius: 0.4rem;
        background: $color-grey-background;
        color: $color-black;
        padding: $indent__s 1.5rem $indent__s 2.5rem;
        font-weight: $font-weight__bold;
        text-align: left;
        line-height: 1.9rem;

        @include min-screen($screen__m) {
            padding-right: 2.5rem;
        }

        .dcfonts-icons {
            font-size: 2.4rem;
            color: $color-secondary;
        }

        &_content {
            color: $color-black;
            margin-left: $indent__s;
        }
    }
}

.quotation-manage-approve,
.quotation-create-createpost {
    .column.main {
        background-color: inherit !important;
        border-radius: 0 !important;
        box-shadow: inherit !important;
        padding: 0 !important;
    }
}

.customer-account-login {
    .note {
        margin-top: $space-1;
    }
}

.customer-account-login-confirm {
    .new-customer-container {
        &--confirmation-page {
            margin-top: 2rem;
        }
    }
}

.block-your-personal-informations {
    .field {
        margin-bottom: 2rem;
    }

    .field-checkbox {
        position: relative;
        padding-left: 2.5rem;
        font-size: $font-size-default;

        &-loyaltyprogram {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .#{$css-namespace}subscription_loyaltyprogram {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 24.3rem;
        margin-top: 2rem;
        padding: 2rem 2.1rem 3rem;
        border-radius: 0.3rem;
        overflow: hidden;
        box-shadow: var(--box-shadow-light);
        background-image: url('../Descours_UserAccount/images/loyaltyprogram/loyalty-program-light-wave.svg');
        background-size: contain;
        background-position-y: bottom;
        background-repeat: no-repeat;

        &::before {
            content: '';
            position: absolute;
            height: 10rem;
            background-color: $color-loyalty-shape-background;
            left: -10rem;
            right: 0;
            top: 0;
            transform: translate(0, -98%) rotate(-5deg);
            transform-origin: bottom right;
        }

        @include min-screen($screen__m) {
            padding: 2rem 2.1rem 2.6rem;
        }

        p {
            position: relative;
            width: fit-content;
            font-size: $font-size-default;
            line-height: 2.2rem;
            font-weight: $font-weight__bold;
            color: $color-primary;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5rem;
            text-align: center;

            &::before {
                display: block;
                content: '';
                position: absolute;
                top: -2rem;
                left: -6rem;
                width: 7rem;
                height: 6rem;
                background-image: url(../Descours_UserAccount/images/dashboard/stars.svg);
                background-repeat: no-repeat;
                transform: scale(0.7);
            }

            span {
                font-size: $font-size-l;
                font-family: $font-secondary;
            }
        }

        li {
            line-height: 2.2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            display: block;
            font-size: $font-size-default;
            line-height: 1.9rem;
            color: $color-secondary;
            font-weight: $font-weight__bold;
            text-align: center;
            margin-top: 1rem;
        }
    }
}
