.#{$css-namespace}order-address {
    $border-style: 0.1rem solid $color-disabled-light;

    display: flex;
    flex-direction: column;
    border-top: $border-style;
    border-bottom: $border-style;
    margin-right: 2rem;

    @include min-screen($screen__l) {
        padding-right: 0;
    }

    & ~ & {
        border-top: none;
    }

    &:last-child {
        border-bottom: none;
    }

    &_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 1.45rem 0 1.45rem 1rem;
        line-height: 1;

        @include min-screen($screen__l) {
            padding: 1.45rem 4rem 1.45rem 5rem;
        }

        &[aria-expanded='false'] {
            .dcfonts-icons:before {
                content: $plus;
            }
        }

        &[aria-expanded='true'] {
            .dcfonts-icons:before {
                content: $minus;
            }
        }
    }

    &_name {
        font-weight: $font-weight-medium;
    }

    &_collapse,
    &_collapse:hover,
    &_collapse:focus,
    &_collapse:active {
        display: inline-flex;
        margin: 0 0 0 auto;
        border: none;
        padding: 0;
        font-size: $font-size-m;
        width: 1.6rem;
        height: 1.6rem;
        background: none;
        color: $color-secondary;
    }

    &_content {
        display: flex;
        flex-direction: column;
        padding: 0 0 2rem 1rem;
        line-height: 2rem;

        @include min-screen($screen__l) {
            padding: 0 4rem 2rem 5rem;
        }
    }
}
