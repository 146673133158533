// promo-tooltip

.promo-tooltip {
    cursor: pointer;
    color: $color-black;
    margin-bottom: $space-1 / 2;
    text-align: left;
    font-style: normal;
    line-height: 1.5;
    background: $color-red-light;
    background-clip: padding-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    height: 7.4rem;
    width: 23rem;
    margin-left: 0;
    margin-top: 1.2rem;
    padding: $indent__s;
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
    cursor: default;
    z-index: 1;

    @include max-screen($screen__m) {
        left: 0;
        max-height: 10rem;
        margin-top: #{$space-1 / 2} !important;
        margin-left: 1rem;

        .quantity-picker-container--bigger & {
            margin-top: 0.5rem !important;
        }
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 0.8rem;
        height: 0.8rem;
        background: #ffd9d9; // TODO : Use a global var
        border-left: none;
        border-bottom: none;
        transform: translateY(-50%) rotate(-45deg);
        left: 1rem;
        top: 0;
    }
}

.tooltip-promo-text-accentuate {
    background: $color-red14;
    max-width: 9rem;
    padding: 0.2rem 0.7rem;
    border-radius: 0.3rem;
    color: $color-white;
    margin-bottom: 0.5rem;
}

.tooltip-promo-text-checkout-price {
    font-weight: $font-weight-bold;
    color: $color-red;
}

.tooltip-text {
    display: flex;
    flex-direction: column;
    margin-top: $space-3;
}
