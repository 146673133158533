$nav-bar-logo-size: 19rem !default;
$nav-bar-botton-row-height: 4rem;

.nav-bar {
    $nav-bar-slider-mr: $space-3;
    $nav-bar-slider-mr: $space-3;

    margin-bottom: $space-2;
    display: flex;

    @include max-screen($screen__m) {
        .--scrolled & {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .product-menu-link {
            display: flex;
            flex-grow: 1;
            min-width: 0;
        }
    }

    &__menu {
        display: flex;
        width: 100%;

        &-listing {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            gap: $space-4;
            flex-grow: 1;
            min-width: 0;

            @include min-screen($screen__m) {
                margin-right: $space-5;
            }

            > li {
                margin: 0;

                &:not(.first) {
                    flex-grow: 1;
                }

                &.first {
                    display: none;
                    line-height: 1;
                    margin-right: -#{$space-1};
                    width: 100%;

                    @include min-screen($screen__m) {
                        width: $nav-bar-logo-size;
                        display: inline-flex;
                        flex-shrink: 0;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        gap: $space-2;
                        border-radius: 2.8rem;
                        color: $color-white;
                        text-transform: uppercase;
                        font-weight: $font-weight-bold;
                        padding: $space-1 $space-3;
                        background-color: $color-secondary;
                        transition: background-color var(--transition-normal);

                        &::before {
                            content: '';
                            @include lib-background-image(
                                'svg/_clean/white/bars.svg',
                                center,
                                no-repeat,
                                transparent,
                                false
                            );
                            width: 2.4rem;
                            height: 2.4rem;
                            left: 0;
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-primary;
                        }

                        span {
                            white-space: nowrap;
                        }
                    }

                    &[aria-expanded='true'] {
                        a {
                            &::before {
                                @include lib-background-image(
                                    'svg/_clean/white/cross.svg',
                                    center,
                                    no-repeat,
                                    transparent,
                                    false
                                );
                            }
                        }
                    }
                }

                &.active {
                    color: $color-primary;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &__slider {
        position: relative;
        height: $nav-bar-botton-row-height;
        overflow: visible;
        flex-grow: 1;
        margin-right: $nav-bar-slider-mr;
        min-width: 0;

        .#{$css-namespace}navbar-aside & {
            display: none !important;
        }
    }

    &__slides {
        display: flex;
        padding: 0;
        height: 100%;
        align-items: center;

        &:has(.slick-slide:last-child.slick-active) {
            &::after {
                display: none;
            }
        }

        &.not-initialized {
            white-space: nowrap;
            gap: $space-4;
            overflow: hidden;
        }

        &.last-slide {
            &::after {
                opacity: 0;
                visibility: hidden;
            }
        }

        &.progress-slide {
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }

        &::after {
            opacity: 1;
            visibility: visible;
            right: 0;
            background: linear-gradient(90deg, rgba($color-white, 0) 37.07%, rgba($color-white, 1) 65.52%);
        }

        &::before {
            opacity: 0;
            visibility: hidden;
            left: 0;
            background: linear-gradient(90deg, rgba($color-white, 1) 37.07%, rgba($color-white, 0) 65.52%);
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 10rem;
            z-index: 2;
            transition: all var(--transition-normal);
            pointer-events: none;
        }

        &:hover,
        &:focus-within {
            .slick-prev,
            .slick-next {
                opacity: 1;
                visibility: visible;
            }
        }

        .slick-disabled {
            opacity: 0 !important;
            visibility: hidden !important;
        }

        .slick-prev,
        .slick-next {
            // TODO : refacto using default slick-arrow and add only specific style here
            position: absolute;
            z-index: 3;
            padding: $space-1;
            border: 0.1rem solid $color-disabled;
            border-radius: 0.3rem;
            background-color: $color-grey-background;
            color: $color-primary;
            font-size: $font-size-m;
            opacity: 0;
            visibility: hidden;
            transition: all var(--transition-normal);
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            left: 100%;
            margin-left: -$nav-bar-slider-mr;
        }

        .category-item.query {
            color: $color-primary;
            font-weight: $font-weight-bold;
        }

        div,
        a {
            height: 100%;
        }

        a {
            color: $color-primary;
            display: flex;
            align-items: center;
            @extend %custom-underline;
        }

        .slick-list {
            width: 100%;
            height: 100%;
        }

        .slick-track {
            width: 100%;
            gap: $space-4;
            height: 100%;
            align-items: center;
        }
    }
}
