.#{$css-namespace}home-current-products {
    padding-bottom: $space-10;

    &-wrapper {
        margin-top: $indent__base;
    }

    &-category {
        &-title {
            @include lib-font-size($font-size__baseplus);
            font-weight: $font-weight-bold;
            color: $color-primary;
            margin-bottom: 0.7rem;
        }

        &-item {
            &:not(:last-child) {
                margin-bottom: $indent__s;
            }

            @include lib-font-size($font-size__s);
            @include max-screen($screen__m) {
                padding-left: $indent__s;
            }
            @include min-screen($screen__l) {
                @include lib-font-size($font-size-default);

                &:not(:last-child) {
                    margin-bottom: 0.7rem;
                }
            }
        }
    }

    + .reinsurance-container {
        .#{$css-namespace}before-footer & {
            margin-top: 3.35rem;
        }
    }
}
