.#{$css-namespace}plp {
    &_title {
        text-align: center;
        margin: $space-1 auto $space-2;
        display: flex;
        flex-direction: column;
        gap: $space-1;
        justify-content: center;

        @include min-screen($screen__m) {
            width: calc(100% - 10rem);
            margin: $space-4 auto $space-6;
            gap: $space-2;
        }

        .#{$css-namespace}title--primary {
            display: inline;
            line-height: 1.15;

            @include min-screen($screen__m) {
                line-height: 1;
            }
        }

        .#{$css-namespace}title.#{$css-namespace}title--secondary {
            display: inline-block;
            font-family: $font-secondary;
            font-size: $font-size-m;
            line-height: 1.25;

            @include min-screen($screen__m) {
                margin-top: 0;
                font-size: $font-size-l;
                line-height: 1.35;
            }
        }

        .#{$css-namespace}title_product-number {
            display: block;
            font-family: $font-default;
            font-size: $font-size-default;
            font-weight: $font-weight-bold;
            color: $color-secondary;

            @include min-screen($screen__m) {
                display: inline-block;
                margin-left: $offset-m;
                font-size: $font-size-m;
                font-weight: $font-weight-medium;
            }
        }
    }
}
