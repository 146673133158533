.#{$css-namespace}event-slot {
    $root: &;

    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    align-items: stretch;
    min-height: 43rem;
    padding: 2rem;
    background-color: $color-primary-darken;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.disabled {
        pointer-events: none;
        cursor: default;
    }

    &_bg-image {
        position: absolute;
        height: 100%;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: fill;
            object-position: 0 0;
        }
    }

    &_content {
        z-index: 1;
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 auto;
        align-items: stretch;
        color: $color-white;

        @include min-screen($screen__m) {
            flex-flow: row nowrap;
        }
    }

    &_icon {
        display: inline-flex;
        flex: 0 0 5.6rem;
        margin: 3rem 0;
        font-size: 5.6rem;
        color: $color-secondary;
    }

    &_text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
    }

    &_title {
        font-family: $font-secondary;
        color: $color-white;
        font-size: $font-size-xxl;
        font-weight: $font-weight-bold;
        text-align: center;
        line-height: 4rem;
    }

    &_subtitle {
        margin-top: 4rem;
        color: $color-white;
        font-size: $font-size-xl;
        font-weight: $font-weight-medium;
        text-align: center;
    }

    &_button {
        display: inline-flex;
        flex-flow: column nowrap;
        align-items: center;
        margin: auto 1rem 0;
        border: none;
        padding: 1.2rem;
        color: $color-primary;
        font-weight: $font-weight-medium;
        background-color: $color-white;

        &:hover,
        &:active,
        &:focus {
            border: none;
            color: $color-primary-darken;
            background-color: $color-white;
        }
    }

    // Banner style
    &--banner {
        padding: 0;
        min-height: auto;

        @include min-screen($screen__m) {
            flex-direction: row;
            padding: 0;
        }

        &::before {
            z-index: 1;
        }

        #{$root}_content {
            padding: 2rem 3rem;
        }

        #{$root}_text {
            @include min-screen($screen__m) {
                align-items: flex-start;
                justify-content: center;
            }
            @include screen($screen__m, $screen__l) {
                margin: 0 5rem;
            }
        }

        #{$root}_icon {
            flex: 0 0 5rem;
            margin: 1.5rem 0;
            font-size: 5rem;

            @include min-screen($screen__m) {
                flex: 0 0 7rem;
                margin: 0;
                font-size: 7rem;
            }
            @include min-screen($screen__l) {
                margin: 0 5rem;
            }

            &:empty {
                display: none;

                @include min-screen($screen__m) {
                    display: inline-flex;
                }
            }
        }

        #{$root}_subtitle {
            margin-top: 1.5rem;

            @include min-screen($screen__m) {
                margin-top: 0.5rem;
            }

            @include screen($screen__m, $screen__l) {
                text-align: left;
            }
        }

        #{$root}_title {
            @include screen($screen__m, $screen__l) {
                text-align: left;
            }
        }

        #{$root}_button {
            margin-top: auto;

            @include min-screen($screen__m) {
                align-self: center;
                margin: 0 0 0 auto;
                min-width: 22rem;
            }
            @include min-screen($screen__l) {
                margin: 0 9rem 0 auto;
            }
        }
    }
}
