@mixin lib-css-wrapper() {
    background-color: $color-white;
    padding: $space-4;
    border-radius: 0.3rem;
    box-shadow: var(--box-shadow);

    @include max-screen($screen__l) {
        margin: 0 $space-1;
    }
}
