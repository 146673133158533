.#{$css-namespace}order-summary {
    &_actions {
        &-status {
            display: inline-flex;

            .#{$css-namespace}link {
                margin-left: $offset-s;
                margin-top: 2rem;
                font-size: $font-size-s;
                color: $color-secondary;

                &:visited {
                    color: $color-secondary;
                }
            }

            svg {
                width: 1.3rem;
                height: 1.3rem;
            }

            .#{$css-namespace}badge {
                margin-top: 2rem;
            }
        }

        &-reorder {
            margin-top: $offset-l;
            font-size: $font-size-default;

            @include min-screen($screen__l) {
                margin-top: 2.5rem;
            }

            span {
                padding: 0 $offset-xxl 0 calc(#{$offset-xxl} - #{$offset-s});
                font-size: $font-size-default;
            }
        }
    }
}
