.#{$css-namespace}card {
    @extend .box-shadow-light;
    @extend .rounded-6;
    background-color: $color-white;
    margin-bottom: $space-2;

    &-wrapper {
        padding: $space-2 $space-3;
        display: flex;
        flex: 1;
    }

    &-content {
        flex: 1 1 auto;
        color: $color-black;

        &--toggle {
            display: flex;
            align-items: center;
            gap: $space-1;
        }

        p:not(.#{$css-namespace}title) {
            color: inherit;
            margin-bottom: 0.5rem;
        }

        .#{$css-namespace}title {
            margin-bottom: 1.5rem;
            font-size: $font-size-m;
        }
    }

    &[data-ui-action='link'] {
        .#{$css-namespace}card {
            &-link {
                flex: 0 0 auto;

                &-text {
                    text-decoration: underline;
                    color: inherit;
                    line-height: 1;
                }

                .#{$css-namespace}link {
                    text-decoration: none;
                }

                .dcfonts-icons {
                    font-size: 1.7rem;
                    color: inherit;
                    margin-right: $space-1 / 2;
                }
            }
        }
    }

    &[data-ui-action='reassurance'] {
        @extend .rounded-3;
        @extend .box-shadow;
        overflow: hidden;

        .#{$css-namespace}card {
            &-wrapper {
                border-top: solid 0.6rem $color-secondary;
                border-bottom: solid 0.6rem $color-secondary;
                padding: 1.5rem 1.5rem $space-3;
                text-align: center;

                @include min-screen($screen-md) {
                    padding: 2.5rem 2.5rem 3.4rem;
                }
            }

            &-content {
                ul,
                ol {
                    text-align: left;
                    margin: 2rem 0;

                    @include min-screen($screen-md) {
                        margin-top: 2rem;
                        margin-bottom: 2rem;
                    }

                    li {
                        display: flex;
                        align-items: center;
                        margin-top: 1.9rem;
                        margin-bottom: 1.9rem;
                        padding-left: 1.4rem;

                        .#{$css-namespace}link {
                            color: $color-secondary;
                        }
                    }
                }

                ul {
                    list-style-type: none;
                    padding: 0;

                    li {
                        padding-top: $space-1;

                        .dcfonts-icons {
                            color: $color-secondary;
                            margin-right: 1rem;
                            margin-top: -0.3rem;
                            font-size: $font-size-xl;
                        }
                    }
                }

                p {
                    &.#{$css-namespace}title {
                        margin-bottom: 0.5rem;
                        font-weight: $font-weight-semi-bold;
                    }

                    strong.primary {
                        color: $color-primary;
                    }
                }

                > *:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &[data-ui-action='toggle'] {
        &-wrapper {
            padding: $space-2;

            @include min-screen($screen-md) {
                padding: $space-2 $space-3;
            }
        }
    }

    &[data-ui-action='store'] {
        p {
            margin: 0;
            line-height: 1.45;
        }

        .#{$css-namespace}card-wrapper {
            @include min-screen($screen-md) {
                padding: $space-2 $space-3;
            }
        }

        .#{$css-namespace}card-content,
        .text-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-1;
        }

        .text-data {
            width: 100%;
        }

        .top-info {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: $space-1;
            width: 100%;
        }

        .right-info {
            display: flex;
            gap: $space-1;
            align-items: center;
        }

        .icon-text {
            display: flex;
            align-items: center;
            font-weight: $font-weight-bold;
            gap: $space-1;
            color: $color-secondary;
        }

        .subtitle {
            font-size: $font-size-m;
            font-weight: $font-weight-bold;
            line-height: 2.2rem;
        }
    }
}
