.#{$css-namespace}checkout__order-with-requested-date {
    display: flex;
    flex-direction: column;
    gap: $space-1 / 2;
}

.modal-choose-date {
    .modal-title,
    .modal-content {
        //TODO: should be generic for all modal in drawer mode
        max-width: 51rem;
        margin: 0 auto;
        width: 100%;
    }

    .air-datepicker.-inline- {
        margin-top: $space-2;
    }

    .control > * {
        font-size: $font-size-m;
        line-height: 1.375;
        font-weight: $font-weight-bold;
    }

    //TODO: should be dynamic and not specific
    .modals-wrapper.-modal--right-position & {
        .modal-inner-wrap {
            background-color: $color-white;
        }
    }
}
