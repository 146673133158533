// !important is necessary to override Payzen default ugly rules
.checkout-payment-method .payment-method {
    #payzen_rest_form {
        iframe {
            height: 3.3rem !important;
        }
    }

    div[is='krypton-field'] {
        height: inherit !important;
    }

    .kr-embedded {
        width: 100%;
        max-width: 54.8rem;
        display: flex;
        flex-direction: column;
        gap: $space-3;
        padding-top: $space-2;

        &[krvue] {
            .kr-pan,
            [class *= "kr-card-holder-"] {
                height: 4rem;
                margin-bottom: 0 !important;

                .kr-icon-wrapper-root {
                    display: none;
                    border: none !important;
                }
            }

            .kr-error .kr-field-component,
            .kr-field-component {
                border: none !important;
            }

            .kr-card-holder-name {
                .kr-input-field {
                    font-family: $font-default;
                }
            }
        }

        .kr-field-wrapper {
            box-sizing: border-box;
            margin-bottom: 0;
            border-radius: 0.3rem;
            background: $color-white;
            padding: #{$space-1 / 2} $space-2;
            border-width: 0.1rem;
            border-style: solid;
        }

        [class *= "kr-field-element"] {
            border-radius: $form-element-input__border-radius;
            border-color: $mainNeutralColor;
            padding: 0;

            &:not(.kr-focus):not(.kr-error) {
                border-color: $mainNeutralColor;
            }

            .kr-field-wrapper {
                border: none !important;
                height: 100%;
            }
        }

        .kr-focus {
            border-color: $color-primary;
        }

        .kr-error {
            border-color: $color-error;
        }

        .kr-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: $space-3;

            @include min-screen($screen__s) {
                flex-direction: row;
            }

            > * {
                margin: 0;
            }

            .kr-expiry,
            .kr-security-code {
                width: 100%;

                @include min-screen($screen__s) {
                    width: 50%;
                }
            }
        }

        .kr-label {
            &.default,
            &.kr-default {
                top: 1.3rem !important;
                left: 1rem !important;

                label {
                    color: $color-dark-grey !important;
                }
            }

            &.focus,
            &.kr-focus {
                label {
                    color: $color-primary !important;
                    font-family: $font-default !important;
                }
            }

            &.error,
            &.kr-error {
                label {
                    color: $color-error !important;
                }
            }

            label {
                background: $color-white;
                padding: 0 $space-1;
                height: 2rem;
                font-family: $font-default;
            }
        }

        [class *= "kr-placeholder-"] {
            &:not(.kr-placeholder-pan) {
                font-family: $font-default;
            }
        }

        .kr-form-error {
            text-align: left;
            width: 100%;
            margin-bottom: 0;
            padding: $space-2;
            font-size: $font-size-s;
            line-height: 1.42;
            border-radius: 0.3rem;
            border: 0.1rem solid $color-error;
            background: rgba($color-error, 0.1);

            &:empty {
                display: none;
            }

            > span {
                display: flex;
                align-items: center;
                gap: $space-2;
                padding: 0 !important;

                &::before {
                    content: $cross;
                    font-family: $font-icons;
                    color: $color-error;
                    font-size: $font-size-m;
                }

                span {
                    font-size: $font-size-default;
                    line-height: (21/14);
                    padding: 0;
                    font-family: $font-default;
                    color: $color-error !important;
                    text-align: left;
                }
            }
        }

        .kr-visibility-button {
            border: none !important;
        }

        // Keep [class *=""] to not fill icons you don't wan't to fill (due to Payzen structure/classes)
        .kr-icon {
            [class="kr-icon-wrapper brand"],
            [class="kr-icon-wrapper brand default"] {
                svg {
                    g,
                    path {
                        fill: $color-primary !important;
                    }
                }
            }

            &.kr-on-error {
                [class="kr-icon-wrapper brand"],
                [class="kr-icon-wrapper brand default"] {
                    svg {
                        g,
                        path {
                            fill: $color-error !important;
                        }
                    }
                }
            }
        }

        &.kr-help-button-inner-field {
            .kr-help-button-wrapper.kr-inner {
                border: none !important;
                height: 3rem;

                .kr-help-button {
                    height: 100%;
                    width: auto;
                    padding: 0 1rem 0 $space-2;

                    .kr-help-icon-wrapper {
                        background-color: $color-primary !important;
                        width: 2.4rem;
                        height: 2.4rem;

                        &.kr-error {
                            background-color: $color-error !important;
                        }

                        svg {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }
            }
        }
    }

    .checkout-agreements-block {
        margin-top: $space-3;
    }
}

.kr-help-modal-content {
    .kr-help-content {
        font-family: $font-default !important;
        font-size: $font-size-default !important;
        line-height: (20/14);
    }
}
