.#{$css-namespace}chip {
    $root: &;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.3rem;
    padding: $space-2 $space-1;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    gap: $space-1 / 2;
    min-height: $badge-min-height;

    &#{$root}--pick_up,
    &#{$root}--promo,
    &#{$root}--destock,
    &#{$root}--products,
    &#{$root}--market-price,
    &#{$root}--refurbished,
    &#{$root}--loyalty {
        color: $color-white;
        background-color: $color-red;
        line-height: 1.34;
        font-size: $badge-text-size;
        padding: $badge-padding;
        font-weight: $badge-text-weight;

        span {
            display: none;

            @include min-screen($screen-sm) {
                display: inline;
            }
        }
    }

    &#{$root}--products {
        background-color: $color-grey-background;
        color: $color-main-dark;
        font-weight: $font-weight-regular;
    }

    &#{$root}--market-price {
        background-color: $color-blue-promo;
    }

    &#{$root}--pick_up {
        background-color: $color-checkout-delivery-pickup;
        $icon-size: 1.9rem;
        padding-top: #{($badge-min-height - $icon-size) / 2}; // Icon redefining the height
        padding-bottom: #{($badge-min-height - $icon-size) / 2};

        .dcfonts-icons {
            font-size: $icon-size;
            font-weight: $font-weight-regular;
        }
    }

    &#{$root}--refurbished {
        background-color: $color-green-promo;
    }

    &#{$root}--loyalty {
        background-color: $color-purple;
    }
}
