// Variables
$roboto-font-path: '#{$path-fonts}/Roboto' !default;

// light italic version
@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

// light version
@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// regular version (default)
@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// medium version
@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// medium italic version
@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

// bold version
@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// black version
@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
