//  Newsletter subscription
.block.newsletter {
    .field {
        margin: 0;

        .control {
            @include lib-icon-font(
                $icon-envelope,
                $_icon-font-size: 3.5rem,
                $_icon-font-line-height: 3.3rem,
                $_icon-font-color: $form-element-input-placeholder__color
            );
            display: block;

            &::before {
                position: absolute;
            }
        }
    }

    input {
        padding-left: 3.5rem;
    }

    .title {
        display: none;
    }

    .label {
        @extend .abs-visually-hidden;
    }
}

//
//  Desktop
//  _____________________________________________

#subscribe-container {
    .block {
        &.newsletter {
            .content {
                border-radius: 0.4rem;
                border: 0.1rem solid #a9acad; // TODO: Use global var
                background-color: $color-white;
                overflow: hidden;
                position: relative;

                &:hover {
                    &:not(:focus-within) {
                        border-color: $color-blue4;

                        .actions {
                            border-left: 0.1rem solid $color-blue4;

                            button span {
                                color: $color-blue4;
                            }
                        }
                    }
                }

                &:focus-within {
                    border-color: #0162b1; // TODO: Use global var

                    .actions {
                        border-left: 0.1rem solid #0162b1; // TODO: Use global var

                        button span {
                            color: #0162b1; // TODO: Use global var
                        }
                    }
                }

                input {
                    border: 0;
                    background: none;
                    border-radius: 0;
                    height: 4.6rem;
                    padding-left: 5rem;
                }

                .actions {
                    width: 4.6rem;
                    padding: 0 $space-1;
                    border-left: 0.1rem solid #a9acad; // TODO: Use global var
                    position: absolute;
                    top: 0;
                    right: 0;

                    button {
                        background: $color-white;
                        height: 4.6rem;
                        width: 100%;
                        text-align: center;
                        padding: 0;
                        @include lib-css-border-less();

                        &:hover {
                            border: 0;
                        }

                        span {
                            @include lib-typography(
                                $_font-size: 1.4rem,
                                $_color: #1b1b1b,
                                // Todo : use $defaultGray var instead of hexa
                                $_font-family: $font-family__base,
                                $_font-weight: $font-weight__bold,
                                $_line-height: $line-height__base,
                                $_font-style: $font-style__base
                            );
                            text-align: center;
                        }
                    }
                }
            }
        }

        &.advices {
            h4 {
                @include lib-typography(
                    $_font-size: 1.8rem,
                    $_color: inherit,
                    $_font-family: $subFont,
                    $_font-weight: $font-weight__bold,
                    $_line-height: 1.4rem,
                    $_font-style: $font-style__base
                );
                border: 0;
                margin: 0;
                padding: 0;
            }

            p {
                @include lib-typography(
                    $_font-size: 1.2rem,
                    $_color: inherit,
                    $_font-family: $subFont,
                    $_font-weight: $font-weight__regular,
                    $_line-height: 1.4rem,
                    $_font-style: $font-style__base
                );
                margin: 0;
                padding: 0;
            }
        }
    }
}

@include max-screen($screen__l) {
    #subscribe-container {
        padding: 1.5rem 1.5rem 0 1.5rem;

        .block {
            margin-bottom: 0;

            &.newsletter {
                margin-top: 1.5rem;
            }

            &.advices {
                h4 {
                    background: none;
                    padding: 0 0 1.2rem;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    #subscribe-container {
        margin-top: 1.4rem;
        @include lib-vendor-prefix-flex-basis(100%);

        .block {
            margin: 0;

            &.advices {
                float: left;
                width: 50%;
            }

            &.newsletter {
                .content {
                    width: 31.4rem;
                    height: 4.6rem;
                }
            }
        }
    }
}
