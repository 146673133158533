@import '../../../../Descours/Descours/styles/blocks/category-header/category_header_layout';

[data-category-name='hc'] {
    border-right-color: nth(map-get($gamme-list, 'arrosage'), 1);
}

[data-category-name='hd'] {
    border-right-color: nth(map-get($gamme-list, 'tuyau-arrosage'), 1);
}

[data-category-name='hk'] {
    border-right-color: nth(map-get($gamme-list, 'pieces-detachees'), 1);
}

[data-category-name='hj'] {
    border-right-color: nth(map-get($gamme-list, 'eclairage-paysager'), 1);
}

[data-category-name='hl'] {
    border-right-color: nth(map-get($gamme-list, 'fournitures-diverses'), 1);
}

[data-category-name='hf'] {
    border-right-color: nth(map-get($gamme-list, 'filtration'), 1);
}

[data-category-name='hi'] {
    border-right-color: nth(map-get($gamme-list, 'fontaine'), 1);
}

[data-category-name='hm'] {
    border-right-color: nth(map-get($gamme-list, 'genie-climatique'), 1);
}

[data-category-name='he'] {
    border-right-color: nth(map-get($gamme-list, 'irrigation-agricole'), 1);
}

[data-category-name='hn'] {
    border-right-color: nth(map-get($gamme-list, 'pieces-detachees'), 1);
}

[data-category-name='ha'] {
    border-right-color: nth(map-get($gamme-list, 'piscine'), 1);
}

[data-category-name='hb'] {
    border-right-color: nth(map-get($gamme-list, 'pompage'), 1);
}

[data-category-name='hh'] {
    border-right-color: nth(map-get($gamme-list, 'reseau'), 1);
}

[data-category-name='hg'] {
    border-right-color: nth(map-get($gamme-list, 'traitement-eau'), 1);
}
