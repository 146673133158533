.#{$css-namespace}back-wrapper {
    &:has(~ div > .container.narrow),
    &:has(~ .container.narrow) {
        width: #{'min(46rem, 100%)'};
    }

    .secondary {
        float: none;
    }

    a.back {
        display: flex !important;
        margin-left: 6.2rem;
        color: $color-secondary;
        text-decoration: none;
        font-weight: $font-weight-semi-bold;

        .dcfonts-icons {
            width: $size-default * 2;
            height: $size-default * 2;
            font-size: $font-size-m;
            margin-left: -6.2rem;
            margin-right: 0.5rem;
            color: $color-secondary;
            fill: $color-secondary;
            font-weight: $font-weight-regular;
            vertical-align: middle;
            margin-bottom: $space-1;
        }

        @include min-screen($screen-md) {
            span {
                text-decoration: none;
            }

            @include lib-hover-focus {
                span {
                    text-decoration: underline;
                }
            }
        }
    }

    &.actions-toolbar > .secondary {
        margin-top: 2rem;
        text-align: left;

        @include min-screen($screen-md) {
            margin-top: 1rem;
        }
    }
}
