$searchbar-height-xs: 7rem;
$searchbar-position-left: 4.9rem;
$searchbar-padding-xs: 1.5rem;

#search_autocomplete {
    background: $color-white;
    z-index: 1003;
    border-radius: 0 0 0.3rem 0.3rem;
    width: 100%;
    @extend %scrollbars !optional;
    scrollbar-width: thin;
    scrollbar-color: $color-secondary transparent;

    @include max-screen($screen__l) {
        height: calc(100vh - var(--header-height));
        overflow-y: auto;
        left: 0;
    }

    .qs-option-name {
        strong {
            color: $color-secondary;
        }
    }

    .#{$css-namespace}quick-search {
        height: 100%;

        &-wrapper {
            display: flex;
            overflow-y: auto;
            height: 100%;

            @include max-screen($screen__l) {
                flex-direction: column;
                height: 100%;
            }
        }

        &-header {
            background: $color-blue-background-1;
            padding: $space-1 $space-2;
            color: $color-primary;
            font-size: $font-size-m;
            font-weight: $font-weight-bold;
            text-align: left;

            .see-all,
            .see-all-suggestion {
                text-align: right;
                float: right;
                color: $color-primary;
                font-weight: $font-weight-bold;
                font-size: $font-size-default;
                text-decoration: underline;

                @include max-screen($screen__l) {
                    padding-right: $space-2;
                }
            }

            .see-all-suggestion {
                display: none;
            }
        }

        &-content {
            padding: 2rem 2.5rem 2rem 1.5rem;

            @include min-screen($screen__l) {
                padding: 1.5rem 2.5rem 1.5rem;
            }

            ul {
                li {
                    margin: 1.2rem 0 0;
                    padding: 0;
                    list-style: none;
                    border: none;
                    background-color: inherit;
                    cursor: default;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:hover {
                        background-color: inherit;
                    }

                    .qs-option-name {
                        color: $color-black;
                        font-size: $font-size-default;
                        margin-top: 2rem;
                    }

                    .dcfonts-icons {
                        color: $color-search-layer-icons;
                        font-size: 1.8rem;
                        margin-right: $space-1 / 2;
                    }
                }
            }
        }

        &-left {
            min-width: 38%;

            @include min-screen($screen__l) {
                border-right: 0.4rem solid $color-white;
            }

            &-full {
                min-width: 100%;
            }

            &-brand {
                li {
                    span {
                        text-transform: uppercase;
                    }
                }
            }

            .suggestion-link:hover,
            .category-link:hover,
            .brand-link:hover {
                color: $color-secondary;
            }
        }

        &-right {
            width: 100%;

            .#{$css-namespace}quick-search-content {
                padding: $space-2;

                @include min-screen($screen__l) {
                    padding: $space-3;
                }
            }

            .#{$css-namespace}item-table_row-item_title {
                padding-top: 0.2rem;
                margin-top: -0.2rem;
            }

            .#{$css-namespace}order-items {
                .#{$css-namespace}item-table_row-item {
                    padding: $space-1;
                    border-bottom: 0.1rem solid $color-grey-argent;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    gap: $space-2;

                    &:last-child {
                        border-bottom: none;
                    }

                    @include max-screen($screen__l) {
                        flex-direction: row;
                    }

                    &:hover {
                        &::before {
                            content: '';
                            display: block;
                            width: 0.5rem;
                            height: 100%;
                            background-color: $color-secondary;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    .#{$css-namespace}item-table_row-picto {
                        img {
                            @include min-screen($screen__l) {
                                max-width: 5rem;
                                width: 5rem;
                                height: 5rem;
                            }
                        }
                    }

                    .#{$css-namespace}item-table_row-item_title {
                        margin-bottom: $space-1 / 2;
                    }
                }

                .#{$css-namespace}item-table_row-details {
                    text-align: left;
                    margin-top: 0;
                    flex: 1;

                    .#{$css-namespace}item-table_row-item_reference {
                        font-size: $reference-text-size;
                        font-weight: $reference-text-weight;
                        color: $reference-text-color;
                    }
                }
            }
        }
    }
}
