.#{$css-namespace}modal-popup {
    .#{$css-namespace}cart-download-popin-content {
        display: flex;
        flex-direction: column;
        gap: $space-2;

        .#{$css-namespace}form-radio-group--with-background {
            margin-bottom: 0 !important;
        }
    }

    .shipping-new-address-form,
    .form-edit-shipping-address .fieldset {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
}
