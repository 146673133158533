@include max-screen($screen__l) {
    .breadcrumbs {
        padding: $indent__s $indent__l;
        border-bottom: 0.1rem solid $color-gray78;
        margin-top: $indent__m;
    }
}

.breadcrumbs {
    @include lib-breadcrumbs();

    .item:not(:last-child)::after {
        top: 0;
    }
}
