@import 'collaborator';

.useraccount-customer-index {
    .page-header {
        max-width: 100vw;

        @include min-screen($screen__l) {
            max-width: 100%;
        }
    }

    .columns {
        .column.main {
            padding: 0;
            background: none;
            box-shadow: none;

            .#{$css-namespace}title[data-ui-title-level='1'] {
                line-height: 3.5rem;
            }
        }
    }
}

.#{$css-namespace}collaborators {
    $root: &;
    $box-shadow: var(--box-shadow);

    display: flex;
    flex-direction: column;

    &_content-container {
        display: flex;
        flex-direction: column;
        border-radius: 0.3rem;
        background-color: $color-white;
        box-shadow: $box-shadow;
        overflow: hidden;
    }

    &_filters {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 0.3rem;
        padding: 2rem 1rem;
        background-color: $color-primary;

        @include max-screen($screen__l) {
            &.padding-bottom-400 {
                padding-bottom: 4rem;
            }
        }

        @include min-screen($screen__l) {
            flex-direction: row;
            align-items: center;
            padding: 3rem;
            height: 10rem;
            background-color: $color-white-smoke-2;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;

        @include min-screen($screen__l) {
            padding: 0 0 3rem;
        }
    }

    &_container + &_content-container {
        margin-top: 2rem;
    }

    &_description {
        margin-top: 1rem;
        margin-bottom: 2rem;

        @include min-screen($screen__l) {
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    }

    &_cta-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        @include min-screen($screen__l) {
            flex-direction: row;
        }

        #{$root}_button:not(:last-child) {
            margin-bottom: 1rem;

            @include min-screen($screen__l) {
                margin-bottom: 0;
                margin-right: 3rem;
            }
        }
    }

    &_button {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid transparent;
        border-radius: 0.3rem;
        padding: 1rem 1.5rem;
        font-family: $font-default;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;

        @include min-screen($screen__l) {
            min-width: 25.7rem;
        }
    }

    &_create-btn[type='button'] {
        color: $color-white;
        background-color: $color-primary;

        &:hover,
        &:focus {
            @include min-screen($screen__l) {
                border: 0.1rem solid transparent;
                color: $color-white;
                background-color: $color-primary-darken;
            }
        }
    }

    &_button-icon {
        position: absolute;
        left: 1.5rem;
        font-size: $font-size-xl;
        color: $color-secondary;
    }

    &_import-btn[type='button'] {
        border-color: $color-grey-dove;
        color: $color-grey-dark2;
        background-color: $color-white;

        @include min-screen($screen__l) {
            &:hover,
            &:focus {
                border: 0.1rem solid $color-primary-darken;
                color: $color-grey-dark2;
                background-color: $color-white;
            }
        }
    }

    &_search-container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;
        border-radius: 0.3rem;
        height: 4rem;
        background-color: $color-white;
        box-shadow: $box-shadow;

        @include min-screen($screen__l) {
            margin-bottom: 0;
            margin-right: 3rem;
            width: 60rem;
        }
    }

    &_search-icon {
        padding-left: 1rem;
        font-size: $font-size-xl;
        color: #666666; // TODO : use $color-gray40 var instead of hexa
    }

    &_search-input[type='text'] {
        // Remove attribute selector once global style fixed.
        border: none;
        height: 4rem;
        padding: 1rem;

        &::placeholder {
            color: $color-grey-dove;

            @include min-screen($screen__l) {
                color: $color-grey-dark;
            }
        }
    }

    .#{$css-namespace}collaborator {
        padding: 1rem 0;

        @include min-screen($screen__l) {
            flex-direction: row;
            align-items: center;
            height: 5rem;
        }

        &:not(:last-child) {
            border-bottom: 0.1rem solid $color-disabled-light;

            @include min-screen($screen__l) {
                border-bottom: none;
            }
        }

        &_label {
            @include min-screen($screen__l) {
                display: none;
            }
        }
    }

    &_role {
        &_wrapper {
            border: 0.2rem solid;
            border-radius: 2rem;
            padding: $indent__xs 1rem;
            display: flex;
            align-items: center;
            min-width: 12rem;
            justify-content: center;

            @include min-screen($screen__l) {
                padding: 0.5rem 1.3rem;
                width: 19rem;
            }

            &.admin {
                border-color: $color-collaborator-admin;
            }

            &.admin_demo {
                border-color: $color-collaborator-admin-demo;
            }

            &.buyer {
                border-color: $color-collaborator-buyer;
            }

            &.guest {
                border-color: $color-collaborator-guest;
            }

            &.demo {
                border-color: $color-collaborator-demo;
            }
        }

        &_label {
            font-size: $font-size-s;
            line-height: 1.7rem;
            text-transform: uppercase;
            margin-bottom: 0 !important;

            @include min-screen($screen__l) {
                font-size: $font-size-default;
                line-height: 1.9rem;
            }
        }

        &-filter-disable {
            display: none;
            position: absolute;
            bottom: -0.5rem;
            left: 1.5rem;
            transform: translateY(100%);
            font-size: $font-size-s;
            padding-bottom: 2rem;
            text-decoration: underline;

            &.visible {
                display: block;
            }
        }
    }

    &__fullname {
        color: $color-secondary;
        font-weight: $font-weight-bold;
        text-decoration: underline;
        font-size: $font-size-s;

        @include min-screen($screen__l) {
            font-size: $font-size-default;
        }
    }

    &__email {
        color: $color-grey-dark2;
    }

    &_table {
        display: flex;
        flex-direction: column;
        min-width: 100%;

        @include min-screen($screen__l) {
            display: table;
        }

        thead {
            display: none !important;

            @include min-screen($screen__l) {
                display: table-header-group !important;
            }
        }

        th {
            color: $color-grey-dove;
            font-size: $font-size-s;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            cursor: default;

            @include min-screen($screen__l) {
                padding-top: $indent__base;
                padding-bottom: 1.5rem;
                height: 5.7rem;
            }

            &:not(.sorting_disabled) {
                cursor: pointer;
            }

            &.fullname {
                @include min-screen($screen__l) {
                    width: 26% !important;
                }
            }

            &.email {
                @include min-screen($screen__l) {
                    width: auto !important;
                }
            }
        }

        th,
        td {
            vertical-align: middle;

            @include min-screen($screen__l) {
                height: 7.2rem;
            }

            &:first-child {
                @include min-screen($screen__l) {
                    padding-left: var(--datatable-cell-spacing);
                    border-left: 0.3rem solid transparent;
                }
            }

            &:last-child:not(:first-child) {
                @include min-screen($screen__l) {
                    text-align: right;
                    padding-right: var(--datatable-cell-spacing);
                    width: 13.6rem !important;
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;

            @include min-screen($screen__l) {
                display: table-row-group;
            }

            tr {
                display: flex;
                flex-direction: column;
                padding: $indent__s 0;
                border-top: 0.1rem solid $color-disabled-light;

                @include min-screen($screen__l) {
                    display: table-row;
                    padding: 0;
                }

                &:hover[data-js-collaborator-edit] {
                    @include min-screen($screen__l) {
                        cursor: pointer;
                        box-shadow: $box-shadow;
                    }

                    td:first-child {
                        @include min-screen($screen__l) {
                            border-left-color: $color-secondary;
                        }
                    }

                    #{$root}__fullname {
                        @include min-screen($screen__l) {
                            text-decoration: none;
                        }
                    }
                }

                td {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: $indent__s 0;

                    @include min-screen($screen__l) {
                        display: table-cell;
                        padding: 2rem 1rem;
                    }

                    &.dataTables_empty {
                        cursor: default;
                    }
                }
            }
        }
    }

    &_role-header {
        @include min-screen($screen__l) {
            width: 25rem !important;
        }

        .collaborator-role-modal-link {
            display: inline-flex;
            vertical-align: top;

            .dcfonts-icons {
                font-size: $font-size-m;
            }

            + span {
                display: inline-flex;
                vertical-align: middle;
                line-height: 1.7rem;
                margin-left: 0.3rem;
            }
        }
    }

    &_filter-container {
        position: relative;
        background-color: $color-white;
        border-radius: 0.3rem;

        label {
            display: none;
        }
    }

    .#{$css-namespace}collaborators_role-filter.nice-select {
        height: 4rem;
        border: 0.1rem solid $color-grey-dove;
        border-radius: 0.3rem;
        background: transparent;
        z-index: 1;

        @include min-screen($screen__l) {
            width: 19rem;
        }

        &::after {
            display: none;
        }

        &.--hasValue,
        &.open {
            .list {
                width: calc(100% + 0.2rem);
                margin: -0.1rem 0 0 0;
                border-top-color: $color-disabled-light;

                &::before {
                    content: '';
                    background: $color-white;
                    position: absolute;
                    top: -0.6rem;
                    left: 0;
                    width: 100%;
                    height: 0.5rem;
                }
            }

            ~ label {
                display: block;
                top: -$space-1 !important;
            }
        }

        &.open {
            overflow-y: initial;
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:not(.--hasValue) {
                .current {
                    color: $color-disabled;
                }
            }
        }

        ~ i {
            z-index: 0;
        }

        ~ label {
            z-index: 1;

            &::before {
                content: '';
                background-color: $color-white-smoke-2;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 0.9rem;
                z-index: -1;
            }
        }

        &.--hasValue {
            .current {
                color: $color-main-dark;
            }
        }

        .current {
            color: $color-grey-dove;
            height: auto;
            opacity: 1;
        }

        .list {
            margin-top: 0;
            margin-left: -0.1rem;
            margin-right: -0.1rem;
            border: 0.1rem solid $color-form-border;
            border-top-color: transparent;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;

            .option {
                margin: 0;
                min-height: 2.6rem;
                line-height: 1.6rem;
                padding-top: $indent__xs;
                padding-bottom: $indent__xs;

                &[data-value=''] {
                    + .option {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &_actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include min-screen($screen__l) {
            justify-content: flex-end;
            max-width: 5.2rem;
            margin-left: auto;
        }
    }

    &_action-btn {
        display: inline-flex;
        flex-direction: row;
        border: none;
        margin-right: auto;
        padding: 0;
        color: $color-grey-dove;
        font-size: 2.4rem; // Icon size
        background: transparent;

        &,
        &:visited {
            color: $color-grey-dove;
        }

        &:hover,
        &:focus {
            @include min-screen($screen__l) {
                color: $color-secondary;
            }
        }

        &[type='button'] {
            &:hover,
            &:focus {
                border: none;
                background-color: transparent;
            }
        }
    }

    &_delete-action {
        padding: 0;
        font-size: $font-size-m;
        color: $color-grey-dark2;
        margin-left: var(--datatable-cell-spacing);
    }
}
