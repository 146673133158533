//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 2.9rem !default;
$form-calendar-icon__color: $primary__color !default;
$tooltipWidth: 4.8rem;
$icon-calendar__font-size: 4rem !default;

.actions {
    .back {
        @include lib-font-size(16);

        padding: 0;

        text-transform: none;

        border: none;
        background: none;

        &::before {
            @include lib-font-size(12);

            font-family: 'icomoon' !important;

            display: inline-block;

            margin: -0.2rem 0.6rem 0 0;

            content: '\e904';
            transform: rotate(90deg);
            vertical-align: middle;

            color: $color-gray52;
        }
    }
}

.fieldset {
    .control {
        position: relative;

        &.has-tooltip {
            > .input-text,
            > .control-pass,
            > textarea,
            > select,
            > .comboTreeWrapper {
                display: inline-block;
                width: calc(100% - 4.8rem);
            }
        }

        &.has-currency {
            > .input-text,
            > .control-pass,
            > textarea,
            > select,
            .comboTreeWrapper {
                display: inline-block;
                width: calc(100% - #{$tooltipWidth + 5rem}) !important;
                margin-right: $indent__s;
            }
        }

        .input-text,
        .input-password,
        select {
            &:focus,
            &:not([value='']),
            &:not([value]),
            &:not(:empty) {
                padding-top: 1.2rem;

                ~ .label {
                    top: 0.5rem;
                    left: 2.1rem;
                    font-size: $font-size-xs;
                    pointer-events: auto;
                }
            }
        }

        .label {
            position: absolute;
            pointer-events: none;
            top: 1.2rem;
            left: 2.25rem;
            transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1); // TODO : Uniformise using var(--transition-normal)
            color: $color-gray34;
            cursor: text;
            pointer-events: none;
        }

        &:has(.comboTreeWrapper),
        &:has(select),
        &:has(.nice-select) {
            .input-text ~ label {
                pointer-events: auto;
            }
        }
    }
}

.fieldset {
    @include lib-form-fieldset();

    &:last-child {
        margin-bottom: 0;
    }

    > .field,
    > .fields > .field {
        @include lib-form-field();

        &.no-label {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        &.choice {
            .label {
                display: inline;
                font-weight: $font-weight-regular;
            }
        }

        .label {
            .column:not(.main) & {
                font-weight: $font-weight-regular;
            }
        }

        .field.choice {
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        input[type='file'] {
            margin: $indent__xs 0;
        }
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {
    strong {
        margin-left: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

.field {
    &.date {
        @extend .abs-field-date;

        .time-picker {
            display: inline-block;
            margin-top: $indent__s;
            white-space: nowrap;
        }
    }

    .message {
        &.warning {
            margin-top: $indent__s;
        }
    }
}

.field-error,
div.mage-error {
    &:not(:empty) {
        position: relative;
        margin-top: $space-1;
        padding-left: $space-3;
        padding-right: 0.5rem;
        font-weight: $font-weight-bold;
        line-height: 1.8rem;
        text-align: left;
    }
}

div.mage-error:empty {
    display: none;
}

.field-error {
    @include lib-form-validation-note();
}

div.mage-error-wrapper {
    border-color: $color-red10;
    border-width: 0.2rem;
}

.field .tooltip {
    @include lib-tooltip(right);

    .tooltip-content {
        min-width: 20rem;
        white-space: normal;
    }
}

input,
select {
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

._has-datepicker {
    ~ .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content: $icon-calendar,
            $_icon-font-color: $primary__color__lighter,
            $_icon-font-size: $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display: block,
            $_icon-font-text-hide: true
        );
        display: inline-block;
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

//
//  Sidebar forms
//  -----------------------------------------

.sidebar {
    .fieldset {
        margin: 0;

        > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 #{$space-1 / 2};
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .fieldset {
        .legend {
            @extend .abs-margin-for-forms-desktop;
        }

        > .field {
            @include lib-form-field-type-revert();
            margin: 0 0 $form-field__vertical-indent__desktop;
        }
    }
}

@include min-screen($screen__l) {
    .fieldset {
        .control {
            &.w25 {
                width: 25%;
            }

            &.w35 {
                width: 35%;
            }

            &.w45 {
                width: 45%;
            }

            &.w50 {
                width: 50%;
            }
        }
    }
}

//
//  Overwrite Chrome / Safari background autofill on inputs /
//  _____________________________________________
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 100rem $defaultColor inset;
}
