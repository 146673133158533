.#{$css-namespace}equivalent-products {
    &__label {
        display: flex;
        align-items: flex-start;
        margin: 0;
        padding: 0 $space-3;
        color: $color-primary;
        font-weight: $font-weight-regular;
        font-size: $font-size-s;
        background-color: transparent;

        @include min-screen($screen__l) {
            align-items: center;
            font-weight: $font-weight-medium;
            font-size: $font-size-default;
            padding: #{$space-3 / 2} $space-3;

            &:nth-child(odd) {
                background-color: $color-grey-background;
            }
        }

        span {
            &:first-letter {
                text-transform: uppercase;
            }
        }
    }

    &__labels {
        @include lib-list-reset-styles();

        position: absolute;
        z-index: 10;
        bottom: 0;
        margin-top: auto;
        border-bottom: 0.1rem solid transparent;
        text-align: left;
        pointer-events: none;

        @include max-screen($screen__l) {
            display: none;
        }

        @include min-screen($screen__l) {
            position: static;
            width: 22.5rem;
            pointer-events: auto;
        }
    }
}
