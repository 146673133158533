.home-current-offers-container,
.home-best-sellers-container {
    background: $color-gray-light6;

    .related-products-container {
        box-shadow: none;
    }
}

.products_offer__container {
    .loading-mask {
        position: static;
        background: transparent;
        margin: $indent__xl auto;

        .loader {
            display: flex;
            flex-direction: column;

            p {
                font-size: 1.5rem;
                text-align: center;
                font-weight: $font-weight-bold;
            }
        }

        .loader > img {
            position: static;
        }
    }

    .#{$css-namespace}title-section {
        margin: 0 3rem; // quick fix for align carousels arrows with other block-content
    }
}

.products_offer {
    display: flex;
    width: 100%;

    &__container {
        width: 100%;
        position: relative;
    }

    &__title {
        font-size: $font-size-xl;
        text-align: center;
    }

    &_see_more {
        font-weight: $font-weight__bold;
        @include lib-font-size(15);
        position: absolute;
        top: 3.4rem;
        right: 0;

        span {
            vertical-align: middle;

            &.icon-fleche {
                color: $color-blue3;
                margin-left: 1.2rem;
            }
        }
    }
}

.product_grid {
    &__item {
        box-sizing: border-box;
        margin-right: auto;
        margin-left: auto;
        border: 0.1rem solid $border-color__base;
        background: $clearColor;

        &--link {
            &-four {
                display: block;
                padding: $space-3;

                &:hover {
                    color: inherit;
                }
            }

            &-five {
                display: block;

                &:hover {
                    color: inherit;
                }
            }
        }

        h4 {
            margin-bottom: 1.5rem;
        }
    }

    &__img_container {
        text-align: center;
        margin-bottom: 2rem;

        .product-image-wrapper {
            display: block;
            width: 20rem;
            height: 20rem;
            max-width: 100%;
            margin: 0 auto;

            img {
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
            }
        }
    }

    &__brand {
        text-align: center;
        padding: $indent__s;
        white-space: normal;
        min-height: 2.5rem;
        font-size: $font-size-default;

        img {
            width: 10rem;
            height: 2rem;
        }
    }

    &__text {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        white-space: normal;
        color: $color-gray-light7;
        @include lib-font-size(14);

        &--bold {
            font-weight: $font-weight-bold;
            min-height: 5rem;
        }
    }

    &__btn_container {
        text-align: center;

        .action {
            &.primary {
                display: block;
                margin: 2rem 3.7rem 0;
                white-space: normal;

                @include max-screen($screen__s) {
                    margin: 0;
                }
            }
        }
    }

    &__not_connected {
        font-size: $font-size-s;
        text-align: center;
        margin: 0 auto;
        padding: 1.5rem 0;
        display: block;
        color: $color-gray-light7;
        font-family: Arial, Verdana, sans-serif;
        background: $lightBlueBackgroundColor;
        white-space: normal;

        &_container {
            padding: 0 0.9rem 1.2rem;
        }

        &__five {
            padding: 0 1.2rem;
            margin-top: 1rem;
        }

        .product_grid__not_connected_link {
            display: inline-block;
            text-decoration: underline;
            color: $color-blue3;

            font-weight: $font-weight-bold;
        }
    }
}

.product_grid__item__not_connected_container {
    padding: 0 0.9rem 1.2rem;
}

.product_grid__not_connected_link.product_grid__not_connected_link_five {
    display: block;
}

@include max-screen($screen__l) {
    .products_offer {
        &_see_more {
            top: 104%;
            display: block;
            left: 0;
            text-align: center;
        }
    }

    .product_grid {
        &__item {
            display: inline-block;
            width: 65%;
            margin-left: $indent__m;
            vertical-align: top;

            &:first-child {
                margin-left: 0;
            }

            &--link {
                &-five {
                    padding: $space-3;
                }
            }
        }

        &__text {
            &--bold {
                min-height: 6rem;
            }
        }

        .product_grid__not_connected_link {
            display: block;
        }

        &__img_container {
            text-align: center;
        }
    }

    .product_grid__item__not_connected_container,
    .product_grid__not_connected__five {
        padding: 0 1.9rem 1.9rem;
    }
}

@include max-screen($screen__xl) {
    .home-current-offers-container,
    .home-best-sellers-container {
        padding: 3rem 2rem;
    }
}

@include min-screen($screen__l) {
    .home-current-offers-container,
    .home-best-sellers-container {
        padding-bottom: $indent__xl;
    }

    .products_offer {
        display: flex;
        width: 100%;

        &__container {
            width: 100%;
            max-width: 132.5rem;
            margin-right: auto;
            margin-left: auto;
            padding-top: $indent__l;
        }
    }

    .product_grid {
        &__item {
            display: inline-block;
            width: 31.2rem; /* HACK IE */
            margin-left: 2rem;
            vertical-align: top;

            &__four {
                width: calc((100% - 6rem) / 4);
                padding: 0;
            }

            &__five {
                width: calc((100% - 8rem) / 5);
                padding-bottom: 1.2rem;
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                @include lib-css-box-shadow-hover();

                border-color: $color-blue3;
            }
        }
    }
}

@include screen($screen__m, $screen__l - 1) {
    .product_grid__item {
        width: 35%;
    }
}

@include min-screen($screen__xl) {
    .home-current-offers-container,
    .home-best-sellers-container {
        padding: #{$indent__xl + 5};
    }
}

@include max-screen($screen__xs) {
    .product_grid__btn_container .action.primary {
        margin: 0;
    }

    .product_grid {
        &__text {
            &--bold {
                min-height: 7.5rem;
            }
        }
    }
}
