// parent has to be at least relative and with width and height
.#{$css-namespace}loader {
    &::after {
        content: '';
        background: url('../images/loader.svg') center center no-repeat;
        width: 6.4rem;
        height: 6.4rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
    }

    &:not(body) {
        > * {
            opacity: 0;
            pointer-events: none;
        }
    }

    @at-root body#{&} {
        &::before,
        &::after {
            content: '';
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        &::before {
            background: $color-white;
            opacity: 0.5;
            z-index: 21;
        }

        &::after {
            margin: auto;
            z-index: 22;
            transform: none;
        }

        > .loading-mask {
            display: none !important; // !important because Magento loader is set in JS
        }
    }
}
