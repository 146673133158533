.top-links {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0 0 0 $space-2;
    margin: 0;
    gap: $space-2;
    flex-shrink: 0;
    flex-grow: 0;

    @include max-screen($screen__l) {
        padding-top: $space-2;
    }

    @include min-screen($screen__l) {
        flex-direction: row;
        padding: 0;
        gap: $space-3;
    }

    .header__wrapper & {
        @include max-screen($screen__l) {
            display: none;
        }
    }

    &__item {
        margin: 0;
        white-space: nowrap;

        a {
            text-decoration: none;
            @extend %custom-underline;

            &::before {
                bottom: -1.3rem; // same render as links in slick-slide in navbar using the same extend
            }

            @include max-screen($screen__l) {
                &:hover,
                &:focus-within {
                    text-decoration: underline;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        &-mobile {
            display: block;

            @include min-screen($screen__l) {
                display: none;
            }

            a {
                @include max-screen($screen__l) {
                    color: $color-primary;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}
