%table-header {
    background: $color-primary;
    font-weight: $font-weight-bold;
    color: $color-main-light;

    > tr {
        > th,
        td {
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }
    }
}
