.#{$css-namespace}modal-checkout-addtocart-modal {
    $padding-l: 2rem !default;
    $carousel-gap: $space-3; // To fit with gap in homepage sliders
    $top-mobile: 4rem !default;

    #checkout-addtocart-modal {
        display: flex;
        flex: 1 1 auto;
        max-height: inherit;
        width: 100%;
    }

    .modal-content {
        .#{$css-namespace}checkout-addtocart__modal__container {
            display: flex;
            flex-direction: column;
            gap: $space-2;

            .related-products-container {
                display: flex;
                flex-direction: column;
                margin-top: $space-2;
                background-color: $color-white;
                left: -#{$space-3};

                &,
                &#cross-sell {
                    padding: $space-3;
                    margin-bottom: 0;
                    width: calc(100% + #{$space-3} * 2);
                }
            }

            .#{$css-namespace}product-carousel__top {
                padding: 0;
            }
        }
    }

    .#{$css-namespace}checkout-addtocart__modal__container {
        width: 100%;

        #products-summary {
            display: flex;
            flex: 0 1 auto;
            flex-direction: column;
        }

        .#{$css-namespace}title-section {
            line-height: 1.1;
            font-size: $font-size-xl;
            font-weight: $font-weight-semi-bold;
            color: $color-secondary;
        }

        .#{$css-namespace}product-carousel__wrapper {
            margin-bottom: $carousel-gap;
            width: 100%;

            .slick-dots {
                // TODO : define in breakpoint slick params
                @include max-screen($screen__m) {
                    display: none !important;
                }
            }

            .#{$css-namespace}product-carousel__slick-arrows-container {
                margin-bottom: $carousel-gap;
            }

            .#{$css-namespace}product_slot__title {
                margin-top: 0;
            }

            .#{$css-namespace}product_slot__name {
                margin-bottom: 0;
            }

            hr {
                margin-bottom: 0;
            }

            .#{$css-namespace}product_slot__price__reference {
                margin-bottom: 0;
            }

            .#{$css-namespace}product_slot__add_cart_main_button {
                margin-top: 1.2rem;
            }
        }

        .products-list-mention {
            display: none;
        }
    }
}
