.product-left-container {
    .dc_product_page_title {
        margin: $indent__xl 0;
        padding-bottom: $indent__s;
        border-bottom: 0.1rem solid $color-gray52;
        @include lib-font-size(22);
        font-weight: $font-weight__bold;
        text-transform: uppercase;
    }

    .documentation-list {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        margin-bottom: $indent__base;
        list-style-type: none;

        @include min-screen($screen__m) {
            margin: 0 0 $indent__base;
        }

        > li {
            width: 100%;
            padding: 1.5rem;
            box-shadow: 0 0 0.5rem 0 rgba(145, 145, 145, 0.5); // TODO: Use box-shadow var
            max-width: 22.5rem;
            border-radius: 0.4rem;
            margin: 0 0 0.75rem;

            @include min-screen($screen__m) {
                margin: 0 0 2rem;

                &:nth-child(even) {
                    margin-left: 2rem;
                }
            }

            & + & {
                margin-top: 1.5rem;
            }

            .link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;

                .type {
                    width: 2.5rem;
                    height: 2.5rem;
                    background:
                        url('../images/svg/_clean/download.svg') center center,
                        no-repeat,
                        transparent;
                }

                .title-format {
                    color: #a1a4a8; // TODO: Use global var
                    display: block;
                    margin-top: 0.5rem;
                    font-size: $font-size__base;
                    text-transform: uppercase;
                }

                .title-type {
                    .title-name {
                        @include lib-font-size(12);
                        font-weight: $font-weight-medium;
                        color: $color-grey-dark2;
                        margin: 0;
                    }
                }
            }
        }

        @include min-screen($screen__xs);
    }

    .#{$css-namespace}attribut_data,
    .#{$css-namespace}documentation {
        padding: 3rem 2rem;
        background: $color-white;
        border-radius: 1rem;

        .#{$css-namespace}title-section {
            margin-bottom: 2.2rem;

            @include min-screen($screen__m) {
                margin-bottom: 5.88rem;
            }
        }
    }

    .#{$css-namespace}attribut_data .#{$css-namespace}title-section {
        margin-bottom: 4.68rem;
    }

    .#{$css-namespace}description {
        background: $color-white;
        border-radius: 1rem;
        position: relative;
        overflow: hidden;
        min-height: 21rem;
        font-size: $font-size-default;
        line-height: 1.4;
        box-shadow: var(--box-shadow-light);
        width: 100%;
        padding: $space-2;
        margin-top: $space-2;
        margin-bottom: $space-2;

        @include min-screen($screen__l) {
            width: calc(100% + #{$space-2});
            left: -$space-1;
            padding: 2rem 1rem 3rem;
        }

        @include max-screen($screen__l) {
            margin: 1rem 0;
            line-height: 1.6;
        }

        .#{$css-namespace}title-section {
            margin-bottom: $space-2;
        }

        @include min-screen($screen__l) {
            padding: $space-4 $space-12 $space-4 #{$space-12 + 10.4rem};
            line-height: 1.4;

            &::before {
                content: '';
                background-color: $color-secondary;
                width: 7rem;
                height: 7rem;
                transform: rotate(45deg);
                position: absolute;
                left: -3.5rem;
                top: 8.5rem;
                box-shadow: 0 0 0 3rem #f6f7fc; // TODO: Use global var
                outline-color: #f6f7fc; // TODO: Use global var
            }
        }

        &__norms {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin-top: 4rem;

            &_title {
                margin: 0 0 2rem;
                color: $color-primary;
                font-size: $font-size-xl;
                font-style: italic;
                font-weight: $font-weight-medium;
                font-family: $font-secondary;
            }

            &_container {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: center;
                align-content: flex-start;
                margin: -1rem -1.5rem;

                @include min-screen($screen__l) {
                    justify-content: flex-start;
                    margin: -1rem -2.5rem;
                }
            }
        }

        &__norm {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem 1.5rem;
            width: 6.4rem;

            @include min-screen($screen__l) {
                margin: 1rem 2.5rem;
            }

            &_img_container {
                display: flex;
                flex-direction: row;
                flex: 1 0 6.4rem;
                align-items: center;
                justify-content: center;
                width: 6.4rem;
                max-width: 6.4rem;
                max-height: 6.4rem;
            }

            &_name {
                margin-top: 1rem;
                color: $color-disabled-dark;
                text-align: center;
                font-size: $font-size-s;
            }
        }
    }

    .#{$css-namespace}reference_table {
        display: none;
        margin: $indent__xxl 0;

        .#{$css-namespace}title-section {
            text-align: center;
        }

        // only show reference table on desktop
        @include min-screen($screen__l) {
            display: block;
        }
    }
}

.#{$css-namespace}navigation {
    &-left-col {
        background-color: $color-white;
        display: flex;
        align-content: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        margin-top: $space-2;
        margin-bottom: $space-2;

        @include max-screen($screen__l) {
            overflow-x: auto;
            display: inline-block;
            white-space: nowrap;
        }

        @include min-screen($screen__l) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                width: var(--app-width);
                max-width: 100%;
                left: 0;
                height: 100%;
                background: $color-white;
                z-index: -1;
                box-shadow: var(--box-shadow-light);
            }
        }

        &Item {
            color: $color-black;
            font-size: $font-size-default;
            padding: 0 $space-4;
            margin: $space-3 0;
            position: relative;

            &:not(:first-child) {
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 100%;
                    height: 100%;
                    width: 0.1rem;
                    background-color: $color-gray97;
                }
            }

            @include max-screen($screen__l) {
                display: inline-block;
                white-space: nowrap;
            }

            a {
                text-decoration: none;
                color: currentColor;
                transition:
                    font-weight 0.2s,
                    color 0.2s,
                    text-decoration 0.2s; // TODO : Uniformise using var(--transition-normal)

                &:hover {
                    font-weight: $font-weight-semi-bold;
                    color: $color-primary;
                    text-decoration: underline;
                }
            }
        }
    }

    &Item {
        &--referenceTable {
            color: $color-secondary;

            @include max-screen($screen__l) {
                display: none;
            }
        }
    }
}
