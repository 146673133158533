.#{$css-namespace}scrollbar,
%scrollbars {
    &::-webkit-scrollbar {
        width: 0.6rem;
    }

    &::-webkit-scrollbar-track {
        background: $color-grey-argent;
        border-radius: 0.3rem;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover {
        background: $color-secondary;
        border-radius: 0.3rem;
    }

    // ComboTree
    &.theList {
        max-height: 20rem;
    }
}

.ui-scrollbar {
    overflow-y: auto !important;
    scrollbar-width: thin;
}

.scroll-x-without-scrollbar {
    @media (pointer: coarse) {
        overflow-x: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border: none;
        }
    }

    &--mobile {
        @media (pointer: fine) {
            overflow: hidden;
        }
    }
}
