.#{$css-namespace}product-summary {
    display: flex;
    padding: 1.5rem 2rem;
    background-color: $color-main-light;
    box-shadow: var(--box-shadow);
    justify-content: space-between;
    margin-bottom: $space-1;

    @include max-screen($screen__m) {
        position: relative;
        flex-direction: column;
    }

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    &__left-container {
        display: flex;
        flex-direction: row;
    }

    &__image-container {
        position: absolute;
        display: flex;
        height: 100%;
        width: 6rem;
        margin-top: -1.5rem;
        margin-right: 2rem;
        align-items: center;

        @include min-screen($screen__m) {
            position: initial;
            margin-top: 0;
        }

        .product-image-wrapper {
            display: flex;
            height: min-content;
        }
    }

    &__product-info {
        text-align: left;
        margin-left: 7rem;

        @include min-screen($screen__m) {
            margin-left: 0;
        }

        h6.small {
            font-weight: $reference-text-weight;
            font-size: $reference-text-size;
            line-height: $reference-text-line-height;
        }

        &__brand {
            margin-top: 0;
            margin-bottom: $space-1 / 2;
            font-weight: $brand-text-weight;
            text-transform: $brand-text-case;
            color: $brand-text-color;
        }

        &__name {
            margin-top: 0;
            margin-bottom: $space-1 / 2;
            font-weight: $font-weight-regular;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            line-height: 1.5rem;
            overflow: hidden;
            height: 1.5rem;
            font-size: $font-size-default;
            max-width: 50rem;
            text-transform: uppercase;

            @include max-screen($screen__m) {
                font-size: $font-size-default;
            }
        }

        &__ref {
            margin-top: 0;
            margin-bottom: $space-1;
        }

        &__qty {
            margin: 0;
        }
    }

    .#{$css-namespace}product_slot__price_delivery {
        flex-grow: 0;
    }

    &__price {
        &,
        &__price_container {
            flex-direction: column;
            flex-grow: 0;
            align-items: stretch;
            align-self: center;
            width: fit-content;

            @include max-screen($screen__m) {
                margin-top: 1rem;
                margin-left: 7rem;
                align-self: start;
                text-align: left;
            }
        }

        &_container {
            text-align: start;

            @include min-screen($screen__m) {
                text-align: end;
            }
        }

        &__amount {
            color: $text-price-color;
            font-size: $font-size-default;
            font-weight: $font-weight-black;
            line-height: 1;
            white-space: nowrap;

            @include min-screen($screen-md) {
                font-size: $font-size-m;
            }
        }

        &__old-price {
            margin-right: $space-1;
            color: $color-main-dark;
            text-decoration: line-through;
            font-weight: $font-weight-regular;
            font-size: $font-size-s;
            line-height: 1;
        }

        &__amount {
            &.-promo {
                color: $color-red-promo;
            }
        }

        &__unitary {
            font-size: $text-price-unit-size;
            margin-bottom: 0;
            text-align: right;
            margin-top: $space-1 / 2;
            color: $text-price-unit-color;
        }
    }
}
