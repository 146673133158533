$link-gap: $space-1;

// defined links
.#{$css-namespace}link {
    @extend %link;

    &,
    &:visited {
        // prevent state in app/design/frontend/Descours/Descours/styles/theme/_body.scss:33
        color: $color-secondary;
    }

    &.#{$css-namespace}link--small {
        font-size: $font-size-s !important;
    }

    @include lib-hover-focus {
        color: $color-link-hover;
    }

    &:has(.dcfonts-icons),
    &:is(&--icon) {
        display: flex;
        gap: $link-gap;
        align-self: center;

        &,
        &:hover span {
            text-decoration: none;
        }

        .dcfonts-icons {
            width: fit-content;
            height: fit-content;
        }

        span {
            text-decoration: underline;
        }
    }

    &:is(&--centered) {
        justify-content: center;

        > span {
            flex: 0 0 auto;
        }
    }

    &--underline-offset {
        text-underline-offset: 0.5rem;
    }
}

.#{$css-namespace}link-title,
.#{$css-namespace}link-underline-reverse {
    @extend %link;
    text-decoration: none;

    @include lib-hover-focus {
        color: $color-secondary;
        text-decoration: underline;
    }
}

button.#{$css-namespace}link {
    background: none;
    border: none;
    font-weight: $font-weight-regular;
    padding: 0;
    @extend %link;
    color: $color-secondary;
    display: inline;

    @include lib-hover-focus {
        color: $color-sub-secondary;
        text-decoration: none;
    }
}

.#{$css-namespace}link-back {
    color: $color-secondary;
    margin-bottom: 2rem;

    .dcfonts-icons {
        color: inherit;
        margin-right: 1rem;
    }
}

.#{$css-namespace}link-with-icon {
    // TODO : refacto to use "dc__link dc__link dc__link--icon dc__link--underline-offset" + replace occurences used : app/code/Descours/Checkout/view/frontend/templates/cart/form.phtml
    display: inline-flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
    align-items: center;
    border: initial;
    padding: 0;
    font-weight: $font-weight-regular;
    color: $color-secondary;
    cursor: pointer;
    background-color: initial;
    gap: $link-gap;

    @include lib-hover-focus {
        border: initial;
        color: $color-secondary;
        background-color: initial;
    }

    &:focus {
        @include min-screen($screen__l) {
            outline: auto;
            outline-offset: 0.3rem;
        }
    }

    &:active {
        border: initial;
        color: $color-primary;
        background-color: initial;
    }

    > &_icon {
        font-size: $font-size-l;
    }

    > &_text {
        margin-left: 0.5rem;
        text-decoration: underline;

        @include lib-hover-focus {
            text-decoration: none;
        }
    }
}
