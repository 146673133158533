.#{$css-namespace}badge {
    @extend %badge;
}

// clickable badges
a.#{$css-namespace}badge,
button.#{$css-namespace}badge {
    cursor: pointer;

    &:visited {
        color: $badge-color-text;
    }

    &:hover,
    &:focus,
    &:active,
    &[data-ui-badge-status='selected'] {
        border-color: $badge-hover-color-border;
        text-decoration: none;
        background-color: $badge-hover-color-background;
        color: $badge-hover-color-text;
    }
}
