$product-slot-width: 22.5rem;
$product-slot-margin-x: 1rem;

%slider-container {
    position: relative;
    width: 100%;
    margin-top: $space-2;
    margin-bottom: $space-2;
    padding: $space-2;
}

// Related Products
// TODO: Replace these IDs by a generic selector once the designs of all sliders are consistent.
#cross-sell,
#linked-articles {
    &.related-products-container {
        @extend %slider-container;

        @include min-screen($screen__m) {
            padding: $space-4 0 $space-4 $space-3;
        }

        @include min-screen($screen__l) {
            width: calc(100% + #{$space-2});
        }
    }
}

#category-top-sales.related-products-container {
    @extend %slider-container;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

#profession-selection,
#last-purchases {
    padding: $space-4 $space-2 0;

    @include min-screen($screen__m) {
        padding: $space-4 0 0;
    }
}

.related-products-container {
    // TODO: Delete these lines when all the designs are consistent.
    position: relative;
    width: 100%;

    .#{$css-namespace}title-section {
        // !important === Override account section
        font-size: $font-size-m !important;
        line-height: 1.56;
        color: $color-secondary !important;
        margin-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        gap: $space-1 / 2;

        .modals-wrapper & {
            font-size: $font-size-xxl !important;
        }

        @include min-screen($screen__m) {
            font-size: $font-size-xxl !important;
            line-height: 1.16;
            gap: 0;
        }

        span {
            font-size: 2.5rem;
            line-height: 1.2;

            @include min-screen($screen__m) {
                font-size: $font-size-xxxl;
                line-height: 1.1;
            }
        }
    }
}

.products-list-mention {
    margin: 0.5rem 0 0 3rem;
}

.slick-dots {
    // move to __slider if needed
    margin-top: 2.2rem;
}

// Related Products < 1200px
@include max-screen($screen__l) {
    line-height: 2rem;

    .#{$css-namespace}title-section {
        span {
            font-size: $font-size-xxl;
        }
    }

    .related-products-container {
        .product_grid {
            .product_grid__item {
                h4 {
                    margin: 0;
                }
            }

            .product_grid__text,
            .product_grid__brand {
                @include lib-font-size(15);
            }
        }
    }
}
