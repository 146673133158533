@import 'module/product-chip';
@import 'module/cart';
@import 'module/checkout';
@import 'module/checkout-header';
@import 'module/minicart';
@import 'module/progress-bar';

.checkout-onepage-success {
    .column.main {
        padding: 0;
    }
}

// cart items
.item-info {
    .price_unit_quantity {
        display: block;
        font-family: $subFont;
        @include lib-font-size(12);
        color: $color-gray52;
    }

    .price_unit_discount {
        display: block;
    }
}

.checkout-container {
    @include max-screen($screen__l) {
        overflow: hidden;
    }

    @include max-screen($screen__xxs) {
        fieldset {
            min-width: 0;
        }
    }

    > .loading-mask ~ * .loading-mask {
        display: none;
    }
}

/* opc-sidebar */
aside.opc-sidebar {
    .opc-block-summary {
        border: 0.1rem solid $color-blue3;
        padding: 0;
        @include lib-css-border-radius(0.4rem 0.4rem 0.4rem 0.4rem);
        @include lib-css-box-shadow(
            $params: 0 0.3rem 0 0 #c9ccd0,
            $default: false
        ); // TODO: use $subAltPrimaryColor-lighten instead of hexa and Use box-shadow var

        > span {
            background: $color-blue3;
            padding: 1.5rem 2rem;
            color: $color-white;
            font-size: 2rem;
            font-weight: $font-weight-bold;
        }

        .items-in-cart {
            padding: 1.5rem 2rem;
        }
    }
}

/* opc-estimated-wrapper */
.opc-estimated-wrapper {
    display: none;
}

//Shipping-address
.checkout-index-index {
    // layout
    background: $color-grey-background;

    .page-wrapper {
        padding-top: 0;
    }

    .page-main {
        margin: 0;
    }

    .column.main {
        padding: 0;
    }

    //Forms
    .fieldset > .field.choice::before {
        width: auto;
        padding-right: 0;
    }

    // Override for Snowdog
    .modal-popup {
        .modal-inner-wrap {
            margin-left: auto;
        }
    }
}

.#{$css-namespace}cart_empty {
    text-align: center;
    margin: 0 0;
    padding: $space-5;
    position: relative;
    background: $color-background-page;

    &_paragraph {
        max-width: 59rem;
        margin-left: auto;
        margin-right: auto;
    }

    .#{$css-namespace}title {
        margin-bottom: 1.5rem;
    }

    &__icon {
        position: relative;
        font-size: 6rem;
        color: $color-primary;
        margin-bottom: 3rem;

        &::after {
            content: '0';
            position: absolute;
            top: 0;
            right: 0;
            background-color: $color-secondary;
            font-family: $font-family-name__base;
            font-size: $font-size-l;
            border-radius: 100%;
            width: 3rem;
            height: 3rem;
            line-height: 3rem;
            color: $color-white;
            text-align: center;
        }
    }

    &__actions {
        display: flex;
        flex-flow: row;
        justify-content: center;
        margin: $space-5 0;

        @include max-screen($screen__l) {
            flex-flow: column;
        }

        .#{$css-namespace}btn {
            margin: 0 0.5rem;
            padding-left: 6rem;
            padding-right: 6rem;
            justify-content: center;

            @include max-screen($screen__l) {
                + .#{$css-namespace}btn {
                    margin: 1rem 0.5rem 0;
                }
            }
        }
    }

    &__recommends {
        padding-top: 3rem;

        .event-showcase {
            margin-top: 0 !important;
        }

        > * + * {
            margin-top: 7rem !important;
        }
    }
}
