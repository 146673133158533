.checkout {
    &__addresses {
        border: 0.1rem solid $color-disabled-light;
        padding: 1rem 4rem;
        margin-top: 1rem;

        @include min-screen($screen__s) {
            display: flex;
            padding: 2.5rem 0;
            margin-top: 2rem;
        }

        [data-step='shipping'] & {
            display: none;
        }

        &-single {
            padding: 2rem 0.5rem;

            @include min-screen($screen__s) {
                padding: 0 6rem;
                flex: 1;
            }

            &-title {
                color: $color-primary;
                margin-bottom: 1.5rem;
                font-weight: $font-weight__heavier;

                @include min-screen($screen__s) {
                    margin-bottom: $space-2;
                }

                [data-delivery-mode='pickup'] & {
                    color: $color-accent;
                }
            }

            &-address {
                line-height: 2rem;
            }

            & + & {
                border-left: 0.1rem solid $color-disabled-light;

                @include max-screen($screen__s) {
                    border-left: none;
                    border-top: 0.1rem solid $color-disabled-light;
                }
            }
        }
    }
}
