.quick-access .account {
    height: 100%;

    @include min-screen($screen__l) {
        display: flex;
        align-items: stretch;
        min-height: 4.2rem; // Visually force 2 lines
    }

    @include max-screen($screen__m) {
        .customer-dropdown-wrap.active {
            &::before {
                content: '';
                position: fixed;
                top: calc(
                    var(--header-height) - (#{$menu-slider-height} + #{$search-bar-height} + #{$margin-height-static})
                );
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($color-black, 0.55);
                z-index: 10;

                .--scrolled & {
                    top: $menu-slider-height + $margin-height-scrolled;
                }
            }
        }
    }

    .customer-dropdown-wrap,
    &__links,
    .#{$css-namespace}account-links {
        display: flex;
    }

    &__link {
        height: 100%;
        display: flex;
        align-items: center;
        gap: $space-1;
        font-size: $font-size-s;
        cursor: pointer;
        transition: color var(--transition-normal);

        .active &,
        &:hover,
        &:focus-within {
            color: $color-primary;
        }

        &.isLogged {
            gap: $space-2;
            font-size: $font-size-s;

            .dcfonts-icons {
                position: relative;

                // Keep the after pseudo-element commented out pending approval (ECOM-11272)
                &::after {
                    content: $check-line;
                    position: absolute;
                    background-color: $color-secondary;
                    color: $color-white;
                    border-radius: 100%;
                    top: -0.7rem;
                    font-size: $font-size-xs;
                    font-weight: $font-weight-bold; // Icon has been updated in Figma with a different weight
                    left: 1rem;
                    text-align: center;
                    width: 1.6rem;
                    height: 1.6rem;
                    line-height: 1.6rem;
                }
            }
        }

        .dcfonts-icons {
            font-size: $font-size-xl;
        }

        span {
            line-height: 1.4;

            @include max-screen($screen__l) {
                display: none;
            }

            &.customer-name {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}
