$confirm-connecting-steps-circle-size: 3.5rem;

.confirm {
    &-welcome-card {
        box-shadow: var(--box-shadow);

        &:not(.p-0) {
            padding: $space-3;

            @include min-screen($screen__m) {
                padding: $space-5;
            }
        }

        &-header-content {
            h1 {
                font-size: 2.5rem;
                line-height: 3rem;

                strong {
                    font-size: $font-size-xxl;
                    line-height: 3.5rem;
                }

                @include min-screen($screen__m) {
                    font-size: $font-size-xxl;
                    line-height: 3.5rem;

                    strong {
                        font-size: $font-size-xxxl;
                        line-height: 5.5rem;
                    }
                }
            }

            .#{$css-namespace}title[data-ui-title-level='3'] {
                margin-top: 2rem;
                margin-bottom: 1rem;
            }
        }

        .alert_message_validate {
            background-color: rgba($color-success-secondary, 0.1);
            border-radius: 0.3rem;
            border: 0.1rem solid $color-success-secondary;
            padding: $space-2;
            margin: $space-2 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: $space-2;

            i {
                color: $color-success-secondary;
                font-size: 2.4rem;
            }

            p {
                margin-bottom: 0;
                text-align: left;
            }

            @include min-screen($screen__m) {
                margin: $space-3 0;
            }
        }

        .accountopeningrequest-container {
            border-radius: 0;
            box-shadow: none;
        }

        .#{$css-namespace}card {
            margin: $space-2 0;
            padding-bottom: $space-2;
            border-bottom: 0.1rem solid $color-border-grey-light;

            @include min-screen($screen__m) {
                margin: $space-3 0;
                padding-bottom: $space-3;
            }

            .#{$css-namespace}card-wrapper {
                padding: 0;
            }

            p > .#{$css-namespace}link {
                margin-top: $space-1;
            }
        }
    }

    &-connecting-steps {
        padding-bottom: 1.3rem;

        .#{$css-namespace}title {
            margin-bottom: 3.1rem;
        }

        &-wrapper {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            max-width: 48.1rem;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 1.5rem;

            @include min-screen($screen__m) {
                padding-bottom: 0;
            }
        }

        &-step {
            display: flex;
            width: 100%;
            margin-bottom: 0;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: center;
            flex-direction: column;

            &:last-child {
                margin-bottom: 2rem;
            }

            > span {
                border-radius: 50%;
                background-color: $color-secondary;
                width: $confirm-connecting-steps-circle-size;
                height: $confirm-connecting-steps-circle-size;
                flex: 0 0 $confirm-connecting-steps-circle-size;
                max-width: $confirm-connecting-steps-circle-size;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto auto 1rem;

                .dcfonts-icons {
                    color: $color-white;
                    fill: $color-white;
                    font-size: $font-size-m;
                }
            }

            p {
                flex: 1;
                margin: 0 auto;
                max-width: 20.3rem;
            }

            @include max-screen($screen__m) {
                &:not(:last-child)::after {
                    content: '';
                    height: 4rem;
                    width: 0.1rem;
                    border: solid 0.1rem $color-disabled-text;
                    margin: 1rem auto;
                    display: block;
                }
            }

            @include min-screen($screen__m) {
                flex: 0 0 50%;
                max-width: 50%;
                text-align: left;
                flex-direction: row;
                margin-bottom: 2rem;

                > span {
                    margin: 0 $space-1 0 0;
                }

                p {
                    margin: 0;
                    max-width: 100%;
                }

                &:nth-child(odd) {
                    padding-right: 2.5rem;
                }

                &:nth-child(even) {
                    padding-left: 2.5rem;
                    position: relative;

                    &::before {
                        content: '';
                        width: 2.9rem;
                        height: 0.1rem;
                        border: solid 0.1rem $color-grey-dark;
                        position: absolute;
                        left: 0;
                        top: $confirm-connecting-steps-circle-size / 2;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

    &-instructions-numeric-list {
        &.accountopeningrequest-container {
            box-shadow: none;
            background-color: transparent;
        }

        .#{$css-namespace}title {
            margin-bottom: 2.3rem;
        }

        ol {
            padding: 0 0 2rem;
            margin-bottom: 0;
            list-style: none;
            counter-reset: instructions-numeric-list;

            > li {
                counter-increment: instructions-numeric-list;
                margin-bottom: $space-1;
                line-height: 1.5;
                position: relative;
                text-indent: 2rem;

                &::before {
                    content: counter(instructions-numeric-list) '.';
                    color: $color-primary;
                    font-weight: $font-weight-bold;
                    position: absolute;
                    left: -2rem;
                    top: 0;
                }
            }
        }

        .#{$css-namespace}btn {
            // TODO : Refacto needed using w-full
            justify-content: center;
            width: 100%;
            max-width: 100%;
        }
    }
}
