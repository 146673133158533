@import 'modules/activities';
@import 'modules/back-button';
@import 'modules/company/multiple';
@import 'modules/company/search';
@import 'modules/confirm';
@import 'modules/progress-bar';
@import 'modules/error-page';
@import 'modules/success';

$activities-listing-input-padding: 0.5rem !default;

body[class^='accountopeningrequest-'] {
    background-color: $color-blue-background-1;
}

.accountopeningrequest-form-wrapper,
.accountopeningrequest-container {
    width: #{'min(46rem, 100%)'};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    &.#{$css-namespace}card-wrapper {
        padding-top: $space-3;
        padding-bottom: $space-3;
    }

    .item-description > span,
    .#{$css-namespace}card-content .address > span {
        display: block;
    }
}

.useropeningrequest-confirm-index {
    .page-wrapper {
        padding-top: 27.2rem;

        @include max-screen($screen__m) {
            padding-top: 24rem;
        }
    }
}

.accountopeningrequest-wrapper {
    > * {
        box-sizing: border-box;
    }

    .control-pass {
        position: relative;
    }

    #message {
        display: none;
        padding-bottom: $indent__m;
        color: $color-red10;
    }

    .actions .back:before {
        font-size: 3.2rem;
        content: '';
        font-family: 'icons-blank-theme' !important; // TODO: Refacto
        vertical-align: middle;
        font-weight: $font-weight-regular;
        transform: none;
        margin: 0;
    }

    .accountopeningrequest-form-wrapper & {
        justify-content: center;
        width: 100%;

        .actions.right .primary.action {
            width: 100%;
        }
    }

    .#{$css-namespace}form-radio-group--with-background label .text {
        margin-right: 5.5rem;

        @include min-screen($screen__s) {
            margin-right: 0;
        }
    }
}

.accountopeningrequest-optin {
    display: block;
    text-align: center;
    color: $color-gray52;

    a {
        text-decoration: underline;
        color: $color-gray52;

        &:active,
        &:visited,
        &:hover {
            color: $color-gray52;
        }
    }
}

.accountopeningrequest-side {
    .page-title-wrapper {
        padding-right: 5%;

        .page-title {
            font-weight: $font-weight__bold;
            line-height: normal;
            color: $color-blue3;
        }
    }
}

.quickSearchForm {
    .separator {
        margin-bottom: $indent__xl;
    }
}

.separator {
    font-size: $font-size-m;
    font-weight: $font-weight__bold;
    text-transform: uppercase;
}

.input-password__info {
    font-size: $font-size-s;
    display: block;
    padding: 1rem 0 0;
}

.alter-search {
    span {
        line-height: 4rem;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        width: 5rem;
        height: 5rem;
        margin: 0 $indent__l;
        padding: $indent__xs;
        text-align: center;
        text-transform: uppercase;
        color: $mainFontColor;
        border: 0.1rem solid $color-gray-middle5;
        border-radius: 100%;
        background: $clearColor;
    }
}

.toggle-password {
    position: absolute;
    top: 1.4rem;
    right: 1.2rem;
    width: 3.5rem;
    height: 2.5rem;
    cursor: pointer;

    &.view {
        @include lib-background-image(
                'png/montrer.png',
                center center,
                no-repeat,
                transparent,
                'Descours_AccountOpeningRequest'
        );
    }

    &.hide {
        @include lib-background-image(
                'png/cacher.png',
                center center,
                no-repeat,
                transparent,
                'Descours_AccountOpeningRequest'
        );
    }

    &.hide,
    &.view {
        background-size: 75%;
    }
}

.accountopeningrequest-confirm-index,
.accountopeningrequest-header-content,
.accountopeningrequest-form-wrapper .accountopeningrequest-form-content,
.useropeningrequest-user-index,
.aop-siret-index,
.aop-confirm-index {
    h1:not(.modal-title),
    .h1 {
        font-size: 2.5rem; // TODO: refactor in a future utility class
        font-family: $font-secondary;
        font-weight: $font-weight-bold;
        line-height: 3.5rem;
        margin: 0 auto $space-1 !important;
        color: $color-secondary;

        strong {
            display: block;
            font-size: $font-size-xxl;
            color: $color-primary;
        }
    }

    .page-title-wrapper {
        text-align: center;
        padding-bottom: 1.2rem;

        &.no-spacing {
            padding-bottom: 0;

            h1,
            .h1 {
                margin-bottom: 0;
            }
        }
    }
}

.accountopeningrequest-form-content {
    .accountopeningrequest-form-wrapper & {
        float: none;
        width: 100%;
        border: none;

        .fieldset {
            &:not(.registration-number, .companies, .geolocate, .search-by-zip) {
                width: 100%;
                margin-bottom: 0;
            }

            .field {
                %focused-label {
                    font-size: $font-size-s;
                    color: $color-primary;
                    top: 0;
                }

                input[type='text'],
                input[type='password'],
                input[type='url'],
                input[type='tel'],
                input[type='search'],
                input[type='number'],
                input[type='datetime'],
                input[type='email'] {
                    height: 4rem;

                    &:focus,
                    &:not([value='']),
                    &:not([value]),
                    &:not(:empty) {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }

                    ~ label {
                        font-size: 1.3rem;
                        padding-left: 0.7rem;
                        padding-right: 0.7rem;
                        left: 1.4rem;
                        background-color: $color-white;
                        top: 2rem;
                        transform: translateY(-50%);
                        line-height: 1;
                    }

                    /* If input is not empty */
                    &:not(:placeholder-shown) {
                        border-color: $color-primary;

                        /* You need to add a placeholder to your fields. For example: <input "placeholder=" "/> */
                        ~ label {
                            @extend %focused-label;
                        }
                    }

                    /* If input is empty */
                    &:placeholder-shown {
                        ~ label {
                            pointer-events: none;
                        }

                        &:focus ~ label {
                            @extend %focused-label;
                        }
                    }
                }
            }

            &.info:not([data-value])::after {
                margin-top: 2rem;
            }

            &.info:not([data-hasrequired])::after {
                display: none;
            }
        }

        .actions.right .primary:only-child {
            width: 100%;
        }
    }
}

.accountopeningrequest-wrapper {
    .actions {
        padding: 0;

        &.right {
            .primary {
                &.action {
                    background: $color-primary;

                    @include lib-hover-focus {
                        background-color: $color-primary-darken;
                    }

                    &.search {
                        position: relative;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        width: 100%;

                        > svg {
                            position: absolute;
                            top: 50%;
                            left: 1.5rem;
                            transform: translateY(-50%);
                            width: 2rem;
                            height: 2rem;
                        }
                    }

                    &:disabled {
                        border-color: $color-disabled-background;
                        background-color: $color-disabled-background;
                        color: $color-disabled-text;
                        opacity: 1;

                        > svg {
                            color: $color-disabled-text;
                            fill: $color-disabled-text;
                        }
                    }

                    &[data-ui-btn-skin='primary-alt'] {
                        border-color: $color-primary;
                        color: $color-primary;
                        background-color: $color-white;

                        @include lib-hover-focus {
                            background-color: $color-primary-darken;
                            color: $color-white;
                        }

                        &:disabled {
                            color: $color-disabled-text;
                            background-color: $color-disabled;
                            border-color: $color-disabled;

                            > svg {
                                color: $color-disabled-text;
                                fill: $color-disabled-text;
                            }
                        }
                    }
                }
            }
        }

        ~ .accountopeningrequest-companies-count .accountopeningrequest-companies-count-text:not(:empty) {
            margin-top: 3rem;
            margin-bottom: 0;
        }
    }
}

.accountopeningrequest-companies-count > .#{$css-namespace}title:not(:empty) {
    margin: 1rem 0;
}

@include max-screen($screen__l) {
    /*.fieldset.search-by-zip {
        display: flex;
        margin-bottom: $indent__xl;
    }*/

    .alter-search {
        position: relative;
        margin-bottom: $indent__xl;
        text-align: center;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 100%;
            height: 0.1rem;
            content: '';
            background: $color-gray-middle5;
        }
    }

    .accountopeningrequest-side {
        .actions {
            display: none;
        }
    }

    .accountopeningrequest-wrapper {
        overflow: hidden;
    }

    .accountopeningrequest-side {
        .page-title-wrapper {
            .page-title {
                font-size: 2.2rem;
            }
        }

        p {
            font-size: $font-size-m;
        }
    }

    .accountopeningrequest-optin {
        margin-bottom: $indent__m;
    }
}

@include min-screen($screen__l) {
    .geolocate-wrapper {
        display: flex;
    }

    .fieldset {
        &.geolocate {
            width: 20rem;
        }

        &.search-by-zip {
            display: flex;
            flex: 1;
            flex-flow: row wrap;
        }
    }

    .accountopeningrequest-wrapper {
        display: flex;

        .actions.right {
            .primary {
                min-width: 18rem;
                height: 4rem;

                &.mauto {
                    display: block;
                    margin: $indent__l auto 0;
                }
            }
        }
    }
}

.accountopeningrequest-side {
    display: flex;
    flex-direction: column;

    .page-title-wrapper {
        flex-grow: 1;
        box-sizing: border-box;

        .page-title {
            font-size: 2.8rem;

            margin-bottom: 1.2rem;
        }

        p {
            font-size: $font-size-m;
        }
    }

    .actions {
        text-align: right;
        margin-right: 10%;
    }
}

#company-search-form,
.accountopeningrequest-form-search-by-zip {
    width: 100%;

    p:not(.#{$css-namespace}title) {
        margin-bottom: 1rem;
    }

    .field {
        flex: 1;
        margin: 0;

        .control {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            //@include max-screen($screen__m) {
            //    flex-direction: row;
            //}

            .input-wrapper {
                display: flex;
                flex-wrap: wrap;
                max-width: 100%;
                width: 100%;
            }

            input {
                &:not(:placeholder-shown) ~ .actions button[type='reset'] {
                    opacity: 1;
                    visibility: visible;
                }

                &.input-text {
                    border: solid 0.1rem $color-primary;
                    border-radius: 0.3rem;
                    flex: 1 1 auto;

                    &:not(.mage-error) ~ div.mage-error {
                        display: none !important;
                    }
                }

                ~ div.mage-error {
                    width: 100%;
                    flex: 0 0 100%;
                }
            }

            .actions {
                position: absolute;
                right: 0;
                top: 0;
                border: none;

                flex: 0 0 auto;

                .primary {
                    height: 4rem;
                }

                button {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 4rem;
                    height: 4rem;
                    text-align: center;
                    padding: $space-1;
                    border: none;
                    background-color: transparent;

                    &[type='reset'] {
                        position: absolute;
                        top: 0;
                        right: 4rem;
                        color: $color-primary;
                        opacity: 0;
                        visibility: hidden;

                        &:hover {
                            color: $color-secondary;
                        }
                    }

                    &[type='submit'] {
                        background-color: $color-primary;
                    }

                    .dcfonts-icons,
                    svg {
                        padding: 0;
                        display: block;
                        font-size: 2.4rem;
                        width: 2.4rem;
                        height: 2.4rem;
                        color: $color-white;
                        fill: $color-white;
                    }
                }
            }
        }
    }
}

#company-search-form .field .control {
    margin-bottom: 2rem;
}

.accountopeningrequest-form-content {
    position: relative;
    border-left: 0.1rem solid $color-gray-darken1;
}

.doc-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $indent__base auto;
    padding: 0 $indent__base;
    max-width: 60rem;

    .doc-title {
        margin: 0 0 $indent__base;
        font-size: $font-size__l;
        font-weight: $font-weight__bold;
        text-transform: uppercase;
        color: $color-sub-secondary;
    }

    p {
        margin: 0 0 $indent__s;
        font-size: $font-size__baseplus;
    }

    strong {
        color: $color-sub-secondary;
    }

    .doc-email {
        display: inline-flex;
        margin: $indent__xs 0 0;
        padding: $indent__s $indent__base;
        background-color: #f0f0f0; // TODO: Use global var
    }
}

.aop-siret-index {
    .email {
        display: flex;
        justify-content: center;

        .left-part {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .dcfonts-icons {
            margin-left: $space-1;
        }
    }
}

.accountopeningrequest-container {
    .#{$css-namespace}card-content {
        .#{$css-namespace}title {
            margin-bottom: $space-1;
        }

        p:not(.#{$css-namespace}title) {
            margin-bottom: 0;
        }
    }
}
