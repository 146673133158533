$slide-width: 22.7rem !default;

.#{$css-namespace}equivalent-products {
    $root: &;

    &__products-slider {
        [data-slick-index='0'] {
            @include min-screen($screen__l) {
                display: none !important;
            }

            span {
                &.#{$css-namespace}chip--promo {
                    @include max-screen($screen__l) {
                        display: none !important;
                    }
                }
            }
        }
    }

    &__section {
        position: relative;
        width: $slide-width;
        border: 0.2rem solid transparent;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            border-color: $color-grey-border-light2;
            border-width: 0.1rem;
        }

        &.hide-on-desktop {
            @include min-screen($screen__l) {
                display: none !important;
            }
        }

        .slick-initialized & {
            min-width: auto;
        }

        &--current {
            width: $slide-width;
            border-color: $color-secondary;
            box-shadow: 0 0.8rem 1rem $color-grey-border-light2; // TODO: Use box-shadow var

            @include max-screen($screen__l) {
                display: none;

                .slick-slider & {
                    display: block;
                }
            }
        }

        .#{$css-namespace}tag--secondary {
            // TODO : Must be similar to dc__badge--tag
            position: absolute;
            top: $space-1;
            left: $space-1;
            font-size: $badge-text-size;
            font-weight: $badge-text-weight;
            padding: $badge-padding;
            border: solid 0.1rem $color-secondary;
            color: $color-secondary;
            background-color: $color-white;
            border-radius: $badge-border-radius-light;
            line-height: $badge-text-line-height;
            z-index: 1;
            min-height: $badge-min-height;
        }

        &__link {
            position: relative;
            white-space: nowrap;
            font-weight: $font-weight-bold;
            text-decoration: underline;
            color: $color-secondary !important;
            padding: $space-1 $space-2 $space-2;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__attributes {
        @include lib-list-reset-styles();
        margin-top: auto;

        &-unit {
            color: $text-price-color;
            font-size: $font-size-s;
            font-weight: $font-weight-bold;
        }

        &-content {
            display: inline-block;
            line-height: 1.1;

            &:first-letter {
                text-transform: uppercase;
            }

            * {
                width: auto;
            }
        }

        &-code {
            font-size: $font-size-s;
            color: $text-price-color;
            font-weight: $font-weight-semi-bold;
            margin-bottom: $space-1;

            @include min-screen($screen__l) {
                display: none !important;
            }
        }

        li {
            display: flex;
            align-items: center;
            margin: 0;
            padding: #{$space-3 / 2} $space-3;
            font-size: $font-size-default;
            font-weight: $font-weight-regular;

            &:nth-child(odd) {
                background-color: $color-grey-background;
            }

            &.is-price {
                color: $text-price-color;
                font-weight: $font-weight-bold;
            }

            &.is-block-login {
                padding: 0;
                background-color: transparent;

                .#{$css-namespace}product_slot__login {
                    width: 100%;
                }
            }

            &.highlight {
                font-weight: $font-weight-semi-bold;
            }
        }
    }

    &__wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    &__promo {
        display: flex;
        align-items: center;
        gap: $space-2;
    }

    &__old_price {
        text-decoration: line-through;
        font-size: $font-size-s;
        color: $text-price-color;
        font-weight: $font-weight-regular;
    }

    &__percent {
        padding: #{$space-1 / 2} $space-1;
        border-radius: 0.2rem;
        font-size: $font-size-xs;
        color: $color-red-promo;
        background-color: rgba($color-red-promo, 0.1);
        font-weight: $font-weight-semi-bold;
    }

    &__price {
        display: flex;
        align-items: center;
        gap: $space-1;
        margin-top: 0.2rem;
    }

    &__amount {
        color: $color-red;
        font-weight: $font-weight-semi-bold;

        .--disconnected & {
            color: $text-price-color;
        }
    }

    &__reference {
        font-size: $reference-text-size;
        color: $reference-text-color-alt;
        font-weight: $reference-text-weight;
    }

    .#{$css-namespace}product_slot {
        border: none;
        width: 100%;
    }
}
